import React from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import MoreVertIcon from "mdi-react/MoreVertIcon";
import Can from "../../../../../helpers/Secure/Can";
import { Popconfirm } from "antd";
import "antd/lib/popover/style/index.css";
import "../../../../../scss/component/motion.scss";
import EmptyCard from "../../../../../helpers/Fichas/emptyCard";

import styles from "./index.module.scss";

const PaymentsTab = (props) => {
  return (
    <>
      {props.dataPyments.length !== 0 ? (
        props.dataPyments.map((dato, index) => {
          return (
            <Card className={styles.payments_top_line} key={index}>
              <Can do="put" on="/payments/send/?">
                <UncontrolledDropdown inNavbar>
                  <DropdownToggle className="card-button icon" outline>
                    <MoreVertIcon className="card-button-icon" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown__menu">
                    <Popconfirm
                      title="¿Estás seguro de reenviar el comprobante de pago?"
                      okText="Si"
                      cancelText="No"
                      icon={
                        <i className="material-icons yellow_icon">warning</i>
                      }
                      placement="topLeft"
                      onConfirm={() => props.resendPaymentTicket(dato._id)}
                    >
                      <button className="dropdown-item">
                        Reenviar Comprobante de pago
                      </button>
                    </Popconfirm>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Can>
              <CardBody>
                <h4>{dato.concept}</h4>
                <h5>Pago: {dato.amount}</h5>
                <h5>Descuento: {dato.discount}</h5>
                <h5>
                  Fecha de pago:{" "}
                  {moment(dato.date).format("DD-MM-YYYY dd:mm A")}
                </h5>
                <h5>
                  Registró:{" "}
                  {dato.user
                    ? `${dato.user.name} ${dato.user.lastName}`
                    : "No disponible"}
                </h5>
              </CardBody>
            </Card>
          );
        })
      ) : (
        <EmptyCard message="No tiene pagos registrados" />
      )}
    </>
  );
};
export default PaymentsTab;
