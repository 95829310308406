import React, { useState, useContext } from 'react';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import Enrollment from 'containers/Enrollment/index';
import Can from "helpers/Secure/Can";
import EnrollmentState from 'context/EnrollmentContext/EnrollmentState';
import { context } from 'context/GlobalContext'


function SidebarContent(props) {
  const [showModalInscripcion, setShowModalInscripcion] = useState(false)
  const { actions } = useContext(context)


  const hideSidebar = () => {
    const { onClick } = props;
    onClick();
  };

  const logOut = () => {
    actions.closeSession()
  }

  return (
    <div className="sidebar__content">
      {/* <SidebarLink title="Inicio" iconMaterial="home" route="/home" onClick={hideSidebar} /> */}
      <ul className="sidebar__block">
        <SidebarCategory title="Gestión de usuarios" iconMaterial="assignment_ind">
          <Can do="get" on="/students/">
            <SidebarLink title="Alumnos" route="/alumnos" onClick={hideSidebar} />
          </Can>
          <Can do="get" on="/users/">
            <SidebarLink title="Empleados" route="/empleados" onClick={hideSidebar} />
          </Can>
          <Can do="get" on="/roles/*">
            <SidebarLink title="Roles" route="/roles" onClick={hideSidebar} />
          </Can>
        </SidebarCategory>

        {/* <SidebarLink title="Inscribir Curso" icon="exit" route="/inscripcion/cursoarea" onClick={hideSidebar} />*/}

        <SidebarCategory title="Administración escolar" iconMaterial="school">

          <Can do="post" on="/students/register">
            <button className="sidebar__link" onClick={() => setShowModalInscripcion(true)}>
              <p className="sidebar__link-title">Inscripción</p>
            </button>
          </Can>
          <Can do="get" on="/locations/">
            <SidebarLink title="Sedes" route="/sedes" onClick={hideSidebar} />

          </Can>
          <Can do="get" on="/courses/">
            <SidebarLink title="Cursos" route="/cursos" onClick={hideSidebar} />

          </Can>
          <Can do="get" on="/groups/">
            <SidebarLink title="Grupos" route="/grupos" onClick={hideSidebar} />
          </Can>

          <Can do="get" on="/appoiments/">
            <SidebarLink title="Asesorías" route="/asesorias" onClick={hideSidebar} />
          </Can>

          <Can do="get" on="/talks/">
            <SidebarLink title="Platicas" route="/platicas" onClick={hideSidebar} />
          </Can>

          <Can do="get" on="/videoComments/">
            <SidebarLink title="Comentarios" route="/comentarios" onClick={hideSidebar} />
          </Can>

          <Can do="put" on="/attendance/qr/*">
            <SidebarLink title="Pase de asistencia" route="/attendanceQr" onClick={hideSidebar} />
          </Can>
          {/* <Can do="post" on="/attendance/qr/">
              <SidebarLink title="Asignación con QR (provisional)" route="/asignationQr" onClick={hideSidebar} />
            </Can> */}
        </SidebarCategory>

        <SidebarCategory title="Cuenta" iconMaterial="person">
          <Can do="put" on="/users/?">
            <SidebarLink title="Editar contraseña" route={`/edit/password`} onClick={hideSidebar} />
          </Can>
          <Can do="get" on="/livechannels/">
            <SidebarLink title="Configuración General" route={`/expertConfig`} onClick={hideSidebar} />
          </Can>
        </SidebarCategory>
      </ul>
      
      <SidebarLink title="Cerrar Sesión" iconMaterial="exit_to_app" iconColor="#fa536c" onClick={logOut} />

      {
        showModalInscripcion &&
        <EnrollmentState>
          <Enrollment openModal={true} close={() => setShowModalInscripcion(false)} />
        </EnrollmentState>
      }
    </div >
  );
}

export default SidebarContent;
