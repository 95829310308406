import React, { useContext, useState } from "react";
import { Col, Row, Button, ButtonToolbar, ButtonGroup } from "reactstrap";
import { showMessageWarning } from "helpers/MessageAndNotificationUtils";
import StepFormulario from "./stepFormulario";
import StepFinalizar from "./stepFinalizar";

import { TalksContext } from "context/TalksContext";

import styles from "./index.module.scss";

const WizardForm = (props) => {
  const talksContext = useContext(TalksContext);
  const { state, editTalk, createTalk } = talksContext;
  const { talkFullInfo } = state;
  const initState = !props.isEditPlatica
    ? {
        page: 1,
        sedes: [],
        selectedSede: null,
        salonesSede: null,
        selectedSalon: [],
        name: "",
        courseLevel: undefined,
        schedules: [],
        status: false,
        quota: undefined,
        selectedProfessor: undefined,
        startDate: new Date(),
        endDate: new Date(),
        bloquear: false,
        isFeria: false,
      }
    : {
        page: 1,
        name: talkFullInfo.name,
        courseLevel: talkFullInfo.courseLevel,
        schedules: talkFullInfo.schedules,
        status: talkFullInfo.active,
        quota: talkFullInfo.quota,
        selectedProfessor: talkFullInfo.teacher
          ? talkFullInfo.teacher._id
          : talkFullInfo.teacher,
        startDate: talkFullInfo.date ? new Date(talkFullInfo.date) : new Date(),
        endDate: talkFullInfo.date ? new Date(talkFullInfo.date) : new Date(),
        sedes: [],
        salonesSede: [],
        selectedSede: talkFullInfo?.classRoom?.location,
        selectedSalon: talkFullInfo?.classRoom,
        isFeria: talkFullInfo?.isFeria,
      };

  const [localState, setState] = useState(initState);

  const nextPageCrea = async () => {
    if (localState.courseLevel === undefined)
      return showMessageWarning(
        <strong>Atención! Debes seleccionar un nivel</strong>,
        2
      );

    if (localState.name === "") {
      showMessageWarning(
        <strong>Atención! Debes escribir un nomre de grupo</strong>,
        2
      );
    } else if (localState.schedules.length <= 0) {
      showMessageWarning(
        <strong>Atención! Debes seleccionar un horario</strong>,
        2
      );
    } else {
      if (props.isEditPlatica) {
        const result = await editTalk(talkFullInfo._id, localState);
        if (result) {
          setState({
            ...localState,
            page: 2,
          });
        }
      } else {
        const result = await createTalk(localState);
        if (result) {
          setState({
            ...localState,
            page: 2,
          });
        }
      }
    }
  };

  //muestra los botones dependiendo del stepMostrado
  const typeBoottom = () => {
    switch (localState.page) {
      case 1:
        return (
          <Row className="d-flex flex-row">
            <Col sm={12} md={12}>
              <Button
                color={props.isEditPlatica ? "primary" : "warning"}
                type="button"
                disabled={localState.bloquear}
                className="btn-sm float-right"
                onClick={nextPageCrea}
              >
                {props.isEditPlatica ? "Guardar" : "Registrar"}
              </Button>
              <Button
                type="button"
                className="btn-sm float-right mr-2"
                onClick={() => props.closeModal()}
              >
                Salir sin guardar
              </Button>
            </Col>
          </Row>
        );
      case 2:
        return (
          <Row className="d-flex flex-row">
            <Col sm={12} md={12}>
              <Button
                color="success"
                type="button"
                className="btn-sm float-right"
                onClick={() => props.closeModal()}
              >
                Salir
              </Button>
            </Col>
          </Row>
        );
      default:
        return "Siguiente6";
    }
  };

  //muestra los botones en version mobile
  const typeBoottomMobile = () => {
    switch (localState.page) {
      case 1:
        return (
          <ButtonToolbar className="mb-2">
            <ButtonGroup className="btn-group--icons">
              <Button onClick={() => setState({ page: localState.page - 1 })}>
                <div>
                  <p>
                    <i className="material-icons ml-1">keyboard_arrow_left</i>
                    Regresar
                  </p>
                </div>
              </Button>
              <Button onClick={() => props.closeModal()}>
                <div>
                  <p>Salir</p>
                </div>
              </Button>
              <Button
                color="warning"
                onClick={nextPageCrea}
                disabled={localState.bloquear}
              >
                <div>
                  <p>
                    {props.isEditPlatica ? "Guardar" : "Registrar"}
                    <i className="material-icons ml-1">keyboard_arrow_right</i>
                  </p>
                </div>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        );
      case 2:
        return (
          <Row className="d-flex flex-row ">
            <Col sm={2} md={2}>
              <Button
                color="success"
                type="button"
                className="btn-sm float-right"
                onClick={() => props.closeModal()}
              >
                Salir
              </Button>
            </Col>
          </Row>
        );
      default:
        return "Siguiente6";
    }
  };

  /*
   *******************************************
   *******************************************
   ********************RENDER*****************
   *******************************************
   *******************************************
   */
  return (
    <section className={styles.wizard_container}>
      <div className={styles.show_big_device_buttons}>{typeBoottom()}</div>
      <section className={styles.show_mobile_buttons}>
        <div>
          <Col xs={12}>{typeBoottomMobile()}</Col>
        </div>
      </section>
      <div className="wizard">
        {props.isEditPlatica ? (
          ""
        ) : (
          <div className="wizard__steps">
            <div
              className={`wizard__step${
                localState.page === 1 ? " wizard__step--active" : ""
              }`}
            >
              <p>Formulario Platica</p>
            </div>
            <div
              className={`wizard__step${
                localState.page === 2 ? " wizard__step--active" : ""
              }`}
            >
              <p>Finalizar registro</p>
            </div>
          </div>
        )}
        <div className="wizard__form-wrapper">
          {localState.page === 1 && (
            <StepFormulario
              isEditPlatica={props.isEditPlatica}
              newGroupData={localState}
              setNewGroupData={setState}
              talkFullInfo={talkFullInfo}
              deleteTalk={props.deleteTalk}
              onClosSidebar={props.onClosSidebar}
            />
          )}
          {localState.page === 2 && (
            <StepFinalizar isEditPlatica={props.isEditPlatica} />
          )}
        </div>
      </div>
    </section>
  );
};

export default WizardForm;
