import React from 'react';
import { Col, Spinner } from 'reactstrap';

const LoadingComponent = (props) => {
    const { type ,message} = props
    return (
        <Col className="text-center">
            <Spinner type={type ? type : "grow"} style={{ width: '4rem', height: '4rem' }} />
            {message?<h4>{message}</h4>:""}
        </Col>
    )
}

export default LoadingComponent