import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import moment from "moment";
import "moment/locale/es";

import GroupsTabs from "./components/LevelTabs";
import AddGroup from "./components/addGroup/index";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import { GroupsContext } from "context/groups";
import SidebarGroup from "./components/Sidebar";
import GroupFilters from "./components/filters/groupFilters";

import styles from "./index.module.scss";
import "antd/es/page-header/style/index.css";
import "antd/es/table/style/index.css";

function Groups() {
  const groupsContext = useContext(GroupsContext);
  const { getCourseLevels, getAllGroups } = groupsContext;

  const [addGroupModal, setAddGroupModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showFiltersSidebar, setShowFiltersSidebar] = useState(false);

  useEffect(() => {
    getCourseLevels();
    let inicialDate = moment.utc(new Date()).local().format();
    getAllGroups("?endRngStart=" + inicialDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row>
        <Col xs="12">
          <MainPageHeader
            title="Administración Escolar |"
            subTitle={`Grupos`}
            buttonTitle={"Agregar grupo"}
            buttonOnClick={() => setAddGroupModal(true)}
            canActions={["post", "/groups/"]}
          />
          <Button
            size="sm"
            color="success"
            onClick={() => setShowFiltersSidebar(true)}
          >
            Ver filtros
          </Button>
        </Col>
        <Col xs={12} className={showDrawer && styles.tabs_container}>
          <GroupsTabs setShowDrawer={setShowDrawer} showDrawer={showDrawer} />
        </Col>
      </Row>

      {addGroupModal && (
        <AddGroup
          valueModal={addGroupModal}
          setAddGroupModal={setAddGroupModal}
        />
      )}

      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SidebarGroup />
        </SidebarRightComponent>
      )}

      {showFiltersSidebar && (
        <SidebarRightComponent
          placementSide="left"
          isSidebarRightOpen={showFiltersSidebar}
          onClosSidebar={() => setShowFiltersSidebar(false)}
        >
          <GroupFilters />
        </SidebarRightComponent>
      )}
    </Container>
  );
}
export default Groups;
