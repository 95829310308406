import React, { useState } from "react";
import { Pagination } from "antd";

import "antd/es/pagination/style/index.css";

const PaginationTable = ({ totalTalks, paginationTalks }) => {
  const [limitTalks, setLimitTalks] = useState(30);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [disablePagination, setDisablePagination] = useState(false);

  //set the number for results per page
  const onShowSizeChange = (current, pageSize) => {
    // console.log(current, pageSize);
    setDisablePagination(true);
    let newSkip = (current - 1) * pageSize;
    setLimitTalks(pageSize);

    const res = paginationTalks(newSkip, pageSize);
    res.then((result) => {
      setDisablePagination(false);
    });
  };

  //set the number for change pagnination
  const onChange = (pageNumber) => {
    let newSkip = (pageNumber - 1) * limitTalks;
    // console.log(pageNumber);
    // console.log(newSkip);
    if (currentPagination !== pageNumber) {
      setDisablePagination(true);
      setCurrentPagination(pageNumber);
      const res = paginationTalks(newSkip, limitTalks);
      res.then((result) => {
        setDisablePagination(false);
      });
    }
  };

  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} de ${total} platicas`;
  };

  return (
    <Pagination
      showSizeChanger
      onChange={onChange}
      current={currentPagination}
      showTotal={showTotal}
      total={totalTalks}
      pageSizeOptions={[10, 30, 50, 100]}
      defaultPageSize={limitTalks}
      onShowSizeChange={onShowSizeChange}
      disabled={disablePagination}
    />
  );
};

export default PaginationTable;
