import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
// import AutoSizer from 'react-virtualized-auto-sizer'
import EmptyCard from "helpers/Fichas/emptyCard";
import { randomColor } from "helpers/Fichas/constants";
import moment from "moment";
import { Avatar, Checkbox } from "antd";
import "moment/locale/es";
import "antd/es/avatar/style/index.css";
import "antd/es/checkbox/style/index.css";

const dayWeek = moment().isoWeekday(); //returns 1-7 where 1 is Monday and 7 is Sunday
let showCheckbox;
const AttendanceSidebar = ({
  actualGroupStudents,
  onClickList,
  setAttendanceByStudent,
  group,
}) => {
  const [disable, setDisable] = useState(false);
  //handler para el checkbox

  useEffect(() => {
    showCheckbox = group.schedules.find(
      (schedule) => schedule.day === dayWeek - 1
    );
  }, [group]);

  const onChange = (e) => {
    setDisable(true);
    const idStudent = e.target.name;
    const body = {
      studentId: idStudent,
      groupId: group._id,
      attendance: e.target.checked,
    };
    const result = setAttendanceByStudent(body);
    result
      .then((res) => {
        setDisable(false);
      })
      .catch((err) => {
        alert("Ocurrio un error , reportar a sistemas");
      });
  };

  const renderElement = (element, index = 0, style, showCheckbox = false) => {
    const { name, lastName, secondLastName, todayAttendance, _id } = element;
    return (
      <div style={style}>
        <li className="nombresLista" key={_id}>
          <Avatar
            style={{ backgroundColor: randomColor(name[0].toUpperCase()) }}
            size="small"
          >
            {name[0]}
          </Avatar>
          <span
            onClick={() => onClickList(actualGroupStudents[index])}
          >{` ${name} ${lastName} ${secondLastName}`}</span>
          {showCheckbox ? (
            <Checkbox
              className={`float-right mr-3 checkbox`}
              name={_id}
              disabled={disable}
              onChange={onChange}
              value={_id}
              checked={todayAttendance}
            />
          ) : (
            ""
          )}
          <hr />
        </li>
      </div>
    );
  };

  const Row = ({ index, style }) => {
    return renderElement(
      actualGroupStudents[index],
      index,
      style,
      showCheckbox
    );
  };

  const renderMap = () => {
    return actualGroupStudents.map((element, index) => {
      return renderElement(element, index, showCheckbox);
    });
  };

  return (
    <>
      {actualGroupStudents !== undefined && actualGroupStudents.length > 0 ? (
        actualGroupStudents.length < 200 ? (
          <>{renderMap()}</>
        ) : (
          <List
            height={600}
            width={280}
            itemSize={60}
            itemCount={actualGroupStudents.length}
          >
            {Row}
          </List>
        )
      ) : (
        <EmptyCard message="No hay alumnos registrados" />
      )}
    </>
  );
};
export default AttendanceSidebar;
