const reducers = {
  GET_COMMENTS: (state, payload) => {
    if (!payload || !payload.videoComments) {
      console.error("Payload no tiene la estructura esperada:", payload);
      return { ...state, onSucess: false };
    }
    return {
      ...state,
      comments: payload.videoComments,
      totalComments: payload.total,
      onSucess: true,
    };
  },
  ON_CHANGE_PAGINATION: (state,{page}) => {
    return { ...state, paginationComments: page };
  },
  ON_SHOW_SIZE: (state, {size}) => {
    return { ...state, paginationSize: size };
  },
  ON_SEARCH: (state, {word}) => {
    return { ...state, badWord: word };
  },
  ERROR: (state, payload) => {
    console.error("Error en acción:", payload);
    return { ...state, onSucess: false };
  },
};

export default reducers;
