import React, { useContext, useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";

import ClassroomsContext from "../../../context/classrooms/ClassroomsContext";
import Can from "../../../helpers/Secure/Can";
import AddEditClassroom from "./addEditClassroom";

import styles from "./index.module.scss";

const SidebarClassroom = (props) => {
  const classroomsContext = useContext(ClassroomsContext);
  const {
    actualClassroom,
    updateClassroom,
    deleteClassroom,
  } = classroomsContext;

  const [editClassModal, setEditClassModal] = useState(false);

  let { capacity, name, location } = actualClassroom;

  return (
    <Container>
      <Row className="mt-2">
        <Col xs="12" className={styles.sidebar_container}>
          <h1>{name}</h1>

          <div>
            <Can do="put" on="/classrooms/*">
              <Button
                size="sm"
                className="icon square float-right"
                type="button"
                color="success"
                outline
                onClick={() => setEditClassModal(true)}
              >
                <p>
                  <i className="material-icons">edit</i>Editar
                </p>
              </Button>
            </Can>
          </div>

          <div className="mt-1">
            <p className={styles.info_data}>Capacidad</p>
            <h3>{capacity}</h3>
          </div>
        </Col>
      </Row>

      {editClassModal && (
        <AddEditClassroom
          value={editClassModal}
          close={setEditClassModal}
          idLocation={location}
          functionAddEdit={updateClassroom}
          title={"Editar"}
          actualClassroom={actualClassroom}
          deleteClassroom={deleteClassroom}
          killSideBar={props.setShowDrawer}
        />
      )}
    </Container>
  );
};
export default SidebarClassroom;
