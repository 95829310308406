import React, { useContext, useState } from 'react';
import { Col, Button, } from 'reactstrap';
import {
  showNotification, showMessageLoading, showMessageWarning, showMessageError
} from 'helpers/MessageAndNotificationUtils';
import { deleteIds, dayWeekLetter, unAvailableMessage } from 'helpers/Globals/funciones';
import 'moment/locale/es';
import { GroupsContext } from 'context/groups';

let closeLoading = ""

const Title = ({ editGroupData, onCloseModal }) => {

  const groupsContext = useContext(GroupsContext);
  const { actualGroup, editGroup, availableClassroom, setActualGroupRowData } = groupsContext;

  const [disableButton, setDisableButton] = useState(false);

  const showAvailable = () => {
    setDisableButton(true)
    closeLoading = loadingMessage()
    let cloneSchedules = editGroupData.schedules.map(a => ({ ...a }))
    dayWeekLetter(cloneSchedules)

    //datos para el servicio de disponibilidad del salón
    let body = {
      classRoomId: editGroupData.classRoom,
      endDate: editGroupData.endDate,
      startDate: editGroupData.startDate,
      scheduleArray: cloneSchedules
    }

    let available = availableClassroom(body);
    available.then((result) => {
      if (result.success) {
        if (!result.isAvailable) {
          if (result.groups.length === 1 && result.groups[0]._id === actualGroup._id) {
            edit()
          } else {
            result.groups.map((dataGroup) => {
              closeLoading();
              setDisableButton(false)
              if (dataGroup._id !== actualGroup._id) return showNotification("error", "El salón ya esta ocupado en el horario y duración asignados.", unAvailableMessage(dataGroup), 0)
              return true
            })
          }
        } else {
          edit()
        }
      } else {
        showMessageError(<strong>{result.errorExt.message.es}</strong>, 3)
        closeLoading();
        setDisableButton(false)
      }
    })
  }

  const loadingMessage = () => {
    return showMessageLoading('Registrando..', 0);
  };

  const edit = () => {
    //validamos si eligio profesor o salòn y sede
    let cloneGroupData = Object.assign({}, editGroupData);
    if (editGroupData.teacher === '') delete cloneGroupData.teacher
    if (editGroupData.classRoom === '' || editGroupData.classRoom._id === '') {
      delete cloneGroupData.classRoom
      delete cloneGroupData.location
    }
    let classicReg = editGroup(editGroupData.teacher === '' || editGroupData.classRoom === '' ? cloneGroupData : editGroupData)
    classicReg.then((result) => {
      if (result.success) {
        setActualGroupRowData(result.group)
        // let inicialDate = moment.utc(new Date()).local().format()
        //getAllGroups("?endRngStart=" + inicialDate);
        closeLoading();
        setDisableButton(false)
        onCloseModal()
      } else {
        showMessageError(<strong>{result.errorExt.message.es}</strong>, 3)
        closeLoading();
        setDisableButton(false)
      }
    })
  }

  const validations = () => {
    if (editGroupData.name === "") return showMessageWarning(<strong>Atención! Debes escribir un nombre.</strong>, 3)
    if (editGroupData.schedules.length === 0) return showMessageWarning(<strong>Atención! Debes agregar los horarios.</strong>, 3)
    if (editGroupData.quota === "") return showMessageWarning(<strong>Atención! Debes agregar el cupo.</strong>, 3)
    //eliminamos el id de los schedules
    deleteIds(editGroupData.schedules)
    if (editGroupData.classRoom === '') {
      setDisableButton(true)
      closeLoading = loadingMessage()
      return edit()
    }
    showAvailable()
  }

  return (
    <>
      <Col xs={12} sm={4} md={4}>
        <h4 className="bold-text mt-3">Editar Grupo</h4>
      </Col>
      <Col xs={12} sm={8} md={8} className="mt-2">
        <Button size="sm" className="btn float-right"
          disabled={disableButton ? true : false}
          color="success"
          style={{ marginRight: "10px" }}
          onClick={() => validations()}
        >
          <p>Guardar</p>
        </Button>
        <Button size="sm" className="btn float-right mr-2"
          onClick={() => {
            onCloseModal()
            if (closeLoading !== "") closeLoading();
          }}>
          <p>Salir</p>
        </Button>
      </Col>
    </>
  )
}
export default Title