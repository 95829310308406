import React, { useReducer, useEffect } from 'react';
import EnrollmentContext from './EnrollmentContext';
import EnrollmentReducer from './EnrollmentReducer';
import {
  showMessageLoading, showMessageError
} from '../../helpers/MessageAndNotificationUtils';
import {
  fetchAsync, urlCourses, urlCourseLevels, urlGroups, urlStudents, urlRegister
} from '../../helpers/Globals/globals';
import {
  NEXT_PAGE,
  BACK_PAGE,
  STUDENT_DATA,
  CHECK_EMAIL,
  ENROLLMENT_ALL_COURSES,
  ENROLLMENT_COURSES_LEVELS,
  ACTIVE_TAB,
  ENROLLMENT_SELECTED_COURSE,
  COURSE_GROUPS,
  LOADING_GROUPS,
  ENROLLMENT_SELECTED_GROUP,
  PAYMENT_DATA,
  DISABLE_BUTTON_REGISTER,
  FAST_REGISTER_DATA_STUDENT,
  STUDENT_DISCOUNTS,
  DELETE_DISCOUNT,
  ADD_DISCOUNT,
  SET_STUDENT_DATA_TALK,
} from '../types/index';

let closeLoading = "";

const EnrollmentState = (props) => {

  const initialState = {
    page: 1,
    studentData: {
      name: "",
      lastName: "",
      secondLastName: "",
      email: "",
      phoneNumber: "",
      secondPhoneNumber: "",
    },
    payment: {
      paymentType: "cash",
      concept: "",
      amount: "",
      discount: "",
      //date: moment.utc(new Date()).local().format()
    },
    emailAlreadyExists: "",
    selectedCourse: "",
    allCourses: [],
    coursesMedia: [],
    coursesSuperior: [],
    mediaLevel: "",
    superiorLevel: "",
    activeTab: '1',
    selectedCourseGroups: [],
    loadingGroups: false,
    selectedGroup: "",
    disableButtonRegister: false,
    studentInfoFastRegister: null,
    discounts: null,
  }

  //crear dispatch y state
  const [state, dispatch] = useReducer(EnrollmentReducer, initialState)

  //traemos los levels para filtrar los cursos de media y superior
  useEffect(() => {
    getCourseLevels();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //traemos los grupos del curso
  useEffect(() => {
    if (state.page === 3) {
      getGrupos();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page])

  //buscamos si ya existe el email
  useEffect(() => {
    if ((state.studentData.email.includes("@") && state.studentData.email.includes("."))) {
      checkEmail()
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.studentData.email])


  const nextPage = () => {
    dispatch({
      type: NEXT_PAGE,
      payload: state.page + 1
    })

  }

  const backPage = () => {
    dispatch({
      type: BACK_PAGE,
      payload: state.page - 1
    })
  }

  const setLoading = (value) => {
    dispatch({
      type: LOADING_GROUPS,
      payload: value
    })
  }

  const disableButton = (value) => {
    dispatch({
      type: DISABLE_BUTTON_REGISTER,
      payload: value
    })
  }

  const setStudentData = (name, value) => {
    let obj = {
      name,
      value
    }
    dispatch({
      type: STUDENT_DATA,
      payload: obj
    })
  }

  const setPaymentData = (name, value) => {
    let obj = {
      name,
      value
    }
    dispatch({
      type: PAYMENT_DATA,
      payload: obj
    })
  }

  const checkEmail = () => {
    fetchAsync(urlStudents + "email/" + state.studentData.email.toLowerCase(), "", "GET", "")
      .then((data) => {
        dispatch({
          type: CHECK_EMAIL,
          payload: data
        })
      }).catch((reason) => { console.log(reason) });
  }

  const setTabValue = (value) => {
    dispatch({
      type: ACTIVE_TAB,
      payload: value
    })
  }

  const selectCourse = (courseData) => {
    dispatch({
      type: ENROLLMENT_SELECTED_COURSE,
      payload: courseData
    })
    if (state.studentInfoFastRegister != null) {
      getDiscountsCourseStudent(courseData._id)
    }
  }

  const selectGroup = (groupData) => {
    dispatch({
      type: ENROLLMENT_SELECTED_GROUP,
      payload: groupData
    })
  }

  /////////////////////TALKS/////////////////
  const setTalkStudentData = (studentData) => {
    dispatch({
      type: SET_STUDENT_DATA_TALK,
      payload: studentData
    })
  }

  //////CUANDO ES REGISTRO RAPIDO //////////////
  const setDataStudentFastRegister = (studentInfo) => {
    dispatch({
      type: FAST_REGISTER_DATA_STUDENT,
      payload: studentInfo
    })
  }

  const deleteDiscount = (idCourse) => {
    //console.log(idCourse)
    dispatch({
      type: DELETE_DISCOUNT,
      payload: idCourse
    })
  }

  const addDiscount = (obj) => {
    dispatch({
      type: ADD_DISCOUNT,
      payload: obj
    })
  }

  //trae los descuentos por cursos anteriores
  const getDiscountsCourseStudent = (idCourse) => {
    fetchAsync(urlCourses + idCourse + "/discounts/" + state.studentInfoFastRegister._id, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: STUDENT_DISCOUNTS,
            payload: data.discounts
          })
        }
      }).catch((reason) => { console.log(reason) });
  }

  //registrar alumno que ya tiene un regsitro previo
  const studentAlreadyRegistered = () => {
    disableButton(true)
    closeLoading = LoadingMessage()

    let newPayment = Object.assign({}, state.payment);
    if (newPayment.discount === "") delete newPayment.discount

    let body = {
      payment: newPayment,
    }
    // console.log(body)
    fetchAsync(urlGroups + state.selectedGroup._id + "/enroll/" + state.studentInfoFastRegister._id, JSON.stringify(body), "PUT", "")
      .then((data) => {
        if (data.success) {
          nextPage()
        } else {//si hay un error se pintara un alert con el mesaje de error
          if (data.errorExt.code === "05") {
            showMessageError(<strong>Atención! El alumno ya esta registrado en el grupo.</strong>, 2)
          } else {
            showMessageError(<strong>Ocurrio un error al realizar el registro.</strong>, 2)
          }
        }
        closeLoading()
        disableButton(false)
      }).catch((reason) => {
        disableButton(false)
        closeLoading()
        console.log(reason)
      });
  }
  //////FIN de funciones adicionales de REGISTRO RAPIDO desde la ficha de alumnosxw //////////////

  //obtiene los ids de niveles media,superior
  const getCourseLevels = () => {
    fetchAsync(urlCourseLevels, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: ENROLLMENT_COURSES_LEVELS,
            payload: data.courseLevels
          })
          //traemos todos los cursos
          getAllCourses()
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  //obtiene todos los cursos
  const getAllCourses = () => {
    fetchAsync(urlCourses, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: ENROLLMENT_ALL_COURSES,
            payload: data.courses
          })
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  //fetch para obtener todos grupos por id curso y fecha de inicio
  const getGrupos = () => {
    setLoading(true)
    let mes = new Date();
    mes.setMonth(mes.getMonth() - 1)
    mes.setDate(mes.getDate() - 1);
    return fetchAsync(urlGroups + "?course=" + state.selectedCourse._id + "&stRngStart=" + mes.toISOString().split("T", 1), "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: COURSE_GROUPS,
            payload: result.groups
          })
        }
        setLoading(false)
      }).catch((reason) => {
        setLoading(false)
        console.log(reason.message)
      });
  }

  const registerStudent = () => {
    disableButton(true)
    closeLoading = LoadingMessage()

    let newPayment = Object.assign({}, state.payment);
    if (newPayment.discount === "") delete newPayment.discount

    let body = {
      student: state.studentData,
      payment: newPayment,
      group: { "id": state.selectedGroup._id }
    }

    return fetchAsync(urlRegister, JSON.stringify(body), "POST", "")
      .then((data) => {
        if (data.success) {
          nextPage()
        } else {//si hay un error se pintara un alert con el mesaje de error
          if (data.errorExt.code === "05") {
            showMessageError(<strong>Atención! El alumno ya esta registrado en el grupo.</strong>, 2)
          } else {
            showMessageError(<strong>Ocurrio un error al realizar el registro.</strong>, 2)
          }
        }
        closeLoading()
        disableButton(false)
        return data
      }).catch((reason) => {
        disableButton(false)
        closeLoading()
        console.log(reason)
      });
  }

  const LoadingMessage = () => {
    return showMessageLoading('Registrando..', 0);
  };

  return (
    <EnrollmentContext.Provider
      value={{
        //estados para mover los steps del wizard
        page: state.page,
        nextPage,
        backPage,
        //estado que contiene la información del estudiante
        studentData: state.studentData,
        setStudentData,
        //estado que contiene información si el correo ya existe
        emailAlreadyExists: state.emailAlreadyExists,
        //estado y función para los tabs de cursos
        activeTab: state.activeTab,
        setTabValue,
        //estados de cursos media y superior y curso selecionado y su función
        coursesMedia: state.coursesMedia,
        coursesSuperior: state.coursesSuperior,
        selectedCourse: state.selectedCourse,
        selectCourse,
        //estado para los grupos del curso
        selectedCourseGroups: state.selectedCourseGroups,
        loadingGroups: state.loadingGroups,
        //estado y funcion para seleccionar un grupo
        selectedGroup: state.selectedGroup,
        selectGroup,
        //estados de pagos
        payment: state.payment,
        setPaymentData,
        //función para registrar al alumno
        registerStudent,
        //cambia el estado para habilitar o deshabilitar el boton de registro
        disableButtonRegister: state.disableButtonRegister,
        //REGISTRO RAPIDO
        studentInfoFastRegister: state.studentInfoFastRegister,
        setDataStudentFastRegister,
        discounts: state.discounts,
        deleteDiscount,
        addDiscount,
        //función para inscribir un alumno que ya tiene un registro
        studentAlreadyRegistered,
        setTalkStudentData,
      }}
    >
      {props.children}
    </EnrollmentContext.Provider>
  )
}
export default EnrollmentState

