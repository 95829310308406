const treeData = [
  {
    title: 'Lunes',
    value: '1',
    key: '0',
    children: [
      {
        title: 'Lun-8:00 AM',
        value: '1-0',
        key: '0-0',
      },
      {
        title: 'Lun-10:00 AM',
        value: '1-1',
        key: '0-1',
      },
      {
        title: 'Lun-12:00 PM',
        value: '1-2',
        key: '0-2',
      },
      {
        title: 'Lun-2:00 PM',
        value: '1-3',
        key: '0-3',
      },
      {
        title: 'Lun-4:00 PM',
        value: '1-4',
        key: '0-4',
      },
      {
        title: 'Lun-6:00 PM',
        value: '1-5',
        key: '0-5',
      },
    ],
  },
  {
    title: 'Martes',
    value: '2',
    key: '1',
    children: [
      {
        title: 'Mar-8:00 AM',
        value: '2-0',
        key: '1-0',
      },
      {
        title: 'Mar-10:00 AM',
        value: '2-1',
        key: '1-1',
      },
      {
        title: 'Mar-12:00 PM',
        value: '2-2',
        key: '1-2',
      },
      {
        title: 'Mar-2:00 PM',
        value: '2-3',
        key: '1-3',
      },
      {
        title: 'Mar-4:00 PM',
        value: '2-4',
        key: '1-4',
      },
      {
        title: 'Mar-6:00 PM',
        value: '2-5',
        key: '1-5',
      },
    ],
  }, {
    title: 'Miercoles',
    value: '3',
    key: '2',
    children: [
      {
        title: 'Mie-8:00 AM',
        value: '3-0',
        key: '2-0',
      },
      {
        title: 'Mie-10:00 AM',
        value: '3-1',
        key: '2-1',
      },
      {
        title: 'Mie-12:00 PM',
        value: '3-2',
        key: '2-2',
      },
      {
        title: 'Mie-2:00 PM',
        value: '3-3',
        key: '2-3',
      },
      {
        title: 'Mie-4:00 PM',
        value: '3-4',
        key: '2-4',
      },
      {
        title: 'Mie-6:00 PM',
        value: '3-5',
        key: '2-5',
      },
    ],
  }, {
    title: 'Jueves',
    value: '4',
    key: '3',
    children: [
      {
        title: 'Jue-8:00 AM',
        value: '4-0',
        key: '3-0',
      },
      {
        title: 'Jue-10:00 AM',
        value: '4-1',
        key: '3-1',
      },
      {
        title: 'Jue-12:00 PM',
        value: '4-2',
        key: '3-2',
      },
      {
        title: 'Jue-2:00 PM',
        value: '4-3',
        key: '3-3',
      },
      {
        title: 'Jue-4:00 PM',
        value: '4-4',
        key: '3-4',
      },
      {
        title: 'Jue-6:00 PM',
        value: '4-5',
        key: '3-5',
      },
    ],
  }, {
    title: 'Viernes',
    value: '5',
    key: '4',
    children: [
      {
        title: 'Vie-8:00 AM',
        value: '5-0',
        key: '4-0',
      },
      {
        title: 'Vie-10:00 AM',
        value: '5-1',
        key: '4-1',
      },
      {
        title: 'Vie-12:00 PM',
        value: '5-2',
        key: '4-2',
      },
      {
        title: 'Vie-2:00 PM',
        value: '5-3',
        key: '4-3',
      },
      {
        title: 'Vie-4:00 PM',
        value: '5-4',
        key: '4-4',
      },
      {
        title: 'Vie-6:00 PM',
        value: '5-5',
        key: '4-5',
      },
    ],
  }, {
    title: 'Sabado',
    value: '6',
    key: '5',
    children: [
      {
        title: 'Sab-8:00 AM',
        value: '6-0',
        key: '5-0',
      },
      {
        title: 'Sab-10:00 AM',
        value: '6-1',
        key: '5-1',
      },
      {
        title: 'Sab-12:00 PM',
        value: '6-2',
        key: '5-2',
      },
      {
        title: 'Sab-2:00 PM',
        value: '6-3',
        key: '5-3',
      },
      {
        title: 'Sab-4:00 PM',
        value: '6-4',
        key: '5-4',
      },
      {
        title: 'Sab-6:00 PM',
        value: '6-5',
        key: '5-5',
      },
    ],
  },
  {
    title: 'Domingo',
    value: '0',
    key: '6',
    children: [
      {
        title: 'Dom-8:00 AM',
        value: '0-0',
        key: '6-0',
      },
      {
        title: 'Dom-10:00 AM',
        value: '0-1',
        key: '6-1',
      },
      {
        title: 'Dom-12:00 PM',
        value: '0-2',
        key: '6-2',
      },
      {
        title: 'Dom-2:00 PM',
        value: '0-3',
        key: '6-3',
      },
      {
        title: 'Dom-4:00 PM',
        value: '0-4',
        key: '6-4',
      },
      {
        title: 'Dom-6:00 PM',
        value: '0-5',
        key: '6-5',
      },
    ],
  }
];

export default treeData