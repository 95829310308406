import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TopBar = memo(({ title }) => (
  <div className="top_bar">
    <p className="">{title}</p>
  </div>
))
TopBar.propTypes = {
  title: PropTypes.string.isRequired,
};
export default TopBar



