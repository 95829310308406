import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "reactstrap";

import RolesContext from "../../context/roles/RolesContext";

import LoadingComponent from "../../helpers/LoadingComponent";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import EmptyComponent from "../../helpers/Empty/EmptyComponent";
import CardDraw from "../../helpers/cards/genericCard";

import SidebarRoles from "./components/sidebarRoles";
import ModalRol from "./components/ModalRol";

import styles from "./index.module.scss";

function Roles(props) {
  const rolesContext = useContext(RolesContext);
  const {
    setSelectedRole,
    allRoles,
    getRoles,
    createRole,
    selectedRole,
  } = rolesContext;

  const [valueModal, setValueModal] = useState(false);
  const [loadigCards, setLoadigCards] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (allRoles.length === 0) setLoadigCards(true);
    let result = getRoles();
    result.then((res) => {
      setLoadigCards(false);
      if (res.roles.length === 0) setEmpty(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (element) => {
    setSelectedRole(element);
    setShowDrawer(true);
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col xs="12">
          <MainPageHeader
            title="Gestión de Usuarios |"
            subTitle={`Roles`}
            buttonTitle={"Agregar rol"}
            buttonOnClick={() => setValueModal(true)}
            canActions={["post", "/roles/*"]}
          />
        </Col>
        <Col
          xs="12"
          className={`${styles.roles_cards_box} ${
            showDrawer ? styles.roles_cards_container : ""
          }`}
        >
          {loadigCards ? (
            <LoadingComponent />
          ) : empty ? (
            <EmptyComponent message="No hay Roles creados" />
          ) : (
            <CardDraw
              type="roles"
              data={allRoles}
              ClickOnElement={handleClick}
              selected={selectedRole}
            />
          )}
        </Col>
      </Row>
      {valueModal && (
        <ModalRol
          value={valueModal}
          close={setValueModal}
          createRole={createRole}
        />
      )}

      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SidebarRoles onClosSidebar={() => setShowDrawer(false)} />
        </SidebarRightComponent>
      )}
    </Container>
  );
}
export default Roles;
