import React, { useState } from "react";
import { Button, Form, Input, Result } from "antd";

import {
  fetchAsync,
  urlStudents,
} from "../../../../../helpers/Globals/globals";

import styles from "./index.module.scss";

const ChgangePassword = ({ studentData, onCloseModal }) => {
  const [successChange, setSuccessChange] = useState(false);
  const [showError, setShowError] = useState(false);
  const [inputError, setInputError] = useState(undefined);

  const onFinish = (values) => {
    // console.log("Success:", values);
    if (
      values?.newPassword?.length >= 4 &&
      values.newPassword === values.repeatpassword
    ) {
      fetchAsync(
        urlStudents + studentData._id,
        JSON.stringify({ password: values.newPassword }),
        "PUT",
        "default"
      )
        .then((data) => {
          if (data.success) {
            setSuccessChange(true);
          } else {
            setShowError(true);
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    } else {
      if (values?.newPassword?.length < 4) {
        setInputError("La contraseña debe contener minimo 4 dígitos");
      } else if (values.newPassword !== values.repeatpassword) {
        setInputError("Las contraseñas no coinciden");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFieldsChange = () => {
    if (inputError != undefined) setInputError(undefined);
  };
  return (
    <section className={styles.main_container}>
      <div className={styles.button_container}>
        <Button
          className={`btn btn-secondary float-right`}
          onClick={onCloseModal}
        >
          Salir
        </Button>
      </div>
      <div className="mt-4">
        <p>
          Aqui podras cambiar la contraseña que el alumno usa para la plataforma
          de videos y exámenes.
        </p>
      </div>
      <div className="mt-4">
        {successChange || showError ? (
          <div>
            <Result
              status={showError ? "error" : "success"}
              title={
                showError ? "Ocurrio un error" : "Cambio de contraseña exitoso"
              }
              subTitle=""
              extra={
                successChange
                  ? []
                  : [
                      <Button
                        type="primary"
                        key="console"
                        onClick={() => setShowError(false)}
                      >
                        Intentar otra vez
                      </Button>,
                    ]
              }
            />
          </div>
        ) : (
          <Form
            layout={"vertical"}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={onFieldsChange}
            autoComplete="off"
          >
            <Form.Item
              label="Nueva Contraseña"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Introduce una contraseña.",
                },
              ]}
              validateStatus={inputError ? "error" : ""}
              help={inputError}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Repetir Nueva Contraseña"
              name="repeatpassword"
              rules={[
                {
                  required: true,
                  message: "Introduce una contraseña",
                },
              ]}
              validateStatus={inputError ? "error" : ""}
              help={inputError}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Cambiar contraseña
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </section>
  );
};

export default ChgangePassword;
