import React, { useReducer, createContext } from "react";
import { message } from "antd";
import {
  fetchAsync,
  urlClassrooms,
  urlClassRoom,
} from "../../helpers/Globals/globals";
import {
  ACTUAL_CLASSROOM,
  SAVE_NEW_CLASSROOM,
  UPDATE_CLASSROOM,
  DELETE_CLASSROOM,
  CLASSROOMS_LOCATION,
  ERRORS_CLASSROOM,
} from "../types/index";
import ClassroomsReducer from "./ClassroomsReducer";

const ClassroomsContext = createContext();
export default ClassroomsContext;

export const ClassroomsProvider = (props) => {
  const inicialState = {
    actualClassroom: {},
    classroomsLocation: [],
    emptyClassroms: false,
    actualError: null,
  };

  const [state, dispatch] = useReducer(ClassroomsReducer, inicialState);

  const setActualClassroom = (classroomInfo) => {
    dispatch({
      type: ACTUAL_CLASSROOM,
      payload: classroomInfo,
    });
  };

  const getClassroomsLocation = (idLocation) => {
    fetchAsync(urlClassrooms + idLocation, "", "GET")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: CLASSROOMS_LOCATION,
            payload: result.classRoom,
          });
        } else {
          dispatch({
            type: ERRORS_CLASSROOM,
            payload: result?.errorExt,
          });
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const createClassroom = (body) => {
    let loadingMessage = message.loading("Creando el salón");
    return fetchAsync(urlClassRoom, JSON.stringify(body), "POST")
      .then((data) => {
        if (data.success) {
          loadingMessage.then(() =>
            message.success("Salón creado exitosamente", 5)
          );
          dispatch({
            type: SAVE_NEW_CLASSROOM,
            payload: data.classRoom,
          });
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
        loadingMessage.then(() => {
          return message.error("Ocurrio un error", 5);
        });
      });
  };

  const updateClassroom = (body) => {
    let loadingMessage = message.loading("Actualizando el salón");
    const idClassroom = body._id;

    delete body._id;
    return fetchAsync(urlClassRoom + idClassroom, JSON.stringify(body), "PUT")
      .then((data) => {
        if (data.success) {
          loadingMessage.then(() =>
            message.success("Salón actualizado exitosamente", 3)
          );
          dispatch({
            type: UPDATE_CLASSROOM,
            payload: data.classRoom,
          });
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
        loadingMessage.then(() => {
          return message.error("Ocurrio un error", 5);
        });
      });
  };

  const deleteClassroom = (idClassroom) => {
    return fetchAsync(urlClassRoom + idClassroom, "", "DELETE", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: DELETE_CLASSROOM,
            payload: idClassroom,
          });
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const value = {
    ...state,
    setActualClassroom,
    getClassroomsLocation,
    createClassroom,
    updateClassroom,
    deleteClassroom,
  };

  return (
    <ClassroomsContext.Provider value={value}>
      {props.children}
    </ClassroomsContext.Provider>
  );
};
