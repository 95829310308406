import React, { useReducer } from 'react';
import {
  showMessageWarning, showMessageSuccess, showMessageError
} from '../../helpers/MessageAndNotificationUtils';
import {
  fetchAsync, urlCourses, urlPlaylist, urlQuiz
} from '../../helpers/Globals/globals';
import {
  GET_QUIZ_INFO, SAVED_QUIZ,
  SAVE_QUIZ_COURSE_INFO, SAVE_QUIZ_PLAYLIST_INFO,
  ONCHANGE_INPUTS, ADD_QUESTION,
  DELETE_QUESTION, ONCHANGE_QUESTION,
  ADD_FORMULA_QUESTION, DELETE_FORMULA_QUESTION,
  ADD_IMAGE_QUESTION, ADD_VIDEO_QUESTION,
  DELETE_VIDEO_QUESTION, ADD_ANSWER,
  DELETE_ANSWER, DELETE_FORMULA_ANSWER,
  ONCHANGE_ANSWER, VALID_ANSWER,
  ADD_FORMULA_ANSWER, ADD_IMAGE_ANSWER,
  AFTER_SAVED_QUIZ, REORDER_QUESTIONS,
  SET_SKIP_NUMBER, LOADING_QUIZ, RESTART_VALUES,
  DISABLE_BUTTONS,
} from '../types/index';
import QuizReducer from './QuizReducer';
import QuizContext from './QuizContext';
import shortid from 'shortid';

const QuizState = (props) => {

  const initialState = {
    courseInfo: "",
    playlistInfo: "",
    saved: false,
    _idQuiz: "",
    quizInfo: {
      name: "",
      description: "",
      active: true,
      random: true,
      studentAttempts: 1,
      questionsPerQuiz: 0,
      limitTime: 0,
      quiz: [],
      quizType: 1,
    },
    skipNumber: 0,
    loading: false,
    disable: false,
  }

  //crear dispatch y state
  const [state, dispatch] = useReducer(QuizReducer, initialState)

  const restartValues = () => {
    dispatch({
      type: RESTART_VALUES,
      payload: initialState
    })
  }

  const setSavedQuiz = (status) => {
    dispatch({
      type: SAVED_QUIZ,
      payload: status
    })
  }

  const setSkipNumber = (numSkip) => {
    dispatch({
      type: SET_SKIP_NUMBER,
      payload: numSkip
    })
  }

  const setLoading = (state) => {
    dispatch({
      type: LOADING_QUIZ,
      payload: state
    })
  }

  const setDisable = (state) => {
    dispatch({
      type: DISABLE_BUTTONS,
      payload: state
    })
  }

  const getPlaylistInfo = async (idPlaylist) => {
    try {
      const resPalylist = await fetchAsync(urlPlaylist + idPlaylist, "", "GET")
      if (resPalylist.success) {
        dispatch({
          type: SAVE_QUIZ_PLAYLIST_INFO,
          payload: resPalylist.playlist
        })
      }
      return resPalylist
    } catch (error) {
      console.log(error)
    }
  }

  const getCourseInfo = async (idCourse) => {
    try {
      const response = await fetchAsync(urlCourses + idCourse, "", "GET")
      if (response.success) {
        dispatch({
          type: SAVE_QUIZ_COURSE_INFO,
          payload: response.course
        })
      }
      return response
    } catch (error) {
      console.log(error)
    }
  }

  //obtenemos la info del quiz
  const getQuizInfo = (idQuiz) => {
    setLoading(true)
    fetchAsync(urlQuiz + idQuiz, "", "GET")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_QUIZ_INFO,
            payload: data.quiz
          })
        }
        setLoading(false)
      }).catch(err => {
        console.log(err)
      })
  }

  //Agrega el texto a los inputs del leftbar
  const onChangeInputs = (field, value) => {
    let inputForm = {
      field,
      value
    }
    dispatch({
      type: ONCHANGE_INPUTS,
      payload: inputForm
    })
  }

  //AGREGAR una pregunta
  const addQuestion = () => {
    const questionObj = {
      question: "",
      answerCollection: [],
      _id: shortid.generate(),
      explanationVideo: [],
    }

    for (let b = 0; b < 3; b++) {
      let answersObj = {
        validAnswer: false,
        content: "",
        idAnswer: shortid.generate(),
      }
      questionObj.answerCollection.push(answersObj)
    }

    dispatch({
      type: ADD_QUESTION,
      payload: questionObj
    })
  }

  //BORRAR una pregunta 
  const deleteQuestion = (idQuestion) => {
    if (state.quizInfo.quiz.length === 1) return showMessageWarning("Atención! No puedes borrar todas las preguntas.", 4)
    dispatch({
      type: DELETE_QUESTION,
      payload: idQuestion
    })
  }

  //escribir pregunta solo texto
  const handleOnChangeQuestion = (e) => {
    let idQuestion = e.currentTarget.id
    let text = e.target.value

    let bodyUpdate = {
      idQuestion,
      text,
      skip: state.skipNumber
    }

    dispatch({
      type: ONCHANGE_QUESTION,
      payload: bodyUpdate
    })
  }

  const addQuestionFormula = (idQuestion, formula) => {
    let bodyFormula = {
      idQuestion,
      formula,
      skip: state.skipNumber
    }
    dispatch({
      type: ADD_FORMULA_QUESTION,
      payload: bodyFormula
    })
  }

  // borrar unaformula de uan pegunta questionFormula questionType=1
  const deleteQuestionFormula = (idQuestion) => {
    dispatch({
      type: DELETE_FORMULA_QUESTION,
      payload: idQuestion
    })
  }

  const addImageQuestion = (idQuestion, imageInfo) => {
    let bodyFormula = {
      idQuestion,
      imageInfo,
    }
    dispatch({
      type: ADD_IMAGE_QUESTION,
      payload: bodyFormula
    })
  }

  //GUARDAR vdeo(s) en una pregunta
  const saveVideosQuestion = (idQuestion, videos) => {
    let bodyVideos = {
      idQuestion,
      videos,
    }
    dispatch({
      type: ADD_VIDEO_QUESTION,
      payload: bodyVideos
    })
  }

  const deleteVideoQuestion = (idQuestion, idVideo) => {
    let bodyVideos = {
      idQuestion,
      idVideo,
    }
    dispatch({
      type: DELETE_VIDEO_QUESTION,
      payload: bodyVideos
    })
  }

  ///////ANSWER FUNCTIONS////////

  //AGREGAR una respuesta a una pregunta
  const addAnswer = (_idQuestion) => {
    const bodyAddAnswer = {
      _idQuestion,
      answersObj: {
        validAnswer: false,
        content: "",
        idAnswer: shortid.generate(),
      }
    }
    dispatch({
      type: ADD_ANSWER,
      payload: bodyAddAnswer
    })
  }

  //Borrar una respuesta a una pregunta
  const deleteAnswer = (idAnswer, idQuestion) => {
    let bodyDeleteAnswer = {
      idAnswer,
      idQuestion,
    }
    dispatch({
      type: DELETE_ANSWER,
      payload: bodyDeleteAnswer
    })
  }

  //escribir una respuesta solo texto
  const handleChangeAnswer = (idAnswer, idQuestion, text) => {
    let bodyUpdate = {
      idAnswer,
      idQuestion,
      text,
      skip: state.skipNumber
    }
    dispatch({
      type: ONCHANGE_ANSWER,
      payload: bodyUpdate
    })
  }

  const addAnswerFormula = (idQuestion, formula, idAnswer) => {
    let bodyUpdate = {
      idQuestion,
      formula,
      idAnswer
    }

    dispatch({
      type: ADD_FORMULA_ANSWER,
      payload: bodyUpdate
    })
  }

  const deleteAnswerFormula = (idQuestion, idAnswer) => {
    let bodyDeleteFormula = {
      idQuestion,
      idAnswer
    }
    dispatch({
      type: DELETE_FORMULA_ANSWER,
      payload: bodyDeleteFormula
    })
  }

  const addAnswerImage = (idQuestion, image, idAnswer) => {
    let bodyAddAnswer = {
      idQuestion,
      image,
      idAnswer
    }
    dispatch({
      type: ADD_IMAGE_ANSWER,
      payload: bodyAddAnswer
    })
  }

  //cambia a true o false el campo de respuesta correcta
  const onChangeCheckbox = (e) => {
    let ids = e.target.id
    let status = e.target.checked
    let idAnswer = ids.split("/")[0]
    let idQuestion = ids.split("/")[1]

    let bodyAnswerCheckbox = {
      idQuestion,
      status,
      idAnswer
    }

    dispatch({
      type: VALID_ANSWER,
      payload: bodyAnswerCheckbox
    })
  }

  //guarda el array de preguntas reordenadas
  const reorderQuestions = (newOrderedArray) => {
    dispatch({
      type: REORDER_QUESTIONS,
      payload: newOrderedArray
    })
    return true
  }

  /////////Save and update quiz functions////////////////
  const validations = () => {
    if (state.quizInfo.name === "") {
      return showMessageWarning("Atención! Debes escribir un titulo.", 2)
    }
    if (state.quizInfo.description === "") {
      return showMessageWarning("Atención! debes agregar una descripción.", 2)
    }
    //eliminamos los campos innecesarios de las respuestas
    let arrayQuestions = deleteAnswersUslessFields()

    for (let a = 0; a < arrayQuestions.length; a++) {
      if (arrayQuestions[a].question.length < 1) {
        return showMessageWarning("Atención! Hay una pregunta vacia.", 2)
      }
      for (let i = 0; i < arrayQuestions[a].answerCollection.length; i++) {
        if (arrayQuestions[a].answerCollection[i].content !== undefined && arrayQuestions[a].answerCollection[i].content.length < 1) {
          return showMessageWarning("Atención! Hay una respuesta vacia.", 2)
        }
      }
      //validamos que exista almenos una pregunta marcada como correcta (true)
      let existTrue = arrayQuestions[a].answerCollection.find(data => data.validAnswer === true);
      if (existTrue === undefined) {
        return showMessageWarning("Atención! Todas las preguntas deben tener almenos una respuesta correcta.", 4)
      }
    }
    //bloquear boton
    setDisable(true)

    const objQuiz = { ...state.quizInfo }
    //agregamos el array de preguntas con respuestas validadas
    objQuiz.quiz = arrayQuestions
    //eliminamos el ca,po de timpo si no se guardo algun intervalo
    if (state.quizInfo.limitTime === 0) {
      delete objQuiz.limitTime
    }

    deleteQuestionsIds(objQuiz.quiz)

    //validamos si es un nuevo quizz (POST / PUT)
    if (!state.saved) {
      fetchAsync(urlQuiz + state.playlistInfo._id, JSON.stringify(objQuiz), "POST")
        .then((data) => {
          if (data.success === true) {
            showMessageSuccess("Cuestionario guardado exitosamente", 2)
            dispatch({
              type: AFTER_SAVED_QUIZ,
              payload: data.quiz
            })
          }
          setDisable(false)
        }).catch((reason) => {
          showMessageError("Ocurrio un error", 3)
          console.log(reason)
        })
    } else {
      fetchAsync(urlQuiz + state._idQuiz, JSON.stringify(objQuiz), "PUT")
        .then((data) => {
          if (data.success === true) {
            showMessageSuccess("Cuestionario actualizado exitosamente", 2)
            getQuizInfo(state._idQuiz)
          }
          setDisable(false)
        }).catch((reason) => {
          showMessageError("Ocurrio un error", 3)
          console.log(reason)
        })
    }
  }

  //limpia el array de respuestas deacuerdo al type guardado, eliminando los campos innecesarios
  const deleteAnswersUslessFields = () => {
    let newArrayQustions = JSON.parse(JSON.stringify(state.quizInfo.quiz));
    for (let a = 0; a < newArrayQustions.length; a++) {
      for (let i = 0; i < newArrayQustions[a].answerCollection.length; i++) {
        if (newArrayQustions[a].answerCollection[i].answerImage) {
          newArrayQustions[a].answerCollection[i].answerImage = newArrayQustions[a].answerCollection[i].answerImage._id
          delete newArrayQustions[a].answerCollection[i].content
          delete newArrayQustions[a].answerCollection[i].answerFormula
        } else if (newArrayQustions[a].answerCollection[i].answerFormula) {
          delete newArrayQustions[a].answerCollection[i].content
          delete newArrayQustions[a].answerCollection[i].answerImage
        }
      }
    }
    return newArrayQustions
  }

  const deleteQuestionsIds = (arrayQuestions) => {
    arrayQuestions.forEach((question) => {
      //asignar solo los ids de las video respuestas
      if (question.explanationVideo.length > 0) {
        let videoIds = []
        for (let i = 0; i < question.explanationVideo.length; i++) {
          videoIds.push(question.explanationVideo[i]._id)
        }
        question.explanationVideo = videoIds
      }
      //asignar solo el id de la imgen question
      if (question.questionImage) {
        question.questionImage = question.questionImage._id
      }
      //borrar el id por default
      if (question._id.length < 16) {
        delete question._id
      }
    })
    return arrayQuestions
  }

  return (
    <QuizContext.Provider
      value={{
        saved: state.saved,
        _idQuiz: state._idQuiz,
        setSavedQuiz,
        quizInfo: state.quizInfo,
        getQuizInfo,
        playlistInfo: state.playlistInfo,
        getPlaylistInfo,
        courseInfo: state.courseInfo,
        getCourseInfo,
        onChangeInputs,
        addQuestion,
        deleteQuestion,
        handleOnChangeQuestion,
        addQuestionFormula,
        deleteQuestionFormula,
        addImageQuestion,
        addAnswer,
        deleteAnswer,
        handleChangeAnswer,
        onChangeCheckbox,
        addAnswerFormula,
        deleteAnswerFormula,
        addAnswerImage,
        validations,
        //videos
        saveVideosQuestion,
        deleteVideoQuestion,
        //reorder
        reorderQuestions,
        //PAGINATION
        setSkipNumber,
        skipNumber: state.skipNumber,
        loading: state.loading,
        restartValues,
        disable: state.disable,
      }}
    >
      {props.children}
    </QuizContext.Provider>
  )
}
export default QuizState
