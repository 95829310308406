import React, { useContext } from "react";
import moment from "moment";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";
import LoadingComponent from "../../../helpers/LoadingComponent";
import EmptyCard from "../../../helpers/Fichas/emptyCard";
import statusTable from "../../../helpers/Tables/statusTable";
import SortTable from "../../../helpers/Tables/sortTable";
import EnrollmentContext from "../../../context/EnrollmentContext/EnrollmentContext";

import styles from "./index.module.scss";

const StepCourse = ({ typeComponent }) => {
  //importamos el context y sus estados y funciones
  const enrollmentContext = useContext(EnrollmentContext);
  const {
    activeTab,
    setTabValue,
    coursesMedia,
    coursesSuperior,
    selectedCourse,
    selectCourse,
  } = enrollmentContext;

  //funcion para el cambio entre tabs
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setTabValue(tab);
    }
  };

  //Funcion que enumera las filas de la lista
  const generaNum = (cell, row, rowIndex) => {
    if (row) {
      return rowIndex + 1;
    } else {
      return "";
    }
  };
  //guarda el curso seleccionado
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      selectCourse(row);
    },
  };

  //pinta el row previamente selecionado
  const rowColor = (cell, row) => {
    let rowStyle = { backgroundColor: "#D0D3D4" };
    if (selectedCourse._id === cell._id) {
      return rowStyle;
    }
  };

  const deleteStatusOff = (data) => {
    let filtered = data
      ? data.filter((data) => {
          return data.active;
        })
      : [];

    return filtered.reverse();
  };

  const headerSortingStyle = { backgroundColor: "#D6DBDF" };
  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: generaNum,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30px", textAlign: "center" };
      },
      headerSortingStyle,
      align: "center",
    },
    {
      dataField: "name",
      text: "Cursos",
      sort: true,
      headerAlign: "center",
      align: "center",
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerSortingStyle,
    },
    {
      dataField: "active",
      text: "Estatus",
      headerAlign: "center",
      align: "center",
      formatter: (cell) => statusTable(cell),
    },
  ];

  const columnsSuperior = [
    {
      dataField: "",
      text: "#",
      formatter: generaNum,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30px", textAlign: "center" };
      },
      headerSortingStyle,
      align: "center",
    },
    {
      dataField: "name",
      text: "Cursos",
      sort: true,
      headerAlign: "center",
      align: "center",
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerSortingStyle,
    },
    {
      dataField: "active",
      text: "Estatus",
      headerAlign: "center",
      align: "center",
      formatter: (cell) => statusTable(cell),
    },
  ];

  if (typeComponent === "talks") {
    columns.pop();
    columnsSuperior.pop();
  }

  return (
    <div className="tabs tabs--justify tabs--bordered-top">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Media Superior
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Superior
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-1">
          <TabPane tabId="1">
            <Col
              sm={12}
              md={12}
              className={styles.tables_course_main_container}
            >
              {coursesMedia.length > 0 ? (
                <div>
                  <BootstrapTable
                    keyField="_id"
                    data={deleteStatusOff(coursesMedia)}
                    columns={columns}
                    bordered={false}
                    rowEvents={rowEvents}
                    rowStyle={rowColor}
                    noDataIndication={
                      deleteStatusOff(coursesMedia).length <= 0 ? (
                        <EmptyCard message="No hay cursos disponibles.." />
                      ) : (
                        ""
                      )
                    }
                  />
                </div>
              ) : (
                <LoadingComponent />
              )}
            </Col>
          </TabPane>

          <TabPane tabId="2">
            <Col
              sm={12}
              md={12}
              className={styles.tables_course_main_container}
            >
              {coursesSuperior.length > 0 ? (
                <div>
                  <BootstrapTable
                    keyField="_id"
                    data={deleteStatusOff(coursesSuperior)}
                    columns={columnsSuperior}
                    bordered={false}
                    rowEvents={rowEvents}
                    rowStyle={rowColor}
                    noDataIndication={
                      deleteStatusOff(coursesSuperior).length <= 0 ? (
                        <EmptyCard message="No hay cursos disponibles.." />
                      ) : (
                        ""
                      )
                    }
                  />
                </div>
              ) : (
                <LoadingComponent />
              )}
            </Col>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};
export default StepCourse;
