import ShowForm from "./showForm";
import ShowArrayFolios from "./showArrayFolios";

const Folios = ({
  actualStudent,
  setFolio,
  editFoliio,
  deleteFolio,
}) => {
  return (
    <div>
      <section>
        <ShowForm setFolio={setFolio} />
      </section>
      <section>
        {actualStudent.materialFolios &&
        actualStudent.materialFolios.length > 0 ? (
          <ShowArrayFolios
            arrayFoliosObj={actualStudent.materialFolios}
            editFoliio={editFoliio}
            deleteFolio={deleteFolio}
          />
        ) : (
          <h4>No hay folios registrados</h4>
        )}
      </section>
    </div>
  );
};

export default Folios;
