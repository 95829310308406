import React, { useState, useContext } from "react";
import { Container, Col, Button, Row } from "reactstrap";
import { Modal } from "react-responsive-modal";

import StudentsContext from "../../../../context/students/StudentsContext";
import CardGroup from "./components/cardGroup";

import SelectorGroup from "../../../../helpers/selectorGroup/index";
import LoadingComponent from "../../../../helpers/LoadingComponent";

import styles from "./components/index.module.scss";

const GroupChange = (props) => {
  //creamos el context
  const studentsContext = useContext(StudentsContext);
  const { changeStudentGroup, getGroupInfo } = studentsContext;

  const [newGroup, setNewGroup] = useState("");
  const [openSelector, setOpenSelector] = useState(false);
  const [loadingNewGroup, setLoadingNewGroup] = useState(false);

  const closeModal = () => {
    props.close();
  };

  //muestra el boton de seleccionar grupo
  const showSelection = () => {
    return (
      <>
        <h4 className="mb-1">Selecciona el nuevo grupo</h4>
        <Button color="success" size="sm" onClick={() => setOpenSelector(true)}>
          Seleccionar grupo
        </Button>
      </>
    );
  };

  //guarda el grupo seleccionado y cierra el modal
  const saveNewGroup = (group) => {
    setLoadingNewGroup(true);
    setNewGroup(group);
    setOpenSelector(false);
    let groupInfo = getGroupInfo(group._id);
    groupInfo.then((result) => {
      setNewGroup(result.group);
      setLoadingNewGroup(false);
    });
  };

  const change = () => {
    //funcion que cambia el grupo recibe el id del grupo actual y el id del nuevo grupo
    let changeGroup = changeStudentGroup(props.groupChange._id, newGroup._id);
    changeGroup.then((result) => {
      if (result.success) closeModal();
    });
  };

  return (
    <Modal
      open={props.valueModal}
      onClose={closeModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container>
        <Row>
          <Col xs={12} md={7}>
            <h3>Cambio de grupo</h3>
          </Col>
          <Col xs={12} md={5}>
            {newGroup !== "" ? (
              <Button
                color="success"
                size="sm"
                className="btn float-right"
                onClick={() => setOpenSelector(true)}
              >
                Seleccionar grupo
              </Button>
            ) : null}
            <Button
              size="sm"
              className="btn btn-secondary float-right mr-2"
              onClick={() => closeModal()}
            >
              <p>Salir</p>
            </Button>
          </Col>

          <Col xs={12} md={5}>
            <CardGroup groupChange={props.groupChange} typeGroup={""} />
          </Col>
          <Col xs={12} md={2} className="text-center">
            <div>
              <i className={`material-icons ${styles.icon_change_group}`}>
                arrow_forward
              </i>
              <i className={`material-icons ${styles.icon_arrow}`}>
                arrow_downward
              </i>
            </div>
            {newGroup !== "" ? (
              <Button
                color="success"
                size="sm"
                className="btn mt-2"
                disabled={loadingNewGroup}
                onClick={() => change()}
              >
                Cambiar
              </Button>
            ) : null}
          </Col>
          <Col xs={12} md={5}>
            {newGroup !== "" ? (
              loadingNewGroup ? (
                <LoadingComponent />
              ) : (
                <CardGroup groupChange={newGroup} typeGroup={2} />
              )
            ) : (
              showSelection()
            )}
          </Col>
        </Row>
      </Container>
      {openSelector ? (
        <SelectorGroup
          valueModal={openSelector}
          close={setOpenSelector}
          saveNewGroup={saveNewGroup}
          newGroup={newGroup}
          oldIdGroup={props.groupChange._id}
        />
      ) : null}
    </Modal>
  );
};
export default GroupChange;
