import React, { useEffect, useContext, useState } from 'react';
import LoadingComponent from "../../../../helpers/LoadingComponent";
import CollapseComponent from "../../../../helpers/collapse/Collapse";
import { GroupsContext } from '../../../../context/groups';
import TableGroups from '../table';

const SubCourses = ({ id }) => {

  //context
  const groupsContext = useContext(GroupsContext);
  const { actualGroup, getChildsinfo, changeStatusGroup } = groupsContext;

  const [buckets, setBuckets] = useState([])
  const [childsCourses, setChildsCourses] = useState([])
  const [loadingBuckets, setLoadingBuckets] = useState(false)

  useEffect(() => {
    setLoadingBuckets(true)
    getChildsinfo(id).then(
      result => {
        if (result.success) {
          const childsData = [];
          const map = new Map();
          for (const item of result.group.buckets) {
            if (!map.has(item.course._id)) {
              map.set(item.course._id, true);
              childsData.push({
                _id: item.course._id,
                name: item.course.name
              });
            }
          }
          setBuckets(result.group.buckets);
          setChildsCourses(childsData);
          setLoadingBuckets(false);
        }
      }
    )
      .catch(
        err => {
          console.log("Error:", err);
        }
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const index = buckets.findIndex((element) => String(element._id) === String(actualGroup._id))
    if (index >= 0) {
      buckets[index] = actualGroup;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualGroup])

  const getCourseGroups = (idCourse) => {
    let groupsByCourse = buckets.filter(dataGroup => {
      if (dataGroup.course._id === idCourse) {
        return true
      }
      return false
    })

    return groupsByCourse
  }
  const updateStatus = (idGroup) => {
    const indexGroup = buckets.findIndex(item => String(item._id) === String(idGroup))
    if (indexGroup >= 0) {
      const newBuckets = [...buckets];
      newBuckets[indexGroup].active = !newBuckets[indexGroup].active
      setBuckets(newBuckets)
      changeStatusGroup(idGroup)
    }
  }

  return (
    loadingBuckets ? <LoadingComponent /> :
      childsCourses.map((value, index) => {
        return (
          <CollapseComponent title={value.name} className="with-shadow" id={value._id} key={index} iconType="plusMinus"
            children={
              <TableGroups
                groupsData={getCourseGroups(value._id)}
                columnsType="childs"
                isChildTable={true}
                changeStatusGroup={updateStatus}
              />}

          />
        )
      })
  )
}
export default SubCourses