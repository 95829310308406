import { fetchAsync, urlVideoComments } from "helpers/Globals/globals";

const actions = (state, dispatch) => ({
  getComments: async (word) => {
    const skip = state.paginationSize * (state.paginationComments - 1);
    const limit = state.paginationSize;
    try {
      const payload = await fetchAsync(
        `${urlVideoComments}?skip=${skip}&limit=${limit}${word ? `&words=${word}` : ""}`,
        "",
        "GET",
        ""
      );
      if (payload.success) {
        dispatch({ type: "GET_COMMENTS", payload });
      } else {
        console.error('Error al obtener comentarios:', payload);
        dispatch({ type: "ERROR", payload });
      }
    } catch (error) {
      console.error('Error en fetchAsync:', error);
      dispatch({ type: "ERROR", payload: { error } });
    }
  },

  onChangePagination: (page) => {
    dispatch({ type: "ON_CHANGE_PAGINATION",payload:{page} });
  },

  onShowSizeChange: (page, size) => {
    dispatch({ type: "ON_SHOW_SIZE", payload:{size} });
  },

  onDelete: async (idComment) => {
    state.onSucess = false;
    try {
      const payload = await fetchAsync(`${urlVideoComments}${idComment}`, "", "DELETE", "");
      if (payload.success) {
        if (state.comments.length === 1) {
          actions(state, dispatch).getComments();
        } else {
          actions(state, dispatch).getComments(state.badWord);
        }
      } else {
        console.error('Error al eliminar comentario:', payload);
        dispatch({ type: "ERROR", payload });
      }
    } catch (error) {
      console.error('Error en fetchAsync:', error);
      dispatch({ type: "ERROR", payload: { error } });
    }
  },

  onSearch: (word) => {
    dispatch({ type: "ON_SEARCH", payload:{word} });
  },
  onchange: ({target}) => {
    if( target.value ===''){
      dispatch({ type: "ON_SEARCH", payload:{word:''} });
    }
  },

  holaMundo: () => {
    console.log("hola mundoo context");
  },
});

export default actions;
