import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import SortTable from '../../Tables/sortTable';
import EmptyComponent from '../../Empty/EmptyComponent';
import { newSchedules } from '../../Globals/funciones';
import SetFechaTable from '../../Tables/fechaTables';

const TableGroup = (props) => {

  //maneja el click en la tabla
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log(row);
      props.saveNewGroup(row)
    }
  };

  //pinta el row previamente selecionado
  const rowColor = (cell, row) => {
    let rowStyle = { backgroundColor: '#CACFD2' };
    if (props.newGroup === cell._id) {
      return rowStyle
    }
  }

  //pinta los dias y horarios
  const diasHoras = (cell, row) => {
    return newSchedules(cell)
  }

  const fecha = (cell, row) => {
    return SetFechaTable(cell)
  }

  const showLocation = (cell, row) => {
    if (cell !== undefined) {
      return cell
    } else {
      return "sin asignar"
    }
  }

  const headerSortingStyle = { backgroundColor: '#D6DBDF' };
  const columns = [
    {
      dataField: 'name',
      text: 'Grupo',
      sort: true,
      headerAlign: 'center',
      align: 'center',
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      headerSortingStyle,
    },
    {
      dataField: 'schedules',
      text: 'Día(s) y horarios',
      formatter: diasHoras,
      headerAlign: 'center',
      csvText: 'Día(s)',
      editable: false,
      style: {
        textAlign: "center",
        verticalAlign: 'middle',
      },
    },
    {
      dataField: 'startDate',
      text: 'Inicia',
      sort: true,
      headerAlign: 'center',
      formatter: fecha,
      headerSortingStyle,
      editable: false,
      style: {
        textAlign: "center",
        verticalAlign: 'middle',
      },
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },

    },
    {
      dataField: 'locationInfo.name',
      text: 'Sede',
      sort: true,
      headerAlign: 'center',
      headerSortingStyle,
      editable: false,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      style: {
        textAlign: "center",
        verticalAlign: 'middle',
      },
      formatter: showLocation,
    },
  ];


  return (
    <BootstrapTable
      keyField={"_id"}
      data={props.groups}
      columns={columns}
      rowEvents={rowEvents}
      rowStyle={rowColor}
      hover
      bordered={false}
      noDataIndication={<EmptyComponent message={"No hay grupos disponibles, elige otro curso o modifica rango de fechas."} />}
    />
  );
}
export default TableGroup
