import React, { useContext } from "react";
import { Divider, Switch } from "antd";
import MessagesContext from "../context/messagesContext";

import styles from "./index.module.scss";

const OptionsTypesMessages = () => {
  const context = useContext(MessagesContext);

  const {
    sendEmail,
    sendSms,
    onChangeEmailStatus,
    onChangeSmsStatus,
  } = context;

  const onChangeEmail = (checked) => {
    console.log(`switch to ${checked}`);
    onChangeEmailStatus(checked);
  };

  const onChangeSms = (checked) => {
    console.log(`switch to ${checked}`);
    onChangeSmsStatus(checked);
  };

  return (
    <>
      <Divider orientation="left">Tipos de mensajes</Divider>
      <div className={styles.options_types_messages_container}>
        <Switch
          checkedChildren="Enviar email"
          unCheckedChildren="No enviar email"
          checked={sendEmail}
          onChange={onChangeEmail}
        />
        <Switch
          checkedChildren="Enviar sms"
          unCheckedChildren="No enviar sms"
          checked={sendSms}
          onChange={onChangeSms}
        />
      </div>
    </>
  );
};

export default OptionsTypesMessages;
