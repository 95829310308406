import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Checkbox, DatePicker } from "antd";
import moment from "moment";
import "moment/locale/es";

import { showMessageWarning } from "../../../../helpers/MessageAndNotificationUtils";
import DeleteButtonConfirm from "../../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";
import { Modal } from "react-responsive-modal";
import RoleSelector from "../roleSelector";
import Can from "../../../../helpers/Secure/Can";
import PropTypes from "prop-types";

import Header from "./Header";
import { registerEmployee } from "./Functions";
import locale from "antd/es/date-picker/locale/es_ES";
import ModalConfirmation from "../../../Modal/ModalConfirmation";
import { EmployeeContext } from "../context/EmployeeContext";

import EyeIcon from "mdi-react/EyeIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import MailRuIcon from "mdi-react/MailRuIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import NumbersIcon from "mdi-react/NumbersIcon";
import AddressMarkerIcon from "mdi-react/AddressMarkerIcon";
import BlackboardIcon from "mdi-react/BlackboardIcon";

import styles from "./index.module.scss";
import "antd/es/checkbox/style/index.css";
import "antd/es/date-picker/style/index.css";

const dateFormat = "DD/MM/YYYY";
let closeLoading = "";
const inicialState = {
  name: "",
  lastName: "",
  secondLastName: "",
  roles: [],
  isProfessor: false,
  email: "",
  password: "",
  phoneNumber: "",
  secondPhoneNumber: "",
  address: "",
  postalCode: "",
  birthDate: moment.utc(new Date()).local().format(),
};

function AddEditEmployee(props) {
  const {
    actualEmployee,
    allRoles,
    setActualEmployee,
    editEmployees,
    registerEmployees,
  } = useContext(EmployeeContext);

  const { valueAddModal, openAddModal, title, edit } = props;

  const [employee, setEmployee] = useState(inicialState);
  const [showPassword, setShowPassword] = useState(false);
  const [arrayRoles, setArrayRoles] = useState([]);
  const [disable, setDisable] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  //effect para editar empleado
  useEffect(() => {
    if (edit) {
      setEmployee({
        name: actualEmployee.name,
        lastName: actualEmployee.lastName,
        secondLastName: actualEmployee.secondLastName,
        roles: actualEmployee.roles,
        email: actualEmployee.email,
        birthDate: moment.utc(actualEmployee.birthDate).local().format(),
        address:
          actualEmployee.address !== undefined ? actualEmployee.address : "",
        phoneNumber: actualEmployee.phoneNumber,
        secondPhoneNumber: actualEmployee.secondPhoneNumber,
        postalCode:
          actualEmployee.postalCode !== undefined
            ? actualEmployee.postalCode
            : "",
        isProfessor: actualEmployee.isProfessor,
      });
      rolesEmployee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //obtiene los roles actuales al editar empleado
  const rolesEmployee = () => {
    let fullRoles = [];
    for (let a = 0; a < actualEmployee.roles.length; a++) {
      let found = allRoles.find(
        (element) => element._id === actualEmployee.roles[a]
      );
      if (found !== undefined) {
        fullRoles.push(found._id + "/" + found.name);
      }
    }
    setArrayRoles(fullRoles);
  };

  const onCloseModal = () => {
    setShowPassword(false);
    setEmployee(inicialState);
    setArrayRoles([]);
    openAddModal(false);

    if (closeLoading !== "") {
      closeLoading();
    }
  };

  const onChamgeInputs = (e) => {
    setEmployee({
      ...employee,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckBox = (e) => {
    setEmployee({
      ...employee,
      isProfessor: e.target.checked,
    });
  };

  //muestra y coulta el password
  const showPasswordField = () => {
    setShowPassword(!showPassword);
  };

  const onChangeDate = (date, dateString) => {
    // console.log(date);
    // console.log(dateString);
    let local = moment.utc(date).local().format();
    //console.log(local);
    setEmployee({
      ...employee,
      birthDate: local,
    });
  };

  //eliminar un id del array de role ids
  const deleteIdList = (idBorrar) => {
    let myArray = arrayRoles.filter((obj) => {
      return obj !== idBorrar;
    });
    setArrayRoles(myArray);
  };

  //genera la lista de roles a pintar en el form
  const generaLista = () => {
    let array = arrayRoles.slice();

    return array.map((value, index) => {
      let name = value.split("/")[1];
      let id = value.split("/")[0];
      return (
        <Row key={id} className="mb-2">
          <Col xs={7} sm={7}>
            <span className="mr-2">{name}</span>
          </Col>
          <Col xs={4} sm={4}>
            <div className="float-right" onClick={() => deleteIdList(value)}>
              <i className="material-icons deleteIcon">delete</i>
            </div>
          </Col>
        </Row>
      );
    });
  };

  //agrega ids al array de roles
  const selectorAddId = (id) => {
    let existe = arrayRoles.findIndex((x) => x === id);
    let array = [];
    if (existe === -1) {
      array.push(id);
      setArrayRoles(arrayRoles.concat(array));
    } else {
      showMessageWarning(
        <strong>Atención! Ya esta agregado el role</strong>,
        2
      );
    }
  };
  //guarda loa daos al registrar o editar
  const saveEmployee = () => {
    registerEmployee(
      employee,
      setDisable,
      registerEmployees,
      editEmployees,
      onCloseModal,
      arrayRoles,
      edit,
      setActualEmployee
    );
  };

  return (
    <Modal
      open={valueAddModal}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container>
        <Row className={styles.modal_addEdit_container}>
          <Col xs={12}>
            <Header
              onCloseModal={onCloseModal}
              registerEmployee={saveEmployee}
              disable={disable}
              title={title}
            />
          </Col>
          <Col sm="12">
            <form>
              <div className="form__form-group">
                <span className="form__form-group-label">Nombre</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    autoComplete="new-password"
                    value={employee.name}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Apellido paterno</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    autoComplete="new-password"
                    value={employee.lastName}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Apellido materno</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="secondLastName"
                    name="secondLastName"
                    autoComplete="new-password"
                    value={employee.secondLastName}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Rol/Roles</span>
                <div className="ml-4 mr-3">{generaLista()}</div>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <RoleSelector
                    handleSelectChange={selectorAddId}
                    idActual={employee.role}
                  />
                </div>
              </div>
              <div className="form__form-group mb-1">
                <span className="form__form-group-label">¿Es Profesor?</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <BlackboardIcon />
                  </div>
                  <Checkbox
                    checked={employee.isProfessor}
                    onChange={onChangeCheckBox}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Correo electrónico
                </span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <MailRuIcon />
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    autoComplete="new-password"
                    placeholder="ejemplo@mail.com"
                    value={employee.email}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              {!edit && (
                <div className="form__form-group">
                  <span className="form__form-group-label">Contraseña</span>
                  <div className="form__form-group-field">
                    <div
                      className={
                        showPassword
                          ? styles.icon_show_password
                          : `form__form-group-icon ${styles.icon_password}`
                      }
                    >
                      <EyeIcon onClick={showPasswordField} />
                    </div>
                    <input
                      className="form-control"
                      name="password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      maxLength="100"
                      value={employee.password}
                      onChange={onChamgeInputs}
                    />
                  </div>
                  <span className="form__form-group-description ml-4">
                    Da Click en el icono para ocultar o mostrar tu Contraseña,
                    esta debe ser entre 5 y 100 digitos.
                  </span>
                </div>
              )}
              <div className="form__form-group">
                <span className="form__form-group-label">Teléfono</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <PhoneIcon />
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    autoComplete="new-password"
                    value={employee.phoneNumber}
                    onChange={onChamgeInputs}
                  />
                </div>
                <span className="form__form-group-description ml-4">
                  Telefono debe incluir como minimo 10 digitos 55 77889966.
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Segundo Teléfono</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <PhoneIcon />
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="secondPhoneNumber"
                    name="secondPhoneNumber"
                    autoComplete="new-password"
                    value={employee.secondPhoneNumber}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Dirección</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AddressMarkerIcon />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    autoComplete="new-password"
                    value={employee.address}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <Row>
                <Col sm={12} md={5}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Código postal
                    </span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <NumbersIcon />
                      </div>
                      <input
                        name="postalCode"
                        id="postalCode"
                        type="number"
                        className="form-control"
                        onChange={onChamgeInputs}
                        value={employee.postalCode}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={7}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Fecha de nacimiento
                    </span>
                    <div className="form__form-group-field">
                      <DatePicker
                        defaultValue={moment("01/01/2020", dateFormat)}
                        value={moment(employee.birthDate)}
                        format={dateFormat}
                        locale={locale}
                        allowClear={false}
                        onChange={onChangeDate}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div>
                {edit && (
                  <Can do="delete" on="/users/">
                    <DeleteButtonConfirm
                      title="¿Deseas eliminar el empleado?"
                      deleteFunction={props.deleteEmployee}
                      id={actualEmployee?._id}
                      onCloseOptionalFunc={props.closeSidebar}
                    />
                  </Can>
                )}
              </div>
            </form>
          </Col>
        </Row>
      </Container>
      {deleteModal && (
        <ModalConfirmation
          closeModal={setDeleteModal}
          valueModal={deleteModal}
          callback={props.deleteEmployee}
          // callback={deleteEmployee}
          value={actualEmployee._id}
          titulo="Confirmación : ¿Deseas eliminar el empleado?"
          closeParent={onCloseModal}
        />
      )}
    </Modal>
  );
}
AddEditEmployee.propTypes = {
  valueAddModal: PropTypes.bool.isRequired,
  openAddModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};
export default AddEditEmployee;
