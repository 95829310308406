import "./Style.css";
import React from "react";
import SetFechaTable from "../../../../helpers/Tables/fechaTables";
import { randomColor } from "../../../../helpers/Fichas/constants";
import { newSchedules } from "../../../../helpers/Globals/funciones";
import { Card, CardBody } from "reactstrap";
import { Avatar, Divider } from "antd";

import styles from "./index.module.scss";
import "antd/es/avatar/style/index.css";

const CardInfo = ({
  studentData,
  groupsArray = [],
  successAttendance,
  openStudent,
  isTalkUser = false,
}) => {
  const itHasDebs = () => {
    const { balance } = studentData;
    let totalCost = balance.costs.total;
    let totalPayment = balance.payments.discounts + balance.payments.total;
    return totalPayment < totalCost ? true : false;
  };

  return (
    <div className="contact-area">
      <div className="contact">
        <div
          className="container_name_avatar"
          onClick={isTalkUser ? null : openStudent}
        >
          <Avatar
            className="avatar_style"
            style={{
              backgroundColor: randomColor(studentData.name[0].toUpperCase()),
            }}
            size={64}
          >
            {studentData.name[0]}
          </Avatar>
          <h4 className="ml-2">{`${studentData.name} ${studentData.lastName} ${studentData.secondLastName}`}</h4>
        </div>
        {isTalkUser && studentData ? (
          <h3 className="my-3">ASISTENTE DE PLATICA INFORMATIVA</h3>
        ) : (
          <>
            <Card>
              {groupsArray &&
                groupsArray.length > 0 &&
                groupsArray.map((data, index) => {
                  return (
                    <CardBody key={index} className={styles.margin_card}>
                      <h3>
                        <b>Curso: </b>
                        {data?.course}
                      </h3>
                      <h3>
                        <b>Sede: </b>
                        {data?.location ? data?.location : "Sin asignar"}
                      </h3>
                      <h3>
                        <b>Salón: </b>
                        {data?.classroom ? data?.classroom : "Sin asignar"}
                      </h3>
                      {isTalkUser ? (
                        ""
                      ) : (
                        <div>
                          <h3>
                            <b>Grupo: </b>
                            {data?.group ? data?.group : ""}
                          </h3>
                          <h3>
                            <b>Fecha de inicio: </b>
                            {data?.startDate
                              ? SetFechaTable(data?.startDate)
                              : ""}
                          </h3>
                          <h3>
                            <b>Dia(s) y Horarios:</b>
                            {data?.schedule
                              ? `${
                                  data.schedule.day
                                } de ${data?.schedule?.startHour.substring(
                                  0,
                                  2
                                )}:${data?.schedule?.startHour.substring(
                                  2,
                                  4
                                )} a ${
                                  data.schedule.day
                                } de ${data?.schedule?.endHour.substring(
                                  0,
                                  2
                                )}:${data?.schedule?.endHour.substring(2, 4)}`
                              : ""}
                          </h3>
                          {groupsArray.length - 1 === index &&
                          itHasDebs() &&
                          !isTalkUser ? (
                            <>
                              <i className="material-icons">error</i>
                              <p className="blink_text">
                                !El alumno tiene adeudos!
                              </p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      <Divider />
                      <Divider />
                    </CardBody>
                  );
                })}
            </Card>
          </>
        )}
      </div>
    </div>
  );
};
export default CardInfo;
