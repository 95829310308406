import React, { useState, useEffect, useContext } from 'react';
import { fetchAsync, urlLiveChannels } from '../../../helpers/Globals/globals'
import { Modal } from 'react-responsive-modal';
import VideoIcon from 'mdi-react/VideoIcon'
import { Col, Row, CardBody, Button, Card, ButtonToolbar } from 'reactstrap';
import { Switch, Select } from 'antd';
import CourseContext from '../../../context/courses/CourseContext';
import "antd/es/switch/style/index.css";

const { Option } = Select;


function ModalEditStreaming({ value, onCloseModal }) {
    const courseContext = useContext(CourseContext);
    const { actualCourse, updateLive } = courseContext;
    const [channel, setChannel] = useState(actualCourse.liveStreamChannel)
    const [urlStream, setUrlStream] = useState(actualCourse.liveStreamUrl)
    const [isActive, setIsActive] = useState(actualCourse.activeLiveStream)

    const [channelsInfo, setChannelsInfo] = useState([])

    //Get info of channel the first time
    useEffect(() => {
        getChannelInfo().then(result => {
            if (result.success) {
                setChannelsInfo(result.channels)
            }
        })
    }, [])


    const getChannelInfo = async () => {
        const result = await fetchAsync(urlLiveChannels, "", "GET")
        return result
    }


    const handleClickUpdate = () => {
        const body = {
            channel: channel,
            url: urlStream,
            active: isActive
        }
        updateLive(body).then(
            () => {
                onCloseModal()
            }
        )
    }

    return (
        <Modal
            open={value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>

            <Card className="addCursoSize mt-0">
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <Row>
                                <Col sm={12} md={5}>
                                    <div className="card__title">
                                        <h4 className="bold-text">Editar transmisión en vivo:</h4>
                                    </div>
                                </Col>
                                <Col sm={12} md={7} className="addButtonsMarg">
                                    <ButtonToolbar className="float-right">
                                        <Button size="sm" className="float-right"
                                            onClick={() => onCloseModal()}>
                                            <p>Salir</p>
                                        </Button>
                                        <Button className="float-right" color="success" size="sm" onClick={handleClickUpdate}>
                                            Actualizar
                                        </Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" md="9">
                            <div className="form__form-group mb-5">
                                <div className="mt-2">
                                    <span className="form__form-group-label">Canal de transmisión</span>
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Selecciona el canal de transmisión"
                                        defaultValue={channel}
                                        onChange={(value) => { setChannel(value) }} >
                                        {channelsInfo !== undefined ? channelsInfo.map((value, index) => {
                                            return <Option key={value._id} value={value._id}>{value.name}</Option>
                                        }) : null
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="form__form-group mb-5">
                                <span className="form__form-group-label">URL de Vimeo </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <VideoIcon />
                                    </div>
                                    <input type="text" className="form-control"
                                        name="url" autoComplete="off"
                                        value={urlStream}
                                        onChange={(e) => setUrlStream(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" md="3" className="text-center">
                            <span className="form__form-group-label">Estatus</span>
                            <br />
                            <Switch style={isActive ? { "background-color": "#de442b" } : null} checked={isActive} onChange={(value) => setIsActive(value)} className="mt-2" checkedChildren="Vivo" />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Modal>
    )
}
export default ModalEditStreaming
