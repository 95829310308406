import React, { useEffect, useState, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import MainPageHeader from "../../helpers/Headers/pageHeader";
import EmptyCard from "../../helpers/Fichas/emptyCard";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import CardDraw from "../../helpers/cards/genericCard";

import SidebarClassroom from "./components/sidebarClassroom";
import AddEditClassroom from "./components/addEditClassroom";

import ClassroomsContext from "../../context/classrooms/ClassroomsContext";

import styles from "./index.module.scss";
import "antd/dist/antd.css";

function Salones(props) {
  const classroomsContext = useContext(ClassroomsContext);
  const {
    classroomsLocation,
    getClassroomsLocation,
    actualClassroom,
    setActualClassroom,
    createClassroom,
    actualError,
  } = classroomsContext;

  const match = useRouteMatch();

  const [openAddmodal, setOpenAddmodal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const { idSede, nameSede } = match.params;

  useEffect(() => {
    getClassroomsLocation(idSede);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSidebar = (classroomInfo) => {
    setActualClassroom(classroomInfo);
    setShowDrawer(true);
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12}>
          <MainPageHeader
            title="Administración Escolar | Sedes |"
            subTitle={`Salones | ${nameSede}`}
            buttonTitle={"Agregar salón"}
            buttonOnClick={() => setOpenAddmodal(true)}
            canActions={["post", "/classrooms/*"]}
            buttonRoute={"/sedes"}
          />
        </Col>
      </Row>
      <Row className={styles.classrooms_cards_box}>
        <Col sm={12} className={showDrawer && styles.cards_container_locations}>
          {actualError?.code == "13" ? (
            <EmptyCard message={actualError?.message?.es} />
          ) : (
            <div>
              <CardDraw
                type="salones"
                data={classroomsLocation}
                ClickOnElement={showSidebar}
                name={nameSede}
                selected={actualClassroom}
              />
            </div>
          )}
        </Col>
      </Row>
      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SidebarClassroom setShowDrawer={setShowDrawer} />
        </SidebarRightComponent>
      )}
      {openAddmodal && (
        <AddEditClassroom
          value={openAddmodal}
          close={setOpenAddmodal}
          idLocation={idSede}
          functionAddEdit={createClassroom}
          title={"Agregar"}
        />
      )}
    </Container>
  );
}
export default Salones;
