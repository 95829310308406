import React, { useState } from "react";
import { Col, Container, Row, Button, ButtonToolbar } from "reactstrap";
import moment from "moment";
import "moment/locale/es";

import { semana } from "../../../../../helpers/Fichas/constants";
import SelectSchedules from "../../../../../helpers/selectSchedules/index";
import SelectClassroomLocation from "../../../../../helpers/classromLocation/index";
import { showMessageWarning } from "../../../../../helpers/MessageAndNotificationUtils";

import { Select, DatePicker, Switch, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";

import ClassIcon from "mdi-react/ClassIcon";
import UsersIcon from "mdi-react/UsersIcon";
import TeacherIcon from "mdi-react/TeacherIcon";
import BuildingIcon from "mdi-react/BuildingIcon";

import styles from "./index.module.scss";
import "antd/lib/date-picker/style/index.css";
import "antd/es/date-picker/style/index.css";
import "antd/es/switch/style/index.css";
import "antd/es/tooltip/style/index.css";
import "antd/es/select/style/index.css";

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;
const instructions =
  "Debes agregar los horarios y la fecha de inicio y finalización antes de seleccionar el salón.";
const instructionsParent =
  'Al crear este tipo de cursos "padre" comipems, no se le asignan salón, cupo, sede ni profesor.';

const StepForm = ({
  newGroupData,
  setNewGroupData,
  selectedCourse,
  teachers,
}) => {
  const [modalSchedules, setModalSchedules] = useState(false);
  const [modalClassroomLocation, setModalClassroomLocation] = useState(false);
  const [locationClassroom, setLocationClassroom] = useState(null);

  //guarda el salón y sede seleccionados
  const saveLocationClassrom = (data) => {
    setLocationClassroom(data);
    setNewGroupData({
      ...newGroupData,
      location: data[0]._id,
      classRoom: data[1]._id,
      quota: data[1].capacity,
    });
  };

  const onChamgeInputs = (e) => {
    setNewGroupData({
      ...newGroupData,
      [e.target.name]: e.target.value,
    });
  };

  //funciones para el calendario
  const onChangeStartdate = (date, dateString) => {
    let local = moment.utc(date).local().format();
    setNewGroupData({
      ...newGroupData,
      startDate: local,
    });
  };

  const onChangeEnddate = (date, dateString) => {
    let local = moment.utc(date).local().format();
    setNewGroupData({
      ...newGroupData,
      endDate: local,
    });
  };

  //funciones para los switch
  const onChangeStatus = (checked) => {
    setNewGroupData({
      ...newGroupData,
      active: checked,
    });
  };

  const onChangeActiveLanding = (checked) => {
    setNewGroupData({
      ...newGroupData,
      activeLanding: checked,
    });
  };

  //agrega el array de schedules
  const getHorarioDias = (array) => {
    if (newGroupData.schedules.length <= 0) {
      let agregar = newGroupData.schedules.concat(array);
      setNewGroupData({
        ...newGroupData,
        schedules: agregar,
      });
    } else {
      newGroupData.schedules.forEach((valor, index) => {
        array.forEach((dato, index) => {
          if (valor.day === dato.day) {
            //valores de hora del estdo horarioDias
            let inicioE = hourFormat(valor.startHour);
            let finalE = hourFormat(valor.endHour);

            //valores de hora del array
            let inicioA = hourFormat(dato.startHour);
            let finalA = hourFormat(dato.endHour);

            if (inicioA < inicioE && finalA < inicioE) {
              let agregar = newGroupData.schedules.concat(array);
              setNewGroupData({
                ...newGroupData,
                schedules: agregar,
              });
            } else if (inicioA > finalE && finalA > finalE) {
              let agregar = newGroupData.schedules.concat(array);
              setNewGroupData({
                ...newGroupData,
                schedules: agregar,
              });
            } else {
              showMessageWarning(
                <strong>
                  ¡Atención! Horario empalmado, revisa los horarios ya
                  agregados.
                </strong>,
                3
              );
              array = [];
              let agregar = newGroupData.schedules.concat(array);
              setNewGroupData({
                ...newGroupData,
                schedules: agregar,
              });
            }
          } else {
            let agregar = newGroupData.schedules.concat(array);
            setNewGroupData({
              ...newGroupData,
              schedules: agregar,
            });
          }
        });
      });
    }
  };

  const showSchedules = () => {
    return newGroupData.schedules.map((data, index) => {
      return (
        <div key={index}>
          <Row id={index}>
            <Col xs={12} sm={3}>
              <span className="mr-2">{semana[data.day]}</span>
            </Col>
            <Col xs={12} sm={7}>
              <span className="mr-2">
                <strong>Inicia:</strong> {hourFormat(data.startHour)}
              </span>
              <span className="mr-2">
                <strong>Termina:</strong> {hourFormat(data.endHour)}
              </span>
            </Col>
            <Col xs={12} sm={2}>
              <div onClick={() => clickIconDelete(index)}>
                <i className="material-icons deleteIcon">delete</i>
              </div>
            </Col>
          </Row>
          <hr></hr>
        </div>
      );
    });
  };

  const hourFormat = (hora) => {
    let uno = hora.slice(0, 2);
    let dos = hora.slice(2, 4);
    return uno + ":" + dos;
  };

  const clickIconDelete = (indice) => {
    let newArray = newGroupData.schedules;
    newArray.splice(indice, 1);
    setNewGroupData({
      ...newGroupData,
      schedules: newArray,
    });
  };

  //muestra la lista de profesores en elselect
  const showTeachers = () => {
    return teachers.map((value) => {
      return (
        <Option key={value._id} value={value.name + "_" + value._id}>
          {value.name} {value.lastName} {value.secondLastName}
        </Option>
      );
    });
  };

  //maneja el evento del selector de profesores
  const handleChangeTeacher = (value) => {
    setNewGroupData({
      ...newGroupData,
      teacher: value.split("_")[1],
    });
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Nombre del grupo</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <ClassIcon />
              </div>
              <input
                className="form-control"
                name="name"
                id="inpuName"
                component="input"
                type="text"
                value={newGroupData.name}
                onChange={onChamgeInputs}
              />
            </div>
            <h4 className="mt-2">Curso elegido: {selectedCourse.name}</h4>
          </div>
          <Row className="mb-2">
            <Col xs={8}>
              <ButtonToolbar>
                <Button
                  color="success"
                  size="sm"
                  onClick={() => setModalSchedules(true)}
                >
                  Agregar horario
                </Button>
              </ButtonToolbar>
            </Col>
            <Col xs={4} className={styles.tooltip_add_group}>
              <Tooltip
                title={
                  selectedCourse.hasOwnProperty("isParent")
                    ? instructionsParent
                    : instructions
                }
              >
                <i
                  // style={{ fontSize: "40px", color: "#f6da6e" }}
                  className="material-icons"
                >
                  info
                </i>
              </Tooltip>
            </Col>
            <Col xs={12}>{showSchedules()}</Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} sm={12} md={6}>
              <p>Fecha de inicio</p>
              <DatePicker
                defaultValue={moment("01/01/2020", dateFormat)}
                value={moment(newGroupData.startDate)}
                format={dateFormat}
                locale={locale}
                allowClear={false}
                onChange={onChangeStartdate}
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <p>Fecha de finalización</p>
              <DatePicker
                defaultValue={moment("01/01/2020", dateFormat)}
                value={moment(newGroupData.endDate)}
                format={dateFormat}
                locale={locale}
                allowClear={false}
                onChange={onChangeEnddate}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} className="mt-1 mb-1">
              <ButtonToolbar>
                <Button
                  color="success"
                  size="sm"
                  disabled={
                    selectedCourse.hasOwnProperty("isParent") ||
                    newGroupData.schedules.length === 0
                      ? true
                      : false
                  }
                  onClick={() => setModalClassroomLocation(true)}
                >
                  <p>Agregar salón</p>
                </Button>
              </ButtonToolbar>
            </Col>
            <Col sm={12} md={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Cupo del grupo</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <UsersIcon />
                  </div>
                  <input
                    disabled={
                      selectedCourse.hasOwnProperty("isParent") ? true : false
                    }
                    id="capacity"
                    type="number"
                    name="quota"
                    className="form-control"
                    style={{ width: "100px" }}
                    onChange={onChamgeInputs}
                    value={newGroupData.quota}
                  />
                </div>
              </div>
            </Col>
            <Col sm={6} md={3} className="mt-2">
              <div className="form__form-group">
                <span className="form__form-group-label">Estatus</span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Switch
                        defaultChecked={newGroupData.active}
                        onChange={onChangeStatus}
                        style={
                          newGroupData.active
                            ? { backgroundColor: "#2ECC71" }
                            : { backgroundColor: "#E74C3C" }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} md={3} className="mt-2">
              <div className="form__form-group">
                <span className="form__form-group-label">Externos</span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Switch
                        defaultChecked={newGroupData.activeLanding}
                        onChange={onChangeActiveLanding}
                        style={
                          newGroupData.activeLanding
                            ? { backgroundColor: "#2ECC71" }
                            : { backgroundColor: "#E74C3C" }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={7}>
              <div className="form__form-group">
                <span className="form__form-group-label">Sede</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <BuildingIcon />
                  </div>
                  <input
                    disabled
                    id="sede"
                    type="text"
                    className="form-control"
                    style={{ width: "100px" }}
                    value={
                      locationClassroom !== null
                        ? locationClassroom[0].name
                        : ""
                    }
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <div className="form__form-group">
                <span className="form__form-group-label">Salón</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <TeacherIcon />
                  </div>
                  <input
                    disabled
                    id="salon"
                    type="text"
                    className="form-control"
                    style={{ width: "100px" }}
                    value={
                      locationClassroom !== null
                        ? locationClassroom[1].name
                        : ""
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <Select
                disabled={
                  selectedCourse.hasOwnProperty("isParent") ? true : false
                }
                showSearch
                style={{ width: 230 }}
                placeholder="Asignar un Profesor"
                onChange={handleChangeTeacher}
              >
                {showTeachers()}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      {modalSchedules ? (
        <SelectSchedules
          valueModal={modalSchedules}
          close={setModalSchedules}
          getHorarioDias={getHorarioDias}
        />
      ) : null}

      {modalClassroomLocation ? (
        <SelectClassroomLocation
          value={modalClassroomLocation}
          close={setModalClassroomLocation}
          saveInfo={saveLocationClassrom}
          filterData={newGroupData}
        />
      ) : null}
    </Container>
  );
};
export default StepForm;
