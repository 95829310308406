import React from "react";

const SuccessError = (props) => {
  const type = props.type ? props.type : "success";
  if (type === "success")
    return (
      <svg
        className="success_error success"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="success_error_circle_success"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="success_error_check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
          strokeLinecap="round"
        />
      </svg>
    );
  else
    return (
      <svg
        className="success_error error"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="success_error_circle_error"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="success_error_check"
          strokeLinecap="round"
          fill="none"
          d="M16 16 36 36 M36 16 16 36"
        />
      </svg>
    );
};
export default SuccessError;
