import React, { useContext, useState } from "react";
import { Col, Row, Button, Container } from "reactstrap";
import { NavLink } from "react-router-dom";

import EmptyCard from "../../../helpers/Fichas/emptyCard";
import LoadingComponent from "../../../helpers/LoadingComponent";
import Can from "../../../helpers/Secure/Can";

import MyMapComponent from "./mapSidebar";
import AddEditLocation from "./addEditLocation";

import LocationsContext from "../../../context/locations/LocationsContext";

import styles from "./index.module.scss";

const SidebarLocation = ({ onClosSidebar }) => {
  const locationsContext = useContext(LocationsContext);
  const {
    actualLocation,
    classroomsLocation,
    emptyClassroms,
  } = locationsContext;
  let {
    name,
    street,
    number,
    colonia,
    latitude,
    longitude,
    district,
    postalCode,
    opening,
    closing,
    _id,
  } = actualLocation;

  const [openEditLocation, setOpenEditLocation] = useState(false);

  //genera la lista de salones para pintar en la ficha
  const generaLista = () => {
    return classroomsLocation.length <= 0 ? (
      <LoadingComponent />
    ) : (
      classroomsLocation.map((data, index) => {
        return (
          <li key={data._id} className="nombresLista">
            <span className="mr-2">{index + 1} </span>
            {data.name}
            <hr></hr>
          </li>
        );
      })
    );
  };

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="mt-1 mb-2">
            <p className={styles.name_location}> {name}</p>
            <p className={styles.info_data}>Dirección</p>
            <p>
              Calle {street} # {number}
            </p>
            <p>Colonia {colonia != null ? colonia : district}</p>
            <p>Cp: {postalCode}</p>
          </div>
        </Col>

        <Col xs="12">
          <Can do="put" on="/locations/">
            <Button
              size="sm"
              className="icon square"
              type="button"
              color="success"
              outline
              onClick={() => setOpenEditLocation(true)}
            >
              <p>
                <i className="material-icons">edit</i>Editar
              </p>
            </Button>
          </Can>
          <Can do="get" on="/classrooms/location/*">
            <NavLink to={"sedes/salones/" + _id + "/" + name}>
              <Button
                size="sm"
                color="success"
                type="button"
                outline
                className="icon square"
              >
                <p>Salones</p>
              </Button>
            </NavLink>
          </Can>
        </Col>

        <Col xs="12">
          <MyMapComponent
            isMarkerShown
            latitud={latitude}
            longitud={longitude}
            name={name}
            street={street}
          />
        </Col>

        <Col xs="12">
          <div className="my-3">
            <p className={styles.info_data}>Horario</p>
            <p>
              {opening}
              <strong> - </strong> {closing}
            </p>
          </div>
        </Col>

        <Col xs="12">
          <p className={styles.info_data}>
            Salones {classroomsLocation.length}
          </p>
          {emptyClassroms ? (
            <EmptyCard message="No hay salones creados." />
          ) : (
            <ul>{generaLista()}</ul>
          )}
        </Col>
      </Row>
      {openEditLocation ? (
        <AddEditLocation
          value={openEditLocation}
          close={setOpenEditLocation}
          edit={true}
          killSideBar={onClosSidebar}
        />
      ) : null}
    </Container>
  );
};
export default SidebarLocation;
