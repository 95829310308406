import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import TableStudents from "./components/table";
import SideBarStudents from "./components/sideBarStudents";
import SearchAndPagination from "./components/searchAndPagination";
import LoadingComponent from "../../helpers/LoadingComponent";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";

import StudentsContext from "../../context/students/StudentsContext";

import "antd/lib/drawer/style/index.css";
import styles from "./index.module.scss";

function Students() {
  const [showDrawer, setShowDrawer] = useState(false);

  const studentsContext = useContext(StudentsContext);
  const {
    allStudents,
    getStudents,
    loadingTable,
    actualStudent,
    setSudentInfo,
    getStudentInfo,
    getPaymentsStudent,
    getGroupsInfo,
  } = studentsContext;

  useEffect(() => {
    getStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={styles.main_container_students}>
      <Row>
        <Col xs={12}>
          <MainPageHeader title="Gestión de Usuarios |" subTitle={`Alumnos`} />
        </Col>
        <Col xs="12">
          <SearchAndPagination />
        </Col>

        <Col xs={12} className={styles.content_table_conatiner}>
          {loadingTable ? (
            <LoadingComponent />
          ) : (
            <TableStudents
              actualStudent={actualStudent}
              data={allStudents}
              showDrawer={showDrawer}
              setShowDrawer={setShowDrawer}
              setSudentInfo={setSudentInfo}
              getStudentInfo={getStudentInfo}
              getPaymentsStudent={getPaymentsStudent}
              getGroupsInfo={getGroupsInfo}
            />
          )}
        </Col>
      </Row>
      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SideBarStudents setShowDrawer={setShowDrawer} />
        </SidebarRightComponent>
      )}
    </Container>
  );
}
export default Students;
