import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Button, ButtonToolbar } from "reactstrap";
import { Modal } from "react-responsive-modal";

import LocationsContext from "context/locations/LocationsContext";
import { getAdgress, ApiKey } from "../../../helpers/Fichas/constants";

import { LoadScriptNext, GoogleMap, Marker } from "@react-google-maps/api";
import styles from "./index.module.scss";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const mapOptions = {
  fullscreenControl: true,
  mapTypeControl: false,
};

const MapaModal = (props) => {
  const locationsContext = useContext(LocationsContext);
  const { actualLocation } = locationsContext;
  const { latitude, longitude } = actualLocation;
  const [actualAdress, setActualAdress] = useState();
  const [mapPosition, setMapPosition] = useState({
    lat: Number(latitude),
    lng: Number(longitude),
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: Number(latitude),
    lng: Number(longitude),
  });

  useEffect(() => {
    adressString(markerPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    props.openAddModal(false);
  };

  const adressString = (jsonLatLong) => {
    setMarkerPosition(jsonLatLong);
    setMapPosition(jsonLatLong);
    fetch(
      getAdgress + jsonLatLong.lat + "," + jsonLatLong.lng + "&key=" + ApiKey
    )
      .then((data) => data.json())
      .then((data) => {
        // console.log(data);
        setActualAdress(data.results[0]);
      });
  };

  return (
    <Modal
      open={props.valueAddModal}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Row>
        <Col sm={12} md={6}>
          <h4>Arrastra el puntero rojo para agregar una dirección</h4>
        </Col>
        <Col sm={12} md={6} className="">
          <ButtonToolbar>
            <Button size="sm" onClick={() => onCloseModal()}>
              <p>Salir</p>
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() =>
                props.getAdressInfo(
                  actualAdress,
                  markerPosition.lat,
                  markerPosition.lng
                )
              }
            >
              <p>Guardar ubicación</p>
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
      <div className={styles.address_text}>
        <p>{actualAdress && actualAdress.formatted_address}</p>
      </div>

      <section className={styles.map_modal_container}>
        <LoadScriptNext
          googleMapsApiKey={ApiKey}
          onError={() => console.log("ERROR")}
        >
          <GoogleMap
            zoom={15}
            options={mapOptions}
            mapContainerStyle={mapContainerStyle}
            center={mapPosition}
          >
            <Marker
              position={markerPosition}
              draggable={true}
              onDragEnd={({ latLng }) => {
                adressString(latLng.toJSON());
              }}
            ></Marker>
          </GoogleMap>
        </LoadScriptNext>
      </section>
    </Modal>
  );
};
export default MapaModal;
