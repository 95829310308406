import React, { useState, useEffect } from 'react';
import { Col, ButtonToolbar, Button, Row } from 'reactstrap';
import { fetchAsync, urlVideo } from "../../../helpers/Globals/globals"
import { Modal } from 'react-responsive-modal';
import { showMessageLoading, showMessageError } from '../../../helpers/MessageAndNotificationUtils';

let closeLoading;

const EditVideo = ({ valueModal, close, updateContent, videoInfo }) => {
  // const EditVideo = ({ valueModal, close, titleVideo,
  //   descriptionVideo, idVideo, updateContent }) => {

  const [description, setDescription] = useState("")
  const [title, setTitle] = useState("")

  useEffect(() => {
    setTitle(videoInfo.title);
    setDescription(videoInfo.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseModal = () => {
    close(false);
    setDescription("");
    setTitle("");
  }

  const numCaracteres = () => {

    if (description.length >= 200) {
      return (<p className="pull-right contadorCaracteres">Limite de 200 caracteres alcanzado.</p>)
    } else {
      return (<p className="pull-right contadorCaracteres">Caracteres restantes: {200 - description.length} de 200</p>)
    }
  }

  const editFields = () => {
    showLoadingMessageEdit()

    let body = {
      title,
      description
    }
    //console.log(body)
    fetchAsync(urlVideo + videoInfo._id, JSON.stringify(body), "PUT")
      .then((data) => {
        if (data.success) {
          onCloseModal()
          updateContent(data.video)
          closeLoading()
        }
      }).catch((reason) => {
        console.log(reason.message)
        showMessageError("Ocurrio un error al actualizar el video", 3)
      });
  }

  const showLoadingMessageEdit = () => {
    closeLoading = showMessageLoading("Actualizando video..", 0)
  }

  return (
    <Modal
      open={valueModal}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >

      <Row className="mt-3 sizeEditVideo">
        <Col xs={12}>
          <Row>

            <Col sm={12} className="float-right">
              <ButtonToolbar className="ml-1 float-right">
                <Button size="sm"
                  onClick={() => onCloseModal()}>
                  <p>Salir</p>
                </Button>
                <Button color="success" size="sm"
                  onClick={() => editFields()}
                //disabled={this.state.bloquear === true ? "disabled" : ""}
                >
                  <p>Guardar</p>
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>

          <Col xs={12} sm={12} md={12} >

            <div className="form__form-group">
              <span className="form__form-group-label">Titulo del video</span>
              <div className="form__form-group-field">
                <input type="text"
                  className="form-control"
                  id="inpuName" autoComplete="new-password"
                  onChange={e => setTitle(e.target.value)}
                  value={title}
                  maxLength="75"
                />
              </div>
            </div>

            <div >
              <span className="form__form-group-label">Descripción del video</span>
              <form action="" className="form_comentarios d-flex justify-content-end flex-wrap" >
                <textarea name="" id=""
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  //autoFocus="true"
                  maxLength="200"
                />
                {
                  numCaracteres()
                }
              </form>

            </div>
          </Col>

        </Col>
      </Row>
    </Modal>
  )
}
export default EditVideo



