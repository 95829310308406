import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BookingCalendar from "./components/bookingCalendar";
import { DatePicker } from "antd";
import moment from "moment";

import AppoimentsContext from "./context/AppoimentsContext";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import SidebarAppoiments from "./components/sidebarAppoiments";

import styles from "./index.module.scss";
import "antd/es/date-picker/style/index.css";

const { WeekPicker } = DatePicker;

const Asesorias = (props) => {
  const appoimentContext = useContext(AppoimentsContext);
  const { getAppoiments } = appoimentContext;

  const [valueDate, setValueDate] = useState(moment(new Date()));
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    getAppoiments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12}>
          <MainPageHeader
            title="Administración Escolar |"
            subTitle={`Asesorias`}
          />
          <WeekPicker
            placeholder={"Semana"}
            format={"wo - MMMM"}
            defaultValue={moment(new Date())}
            onChange={(date) => setValueDate(date)}
          />
        </Col>
        <Col xs={12} className={showDrawer && styles.table_time_container}>
          <BookingCalendar
            // openSide={props.openSide}
            // closeTab={props.closeTab}
            setShowDrawer={setShowDrawer}
            valueDate={valueDate}
          />
        </Col>
      </Row>
      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SidebarAppoiments />
        </SidebarRightComponent>
      )}
    </Container>
  );
};
export default Asesorias;
