import React from 'react';
import Board from './board'

const TransferContentComponent = ({ idCourse, idPlaylist, idChapter, openModalVideoView }) => {
    return (
        <Board idPlaylist={idPlaylist} idChapter={idChapter} openModalVideoView={openModalVideoView} idCourse={idCourse} />
    )
}
export default TransferContentComponent



