import React from "react";
import { Col, Row } from "reactstrap";
import moment from "moment";
import "moment/locale/es";
import { month } from "../Fichas/constants";

import styles from "./index.module.scss";

export const showStatus = (status, onlyIcon = false) => {
  return (
    <section
      className={
        status
          ? styles.show_status_container
          : styles.show_status_container_inactive
      }
    >
      <i className={`material-icons`}>fiber_manual_record</i>
      {!onlyIcon && <span>{status ? "Activo" : "Inactivo"}</span>}
    </section>
  );
};

export const duracion = (valor) => {
  let dias = parseInt(valor);
  let semanas = 0;
  if (dias > 6) {
    semanas = dias / 7;
    return `${semanas.toFixed(0)} semanas`;
  } else {
    return `${dias} dia(s)`;
  }
};

export const dateDnumberMstringYY = (cell) => {
  if (cell) {
    let start = moment(cell).format("DD/MM/YYYY");
    let dataStart = start.split("/");
    return `${dataStart[0]} de ${month[dataStart[1] - 1]} ${dataStart[2]}`;
  } else {
    return ` `;
  }
};
