import React from 'react';
import { Col } from 'reactstrap';


// saveTypeQuestions 1 to tex, 2 to image 3 to formula 
function ImageContentComponent({ url, isAnswer }) {


    /********************************************
    *********************************************
    *********************************************
    ************** RETURN RENDER ****************
    *********************************************
    ********************************************/

    const imgDraw = {
        width: "100%",
        height: "200px",
        display: "flex",
        "justifyContent": "center",
        "marginBottom": "30px",
        overflow: "hidden",
    }
    const imgDrawAnswer = {
        width: "100%",
        height: "100px",
        display: "flex",
        "marginBottom": "30px",
        overflow: "hidden",
    }
    const imgStyle = {
        height: "100%",
        width: "auto"
    }
    return (
        <Col sm={!isAnswer ? 12 : null}>
            <div className="my-3" style={isAnswer ? imgDrawAnswer : imgDraw}>
                <img style={imgStyle} className="catalog-item__img" src={url} alt="image_question" />
            </div>
        </Col>
    );
}

export default ImageContentComponent
