import React, { useState, useEffect } from 'react';
import { fetchAsync, urlLiveChannels } from '../../../helpers/Globals/globals'
import { Modal } from 'react-responsive-modal';
import ViewStreamIcon from 'mdi-react/ViewStreamIcon'
import VideoIcon from 'mdi-react/VideoIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { Col, Row, CardBody, Button, Card, ButtonToolbar } from 'reactstrap';
import { Switch, Select } from 'antd';
import "antd/es/switch/style/index.css";

const { Option } = Select;

const ModalChannel = ({ channelEditData, value, onCloseModal, isEditModal = false }) => {
    const [channelInfo, setChannelInfo] = useState([])

    const [nameChannel, setNameChannel] = useState()
    const [isActive, setIsActive] = useState()
    const [channelSelected, setChannelSelected] = useState()
    const [rtmpSelected, setRtmpSelected] = useState()

    const [urlStream, setUrlStream] = useState()
    const [cheapurlStream, setCheapUrlStream] = useState()

    //Get info of channel the first time
    useEffect(() => {
        getChannelInfoAws().then(result => {
            if (result.success) {
                setChannelInfo(result.channels)
                if (isEditModal) {
                    const inputSelected = result.channels.find(value => value.Id === channelEditData.idInput)
                    setChannelSelected(inputSelected)
                    setRtmpSelected(inputSelected.Destinations.findIndex(value => value.Url === channelEditData.rtmpUrl))
                }
            }
        })
        if (isEditModal) {
            setNameChannel(channelEditData.name)
            setIsActive(channelEditData.active)
            setUrlStream(channelEditData.awsCdnUrl)
            setCheapUrlStream(channelEditData.cdnUrl)
            setRtmpSelected(channelEditData.rtmpUrl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSaveButton = () => {
        const body = {
            name: nameChannel,
            idInput: channelSelected.Id,
            idChannel: channelSelected.AttachedChannels[0].Id,
            rtmpUrl: channelSelected.Destinations[rtmpSelected].Url,
            awsCdnUrl: urlStream,
            cdnUrl: cheapurlStream,
            active: false
        }
        saveService(body).then(
            () => {
                onCloseModal()
            }
        )
    }

    const saveService = async (body) => {
        let result
        if (isEditModal) {
            result = await fetchAsync(urlLiveChannels + channelEditData._id, JSON.stringify(body), "PUT")
        } else {
            result = await fetchAsync(urlLiveChannels, JSON.stringify(body), "POST")
        }
        return result
    }

    const getChannelInfoAws = async () => {
        const result = await fetchAsync(urlLiveChannels + 'awsInfo', "", "GET")
        console.log(result)
        return result
    }


    return (
        <Modal
            open={value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>

            <Card className="addCursoSize mt-0">
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <Row>
                                <Col sm={12} md={5}>
                                    <div className="card__title">
                                        <h4 className="bold-text">{isEditModal ? "Editar Canal de transmisión:" : "Agregar Canal de transmisión:"}</h4>
                                    </div>
                                </Col>
                                <Col sm={12} md={7} className="addButtonsMarg">
                                    <ButtonToolbar className="float-right">
                                        <Button size="sm" className="float-right"
                                            onClick={() => onCloseModal()}>
                                            <p>Salir</p>
                                        </Button>
                                        <Button className="float-right" color="success" size="sm" onClick={handleSaveButton}>
                                            {isEditModal ? "Editar" : "Guardar"}
                                        </Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs="12" md="9">
                            <div className="form__form-group mb-5">
                                <span className="form__form-group-label">Alias del canal</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <ViewStreamIcon />
                                    </div>
                                    <input type="text" className="form-control"
                                        name="url" autoComplete="off"
                                        value={nameChannel}
                                        onChange={(e) => setNameChannel(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="form__form-group mb-5">
                                <span className="form__form-group-label">Seleccionar canal de aws</span>
                                <div>
                                    <Select
                                        disabled={false ? true : false}
                                        style={{ width: "100%" }}
                                        placeholder="Canal de aws"
                                        value={channelSelected}
                                        onChange={(value) => { setChannelSelected(value) }} >
                                        {channelInfo.map((value, index) => {
                                            return <Option key={value.Id} value={value}>{value.Name}</Option>
                                        })
                                        }
                                    </Select>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        disabled={channelSelected === undefined}
                                        style={{ width: "100%" }}
                                        placeholder="RTMP"
                                        value={rtmpSelected}
                                        onChange={(value) => { setRtmpSelected(value) }} >
                                        {channelSelected !== undefined ? channelSelected.Destinations.map((value, index) => {
                                            return <Option key={value.ip} value={index}>{value.Url}</Option>
                                        }) : null
                                        }
                                    </Select>
                                </div>
                            </div>

                            <div className="form__form-group mb-5">
                                <span className="form__form-group-label">URL CDN AWS (Costoso)</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <VideoIcon />
                                    </div>
                                    <input type="text" className="form-control"
                                        name="url" autoComplete="off"
                                        value={urlStream}
                                        onChange={(e) => setUrlStream(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="form__form-group mb-5">
                                <span className="form__form-group-label">URL CDN Cliente (Economico)</span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <VideoIcon />
                                    </div>
                                    <input type="text" className="form-control"
                                        name="url" autoComplete="off"
                                        value={cheapurlStream}
                                        onChange={(e) => setCheapUrlStream(e.target.value)}
                                    />
                                </div>
                            </div>

                        </Col>
                        <Col xs="12" md="3" className="text-center">
                            <span className="form__form-group-label">Estado del canal</span>
                            <br />
                            <Switch style={isActive ? { backgroundColor: "#de442b" } : null} checked={isActive} onChange={(value) => setIsActive(value)} className="mt-2" checkedChildren="Vivo" />
                        </Col>
                    </Row>
                    {isEditModal ?
                        <Row className="mt-3">
                            <Col xs={12} sm={12} md={12} className="deleteCourseEdit">
                                <Button className="btn  icon float-right" color="danger"
                                    onClick={() => console.log(true)}>
                                    <p><DeleteIcon />Eliminar</p>
                                </Button>
                            </Col>
                        </Row>
                        : null}
                </CardBody>
            </Card>
        </Modal>
    )
}
export default ModalChannel
