import React, { useState, useContext } from "react";
import { Input, message, Divider } from "antd";
import { Button } from "reactstrap";

import { sendFetchTypes } from "./redFunctions";
import MessagesContext from "../context/messagesContext";

import styles from "./index.module.scss";
// import { group } from "@antv/util";

const { TextArea } = Input;

const WriteComment = ({ type, infoType }) => {
  const context = useContext(MessagesContext);
  const {
    groupsList,
    actualTab,
    textEmail,
    textSms,
    subjectEmail,
    sendEmail,
    sendSms,
    paymentFilter,
    onChangeEmailText,
    onChangeSmsText,
    onChangeEmailSubject,
  } = context;

  const [disdable, setDisdable] = useState(false);

  const sendMessage = async () => {
    if (sendEmail && textEmail === "")
      return message.warning(
        `Debes escribir un Email o desactivar el envio de emails `
      );
    if (sendSms && textSms === "")
      return message.warning(
        `Debes escribir un  SMS o desactivar el envio de SMS`
      );

    setDisdable(true);
    let body = {};

    if (sendEmail) {
      body.email = {
        template: "d-a934c1e29ac94e4eb91061956d620114",
        templateData: { message: textEmail, subject: subjectEmail },
      };
    }
    if (sendSms) {
      body.sms = {
        message: textSms,
      };
    }

    //create the array groups ids to send the message
    if (type === "courses") {
      // let copyGroupsList = [...groupsList];

      let idsArray = [];
      for (let i = 0; i < groupsList.length; i++) {
        if (groupsList[i].isSelected) {
          idsArray.push(groupsList[i]._id);
        }
      }
      body.groups = idsArray;
    }

    if (type === "groups") infoType.filterByDebt = paymentFilter;

    const result = await sendFetchTypes(type, infoType, body);
    if (result.success) {
      onChangeEmailText("");
      onChangeSmsText("");
    } else {
      message.error(result.error, 5);
    }
    setDisdable(false);
  };

  return (
    <section className={styles.write_comments_container}>
      <Divider orientation="left">
        Mensaje {actualTab === "1" ? "Email" : "SMS"}
      </Divider>
      {actualTab === "1" ? (
        <>
          <Input
            placeholder=""
            className="mb-2"
            value={subjectEmail}
            onChange={(e) => {
              onChangeEmailSubject(e.target.value);
            }}
          />
          <TextArea
            rows={type === "student" ? 12 : 7}
            maxLength={5000}
            value={textEmail}
            onChange={(e) => onChangeEmailText(e.target.value)}
            placeholder={"Escribe el mensaje"}
            disabled={!sendEmail}
          />
          <p>{`Caracteres restantes: ${5000 - textEmail.length}`}</p>
          <Button
            disabled={!sendEmail || disdable}
            className="btn mt-3 mb-1"
            color="success"
            block
            onClick={sendMessage}
          >
            Envíar
          </Button>
        </>
      ) : (
        <>
          <TextArea
            rows={type === "student" ? 12 : 7}
            maxLength={1000}
            value={textSms}
            onChange={(e) => onChangeSmsText(e.target.value)}
            placeholder={"Escribe el mensaje"}
            disabled={!sendSms}
          />
          <p>{`Caracteres restantes: ${1000 - textSms.length}`}</p>
          <Button
            disabled={!sendSms || disdable}
            className="btn mt-3 mb-1"
            color="success"
            block
            onClick={sendMessage}
          >
            Envíar
          </Button>
        </>
      )}
    </section>
  );
};
export default WriteComment;
