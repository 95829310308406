import {
  NEXT_PAGE,
  BACK_PAGE,
  STUDENT_DATA,
  CHECK_EMAIL,
  ENROLLMENT_ALL_COURSES,
  ENROLLMENT_COURSES_LEVELS,
  ACTIVE_TAB,
  ENROLLMENT_SELECTED_COURSE,
  COURSE_GROUPS,
  LOADING_GROUPS,
  ENROLLMENT_SELECTED_GROUP,
  PAYMENT_DATA,
  DISABLE_BUTTON_REGISTER,
  FAST_REGISTER_DATA_STUDENT,
  STUDENT_DISCOUNTS,
  DELETE_DISCOUNT,
  ADD_DISCOUNT,
  SET_STUDENT_DATA_TALK,
} from "../types/index";

const Reducer = (state, action) => {
  switch (action.type) {
    case NEXT_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case BACK_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case DISABLE_BUTTON_REGISTER:
      return {
        ...state,
        disableButtonRegister: action.payload,
      };
    case LOADING_GROUPS:
      return {
        ...state,
        loadingGroups: action.payload,
      };
    case ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case ENROLLMENT_SELECTED_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
        payment: { ...state.payment, concept: action.payload.name },
      };
    case ENROLLMENT_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
      };
    case STUDENT_DATA:
      if (state.studentInfoFastRegister) {
        return {
          ...state,
          studentInfoFastRegister: {
            ...state.studentInfoFastRegister,
            [action.payload.name]: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
          studentData: {
            ...state.studentData,
            [action.payload.name]: action.payload.value,
          },
        };
      }

    case PAYMENT_DATA:
      return {
        ...state,
        payment: {
          ...state.payment,
          [action.payload.name]: action.payload.value,
        },
      };
    case CHECK_EMAIL:
      return {
        ...state,
        emailAlreadyExists: action.payload,
      };
    case ENROLLMENT_COURSES_LEVELS:
      return {
        ...state,
        mediaLevel: action.payload.find(
          (data) => data.name === "Media Superior"
        ),
        superiorLevel: action.payload.find((data) => data.name === "Superior"),
      };
    case ENROLLMENT_ALL_COURSES:
      return {
        ...state,
        allCourses: action.payload,
        coursesMedia: action.payload.filter(
          (data) => data.courseLevel === state.mediaLevel._id
        ),
        coursesSuperior: action.payload.filter(
          (data) => data.courseLevel === state.superiorLevel._id
        ),
      };
    case COURSE_GROUPS:
      if (action.payload.length <= 0) {
        return {
          ...state,
          selectedCourseGroups: [],
        };
      } else {
        return {
          ...state,
          selectedCourseGroups: action.payload.filter(
            (group) => group.active === true
          ),
        };
      }
    case FAST_REGISTER_DATA_STUDENT:
      return {
        ...state,
        studentInfoFastRegister: action.payload,
      };
    case STUDENT_DISCOUNTS:
      if (action.payload.length > 0) {
        //agregamos el nombre del curso a los decuentos
        const addName = () => {
          action.payload.forEach((data) => {
            let dataCourse = state.allCourses.find(
              (x) => x._id === data.course
            );
            data.courseName = dataCourse.name;
          });
          return action.payload;
        };
        return {
          ...state,
          discounts: addName(),
        };
      } else {
        return {
          ...state,
          discounts: [],
        };
      }
    case DELETE_DISCOUNT:
      let newDiscounts = state.discounts.filter((obj) => {
        return obj._id !== action.payload;
      });
      return {
        ...state,
        discounts: newDiscounts,
      };
    case ADD_DISCOUNT:
      let disArray = state.discounts;
      disArray.push(action.payload);
      return {
        ...state,
        discounts: disArray,
      };
    case SET_STUDENT_DATA_TALK:
      return {
        ...state,
        studentData: action.payload,
      };
    default:
      return state;
  }
};
export default Reducer;
