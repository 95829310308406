import React, { useContext } from "react";
import { DatePicker, Switch, Checkbox } from "antd";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import "antd/es/date-picker/style/index.css";
import "antd/es/divider/style/index.css";

import MessagesContext from "../context/messagesContext";

import styles from "./index.module.scss";

const dateFormat = "DD/MM/YYYY";

const CourseFilters = ({ infoType }) => {
  const context = useContext(MessagesContext);

  const {
    statusGroups,
    onChangeStatusGroups,
    onChangeCheckboxShowAllGroups,
    addParameterToUrlString,
    checkboxAllGroups,
  } = context;

  const onChangeStartdate = (date, dateString) => {
    if (date) {
      let local = moment.utc(date).local().format();

      let stringParameter = `startDate=${local}`;
      addParameterToUrlString(stringParameter, infoType._id);
    } else {
      let stringParameter = `startDate`;
      addParameterToUrlString(stringParameter, infoType._id, true);
    }
  };

  const onChangeEndDate = (date, dateString) => {
    if (date) {
      let local = moment.utc(date).local().format();

      let stringParameter = `endDate=${local}`;
      addParameterToUrlString(stringParameter, infoType._id);
    } else {
      let stringParameter = `endDate`;
      addParameterToUrlString(stringParameter, infoType._id, true);
    }
  };

  const onChangeStatus = (checked) => {
    let stringParameter = `active=${checked}`;
    addParameterToUrlString(stringParameter, infoType._id);
    onChangeStatusGroups(checked);
  };

  const onChangeCheckBox = (e) => {
    let stringParameter = `allGroups=${e.target.checked}`;
    addParameterToUrlString(stringParameter, infoType._id);
    onChangeCheckboxShowAllGroups(e.target.checked);
  };

  return (
    <section className={styles.course_filters_container}>
      <p>Filtrar grupos por fecha inicial</p>
      <br></br>
      <DatePicker
        size="large"
        format={dateFormat}
        locale={locale}
        allowClear={(e) => console.log(e)}
        onChange={onChangeStartdate}
      />
      <p>Filtrar grupos por fecha final</p>
      <br></br>
      <DatePicker
        size="large"
        format={dateFormat}
        locale={locale}
        allowClear={true}
        onChange={onChangeEndDate}
      />
      <p>Filtrar por grupos activos, inactivos o mostrar todos</p>
      <br></br>
      <Switch
        checkedChildren="Ver Activos"
        unCheckedChildren="Ver Inactivos"
        checked={statusGroups}
        onChange={onChangeStatus}
        disabled={checkboxAllGroups}
      />
      <br></br>
      <Checkbox checked={checkboxAllGroups} onChange={onChangeCheckBox}>
        Ver todos los grupos
      </Checkbox>
    </section>
  );
};

export default CourseFilters;
