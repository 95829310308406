import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, ButtonToolbar } from "reactstrap";
import { Modal } from "react-responsive-modal";
import { showMessageWarning } from "../../../helpers/MessageAndNotificationUtils";

import Can from "../../../helpers/Secure/Can";
import DeleteButtonConfirm from "../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";

import HashtagIcon from "mdi-react/HashtagIcon";
import ClassIcon from "mdi-react/ClassIcon";
import "rc-time-picker/assets/index.css";

const inicialState = {
  capacity: "",
  location: "",
  name: "",
};

const AddEditClassroom = (props) => {
  const [classroom, setClassroom] = useState(inicialState);
  const [disableButton, setDisableButton] = useState(false);

  const onChangeInputs = (e) => {
    setClassroom({
      ...classroom,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setClassroom({
      ...classroom,
      location: props.idLocation,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.title === "Editar") {
      const { capacity, location, name, _id } = props.actualClassroom;
      setClassroom({
        capacity,
        location,
        name,
        _id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    setClassroom(inicialState);
    props.close(false);
  };

  const afterDelte = () => {
    setClassroom(inicialState);
    props.close(false);
    props.killSideBar(false);
  };

  const onPushButton = () => {
    if (classroom.name === null || classroom.name === "") {
      return showMessageWarning(
        "Atención! Debes ingresar un nombre de salón",
        2
      );
    }

    if (classroom.capacity === null || classroom.capacity === "") {
      return showMessageWarning(
        "Atención! Debes ingresar la capacidad del salón",
        2
      );
    }

    if (parseInt(classroom.capacity) <= 0) {
      return showMessageWarning(
        "Atención! Debes ingresar un numero  positivo mayor a cero",
        2
      );
    }

    setDisableButton(true);
    const result = props.functionAddEdit(classroom);
    result.then((data) => {
      setDisableButton(false);
      onCloseModal();
    });
  };

  return (
    <Modal
      open={props.value}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container>
        <Row>
          <Col sm={12} md={12}>
            <Row>
              <Col sm={12} md={5}>
                <h3 className="bold-text">{props.title} Salón</h3>
              </Col>
              <Col sm={12} md={7}>
                <ButtonToolbar>
                  <Button size="sm" onClick={onCloseModal}>
                    <p>Salir</p>
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={onPushButton}
                    disabled={disableButton}
                  >
                    <p>{props.title}</p>
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
            <div className="form__form-group">
              <span className="form__form-group-label">Nombre</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <ClassIcon />
                </div>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="inpuName"
                  autoComplete="off"
                  value={classroom.name}
                  onChange={onChangeInputs}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Capacidad</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <HashtagIcon />
                </div>
                <input
                  type="number"
                  className="form-control"
                  name="capacity"
                  id="inputcapacity"
                  autoComplete="off"
                  value={classroom.capacity}
                  onChange={onChangeInputs}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {props.title === "Editar" && (
        <Can do="delete" on="/classrooms/*">
          <DeleteButtonConfirm
            title="¿Deseas eliminar el salón?"
            deleteFunction={props.deleteClassroom}
            id={classroom._id}
            onCloseOptionalFunc={afterDelte}
          />
        </Can>
      )}
    </Modal>
  );
};
export default AddEditClassroom;
