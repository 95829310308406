import React, { PureComponent } from "react";
import { Col, ButtonToolbar, Button, Row } from "reactstrap";
import {
  fetchAsync,
  urlUsersTalks,
  urlTalks,
} from "../../../../../helpers/Globals/globals";
import CardGroup from "./components/cardGroup";
import TalkSelectorModal from "../../../../../helpers/TalkSelectorModal";
import SucessView from "../../../../../helpers/SucessView";
import {
  showNotification,
  showMessageLoading,
  showMessageSuccess,
  showMessageError,
} from "../../../../../helpers/MessageAndNotificationUtils";

import styles from "./index.module.scss";

const defaultState = {
  loading: true,
  loadingchange: false,
  target: "",
  students: [],
  targetKeys: [],
  modalChangeGroupVisible: false,
  isDisabledTransfer: false,
};
// Declare to recive the function of messages, calling again will close the notification
let closeLoading;

class TalkChange extends PureComponent {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  openModalSelectGroup = () => {
    this.setState({
      modalChangeGroupVisible: true,
    });
  };

  closeModalSelectGroup = () => {
    this.setState({
      modalChangeGroupVisible: false,
    });
  };

  settarget = (id) => {
    fetchAsync(urlTalks + id, "", "GET", "").then((data) => {
      if (data.success) {
        this.setState({
          target: data.talk,
          modalChangeGroupVisible: false,
          loading: false,
        });
      }
    });
  };

  LoadingMessage = () => {
    return showMessageLoading("Cambiando alumno", 0);
  };

  SuccessMessage = (messageText) => {
    showMessageSuccess(messageText, 2.5);
  };

  ErrorMessage = (messageText) => {
    showMessageError(messageText, 2.5);
  };

  ErrorNotification(studentList) {
    let messageText = "Error al inscribir " + studentList.length + " alumnos";
    let messageDescription = (
      <div>
        <p>Ocurrio un error al ejecutar la operación</p>
        <p>Error:{studentList[0].error}</p>
      </div>
    );
    showNotification("error", messageText, messageDescription, 0);
  }

  /****************************************************************
   *****************************************************************
   ************************Network Operations***********************
   *****************************************************************
   ****************************************************************/

  //Funcion que cambia de grupos a los alumnos seleccionados
  //Recibe:
  //idStudentArray: Arreglo de alumnos a cambiar
  //idGroupSource: idGrupo origen
  //idGroupTarget: id del grupo de destino
  //targetKeys : La colección de los targetKeys actualizados para que se refleje el cambio en las tablas Transfer

  changeStudentGroup() {
    closeLoading = this.LoadingMessage();
    this.setState({
      loadingchange: true,
    });
    let body = {};
    body.talk = {
      id: this.state.target._id,
    };
    fetchAsync(
      urlUsersTalks +
        this.props.idStudent +
        "/talks/" +
        this.props.talkInfo._id,
      JSON.stringify(body),
      "PUT",
      ""
    ).then((data) => {
      if (data.success) {
        closeLoading();
        this.SuccessMessage("Alumno cambiado correctamente");
        this.setState({
          showSucessView: true,
        });
      } else {
        closeLoading();
        this.ErrorMessage("Ocurrio un error al cambiar al alumno");
      }
    });
  }

  //La siguiente es la operación de red para el cambio de grupos , existen varios parametros que recibe
  // se pueden forzar los cambios para que aunque ya no haya grupos se efectue la operación de red

  /****************************************************************
   *****************************************************************
   *****************************Render******************************
   *****************************************************************
   ****************************************************************/

  render() {
    return (
      <div className={styles.main_container_chnage_talk}>
        {this.state.showSucessView ? (
          <section className={styles.success_box}>
            <div>
              <Button
                size="sm"
                className={`btn btn-secondary float-right`}
                onClick={this.props.onCloseModal}
              >
                Salir
              </Button>
            </div>
            <SucessView />
          </section>
        ) : (
          <Row>
            <Col xs="12">
              <Button
                size="sm"
                className={`btn btn-secondary float-right`}
                onClick={this.props.onCloseModal}
              >
                Salir
              </Button>
              <Button
                className="float-right mr-4"
                color="success"
                size="sm"
                onClick={() => this.openModalSelectGroup(true)}
              >
                <p>Selecciona la nueva platica</p>
              </Button>
            </Col>
            <Col sm={12} className="text-center">
              <p className={styles.direction_box}>Origen</p>
              <CardGroup grupo={this.props.talkInfo} />
            </Col>
            <Col className="text-center" style={{ backgrounColor: "red" }}>
              <i className="material-icons mr-4">arrow_downward</i>
            </Col>
            <Col sm={12} className="text-center">
              <p className={styles.direction_box}>Destino</p>

              <CardGroup grupo={this.state.target} />
            </Col>
            <Col>
              {this.state.target !== "" && (
                <Row className="justify-content-center">
                  <ButtonToolbar>
                    <Button
                      color="success"
                      size="sm"
                      disabled={this.state.loadingchange}
                      onClick={() => this.changeStudentGroup()}
                    >
                      <p>Realizar cambio</p>
                    </Button>
                  </ButtonToolbar>
                </Row>
              )}
            </Col>
          </Row>
        )}
        <TalkSelectorModal
          selectTarget={this.settarget.bind(this)}
          valueModal={this.state.modalChangeGroupVisible}
          closeModal={this.closeModalSelectGroup}
          currentGroup={this.props.talkInfo._id}
        />
      </div>
    );
  }
}
export default TalkChange;
