import React, { createContext, useEffect, useState } from 'react';

//this context get and shares the screen values "width and height" for the re-zise of the layout
const ViewportContext = createContext();
export default ViewportContext

export const ViewportState = ({ children }) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    // console.log("useEffect_resize")
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [])

  return (
    <ViewportContext.Provider
      value={{
        width,
        height,
      }}
    >
      {children}
    </ViewportContext.Provider>
  )
}


