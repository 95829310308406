import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Button, ButtonToolbar } from "reactstrap";
import { Checkbox, Divider, Tooltip } from "antd";
import { Modal } from "react-responsive-modal";

import TimePicker from "rc-time-picker";
import moment from "moment";
import MapaModal from "./mapaModal";

import DeleteButtonConfirm from "../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";
import {
  showMessageLoading,
  showMessageWarning,
} from "../../../helpers/MessageAndNotificationUtils";
import Can from "../../../helpers/Secure/Can";

import LocationsContext from "../../../context/locations/LocationsContext";

import MapAddIcon from "mdi-react/MapAddIcon";
import HashtagIcon from "mdi-react/HashtagIcon";
import SignRoutesIcon from "mdi-react/SignRoutesIcon";
import SignTextIcon from "mdi-react/SignTextIcon";
import HouseIcon from "mdi-react/HouseIcon";
import ClockIcon from "mdi-react/ClockIcon";

import styles from "./index.module.scss";
import "rc-time-picker/assets/index.css";
import "antd/es/checkbox/style/index.css";
import "antd/es/divider/style/index.css";

const now = moment().hour(12).minute(0);
let closeLoading = "";

const initialState = {
  name: "",
  street: "",
  number: "",
  postalCode: "",
  district: "",
  latitude: "",
  longitude: "",
  opening: now,
  closing: now,
};

const AddEditLocation = (props) => {
  const [location, setLocation] = useState(initialState);
  const [openMap, setOpenMap] = useState(false);
  const [editAdress, setEditAdress] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const locationsContext = useContext(LocationsContext);
  const {
    createLocation,
    actualLocation,
    deleteLocation,
    updateLocation,
  } = locationsContext;

  //en caso de editar se agregan los datos de la sede seleccionada
  useEffect(() => {
    const {
      name,
      street,
      number,
      postalCode,
      district,
      opening,
      closing,
      latitude,
      longitude,
    } = actualLocation;
    if (props.edit !== undefined) {
      setLocation({
        name,
        street,
        number,
        postalCode,
        district,
        latitude,
        longitude,
        opening,
        closing,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    props.close(false);
    setLocation(initialState);
    setOpenMap(false);
    setDisableButton(false);
    if (closeLoading !== "") {
      closeLoading();
    }
  };

  // //valida y genera las advertencias necesarias para el formulario y ejecuta el servicio para registrar
  const register = () => {
    const {
      name,
      street,
      number,
      postalCode,
      district,
      opening,
      closing,
    } = location;

    if (name === null || name === "") {
      return showMessageWarning(
        "Atención! Debes ingresar un nombre de sede",
        2
      );
    } else if (street === null || street === "") {
      return showMessageWarning(
        "Atención! Debes ingresar el nombre de la calle",
        2
      );
    } else if (number === null || number === "") {
      return showMessageWarning(
        "Atención! Debes ingresar un numero para continuar",
        2
      );
    } else if (isNaN(number)) {
      return showMessageWarning(
        "Atención! Debes ingresar un numero para continuar",
        2
      );
    } else if (postalCode === null || postalCode === "") {
      return showMessageWarning(
        "Atención! Debes ingresar un Código postal para continuar",
        2
      );
    } else if (district === null || district === "") {
      return showMessageWarning(
        "Atención! Debes ingresar una colonia para continuar",
        2
      );
    } else if (opening === null || opening === "") {
      return showMessageWarning(
        "Atención! Debes selecionar el horario de apertura",
        2
      );
    } else if (closing === null || closing === "") {
      return showMessageWarning(
        "Atención! Debes selecionar el horario de cierre",
        2
      );
    } else if (opening > closing) {
      return showMessageWarning(
        "Atención! El horario de cierre no puede ser menor al horario de apertura",
        2
      );
    } else if (opening.length === undefined || closing === undefined) {
      return showMessageWarning(
        "Atención! Debes elegir los horarios de apertura y cierre",
        2
      );
    } else {
      closeLoading = LoadingMessage();
      setDisableButton(true);
      if (props.edit !== undefined) {
        let updateL = updateLocation(location);
        updateL.then((result) => {
          if (result.success) {
            onCloseModal();
          } else {
            showMessageWarning(
              <strong>Ocurrio un error al actualizar la sede</strong>,
              2
            );
          }
          if (closeLoading !== "") {
            closeLoading();
          }
          setDisableButton(false);
        });
      } else {
        let loc = createLocation(location);
        loc.then((result) => {
          if (result.success) {
            onCloseModal();
          } else {
            showMessageWarning(
              <strong>Ocurrio un error al crear la sede</strong>,
              2
            );
          }
          if (closeLoading !== "") {
            closeLoading();
          }
          setDisableButton(false);
        });
      }
    }
  };

  //muestra el mensage de loading al registrar
  const LoadingMessage = () => {
    return showMessageLoading("Registrando..", 0);
  };

  const onChangeInputs = (e) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value,
    });
  };

  //setea la hora de inicio
  const handleValueChangeOpen = (value) => {
    if (value !== null || value !== undefined) {
      //console.log(value && value.format('HH:mm'));
      let hora = value.format("HH:mm");
      setLocation({
        ...location,
        opening: hora,
      });
    }
  };

  const handleValueChangeClose = (value) => {
    if (value !== null || value !== undefined) {
      //console.log(value && value.format('HH:mm'));
      let hora = value.format("HH:mm");
      setLocation({
        ...location,
        closing: hora,
      });
    }
  };

  const getAdressInfo = (info, lat, long) => {
    setLocation({
      ...location,
      street: info.address_components[1].long_name,
      number: info.address_components[0].long_name,
      postalCode:
        info.address_components[6] && info.address_components[6].long_name,
      district: info.address_components[2].long_name,
      latitude: String(lat),
      longitude: String(long),
    });
    setOpenMap(false);
  };

  const onChangeCheckBox = (e) => {
    setEditAdress(e.target.checked);
  };

  return (
    <Modal
      open={props.value}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container>
        <Row>
          <Col xs="12">
            <Row>
              <Col sm={12} md={5}>
                <h4 className="bold-text">{`${
                  props.edit ? "Editar" : "Agregar"
                } sede`}</h4>
              </Col>
              <Col sm={12} md={7} className="float-right">
                <ButtonToolbar>
                  <Button size="sm" onClick={onCloseModal}>
                    <p>Salir</p>
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={register}
                    disabled={disableButton}
                  >
                    {props.edit !== undefined ? <p>Editar</p> : <p>Crear</p>}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
            <div className="form__form-group">
              <span className="form__form-group-label">Nombre de la sede</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <HouseIcon />
                </div>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="inpuName"
                  autoComplete="off"
                  value={location.name}
                  onChange={onChangeInputs}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={12}>
                <ButtonToolbar>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => setOpenMap(true)}
                  >
                    <p>Seleccionar ubicación</p>
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="8">
                <Tooltip
                  placement="right"
                  title="Selecciona el recuadro si deseas editar la ubicación."
                >
                  <span>
                    <Divider orientation="left">Editar ubicación</Divider>
                  </span>
                </Tooltip>
              </Col>
              <Col xs="12" sm="4" className="mt-3">
                <Checkbox onChange={onChangeCheckBox} />
              </Col>
            </Row>
            <div className="form__form-group">
              <span className="form__form-group-label">Calle</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <SignRoutesIcon />
                </div>
                <input
                  type="text"
                  name="street"
                  disabled={editAdress ? false : true}
                  className="form-control"
                  id="st"
                  autoComplete="off"
                  value={location.street}
                  maxLength={99}
                  onChange={onChangeInputs}
                />
              </div>
            </div>
            <Row className={styles.form_location_field}>
              <Col sm={12} md={5}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Numero</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <HashtagIcon />
                    </div>
                    <input
                      id="Cp"
                      className="form-control"
                      name="number"
                      disabled={editAdress ? false : true}
                      type="number"
                      value={location.number}
                      onChange={onChangeInputs}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={12} md={7}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Código postal</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <SignTextIcon />
                    </div>
                    <input
                      id="Cp"
                      className="form-control"
                      name="postalCode"
                      disabled={editAdress ? false : true}
                      type="number"
                      value={location.postalCode}
                      onChange={onChangeInputs}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="form__form-group">
              <span className="form__form-group-label">Colonia</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <MapAddIcon />
                </div>
                <input
                  type="text"
                  name="district"
                  className="form-control"
                  disabled={editAdress ? false : true}
                  id="inputSecondLastName"
                  autoComplete="off"
                  value={location.district}
                  onChange={onChangeInputs}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={5}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Horario de apertura
                  </span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <ClockIcon />
                    </div>
                    <TimePicker
                      showSecond={false}
                      value={moment(location.opening, "HH:mm")}
                      use12Hours
                      onChange={handleValueChangeOpen}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={12} md={7}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Horario de cierre
                  </span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <ClockIcon />
                    </div>
                    <TimePicker
                      showSecond={false}
                      value={moment(location.closing, "HH:mm")}
                      use12Hours
                      onChange={handleValueChangeClose}
                    />
                  </div>
                </div>
              </Col>
              <Col xs="12">
                {props.edit && (
                  <Can do="delete" on="/locations/">
                    <Row className="d-flex flex-row-reverse">
                      <DeleteButtonConfirm
                        title="¿Deseas eliminar la sede?"
                        deleteFunction={deleteLocation}
                        id={actualLocation?._id}
                        onCloseOptionalFunc={props.killSideBar}
                      />
                    </Row>
                  </Can>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {openMap && (
        <MapaModal
          valueAddModal={openMap}
          openAddModal={setOpenMap}
          getAdressInfo={getAdressInfo}
        />
      )}
    </Modal>
  );
};
export default AddEditLocation;
