import {
    CHANGE_INIT_POSITION,
    CHANGE_SIDEBAR_SHOW,
    CHANGE_SIDEBAR_MOBILE_SHOW,
    CHANGE_SIDEBAR_COMPLEMENT_SHOW,
    CHANGE_SIDEBAR_COMPLEMENT_MOBILE_SHOW,
    OPEN_ADDCONTENT_SIDEBAR_COMPLEMENT,
    KILL_SIDEBAR_COMPLEMENT
} from '../types/index';

const Reducer = (state, action) => {
    switch (action.type) {
        case CHANGE_INIT_POSITION:
            return {
                ...state,
                sidebarPosition: action.payload,
            }
        case CHANGE_SIDEBAR_SHOW:
            return {
                ...state,
                sidebarShow: action.payload,
            }

        case CHANGE_SIDEBAR_MOBILE_SHOW:
            return {
                ...state,
                sidebarMobileShow: action.payload,
            }
        case CHANGE_SIDEBAR_COMPLEMENT_SHOW:
            return {
                ...state,
                sidebarComplementShow: action.payload,
            }

        case CHANGE_SIDEBAR_COMPLEMENT_MOBILE_SHOW:
            return {
                ...state,
                sidebarComplementMobileShow: action.payload,
            }
        case OPEN_ADDCONTENT_SIDEBAR_COMPLEMENT:
            return {
                ...state,
                sidebarComplementContent: action.payload
            }
        case KILL_SIDEBAR_COMPLEMENT:
            return {
                ...state,
                sidebarComplementContent: "",
                sidebarComplementShow: false
            }
        default:
            return state
    }
}
export default Reducer;