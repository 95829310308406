import React, { Fragment, useState } from 'react';
import { Select } from 'antd';
import { Row, Col } from 'reactstrap';
import ModalAddToppic from './modalAddToppic'
import "antd/es/select/style/index.css";

const Option = Select.Option;
const NEW_ITEM = "___NEW_ITEM___";
const days = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"]

const SelectTopics = ({ topicList, topicByDayList, setTopicByDayList }) => {
    const [items, setItems] = useState(topicByDayList || [])
    const [day, setDay] = useState()
    const [topic, setTopic] = useState()
    const [modalAdd, setModalAdd] = useState(false)
    
    const onChangeDays = value => {
        setDay(value)
    }

    const onChange = value => {
        if (value !== NEW_ITEM) {
            let prevItem = [...items]
            prevItem.push({
                day,
                topics: value
            })
            setItems(prevItem)
            setTopicByDayList(prevItem)
            setDay()
            setTopic()
        } else {
            setModalAdd(true)
        }
    }

    const onClickDelete = index => {
        let itemsProv = [...items]
        itemsProv.splice(index, 1)
        setItems(itemsProv)
        setTopicByDayList(itemsProv)
    }

    const listDays = () => {
        return (
            items.map((data, index) => {
                let result = topicList.findIndex(x => String(x._id) === String(data.topics))
                return (
                    <Fragment>
                        <hr></hr>
                        <Row>
                            <Col xs={4}>
                                <span className="mr-2">{days[data.day]}</span>
                            </Col>
                            <Col xs={6}>
                                <span className="mr-2">{result !== -1 ? topicList[result].name : "No disponible"}</span>
                            </Col>
                            <Col xs={2}>
                                <div >
                                    <i onClick={() => onClickDelete(index)}
                                        className="material-icons deleteIcon" >delete</i>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                )
            })
        )
    }

    const drawOptions = () => {
        if (topicList && topicList.length >= 0) {
            return topicList.map((value) => {
                return (
                    <Option value={value._id}>{value.name}</Option>
                )
            })
        }
    }

    return (
        <>
            <span className="form__form-group-label">Selecciona los módulos por día de asesoría(opcional)</span>
            <Col xs="12" md="6">
                <Select
                    style={{ width: "100%" }}
                    value={day}
                    placeholder="Selecciona el día"
                    onChange={onChangeDays}>
                    <Option value={1}>Lunes</Option>
                    <Option value={2}>Martes</Option>
                    <Option value={3}>Miercoles</Option>
                    <Option value={4}>Jueves</Option>
                    <Option value={5}>Viernes</Option>
                    <Option value={6}>Sabado</Option>
                    <Option value={7}>Domingo</Option>
                </Select>
            </Col>
            <Col xs="12" md="6" className="text-center">
                <Select
                    value={topic}
                    disabled={day === undefined}
                    style={{ width: "100%" }}
                    placeholder="Selecciona el módulo"
                    onChange={onChange}>
                    {drawOptions()}
                    <Option value={NEW_ITEM}>+ Agregar uno nuevo</Option>
                </Select>
            </Col>
            <Col md="12">
                {listDays()}
            </Col>
            {modalAdd ? <ModalAddToppic value={modalAdd} onCloseModal={() => setModalAdd(false)} /> : null}
        </>
    )
}
export default SelectTopics
