import {
  GET_ALL_COURSES,
  GET_COURSES_TYPES,
  GET_COURSES_LEVELS,
  ACTUAL_COURSE,
  ACTUAL_COURSE_GROUPS,
  DISABLE_BUTTON,
  UPDATE_COURSE_AFTER_EDIT,
  UPDATE_COURSE_AFTER_ADD,
} from '../types/index';

const Reducer = (state, action) => {
  switch (action.type) {
    case GET_ALL_COURSES:
      return {
        ...state,
        courses: action.payload,
        coursesMedia: action.payload.filter((data) => data.courseLevel === state.mediaLevel._id),
        coursesSuperior: action.payload.filter((data) => data.courseLevel === state.superiorLevel._id),
      }
    case GET_COURSES_TYPES:
      return {
        ...state,
        courseTypes: action.payload
      }
    case GET_COURSES_LEVELS:
      return {
        ...state,
        courseLevels: action.payload,
        mediaLevel: action.payload.find((data) => data.name === "Media Superior"),
        superiorLevel: action.payload.find((data) => data.name === "Superior"),
      }
    case ACTUAL_COURSE:
      return {
        ...state,
        actualCourse: action.payload,
        idActualCourse: action.payload._id
      }
    case ACTUAL_COURSE_GROUPS:
      return {
        ...state,
        actualCourseGroups: action.payload,
      }
    case DISABLE_BUTTON:
      return {
        ...state,
        disableButton: action.payload
      }
    case UPDATE_COURSE_AFTER_EDIT:
      let arrayCoursesall = [...state.courses];
      const indexAll = state.courses.findIndex((obj => obj._id === action.payload._id));
      arrayCoursesall[indexAll] = action.payload;
      let arrayCourses = action.payload.courseLevel === state.mediaLevel._id ? [...state.coursesMedia] : [...state.coursesSuperior];
      const index = arrayCourses.findIndex((obj => obj._id === action.payload._id));
      arrayCourses[index] = action.payload;
      return action.payload.courseLevel === state.mediaLevel._id ? {
        ...state,
        courses: arrayCoursesall,
        coursesMedia: arrayCourses,
      } :
        {
          ...state,
          courses: arrayCoursesall,
          coursesSuperior: arrayCourses,
        }
    case UPDATE_COURSE_AFTER_ADD:
      return action.payload.courseLevel === state.mediaLevel._id ? {
        ...state,
        courses: [action.payload, ...state.courses],
        coursesMedia: [action.payload, ...state.coursesMedia],
      } :
        {
          ...state,
          courses: [action.payload, ...state.courses],
          coursesSuperior: [action.payload, ...state.coursesSuperior],
        }
    default:
      return state;
  }
}
export default Reducer;