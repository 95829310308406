import React, { useContext } from 'react';
import SidebarContext from '../../../context/sidebar/SidebarContext'

const icon = `${process.env.PUBLIC_URL}/img/cross.svg`;

const SidebarButtonComplement = () => {

  const sidebarContext = useContext(SidebarContext)
  const { killSideBar, sidebarComplementShow } = sidebarContext

  return (
    <div>
      <button type="button" className={sidebarComplementShow ? "sidebarComplement__button sidebarComplement__button--desktop" : "sidebarComplement__button sidebarComplement__button--desktop collapsed"} onClick={() => killSideBar()}>
        <img src={icon} alt="" className="sidebarComplement__button-icon" />
      </button>
    </div>
  );

}

export default SidebarButtonComplement;
