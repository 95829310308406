import {
  GET_QUIZ_INFO, SAVED_QUIZ,
  SAVE_QUIZ_PLAYLIST_INFO, SAVE_QUIZ_COURSE_INFO,
  ONCHANGE_INPUTS, ADD_QUESTION,
  DELETE_QUESTION, ONCHANGE_QUESTION,
  ADD_VIDEO_QUESTION, ADD_FORMULA_QUESTION,
  DELETE_FORMULA_QUESTION, ADD_IMAGE_QUESTION,
  DELETE_VIDEO_QUESTION, ADD_ANSWER,
  DELETE_ANSWER, ONCHANGE_ANSWER,
  VALID_ANSWER, ADD_FORMULA_ANSWER,
  DELETE_FORMULA_ANSWER, ADD_IMAGE_ANSWER, AFTER_SAVED_QUIZ,
  REORDER_QUESTIONS, SET_SKIP_NUMBER,
  LOADING_QUIZ, RESTART_VALUES, DISABLE_BUTTONS,
} from '../types/index';

const Reducer = (state, action) => {
  switch (action.type) {
    case GET_QUIZ_INFO:
      const { name, description, active, random, studentAttempts = 1,
        questionsPerQuiz = 0, limitTime = 0, questionCollection } = action.payload
      return {
        ...state,
        saved: true,
        _idQuiz: action.payload._id,
        quizInfo: {
          ...state.quizInfo,
          name,
          description,
          active,
          random,
          studentAttempts,
          questionsPerQuiz,
          limitTime,
          quiz: questionCollection
          // quizType: 1,
        }
      }
    case SAVED_QUIZ:
      return {
        ...state,
        saved: action.payload,
      }
    case SAVE_QUIZ_PLAYLIST_INFO:
      return {
        ...state,
        playlistInfo: action.payload,
      }
    case SAVE_QUIZ_COURSE_INFO:
      return {
        ...state,
        courseInfo: action.payload,
      }
    case ONCHANGE_INPUTS:
      return {
        ...state,
        quizInfo: {
          ...state.quizInfo,
          [action.payload.field]: action.payload.value
        }
      }
    case ADD_QUESTION:
      return {
        ...state,
        quizInfo: {
          ...state.quizInfo,
          quiz: [...state.quizInfo.quiz, action.payload]
        }
      }
    case DELETE_QUESTION:
      const newArrayQuestions = state.quizInfo.quiz.filter((item) => item._id !== action.payload);
      return {
        ...state,
        quizInfo: {
          ...state.quizInfo,
          quiz: newArrayQuestions
        }
      }
    case ONCHANGE_QUESTION:
      for (let i = action.payload.skip; i < state.quizInfo.quiz.length; i++) {
        if (String(state.quizInfo.quiz[i]._id) === String(action.payload.idQuestion)) {
          state.quizInfo.quiz[i].question = action.payload.text;
          break
        }
      }
      return {
        ...state,
      }
    case ADD_FORMULA_QUESTION:
      for (let i = action.payload.skip; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload.idQuestion) {
          state.quizInfo.quiz[i].questionFormula = action.payload.formula
          state.quizInfo.quiz[i].questionType = 3
          //delete image if exits
          delete state.quizInfo.quiz[i].questionImage
          break
        }
      }
      return {
        ...state,
      }
    case DELETE_FORMULA_QUESTION:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload) {
          delete state.quizInfo.quiz[i].questionFormula
          delete state.quizInfo.quiz[i].questionImage
          state.quizInfo.quiz[i].questionType = 1
          break
        }
      }
      return {
        ...state,
      }
    case ADD_IMAGE_QUESTION:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload.idQuestion) {
          state.quizInfo.quiz[i].questionImage = action.payload.imageInfo
          state.quizInfo.quiz[i].questionType = 2
          //delete formula if exits
          delete state.quizInfo.quiz[i].questionFormula
          break
        }
      }
      return {
        ...state,
      }
    case ADD_VIDEO_QUESTION:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload.idQuestion) {
          state.quizInfo.quiz[i].explanationVideo = action.payload.videos
          break
        }
      }
      return {
        ...state,
      }
    case DELETE_VIDEO_QUESTION:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload.idQuestion) {
          let indice = state.quizInfo.quiz[i].explanationVideo.findIndex(data => data._id === action.payload.idVideo);
          state.quizInfo.quiz[i].explanationVideo.splice(indice, 1);
          break
        }
      }
      return {
        ...state,
      }
    case ADD_ANSWER:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (String(state.quizInfo.quiz[i]._id) === String(action.payload._idQuestion)) {
          state.quizInfo.quiz[i].answerCollection.push(action.payload.answersObj)
          break
        }
      }
      return {
        ...state,
      }
    case DELETE_ANSWER:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (String(state.quizInfo.quiz[i]._id) === String(action.payload.idQuestion)) {
          // if (state.quizInfo.quiz[i].answerCollection.length === 1) return showMessageWarning("Atención! No puedes borrar todas las respuestas de una pregunta.", 4)
          let newArrayanswers = state.quizInfo.quiz[i].answerCollection.filter(item => item.idAnswer !== action.payload.idAnswer)
          state.quizInfo.quiz[i].answerCollection = newArrayanswers
          break
        }
      }
      return {
        ...state,
      }
    case ONCHANGE_ANSWER:
      const { idAnswer, idQuestion, text, skip } = action.payload
      for (let i = skip; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === idQuestion) {
          let answerIndex = state.quizInfo.quiz[i].answerCollection.findIndex(obj => obj.idAnswer === idAnswer)
          state.quizInfo.quiz[i].answerCollection[answerIndex].content = text
          break
        }
      }
      return {
        ...state,
      }
    case VALID_ANSWER:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (String(state.quizInfo.quiz[i]._id) === String(action.payload.idQuestion)) {
          let answerIndex = state.quizInfo.quiz[i].answerCollection.findIndex(obj => obj.idAnswer === action.payload.idAnswer)
          state.quizInfo.quiz[i].answerCollection[answerIndex].validAnswer = action.payload.status
          break
        }
      }
      return {
        ...state,
      }
    case ADD_FORMULA_ANSWER:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload.idQuestion) {
          let answerIndex = state.quizInfo.quiz[i].answerCollection.findIndex(obj => obj.idAnswer === action.payload.idAnswer)
          state.quizInfo.quiz[i].answerCollection[answerIndex].answerFormula = action.payload.formula
          state.quizInfo.quiz[i].answerCollection[answerIndex].answerType = 3
          delete state.quizInfo.quiz[i].answerCollection[answerIndex].answerImage
          break
        }
      }
      return {
        ...state,
      }
    case DELETE_FORMULA_ANSWER:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload.idQuestion) {
          let answerIndex = state.quizInfo.quiz[i].answerCollection.findIndex(obj => obj.idAnswer === action.payload.idAnswer)
          state.quizInfo.quiz[i].answerCollection[answerIndex].answerType = 1
          delete state.quizInfo.quiz[i].answerCollection[answerIndex].answerFormula
          delete state.quizInfo.quiz[i].answerCollection[answerIndex].answerImage
          break
        }
      }
      return {
        ...state,
      }
    case ADD_IMAGE_ANSWER:
      for (let i = state.skipNumber; i < state.quizInfo.quiz.length; i++) {
        if (state.quizInfo.quiz[i]._id === action.payload.idQuestion) {
          let answerIndex = state.quizInfo.quiz[i].answerCollection.findIndex(obj => obj.idAnswer === action.payload.idAnswer)
          state.quizInfo.quiz[i].answerCollection[answerIndex].answerImage = action.payload.image
          state.quizInfo.quiz[i].answerCollection[answerIndex].answerType = 2
          delete state.quizInfo.quiz[i].answerCollection[answerIndex].answerFormula
          break
        }
      }
      return {
        ...state,
      }
    case AFTER_SAVED_QUIZ:
      return {
        ...state,
        saved: true,
        _idQuiz: action.payload._id,
        quizInfo: {
          ...state.quizInfo,
          quiz: action.payload.questionCollection
        }
      }
    case REORDER_QUESTIONS:
      return {
        ...state,
        quizInfo: {
          ...state.quizInfo,
          quiz: action.payload
        }
      }
    case SET_SKIP_NUMBER:
      return {
        ...state,
        skipNumber: action.payload
      }
    case LOADING_QUIZ:
      return {
        ...state,
        loading: action.payload
      }
    case DISABLE_BUTTONS:
      return {
        ...state,
        disable: action.payload
      }
    case RESTART_VALUES:
      return {
        state: action.payload
      }
    default:
      return state;
  }
}
export default Reducer;