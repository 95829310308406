import React, { PureComponent } from 'react';
import { Col, ButtonToolbar, Button, Row } from 'reactstrap';
import { fetchAsync, urlGroups, urlStudents, } from 'helpers/Globals/globals';
import { Modal } from 'react-responsive-modal';
import LoadingComponent from 'helpers/LoadingComponent'
import { showNotification, showMessageLoading, showMessageSuccess, showMessageError } from 'helpers/MessageAndNotificationUtils'
import SelectorGroup from 'helpers/selectorGroup/index';
//Imports of antd ant their styles 
import { Transfer, Empty } from 'antd';
import 'antd/lib/transfer/style/index.css';
import 'antd/lib/empty/style/index.css';
import 'antd/lib/checkbox/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/input/style/index.css';


const defaultState = {
    loading: true,
    destinationInfo: undefined,
    targetId: "",
    students: [],
    targetKeys: [],
    isDisabledTransfer: false,
    openSelectorGroupModal: false
}

const ResetState = {
    loading: true,
    destinationInfo: undefined,
    targetId: "",
    students: [],
    targetKeys: [],
    isDisabledTransfer: false,
    openSelectorGroupModal: false
}
// Declare to recive the function of messages, calling again will close the notification
let closeLoading

class MasiveChangesStudents extends PureComponent {

    constructor(props) {
        super(props);
        this.state = defaultState
    }

    filterOption = (inputValue, option) => option.name.indexOf(inputValue) > -1;

    //handle when some id is changed
    // this method will call network operations
    handleChange = (targetKeys, direction, moveKeys) => {
        //console.log("handleChange")
        // console.log(direction)
        // console.log(moveKeys)
        closeLoading = this.LoadingMessage()
        this.setState({
            isDisabledTransfer: true
        })
        if (direction === "left") {
            this.changeStudentGroup(moveKeys, this.state.targetId, this.props.grupo._id, targetKeys)
        } else {
            this.changeStudentGroup(moveKeys, this.props.grupo._id, this.state.targetId, targetKeys)
        }        //this.setState({ targetKeys });
    };


    //Implementation of this line
    // onSelectChange={this.handleOnSelectChange}
    // used to handle when some id is selected

    handleOnSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        // console.log("onSelectChange")
        // console.log("sourceSelectedKeys")
        //console.log(sourceSelectedKeys)
        // console.log("targetSelectedKeys")
        //console.log(targetSelectedKeys)
    }


    handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };

    renderItem = item => {
        const customLabel = (
            <span className="custom-item">
                {item.name}
            </span>
        );
        return {
            label: customLabel, // for displayed item
            value: item.name, // for title and filter matching
        };
    };


    onCloseModal = () => {
        this.props.closeModal(false)
        this.setState(ResetState)
        //this will close all notification loading indicator
        //if (this.state.isDisabledTransfer)
        //closeLoading()

        //this.setState(ResetState)
    }

    openSelectGroup = () => {
        this.setState({
            openSelectorGroupModal: true
        })
    }

    closeSelectGroup = () => {
        this.setState({
            openSelectorGroupModal: false
        })
    }


    setTargetId = (group) => {
        //console.log(group)
        this.setState({
            destinationInfo: group,
            targetId: group._id,
            loading: true,
            openSelectorGroupModal: false
        })
        this.studentsByGroup(this.props.grupo._id, group._id)
    }


    LoadingMessage = () => {
        return showMessageLoading('Moviendo alumnos', 0);
    };

    SuccessMessage = (messageText) => {
        showMessageSuccess(messageText, 2.5)
    }

    ErrorMessage = (messageText) => {
        showMessageError(messageText, 2.5)
    }

    ErrorNotification(studentList) {
        let messageText = "Error al inscribir " + studentList.length + " alumnos"
        let messageDescription = <div><p>Ocurrio un error al ejecutar la operación</p><p>Error:{studentList[0].error}</p></div>
        showNotification("error", messageText, messageDescription, 0)
    }


    /****************************************************************
    *****************************************************************
    ************************Network Operations***********************
    *****************************************************************
    ****************************************************************/


    //obtiene los alumnos de un grupo
    //Atención esta operación de red se ejecuta primero , son operaciones dependientes
    //Para cargar todos los resultados en un solo setState se pasan los datos del resultado
    //a la siguiente operación de red en el then de esta forma se mejora el performance y 
    // la posible deficiencia de datos por las operaciones asincronas

    studentsByGroup = (originId, id) => {
        //console.log("cometarios " + group)
        return fetchAsync(urlGroups + originId + "/students", "", "GET", "")
            .then(
                (result) => {
                    if (result.success === true) {
                        console.log("SUCCESS FETCH")
                        let destination = []
                        for (let i = 0; i < result.students.length; i++) {
                            let data = {
                                key: result.students[i]._id,
                                name: result.students[i].fullName_lower,
                                email: result.students[i].email,
                            };
                            destination.push(data);
                        }
                        this.studentsByGroupTarget(destination, id)
                    } else {
                        console.log('success false');
                        console.log(result);
                    }
                })
            .catch(
                (reason) => {
                    // console.log(reason.message)
                });
    }
    studentsByGroupTarget = (results, id) => {
        //console.log("Enter studentsByGroupTarget")
        return fetchAsync(urlGroups + id + "/students", "", "GET", "")
            .then(
                (result) => {
                    if (result.success === true) {
                        let dataSource = results
                        let destination = []
                        for (let i = 0; i < result.students.length; i++) {
                            let data = {
                                key: result.students[i]._id,
                                name: result.students[i].fullName_lower,
                                email: result.students[i].email,
                            };
                            dataSource.push(data)
                            destination.push(data.key);
                        }
                        //console.log("END FOR")
                        this.setState({
                            students: dataSource,
                            targetKeys: destination,
                            loading: false
                        })
                    } else {
                        console.log('success false');
                        console.log(result);
                    }
                })
            .catch(
                (reason) => {
                    // console.log(reason.message)
                });
    }


    //Funcion que cambia de grupos a los alumnos seleccionados
    //Recibe:
    //idStudentArray: Arreglo de alumnos a cambiar
    //idGroupSource: idGrupo origen
    //idGroupTarget: id del grupo de destino
    //targetKeys : La colección de los targetKeys actualizados para que se refleje el cambio en las tablas Transfer


    changeStudentGroup(idStudentArray, idGroupSource, idGroupTarget, targetKeys) {
        if (idStudentArray && idGroupSource && idGroupTarget) {

            //Funcionamiento , el primer return regresa una promesa que se asigna a los resultados del map
            // El segundo return del then es la respuesta del promise , cuando se termina de ejecutar podemos 
            // leer los datos , en este caso el dato del result
            let body = {}
            body.group = {
                "id": idGroupTarget
            }
            var promises = idStudentArray.map(id => {
                return fetchAsync(urlStudents + id + "/groups/" + idGroupSource + "?force=true", JSON.stringify(body), "PUT", "")
                    .then(data => {
                        // let objectResult = data
                        // objectResult.id=id
                        return data
                    })
            })

            //Promise all recibe el arreglo de promesas que regreso el map, esté espera a que terminen
            // de ejecutarse todas y en cuando termina ejecuta el then , esté then recibe un arreglo de 
            // resultados , estos resultados son los que nos regresa el segundo return de la operación anterior
            // se puede leer todos los resultados en busca de algún error e identificarlos para mostrarle al usuario
            // las operaciones necesarias para solucionar su error
            Promise.all(promises).then(results => {
                if (results.length > 0) {
                    let objectError = []
                    for (let i = 0; i < results.length; i++) {
                        console.log(results[i])
                        if (!results[i].success) {
                            objectError.push(results[i])
                        }
                    }
                    closeLoading()
                    if (objectError.length > 0) {
                        this.setState({
                            isDisabledTransfer: false
                        })
                        let message = "Ocurrio un error al cambiar " + objectError.length + " alumnos."
                        this.ErrorMessage(message)
                        this.ErrorNotification(objectError)
                    } else {
                        this.setState({
                            targetKeys: targetKeys,
                            isDisabledTransfer: false
                        })
                        this.SuccessMessage("Movimiento efectuado correctamente.")
                    }
                } else {

                }
            });

        } else {
            this.ErrorMessage("Falta algún argumento para realizar la operación.")
        }
    }


    //La siguiente es la operación de red para el cambio de grupos , existen varios parametros que recibe
    // se pueden forzar los cambios para que aunque ya no haya grupos se efectue la operación de red 


    /****************************************************************
    *****************************************************************
    *****************************Render******************************
    *****************************************************************
    ****************************************************************/

    render() {
        const locale = {
            itemUnit: 'registros',
            itemsUnit: 'registros',
            searchPlaceholder: 'Buscar',
            notFoundContent: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sin Resultados" />
        }

        const ClassNames = {
            modal: "modal-medium modal-full-height",
        }
        const Header = this.state.destinationInfo !== undefined ? [this.props.grupo.name, this.state.destinationInfo.name] : ["", ""]

        return (
            <Modal
                open={this.props.valueModal}
                onClose={this.closeModal}
                center={true}
                closeOnEsc={false}
                showCloseIcon={false}
                closeOnOverlayClick={false}
                classNames={ClassNames}
            >

                <Row className="marginTableInfoG">
                    <Col xs={12} sm={12} md={8}>
                        <p className="titleInfoTable">Cambio de alumnos del grupo: <strong>{this.props.grupo.name}</strong></p>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                        <Button size="sm" className="btn btn-secondary float-right" style={{ marginRight: "15px" }}
                            onClick={() => this.onCloseModal()}>
                            <p>Cerrar</p>
                        </Button>
                    </Col>
                </Row>
                {!this.state.targetId ?
                    <Row className="justify-content-center">
                        <div>
                            <p>Seleccionar grupo destino</p>
                            <ButtonToolbar>
                                <Button color="success" size="sm"
                                    onClick={() => this.openSelectGroup()}>
                                    {/* onClick={() => this.openModalSelectGroup(true)}> */}
                                    <p>Seleccionar Grupo</p>
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </Row> :
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {this.state.loading ? <LoadingComponent /> :
                                <Transfer
                                    dataSource={this.state.students}
                                    titles={Header}
                                    showSearch
                                    listStyle={{
                                        width: "45%",
                                        height: "450px",
                                    }}
                                    targetKeys={this.state.targetKeys}
                                    filterOption={this.filterOption}
                                    onChange={this.handleChange}
                                    onSearch={this.handleSearch}
                                    disabled={this.state.isDisabledTransfer}
                                    locale={locale}
                                    render={this.renderItem}
                                />
                            }
                        </Col>
                    </Row>
                }
                {
                    this.state.openSelectorGroupModal ?
                        <SelectorGroup
                            valueModal={this.state.openSelectorGroupModal}
                            close={this.closeSelectGroup}
                            saveNewGroup={this.setTargetId}
                            newGroup={this.state.destinationInfo !== undefined ? this.state.destinationInfo : ""}
                            oldIdGroup={this.props.grupo._id}
                        /> : ""
                }
            </Modal>
        )
    }
}
export default MasiveChangesStudents
