import React from 'react';
import { semanaLetra, semanaDias } from '../Fichas/constants';
import moment from 'moment';
import 'moment/locale/es';

const dateFormat = 'DD/MM/YYYY';

export const unAvailableMessage = (dataGroup) => {
  let start = moment.utc(dataGroup.startDate).local().format(dateFormat);
  let end = moment.utc(dataGroup.endDate).local().format(dateFormat);
  const newArray = dataGroup.schedules.map(a => ({ ...a }))
  let datos = <>
    <div>Grupo: <strong>{dataGroup.name}</strong>
      <div>Estatus: {dataGroup.active ? "Habilitado" : "Inhabilitado"}</div>
      <div>Inicia: {start}</div>
      <div>Termina: {end}</div>
      <div>Horario: {newSchedules(newArray)}</div>
      <div>Curso: {dataGroup.courseInfo.name}</div>
      <div>Sede: {dataGroup.locationInfo.name}</div>
      <div>Salón: {dataGroup.classRoomInfo.name}</div>
    </div>
  </>
  return datos
}

//elimina los ids de los horarios
export const deleteIds = (array) => {
  array.forEach((value) => {
    delete value._id
  })
  return array
}

//genera un nuevo array solo con las iniciales de cada dia en minuscula
export const dayWeekLetter = (schedules) => {

  schedules.forEach((horario) => {

    switch (horario.day.toString()) {
      case "0":
        horario.day = semanaLetra[horario.day]
        break;

      case "1":
        horario.day = semanaLetra[horario.day];
        break;

      case "2":
        horario.day = semanaLetra[horario.day];
        break;

      case "3":
        horario.day = semanaLetra[horario.day];
        break;

      case "4":
        horario.day = semanaLetra[horario.day];
        break;
      case "5":
        horario.day = semanaLetra[horario.day];
        break;

      case "6":
        horario.day = semanaLetra[horario.day];
        break;
      default:
        break;
    }
  })

  return schedules

}

//genera un nuevo array solo con las dos iniciales de cada dia en minuscula
export const twoLettersWeek = (schedules) => {
  schedules.forEach((horario) => {
    switch (horario.day.toString()) {
      case "0":
        horario.day = semanaDias[horario.day]
        break;

      case "1":
        horario.day = semanaDias[horario.day];
        break;

      case "2":
        horario.day = semanaDias[horario.day];
        break;

      case "3":
        horario.day = semanaDias[horario.day];
        break;

      case "4":
        horario.day = semanaDias[horario.day];
        break;
      case "5":

        horario.day = semanaDias[horario.day];
        break;

      case "6":
        horario.day = semanaDias[horario.day];
        break;
      default:
        break;
    }
  })
  return schedules
}

//agrega dos puntos a la hora 0930 =  09:30
const formatoHora = (hora) => {
  let uno = hora.slice(0, 2);
  let dos = hora.slice(2, 4);
  return uno + ":" + dos
}

//genera un formato para los schedules
export const newSchedules = (cell, row) => {
  const cellCopy = cell.map(a => ({ ...a }))
  let compiladoDias = "";
  let horarioFinal = []
  let horarios = twoLettersWeek(cellCopy)
  //elimina los horarios duplicados
  const filteredArr = horarios.reduce((acc, current) => {
    const x = acc.find(item => item.startHour === current.startHour);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  //guarda los dias por horario
  filteredArr.forEach((dato) => {
    let grupoHorarios = []
    for (let i = 0; i < horarios.length; i++) {
      if (dato.startHour === horarios[i].startHour) {
        grupoHorarios.push(horarios[i])
      }
    }
    horarioFinal.push(grupoHorarios)
  })
  //genera un compilado de dias por horario
  horarioFinal.forEach((value) => {
    let horas = "Horario: " + formatoHora(value[0].startHour) + " a " + formatoHora(value[0].endHour)
    for (let i = 0; i < value.length; i++) {
      compiladoDias = compiladoDias + " " + value[i].day

    }
    compiladoDias = compiladoDias + " " + horas + "_"
  })
  let partes = compiladoDias.split('_')
  return partes.map((value) => value)
}

export const removeItemFromArray = (array, item) => {
  var index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
    return array
  }
  return array
}


export const isInArray = (array, item) => {
  var index = array.indexOf(item);
  if (index > -1) {
    return true
  }
  return false
}

export const locale = {
  "lang": {
    "locale": "es_ES",
    "placeholder": "Selecciona una fecha",
    "rangePlaceholder": ["Inicio", "Fin"],
    "today": "Hoy",
    "now": "Ahora",
    "backToToday": "Regresar a hoy.",
    "ok": "Ok",
    "clear": "Borrar",
    "month": "Mes",
    "year": "Año",
    "timeSelect": "Selecciona una hora",
    "dateSelect": "Selecciona una fecha",
    "monthSelect": "Selecciona un més",
    "yearSelect": "Selecciona un año",
    "decadeSelect": "Selecciona una decada",
    "yearFormat": "YYYY",
    "dateFormat": "DD/MM/YYYY",
    "dayFormat": "D",
    "dateTimeFormat": "DD/MM/YYYY HH:mm:ss",
    "monthFormat": "MMMM",
    "monthBeforeYear": true,
    "previousMonth": "Mes anterior (PageUp)",
    "nextMonth": "Siguiente més (PageDown)",
    "previousYear": "Ùltimo año (Control + izquierda)",
    "nextYear": "Siguiente año (Control + derecha)",
    "previousDecade": "Ultima decada",
    "nextDecade": "Suiguiente decada",
    "previousCentury": "Ultimo siglo",
    "nextCentury": "Siguiente siglo"
  },
  "timePickerLocale": {
    "placeholder": "Selecciona una hora"
  },
  "dateFormat": "DD-MM-YYYY",
  "dateTimeFormat": "DD-MM-YYYY HH:mm:ss",
  "weekFormat": "wo-YYYY",
  "monthFormat": "MM-YYYY"
}