import React, { Component } from "react";
import { Col, Card } from "reactstrap";
import { newSchedules } from "../../../../../../helpers/Globals/funciones";
import moment from "moment";

class CardGroup extends Component {
  //metodo para dar formato a la fecha obtenida
  getDate = () => {
    if (this.props.grupo.date) {
      return moment(this.props.grupo.date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  };

  render() {
    const newArray =
      this.props.grupo.schedules !== undefined
        ? this.props.grupo.schedules
        : [];
    return (
      <Col sm={12}>
        <Card className="pricing-card pricing-card--warning">
          {this.props.grupo !== "" && (
            <>
              <h4 className="mt-3">
                Platica: - <b>{this.props.grupo.name}</b>
              </h4>
              <h4 className="mt-1">
                <b>
                  {this.props.grupo.schedules !== undefined
                    ? newSchedules(newArray)
                    : ""}
                </b>
              </h4>
              <h4 className="mt-1">
                Fecha: <b>{this.getDate()}</b>
              </h4>
              {this.props.grupo?.classRoom && (
                <>
                  <h4 className="mt-1">
                    Sede: <b>{this.props.grupo?.classRoom?.location?.name}</b>
                  </h4>
                  <h4 className="mt-1">
                    Salón: <b>{this.props.grupo?.classRoom?.name}</b>
                  </h4>
                </>
              )}
            </>
          )}
        </Card>
      </Col>
    );
  }
}
export default CardGroup;
