import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ChaptersList from './components/chapters/index';
// import MobileChapter from './components/chapters/mobileChapter';
import ChaptersContentPlaylist from './components/chaptersContentPlaylist/index';
import ModalAddChapter from './components/ModalAddChapter';
import { Link } from 'react-router-dom';
import { fetchAsync, urlCourses, urlPlaylistByCourse, urlPlaylist, urlVideo, urlQuiz, urlSocket } from "../../helpers/Globals/globals";
import { Breadcrumb, PageHeader } from 'antd';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Element, Events, scroller } from 'react-scroll';
import openSocket from 'socket.io-client';
import AddIcon from 'mdi-react/AddIcon';
import Can from '../../helpers/Secure/Can';
import LoadingComponent from "../../helpers/LoadingComponent";
import EmptyComponent from "../../helpers/Empty/EmptyComponent"
import ModalVideoView from './components/ModalVideoView';
import ModalAddVideo from "./components/ModalAddVideo";
import TransferContent from './components/ModalTransferContent';
import EditVideo from './components/editVideo';
import LinkMaterial from './components/LinkMaterial';
import { TYPE_PLAYLIST, TYPE_QUIZ, TYPE_VIDEO } from '../../helpers/Globals/constants';
import 'antd/es/page-header/style/index.css'

const Material = (props) => {

  const history = useHistory();
  const match = useRouteMatch();

  const [selectedCourse, setSelectedCourse] = useState()
  const [selectedCoursePlaylist, setSelectedCoursePlaylist] = useState([])
  const [modalChapter, setModalChapter] = useState(false)
  const [modalChapterEdit, setModalChapterEdit] = useState(false)
  const [editChapterInfo, setEditChapterInfo] = useState()
  const [loading, setLoading] = useState(false)

  const [selectedChapter, setSelectedChapter] = useState("")
  const [selectedChapterContentPlaylist, setSelectedChapterContentPlaylist] = useState("")
  const [loadingPlaylist, setLoadingPlaylist] = useState(false)
  const [modalViewVideo, setModalViewVideo] = useState(false)
  const [viewVideoInfo, setViewVideoInfo] = useState("")

  const [addSubChapter, setAddSubChapter] = useState(false)
  const [addVideo, setAddVideo] = useState(false)
  const [editVideo, setEditVideo] = useState(false)
  const [editVideoInfo, setEditVideoInfo] = useState("")
  const [trasnferContent, setTrasnferContent] = useState(false)
  const [chaptersTracker, setChaptersTracker] = useState([])
  const [modalLink, setModalLink] = useState(false)

  useEffect(() => {
    const socket = openSocket(urlSocket, { secure: true });
    socket.emit('join', 'update_material_event');
    socket.on('update_material_event', updateSocketRecive)
    socket.on('disconnect', function () { console.log('disconnected') });
    socket.on('reconnect', () => {
      socket.emit('join', 'update_material_event')
    })

    getCourseInfo(match.params.idCourse)
    getChapters(match.params.idCourse)

    //this will run on exit or unmount
    return () => {
      socket.off('update_material_event')
      socket.disconnect()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedChapter !== undefined && !loadingPlaylist)
      scrollToWithContainer(selectedChapter._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPlaylist])

  const updateSocketRecive = (data) => {
    if (data && selectedChapterContentPlaylist.contentPlaylist !== undefined) {
      let newArray = [...selectedChapterContentPlaylist.contentPlaylist]
      let indexModify = newArray.findIndex(i => i != null && i.transcodeId === data.video.transcodeId);
      if (indexModify >= 0) {
        if (data.event === "UPDATE_PROGRESS") {
          if (data.progress > newArray[indexModify].progress || newArray[indexModify].progress === undefined) {
            newArray[indexModify].progress = data.progress
            setSelectedChapterContentPlaylist({
              ...selectedChapterContentPlaylist,
              contentPlaylist: newArray
            })
          }
        } else if (data.event === "PROCESSING" || data.event === "COMPLETE") {
          newArray[indexModify] = { ...data.video }
          newArray[indexModify].statusTranscode = data.event
          newArray[indexModify].type = "2"
          setSelectedChapterContentPlaylist({
            ...selectedChapterContentPlaylist,
            contentPlaylist: newArray
          })
        }
      }
    }
  }



  // funcion que re-ordena la lista de video y capitulos
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const openEdit = (chapter) => {
    setEditChapterInfo(chapter)
    setModalChapterEdit(true)
  }

  const updateAfterAdd = (chapter) => {
    if (chapter.hasOwnProperty("parent")) {
      let newArray = [...selectedChapterContentPlaylist.contentPlaylist]
      newArray.unshift(chapter)
      setSelectedChapterContentPlaylist({
        ...selectedChapterContentPlaylist,
        contentPlaylist: newArray
      })
    } else {
      let newArray = [...selectedCoursePlaylist]
      newArray.unshift(chapter)
      setSelectedCoursePlaylist(newArray)
    }
    // getChapters(selectedCourse._id)
  }

  const updateAfterEdit = (chapter) => {
    if (editChapterInfo.hasOwnProperty("parent")) {
      let newArray = [...selectedChapterContentPlaylist.contentPlaylist]
      newArray[selectedChapterContentPlaylist.contentPlaylist.findIndex((obj => obj._id === chapter._id))] = chapter
      //guardamos en el estado el nuevo ordenamiento
      setSelectedChapterContentPlaylist({
        ...selectedChapterContentPlaylist,
        contentPlaylist: newArray
      })
      // getChaptersContentPlaylist(selectedChapter._id)
    } else {
      let newArray = [...selectedCoursePlaylist]
      newArray[selectedCoursePlaylist.findIndex((obj => obj._id === chapter._id))] = chapter
      //guardamos en el estado el nuevo ordenamiento
      setSelectedCoursePlaylist(newArray)
      // getChapters(selectedCourse._id)
    }
  }


  /////////////functions for the playlist of the chapters//////////////
  const saveSelectedChapter = (chapter) => {
    setLoadingPlaylist(true)
    setSelectedChapter(chapter)
    getChaptersContentPlaylist(chapter._id)
    if (chapter.hasOwnProperty("parent")) {
      let saved = chaptersTracker.findIndex(obj => obj._id === chapter._id)
      if (saved === -1) {
        let traker = [...chaptersTracker]
        traker.push(chapter)
        setChaptersTracker(traker)
      }
    } else {
      let newArray = []
      newArray.push(chapter)
      setChaptersTracker(newArray)
    }
  }
  const openViewVideo = (video) => {
    setModalViewVideo(true)
    setViewVideoInfo(video)
  }

  const updateContentPlaylist = (item) => {
    getChaptersContentPlaylist(selectedChapter._id)
  }

  const updateContentPlaylistVideo = (video) => {
    let newArray = [...selectedChapterContentPlaylist.contentPlaylist]
    newArray[selectedChapterContentPlaylist.contentPlaylist.findIndex((obj => obj._id === video._id))] = video
    //guardamos en el estado el nuevo ordenamiento
    setSelectedChapterContentPlaylist({
      ...selectedChapterContentPlaylist,
      contentPlaylist: newArray
    })
  }

  const closeTransferContent = () => {
    getChaptersContentPlaylist(selectedChapter._id)
    setTrasnferContent(false)
  }

  const openEditVideo = (video) => {
    setEditVideoInfo(video)
    setEditVideo(true)
  }

  const backPlaylist = async () => {
    await getChaptersContentPlaylist(selectedChapterContentPlaylist.parent)
    let deleteArray = [...chaptersTracker]
    deleteArray.pop()
    setChaptersTracker(deleteArray)
  }

  const scrollToWithContainer = (element) => {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });
      scroller.scrollTo('scroll-container', {
        duration: 0,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    });
    goToContainer.then(() =>
      scroller.scrollTo(element, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      }));
  }

  /******************************************************
   * ****************************************************
   * ****************************************************
   * *************** NETWORK OPERATIONS *****************
   * ****************************************************
   * ****************************************************
   * ****************************************************
   * ****************************************************
   */

  const removeChapter = (idChapter) => {
    fetchAsync(urlPlaylist + idChapter, "", "DELETE")
      .then((data) => {
        if (data.success) {
          let newArray = selectedCoursePlaylist.filter(chapter => chapter._id !== idChapter)
          setSelectedCoursePlaylist(newArray)
        }
      }).catch((error) => {
        console.log(error)
      })
  }

  const getCourseInfo = (idCourse) => {
    fetchAsync(urlCourses + idCourse, "", "GET")
      .then((data) => {
        if (data.success) {
          setSelectedCourse(data.course)
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const getChapters = (idCourse) => {
    setLoading(true)
    fetchAsync(urlPlaylistByCourse + idCourse, "", "GET")
      .then((data) => {
        if (data.success) {
          setSelectedCoursePlaylist(data.playlists)
        }
        setLoading(false)
      }).catch(err => {
        console.log(err)
      })
  }


  //funcion que maneja el drag and drop 
  const onDragVideoChapter = (result) => {
    if (!result.destination) { return; }
    const items = reorder(  //pasamos los datos para el reordenamiento
      selectedCoursePlaylist,
      result.source.index,
      result.destination.index
    );
    setSelectedCoursePlaylist(items)    //guardamos en el estado el nuevo ordenamiento
    let body = []//generamos el array de obj con los datos a actualizar 
    for (let a = 0; a < items.length; a++) {
      let newObj = {
        order: a + 1,
        _id: items[a]._id,
        type: items[a].type
      }
      body.push(newObj)
    }
    fetchAsync(urlPlaylist + "updateOrder/course/" + selectedCourse._id, JSON.stringify(body), "PUT", "default")
      .then((data) => {
        if (!data.success) {
          getChapters(selectedCourse._id)
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  const getChaptersContentPlaylist = (idChapter) => {
    setLoadingPlaylist(true)
    return fetchAsync(urlPlaylist + idChapter, "", "GET")
      .then((data) => {
        if (data.success) {
          setSelectedChapterContentPlaylist(data.playlist)
          setLoadingPlaylist(false)
        }
      }).catch(err => {
        console.log(err)
        setLoadingPlaylist(false)
      })
  }

  //funcion que maneja el drag and drop 
  const onDragPlaylistContent = (result) => {
    if (!result.destination) { return; }
    const items = reorder( //pasamos los datos para el reordenamiento
      selectedChapterContentPlaylist.contentPlaylist,
      result.source.index,
      result.destination.index
    );
    setSelectedChapterContentPlaylist({//guardamos en el estado el nuevo ordenamiento
      ...selectedChapterContentPlaylist,
      contentPlaylist: items
    })
    let body = [] //generamos el array de obj con los datos a actualizar 
    for (let a = 0; a < items.length; a++) {
      let newObj = {
        order: a + 1,
        _id: items[a]._id,
        type: items[a].type
      }
      body.push(newObj)
    }
    fetchAsync(urlPlaylist + "updateOrder/" + selectedChapter._id, JSON.stringify(body), "PUT", "default")
      .then((data) => {
        if (data.success) {
          getChaptersContentPlaylist(selectedChapter._id)
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  const removeItemPlaylist = (item) => {
    switch (item.type) {
      case TYPE_PLAYLIST:
        fetchAsync(urlPlaylist + item._id, "", "DELETE")
          .then((data) => {
            if (data.success) {
              let newArray = selectedChapterContentPlaylist.contentPlaylist.filter(chapter => chapter._id !== item._id)
              setSelectedChapterContentPlaylist({
                ...selectedChapterContentPlaylist,
                contentPlaylist: newArray
              })
            }
          }).catch((error) => {
            console.log(error)
          })
        break
      case TYPE_VIDEO:
        fetchAsync(urlVideo + item._id, "", "DELETE")
          .then((data) => {
            if (data.success) {
              let newArray = selectedChapterContentPlaylist.contentPlaylist.filter(chapter => chapter._id !== item._id)
              setSelectedChapterContentPlaylist({
                ...selectedChapterContentPlaylist,
                contentPlaylist: newArray
              })
            }
          }).catch((error) => {
            console.log(error)
          })
        break
      case TYPE_QUIZ:
        fetchAsync(urlQuiz + item._id, "", "DELETE", "")
          .then((data) => {
            if (data.success) {
              let newArray = selectedChapterContentPlaylist.contentPlaylist.filter(chapter => chapter._id !== item._id)
              setSelectedChapterContentPlaylist({
                ...selectedChapterContentPlaylist,
                contentPlaylist: newArray
              })
            }
          }).catch((reason) => {
            console.log(reason.message)
          });
        break
      default:
        break
    }
  }

  /****************************************************
 * ****************************************************
 * ****************************************************
 * *************** RETURN ELEMENTS VIEW ***************
 * ****************************************************
 * ****************************************************
 * ****************************************************
 */

  return (
    <Container className="container_material">
      <PageHeader
        onBack={() => history.push('/cursos')}
        className="material_page_header_global"
        title={selectedCourse !== undefined ? selectedCourse.name : ""}
        // subTitle="This is a subtitle"
        extra={[
          <Can do="post" on="/playlists/">
            <Button className="btn" size="sm" color="add"
              onClick={() => setModalChapter(true)}>
              <p><AddIcon />Nuevo cápitulo</p>
            </Button>
          </Can>
        ]}
      />
      <Row>
        <Col xs="12" md="4" className="chapters_sidebar">
          <div className="div_chapters_list">
            {!loading ? selectedCoursePlaylist.length > 0 ?
              <DragDropContext onDragEnd={onDragVideoChapter}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <ChaptersList selectedCoursePlaylist={selectedCoursePlaylist}
                        openEdit={openEdit} removeChapter={removeChapter} setSelectedChapter={setSelectedChapter}
                        selectedChapter={selectedChapter} saveSelectedChapter={saveSelectedChapter}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              : <EmptyComponent message="Aún no hay material en este cápitulo" /> : <LoadingComponent />}
          </div>
          {/* <div>
            <MobileChapter electedCoursePlaylist={selectedCoursePlaylist}
              openEdit={openEdit} removeChapter={removeChapter} />
          </div> */}
        </Col>
        <Element name="container_chapters" className="content_chapters_playlist col-12 col-md-8" id="scroll-container">
          <PageHeader
            onBack={backPlaylist}
            backIcon={selectedChapterContentPlaylist !== undefined && selectedChapterContentPlaylist.parent !== undefined ? undefined : false}
            title={selectedChapterContentPlaylist !== undefined ? chaptersTracker.map((item) => {
              return (
                <Breadcrumb.Item>
                  {item.name}
                </Breadcrumb.Item>)
            }) : ""}
            // breadcrumb={{ routes: generateBreadcrumbs(), separator: ">" }}
            extra={selectedChapterContentPlaylist !== "" ? [
              <Can do="post" on="/playlists/">
                <UncontrolledDropdown className="float-right">
                  <DropdownToggle className="align-right btn-add dropdown">
                    <p><AddIcon />Agregar material</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown__menu">
                    <DropdownItem onClick={() => setAddVideo(true)}>Agregar Video</DropdownItem>
                    <DropdownItem >
                      <Link
                        to={{ pathname: "/cuestionarios/" + match.params.idCourse + "/" + selectedChapter._id }}
                        className="col-xs-1">
                        <p style={{ color: "black" }}>Agregar Cuestionarios</p>
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => setAddSubChapter(true)}>Agregar Capítulo</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => setTrasnferContent(true)}>Mover material</DropdownItem>
                    <DropdownItem onClick={() => setModalLink(true)}>Enlazar material</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Can>
            ] : []}
          />
          <div className="div_playlist_chapter">
            <DragDropContext onDragEnd={onDragPlaylistContent}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {loadingPlaylist ? <LoadingComponent /> :
                      selectedChapterContentPlaylist !== "" && selectedChapterContentPlaylist.contentPlaylist.length > 0 ?
                        selectedChapterContentPlaylist.contentPlaylist.map((content, index) => {
                          return <ChaptersContentPlaylist key={index} contentInfo={content}
                            openViewVideo={openViewVideo} idDD={index} saveSelectedChapter={saveSelectedChapter}
                            openEdit={openEdit} removeItemPlaylist={removeItemPlaylist} openEditVideo={openEditVideo}
                            selectedChapter={selectedChapter} selectedCourse={selectedCourse}
                          />
                        }) : <EmptyComponent message="Aún no hay material en este cápitulo , Agrega material y lo visualizarás aquí" />
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Element>
      </Row>
      {modalChapter ? //add new chapter
        <ModalAddChapter
          value={modalChapter}
          closeModal={setModalChapter}
          idCourse={selectedCourse._id}
          updateContent={updateAfterAdd}
        /> : null}
      {modalChapterEdit ?
        <ModalAddChapter //edit chapters
          value={modalChapterEdit}
          closeModal={setModalChapterEdit}
          editChapterInfo={editChapterInfo}
          editar={true}
          updateContent={updateAfterEdit}
        /> : null}

      {modalViewVideo ?
        <ModalVideoView
          value={modalViewVideo}
          closeModal={setModalViewVideo}
          videoObj={viewVideoInfo}
        />
        : null}
      {addSubChapter ? //add Sub-chapters
        <ModalAddChapter
          value={addSubChapter}
          closeModal={setAddSubChapter}
          idParent={selectedChapter._id}
          updateContent={updateAfterAdd}
        />
        : null}
      {addVideo ?
        <ModalAddVideo //show video
          value={addVideo}
          closeModal={setAddVideo}
          idParent={selectedChapter._id}
          updateContent={updateContentPlaylist}
        />
        : null}
      {editVideo ?
        <EditVideo
          valueModal={editVideo}
          close={setEditVideo}
          updateContent={updateContentPlaylistVideo}
          videoInfo={editVideoInfo}
        /> : null}
      {trasnferContent ?
        <TransferContent //change material betweent chapters
          value={trasnferContent}
          onClose={closeTransferContent}
          idPlaylist={selectedChapterContentPlaylist._id}
          idChapter={selectedChapter._id}
          openModalVideoView={openViewVideo}
          idCourse={selectedCourse._id}
        />
        : null}
      {modalLink ?
        <LinkMaterial
          valueModal={modalLink}
          close={setModalLink}
          selectedCourse={selectedCourse}
          selectedChapter={selectedChapter}
        /> : null}
    </Container>
  )
}
export default Material