import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import LoadingComponent from "../../helpers/LoadingComponent";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import TableEmployess from "./components/tableEmployess";
import AddEditEmployee from "./components/addEdit/AddEditEmployee";
import { EmployeeContext } from "./components/context/EmployeeContext";
import SidebarEmployees from "./components/sidebarEmployees";

import styles from "./index.module.scss";

function Employees(props) {
  const {
    getAllEmployees,
    actualEmployee,
    loading,
    allEmployees,
    setIdActualEmployee,
    setActualEmployee,
  } = useContext(EmployeeContext);

  const [valueAddModal, setValueAddModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    getAllEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //abre la ficha lateral
  const showSidebar = () => {
    setShowDrawer(true);
  };

  return (
    <Container className={styles.main_container_employess}>
      <Row>
        <Col xs="12">
          <MainPageHeader
            title="Gestión de Usuarios |"
            subTitle={`Empleados`}
            buttonTitle={"Agregar empleado"}
            buttonOnClick={() => setValueAddModal(true)}
            canActions={["post", "/users/"]}
          />
        </Col>

        <Col sm={12} className={styles.content_table_employess}>
          {loading ? (
            <LoadingComponent />
          ) : (
            <TableEmployess
              allEmployees={allEmployees}
              showSidebar={showSidebar}
              setIdActualEmployee={setIdActualEmployee}
              setActualEmployee={setActualEmployee}
              actualEmployee={actualEmployee}
            />
          )}
        </Col>
      </Row>
      {valueAddModal && (
        <AddEditEmployee
          openAddModal={setValueAddModal}
          valueAddModal={valueAddModal}
          title="Registrar"
        />
      )}
      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SidebarEmployees closeSidebar={() => setShowDrawer(false)} />
        </SidebarRightComponent>
      )}
    </Container>
  );
}
export default Employees;
