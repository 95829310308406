import types from './types'

export const initialState = {
  activeTab: 1,
  student: {
    name: "",
    lastName: "",
    secondLastName: ""
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }
    case types.SET_STUDENT:
      return {
        ...state,
        student: action.payload
      }

    default:
      return { ...state }
  }
}
export default reducer
