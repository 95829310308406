import React, { useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { urlAttendanceQr, fetchAsync } from "../../../helpers/Globals/globals";
import Empty from "../../../helpers/Empty/EmptyComponent";
import SuccessError from "../../../helpers/SucessView/SuccessErrorView";
import LoadingComponent from "../../../helpers/LoadingComponent/index";
import BarcodeReader from "react-barcode-reader";
import TabsModal from "../../Students/components/tabsStudents/TabsModal";
import { Select } from "antd";
import "antd/es/select/style/index.css";
import "antd/es/avatar/style/index.css";
const qrImage = process.env.PUBLIC_URL + "/img/icons/qrCode.png";
const { Option } = Select;

const ScanView = () => {
  const [scanResult, setScanResult] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showTabsStudent, setShowTabsStudent] = useState(false);

  const handleScan = (data) => {
    setLoading(true);
    let validation = new RegExp(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/).test(
      data
    );
    if (validation) {
      updateAttendance(data);
    } else {
      let data = {
        success: false,
        error: "El código escaneado no es de un usuario del sistema.",
      };
      setScanResult(data);
      setLoading(false);
    }
  };
  const handleError = (err) => {
    setLoading(true);
    let data = {
      success: false,
      error:
        "Ocurrio un error desconocido, probablemente sea problema del equipo de escanéo: " +
        err,
    };
    setScanResult(data);
    setLoading(false);
  };
  const updateAttendance = (id) => {
    fetchAsync(urlAttendanceQr + id, "", "PUT")
      .then((result) => {
        setLoading(false);
        if (result.success) {
          let data = { ...result };
          setScanResult(data);
        } else {
          let data = { ...result };
          setScanResult(data);
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };
  return (
    <Container>
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <Card className="pricing-card pricing-card--success">
        <div>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Selecciona el curso a asignar"
            optionFilterProp="children"
            // onChange={onChange}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="1">Razonamiento</Option>
            <Option value="2">Ciencias Sociales</Option>
            <Option value="3">Mátematicas</Option>
            <Option value="4">Ciencias Experimentales</Option>
          </Select>
        </div>
        {loading ? (
          <CardBody className="pricing-card__body">
            <div className="svg-container stepFinalizar">
              {" "}
              <LoadingComponent message="Cargando..!" />
            </div>
          </CardBody>
        ) : scanResult === undefined ? (
          <Empty
            image={qrImage}
            message="Configura el dispositivo y escanea un código para comenzar."
          />
        ) : (
          <CardBody className="container_qrCode_result pricing-card__body">
            <div className="svg-container stepFinalizar">
              <SuccessError type={scanResult.success ? "success" : "error"} />
            </div>
            <h3 className="pricing-card__plan mt-3">
              {scanResult.success ? "Asignado éxitosamente" : scanResult.error}
            </h3>
          </CardBody>
        )}
      </Card>

      {showTabsStudent && (
        <TabsModal
          valueModal={showTabsStudent}
          close={setShowTabsStudent}
          studentInfo={
            scanResult
              ? scanResult.student
                ? { _id: scanResult.student._id }
                : ""
              : ""
          }
          updateStudentContext={() => ""}
          afterDeleteStudent={() => ""}
          addPaymentsStudent={() => ""}
        />
      )}
    </Container>
  );
};

export default ScanView;
