import React, { useContext, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import PhoneIcon from "mdi-react/PhoneIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import EmailIcon from "mdi-react/EmailIcon";
import EnrollmentContext from "../../../context/EnrollmentContext/EnrollmentContext";
import { showMessageWarning } from "../../../helpers/MessageAndNotificationUtils";
import moment from "moment";

const StepFrom = ({ typeComponent }) => {
  //importamos el context y sus estados y funciones
  const enrollmentContext = useContext(EnrollmentContext);
  const { emailAlreadyExists, studentData, setStudentData } = enrollmentContext;

  const [mailValidation, setMailValidation] = useState(false);

  const validate = () => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (studentData.email !== "") {
      if (re.test(studentData.email) === false) {
        setMailValidation(true);
        showMessageWarning(
          <strong>
            Atención! Correo inválido asegurate que este bien escrito.
          </strong>,
          2
        );
      } else {
        setMailValidation(false);
      }
    }
  };

  return (
    <form className="form form--horizontal wizard__form">
      {typeComponent === "talks" && (
        <h5 className="mb-2">
          {emailAlreadyExists.success &&
            `¡Correo Ya Registrado! El alumno ${
              emailAlreadyExists.student.name
            } ${emailAlreadyExists.student.lastName} ${
              emailAlreadyExists.student.secondLastName
            } 
                      ya fue REGISTRADO el día ${moment(
                        new Date(emailAlreadyExists.student.registerDate)
                      ).format("DD/MM/YYYY")} con este correo: ${
              emailAlreadyExists.student.email
            }`}
        </h5>
      )}
      <div className="form__form-group">
        <span className="form__form-group-label">Nombre</span>
        <div className="form__form-group-field">
          <div
            className="form__form-group-icon cornersIconRounded"
            style={{ backgroundColor: "#EAECEE" }}
          >
            <AccountOutlineIcon />
          </div>
          <input
            maxLength="40"
            name="name"
            className="form-control"
            type="text"
            onChange={(e) => {
              setStudentData(e.target.name, e.target.value);
            }}
            value={studentData.name}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Apellido paterno</span>
        <div className="form__form-group-field">
          <div
            className="form__form-group-icon cornersIconRounded"
            style={{ backgroundColor: "#EAECEE" }}
          >
            <AccountOutlineIcon />
          </div>
          <input
            maxLength="40"
            name="lastName"
            className="form-control inputsRounded"
            type="text"
            onChange={(e) => {
              setStudentData(e.target.name, e.target.value);
            }}
            value={studentData.lastName}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Apellido materno</span>
        <div className="form__form-group-field">
          <div
            className="form__form-group-icon cornersIconRounded"
            style={{ backgroundColor: "#EAECEE" }}
          >
            <AccountOutlineIcon />
          </div>
          <input
            maxLength="40"
            name="secondLastName"
            className="form-control"
            type="text"
            onChange={(e) => {
              setStudentData(e.target.name, e.target.value);
            }}
            value={studentData.secondLastName}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Correo electrónico</span>
        <div className="form__form-group-field">
          <div
            className="form__form-group-icon cornersIconRounded"
            style={{ backgroundColor: "#EAECEE" }}
          >
            <EmailIcon />
          </div>
          <input
            name="email"
            className="form-control iconAlertext"
            type="email"
            onBlur={(event) => {
              validate(event.target.value);
            }}
            onChange={(e) => {
              setStudentData(e.target.name, e.target.value);
            }}
            value={studentData.email}
            placeholder="ejemplo@mail.com"
          />
          {mailValidation === false && emailAlreadyExists !== "" ? (
            emailAlreadyExists.success ? (
              <div className="form__form-group-icon">
                <i
                  className="material-icons ficha_icons margenIconIns"
                  id="UncontrolledTooltipExample"
                >
                  backspace
                </i>
                <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample"
                >
                  {`¡Correo Ya Registrado! El alumno ${
                    emailAlreadyExists.student.name
                  } ${emailAlreadyExists.student.lastName} ${
                    emailAlreadyExists.student.secondLastName
                  } 
                      ya fue REGISTRADO el día ${moment(
                        new Date(emailAlreadyExists.student.registerDate)
                      ).format("DD/MM/YYYY")} con este correo: ${
                    emailAlreadyExists.student.email
                  }`}
                </UncontrolledTooltip>
              </div>
            ) : (
              <div className="form__form-group-icon">
                <i
                  className="material-icons margenIconIns iconCheckColor"
                  id="UncontrolledTooltipExample"
                >
                  beenhere
                </i>
              </div>
            )
          ) : mailValidation ? (
            <div className="form__form-group-icon">
              <i
                className="material-icons ficha_icons margenIconIns"
                id="UncontrolledTooltipExample"
              >
                backspace
              </i>
              <UncontrolledTooltip
                placement="top"
                target="UncontrolledTooltipExample"
              >
                {`¡Correo inválido! revisar que el correo este bien escrito ${studentData.email}`}
              </UncontrolledTooltip>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          Teléfono (Celular 10 digitos)
        </span>
        <div className="form__form-group-field">
          <div
            className="form__form-group-icon cornersIconRounded"
            style={{ backgroundColor: "#EAECEE" }}
          >
            <PhoneIcon />
          </div>
          <input
            name="phoneNumber"
            maxLength="12"
            className="form-control"
            type="Number"
            onChange={(e) => {
              setStudentData(e.target.name, e.target.value);
            }}
            value={studentData.phoneNumber}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Segundo Teléfono</span>
        <div className="form__form-group-field">
          <div
            className="form__form-group-icon cornersIconRounded"
            style={{ backgroundColor: "#EAECEE" }}
          >
            <PhoneIcon />
          </div>
          <input
            name="secondPhoneNumber"
            maxLength="12"
            className="form-control"
            type="Number"
            onChange={(e) => {
              setStudentData(e.target.name, e.target.value);
            }}
            value={studentData.secondPhoneNumber}
          />
        </div>
      </div>
    </form>
  );
};

export default StepFrom;
