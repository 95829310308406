import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, message } from 'antd';
import { addComment } from './fetchFunctions'

import {
  PlusOutlined,
} from '@ant-design/icons';

const { TextArea } = Input;

const WriteComment = ({ studentData, updateAfterAdd }) => {

  const [text, setText] = useState("");
  const [disable, setDisable] = useState(false);

  const onChangeTextarea = (value) => {
    setText(value)
  }

  const onAddComment = () => {
    if (text == "") return message.warning(`Debes escribir un comentario`)
    setDisable(true)

    let utc = new Date().toJSON();
    let body = {
      "student": studentData?._id,
      "date": utc,
      "comment": text.trim()
    }

    addComment(body).then((res) => {
      setText("")
      setDisable(false)
      if (res.success) updateAfterAdd(res.comment)
    }).catch((error) => {
      setDisable(false)
      console.log(error)
      return message.error('Ocurrio un error', 4)
    })
  }

  return (
    <>
      <TextArea rows={3}
        placeholder={`Escribe un comentario`}
        value={text}
        onChange={(e) => onChangeTextarea(e.target.value)}
        maxLength={500}
      />
      <span className="mt-1">Caracteres restantes {500 - text.length}</span>
      <Button key="addButton" type="primary" icon={<PlusOutlined />}
        className="float-right mt-2" disabled={disable}
        onClick={onAddComment}
      >
        Agregar cometario
      </Button>
    </>
  )
}

WriteComment.propTypes = {
  studentData: PropTypes.object.isRequired,
  updateAfterAdd: PropTypes.func.isRequired,
}

export default WriteComment

