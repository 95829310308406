export const STUDENTS_SIDEBAR = 'STUDENTS_SIDEBAR'
export const EMPLOYEES_SIDEBAR = 'EMPLOYEES_SIDEBAR'
export const ROLES_SIDEBAR = 'ROLES_SIDEBAR'
export const LOCATIONS_SIDEBAR = 'LOCATIONS_SIDEBAR'
export const CLASSROOM_SIDEBAR = 'CLASSROOM_SIDEBAR'
export const COURSES_SIDEBAR = 'COURSES_SIDEBAR'
export const APPOINTMENTS_SIDEBAR = 'APPOINTMENTS_SIDEBAR'
export const GROUPS_SIDEBAR = 'GROUPS_SIDEBAR'
export const ASESSMENT_SIDEBAR = 'ASESSMENT_SIDEBAR'
export const TALKS_SIDEBAR = 'TALKS_SIDEBAR'
export const GENERAL_CONFIG_SIDEBAR = 'GENERAL_CONFIG_SIDEBAR'