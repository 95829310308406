import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { showMessageWarning } from "../../../../helpers/MessageAndNotificationUtils";

import styles from "./index.module.scss";

//genera la lista de descuentos agregados
const GeneraLista = ({ allCourses, discounts, clickIcon }) => {
  return (
    <>
      {discounts.map((data) => {
        let obj = allCourses.find((value) => value._id === data.course);

        return (
          <Fragment key={data.course}>
            <Row className={styles.discount_list}>
              <Col xs={5}>
                <span className="mr-2">
                  {obj !== undefined ? obj.name : "Borrado"}
                </span>
              </Col>
              <Col xs={4}>
                <span className="mr-2">Descuento: {data.discount}</span>
              </Col>
              <Col xs={3}>
                <div>
                  <i
                    onClick={() => clickIcon(data.course)}
                    className="material-icons"
                  >
                    delete
                  </i>
                </div>
              </Col>
            </Row>
            <hr></hr>
          </Fragment>
        );
      })}
    </>
  );
};
export default GeneraLista;

export const ValidateCourse = (
  course,
  childrenArray,
  createCourse,
  edit,
  dataCourse,
  onCloseModal,
  editCourse
) => {
  let re = new RegExp("_");

  if (course.name === null || course.name === "") {
    showMessageWarning(<strong>Atención! Debes ingresar un nombre.</strong>, 2);
  } else if (re.test(course.name)) {
    showMessageWarning(
      <strong>Atención! EL nombre no puede tener un guion bajo (_).</strong>,
      2
    );
  } else if (course.duration === null || course.duration === "") {
    showMessageWarning(
      <strong>Atención! Debes ingresar un numero de dias.</strong>,
      2
    );
  } else if (parseInt(course.price) <= 0) {
    showMessageWarning(
      <strong>
        Atención! Debes ingresar un numero positivo mayor a cero.
      </strong>,
      2
    );
  } else if (course.price === null || course.price === "") {
    showMessageWarning(
      <strong>Atención! Debes ingresar una cantidad.</strong>,
      2
    );
  } else if (course.courseType === "") {
    showMessageWarning(
      <strong>Atención! Debes seleccionar un tipo.</strong>,
      2
    );
  } else if (course.courseLevel === "") {
    showMessageWarning(
      <strong>Atención! Debes seleccionar un nivel.</strong>,
      2
    );
  } else if (childrenArray.length < 4 && childrenArray.length > 0) {
    showMessageWarning(
      <strong>
        Atención! Debes agregar 4 cursos si deseas crear un curso con subcursos
        de lo contrario no agregar ninguno.
      </strong>,
      5
    );
  } else if (childrenArray.length > 4) {
    showMessageWarning(
      <strong>
        Atención! Agregaste {childrenArray.length} subcursos. No debes agregar
        mas de 4 cursos .
      </strong>,
      5
    );
  } else {
    if (edit) {
      course.discounts = deleteIdDiscount(course.discounts);
      editCourse(course, dataCourse._id);
      onCloseModal();
    } else {
      //agregamos el array de objetos con los subcursos
      course.children = getIdsCourse(childrenArray);
      createCourse(course);
      onCloseModal();
    }
  }
};

//obtiene los ids de los cursos para el parametro children  en el body
const getIdsCourse = (childrenArray) => {
  let idsArray = [];
  childrenArray.forEach((value) => {
    idsArray.push(value.split("_")[1]);
  });
  return idsArray;
};

//elimina los ids de los cursos para editar
const deleteIdDiscount = (array) => {
  let descuentos = array.map((a) => ({ ...a }));
  descuentos.forEach((value) => {
    delete value._id;
  });

  //console.log(descuentos)
  return descuentos;
};

const Padre =
  "Confirmación : ¿Deseas eliminar el curso? Vas a borrar un curso PADRE comipems";
const Hijo =
  "Confirmación : ¿Deseas eliminar el curso? Vas a borrar un curso HIJO comipems";
const Normal = "Confirmación : ¿Deseas eliminar el curso?";

//muestra el texto para cada curso a borrar
//asigna el texto a mostrar cuando se quiere borrar un curso
export const showTextDelete = (dataCourse) => {
  if (dataCourse.isParent) {
    return Padre;
  } else if (dataCourse.isChildren) {
    return Hijo;
  } else {
    return Normal;
  }
};
