import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Can from "helpers/Secure/Can";
import { Avatar, Button, List, Pagination, Popconfirm, Input } from "antd";
import LoadingComponent from "helpers/LoadingComponent";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import { randomColor } from "helpers/Fichas/constants";

import { contextComments } from "context/CommentContext";

import "antd/es/page-header/style/index.css";
import styles from "./index.module.scss";

const { Search } = Input;

function Comentarios() {
  const { actions, state } = useContext(contextComments);

  const {
    comments,
    paginationComments,
    paginationSize,
    totalComments,
    onSucess,
    badWord,
  } = state;

  const {
    getComments,
    onChangePagination,
    onShowSizeChange,
    onDelete,
    onSearch,
    onchange,
  } = actions;

  useEffect(() => {
    getComments(badWord);
  }, [paginationComments, paginationSize, badWord]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <MainPageHeader
            title="Administración Escolar |"
            subTitle={`Comentarios`}
          />
        </Col>
        {onSucess ? (
          <>
            <Col xs="12" className="mb-2">
              <div className={styles.pagination_search}>
                <Pagination
                  className={styles["pagination-right"]}
                  pageSizeOptions={[50, 100, 150]}
                  total={totalComments}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} de ${totalComments} comentarios`
                  }
                  defaultPageSize={50}
                  defaultCurrent={1}
                  onChange={onChangePagination}
                  onShowSizeChange={onShowSizeChange}
                />
                <Search
                  className={styles.search}
                  onChange={onchange}
                  placeholder="Buscar"
                  onSearch={onSearch}
                  size="large"
                  enterButton
                />
              </div>
            </Col>

            <Col sm={12}>
              <List
                itemLayout="horizontal"
                dataSource={comments}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Can do="delete" on="/videoComments/">
                        <Popconfirm
                          title="Borrar Comentario"
                          description="Deseas borrar el comentario ?"
                          onConfirm={() => {
                            onDelete(item._id);
                          }}
                          okText="Si"
                          cancelText="No"
                        >
                          <Button danger>Borrar</Button>
                        </Popconfirm>
                      </Can>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor: randomColor(
                              item.student?.name[0].toUpperCase()
                            ),
                          }}
                          size="large"
                        >
                          {item.student?.name[0]}
                        </Avatar>
                      }
                      title={
                        <h4>
                          <b>{item.student?.name}</b>/ Video:{" "}
                          {item.video?.title}
                        </h4>
                      }
                      description={<h3>{item.comment}</h3>}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </>
        ) : (
          <LoadingComponent />
        )}
      </Row>
    </Container>
  );
}

export default Comentarios;
