import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import SidebarButton from './SidebarButtonComplement';
import {
  STUDENTS_SIDEBAR, EMPLOYEES_SIDEBAR, ROLES_SIDEBAR, LOCATIONS_SIDEBAR, CLASSROOM_SIDEBAR,
  COURSES_SIDEBAR, GROUPS_SIDEBAR, ASESSMENT_SIDEBAR, TALKS_SIDEBAR, GENERAL_CONFIG_SIDEBAR,
  APPOINTMENTS_SIDEBAR,
} from '../../../helpers/constants/sidebarComplement'
import SidebarStudents from '../../Students/components/sideBarStudents'
import SidebarGroup from 'containers/Grupos/components/Sidebar'
import SidebarTalks from 'containers/Platicas/components/Sidebar'
import SidebarLocation from '../../Sedes/components/sidebarLocation';
import SidebarClassroom from '../../Salones/components/sidebarClassroom';
// import SidebarEmployees from '../../employess/components/sidebarEmployees';
import SidebarCourse from '../../Cursos/components/SidebarCourse';
import SidebarAppoiments from '../../Appoiments/components/sidebarAppoiments';
import SidebarRoles from '../../Roles/components/sidebarRoles';
import SideBarChannels from '../../ExpertConfig/components/sideBarChannels';

const SidebarComplement = ({
  sidebarComplementShow,
  changeSidebarComplementShow,
  sidebarComplementContent,
}) => {

  const sidebarClass = classNames({
    sidebarComplement: true,
    'sidebarComplement--show': sidebarComplementShow,
    'sidebarComplement--collapse': !sidebarComplementShow,
  });

  function renderContent() {
    switch (sidebarComplementContent) {
      case STUDENTS_SIDEBAR:
        return <SidebarStudents />
      // case EMPLOYEES_SIDEBAR:
      //   return <SidebarEmployees />
      case ROLES_SIDEBAR:
        return <SidebarRoles />
      case LOCATIONS_SIDEBAR:
        return <SidebarLocation />
      case CLASSROOM_SIDEBAR:
        return <SidebarClassroom />
      case COURSES_SIDEBAR:
        return <SidebarCourse />
      case APPOINTMENTS_SIDEBAR:
        return <SidebarAppoiments />
      case GROUPS_SIDEBAR:
        return <SidebarGroup />
      case ASESSMENT_SIDEBAR:
        return <SidebarGroup />
      case TALKS_SIDEBAR:
        return <SidebarTalks />
      case GENERAL_CONFIG_SIDEBAR:
        return <SideBarChannels />
      default:
        // console.log("No hay");
        return
    }
  }

  return (
    <div className={sidebarClass}>
      {/* {console.log("RENDER SIDEBAR COMPLEMENT")} */}
      {/* button for mobile to close the sidebar by clicking outside the sidebar */}
      <button type="button" className="sidebarComplement__back" onClick={() => changeSidebarComplementShow(false)} />
      {/* <Scrollbar className="sidebarComplement__scroll" continuousScrolling={true}> */}
      <div className="sidebarComplement__wrapper sidebarComplement__wrapper--desktop">
        <SidebarButton />
        <div className="sidebarComplement_container">
          {renderContent()}
        </div>
      </div>
      {/* </Scrollbar> */}
    </div>
  );
};
export default SidebarComplement;
