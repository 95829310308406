import React, { useEffect, useReducer } from 'react'
import { Button } from 'reactstrap';

import reducer, { types, initialState } from './reducer'
import { getStudentComments } from './fetchFunctions'

import LoadingComponent from "../../../../../helpers/LoadingComponent";
import EmptyCard from '../../../../../helpers/Fichas/emptyCard';
import ShowComment from './ShowComment'
import WriteComment from './WriteComment'
import Can from "../../../../../helpers/Secure/Can";

import styles from './index.module.scss'

const StudentComments = ({ studentData, onCloseModal }) => {

  const [state, dispatch] = useReducer(reducer, initialState)
  const { comments, loading, noComments } = state

  useEffect(() => {
    dispatch({ type: types.SET_LOADING, payload: true })
    getStudentComments(studentData?._id).then(result => {
      dispatch({ type: types.SET_LOADING, payload: false })
      if (result.success) {
        if (result.comments.length === 0) return dispatch({ type: types.SET_NO_COMMENTS, payload: true })
        dispatch({ type: types.SET_COMMENTS, payload: result.comments })
      }
    }).catch((reason) => {
      dispatch({ type: types.SET_LOADING, payload: false })
      console.log(reason.message)
    });
  }, [])


  const updateAfterEdit = (comment) => {
    dispatch({ type: types.UPDATE_AFTER_EDIT, payload: comment })
  }

  const updateAfterAdd = (comment) => {
    dispatch({ type: types.UPDATE_AFTER_ADD, payload: comment })
  }

  const updateAfterDelete = (commentId) => {
    dispatch({ type: types.UPDATE_AFTER_DELETE, payload: commentId })
  }

  return (
    <div className={styles.comments_main_container}>
      <section className={styles.button_container}>
        <Button size="sm" className={`btn btn-secondary float-right`}
          onClick={onCloseModal}>
          Salir
        </Button>
      </section>
      <Can do="post" on="/comments/">
        <div>
          <WriteComment
            studentData={studentData}
            updateAfterAdd={updateAfterAdd} />
        </div>
      </Can>
      {loading ? <LoadingComponent /> :
        !noComments ?
          <section>
            {comments.map((datacomment, index) => {
              return (
                <ShowComment key={index} commentData={datacomment} index={index}
                  updateAfterEdit={updateAfterEdit}
                  updateAfterDelete={updateAfterDelete}
                />)
            })}
          </section> :
          <EmptyCard message="No hay comentarios agregados." />
      }
    </div>
  )
}
export default StudentComments
