import React, { useContext, useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { Modal } from "react-responsive-modal";
import EnrollmentContext from "../../context/EnrollmentContext/EnrollmentContext";
import StepFrom from "./components/StepForm";
import StepCourse from "./components/StepCourse";
import StepGroup from "./components/StepGroup";
import StepPayment from "./components/StepPayment";
import EndRegister from "./components/StepEndRegister";
import ModalDataConfirmation from "./components/ModalDataConfirmation";
import StepConfirmation from "./components/StepConfirmation";
import ValidateForm, { validatePayments } from "./components/Validations";
import { showMessageWarning } from "../../helpers/MessageAndNotificationUtils";

import styles from "./index.module.scss";

function Enrollment({
  openModal,
  close,
  data = null,
  updateAfterFastRegister = "",
  typeComponent = "",
}) {
  const enrollmentContext = useContext(EnrollmentContext);
  const {
    page,
    nextPage,
    backPage,
    studentData,
    emailAlreadyExists,
    selectedCourse,
    selectedGroup,
    payment,
    disableButtonRegister,
    setDataStudentFastRegister,
    studentInfoFastRegister,
    setTalkStudentData,
  } = enrollmentContext;

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [showStepConfirmation, setShowStepConfirmation] = useState(false);

  useEffect(() => {
    if (data !== null && typeComponent === "") {
      if (typeComponent !== "talks") nextPage();
      setDataStudentFastRegister(data);
    }

    if (typeComponent === "talks") {
      const { status, _id, comments, registerDate, attendance, ...newuser } =
        data;
      setTalkStudentData(newuser);
    }
  }, []);

  const onCloseModal = () => {
    close(false);
    if (updateAfterFastRegister !== "" && selectedGroup !== "" && page === 5) {
      updateAfterFastRegister(selectedGroup._id);
    }
  };

  const showStep = () => {
    switch (page) {
      case 1:
        return (
          <div>
            <StepFrom typeComponent={typeComponent} />
          </div>
        );
      case 2:
        return (
          <div>
            <StepCourse typeComponent={typeComponent} />
          </div>
        );
      case 3:
        return (
          <div>
            <StepGroup />
          </div>
        );
      case 4:
        return (
          <div>
            {showStepConfirmation ? (
              <StepConfirmation
                typeComponent={typeComponent}
                setShowStepConfirmation={setShowStepConfirmation}
              />
            ) : (
              <StepPayment />
            )}
          </div>
        );
      case 5:
        return <EndRegister />;
      default:
        return "";
    }
  };

  const validateNextStep = () => {
    switch (page) {
      case 1:
        let next = ValidateForm(
          studentInfoFastRegister ? studentInfoFastRegister : studentData
        );
        if (next && !emailAlreadyExists.success) nextPage();
        break;
      case 2:
        if (selectedCourse === "")
          return showMessageWarning(
            <strong>Atención! Debes seleccionar un curso.</strong>,
            3
          );
        nextPage();
        break;
      case 3:
        if (selectedGroup === "")
          return showMessageWarning(
            <strong>Atención! Debes seleccionar un grupo.</strong>,
            3
          );
        nextPage();
        break;
      default:
        return "";
    }
  };

  const showModalConfirmation = async () => {
    let showModal = await validatePayments(payment, selectedCourse);

    if (showModal) {
      typeComponent === "talks"
        ? setShowStepConfirmation(true)
        : setModalConfirmation(true);
    }
  };

  return typeComponent === "talks" ? (
    <div>
      <Row className="no-gutters">
        <Col xs="12" className="hide_buttons_enrollment_desk">
          {!showStepConfirmation && (
            <Button
              color="warning"
              type="button"
              disabled={disableButtonRegister}
              className={
                page === 4 ? "btn-sm float-right" : "hide_buttons_enrollment"
              }
              onClick={showModalConfirmation}
            >
              Registrar
            </Button>
          )}

          <Button
            color="success"
            type="button"
            className={
              page < 4 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={() => validateNextStep()}
          >
            Siguiente
          </Button>

          {data !== null && page === 2 ? (
            ""
          ) : (
            <Button
              color="success"
              type="button"
              className={
                page !== 1 && page < 5
                  ? "btn-sm float-right mr-2"
                  : "hide_buttons_enrollment mr-2"
              }
              onClick={() => backPage()}
            >
              regresar
            </Button>
          )}

          {typeComponent !== "talks" && (
            <Button
              type="button"
              className={
                page < 5 ? "btn-sm float-left" : "hide_buttons_enrollment"
              }
              onClick={onCloseModal}
            >
              Salir sin guardar
            </Button>
          )}

          {typeComponent !== "talks" && (
            <Button
              color="success"
              type="button"
              className={
                page === 5 ? "btn-sm float-right" : "hide_buttons_enrollment"
              }
              onClick={onCloseModal}
            >
              Salir
            </Button>
          )}
        </Col>
        {/* Columna de botones para mobile */}
        <Col xs="12" className="mb-2 hide_buttons_enrollment_mobile">
          <Button
            color="warning"
            type="button"
            disabled={disableButtonRegister}
            className={
              page === 4 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={showModalConfirmation}
          >
            Registrar
          </Button>

          <div
            type="button"
            className={
              page < 4 ? "div_buttons float-right" : "hide_buttons_enrollment"
            }
            onClick={() => validateNextStep()}
          >
            <i className="material-icons icon_buttons_enroll">arrow_forward</i>
          </div>

          {data !== null && page === 2 ? (
            ""
          ) : (
            <div
              type="button"
              className={
                page !== 1 && page < 5
                  ? "div_buttons float-right mr-3"
                  : "hide_buttons_enrollment"
              }
              onClick={() => backPage()}
            >
              <i className="material-icons icon_buttons_enroll">arrow_back</i>
            </div>
          )}

          <Button
            type="button"
            className={
              page < 5 ? "btn-sm float-left" : "hide_buttons_enrollment"
            }
            onClick={onCloseModal}
          >
            Salir
          </Button>

          <Button
            color="success"
            type="button"
            className={
              page === 5 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={onCloseModal}
          >
            Salir
          </Button>
        </Col>

        <Col xs="12" className="col_wizard text-center">
          <div className="wizard">
            <div className="wizard__steps">
              {data !== null && typeComponent == "" ? (
                ""
              ) : (
                <div
                  className={`wizard__step${
                    page === 1 ? " wizard__step--active" : ""
                  }`}
                >
                  <p>Datos personales</p>
                </div>
              )}
              <div
                className={`wizard__step${
                  page === 2 ? " wizard__step--active" : ""
                }`}
              >
                <p>Seleccionar curso</p>
              </div>
              <div
                className={`wizard__step${
                  page === 3 ? " wizard__step--active" : ""
                }`}
              >
                <p>Seleccionar grupo</p>
              </div>
              <div
                className={`wizard__step${
                  page === 4 ? " wizard__step--active" : ""
                }`}
              >
                <p>Pago</p>
              </div>
              <div
                className={`wizard__step${
                  page === 5 ? " wizard__step--active" : ""
                }`}
              >
                <p>Finalizar Inscripción</p>
              </div>
            </div>
            <div className="wizard__form_wrapper">{showStep()}</div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      center={false}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        modal: `${styles.modal_main_container}`,
      }}
    >
      {/* <Container className="container-fluid"> */}
      <Row className="no-gutters">
        <Col xs="12" className="hide_buttons_enrollment_desk">
          <Button
            color="warning"
            type="button"
            disabled={disableButtonRegister}
            className={
              page === 4 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={showModalConfirmation}
          >
            Registrar
          </Button>

          <Button
            color="success"
            type="button"
            className={
              page < 4 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={() => validateNextStep()}
          >
            Siguiente
          </Button>

          {data !== null && page === 2 ? (
            ""
          ) : (
            <Button
              color="success"
              type="button"
              className={
                page !== 1 && page < 5
                  ? "btn-sm float-right"
                  : "hide_buttons_enrollment"
              }
              onClick={() => backPage()}
            >
              regresar
            </Button>
          )}

          <Button
            type="button"
            className={
              page < 5 ? "btn-sm float-left" : "hide_buttons_enrollment"
            }
            onClick={onCloseModal}
          >
            Salir sin guardar
          </Button>

          <Button
            color="success"
            type="button"
            className={
              page === 5 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={onCloseModal}
          >
            Salir
          </Button>
        </Col>
        {/* Columna de botones para mobile */}
        <Col xs="12" className="mb-2 hide_buttons_enrollment_mobile">
          <Button
            color="warning"
            type="button"
            disabled={disableButtonRegister}
            className={
              page === 4 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={showModalConfirmation}
          >
            Registrar
          </Button>

          <div
            type="button"
            className={
              page < 4 ? "div_buttons float-right" : "hide_buttons_enrollment"
            }
            onClick={() => validateNextStep()}
          >
            <i className="material-icons icon_buttons_enroll">arrow_forward</i>
          </div>

          {data !== null && page === 2 ? (
            ""
          ) : (
            <div
              type="button"
              className={
                page !== 1 && page < 5
                  ? "div_buttons float-right mr-3"
                  : "hide_buttons_enrollment"
              }
              onClick={() => backPage()}
            >
              <i className="material-icons icon_buttons_enroll">arrow_back</i>
            </div>
          )}

          <Button
            type="button"
            className={
              page < 5 ? "btn-sm float-left" : "hide_buttons_enrollment"
            }
            onClick={onCloseModal}
          >
            Salir
          </Button>

          <Button
            color="success"
            type="button"
            className={
              page === 5 ? "btn-sm float-right" : "hide_buttons_enrollment"
            }
            onClick={onCloseModal}
          >
            Salir
          </Button>
        </Col>

        <Col xs="12" className="col_wizard text-center">
          <div className="wizard">
            <div className="wizard__steps">
              {data !== null ? (
                ""
              ) : (
                <div
                  className={`wizard__step${
                    page === 1 ? " wizard__step--active" : ""
                  }`}
                >
                  <p>Datos personales</p>
                </div>
              )}
              <div
                className={`wizard__step${
                  page === 2 ? " wizard__step--active" : ""
                }`}
              >
                <p>Seleccionar curso</p>
              </div>
              <div
                className={`wizard__step${
                  page === 3 ? " wizard__step--active" : ""
                }`}
              >
                <p>Seleccionar grupo</p>
              </div>
              <div
                className={`wizard__step${
                  page === 4 ? " wizard__step--active" : ""
                }`}
              >
                <p>Pago</p>
              </div>
              <div
                className={`wizard__step${
                  page === 5 ? " wizard__step--active" : ""
                }`}
              >
                <p>Finalizar Inscripción</p>
              </div>
            </div>
            <div className="wizard__form_wrapper">{showStep()}</div>
          </div>
        </Col>
      </Row>
      {/* </Container> */}
      {modalConfirmation && (
        <ModalDataConfirmation
          value={modalConfirmation}
          close={setModalConfirmation}
        />
      )}
    </Modal>
  );
}
export default Enrollment;
