import React, { useState, useContext } from 'react';
import { Dropdown, Button } from 'antd';
import DownIcon from 'mdi-react/ChevronDownIcon';
import Can from "../../../helpers/Secure/Can";
import { context } from '../../../context/GlobalContext'
import styles from './index.module.scss'
import 'antd/es/dropdown/style/index.css'
import 'antd/es/button/style/index.css'
import { Link } from 'react-router-dom';
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

function TopbarProfile() {
  const { state, actions } = useContext(context);
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };
  const logOut = () => {
    actions.closeSession();
  }

  const menu = (
    <div className={styles.dropdown_items_container}>
      <Can do="put" on="/users/?">
        <Link className={styles.dropdown_item} to={`/edit/password`}>
          <span className={`${styles.topbar__link_icon} lnr lnr-${"user"}`} />
          <p className={styles.topbar__link_title}>Cambiar contraseña</p>
        </Link>
      </Can>
      <div onClick={logOut} className={styles.dropdown_item}>
        <span className={`${styles.topbar__link_icon} lnr lnr-${"exit"}`} />
        <p className={styles.topbar__link_title}>Cerrar Sesión</p>
      </div>
    </div>
  );


  return (
    <Dropdown overlay={menu} visible={collapse}>
      <Button onClick={toggle} className={styles.buttonCollapse}>
        <img className={styles.topbar__avatar_img} src={Ava} alt="avatar" />
        {<p className={styles.topbar__avatar_name}>{state.user.name + " " + state.user.lastName}</p>}
        <DownIcon className={styles.down_icon} />
      </Button>
    </Dropdown>
  );
}
export default TopbarProfile;
