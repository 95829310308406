import {
  SET_ALL_TALKS,
  SET_ROW_DATA,
  SET_FULLINFO_TALK,
  SET_STUDENT_REMOVE,
  SET_TALK_ACTIVE,
  SOCKET_EVENT_TALKS,
  CREATE_TALK,
  EDIT_TALK,
  DELETE_TALK,
  CHANGE_SKELETON_LOADING,
  SET_STUDENT_CONFIRMATION,
  SET_STUDENT_ATTENDANCE,
  SET_STUDENT_STATUS,
  SET_STUDENT_COMMENT_ADD,
  SET_STUDENT_COMMENT_UPDATE,
  SET_STUDENT_COMMENT_REMOVE,
  UPDATE_AFTER_EDIT,
  UPDATE_AFTER_DELETE_STUDENT_TALK,
} from "../types/index";
import _ from "lodash";

const Reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_SKELETON_LOADING:
      return {
        ...state,
        loadingSkeleton: action.payload,
      };
    case UPDATE_AFTER_EDIT:
      const cloneTalkFullInfo = _.cloneDeep(state.talkFullInfo);
      const indexUpdate = state.talkFullInfo.studentList.findIndex(
        (item) => item._id === action.payload._id
      );
      cloneTalkFullInfo.studentList[indexUpdate] = action.payload;
      console.log("VER_reducer_update");
      console.log(action.payload);
      console.log(indexUpdate);
      console.log(cloneTalkFullInfo);
      console.log("VER_reducer_update");

      return {
        ...state,
        talkFullInfo: cloneTalkFullInfo,
      };
    case SET_ALL_TALKS:
      return {
        ...state,
        talks: action.payload.talks,
        loadingAllTalks: false,
        totalTalks: action.payload.totalTalks,
      };
    case SET_ROW_DATA:
      return {
        ...state,
        talkSelected: action.payload,
      };
    case SET_FULLINFO_TALK:
      return {
        ...state,
        talkFullInfo: action.payload,
        loadingSkeleton: false,
      };

    case SOCKET_EVENT_TALKS: {
      const event = action.payload.event;
      console.log(event);
      switch (event) {
        case "remove_from_talk": {
          const index =
            state.talkFullInfo &&
            state.talkFullInfo.studentList.findIndex(
              (item) => String(item._id) === String(action.payload.studentId)
            );
          console.log("INDEX SOCKET", index);
          if (index >= 0) {
            let talksProv = { ...state.talkFullInfo };
            const listNew = [...talksProv.studentList];
            let newObject = { ...listNew[index] }; // Deep copy to evoid ref and null , prevent re-render state
            newObject.isUnavailable = true;
            newObject.comments = [];
            listNew[index] = newObject; // copy ref of "new" talks student to the full array
            talksProv.studentList = listNew;
            return {
              ...state,
              talkFullInfo: talksProv,
            };
          } else {
            return {
              ...state,
            };
          }
        }
        default:
          return {
            ...state,
          };
      }
    }

    case CREATE_TALK: {
      const newTalks = [...state.talks];
      newTalks.unshift(action.payload.talk);
      newTalks.sort((a, b) => a.date - b.date);
      return {
        ...state,
        talks: newTalks,
      };
    }

    case EDIT_TALK: {
      const index = state.talks.findIndex(
        (item) => String(item._id) === String(action.payload.talk._id)
      );
      if (index >= 0) {
        const newTalks = [...state.talks];
        newTalks[index] = action.payload.talk;
        if (
          String(action.payload.talk._id) === String(state.talkFullInfo._id)
        ) {
          state.talkFullInfo = action.payload.talk;
        }
        return {
          ...state,
          talks: newTalks,
          talkSelected: action.payload.talk,
        };
      }
      break;
    }

    case DELETE_TALK: {
      const index = state.talks.findIndex(
        (item) => String(item._id) === String(action.payload.idTalk)
      );
      if (index >= 0) {
        const newTalks = [...state.talks];
        newTalks.splice(index, 1);
        return {
          ...state,
          talks: newTalks,
        };
      }
      break;
    }

    case SET_TALK_ACTIVE: {
      const index = state.talks.findIndex(
        (item) => String(item._id) === String(action.payload.idTalk)
      );
      if (index >= 0) {
        const newTalks = [...state.talks];
        const newObject = { ...newTalks[index] };
        newObject.active = !newObject.active;
        newTalks[index] = newObject;
        if (String(state.talks[index]._id) === String(state.talkFullInfo._id)) {
          state.talkFullInfo.active = newObject.active;
        }
        return {
          ...state,
          talks: newTalks,
        };
      }
      break;
    }

    case SET_STUDENT_REMOVE: {
      const index = state.talkFullInfo.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (index >= 0) {
        const talkFullNew = { ...state.talkFullInfo };
        const newStudentList = [...talkFullNew.studentList];
        newStudentList.splice(index, 1);
        talkFullNew.studentList = newStudentList;
        const indexTalk = state.talks.findIndex(
          (item) => String(item._id) === String(state.talkFullInfo._id)
        );
        if (indexTalk >= 0) {
          const newTalks = [...state.talks];
          const newObject = { ...newTalks[indexTalk] };
          newObject.studentListLength = newObject.studentListLength - 1;
          newObject.percentage =
            (newObject.studentListLength * 100) / newObject.quota;
          newTalks[indexTalk] = newObject;
          return {
            ...state,
            talkFullInfo: talkFullNew,
            talks: newTalks,
          };
        } else {
          return {
            ...state,
            talkFullInfo: talkFullNew,
          };
        }
      }
      break;
    }
    case SET_STUDENT_CONFIRMATION: {
      const index = state.talkFullInfo.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      let talksProv = { ...state.talkFullInfo };
      if (Object.entries(action.payload.calledBy).length >= 0) {
        const listNew = [...talksProv.studentList];
        let newObject = { ...listNew[index] }; // Deep copy to evoid ref and null , prevent re-render state
        newObject.calledBy = action.payload.calledBy;
        listNew[index] = newObject; // copy ref of "new" talks student to the full array
        talksProv.studentList = listNew;
      } else {
        const listNew = [...talksProv.studentList];
        let newObject = { ...listNew[index] }; // Deep copy to evoid ref and null , prevent re-render state
        delete newObject.calledBy;
        listNew[index] = newObject; // copy ref of "new" talks student to the full array
        talksProv.studentList = listNew;
      }
      return {
        ...state,
        talkFullInfo: talksProv,
      };
    }

    case SET_STUDENT_STATUS: {
      const index = state.talkFullInfo.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (action.payload.status !== undefined && index >= 0) {
        let talksProv = { ...state.talkFullInfo };
        const listNew = [...talksProv.studentList];
        let newObject = { ...listNew[index] }; // Deep copy to evoid ref and null , prevent re-render state
        newObject.status = action.payload.status;
        listNew[index] = newObject; // copy ref of "new" talks student to the full array
        talksProv.studentList = listNew;
        return {
          ...state,
          talkFullInfo: talksProv,
        };
        // Return same ref array with only 1 item to modify (This will evoid re-render all table , only render 1 element )
      }
      return {
        ...state,
      };
    }

    case SET_STUDENT_ATTENDANCE: {
      const index = state.talkFullInfo.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (action.payload.attendance !== undefined && index >= 0) {
        let talksProv = { ...state.talkFullInfo };
        const listNew = [...talksProv.studentList];
        let newObject = { ...listNew[index] }; // Deep copy to evoid ref and null , prevent re-render state
        newObject.attendance = action.payload.attendance;
        listNew[index] = newObject; // copy ref of "new" talks student to the full array
        talksProv.studentList = listNew;
        return {
          ...state,
          talkFullInfo: talksProv,
        };
      }
      break;
    }

    case SET_STUDENT_COMMENT_ADD: {
      const index = state.talkFullInfo.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (action.payload.comment !== undefined && index >= 0) {
        let talksProv = { ...state.talkFullInfo };
        let newObject = { ...talksProv.studentList[index] };
        if (newObject.comments !== undefined) {
          newObject.comments.push(action.payload.comment);
        } else {
          newObject.comments = [action.payload.comment];
        }
        talksProv.studentList[index] = newObject;
        return {
          ...state,
          talkFullInfo: talksProv,
        };
      }
      return {
        ...state,
      };
    }
    case SET_STUDENT_COMMENT_UPDATE: {
      const index = state.talkFullInfo.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (action.payload.comment !== undefined && index >= 0) {
        let talksProv = { ...state.talkFullInfo };
        let newObject = { ...talksProv.studentList[index] };
        let indexComment = newObject.comments.findIndex(
          (item) => String(item._id) === String(action.payload.comment._id)
        );
        console.log(indexComment);
        if (indexComment >= 0) {
          newObject.comments[indexComment] = action.payload.comment;
          talksProv.studentList[index] = newObject;
          return {
            ...state,
            talkFullInfo: talksProv,
          };
        }
      }
      return {
        ...state,
      };
    }
    case SET_STUDENT_COMMENT_REMOVE: {
      const index = state.talkFullInfo.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (action.payload.comment !== undefined && index >= 0) {
        let talksProv = { ...state.talkFullInfo };
        let newObject = { ...talksProv.studentList[index] };
        let indexComment = newObject.comments.findIndex(
          (item) => String(item._id) === String(action.payload.comment._id)
        );
        if (indexComment >= 0) {
          newObject.comments.splice(indexComment, 1);
          talksProv.studentList[index] = newObject;
          return {
            ...state,
            talkFullInfo: talksProv,
          };
        }
      }
      return {
        ...state,
      };
    }
    case UPDATE_AFTER_DELETE_STUDENT_TALK:
      const cloneStateTalkFullInfo = _.cloneDeep(state.talkFullInfo);
      let newArrayStudentList = cloneStateTalkFullInfo.studentList.filter(
        (data) => data._id !== action.payload
      );
      cloneStateTalkFullInfo.studentList = newArrayStudentList;

      return {
        ...state,
        talkFullInfo: cloneStateTalkFullInfo,
      };

    default:
      return state;
  }
};
export default Reducer;
