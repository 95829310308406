import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import Wizard from "./mainWizard";
class AddPlatica extends Component {
  onCloseModal = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <Modal
        open={this.props.valueModal}
        center={false}
        closeOnEsc={false}
        showCloseIcon={false}
        closeOnOverlayClick={false}
      >
        <Wizard
          isEditPlatica={this.props.isEditPlatica}
          valueModal={this.props.valueModal}
          closeModal={this.onCloseModal}
          deleteTalk={this.props.deleteTalk}
          onClosSidebar={this.props.onClosSidebar}
        />
      </Modal>
    );
  }
}
export default AddPlatica;
