import React, { useContext, useEffect, useCallback } from 'react';
import ModalStudentsDetails from 'helpers/ModalStudentsDetails'
import { GroupsContext } from 'context/groups';
import TableStudents from './components/tableStudents';
import 'moment/locale/es';


const GroupStudents = (props) => {
  //context
  const groupsContext = useContext(GroupsContext);
  const { actualGroup, deleteStudentGroup } = groupsContext;

  useEffect(() => {
  }, [])

  const onCloseModal = useCallback(() => {
    props.close(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualGroup._id])

  return (
    <ModalStudentsDetails
      valueModal={props.valueModal}
      onCloseModal={onCloseModal}
      title={"Información: " + actualGroup.name}
      TableComponent={
        <TableStudents
          actualGroup={actualGroup}
          deleteStudentGroup={deleteStudentGroup}
          updateList={props.updateList}
          onClickList={props.onClickList}
        />
      }
    />
  )
}
export default GroupStudents