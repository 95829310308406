import React, { useState } from 'react'
import moment from 'moment'
import { Comment, Avatar, Form, Button, List, Input, Popconfirm } from 'antd';
import { showMessageWarning } from '../MessageAndNotificationUtils'
import { fetchAsync, urlComments } from '../Globals/globals'
import { randomColor } from '../Fichas/constants'
import 'antd/es/comment/style/index.css'
import 'antd/es/input/style/index.css'
import 'antd/es/avatar/style/index.css'
import 'antd/es/list/style/index.css'
import 'antd/es/form/style/index.css'

const { TextArea } = Input;

const showNum = (value) => {
    if (value.length >= 500) {
        return (<p className="pull-right contadorCaracteres">Limite de 500 caracteres alcanzado.</p>)
    } else {
        return (<p className="pull-right contadorCaracteres">Caracteres restantes: {500 - value.length} de 500</p>)
    }
}

const Editor = ({ onChange, onSubmit, submitting, value, editComment = false, setEditComment = "" }) => (
    <div>
        <Form.Item>
            <TextArea onChange={onChange} value={value} maxLength={500} rows={3} />
        </Form.Item>
        {showNum(value)}
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                {editComment ? "Editar comentario" : "Agregar comentario"}
            </Button>
            {editComment ? <Button htmlType="submit" onClick={() => setEditComment(false)} type="primary" className="ml-2">
                cancelar
            </Button> : ""}
        </Form.Item>
    </div>
);

const CommentsEditor = ({ comentarios = [], idStudent, idTalk = "", idGroup = "", getCommentsByGroup,
    updateAfterDeleteComment = "", updateAfterAddComment = "", updateAfterEditComment = "" }) => {

    const [submitting, setSubmitting] = useState(false)
    const [value, setValue] = useState([])

    const [editComment, setEditComment] = useState(false);
    const [actualEditId, setActualEditId] = useState("");
    const [valueEdit, setValueEdit] = useState("")

    //crea o edita un comentario
    const handleSubmit = () => {
        setSubmitting(true)
        if (value.length <= 500 || valueEdit.length <= 500) {
            let bodyRequest = {
                "userTalks": idStudent,
                "talk": idTalk,
                "date": new Date(),
                "comment": value
            }

            let bodyGroupComment = {
                "student": idStudent,
                "group": idGroup,
                "date": moment.utc(new Date()).local().format(),
                "comment": value
            }

            let bodyEditComment = {
                "comment": valueEdit
            }
            if (editComment) {
                return fetchAsync(urlComments + actualEditId, JSON.stringify(bodyEditComment), "PUT", "")
                    .then((result) => {
                        if (result.success) {
                            setSubmitting(false)
                            setValueEdit("")
                            setActualEditId("")
                            if (updateAfterEditComment !== undefined) {
                                updateAfterEditComment(result.comment)
                                setEditComment(false)
                            } else {
                                let reload = getCommentsByGroup()
                                reload.then((result) => {
                                    setEditComment(false)
                                })
                            }
                        } else {
                            setSubmitting(false)
                            setEditComment(false)
                            setValueEdit("")
                            setActualEditId("")
                        }
                    }).catch(
                        console.log('err')
                    )
            }
            fetchAsync(urlComments, JSON.stringify(idTalk !== "" ? bodyRequest : bodyGroupComment), "POST", "")
                .then((result) => {
                    if (result.success) {
                        setSubmitting(false)
                        setValue("")
                        if (updateAfterAddComment !== undefined) {
                            updateAfterAddComment(result.comment)
                        } else {
                            getCommentsByGroup()
                        }
                    }
                    setSubmitting(false)
                }).catch(
                    console.log('err')
                );
        } else {
            showMessageWarning("El comentario excede el limite de 500 caracteres.", 4)
        }
    };

    const deleteComment = (id) => {
        return fetchAsync(urlComments + id, "", "DELETE", "")
            .then((data) => {
                if (data.success) {
                    if (updateAfterDeleteComment !== undefined) {
                        console.log("ENTER al if en el componente editor ")
                        updateAfterDeleteComment(id)
                    } else {
                        console.log("ENTER al else")
                        getCommentsByGroup()
                    }
                }
                return data
            }).catch((reason) => {
                console.log(reason.message)
            });
    }

    const handleChange = e => {
        setValue(e.target.value)
    }

    const handleChangeEdit = e => {
        setValueEdit(e.target.value)
    }

    //muesrta la lista de comentarios 
    const commentList = () => {

        const editCom = (item) => {
            setEditComment(true)
            setActualEditId(item._id)
            setValueEdit(item.comment)
        }

        return (
            <List
                itemLayout="horizontal"
                dataSource={comentarios}
                rowKey="_id"
                renderItem={item => (
                    <List.Item
                        actions={[editComment ? "" :
                            <>
                                <Button className="botonesComment" key="list-loadmore-edit" onClick={() => editCom(item)}>Editar</Button>
                                <Popconfirm title="¿Estás seguro de borrar el comentario?"
                                    okText="Si"
                                    cancelText="No"
                                    icon={<i className="material-icons yellow_icon">warning</i>}
                                    placement="topLeft"
                                    onConfirm={() => deleteComment(item._id)}>
                                    <Button className="botonesComment" key="list-loadmore-delete">Borrar</Button>
                                </Popconfirm>
                            </>
                        ]}
                    >
                        {editComment && item._id === actualEditId ?
                            <div className="editor_comment">
                                <Editor
                                    onChange={handleChangeEdit}
                                    onSubmit={handleSubmit}
                                    submitting={submitting}
                                    value={valueEdit}
                                    editComment={editComment}
                                    setEditComment={setEditComment}
                                />
                            </div>
                            : <List.Item.Meta
                                avatar={item.user !== undefined && item.user !== null && item.user.hasOwnProperty("name") ?
                                    <Avatar style={{ backgroundColor: randomColor(item.user.name[0].toUpperCase()) }} size="large" >
                                        {item.user.name[0].toUpperCase()}
                                    </Avatar> : ""
                                }
                                title={item.comment}
                                description={item.user !== undefined && item.user !== null && item.user.hasOwnProperty("name") ?
                                    `Comentado por: ${item.user.name} ${item.user.lastName !== undefined ? item.user.lastName : ""}  ${moment(item.date).format("DD/MM/YY HH:mm A")}` : "El usuario que realizón este comentario ya no está disponible"}
                            />}
                    </List.Item>
                )}
            />)
    };

    return (
        <div>
            {comentarios.length > 0 && commentList()}
            <Comment
                avatar={
                    <i className="material-icons" style={{ fontSize: "30px" }}>face</i>
                }
                content={
                    <Editor
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        value={value}
                    />
                }
            />
        </div>
    )
}

export default CommentsEditor