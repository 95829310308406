import React, { useEffect, useState } from 'react';
import { fetchAsync, urlPlaylist } from "../../../helpers/Globals/globals"
import { Row, Col, Card, Button } from "reactstrap"
import Modal from "react-responsive-modal"
import Switch from "react-switch";
import {
    showNotification, showMessageLoading, showMessageWarning,
    showMessageSuccess, showMessageError
} from '../../../helpers/MessageAndNotificationUtils';
import ColorPicker from '../../../helpers/ColorPicker'
import ImageContentComponent from '../../../helpers/uploadImage/imageComponent';
import UploadImageModal from '../../../helpers/uploadImage'


let closeLoading;

const startState = {
    name: "",
    description: "",
    active: false,
    backgroundColor: undefined,
    image: undefined,
    valueModal: false,
    loading: false,
}

const ModalAddChapter = (props) => {
    const [state, setState] = useState(startState)
    useEffect(() => {
        if (props.editar) {
            const { name, description, active, backgroundColor, image } = props.editChapterInfo
            setState({
                name,
                description,
                active,
                backgroundColor,
                image,
                loading: false,
            })
            // setState({
            //     name: props.nameChapter,
            //     description: props.descriptionChapter,
            //     active: props.active,
            //     backgroundColor: props.backgroundColor,
            //     image: props.image,
            //     loading: false,
            // })
        }
    }, [props])

    const onSave = (item) => {
        setState({
            ...state,
            image: item,
            valueModal: false
        })
    }
    const onRemove = (item) => {
        setState({
            ...state,
            image: "",
            valueModal: false
        })
    }

    const onCloseModal = () => {
        props.closeModal()
        setState(startState)
    }

    const showLoadingMessage = () => {
        closeLoading = showMessageLoading("Creando Capitulo", 0)
    }

    const showSuccessMessage = () => {
        closeLoading()
        showMessageSuccess(`Capitulo ${props.editar ? "editado" : "creado"} correctamente`, 3)
    }

    const showLoadingMessageEdit = () => {
        closeLoading = showMessageLoading("Actualizando Capitulo..", 0)
    }

    const createUnidad = () => {
        if (!state.name) {
            showMessageWarning("Debes ingresar un titulo para poder continuar", 3)
        } else if (!state.description) {
            showMessageWarning("Debes ingresar una descripción para continuar", 3)
        } else {
            setState({
                ...state,
                disabled: true,
            })
            let body = {
                "name": state.name,
                "description": state.description
            }
            if (state.backgroundColor !== undefined) {
                body.backgroundColor = state.backgroundColor
            }
            if (props.idCourse) {
                body.course = props.idCourse
            } else if (props.idParent) {
                body.parent = props.idParent
            }
            showLoadingMessage()
            fetchAsync(urlPlaylist, JSON.stringify(body), "POST")
                .then(
                    (data) => {
                        if (data.success) {
                            setState(startState)
                            onCloseModal()
                            showSuccessMessage()
                            props.updateContent(data.playlist)
                        } else {
                            showMessageError("Ocurrio un error al crear el capitulo", 3)
                            showNotification("error", "Ocurrio un error al crear el capitulo", data, 0)
                        }
                    }
                )
                .catch(
                    (reason) => {
                        console.log(reason.message)
                        showMessageError("Ocurrio un error al crear el capitulo", 3)
                        showNotification("error", "Ocurrio un error al crear el capitulo", reason.message, 0)
                    }
                );
        }
    }

    const editUnidad = () => {
        if (!state.name) {
            showMessageWarning("Debes ingresar un titulo para poder continuar", 3)
        } else if (!state.description) {
            showMessageWarning("Debes ingresar una descripción para continuar", 3)
        } else {
            showLoadingMessageEdit();
            setState({
                ...state,
                disabled: true,
            });

            let body = {
                name: state.name,
                description: state.description,
                active: state.active
            }
            if (state.backgroundColor !== undefined) {
                body.backgroundColor = state.backgroundColor
            }
            if (state.image !== undefined) {
                body.image = state.image._id
            }
            //console.log(body)
            fetchAsync(urlPlaylist + props.editChapterInfo._id, JSON.stringify(body), "PUT")
                .then((data) => {
                    if (data.success) {
                        setState(startState)
                        onCloseModal()
                        showSuccessMessage()
                        props.updateContent(data.playlist)
                    }
                }).catch((reason) => {
                    console.log(reason.message)
                    showMessageError("Ocurrio un error al actualizar el capitulo", 3)
                });
        }
    }
    //ejecuta el metodo para crear o editar el capitulo
    const selectCreateEdit = () => {
        if (props.editar) {
            editUnidad()
        } else {
            createUnidad()
        }
    }

    //handler del switch
    const onChange = (checked) => {
        setState({
            ...state,
            active: checked,
        })
    }

    const numCaracteres = () => {
        if (state.description.length >= 200) {
            return (<p className="pull-right contadorCaracteres">Limite de 200 caracteres alcanzado.</p>)
        } else {
            return (<p className="pull-right contadorCaracteres">Caracteres restantes: {200 - state.description.length} de 200</p>)
        }
    }

    return (
        <Modal
            open={props.value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>

            <Card className="mt-4">

                <Row>
                    <Col sm={12} md={5}>
                        <div className="modal_title">
                            {(props.editar) ?
                                <h4 className="bold-text">Editar Cápitulo</h4> :
                                <h4 className="bold-text">Agregar Cápitulo</h4>
                            }
                        </div>
                    </Col>

                    <Col sm={12} md={7}>
                        <Button color="success" type="button"
                            className="btn-sm float-right"
                            onClick={() => selectCreateEdit()}
                            disabled={state.disabled}>
                            {props.editar ? "Editar" : "Crear"}
                        </Button>
                        <Button style={{ marginRight: "15px" }} type="button" className="btn-sm float-right" onClick={() => onCloseModal()}>Salir</Button>
                    </Col>
                </Row>

                {(props.editar) ?
                    <Col sm={12} className="mt-1">
                        <div className="float-right">
                            <span>
                                <span className="mr-3">Estatus</span>
                                <Switch checked={state.active} onChange={onChange}
                                    onColor="#2ecc71"
                                    onHandleColor="#2ecc71"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch" />
                            </span>
                        </div>
                    </Col> : null}

                {state.image !== undefined && state.image !== "" ? <Row className="mt-1">
                    <ImageContentComponent url={state.image.url} />
                </Row> : null}

                <Row className="mt-1">
                    {(props.editar) ?
                        <Col xs="12" md="6">
                            <Button color="success" type="button"
                                className="btn-sm float-right"
                                outline
                                onClick={() => setState({ ...state, valueModal: true })}
                                disabled={state.loading}>
                                {state.image === undefined || state.image === "" ? "Agregar Imagen" : "Editar Imagen"}
                            </Button>
                        </Col> : null}
                    {state.backgroundColor === undefined || state.backgroundColor === '' ?
                        <Col xs="12" md="6">
                            <div className="color-palette">
                                <Button color="success" type="button"
                                    className="btn-sm float-right"
                                    onClick={() => setState({ ...state, backgroundColor: "#00D084" })}
                                    outline
                                    disabled={state.loading}>
                                    Seleccionar Color
                                </Button>
                            </div>
                        </Col> : null}
                </Row>


                {state.backgroundColor !== undefined && state.backgroundColor !== "" ?
                    <div className="form__form-group">
                        <span className="form__form-group-label">Color de fondo</span>
                        <div className="form__form-group-field">
                            <Col xs={10} className="color-palette">
                                <ColorPicker
                                    type="sketch"
                                    color={state.backgroundColor}
                                    full
                                    position={'right'}
                                    presetColors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#00000000']}
                                    onChangeComplete={color => setState({ ...state, backgroundColor: color })}
                                />
                            </Col>
                            <Col><div className="containerDeleteColor"><i onClick={() => setState({ ...state, backgroundColor: undefined })} className="material-icons deleteIcon" >delete</i></div></Col>
                        </div>
                    </div> : null}

                <div className="form__form-group">
                    <span className="form__form-group-label">Nombre</span>
                    <div className="form__form-group-field">
                        <input type="text"
                            maxLength="75"
                            className="form-control"
                            id="inpuName" autoComplete="off"
                            defaultValue={state.name}
                            disabled={state.loading}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    name: event.target.value
                                })
                            }} />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">Descripción del capitulo</span>
                    <div className="form__form-group">
                        <div className="form__form-group-field">
                            <textarea
                                className="form-control"
                                name="textarea"
                                maxLength="200"
                                type="text"
                                disabled={state.loading}
                                defaultValue={state.description}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        description: event.target.value
                                    })
                                }}
                            />
                        </div>
                        {
                            numCaracteres()
                        }
                    </div>
                </div>
            </Card>
            {state.valueModal ? <UploadImageModal
                value={state.valueModal}
                close={() => setState({ ...state, valueModal: false })}
                imageId={state.image !== undefined ? state.image._id : undefined}
                imageUrl={state.image !== undefined ? state.image.url : undefined}
                onSave={onSave}
                onRemove={onRemove} />
                : null}
        </Modal>
    );
}
export default ModalAddChapter;
