import React, { useReducer } from "react";
import LocationsReducer from "./LocationsReducer";
import LocationsContext from "./LocationsContext";
import {
  fetchAsync,
  urlSedes,
  urlClassrooms,
} from "../../helpers/Globals/globals";
import {
  GET_ALL_LOCATIONS,
  ACTUAL_LOCATION,
  RESET_ACTUAL_LOCATION,
  CLASSROOMS_LOCATION,
  EMPTY_CLASSROOMS,
} from "../types/index";

const LocationsState = (props) => {
  const initialState = {
    allLocations: [],
    actualLocation: {
      latitude:"19.497146", 
      longitude:"-99.146226", 
    },
    classroomsLocation: [],
    emptyClassroms: false,

    //////////////////CLASSROOMS STATES////////////////////
    empty: false,
    actualClassroom: {},
  };
  //crear dispatch y state
  const [state, dispatch] = useReducer(LocationsReducer, initialState);

  //obtiene la información de la sede seleccionada en la tabla
  const setActualLocation = (locationInfo) => {
    dispatch({
      type: ACTUAL_LOCATION,
      payload: locationInfo,
    });
    getClassroomsLocation(locationInfo._id);
  };

  //borra los valores guardados de la sede selecionada
  const resetActualLocation = () => {
    dispatch({
      type: RESET_ACTUAL_LOCATION,
    });
  };

  //obtiene todas las sedes
  const getAllLocations = () => {
    fetchAsync(urlSedes, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_ALL_LOCATIONS,
            payload: data.locations,
          });
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //obtiene los salones por sede
  const getClassroomsLocation = (idLocation) => {
    fetchAsync(urlClassrooms + idLocation, "", "GET")
      .then((result) => {
        if (result.success === true) {
          dispatch({
            type: CLASSROOMS_LOCATION,
            payload: result.classRoom,
          });
          dispatch({
            type: EMPTY_CLASSROOMS,
            payload: false,
          });
        } else {
          let empty = [];
          dispatch({
            type: CLASSROOMS_LOCATION,
            payload: empty,
          });
          dispatch({
            type: EMPTY_CLASSROOMS,
            payload: true,
          });
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //crear sede
  const createLocation = (body) => {
    return fetchAsync(urlSedes, JSON.stringify(body), "POST")
      .then((data) => {
        if (data.success === true) {
          getAllLocations();
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
        //this.setState({ showError: true, errorMessage: reason.message, colorMessage: "red" });
      });
  };

  //actualizar sede
  const updateLocation = (body) => {
    return fetchAsync(
      urlSedes + state.actualLocation._id,
      JSON.stringify(body),
      "PUT"
    )
      .then((data) => {
        getAllLocations();
        setActualLocation(data.location);
        return data;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //funcion para eliminar una sede
  const deleteLocation = () => {
    return fetchAsync(urlSedes + state.actualLocation._id, "", "DELETE", "")
      .then((data) => {
        if (data.success === true) {
          getAllLocations();
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  return (
    <LocationsContext.Provider
      value={{
        allLocations: state.allLocations,
        getAllLocations,
        //seleccionar una sede en la tabla
        actualLocation: state.actualLocation,
        setActualLocation,
        //salones de la sede seleccionada
        classroomsLocation: state.classroomsLocation,
        emptyClassroms: state.emptyClassroms,
        //crear Sede
        createLocation,
        //borrar sede
        deleteLocation,
        //actualizar sede
        updateLocation,
        //resetear los datos de la sede seleccionada al cerrar la ficha
        resetActualLocation,
        getClassroomsLocation,
      }}
    >
      {props.children}
    </LocationsContext.Provider>
  );
};
export default LocationsState;
