import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";
import styles from "./index.module.scss";

const icon = `${process.env.PUBLIC_URL}/img/cross.svg`;

const SidebarRightComponent = ({
  isSidebarRightOpen,
  onClosSidebar,
  children,
  placementSide = "right",
}) => {
  const close = () => {
    onClosSidebar();
  };
  // console.log("sidebar_new")
  return (
    <Drawer
      placement={placementSide}
      closable={false}
      // closable={true}
      onClose={onClosSidebar}
      visible={isSidebarRightOpen}
      width={330}
      // mask={true}
      mask={false}
      maskClosable={false}
      bodyStyle={{ padding: 0, backgroundColor: "#f6f6ff" }}
    // style={{ position: "absolute" }}
    // getContainer={false}
    >
      <section className={styles.section_box_button}>
        <button
          type="button"
          onClick={() => close()}
          className={styles.sidebar_close_button}
        >
          <img src={icon} alt="" />
        </button>
      </section>
      <div className={styles.container_content}>
        {children}
      </div>

    </Drawer>
  );
};
SidebarRightComponent.propTypes = {
  isSidebarRightOpen: PropTypes.bool.isRequired,
  onClosSidebar: PropTypes.func.isRequired,
};
export default SidebarRightComponent;
