import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import TableLink from './table';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import "antd/es/breadcrumb/style/index.css";

function MainTabs({ mediaCourses, superiorCourses, courseChapters, addContent, getNewPlaylist,
  chaptersTracker, backPlaylist, emptyMaterial, mediaLevel, superiorLevel, setChaptersTracker }) {

  const [activeTab, setActiveTab] = useState('1')

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      setChaptersTracker([])
    }
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-top">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1') }}
          >
            Nivel media superior
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Nivel superior
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="mt-1">
        <TabPane tabId="1">
          <div className="div_breadcrum">
            <Breadcrumb >
              {chaptersTracker.length >= 1 ?
                <Breadcrumb.Item>
                  <p href="#"><i className="material-icons ml-1" onClick={backPlaylist} >arrow_back</i> </p>
                </Breadcrumb.Item> : ""}
              {chaptersTracker.map((item) => {
                return (
                  <Breadcrumb.Item>
                    {item.name}
                  </Breadcrumb.Item>)
              })}
            </Breadcrumb>
          </div>
          <TableLink
            dataTable={chaptersTracker.length > 0 && chaptersTracker[0].courseLevel === mediaLevel._id ? emptyMaterial ? [] : courseChapters.length > 0 ? courseChapters : mediaCourses : mediaCourses}
            addContent={addContent}
            getNewPlaylist={getNewPlaylist}
            emptyMaterial={emptyMaterial}
          />
        </TabPane>
        <TabPane tabId="2">
          <div className="div_breadcrum">
            <Breadcrumb >
              {chaptersTracker.length >= 1 ?
                <Breadcrumb.Item>
                  <p href="#"><i className="material-icons ml-1" onClick={backPlaylist} >arrow_back</i> </p>
                </Breadcrumb.Item> : ""}
              {chaptersTracker.map((item) => {
                return (
                  <Breadcrumb.Item>
                    {item.name}
                  </Breadcrumb.Item>)
              })}
            </Breadcrumb>
          </div>
          <TableLink
            dataTable={chaptersTracker.length > 0 && chaptersTracker[0].courseLevel === superiorLevel._id ? emptyMaterial ? [] : courseChapters.length > 0 ? courseChapters : superiorCourses : superiorCourses}
            addContent={addContent}
            getNewPlaylist={getNewPlaylist}
            emptyMaterial={emptyMaterial}
          />
        </TabPane>
      </TabContent>
    </div>
  );
}
MainTabs.propTypes = {
  mediaCourses: PropTypes.array.isRequired,
  superiorCourses: PropTypes.array.isRequired,
  courseChapters: PropTypes.array.isRequired,
  addContent: PropTypes.func.isRequired,
  getNewPlaylist: PropTypes.func.isRequired,
  chaptersTracker: PropTypes.array.isRequired,
  backPlaylist: PropTypes.func.isRequired,
  setChaptersTracker: PropTypes.func.isRequired,
  emptyMaterial: PropTypes.bool.isRequired,
  mediaLevel: PropTypes.object,
  superiorLevel: PropTypes.object,
};
export default MainTabs
