import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import LoadingComponent from "../LoadingComponent";

import styles from "./index.module.scss";

const Table = (props) => {
  //Funcion que enumera las filas de la lista
  const generaNum = (cell, row, rowIndex) => {
    if (row) {
      return rowIndex + 1;
    } else {
      return "";
    }
  };

  const headerSortingStyle = { backgroundColor: "#D6DBDF" };

  const onClickTableRow = (row) => {
    props.saveInfo(row);
  };

  const columnsLocation = [
    {
      dataField: "",
      text: "#",
      formatter: generaNum,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30px", textAlign: "center" };
      },
      headerSortingStyle,
      align: "center",
    },
    {
      dataField: "name",
      text: "Sede",
      editable: false,
      headerAlign: "center",
      align: "center",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) =>
          onClickTableRow(row),
      },
    },
  ];

  const columnsClassroom = [
    {
      dataField: "",
      text: "#",
      formatter: generaNum,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30px", textAlign: "center" };
      },
      headerSortingStyle,
      align: "center",
    },
    {
      dataField: "name",
      text: "Salón",
      editable: false,
      headerAlign: "center",
      align: "center",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) =>
          onClickTableRow(row),
      },
    },
  ];

  //pinta el row previamente selecionado
  const rowColor = (cell, row) => {
    let rowStyle = { backgroundColor: "#CACFD2" };
    if (props.idSelected === cell._id) {
      return rowStyle;
    }
  };

  return (
    <div className={styles.table_container}>
      <BootstrapTable
        keyField={"_id"}
        data={props.data}
        columns={props.typeColumn === 1 ? columnsLocation : columnsClassroom}
        rowStyle={rowColor}
        hover
        bordered={false}
        noDataIndication={<LoadingComponent />}
      />
    </div>
  );
};
export default Table;
