import React, { useReducer, createContext } from 'react';
import reducers from './reducers';
import actionCreators from './actions';

const initialState = {
  isLoggedIn: !!localStorage['user'],
  user: localStorage['user'] && JSON.parse(localStorage['user']),
  allows: localStorage['allows'] && JSON.parse(localStorage['allows']),
  error: null,
  userId: ""
};

const reducer = (state = initialState, { type, payload }) => {
  return reducers[(type) || 'ERROR'](state, payload);
};

export const context = createContext(initialState);

const GlobalContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = actionCreators(state, dispatch);
  return <context.Provider value={{ state, actions }}>{children}</context.Provider>;
};

export default GlobalContext
