export const SEND_EMAIL_STATUTS = "SEND_EMAIL_STATUTS";
export const SEND_SMS_STATUTS = "SEND_SMS_STATUTS";
export const SET_STATUTS_GROUPS_FILTER = "SET_STATUTS_GROUPS_FILTER";
export const SET_TAB = "SET_TAB";
export const SET_TEXT_EMAIL = "SET_TEXT_EMAIL";
export const SET_TEXT_SMS = "SET_TEXT_SMS";
export const SET_SUBJECT_EMAIL = "SET_SUBJECT_EMAIL";
export const PAYMENT_FILTER_GROUPS = "PAYMENT_FILTER_GROUPS";
export const GET_COURSE_GROUPS = "GET_COURSE_GROUPS";
export const SET_CHECKBOX_SHOW_ALL = "SET_CHECKBOX_SHOW_ALL";
export const SET_STRING_URL = "SET_STRING_URL";
