import React, { useState, useEffect } from 'react';
import Modal from "react-responsive-modal";
import PropTypes from 'prop-types';
import { MathFieldComponent } from 'react-mathlive'
import { Col, Container, Row, Button } from 'reactstrap';
import { Popconfirm } from 'antd';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { showMessageWarning } from '../../../../helpers/MessageAndNotificationUtils';
import 'antd/lib/popover/style/index.css'
import '../../../../scss/component/motion.scss'

function MathEditorModal({ value, close, fomulaId, formulaValue, onSave, onRemove, actualIdAnswer = "" }) {

    const [formula, setFormula] = useState("")

    useEffect(() => {
        if (formulaValue !== "") {
            setFormula(formulaValue)
        }
    }, [formulaValue])

    const onCloseModal = () => {
        close()
    }

    const handleChangeFormula = (mathText) => {
        setFormula(mathText)
    }

    const handleSaveFormula = () => {
        if (formula.length > 0) {
            onSave(fomulaId, formula, actualIdAnswer)
            onCloseModal()
        } else {
            showMessageWarning("No puedes guardar una formula vacia", 5)
        }
    }

    const handleRemoveFormula = () => {
        onRemove(fomulaId, actualIdAnswer)
        onCloseModal()
    }

    const classnames = {
        modal: "component_math_modal"
    }

    return (
        <Modal
            open={value}
            onClose={onCloseModal}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}
            classNames={classnames}>
            <Container>
                <Row className="modal-videos-quiz">
                    <Col xs="12">
                        <Row>
                            <Col xs="12" md="8">
                                <h3 className="mt-1">Ingresa la ecuación:</h3>
                            </Col>
                            <Col sm="12" md="4">
                                <Button color="success" size="sm" className="float-right mr-3" onClick={handleSaveFormula}>
                                    <p>Guardar</p>
                                </Button>
                                <Button size="sm" className="float-right mr-3"
                                    onClick={onCloseModal}>
                                    <p>Salir</p>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" className="pt-4">
                        <div>
                            <MathFieldComponent
                                initialLatex={formulaValue}
                                latex={formula}
                                onChange={handleChangeFormula}
                                mathFieldConfig={{
                                    defaultMode: "math",
                                    smartMode: true,
                                    // Comment in readOnly Mode
                                    virtualKeyboardMode: "onfocus",
                                    removeExtraneousParentheses: true
                                    // Enable for read Only Mode
                                    // readOnly:true
                                }}
                            />
                        </div>
                    </Col>
                    {formulaValue.length > 0 ?
                        <Col xs={12} className="pt-4">
                            <Popconfirm title="¿Estás seguro de eliminar la ecuación?"
                                okText="Si"
                                cancelText="No"
                                icon={<i className="material-icons red_icon">error</i>}
                                placement="topLeft"
                                onConfirm={handleRemoveFormula}>
                                <Button className="btn  icon float-right" color="danger">
                                    <p><DeleteIcon />Eliminar</p>
                                </Button>
                            </Popconfirm>
                        </Col>
                        : null}
                </Row>
            </Container>
        </Modal>
    )
}
MathEditorModal.propTypes = {
    actualIdAnswer: PropTypes.string,
    formulaValue: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    fomulaId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
};
export default MathEditorModal