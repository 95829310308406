import React, { useContext, useState } from "react";
import { Col, Row, Button, Container } from "reactstrap";

import Can from "../../../helpers/Secure/Can";
import { month } from "../../../helpers/Fichas/constants";
import LoadingComponent from "../../../helpers/LoadingComponent";
import EmptyCard from "../../../helpers/Fichas/emptyCard";

import { EmployeeContext } from "./context/EmployeeContext";

import AddEditEmployee from "./addEdit/AddEditEmployee";
import Attendance from "./attendance/index";

import styles from "./index.module.scss";

const Ava = `${process.env.PUBLIC_URL}/img/generics/face.png`;

function SidebarEmployees({ closeSidebar }) {
  //obtenemos los datos dle context
  const {
    actualEmployee,
    allTeacherGroups,
    allRoles,
    emptyGroups,
    deleteEmployee,
    getEmployeeAttendance,
    setAttendace,
    updateAttendace,
    deleteAttendance,
  } = useContext(EmployeeContext);
  //obtenemos lod datos del empleado
  const {
    name,
    lastName,
    secondLastName,
    isProfessor,
    birthDate,
    roles,
    email,
    phoneNumber,
    secondPhoneNumber,
    address,
    postalCode,
  } = actualEmployee;
  //states del modal
  const [editEmployeModal, setEditEmployeModal] = useState(false);
  const [openAttendance, setOpenAttendance] = useState(false);

  const fecha = (cell) => {
    if (cell) {
      let anio = [];
      let fechaS = cell.split("T")[0];
      anio = fechaS.split("-");

      return `${anio[2]} de ${month[anio[1] - 1]}`;
    }
  };

  //genera la lista de grupos para pintar en la ficha
  const generaLista = () => {
    return allTeacherGroups.length <= 0 ? (
      <EmptyCard message={"No tiene asignado ningun grupo."} />
    ) : (
      allTeacherGroups.map((data, index) => {
        return (
          <div key={data._id} className={styles.groups_list}>
            <span className="mr-1">{index + 1}.- </span>
            {data.name}
            <br></br>
            <span>
              Alumnos inscritos:{" "}
              {data.studentListLength === 0
                ? data.studentListLength
                : data.studentListLength - 1}
              <br></br>
            </span>
            <span>Fecha de inicio: {fecha(data.startDate)}</span>
            <br></br>
            <span>Fecha de término: {fecha(data.endDate)}</span>
            <hr></hr>
          </div>
        );
      })
    );
  };

  const showRole = () => {
    let arrayUserRoles = [];
    if (roles.length > 0) {
      for (let a = 0; a < roles.length; a++) {
        let rolesUser = allRoles.find((data) => data._id === roles[a]);
        if (rolesUser !== undefined) {
          arrayUserRoles.push(rolesUser);
        }
      }
    } else {
      return (
        <li key="1">
          <p>Sin roles asignados</p>
        </li>
      );
    }
    return arrayUserRoles.length === 0 ? (
      <li key="1">
        <p>Sin roles asignados</p>
      </li>
    ) : arrayUserRoles.length > 1 ? (
      arrayUserRoles.map((data) => (
        <li key={data._id}>
          <p>{data.name}</p>
        </li>
      ))
    ) : (
      <li key={arrayUserRoles[0]._id}>
        <p>{arrayUserRoles[0].name}</p>
      </li>
    );
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col xs="5">
          <div className={styles.avatar_ficha_container}>
            <img src={Ava} alt="avatar" />
          </div>
        </Col>
        <Col xs="7" className={styles.ficha_name_container}>
          <p>{name}</p>
          <p>
            {lastName} {secondLastName}
          </p>
          <p>{isProfessor ? "Profesor" : "Usuario"}</p>
        </Col>
      </Row>

      <Row>
        <Col className={styles.sidebar_buttons_container}>
          <Can do="get" on="/attendance/user/?">
            <Button
              className="icon square float-left"
              type="button"
              color="success"
              size="sm"
              outline
              onClick={() => setOpenAttendance(true)}
            >
              <p>
                <i className="material-icons">event_available</i>Asistencia
              </p>
            </Button>
          </Can>
          <Can do="put" on="/users/">
            <Button
              className="icon square  float-right"
              size="sm"
              type="button"
              color="success"
              outline
              onClick={() => setEditEmployeModal(true)}
            >
              <p>
                <i className="material-icons">edit</i>Editar
              </p>
            </Button>
          </Can>
        </Col>
      </Row>

      <Row className={styles.content_sidebar_employee}>
        <Col xs="12">
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              vpn_key
            </i>
            <ul className="ml-3">
              {allRoles.length > 0 && actualEmployee._id && showRole()}
            </ul>
          </div>
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              cake
            </i>
            <p> Cumple el {fecha(birthDate)}</p>
          </div>
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              mail
            </i>
            <p>{email}</p>
          </div>
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              phone
            </i>
            <p>{phoneNumber}</p>
          </div>
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              phone
            </i>
            <p>{secondPhoneNumber}</p>
          </div>
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              home
            </i>
            <p>
              {address} cp: {postalCode}
            </p>
          </div>
          <section className={styles.section_groups_sidebar}>
            <p className="mb-2">Grupos asignados: {allTeacherGroups.length}</p>
            {emptyGroups ? <LoadingComponent /> : generaLista()}
          </section>
        </Col>
      </Row>

      {editEmployeModal && (
        <AddEditEmployee
          edit={true}
          title="Editar"
          valueAddModal={editEmployeModal}
          openAddModal={setEditEmployeModal}
          deleteEmployee={deleteEmployee}
          closeSidebar={closeSidebar}
        />
      )}

      {openAttendance && (
        <Attendance
          openAttendance={openAttendance}
          setOpenAttendance={setOpenAttendance}
          actualEmployee={actualEmployee}
          getEmployeeAttendance={getEmployeeAttendance}
          setAttendace={setAttendace}
          updateAttendace={updateAttendace}
          deleteAttendance={deleteAttendance}
        />
      )}
    </Container>
  );
}
export default SidebarEmployees;
