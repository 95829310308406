
import { fetchAsync, urlPayment, urlStudents } from '../../../../../helpers/Globals/globals';

export const getDebts = async (studentId) => {
  const result = await fetchAsync(urlStudents + `${studentId}/debt`, "", "GET", "")
  return result
}

export const getStudentPayments = async (studentId) => {
  const result = await fetchAsync(urlPayment + "/student/" + studentId, "", "GET", "")
  return result
}

export const deletePayment = async (idPayment) => {
  const result = fetchAsync(urlPayment + '/' + idPayment, "", "DELETE", "")
  return result
}
export const newGetPayments = async (idPayment) => {
  const result = fetchAsync(urlPayment + '/student/' + idPayment + '/byCourse', "", "GET", "")
  return result
}



