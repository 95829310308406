import React from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import TableGroups from "./table";
import LoadingComponent from "../../../helpers/LoadingComponent";
import EmptyComponent from "../../../helpers/Empty/EmptyComponent";

const { Panel } = Collapse;

const CollapseComponent = (props) => {
  const callback = (key) => {
    console.log(key);
  };
  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      bordered={true}
      destroyInactivePanel={true}
      onChange={callback}
      className="ant-collapse-custom-collapse"
    >
      {
        //validamos que existan grupos en el curso
        props.courses.length > 0 && props.allGroups.length > 0 ? (
          props.courses.map((value, index) => {
            const itHasGroups = props.allGroups.findIndex(
              (x) => x.course === value._id
            );
            const dataCourse = props.getCourseGroups(value._id);
            if (itHasGroups !== -1) {
              return (
                <Panel
                  className="ant-collapse-custom-panel"
                  header={value.name}
                  key={value._id}
                >
                  <TableGroups
                    groupsData={dataCourse}
                    isParent={value.isParent}
                    setShowDrawer={props.setShowDrawer}
                    showDrawer={props.showDrawer}
                  />
                </Panel>
              );
            }
            return null;
          })
        ) : props.resultGrups ? (
          <EmptyComponent
            message={
              "No hay grupos activos para tu búsqueda, modifica el rango de fechas o el criterio de filtrado. "
            }
          />
        ) : props.emptySearch ? (
          <EmptyComponent message={"No hay coincidencias para tu busqueda."} />
        ) : (
          <LoadingComponent />
        )
      }
    </Collapse>
  );
};
export default CollapseComponent;
