import {
  GET_ALL_LOCATIONS,
  ACTUAL_LOCATION,
  RESET_ACTUAL_LOCATION,
  CLASSROOMS_LOCATION,
  EMPTY_CLASSROOMS,
  //////////////////CLASSROOMS/////////////////
  ACTUAL_CLASSROOM,
  SAVE_NEW_CLASSROOM,
  UPDATE_CLASSROOM,
  DELETE_CLASSROOM,
} from '../types/index';

const Reducer = (state, action) => {
  switch (action.type) {
    case GET_ALL_LOCATIONS:
      return {
        ...state,
        allLocations: action.payload,
      }
    case ACTUAL_LOCATION:
      return {
        ...state,
        actualLocation: action.payload,
      }
    case RESET_ACTUAL_LOCATION:
      return {
        ...state,
        actualLocation: {},
      }
    case CLASSROOMS_LOCATION:
      return {
        ...state,
        classroomsLocation: action.payload,
      }
    case EMPTY_CLASSROOMS:
      return {
        ...state,
        emptyClassroms: action.payload,
      }
    //////////////////CLASSROOMS/////////////////
    case ACTUAL_CLASSROOM:
      return {
        ...state,
        actualClassroom: action.payload,
      }
    case SAVE_NEW_CLASSROOM:
      return {
        ...state,
        classroomsLocation: [...state.classroomsLocation, action.payload],
        emptyClassroms: state.emptyClassroms ? false : false
      }
    case DELETE_CLASSROOM:
      const newArrayClassrooms = state.classroomsLocation
      newArrayClassrooms.splice(newArrayClassrooms.findIndex(item => item._id === action.payload), 1)
      return {
        ...state,
        classroomsLocation: newArrayClassrooms,
        emptyClassroms: newArrayClassrooms.length === 0 ? true : false
      }
    case UPDATE_CLASSROOM:
      const newArray = state.classroomsLocation;
      const indexClassroom = state.classroomsLocation.findIndex((obj => obj._id === action.payload._id));
      newArray[indexClassroom] = action.payload
      return {
        ...state,
        classroomsLocation: newArray,
        actualClassroom: action.payload
      }
    default:
      return state
  }
}
export default Reducer;