import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popconfirm, message } from "antd";
import { Button } from "reactstrap";

import { QuestionCircleOutlined } from "@ant-design/icons";
import DeleteIcon from "mdi-react/DeleteIcon";

import "antd/es/popconfirm/style/index.css";
import "antd/es/message/style/index.css";

const DeleteButtonConfirm = ({
  title = "",
  deleteFunction = null,
  onCloseOptionalFunc = null,
  id = "",
  typeButton = "button",
  aditionalInfo = "",
}) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const showPopconfirm = () => {
    setVisible(!visible);
    if (visible) setConfirmLoading(false);
  };

  // const showLoadingButton = () => {
  //   setConfirmLoading(!confirmLoading);
  // }

  const handleOkButton = () => {
    setConfirmLoading(true);
    setDisable(true);
    let loadingMessage = message.loading("Eliminando..");

    let result = deleteFunction(id);
    result
      .then((res) => {
        setVisible(false);
        setConfirmLoading(false);
        setDisable(false);
        if (res.success) {
          loadingMessage.then(() =>
            message.success("Registro eliminado exitosamente", 3)
          );
          onCloseOptionalFunc && onCloseOptionalFunc(id, aditionalInfo);
        }
      })
      .catch((err) => {
        console.log(err);
        loadingMessage.then(() => {
          setConfirmLoading(false);
          setDisable(false);
          return message.error("Ocurrio un error", 5);
        });
      });
  };

  return (
    <Popconfirm
      placement="rightTop"
      title={title}
      visible={visible}
      onConfirm={handleOkButton}
      onCancel={showPopconfirm}
      okButtonProps={{ loading: confirmLoading }}
      okText="SI"
      cancelText="NO"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
    >
      {typeButton == "button" ? (
        <Button
          size="sm"
          className="btn float-right"
          color="danger"
          disabled={disable}
          onClick={showPopconfirm}
        >
          Eliminar
        </Button>
      ) : (
        <DeleteIcon
          className="iconDeletePay"
          disabled={disable}
          onClick={showPopconfirm}
        />
      )}
    </Popconfirm>
  );
};
DeleteButtonConfirm.propTypes = {
  title: PropTypes.string,
  deleteFunction: PropTypes.func,
  id: PropTypes.string,
  onCloseOptionalFunc: PropTypes.func,
};
export default DeleteButtonConfirm;
