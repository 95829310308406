import React, { useContext } from "react";
import { Select } from "antd";

import MessagesContext from "../context/messagesContext";

const { Option } = Select;

const GroupsFilter = () => {
  const context = useContext(MessagesContext);

  const { paymentFilter, onSelectType } = context;

  const handleChangeFilter = (value) => {
    //1 = todos , 2= liquidados, 3 adeudos
    onSelectType(value);
  };

  return (
    <div className="ml-3">
      <p>Filtrar estatus de pago</p>
      <Select
        defaultValue={paymentFilter}
        style={{ width: 250 }}
        onChange={handleChangeFilter}
      >
        <Option value={1}>Todos</Option>
        <Option value={2}>Solo Liquidados</Option>
        <Option value={3}>Solo Con Adeudos</Option>
      </Select>
    </div>
  );
};

export default GroupsFilter;
