import React, { useReducer, createContext } from 'react';
import reducers from './reducers';
import actionCreators from './actions';

const initialState = {
    comments: [],
    paginationComments: 1,
    paginationSize: 50,
    totalComments: 0,
    onSucess: false,
    badWord: "",
};

const reducer = (state = initialState, { type, payload }) => {
    return reducers[(payload && type) || 'ERROR'](state, payload);
};

export const contextComments = createContext(initialState);

const CommentContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actions = actionCreators(state, dispatch);

    return (
        <contextComments.Provider value={{ state, actions }}>
            {children}
        </contextComments.Provider>
    );
};

export default CommentContext;
