import React from "react";
import { showMessageWarning } from "../../../helpers/MessageAndNotificationUtils";

const ValidateForm = (props) => {
  const {
    name,
    lastName,
    secondLastName,
    email,
    phoneNumber,
    secondPhoneNumber,
  } = props;

  let letterNumber = /[0-9]/g;
  let ifNum = name + lastName + secondLastName;

  if (name === "" || lastName === "" || secondLastName === "") {
    return showMessageWarning(
      <strong>Atención! Debes ingresar un nombre y apellidos.</strong>,
      3
    );
  } else if (ifNum.match(letterNumber)) {
    return showMessageWarning(
      <strong>
        Atención! Debes escribir solo letras en nombre y apellidos
      </strong>,
      3
    );
  } else if (email === "") {
    return showMessageWarning(
      <strong>Atención! Debes ingresar un correo, ejemplo@mail.com</strong>,
      3
    );
  } else if (!(email.includes("@") && email.includes("."))) {
    return showMessageWarning(
      <strong>
        Atención! Debes ingresar un @ y un punto (.), el correo : "{email}" esta
        incompleto
      </strong>,
      3
    );
  } else if (phoneNumber === "" || phoneNumber.length < 10) {
    return showMessageWarning(
      <strong>
        Atención! Debes ingresar un numero de teléfono minimo 10 digitos
      </strong>,
      3
    );
  } else if (secondPhoneNumber === "" || secondPhoneNumber.length < 8) {
    return showMessageWarning(
      <strong>
        Atención! Debes ingresar un segundo numero de teléfono minimo 8 digitos
      </strong>,
      3
    );
  } else {
    return true;
  }
};
export default ValidateForm;

export const validatePayments = (paymentData, courseData) => {
  if (paymentData.concept === "") {
    showMessageWarning(
      <strong>Atención! Debes ingresar un concepto.</strong>,
      3
    );
    return false;
  } else if (paymentData.amount === "" || parseInt(paymentData.amount) < 0) {
    showMessageWarning(
      <strong>Atención! Debes ingresar una cantidad.</strong>,
      3
    );
    return false;
  } else if (parseInt(paymentData.amount) > parseInt(courseData.price)) {
    showMessageWarning(
      <strong>Atención! El pago no puede ser mayor al costo del curso.</strong>,
      3
    );
    return false;
  } else if (paymentData.discount !== "") {
    if (parseInt(paymentData.discount) <= 0) {
      showMessageWarning(
        <strong>Atención! Debes ingresar un descuento mayor a cero.</strong>,
        3
      );
      return false;
    } else if (
      parseInt(paymentData.discount) + parseInt(paymentData.amount) >
      parseInt(courseData.price)
    ) {
      showMessageWarning(
        <strong>
          Atención! El pago y descuento no puede ser mayor al costo del curso.
        </strong>,
        3
      );
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
