

export const initialState = {
  comments: [],
  loading: false,
  noComments: false,
}

export const types = {
  SET_COMMENTS: "SET_COMMENTS",
  SET_LOADING: "SET_LOADING",
  SET_NO_COMMENTS: "SET_NO_COMMENTS",
  UPDATE_AFTER_EDIT: "UPDATE_AFTER_EDIT",
  UPDATE_AFTER_ADD: "UPDATE_AFTER_ADD",
  UPDATE_AFTER_DELETE: "UPDATE_AFTER_DELETE",
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_NO_COMMENTS:
      return {
        ...state,
        noComments: action.payload
      }
    case types.SET_COMMENTS:
      return {
        ...state,
        comments: action.payload
      }
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case types.UPDATE_AFTER_EDIT:
      return {
        ...state,
        comments: state.comments.map(comment => comment._id == action.payload._id ? action.payload : comment)
      }
    case types.UPDATE_AFTER_ADD:
      return {
        ...state,
        comments: [action.payload, ...state.comments],
        noComments: false,
      }
    case types.UPDATE_AFTER_DELETE:
      return {
        ...state,
        comments: state.comments.filter(comment => comment._id !== action.payload),
        noComments: false,
      }
    default:
      return state
  }

}
export default reducer