import React, { useContext, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import MainPageHeader from "../../helpers/Headers/pageHeader";
import CardDraw from "../../helpers/cards/genericCard";
import EmptyComponent from "../../helpers/Empty/EmptyComponent";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import SidebarLocation from "./components/sidebarLocation";
import AddEditLocation from "./components/addEditLocation";
import LocationsContext from "../../context/locations/LocationsContext";

import styles from "./index.module.scss";

function Locations(props) {
  const locationsContext = useContext(LocationsContext);
  const {
    allLocations,
    getAllLocations,
    setActualLocation,
    actualLocation,
  } = locationsContext;

  const [openAddLocation, setOpenAddLocation] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    getAllLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //abre la ficha lateral
  const showSidebar = (locationInfo) => {
    setActualLocation(locationInfo);
    setShowDrawer(true);
  };

  return (
    <Container>
      <Row>
        <Col xs="12">
          <MainPageHeader
            title="Administración Escolar |"
            subTitle={`Sedes`}
            buttonTitle={"Agregar sede"}
            buttonOnClick={() => setOpenAddLocation(true)}
            canActions={["post", "/locations/"]}
          />
        </Col>
        <Col
          sm={12}
          className={`${styles.locations_cards_box} ${
            showDrawer && styles.cards_container_locations
          }`}
        >
          {allLocations.length > 0 ? (
            <CardDraw
              type="sedes"
              data={allLocations}
              ClickOnElement={showSidebar}
              selected={actualLocation}
            />
          ) : (
            <EmptyComponent message="Aún no hay sedes creadas" />
          )}
        </Col>
      </Row>
      {openAddLocation ? (
        <AddEditLocation value={openAddLocation} close={setOpenAddLocation} />
      ) : null}

      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SidebarLocation onClosSidebar={() => setShowDrawer(false)} />
        </SidebarRightComponent>
      )}
    </Container>
  );
}
export default Locations;
