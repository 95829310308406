import {
    GET_ALL_APPOIMENTS,
    GET_COURSES_TYPES, SET_LOADING, SET_SELECTED
} from './AppoimentsTypes'
const Reducer = (state, action) => {
    switch (action.type) {
        case GET_ALL_APPOIMENTS:
            return {
                ...state,
                dateArray: action.payload,
                loading: false,
            }
        case GET_COURSES_TYPES:
            return {
                ...state,
                courseTypes: action.payload,
                loading: false
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case SET_SELECTED:
            return {
                ...state,
                selected: action.payload
            }
        default:
            return state;
    }
}
export default Reducer;