import React, { useReducer, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Modal } from "react-responsive-modal";

import StudentForm from "./studentInfo/studentForm";
import StudentPayments from "./studentPayments/studentPayments";
import StudentComments from "./studentComments/StudentComments";
import Attendance from "./attendance";
import ChgangePassword from "./changeStudentPassword/chgangePassword";
import SmsAndMailMessages from "../../../../helpers/SmsAndMailComponent/SmsAndMailMessages";

import types from "./types";
import reducer, { initialState } from "./reducerTabs";

import CreditCardIcon from "mdi-react/CreditCardIcon";
import AccountIcon from "mdi-react/AccountIcon";
import CalendarCheckIcon from "mdi-react/CalendarCheckIcon";
import EmailAddIcon from "mdi-react/EmailAddIcon";
import LockIcon from "mdi-react/LockIcon";
import MessageReplyTextIcon from "mdi-react/MessageReplyTextIcon";
import CloseIcon from "mdi-react/CloseIcon";

import styles from "./index.module.scss";

const TabsModal = ({
  valueModal,
  close,
  studentInfo,
  updateStudentContext,
  afterDeleteStudent,
  addPaymentsStudent,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { student, activeTab } = state;

  useEffect(() => {
    dispatch({ type: types.SET_STUDENT, payload: studentInfo });
  }, []);

  const onCloseModal = () => {
    close(false);
  };

  const updateStudent = (studentIformation) => {
    dispatch({ type: types.SET_STUDENT, payload: studentIformation });
    updateStudentContext(studentIformation);
  };

  const showComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <StudentForm
            studentData={student}
            updateStudent={updateStudent}
            onCloseModal={onCloseModal}
            //this func update the table and hide the sidebar after delete the student
            afterDeleteStudent={afterDeleteStudent}
          />
        );
      case 2:
        return (
          <StudentPayments
            onCloseModal={onCloseModal}
            studentData={student}
            addPaymentsStudent={addPaymentsStudent}
          />
        );
      case 3:
        return (
          <StudentComments studentData={student} onCloseModal={onCloseModal} />
        );
      case 4:
        return <Attendance dataAlumno={student} onCloseModal={onCloseModal} />;
      case 5:
        return (
          <SmsAndMailMessages
            type={"student"}
            infoType={student}
            close={onCloseModal}
          />
        );
      case 6:
        return (
          <ChgangePassword studentData={student} onCloseModal={onCloseModal} />
        );
      default:
        break;
    }
  };

  const { name = "", lastName = "", secondLastName = "" } = student;
  return (
    <Modal
      open={valueModal}
      onClose={onCloseModal}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        modal: `${styles.modal_container}`,
      }}
    >
      <Row className="no-gutters">
        <Col md={4} className={styles.sidebar_tabs}>
          <section className={styles.student_info}>
            <p>Alumno</p>
            <h3>{name}</h3>
            <h4>{`${lastName} ${secondLastName}`}</h4>
          </section>

          <section className={`${styles.options_container}`}>
            <p
              className={`${
                activeTab === 1
                  ? styles.option_style_selected
                  : styles.options_style
              }`}
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 1 })
              }
            >
              <AccountIcon /> Datos personales
            </p>

            <p
              className={`${
                activeTab === 2
                  ? styles.option_style_selected
                  : styles.options_style
              }`}
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 2 })
              }
            >
              <CreditCardIcon /> Pagos
            </p>

            <p
              className={`${
                activeTab === 3
                  ? styles.option_style_selected
                  : styles.options_style
              }`}
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 3 })
              }
            >
              <MessageReplyTextIcon /> Comentarios
            </p>

            <p
              className={`${
                activeTab === 4
                  ? styles.option_style_selected
                  : styles.options_style
              }`}
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 4 })
              }
            >
              <CalendarCheckIcon /> Asistencia
            </p>

            <p
              className={`${
                activeTab === 5
                  ? styles.option_style_selected
                  : styles.options_style
              }`}
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 5 })
              }
            >
              <EmailAddIcon /> Enviar notificación
            </p>

            <p
              className={`${
                activeTab === 6
                  ? styles.option_style_selected
                  : styles.options_style
              }`}
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 6 })
              }
            >
              <LockIcon /> Cambiar contraseña
            </p>
          </section>

          {/* Mobile menu */}
          <div className={`${styles.options_container_mobile}`}>
            <AccountIcon
              className={
                activeTab === 1
                  ? styles.selected_mobile_icon
                  : styles.mobile_icon
              }
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 1 })
              }
            />

            <CreditCardIcon
              className={
                activeTab === 2
                  ? styles.selected_mobile_icon
                  : styles.mobile_icon
              }
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 2 })
              }
            />

            <MessageReplyTextIcon
              className={
                activeTab === 3
                  ? styles.selected_mobile_icon
                  : styles.mobile_icon
              }
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 3 })
              }
            />

            <CalendarCheckIcon
              className={
                activeTab === 4
                  ? styles.selected_mobile_icon
                  : styles.mobile_icon
              }
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 4 })
              }
            />

            <EmailAddIcon
              className={
                activeTab === 5
                  ? styles.selected_mobile_icon
                  : styles.mobile_icon
              }
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 5 })
              }
            />

            <LockIcon
              className={
                activeTab === 6
                  ? styles.selected_mobile_icon
                  : styles.mobile_icon
              }
              onClick={() =>
                dispatch({ type: types.SET_ACTIVE_TAB, payload: 6 })
              }
            />

            <CloseIcon className={styles.mobile_icon} onClick={onCloseModal} />
          </div>
        </Col>
        <Col md={8} className={styles.sidebar_content}>
          {showComponent()}
        </Col>
      </Row>
    </Modal>
  );
};
export default TabsModal;
