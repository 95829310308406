
import React, { useContext } from 'react';
import AppoimentsContext from '../context/AppoimentsContext';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import LoadingComponent from '../../../helpers/LoadingComponent';
import './index.sass';

const BookingCalendar = ({setShowDrawer}) => {

    const appoimentContext = useContext(AppoimentsContext);
    const { setSelected, loading, dateArray, selected } = appoimentContext

    //abre la ficha lateral
    const showSidebar = (data) => {
        setSelected(data)
        setShowDrawer(true)
    }

    return loading ? <LoadingComponent /> : (
        <div className="booking_schedule schedule_widget">
            <div className="schedule_header">
                <Row>
                    <Col>
                        <div className="day_slot">
                            <ul>
                                {dateArray.map(value => {
                                    let date = new Date(value.day)
                                    return (
                                        <Col>
                                            <li>
                                                <span>{moment(date).format('ddd')}</span>
                                                <span className="day_slot">{moment(date).format('DD MMM')} <small className="slot_year">{moment(date).format('YYYY')}</small></span>
                                            </li>
                                        </Col>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="schedule_cont">
                <Row>
                    <Col>
                        <div className="time_slot">
                            <ul>
                                {dateArray.map((valueDates, indexValue) => {
                                    return (
                                        <Col>
                                            <li>
                                                {valueDates.hours.map((value, indexHour) => {
                                                    return (
                                                        <div className={"timing" + ((selected && selected.dateIndex === indexValue) && (selected && selected.hourIndex === indexHour) ? " selected" : "") + (value.appoimentList ? ' hasItem' : "")} onClick={() => showSidebar({ dateIndex: indexValue, hourIndex: indexHour })}>
                                                            <span>{moment(value.value, ["HH"]).format("hh A")}</span>
                                                            <br />
                                                            {value.appoimentsInfo ? value.appoimentsInfo.map((value) => {
                                                                return <tr>{`${value.courseName} - ${value.studentsCount}`}</tr>
                                                            }) : null}
                                                            <span>Total: {value.appoimentList ? `${value.totalCountStudents}` : 0} inscritos</span>
                                                        </div>
                                                    )
                                                })}
                                            </li>
                                        </Col>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default BookingCalendar