import React from "react";
import { Table, Tag } from "antd";
import EmptyComponent from "../../../helpers/Empty/EmptyComponent";
import "antd/es/tag/style/index.css";
import "antd/es/table/style/index.css";

function TableStudents(props) {
  const rowEvents = (row) => {
    props.setSudentInfo(row);
    !props.showDrawer && props.setShowDrawer(true);
    props.getPaymentsStudent(row._id);
    row.hasOwnProperty("groups")
      ? props.getGroupsInfo(row.groups)
      : props.getStudentInfo(row._id);
  };

  const TagsRender = (cell, row) => {
    const arrayColors = ["geekblue", "green", "volcano"];
    if (cell) {
      let costo = cell.costs.total;
      let pagado = cell.payments.total;
      let descuentos = cell.payments.discounts;
      if (pagado + descuentos < costo) {
        return (
          <Tag color={arrayColors[2]} key={1}>
            Tiene adeudos
          </Tag>
        );
      } else {
        return (
          <Tag color={arrayColors[1]} key={2}>
            Al corriente
          </Tag>
        );
      }
    }
  };

  const columns = [
    {
      dataIndex: "name",
      title: "Nombre",
      sort: true,
    },
    {
      dataIndex: "lastName",
      title: "A Paterno",
    },
    {
      dataIndex: "secondLastName",
      title: "A Materno",
    },
    {
      dataIndex: "email",
      title: "Correo",
    },
    {
      dataIndex: "phoneNumber",
      title: "Teléfono",
      width: 100,
    },
    {
      dataIndex: "balance",
      title: "Detalles",
      render: TagsRender,
    },
  ];

  return (
    <Table
      rowKey="_id"
      columns={columns}
      pagination={false}
      rowSelection={{
        selectedRowKeys: [props.actualStudent._id],
        hideSelectAll: true,
        columnWidth: "0px",
        renderCell: () => {
          return;
        },
      }}
      dataSource={props.data}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            rowEvents(record);
          }, // click row
          onDoubleClick: (event) => {}, // double click row
          onContextMenu: (event) => {}, // right button click row
          onMouseEnter: (event) => {}, // mouse enter row
          onMouseLeave: (event) => {}, // mouse leave row
        };
      }}
      scroll={{
        x: true,
      }}
      noDataIndication={
        <EmptyComponent message="No hay resultados para tu busqueda" />
      }
    />
  );
}
export default TableStudents;
