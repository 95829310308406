import React, { Fragment } from "react";
import { Col, Button, ButtonToolbar } from "reactstrap";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const Header = ({ onCloseModal, registerEmployee, disable, title }) => {
  return (
    <div className={styles.header_conatiner}>
      <section>
        <div className="card__title">
          <h4 className="bold-text">{title}</h4>
        </div>
      </section>
      <section>
        {/* <Col sm={12} md={6} className="float-right"> */}
        <ButtonToolbar className="ml-1 float-right">
          <Button size="sm" onClick={() => onCloseModal()}>
            Salir
          </Button>
          <Button
            color="success"
            size="sm"
            className="float-right"
            onClick={() => registerEmployee()}
            disabled={disable}
          >
            Guardar
          </Button>
        </ButtonToolbar>
      </section>
    </div>
  );
};
Header.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  registerEmployee: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
};

export default Header;
