import { notification, message } from 'antd';
import 'antd/lib/notification/style/index.css';
import 'antd/lib/message/style/index.css';
// import 'antd/dist/antd.css';


function showNotification(Type, MessageProp, DescriptionProp, DurationTime) {
    notification[Type]({
        message: MessageProp,
        description: DescriptionProp,
        duration: DurationTime
    })
}

function showMessageCustom(Type, props) {
    return message[Type](props);
}

function showNotificationCustom(props) {
    return notification.open(props);
}

function showNotificationUpdate(key, MessageProp, DescriptionProp, DurationTime) {
    let params = {
        key,
        message: MessageProp,
        description: DescriptionProp,
    }
    if (DurationTime !== undefined) {
        params.duration = DurationTime
    }
    notification.open(params);
}

function showMessageLoading(Message, DurationTime) {
    return message.loading(Message, DurationTime);
}
function showMessageInfo(Message, DurationTime) {
    return message.info(Message, DurationTime);
}
function showMessageSuccess(Message, DurationTime) {
    return message.success(Message, DurationTime);
}
function showMessageWarning(Message, DurationTime) {
    return message.warning(Message, DurationTime);
}
function showMessageError(Message, DurationTime) {
    return message.error(Message, DurationTime);
}


export {
    showNotification,
    showNotificationUpdate,
    showMessageLoading,
    showMessageInfo,
    showMessageSuccess,
    showMessageWarning,
    showMessageError,
    showMessageCustom,
    showNotificationCustom
}
