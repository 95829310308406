import React, { useContext, useState } from "react";
import { Col, Row, Button, Container } from "reactstrap";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";

import Can from "../../../helpers/Secure/Can";
import SmsAndMailMessages from "../../../helpers/SmsAndMailComponent/SmsAndMailMessages";
import EmptyCard from "../../../helpers/Fichas/emptyCard";
import { showStatus, duracion } from "../../../helpers/Fichas/functions";
import SetFechaTable from "./../../../helpers/Tables/fechaTables";
import LoadingComponent from "../../../helpers/LoadingComponent";

import CourseContext from "../../../context/courses/CourseContext";

import AddEditCourses from "./addEdit/AddEdithCourses";
import CourseReport from "./reports/index";
import LiveLabel from "./LiveLabel";
import ModalEditStreaming from "./modalEditStreaming";

import styles from "./index.module.scss";

function SidebarCourse(props) {
  const courseContext = useContext(CourseContext);
  const {
    actualCourse,
    actualCourseGroups,
    idActualCourse,
    courses,
    getReportInfo,
  } = courseContext;

  let {
    name,
    active,
    creationDate,
    duration,
    price,
    activeLiveStream,
  } = actualCourse;

  const [editModal, setEditModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const [editStreamingModal, setEditStreamingModal] = useState(false);
  const [modalSmsMail, setModalSmsMail] = useState(false);

  //genera la lista de grupos para pintar en la ficha
  const generaLista = () => {
    return actualCourseGroups.map((data, index) => {
      return (
        <li key={index + 1}>
          <span className="mr-1">{index + 1} .- </span>
          {data.name}
          <br></br>
          <span>
            Alumnos inscritos: {data.studentListLength}
            <br></br>
          </span>
          <span>Fecha de inicio: {SetFechaTable(data.startDate)}</span>
          <hr></hr>
        </li>
      );
    });
  };

  const showSubCourses = () => {
    if (actualCourse.isParent) {
      return actualCourse.children.map((data, index) => {
        if (data.hasOwnProperty("name")) {
          return (
            <ul key={index}>
              <li>{data.name}</li>
            </ul>
          );
        } else {
          let infoCourse = courses.find((course) => course._id === data);
          return (
            <ul key={index}>
              <li>{infoCourse.name}</li>
            </ul>
          );
        }
      });
    }
  };

  //genera la lista de descuentos agregados
  const generaListaDescuentos = () => {
    return actualCourse.discounts.map((data, index) => {
      let obj = courses.find((value) => value._id === data.course);
      return (
        <Row id={data.course} key={index}>
          <Col xs={8} sm={8}>
            <span className="mr-2">
              {index + 1}- {obj !== undefined ? obj.name : "Borrado"}
            </span>
          </Col>
          <Col xs={4} sm={4}>
            <span className="mr-2">{data.discount}</span>
          </Col>
        </Row>
      );
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link
          to={{ pathname: "/material/" + idActualCourse }}
          className="col-xs-1"
        >
          Gestión de material
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setModalSmsMail(true)}>
        Enviar notificación
      </Menu.Item>
    </Menu>
  );

  return (
    <Container className={styles.container}>
      <Row className={styles.sidebar_main_container}>
        <Col xs="12" className="">
          <h3>{name}</h3>

          <section className={styles.status_box}>
            <span>{showStatus(active)}</span>
            <Button
              className="icon square float-left"
              type="button"
              color="success"
              outline
              onClick={() => setReportModal(true)}
            >
              <p>
                <i className="material-icons">article</i>Reportes
              </p>
            </Button>
          </section>

          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              queue copy
            </i>
            <p> Creado {SetFechaTable(creationDate)}</p>
          </div>
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              attach_money
            </i>
            <p>Costo ${price}</p>
          </div>
          <div className={styles.icons_data_sidebar}>
            <i className={`material-icons ${styles.icons_sidebar_students}`}>
              date_range
            </i>
            <p>Duración {duracion(duration)}</p>
          </div>

          <Can do="put" on="/courses/?/updateLive/">
            <p>Clases en live streaming:</p>
            <LiveLabel
              activeLiveStream={activeLiveStream}
              message={activeLiveStream ? "En vivo" : "Esperando evento"}
              onClickCallback={() => setEditStreamingModal(true)}
            />
          </Can>
        </Col>

        <Col xs="12" className={styles.options_box}>
          <Can do="put" on="/courses/">
            <Button
              className="icon square mt-3"
              type="button"
              color="success"
              outline
              onClick={() => setEditModal(true)}
            >
              <p>
                <i className="material-icons">edit</i>Editar
              </p>
            </Button>
          </Can>
          <Can do="get" on="/playlists/">
            <Dropdown.Button overlay={menu} size={"large"}>
              Opciones
            </Dropdown.Button>
          </Can>
        </Col>
        {actualCourse.isParent && (
          <Col xs={12} className={styles.subcourses_box}>
            <h4>Subcursos agregados</h4>
            {showSubCourses()}
          </Col>
        )}
        {actualCourse.discounts.length > 0 && (
          <Col xs={12} className={styles.subcourses_box}>
            <h4>Descuentos agregados</h4>
            {generaListaDescuentos()}
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} className="mt-2">
          {actualCourseGroups ? (
            <Col className={styles.subcourses_box}>
              <h4>Grupos: {actualCourseGroups.length}</h4>
              {actualCourseGroups.length > 0 ? (
                <ul className={styles.list_groups}>{generaLista()}</ul>
              ) : (
                <EmptyCard message={"No hay grupos creados en el curso"} />
              )}
            </Col>
          ) : (
            <LoadingComponent />
          )}
        </Col>
      </Row>
      {editModal && (
        <AddEditCourses
          title={"Editar curso"}
          value={editModal}
          close={setEditModal}
          edit={true}
          killSideBar={props.closeSidebar}
        />
      )}
      {editStreamingModal && (
        <ModalEditStreaming
          onCloseModal={() => setEditStreamingModal(false)}
          value={editStreamingModal}
        />
      )}
      {reportModal && (
        <CourseReport
          value={reportModal}
          close={setReportModal}
          actualCourse={actualCourse}
          courses={courses}
          getReportInfo={getReportInfo}
        />
      )}

      {modalSmsMail && (
        <SmsAndMailMessages
          value={modalSmsMail}
          close={setModalSmsMail}
          type={"courses"}
          infoType={actualCourse}
        />
      )}
    </Container>
  );
}
export default SidebarCourse;
