import React, { PureComponent } from "react";
import { Col, Button, Row } from "reactstrap";
import { Modal } from "react-responsive-modal";
import SelectGroup from "./components/SelectGroup";
const defaulState = {
  loading: true,
  targetId: "",
  courseId: "",
};
class TalkSelectorModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = defaulState;
  }

  onCloseModal = () => {
    this.props.closeModal();
    this.setState(defaulState);
  };

  onClickButton = () => {
    this.props.selectTarget(this.state.targetId);
    this.setState(defaulState);
  };

  setTargetId(id) {
    this.setState({
      targetId: id,
    });
  }

  setCourseId(id) {
    this.setState({
      courseId: id,
    });
  }

  selectTargetId() {
    this.props.selectTarget(this.state.targetId);
  }

  render() {
    return (
      <Modal
        open={this.props.valueModal}
        onClose={this.onCloseModal}
        center={true}
        closeOnEsc={false}
        showCloseIcon={false}
        closeOnOverlayClick={false}
      >
        <Row className="marginTableInfoG ">
          <Col sm={12}>
            <Button
              size="sm"
              className="btn btn-secondary float-right"
              style={{ marginRight: "15px" }}
              onClick={() => this.onCloseModal()}
            >
              <p>Cerrar</p>
            </Button>
            <Button
              size="sm"
              color="success"
              className="btn float-right mr-3"
              onClick={() => this.onClickButton()}
              disabled={!this.state.targetId}
            >
              <p>Seleccionar</p>
            </Button>
          </Col>
          <Col xs={12} className="scrollCambioMasivoComponente">
            <SelectGroup
              setGroupId={this.setTargetId.bind(this)}
              currentGroup={this.props.currentGroup}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}
export default TalkSelectorModal;
