import React from "react";
import { Col, Container, Row } from "reactstrap";
import VerticalForm from "./components/VerticalForm";
import MainPageHeader from "../../helpers/Headers/pageHeader";

const PasswordUpdate = (props) => (
  <Container>
    <Row>
      <Col sm={12}>
        <MainPageHeader title="Cuenta |" subTitle={`Editar contraseña`} />
        <h3 className="page-subhead subhead ml-4">
          Aqui podrás cambiar tu contraseña, debe tener entre 5 a 100 digitos.
        </h3>
      </Col>
      <Col sm="12" md={{ size: 6, offset: 3 }}>
        <VerticalForm />
      </Col>
    </Row>
  </Container>
);
export default PasswordUpdate;
