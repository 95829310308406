import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { message } from "antd";

import Table from "./table";
import { Modal } from "react-responsive-modal";
import { fetchAsync, urlSedes, urlClassrooms } from "../Globals/globals";
import { showMessageWarning } from "..//MessageAndNotificationUtils";
import { dayWeekLetter } from "../Globals/funciones";

import styles from "./index.module.scss";
import "antd/es/message/style/index.css";

const SelectClassroomLocation = (props) => {
  useEffect(() => {
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //states
  const [page, setPage] = useState(1);
  const [locations, setLocations] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [disableButton, setDisableButton] = useState(true);

  const onCloseModal = () => {
    props.close();
  };

  const saveLocation = (location) => {
    setSelectedLocation(location);
    getClassrooms(location._id);
  };

  const saveClassroom = (classroom) => {
    let fullData = [selectedLocation, classroom];
    props.saveInfo(fullData);
    props.close();
  };

  const showStep = () => {
    switch (page) {
      case 1:
        return (
          <Table
            data={locations}
            typeColumn={1}
            saveInfo={saveLocation}
            idSelected={selectedLocation !== null ? selectedLocation._id : ""}
          />
        );
      case 2:
        return (
          <Table
            data={classrooms}
            typeColumn={2}
            saveInfo={saveClassroom}
            idSelected={""}
          />
        );
      default:
        return "";
    }
  };

  const validateNextStep = () => {
    if (selectedLocation === null)
      return showMessageWarning(
        <strong>Atención! Debes seleccionar una sede.</strong>,
        3
      );
    setPage(page + 1);
  };

  //fetch para obtener todas las sedes
  const getLocations = () => {
    fetchAsync(urlSedes, "", "GET", "")
      .then((result) => {
        if (result.success) {
          setLocations(result.locations);
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //servicio para traerse los salones por id de sede con filtro de disponibilidad
  const getClassrooms = (idLocation) => {
    let loadingMessage = message.loading("cargando salones disponibles..");
    let cloneSchedules = props.filterData.schedules.map((a) => ({ ...a }));
    dayWeekLetter(cloneSchedules);
    let body = {
      endDate: props.filterData.endDate,
      startDate: props.filterData.startDate,
      schedules: cloneSchedules,
    };
    fetchAsync(
      urlClassrooms + idLocation + "/available",
      JSON.stringify(body),
      "POST"
    )
      .then((result) => {
        if (result.success) {
          setClassrooms(result.classRooms);
          setDisableButton(false);
        } else {
          setDisableButton(true);
          let text = "revisa que el día y la fecha conicidan.";
          loadingMessage.then(() =>
            message.error(
              `${result?.errorExt?.message?.es} ${
                result?.errorExt?.code === "08" && text
              }`,
              7
            )
          );
        }
      })
      .catch((reason) => {
        loadingMessage.then(() => message.error("Ocurrio un error", 3));
        console.log(reason.message);
      });
  };

  //servicio para traerse los salones por id de sede
  // const getClassrooms = (idLocation) => {
  //   fetchAsync(urlClassrooms + idLocation, '', "GET")
  //     .then((result) => {
  //       if (result.success == true) {
  //         setClassrooms(result.classRoom)
  //       }
  //     }).catch((reason) => {
  //       console.log(reason.message)
  //     });
  // }

  return (
    <Modal
      open={props.value}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container>
        <Row className={styles.wizard_container}>
          <Col xs="12">
            {page > 1 ? (
              <Button
                type="button"
                className="btn-sm float-left"
                onClick={() => setPage(page - 1)}
              >
                Regresar
              </Button>
            ) : (
              ""
            )}
            {page === 1 ? (
              <Button
                color="success"
                type="button"
                className="btn-sm float-right ml-2"
                onClick={() => validateNextStep()}
                disabled={disableButton}
              >
                Siguiente
              </Button>
            ) : (
              ""
            )}
            <Button
              type="button"
              className="btn-sm float-right"
              onClick={onCloseModal}
            >
              Salir
            </Button>
          </Col>

          <Col xs="12">
            <div className="wizard">
              <div className="wizard__steps">
                <div
                  className={`wizard__step${
                    page === 1 ? " wizard__step--active" : ""
                  }`}
                >
                  <p>ELIGE UNA SEDE</p>
                </div>
                <div
                  className={`wizard__step${
                    page === 2 ? " wizard__step--active" : ""
                  }`}
                >
                  <p>ELIGE EL SALÓN</p>
                </div>
              </div>
              <div>
                <div className="scrollSalones text-center">{showStep()}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
export default SelectClassroomLocation;
