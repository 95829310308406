import React from 'react';
import { List } from 'antd';
import PropTypes from 'prop-types';
import "antd/es/list/style/index.css";

const LinkList = ({ arrayContenLink, deleteContent }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={arrayContenLink}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={item.course !== undefined || item.parent !== undefined ? <i className="material-icons">folder</i> : <span className="span_icon_course"><i className="material-icons">library_books</i></span>}
            title={item.name}
            // title={<span>{item.name} <i className="material-icons">remove</i></span>}
            description={item.course !== undefined || item.parent !== undefined ?
              <span className="span_icon">Agregaste una sub-carpeta <i className="material-icons" onClick={() => deleteContent(item)}>delete</i></span> :
              <span className="span_icon"> Agregaste un curso completo <i className="material-icons" onClick={() => deleteContent(item)} >delete</i> </span>}
          />
        </List.Item>
      )}
    />
  );
}
LinkList.propTypes = {
  arrayContenLink: PropTypes.array.isRequired,
  deleteContent: PropTypes.func.isRequired,
};
export default LinkList;
