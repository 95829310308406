import React from 'react';
import {
  showMessageLoading, showMessageWarning, showMessageError
} from '../../../../helpers/MessageAndNotificationUtils';

let closeLoading = ""

//crea uan funcion para lamzar el mensaje de loading
const LoadingMessage = () => {
  return showMessageLoading('Guardando..', 0);
};

//genera el array final de ids roles para enviar
const createArrayIds = (arrayRoles) => {
  let array = [];
  arrayRoles.forEach((value) => {
    let id = value.split('/')[0]
    array.push(id)
  })
  return array
}

export const registerEmployee = (employee, setDisable, registerEmployees,
  editEmployees, onCloseModal, arrayRoles, edit = false, setActualEmployee) => {

  let letterNumber = /[0-9]/g;
  let ifNum = employee.name + employee.lastName + employee.secondLastName;

  if (employee.name === "" || employee.lastName === "" || employee.secondLastName === "") {
    showMessageWarning(<strong>Atención! Debes ingresar un nombre y apellidos.</strong>, 3)
  } else if (ifNum.match(letterNumber)) {
    showMessageWarning(<strong>Atención! Debes escribir solo letras en nombre y apellidos</strong>, 3)
  } else if (employee.email === "") {
    showMessageWarning(<strong>Atención! Debes ingresar un correo, ejemplo@mail.com</strong>, 3)
  } else if ((employee.email.includes("@") && employee.email.includes(".")) !== true) {
    showMessageWarning(<strong>Atención! Debes ingresar un @  y un  punto (.), el correo : "{employee.email}" esta incompleto</strong>, 3)
  } else if (employee.password === "") {
    showMessageWarning(<strong>Atención! Debes ingresar una contraseña de minimo 5 digitos</strong>, 3)
  } else if (!edit && employee.password.length < 5) {
    showMessageWarning(<strong>Atención! Debes ingresar una contraseña de minimo 5 digitos</strong>, 3)
  } else if (employee.phoneNumber === "" || employee.phoneNumber.length < 10) {
    showMessageWarning(<strong>Atención! Debes ingresar un numero de teléfono minimo 10 digitos</strong>, 3)
  } else if (employee.secondPhoneNumber === "" || employee.secondPhoneNumber.length < 8) {
    showMessageWarning(<strong>Atención! Debes ingresar un  segundo numero de teléfono minimo 8 digitos</strong>, 3)
  } else {
    closeLoading = LoadingMessage()
    setDisable(true)

    if (employee.address === "" || employee.postalCode === "") {
      delete employee.address
      delete employee.postalCode
    }

    employee.roles = createArrayIds(arrayRoles)

    if (edit) {
      const result = editEmployees(employee)
      result.then((res) => {
        if (res === undefined) showMessageError(`Ocurrio un error al actualizar el empleado`, 3)
        closeLoading();
        setDisable(false)
        if (res.success) {
          setActualEmployee(res.user)
          onCloseModal()
        } else {
          if (res.code === 400) {
            showMessageError(`${res.errorExt.message.es}`, 3)
          }
        }
      })
    } else {
      const result = registerEmployees(employee)
      result.then((res) => {
        if (res === undefined) showMessageError(`Ocurrio un error al registrar el empleado`, 3)
        closeLoading();
        setDisable(false)
        if (res.success) {
          onCloseModal()
        } else {
          if (res.code === 400) {
            showMessageError(`${res.errorExt.message.es}`, 3)
          }
        }
      })
    }
  }
}


