import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import MainTab from "./components/MainTab";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import AddEditCourses from "./components/addEdit/AddEdithCourses";
import CourseContext from "../../context/courses/CourseContext";

import styles from "./index.module.scss";

function Cursos(props) {
  const courseContext = useContext(CourseContext);
  const { getCourseTypes, getAllCourses, setActualCourse } = courseContext;

  const [addCourse, setAddCourse] = useState(false);

  useEffect(() => {
    getCourseTypes();
    getAllCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAddModal = () => {
    //cerramos el sidebar
    // killSideBar();
    //seteamos el courso actual a si valor inicial
    setActualCourse({});
    //abrimos el modal
    setAddCourse(true);
  };

  return (
    <Container>
      <Row className={styles.main_container_courses}>
        <Col xs="12">
          <MainPageHeader
            title="Administración Escolar |"
            subTitle={`Cursos`}
            buttonTitle={"Agregar curso"}
            buttonOnClick={() => openAddModal()}
            canActions={["post", "/locations/"]}
          />

          <MainTab />
        </Col>
      </Row>
      {addCourse && (
        <AddEditCourses
          title={"Crear curso"}
          value={addCourse}
          close={setAddCourse}
        />
      )}
    </Container>
  );
}
export default Cursos;
