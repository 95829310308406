import React, { PureComponent } from 'react';
import { fetchAsync, uploadData3, urlVideo } from "../../../helpers/Globals/globals"
import { Row, Col, Card, Button } from "reactstrap"
import Modal from "react-responsive-modal"
import { Upload, Progress, Input, Popconfirm } from 'antd';
import { showMessageWarning,showMessageError} from '../../../helpers/MessageAndNotificationUtils';
import ImageComponent from "./ImageComponent"
import PropTypes from 'prop-types';
import "antd/es/upload/style/index.css"
import "antd/es/progress/style/index.css"
import "antd/es/icon/style/index.css"
import "antd/es/input/style/index.css";

let loadingContent
const { TextArea } = Input;
const { Dragger } = Upload;

const startState = {
    name: "",
    description: "",
    setParent: undefined,
    uploading: false,
    completeUpload: false,
    previewVisible: false,
    previewImage: '',
    fileList: [],
}

class ModalAddVideo extends PureComponent {
    static propTypes = {
        idParent: PropTypes.string,
        idQuizParent: PropTypes.string
    };
    constructor(props) {
        super(props);
        this.state = startState;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.value) {
            this.setState(startState)
        }
    }

    onProgress = (value, index) => {
        let fileList = [...this.state.fileList];
        let item = { ...fileList[index] }
        item.percent = value
        fileList[index] = item
        this.setState({
            fileList: fileList
        })
    }
    subirVideos2 = () => {
        let promisesList = this.state.fileList.map(async (file, index) => {
            let body = {}
            if (file.name.length < 1) {
                showMessageWarning("Debees de ingresar un titulo / nombre para el video", 3)
                loadingContent()
                return ""
            } else {
                body.title = file.name
            }
            if (file.description === undefined || file.description === "") {
                body.description = "Sin descripción."
            } else {
                body.description = file.description
            }
            body.filename = file.file.name
            if (this.props.idParent)
                body.playlist = this.props.idParent
            if (this.props.idQuizParent)
                body.quizParent = this.props.idQuizParent
            let datos = await fetchAsync(urlVideo, JSON.stringify(body), "POST")
                .then(
                    (data) => {
                        if (data.success) {
                            return uploadData3(data.url, file.file, "PUT", this.onProgress, index)
                                .then(result => {
                                    let fileList1 = [...this.state.fileList];
                                    let item = { ...fileList1[index] }
                                    item.status = "success"
                                    item.percent = 100
                                    fileList1[index] = item
                                    this.setState({
                                        fileList: fileList1
                                    })
                                })
                                .catch(
                                    error => {
                                        loadingContent()
                                        showMessageError("Ocurrio un error al subir el contenido", 3)
                                        let fileList = [...this.state.fileList];
                                        let item = { ...fileList[index] }
                                        item.status = "exception"
                                        fileList[index] = item
                                        this.setState({
                                            fileList: fileList
                                        })
                                    }
                                )
                        }
                    }
                )
                .catch(
                    (reason) => {
                        console.log(reason.message)
                    }
                );
            return datos
        })
        Promise.all(promisesList).then(results => {
            // console.log("en promise all", results)
            if (results.length > 0) {
                this.setState({
                    uploading: false,
                    completeUpload: true
                })
            }
        })
    }
    onChangeTitle = (e, item) => {
        let newName = e.target.value
        let theitem = item
        this.setState(prevState => {
            let fileList = [...prevState.fileList];
            let index = fileList.findIndex(el => el.uid === theitem.uid);
            let item = { ...fileList[index] }
            item.name = newName
            fileList[index] = item
            return { fileList };
        })
    }

    onChangeDescription = (e, item) => {
        let newName = e.target.value
        let theitem = item
        this.setState(prevState => {
            let fileList = [...prevState.fileList];
            let index = fileList.findIndex(el => el.uid === theitem.uid);
            let item = { ...fileList[index] }
            item.description = newName
            fileList[index] = item
            return { fileList };
        })
    }

    drawVideoPreview = (list) => {
        return (
            <div class="ant-upload-list ant-upload-list-picture">
                {list.map(item => {
                    return (
                        <div class={`ant-upload-list-item ant-upload-list-item-${item.status}`}>
                            <div class="ant-upload-list-item-info">
                                <Row>
                                    <Col xs={3}>
                                        <ImageComponent
                                            isFile
                                            file={item.file}
                                        />
                                    </Col>
                                    <Col xs={9}>
                                        <Input
                                            maxLength={75}
                                            // prefix={<Icon type="play-square" />}
                                            placeholder="Titulo limite de caracteres 75"
                                            value={item.name}
                                            onChange={(event) => this.onChangeTitle(event, item)}
                                            disabled={item.status === "uploading"} />
                                        <TextArea
                                            maxLength={200}
                                            placeholder="Descripción, limite de caracteres 200"
                                            onChange={(event) => this.onChangeDescription(event, item)}
                                            autosize={{ minRows: 2, maxRows: 6 }}
                                            disabled={item.status === "uploading"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        {item.status === "uploading" || item.status === "success" || item.status === "exception" ?
                                            <Progress percent={item.percent ? item.percent : 0} status={item.status === "uploading" ? "active" : item.status} />
                                            : ""
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <i aria-label="icon: close" title="Remove file" tabindex="-1" class="anticon anticon-close" onClick={() => this.handleRemoveElement(item)}><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
                            </i>
                        </div>
                    )
                })}
            </div>
        )
    }


    handleChange = ({ fileList }) => {
        console.log("enter handleChange")
        this.setState(state => {
            let data = state.fileList
            if (data.length < 1) {
                data.push(fileList[0])
            }
            return {
                fileList: data,
            };
        });
    }

    handleRemoveElement = (file) => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }

    handleUpload = () => {
        if (this.state.completeUpload) {
            this.props.updateContent()
            this.onCloseModal()
        } else {
            let prevfileList = []
            for (let i = 0; i < this.state.fileList.length; i++) {
                prevfileList.push(this.state.fileList[i])
                prevfileList[i].status = 'uploading';
                // prevfileList[i].percent = 50;
            }
            this.setState({
                fileList: prevfileList,
                uploading: true,
            })
            this.subirVideos2()
        }
    }

    onCloseModal = () => {
        this.props.closeModal()
    }


    render() {
        console.log("RENDER")
        const { uploading, fileList, completeUpload } = this.state;
        const props = {
            beforeUpload: file => {
                this.setState(state => {
                    let fileList = [...state.fileList]
                    let name2 = file.name.replace(/\.[^/.]+$/, "")
                    let prov = {
                        name: name2,
                        description: "",
                        percent: 0,
                        uid: file.uid,
                        file: file
                    }
                    fileList.push(prov)
                    return { fileList }
                    // fileList: [...state.fileList, file],
                });
                return false;
            },
            accept: ["video/*", ".mts"],
            fileList: [],
            multiple: true,
            onChange: this.handleChange,
            disabled: this.state.uploading,
        };

        return (
            <Row >
                <Col sm={12} md={12}>
                    <Modal
                        open={this.props.value}
                        onClose={this.onCloseModal}
                        center={true}
                        closeOnEsc={false}
                        showCloseIcon={false}
                        closeOnOverlayClick={false}>
                        <Card className="add-video mt-2 margenCursoAdd">
                            <Row>
                                <Col sm={12} md={8}>
                                    <div className="card__title">
                                        <h4 className="bold-text">Agregar Videos</h4>
                                    </div>
                                </Col>
                                {uploading ?
                                    <Col sm={12} md={4}>
                                        <Popconfirm
                                            title="¿Seguro que quieres cerrar la ventana?, hay una subida en proceso."
                                            onConfirm={this.onCloseModal}
                                            //onCancel={cancel}
                                            okText="Si"
                                            cancelText="No"
                                        >
                                            <Button type="button" className="btn-sm float-right">Salir</Button>
                                        </Popconfirm>,
                                    </Col>
                                    :
                                    <Col sm={12} md={4}>
                                        <Button type="button" className="btn-sm float-right" onClick={() => this.onCloseModal()}>Salir</Button>
                                    </Col>
                                }
                            </Row>

                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    {/* <Icon type="inbox" /> */}
                                </p>
                                <p className="ant-upload-text">Da click o arrastra contenido para subir</p>
                                <p className="ant-upload-hint">
                                    Recuerda que todo el contenido debe verificarse antes de subir para evitar errores en sistema.
                                        </p>
                            </Dragger>
                            {this.drawVideoPreview(this.state.fileList)}

                            <Button
                                color="success"
                                type="button"
                                onClick={() => this.handleUpload()}
                                disabled={fileList.length === 0 || this.state.uploading}

                                loading={uploading}>
                                {uploading ? 'Subiendo' : completeUpload ? "Terminar" : 'Subir Videos'}
                            </Button>
                        </Card>
                    </Modal>
                </Col>
            </Row>
        );
    }
}
export default ModalAddVideo;
