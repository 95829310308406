import React, { useState, useContext } from 'react'
import { Row, Col, Card, Button } from "reactstrap"
import Modal from "react-responsive-modal"
import CourseContext from '../../../../context/courses/CourseContext'

const ModalAddToppic = ({ value, onCloseModal }) => {
    const courseContext = useContext(CourseContext);
    const { createTopic } = courseContext
    const [name, setName] = useState("")

    const createTopicFunction = () => {
        createTopic(name).then(
            (res) => {
                onCloseModal()
            }
        )
    }

    return (
        <Modal
            open={value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>
            <Card className="addCursoSize mt-4 margenCursoAdd">

                <Row>
                    <Col sm={12} md={7}>
                        <div className="card__title">
                            <h4 className="bold-text">Agregar módulo de asesoría</h4>
                        </div>

                    </Col>
                    <Col sm={12} md={5}>
                        <Button color="success" type="button"
                            disabled={name.length <= 0}
                            className="btn-sm float-right"
                            onClick={() => createTopicFunction()}
                        // disabled={this.state.loading}
                        >Crear</Button>
                        <Button style={{ marginRight: "15px" }} type="button" className="btn-sm float-right" onClick={() => onCloseModal()}>Salir</Button>
                    </Col>
                </Row>
                <div className="form__form-group">
                    <span className="form__form-group-label">Nombre del módulo</span>
                    <div className="form__form-group-field">
                        <input type="text"
                            className="form-control"
                            id="inpuName" autocomplete="off"
                            value={name}
                            // disabled={this.state.loading}
                            onChange={(event) => { setName(event.target.value) }} />
                    </div>
                </div>
            </Card>
        </Modal>
    )

}
export default ModalAddToppic