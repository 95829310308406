import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { Col, Container, Row, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { DatePicker, Tooltip } from "antd";
import moment from "moment";
import "moment/locale/es";

import EmptyComponent from "../../../../helpers/Empty/EmptyComponent";
import { showMessageLoading } from "../../../../helpers/MessageAndNotificationUtils";
import LoadingComponent from "../../../../helpers/LoadingComponent";
import { newSchedules } from "../../../../helpers/Globals/funciones";
import SetFechaTable from "../../../../helpers/Tables/fechaTables";

import locale from "antd/es/date-picker/locale/es_ES";

import styles from "./index.module.scss";
import "antd/es/date-picker/style/index.css";
import "antd/es/tooltip/style/index.css";

//fetchAsync(urlCourses + "report/5ddae1e6f0ca913482255acc?startDate=2020-08-02&endDate=2020-08-12&wd=7&dd=3", "")
const instructions = (
  <div>
    <ul>
      <li>
        Selecciona un rango de fechas (Obligatorio) y presiona generar, los
        otros filtros son opcionales.
      </li>
    </ul>
  </div>
);

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";
let closeLoading = "";

const CourseReport = ({ value, close, actualCourse, getReportInfo }) => {
  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).local().format()
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).local().format()
  );
  const [wd, setWd] = useState("");
  const [dd, setDd] = useState("");
  const [reportInfo, setReportInfo] = useState("");
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    close(false);
    if (closeLoading !== "") {
      closeLoading();
    }
  };

  //Funcion que enumera las filas de la lista
  const generaNum = (cell, row, rowIndex) => {
    if (row) {
      return rowIndex + 1;
    } else {
      return "";
    }
  };

  //funciones para el calendario
  const onChangeStRngStart = (date, dateString) => {
    let startDate = moment.utc(date[0]).local().format();
    let endDate = moment.utc(date[1]).local().format();
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onChamgeWd = (e) => {
    let wd = e.target.value.replace(/\D/g, "");
    setWd(wd);
  };

  const onChamgeDd = (e) => {
    let dd = e.target.value.replace(/\D/g, "");
    setDd(dd);
  };

  //genera la consulta para el reporte
  const createReport = () => {
    setLoading(true);
    closeLoading = LoadingMessage();
    let str = `report/${actualCourse._id}?startDate=${startDate}&endDate=${endDate}`;

    if (wd !== "" && dd !== "") {
      str = str + `&wd=${wd}&dd=${dd}`;
      const info = getReportInfo(str);
      info.then((result) => {
        if (result.success) {
          result.report.sort(
            (a, b) => moment(a.startDate) - moment(b.startDate)
          );
          setReportInfo(result.report);
          setLoading(false);
          closeLoading();
        }
      });
    } else if (wd !== "") {
      str = str + `&wd=${wd}`;
      const info = getReportInfo(str);
      info.then((result) => {
        if (result.success) {
          result.report.sort(
            (a, b) => moment(a.startDate) - moment(b.startDate)
          );
          setReportInfo(result.report);
          setLoading(false);
          closeLoading();
        }
      });
    } else if (dd !== "") {
      str = str + `&dd=${dd}`;
      const info = getReportInfo(str);
      info.then((result) => {
        if (result.success) {
          result.report.sort(
            (a, b) => moment(a.startDate) - moment(b.startDate)
          );
          setReportInfo(result.report);
          setLoading(false);
          closeLoading();
        }
      });
    } else {
      const info = getReportInfo(str);
      info.then((result) => {
        if (result.success) {
          result.report.sort(
            (a, b) => moment(a.startDate) - moment(b.startDate)
          );
          setReportInfo(result.report);
          setLoading(false);
          closeLoading();
        }
      });
    }
  };

  const fecha = (cell, row) => {
    return SetFechaTable(cell);
  };

  //pinta los dias y horarios
  const diasHoras = (cell, row) => {
    return newSchedules(cell);
  };

  const LoadingMessage = () => {
    return showMessageLoading("Cargando los datos, puede tardar un poco.", 0);
  };

  const headerStyle = { backgroundColor: "#D6DBDF" };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: generaNum,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return {
          width: "30px",
          textAlign: "center",
          backgroundColor: "#D6DBDF",
        };
      },
      align: "center",
    },
    {
      dataField: "name",
      text: "Grupo",
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "startDate",
      text: "Inicia",
      formatter: fecha,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "schedules",
      text: "Día(s) y horarios",
      formatter: diasHoras,
      headerAlign: "center",
      editable: false,
      align: "center",
      headerStyle,
    },
    {
      dataField: "quota",
      text: "Cupo",
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "registered",
      text: "Inscritos",
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "confirmed",
      text: "Confirmados",
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "location",
      text: "Sede",
      editable: false,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    // {
    //   dataField: 'percentage_confirmed',
    //   text: 'Porcentaje Confirmados',
    //   editable: false,
    //   headerAlign: 'center',
    //   align: 'center',

    // },
    // {
    //   dataField: 'percentage_registered',
    //   text: 'Porcentaje registrados',
    //   editable: false,
    //   headerAlign: 'center',
    //   align: 'center',

    // },
    {
      dataField: "status_color",
      text: "Estado",
      formatter: () => "",
      editable: false,
      headerAlign: "center",
      headerStyle,
      align: "center",
      style: (cell, row, rowIndex, colIndex) => {
        const style = {};
        style.backgroundColor = row.status_color;

        return style;
      },
    },
  ];

  return (
    <Modal
      open={value}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container className="px-0">
        <Row className={styles.container_report_course}>
          <Col xs="12">
            <Row>
              <Col xs="12" md="9">
                <h3 className="float-left">{actualCourse.name}</h3>
              </Col>
              <Col xs="12" md="3">
                <Button
                  className="btn float-right"
                  size="sm"
                  onClick={() => onCloseModal()}
                >
                  Salir
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs="12" className={styles.date_instructions_box}>
            <div>
              <RangePicker
                // value={stRngStart}
                format={dateFormat}
                locale={locale}
                allowClear={false}
                onChange={onChangeStRngStart}
              />
            </div>
            <div className={styles.tooltip_courses_reports}>
              <Tooltip
                title={instructions}
                placement="rightTop"
                key={"#2db7f5"}
              >
                <i className={`material-icons`}>info</i>
              </Tooltip>
            </div>
            <div>
              <Button
                className="float-right ml-4"
                color="success"
                size="sm"
                onClick={() => createReport()}
                disabled={loading}
              >
                Generar
              </Button>
            </div>
          </Col>
          <Col xs="12">
            <form>
              <Row className="mt-2">
                <Col xs="6" md="3">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Días laborables (opcional)
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        maxLength="3"
                        id="wd"
                        name="wd"
                        value={wd}
                        onChange={onChamgeWd}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="6" md="3">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Días de tolerancia (opcional)
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        maxLength="3"
                        id="dd"
                        name="dd"
                        value={dd}
                        onChange={onChamgeDd}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
          <Col xs="12" className={styles.table_repprt_course}>
            {loading ? (
              <LoadingComponent />
            ) : reportInfo !== "" ? (
              <BootstrapTable
                keyField={"id"}
                data={reportInfo}
                columns={columns}
                bordered={false}
                noDataIndication={
                  <EmptyComponent
                    message={"No hay reportes disponibles para este curso."}
                  />
                }
              />
            ) : (
              <EmptyComponent
                message={"Agrega los datos requeridos y presiona generar."}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
export default CourseReport;
