import React, { useState } from "react";

import { List, Popconfirm } from "antd";

import EditFolioNew from "./editFolioNew";

import styles from "./index.module.scss";
import "antd/es/list/style/index.css";
import "antd/es/popconfirm/style/index.css";

const ShowArrayFolios = ({ arrayFoliosObj, editFoliio, deleteFolio }) => {
  const [selectedFolio, setSelectedFolio] = useState("");

  const onEdit = (folio) => {
    setSelectedFolio(folio);
  };

  const onDelete = (idFolio) => {
    deleteFolio(idFolio);
  };

  return (
    <div className={styles.show_folios_list}>
      <List
        itemLayout="horizontal"
        dataSource={arrayFoliosObj}
        renderItem={(item) =>
          selectedFolio !== "" && selectedFolio?._id == item._id ? (
            <>
              <EditFolioNew
                selectedFolio={item}
                setSelectedFolio={setSelectedFolio}
                editFoliio={editFoliio}
              />
            </>
          ) : (
            <List.Item
              actions={[
                <p key="1-edit-folio" onClick={() => onEdit(item)}>
                  <i className="material-icons">edit</i>
                </p>,
                <p key="2-delete-folio">
                  <Popconfirm
                    title="¿Quieres borrar el folio?"
                    onConfirm={() => onDelete(item._id)}
                    okText="SI"
                    cancelText="No"
                  >
                    <i className="material-icons">delete</i>
                  </Popconfirm>
                </p>,
              ]}
            >
              <List.Item.Meta title={item.folio} description={item.title} />
            </List.Item>
          )
        }
      />
    </div>
  );
};

ShowArrayFolios.propTypes = {};

export default ShowArrayFolios;
