import React, { useState, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Col, Row, Card, Container } from "reactstrap";
import EmptyCard from "../../../helpers/Empty/EmptyComponent";
import TabsModal from "../../Students/components/tabsStudents/TabsModal";
import ModalAddToppic from "./modalEditAppoimentList";
import { Avatar, Collapse } from "antd";
import Can from "../../../helpers/Secure/Can";
import AppoimentsContext from "../context/AppoimentsContext";
import "antd/es/avatar/style/index.css";
import "antd/es/collapse/style/index.css";
import "./ficha.sass";
const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
const { Panel } = Collapse;

const SidebarAppoiments = (props) => {
  const appoimentContext = useContext(AppoimentsContext);
  const { selected, dateArray } = appoimentContext;

  const [courseSelected, setCouseSelected] = useState(0);
  const [studentSelect, setStudentSelect] = useState();
  const [showEditAppoiment, setShowEditAppoiment] = useState(false);
  const [showTabsStudent, setShowTabsStudent] = useState(false);

  let dateIndex = selected.dateIndex;
  let hourIndex = selected.hourIndex;
  let item = dateArray[dateIndex].hours[hourIndex];

  const onClickStudent = (id) => {
    setStudentSelect(id);
    setCouseSelected(true);
  };
  const openEditModal = (value) => {
    setShowEditAppoiment(true);
  };
  const updateQuoota = (value) => {
    //selectedEdit
  };
  const randomColor = () => {
    let color = colorList[Math.floor(Math.random() * colorList.length)];
    return color;
  };
  const generaLista = () => {
    let appoiments = item.appoimentList[courseSelected].appoiments;
    if (appoiments[0].courseTopics) {
      let category = _.groupBy(appoiments, (x) => x.courseTopics._id);
      return (
        <Collapse defaultActiveKey={Object.keys(category)} bordered={false}>
          {Object.keys(category).map((value) => {
            return (
              <Panel
                header={`${category[value].length}/${item.appoimentList[courseSelected].maxQuota} - ${category[value][0].courseTopics.name}`}
                key={category[value][0].courseTopics._id}
                extra={
                  <Can do="update" on="/appoiments/">
                    <p onClick={() => openEditModal(value)}>
                      <i className="material-icons">edit</i>
                    </p>
                  </Can>
                }
              >
                {category[value].map((data, index) => {
                  if (data != null) {
                    let name = data.student.name;
                    let lastName = data.student.lastName;
                    let secondLastName = data.student.secondLastName;
                    return (
                      <li
                        className="nombresLista"
                        onClick={() => {
                          onClickStudent(data.student);
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: randomColor(),
                            verticalAlign: "middle",
                          }}
                          size="small"
                        >
                          {name[0] + lastName[0]}
                        </Avatar>
                        {`${name} ${lastName} ${secondLastName}`}
                        <hr></hr>
                      </li>
                    );
                  }
                  return null;
                })}
              </Panel>
            );
          })}
        </Collapse>
      );
    } else {
      return appoiments.map((data, index) => {
        if (data != null) {
          let name = data.student.name;
          let lastName = data.student.lastName;
          let secondLastName = data.student.secondLastName;
          return (
            <li
              className="nombresLista"
              onClick={() => {
                onClickStudent(data.student);
              }}
            >
              <Avatar
                style={{
                  backgroundColor: randomColor(),
                  verticalAlign: "middle",
                }}
                size="small"
              >
                {name[0] + lastName[0]}
              </Avatar>
              {`${name} ${lastName} ${secondLastName}`}
              <hr></hr>
            </li>
          );
        }
        return null;
      });
    }
  };

  return (
    <Container>
      <Card>
        <Col xs={12} sm={12} md={12}>
          <Row>
            <Col>
              <p className="fichaNameTitle">
                {moment(dateArray[dateIndex].day).format("DD/MM/YYYY")}
              </p>
              <p className="fichaNameTitle">
                {moment(dateArray[dateIndex].day).format("dddd")}
              </p>
              <p className="fichaNameTitle">
                {moment(item.value, "HH").format("hh:mm A")}
              </p>
              {item.appoimentList !== undefined &&
              !item.appoimentList[courseSelected].appoiments.length >= 0 ? (
                <Row className="my-2">
                  <Col xs={12} className="marginDireccion">
                    <p style={{ color: "#11306c" }} className="subTitulosFicha">
                      Cursos
                    </p>
                    {item.appoimentList.map((value, index) => (
                      <h4
                        className={
                          index === courseSelected ? "selected_course" : ""
                        }
                        onClick={() => setCouseSelected(index)}
                      >
                        {value.course.name}
                      </h4>
                    ))}
                  </Col>
                </Row>
              ) : null}
              <Row className="mt-3">
                <Col xs={12} sm={12} md={12}>
                  <p
                    style={{ color: "#11306c" }}
                    className="subTitulosFicha mb-2"
                  >
                    {item.appoimentList !== undefined &&
                    item.appoimentList[courseSelected].appoiments.length >= 0
                      ? `Alumnos inscritos: ${item.appoimentList[courseSelected].appoiments.length}`
                      : ""}
                  </p>
                  {item.appoimentList !== undefined &&
                  !item.appoimentList[courseSelected].appoiments.length >= 0 ? (
                    generaLista()
                  ) : (
                    <EmptyCard message="No hay alumnos registrados" />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Card>

      {showEditAppoiment ? (
        <ModalAddToppic
          value={showEditAppoiment}
          onCloseModal={() => setShowEditAppoiment(false)}
          quota={item.appoimentList[courseSelected].maxQuota}
          updateQuoota={updateQuoota}
        />
      ) : null}

      {showTabsStudent && (
        <TabsModal
          valueModal={showTabsStudent}
          close={setShowTabsStudent}
          studentInfo={studentSelect}
          updateStudentContext={() => ""}
          afterDeleteStudent={() => ""}
          addPaymentsStudent={() => ""}
        />
      )}
    </Container>
  );
};
export default SidebarAppoiments;
