import React from "react";
import { Button } from "reactstrap";

import Enrollment from "../../../../Enrollment";
import EnrollmentState from "../../../../../context/EnrollmentContext/EnrollmentState";

import styles from "./index.module.scss";

const Index = ({ dataUserTalks, onCloseModal }) => {
  return (
    <div className={styles.main_conatiner_register_group}>
      <section>
        <Button
          size="sm"
          className={`btn btn-secondary float-right`}
          onClick={onCloseModal}
        >
          Salir
        </Button>
      </section>
      <EnrollmentState>
        <Enrollment
          openModal={false}
          close={() => ""}
          data={dataUserTalks}
          updateAfterFastRegister={() => ""}
          typeComponent={"talks"}
        />
      </EnrollmentState>
    </div>
  );
};

export default Index;
