import {
  SIDEBAR_ALL_DATA,
  LEVELS,
  COURSES_MEDIA,
  COURSES_SUPERIOR,
  GET_ALL_GROUPS,
  DISABLE_SWITCH_GROUP,
  ACTUAL_GROUP,
  TEACHERS,
  LOADING_SKELETON_GROUPS,
  SET_SEARCH_NAME,
  SEARCH_GROUPS_RESULT,
  EMPTY_SEARCH,
  GROUP_STUDENTS,
  GROUP_LOCATIONS,
  EMPTY_RESULT_GROUPS,
  UPDATE_TABLE_ROW,
  UPDATE_TABLE_CREATE,
  DELETE_GROUP,
  DELETE_GROUP_STUDENT,
  UPDATE_TABLE_STATUS,
  UPDATE_TABLE_STUDENTS_COMMENT_DELETE,
  UPDATE_TABLE_STUDENTS_ADD_COMMENT,
  UPDATE_TABLE_STUDENTS_UPDATE_COMMENT,
  UPDATE_ATTENDANCE_SIDEBAR,
  SET_URL_STRING,
  SET_URL_ARRAY,
} from "../types/index";

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_URL_STRING:
      console.log(action.payload);
      // let obj={
      //   id=
      // }
      return {
        ...state,
        filterUrlString: action.payload,
        filterUrlArray: action.payload.split("&"),
      };
    case SIDEBAR_ALL_DATA:
      return {
        ...state,
        actualGroup: action.payload,
      };
    case ACTUAL_GROUP:
      return {
        ...state,
        actualGroup: action.payload,
      };
    case TEACHERS:
      return {
        ...state,
        teachers: action.payload.filter((dato) => dato.isProfessor !== false),
      };
    case LEVELS:
      return {
        ...state,
        mediaLevel: action.payload.find(
          (data) => data.name === "Media Superior"
        ),
        superiorLevel: action.payload.find((data) => data.name === "Superior"),
      };
    case COURSES_MEDIA:
      return {
        ...state,
        coursesMedia: action.payload,
      };
    case COURSES_SUPERIOR:
      return {
        ...state,
        coursesSuperior: action.payload,
      };
    case GET_ALL_GROUPS:
      return {
        ...state,
        allGroups: action.payload,
      };
    case DISABLE_SWITCH_GROUP:
      return {
        ...state,
        disableSwitch: action.payload,
      };
    case LOADING_SKELETON_GROUPS:
      return {
        ...state,
        loadingSkeleton: action.payload,
      };
    case SET_SEARCH_NAME:
      return {
        ...state,
        searchName: action.payload,
      };
    case SEARCH_GROUPS_RESULT:
      return {
        ...state,
        searchGroupsResult: action.payload,
      };
    case EMPTY_SEARCH:
      return {
        ...state,
        emptySearch: action.payload,
      };
    case GROUP_STUDENTS:
      return {
        ...state,
        actualGroupStudentsFullInfo: action.payload,
      };
    case GROUP_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case EMPTY_RESULT_GROUPS:
      return {
        ...state,
        resultGorups: action.payload,
      };
    case UPDATE_TABLE_ROW: {
      const objIndex = state.allGroups.findIndex(
        (obj) => obj._id === action.payload._id
      );
      const allCopy = [...state.allGroups];
      allCopy[objIndex] = { ...action.payload };
      //change of object to id to match the format
      allCopy[objIndex].course = allCopy[objIndex].course._id;
      if (String(state.actualGroup._id) === String(action.payload._id)) {
        state.actualGroup = action.payload;
      }
      return {
        ...state,
        allGroups: allCopy,
      };
    }
    case UPDATE_TABLE_STATUS: {
      const indexRow = state.allGroups.findIndex(
        (obj) => String(obj._id) === String(action.payload.idGroup)
      );
      if (indexRow >= 0) {
        state.allGroups[indexRow].active = action.payload.status;
      }
      //si la ficha esta abierta y es el mismo grupo actualizamos su estatus
      if (String(action.payload.idGroup) === String(state.actualGroup._id)) {
        state.actualGroup.active = action.payload.status;
      }
      //si hay una busqueda activa actualizamos esos datos tambien
      if (state.searchGroupsResult.length > 0) {
        const indexRowSearch = state.searchGroupsResult.findIndex(
          (obj) => obj._id === action.payload.idGroup
        );
        state.searchGroupsResult[indexRowSearch].active = action.payload.status;
      }
      return {
        ...state,
        disableSwitch: false,
      };
    }

    case DELETE_GROUP: {
      state.allGroups.splice(
        state.allGroups.findIndex((item) => item._id === action.payload),
        1
      );
      return {
        ...state,
      };
    }
    /**
     * Here are an example of why react needs in some cases clone the full arrange
     * if you update the data but the reference no react says that anything is updated.
     */
    case DELETE_GROUP_STUDENT: {
      const indexStudent = state.actualGroup.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (indexStudent >= 0) {
        const newActual = { ...state.actualGroup };
        const newStudentList = [...newActual.studentList];
        newStudentList.splice(indexStudent, 1);
        newActual.studentList = newStudentList;
        newActual.percentage = (newStudentList.length * 100) / newActual.quota;
        const indexGroup = state.allGroups.findIndex(
          (item) => item._id === action.payload.groupId
        );
        const newAllGroups = state.allGroups;
        if (indexGroup >= 0) {
          const group = newAllGroups[indexGroup];
          const indexAllGroups = group.studentList.list.findIndex(
            (item) => String(item) === String(action.payload.studentId)
          );
          if (indexAllGroups >= 0)
            group.studentList.list.splice(indexAllGroups, 1);
          group.studentListLength = group.studentListLength - 1;
          group.percentage = (group.studentListLength * 100) / group.quota;
        }
        return {
          ...state,
          actualGroup: newActual,
        };
      }
      return state;
    }

    case UPDATE_TABLE_CREATE:
      state.allGroups.push(action.payload);
      return state;

    case UPDATE_TABLE_STUDENTS_ADD_COMMENT: {
      const index = state.actualGroup.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (action.payload.comment !== undefined && index >= 0) {
        let groupProv = { ...state.actualGroup };
        let newObject = { ...groupProv.studentList[index] };
        newObject.comments.push(action.payload.comment);
        groupProv.studentList[index] = newObject;
        return {
          ...state,
          actualGroup: groupProv,
        };
      }
      break;
    }

    case UPDATE_TABLE_STUDENTS_UPDATE_COMMENT: {
      const index = state.actualGroup.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      if (action.payload.comment !== undefined && index >= 0) {
        let talksProv = { ...state.actualGroup };
        let newObject = { ...talksProv.studentList[index] };
        let indexComment = newObject.comments.findIndex(
          (item) => String(item._id) === String(action.payload.comment._id)
        );
        console.log(indexComment);
        if (indexComment >= 0) {
          newObject.comments[indexComment] = action.payload.comment;
          talksProv.studentList[index] = newObject;
          return {
            ...state,
            actualGroup: talksProv,
          };
        }
      }
      return {
        ...state,
      };
    }
    case UPDATE_TABLE_STUDENTS_COMMENT_DELETE: {
      const index = state.actualGroup.studentList.findIndex(
        (item) => String(item._id) === String(action.payload.studentId)
      );
      console.log(index);
      if (action.payload.comment !== undefined && index >= 0) {
        let groupProv = { ...state.actualGroup };
        let newObject = { ...groupProv.studentList[index] };
        let indexComment = newObject.comments.findIndex(
          (item) => String(item._id) === String(action.payload.comment._id)
        );
        if (indexComment >= 0) {
          newObject.comments.splice(indexComment, 1);
          groupProv.studentList[index] = newObject;
          return {
            ...state,
            actualGroup: groupProv,
          };
        }
      }
      return {
        ...state,
      };
    }
    case UPDATE_ATTENDANCE_SIDEBAR:
      const indexAttendace = state.actualGroup.studentList.findIndex(
        (obj) => obj._id === action.payload.studentId
      );
      let newArr = { ...state.actualGroup };
      newArr.studentList[indexAttendace].todayAttendance =
        action.payload.attendance;
      return {
        ...state,
        actualGroup: newArr,
      };
    default:
      return state;
  }
};
export default Reducer;
