import React from 'react';
import { Col, Row, Button, ButtonToolbar, } from 'reactstrap';

export const Header = ({ title, onCloseModal, registerCourse,
  course, childrenArray, createCourse, bloquear, edit, dataCourse, editCourse }) => (
    <Row>
      <Col sm={12} md={5}>
        <div className="card__title">
          <h4 className="bold-text">{title}</h4>
        </div>
      </Col>
      <Col sm={12} md={7} className="addButtonsMarg">
        <ButtonToolbar className="float-right">
          <Button size="sm" className="float-right"
            onClick={() => onCloseModal()}>
            <p>Salir</p>
          </Button>
          <Button className="float-right" color="success" size="sm"
            onClick={() => registerCourse(course, childrenArray, createCourse, edit, dataCourse, onCloseModal, editCourse)}
            disabled={bloquear}
          >
            <p>{title.split(' ')[0]}</p>
          </Button>
        </ButtonToolbar>
      </Col>
    </Row>
  )
