import {
  fetchAsync,
  urlComments,
} from "../../../../../helpers/Globals/globals";

export const commentsStudentTalk = async (studentId) => {
  const result = await fetchAsync(
    urlComments + "?userTalks=" + studentId,
    "",
    "GET",
    ""
  );
  return result;
};

export const addComment = async (body) => {
  const result = await fetchAsync(
    urlComments,
    JSON.stringify(body),
    "POST",
    ""
  );
  return result;
};

export const editCommentById = async (idComment, body) => {
  const result = await fetchAsync(
    urlComments + idComment,
    JSON.stringify(body),
    "PUT",
    ""
  );
  return result;
};

export const deleteCommentById = async (idComment) => {
  const result = await fetchAsync(urlComments + idComment, "", "DELETE", "");
  return result;
};
