export const initialState = {
  studentPaymentsByCourse: [],
  loading: false,
  noPayments: false,
  discountModal: false,
  paymentModal: false,
};

export const types = {
  SET_NO_PAYMENTS: "SET_NO_PAYMENTS",
  SET_LOADING: "SET_LOADING",
  SET_STUDENT_PAYMENTS_BY_COURSE: "SET_STUDENT_PAYMENTS_BY_COURSE",
  SHOW_MODAL_DISCOUNT: "SHOW_MODAL_DISCOUNT",
  SHOW_MODAL_PAYMENT: "SHOW_MODAL_PAYMENT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SHOW_MODAL_DISCOUNT:
      return {
        ...state,
        discountModal: action.payload,
      };
    case types.SHOW_MODAL_PAYMENT:
      return {
        ...state,
        paymentModal: action.payload,
      };
    case types.SET_NO_PAYMENTS:
      return {
        ...state,
        noPayments: action.payload,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case types.SET_STUDENT_PAYMENTS_BY_COURSE:
      return {
        ...state,
        studentPaymentsByCourse: action.payload,
      };
    default:
      return { ...state };
  }
};
export default reducer;
