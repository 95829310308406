import React, { PureComponent } from "react";
import { fetchAsync, urlTalks } from "../../../Globals/globals";
import { newSchedules } from "../../../../helpers/Globals/funciones";
import { Col, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SortTable from "../../../Tables/sortTable";
import moment from "moment";

const headerSortingStyle = { backgroundColor: "#D6DBDF" };

class SelectGroup extends PureComponent {
  static propTypes = {
    // handleSubmit: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      idGroup: "",
      dataSelect: [],
      dataSelectGroup: [],
    };
    this.loadCursosInfo();
  }

  stringHour = (_hour) => {
    var hour = _hour.toString();
    var lengthHour = hour.length;
    var substringminuts = hour.substring(
      Number(lengthHour) - 2,
      Number(lengthHour)
    );
    var substringHour = hour.substring(0, Number(lengthHour) - 2);
    if (substringHour.length === 1) {
      substringHour = "0" + substringHour.toString();
    }
    return substringHour.toString() + ":" + substringminuts.toString();
  };

  dateToIsoString(_date) {
    return moment(_date).format("DD/MM/YYYY");
  }

  rangeDays = (row) => {
    let result =
      this.stringHour(row.startHour) + " - " + this.stringHour(row.endHour);
    return result;
  };

  selectGroup(data) {
    this.props.setSelected(data);
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.props.setGroupId(row._id);
      this.setState({
        idGroup: row._id,
      });
    },
  };

  rowColor = (cell, row) => {
    let rowStyle = { backgroundColor: "#f6f6ff" };
    if (String(this.state.idGroup) === String(cell._id)) {
      return rowStyle;
    }
  };

  rowClassColor = (cell, row) => {
    let rowClass = "rowTableSelected";
    if (this.state.idGroup === cell._id) {
      return rowClass;
    }
  };

  diasHoras = (cell, row) => {
    return newSchedules(cell);
  };

  sortIncrementarDate(dataArray) {
    let sortedArray = dataArray.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    return sortedArray;
  }

  async loadCursosInfo() {
    await this.setState({
      visibleLoading: "initial",
      visibleTable: "none",
    });
    await fetchAsync(urlTalks, "", "GET", "")
      .then((result) => {
        if (result.success) {
          let arrayOrder = [];
          for (let i = 0; i < result.talks.length; i++) {
            let talkDate = moment(result.talks[i].date);
            let startDate = moment().subtract(1, "M");
            let endDate = moment().add(1, "M");
            if (talkDate.isBetween(startDate, endDate)) {
              arrayOrder.push(result.talks[i]);
            }
          }
          this.setState({
            dataSelect: this.sortIncrementarDate(arrayOrder),
          });
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
    await this.setState({
      visibleLoading: "none",
      visibleTable: "initial",
    });
  }

  render() {
    let hiddenRows = [];
    if (this.props.currentGroup) {
      hiddenRows.push(this.props.currentGroup);
    }
    const columns = [
      {
        dataField: "name",
        text: "Nombre del grupo",
        sort: true,
        headerAlign: "center",
        align: "center",
        sortCaret: (order, column) => {
          return <SortTable order={order} colum={column} />;
        },
        headerSortingStyle,
      },
      {
        dataField: "date",
        text: "Fecha de inicio",
        headerAlign: "center",
        align: "center",
        sortCaret: (order, column) => {
          return <SortTable order={order} colum={column} />;
        },
        headerSortingStyle,
        formatter: this.dateToIsoString,
      },
      {
        dataField: "schedules",
        text: "Dia(s) y Horarios",
        sort: true,
        headerAlign: "center",
        align: "center",
        sortCaret: (order, column) => {
          return <SortTable order={order} colum={column} />;
        },
        headerSortingStyle,
        formatter: this.diasHoras,
      },
      {
        dataField: "locationInfo.name",
        text: "Sede",
        sort: true,
        headerAlign: "center",
        align: "center",
        sortCaret: (order, column) => {
          return <SortTable order={order} colum={column} />;
        },
        headerSortingStyle,
      },
    ];
    return (
      <Col sm={12}>
        <ToolkitProvider
          keyField="_id"
          data={this.state.dataSelect}
          columns={columns}
        >
          {(props) => (
            <BootstrapTable
              keyField="_id"
              bordered={false}
              rowEvents={this.rowEvents}
              rowStyle={this.rowColor}
              rowClasses={this.rowClassColor}
              hiddenRows={hiddenRows}
              {...props.baseProps}
              noDataIndication={
                <div style={{ textAlign: "center", color: "#2471A3" }}>
                  <Spinner
                    type="grow"
                    style={{ width: "4rem", height: "4rem" }}
                  />
                </div>
              }
            />
          )}
        </ToolkitProvider>
      </Col>
    );
  }
}

export default SelectGroup;
