import React, { PureComponent } from 'react';
import { Card, CardBody, Button, ButtonToolbar } from 'reactstrap';
import { fetchAsync, urlUsers } from '../../../helpers/Globals/globals'
import EyeIcon from 'mdi-react/EyeIcon';
import {
  showMessageLoading, showMessageWarning,
  showMessageSuccess,
} from '../../../helpers/MessageAndNotificationUtils';

let closeLoading = ""

class VerticalForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
      showError: false,
      errorMessage: "",
      colorMessage: "",
      bloquear: false,
    };
  }

  user = localStorage['user'] && JSON.parse(localStorage['user']);


  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  updatePassword = () => {
    if (!this.state.password || !this.state.passwordConfirmation) {
      showMessageWarning(<strong>Debes de llenar el campo de contraseña y la confirmación</strong>, 3)

    } else if (this.state.password.length <= 4 || this.state.passwordConfirmation.length <= 4) {
      showMessageWarning(<strong>La contraseña  y la confirmación deben terner un minimo de 5 digitos.</strong>, 3)

    } else if (this.state.password !== this.state.passwordConfirmation) {
      showMessageWarning(<strong>Las contraseñas no coinciden , la contraseña y la confirmación deben ser las mismas</strong>, 3)

    } else {

      closeLoading = this.LoadingMessage()
      this.setState({ bloquear: true })
      let body = {};

      body = {
        password: this.state.password,
      }

      body.password = this.state.password;
      fetchAsync(urlUsers + this.user._id, JSON.stringify(body), "PUT")
        .then((result) => {
          //console.log(result)
          closeLoading()
          this.setState({ bloquear: false })
          if (result.success) {
            showMessageSuccess(<strong>La contraseña ha sido cambiada exitosamente</strong>, 3)
            this.setState({
              password: "",
              passwordConfirmation: ""
            })
          }
        }).catch((reason) => {
          console.log(reason)
        }
        );
    }
  }

  LoadingMessage = () => {
    return showMessageLoading('Actualizando..', 0);
  };

  cancelar = () => {
    this.setState({
      password: "",
      passwordConfirmation: "",
      bloquear: false
    })

    if (closeLoading === "") {
      closeLoading()
    }
  }

  render() {

    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Editar contraseña</h5>

          </div>
          <div className="form">
            <div className="form__form-group">

              <span className="form__form-group-label">Nueva contraseña</span>
              <div className="form__form-group-field">
                <input
                  maxlength="100"
                  name="password"
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({
                      password: event.target.value,
                      showError: false
                    });
                  }}
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                />
                <button
                  className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                  onClick={e => this.showPassword(e)}
                ><EyeIcon />
                </button>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Confirmar tú nueva contraseña</span>
              <div className="form__form-group-field">
                <input
                  maxlength="100"
                  name="passwordConfirmation"
                  value={this.state.passwordConfirmation}
                  onChange={(event) => {
                    this.setState({
                      passwordConfirmation: event.target.value,
                      showError: false
                    });
                  }}
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                />
                <button
                  className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                  onClick={e => this.showPassword(e)}
                ><EyeIcon />
                </button>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary"
                disabled={this.state.bloquear === true ? "disabled" : ""}
                onClick={() => this.updatePassword()}
              >
                Actualizar
                </Button>
              <Button c
                onClick={() => this.cancelar()}
              >
                Limpiar
                </Button>
            </ButtonToolbar>
          </div>
        </CardBody>
      </Card>
    );
  }
}
export default VerticalForm;
