import React, { useReducer, createContext } from "react";
import Reducer from "./reducer";

import { getCourseGroups } from "../components/redFunctions";

import {
  SEND_EMAIL_STATUTS,
  SEND_SMS_STATUTS,
  SET_STATUTS_GROUPS_FILTER,
  SET_TAB,
  SET_TEXT_EMAIL,
  SET_TEXT_SMS,
  SET_SUBJECT_EMAIL,
  PAYMENT_FILTER_GROUPS,
  GET_COURSE_GROUPS,
  SET_CHECKBOX_SHOW_ALL,
  SET_STRING_URL,
} from "../context/types";

const inicialState = {
  sendEmail: true,
  sendSms: true,
  groupsList: [],
  emptyGroupsList: false,
  //tabs
  actualTab: "1",
  //filters courses
  stringFilterUrl: "?limit=1000&active=true",
  statusGroups: true,
  checkboxAllGroups: false,
  //filters groups
  paymentFilter: 1, // 1 = todos, 2 = liquidadoos, 3 = con adeudos
  //text message
  textEmail: "",
  textSms: "",
  subjectEmail: "Preparación Politécnico",
};

const MessagesContext = createContext();
export default MessagesContext;

export const MessagesProvider = (props) => {
  const [state, dispatch] = useReducer(Reducer, inicialState);

  const getGroups = (arrayGroups) => {
    dispatch({ type: GET_COURSE_GROUPS, payload: arrayGroups });
  };

  const onChangeEmailStatus = (status) => {
    dispatch({ type: SEND_EMAIL_STATUTS, payload: status });
  };

  const addParameterToUrlString = (
    parameterUrl,
    idCourse,
    removeParam = false
  ) => {
    let onlyParameterName = parameterUrl.split("=")[0];
    let arrayUrlParameters = state.stringFilterUrl.split("&");

    //---------to show all groups--------//
    if (onlyParameterName === "allGroups") {
      let allGroupsValue = parameterUrl.split("=")[1];

      if (allGroupsValue === "true") {
        if (state.stringFilterUrl.includes("active")) {
          for (let i = 0; i < arrayUrlParameters.length; i++) {
            if (arrayUrlParameters[i].includes("active")) {
              arrayUrlParameters.splice(i, 1);
            }
          }

          let stringParameters = arrayUrlParameters.join("&");
          let groupsArray = getCourseGroups(idCourse, stringParameters);
          groupsArray.then((res) => {
            getGroups(res.result);
          });

          dispatch({
            type: SET_STRING_URL,
            payload: stringParameters,
          });
        }
      } else {
        let groupsArray = getCourseGroups(
          idCourse,
          state.stringFilterUrl + `&active=${state.statusGroups}`
        );
        groupsArray.then((res) => {
          getGroups(res.result);
        });
        dispatch({
          type: SET_STRING_URL,
          payload: state.stringFilterUrl + `&active=${state.statusGroups}`,
        });
      }

      return;
    }

    //---------to remove the filter param--------//
    if (removeParam) {
      for (let i = 0; i < arrayUrlParameters.length; i++) {
        if (arrayUrlParameters[i].includes(onlyParameterName)) {
          arrayUrlParameters.splice(i, 1);
        }
      }

      let stringParameters = arrayUrlParameters.join("&");
      let groupsArray = getCourseGroups(idCourse, stringParameters);
      groupsArray.then((res) => {
        getGroups(res.result);
      });
      dispatch({
        type: SET_STRING_URL,
        payload: stringParameters,
      });

      return;
    }

    //---------if filter already exits--------//
    if (state.stringFilterUrl.includes(onlyParameterName)) {
      for (let i = 0; i < arrayUrlParameters.length; i++) {
        if (arrayUrlParameters[i].includes(onlyParameterName)) {
          arrayUrlParameters[i] = parameterUrl;
        }
      }

      let stringParameters = arrayUrlParameters.join("&");
      let groupsArray = getCourseGroups(idCourse, stringParameters);
      groupsArray.then((res) => {
        getGroups(res.result);
      });
      dispatch({
        type: SET_STRING_URL,
        payload: stringParameters,
      });
    } else {
      //---------Its a new filter--------//
      let groupsArray = getCourseGroups(
        idCourse,
        state.stringFilterUrl + "&" + parameterUrl
      );
      groupsArray.then((res) => {
        getGroups(res.result);
      });
      dispatch({
        type: SET_STRING_URL,
        payload: state.stringFilterUrl + "&" + parameterUrl,
      });
    }
  };

  const onChangeSmsStatus = (status) => {
    dispatch({ type: SEND_SMS_STATUTS, payload: status });
  };

  const onChangeStatusGroups = (status) => {
    dispatch({ type: SET_STATUTS_GROUPS_FILTER, payload: status });
  };

  const onChangeCheckboxShowAllGroups = (status) => {
    dispatch({ type: SET_CHECKBOX_SHOW_ALL, payload: status });
  };

  const onChangeTab = (tabNumber) => {
    dispatch({ type: SET_TAB, payload: tabNumber });
  };

  const onChangeEmailText = (text) => {
    dispatch({ type: SET_TEXT_EMAIL, payload: text });
  };

  const onChangeSmsText = (text) => {
    dispatch({ type: SET_TEXT_SMS, payload: text });
  };

  const onChangeEmailSubject = (text) => {
    dispatch({ type: SET_SUBJECT_EMAIL, payload: text });
  };

  const onSelectType = (typeSelected) => {
    dispatch({ type: PAYMENT_FILTER_GROUPS, payload: typeSelected });
  };

  const value = {
    ...state,
    getGroups,
    onChangeEmailStatus,
    onChangeSmsStatus,
    onChangeStatusGroups,
    onChangeCheckboxShowAllGroups,
    addParameterToUrlString,
    onChangeTab,
    onChangeEmailText,
    onChangeSmsText,
    onChangeEmailSubject,
    onSelectType,
  };

  return (
    <MessagesContext.Provider value={value}>
      {props.children}
    </MessagesContext.Provider>
  );
};
