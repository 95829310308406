import React, { Component } from "react";
import { Col, Container, Row, Button, Card } from "reactstrap";
import { Modal } from "react-responsive-modal";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import { semana } from "../Fichas/constants";
import { showMessageWarning } from "../MessageAndNotificationUtils";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import ClockIcon from "mdi-react/ClockIcon";
import moment from "moment";

import styles from "./index.module.scss";

const now = moment().hour(12).minute(0);
let arrayHorarios = [];

class SelectSchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opening: moment().hour(12).minute(0).format("HH:mm"),
      closing: moment().hour(12).minute(0).format("HH:mm"),
    };
    this.onChange = this.onChange.bind(this);
  }

  onCloseModal = () => {
    this.props.close(false);
    arrayHorarios = [];
  };

  onChange(e) {
    //console.log('Checkbox checked:', (e.target.checked));
    //console.log(e.target.name);
    this.setDia(e.target.name, e.target.checked);
  }

  setDia = (valor, status) => {
    //console.log(status)
    switch (valor) {
      case "Lunes":
        if (status === true) {
          let l = { day: "0" };
          arrayHorarios.push(l);
          //console.log(arrayHorarios)
        } else {
          arrayHorarios = arrayHorarios.filter((obj) => {
            return obj.day !== "0";
          });
        }
        break;

      case "Martes":
        if (status === true) {
          let l = { day: "1" };
          arrayHorarios.push(l);
          //console.log(arrayHorarios)
        } else {
          arrayHorarios = arrayHorarios.filter((obj) => {
            return obj.day !== "1";
          });
        }
        break;

      case "Miercoles":
        if (status === true) {
          let l = { day: "2" };
          arrayHorarios.push(l);
          //console.log(arrayHorarios)
        } else {
          arrayHorarios = arrayHorarios.filter((obj) => {
            return obj.day !== "2";
          });
        }
        break;

      case "Jueves":
        if (status === true) {
          let l = { day: "3" };
          arrayHorarios.push(l);
          //console.log(arrayHorarios)
        } else {
          arrayHorarios = arrayHorarios.filter((obj) => {
            return obj.day !== "3";
          });
        }
        break;

      case "Viernes":
        if (status === true) {
          let l = { day: "4" };
          arrayHorarios.push(l);
          //console.log(arrayHorarios)
        } else {
          arrayHorarios = arrayHorarios.filter((obj) => {
            return obj.day !== "4";
          });
        }
        break;

      case "Sabado":
        if (status === true) {
          let l = { day: "5" };
          arrayHorarios.push(l);
          //console.log(arrayHorarios)
        } else {
          arrayHorarios = arrayHorarios.filter((obj) => {
            return obj.day !== "5";
          });
        }
        break;

      case "Domingo":
        if (status === true) {
          let l = { day: "6" };
          arrayHorarios.push(l);
          //console.log(arrayHorarios)
        } else {
          arrayHorarios = arrayHorarios.filter((obj) => {
            return obj.day !== "6";
          });
        }
        break;

      default:
        break;
    }
  };

  //setea la hora de inicio
  handleValueChange = (value) => {
    if (value !== null || value !== undefined) {
      let hora = value.format("HH:mm");
      this.setState({ opening: hora });
    } else {
    }
  };
  //setea la hora de cierre
  handleValueChangeClose = (value) => {
    if (value != null || value !== undefined) {
      //console.log(value && value.format('HH:mm'));
      let hora = value.format("HH:mm");
      this.setState({ closing: hora });
    }
  };

  guardar = () => {
    if (this.state.closing > this.state.opening) {
      arrayHorarios.forEach((value) => {
        value.startHour = this.horaFormato(this.state.opening);
        value.endHour = this.horaFormato(this.state.closing);
      });
      this.props.getHorarioDias(arrayHorarios);
      this.onCloseModal();
    } else {
      showMessageWarning(
        <strong>
          ¡Atención! el horario de término no puede ser menor al de inicio
        </strong>,
        2
      );
    }
  };

  horaFormato = (hora) => {
    let valor = hora.replace(":", "");
    return valor;
  };

  render() {
    return (
      <Container>
        <Modal
          onClose={this.onCloseModal}
          open={this.props.valueModal}
          center={false}
          closeOnEsc={false}
          showCloseIcon={false}
          closeOnOverlayClick={false}
        >
          <Row className="mb-3">
            <Col xs={5} s={5} md={5} className={styles.title_box}>
              <h4 className="bold-text">Dia y Horarios</h4>
            </Col>
            <Col xs={12} s={12} md={7}>
              <Row className="d-flex flex-row">
                <Col sm={12} md={12}>
                  <Button
                    color="success"
                    type="button"
                    className="btn-sm float-right"
                    onClick={() => this.guardar()}
                  >
                    Guardar
                  </Button>
                  <Button
                    style={{ marginRight: "15px" }}
                    type="button"
                    className="btn-sm float-right"
                    onClick={() => this.onCloseModal()}
                  >
                    Salir
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Card>
            <Row>
              <Col xs={6}>
                <p className={styles.text_schedules}>
                  Selecciona el dia(s) del grupo.
                </p>
                {semana.map((valor, index) => {
                  //console.log(valor)
                  return (
                    <div className="mt-2 ml-3" key={index}>
                      <label>
                        <Checkbox
                          className="mr-2"
                          //defaultChecked
                          onChange={this.onChange}
                          name={valor}
                        />
                        {valor}
                      </label>
                      <br></br>
                    </div>
                  );
                })}
              </Col>

              <Col xs={6}>
                <p className={styles.text_schedules}>
                  Selecciona el horario del grupo.
                </p>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Horario de inicio
                  </span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <ClockIcon />
                    </div>
                    <TimePicker
                      showSecond={false}
                      defaultValue={now}
                      className=""
                      use12Hours
                      onChange={this.handleValueChange}
                    />
                  </div>
                </div>

                <div className="form__form-group mt-5">
                  <span className="form__form-group-label">
                    Horario de término
                  </span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <ClockIcon />
                    </div>
                    <TimePicker
                      showSecond={false}
                      defaultValue={now}
                      className=""
                      use12Hours
                      onChange={this.handleValueChangeClose}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Modal>
      </Container>
    );
  }
}
export default SelectSchedules;
