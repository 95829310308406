import React, { useState, useContext, useEffect } from 'react';
import { Popconfirm } from 'antd';
import { Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import CommentsEditor from 'helpers/CommentsEditor'
import { GroupsContext } from 'context/groups';
import Can from 'helpers/Secure/Can';
import SortTable from 'helpers/Tables/sortTable';
import DeleteIcon from 'mdi-react/DeleteIcon';

import styles from '../../../index.module.scss'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'antd/es/pagination/style/index.css'
import 'antd/es/dropdown/style/index.css'
import 'antd/es/table/style/index.css'
import 'antd/lib/popover/style/index.css'
import 'scss/component/motion.scss'

const alphabeticSort = (a, b) => {
  return a.localeCompare(b);
}
const DEFAULT_PAGE_SIZE = 20;
const { SearchBar } = Search;

const TableStudents = (props) => {
  //context
  const groupsContext = useContext(GroupsContext);
  const { updateAfterAddComment, updateAfterDeleteComment, updateAfterEditComment } = groupsContext;

  //states
  const [pagination, setPagination] = useState(1)
  const [paginationSize, setPaginationSize] = useState(20)

  const [collapsableRows, setCollapsableRows] = useState([])

  useEffect(() => {
    setExpandedItems(1, DEFAULT_PAGE_SIZE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (currentPagination, sizePagination) => {
    setExpandedItems(currentPagination, sizePagination)
    setPagination(currentPagination)
    setPaginationSize(sizePagination)
  }

  const setExpandedItems = (current, size) => {
    // const start = (current - 1) * size;
    // const end = (current * size) < props.actualGroup.studentList.length ? (current * size) : props.actualGroup.studentList.length;
    const prevcollapsableRows = [];
    for (let i = 0; i < props.actualGroup.studentList.length; i++) {
      prevcollapsableRows.push(props.actualGroup.studentList[i]._id);
    }
    setCollapsableRows(prevcollapsableRows)
  }


  const colEvents = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      props.onClickList(row)
    },
  };

  const compareHasComment = (a, b) => {
    if (a.comments && a.comments.length > 0)
      if (b.comments && b.comments.length > 0)
        return a.comments.length - b.comments.length
      else return -1
    else return 1
  }

  const debtIcon = (cell, row, index) => {
    if (cell) {
      return (<i className="material-icons red_icon">error</i>)
    } else {
      return " "
    }
  }

  const deleteIcon = (cell, row, index) => {
    return (
      <Can do="delete" on="/students/?/group/?">
        <Popconfirm title="¿Estàs seguro de borrar el registo?"
          okText="Si"
          cancelText="No"
          icon={<i className="material-icons red_icon">warning</i>}
          placement="topLeft"
          onConfirm={() => props.deleteStudentGroup(row._id)}>
          <DeleteIcon style={{ color: "#F44336" }} />
        </Popconfirm>

      </Can>
    )
  }

  const renderContentComments = (rowStudent) => {
    return (
      <CommentsEditor key={rowStudent._id}
        idStudent={rowStudent._id}
        idGroup={props.actualGroup._id}
        comentarios={rowStudent.comments}
        getCommentsByGroup={() => { }}
        updateAfterAddComment={(comment) => { updateAfterAddComment(rowStudent._id, comment) }}
        updateAfterEditComment={(comment) => { updateAfterEditComment(rowStudent._id, comment) }}
        updateAfterDeleteComment={(id) => { updateAfterDeleteComment(rowStudent._id, { _id: id }) }}
      />
    )
  }

  const onExpandTable = (row) => {
    return (
      <Row className="ml-1"> 
        <Col xs={12} sm={12} md={12} >
          {renderContentComments(row)}
        </Col>
      </Row>
    )
  }

  const roundedNumbers = (text, record, index) => {
    if (record.balance !== undefined) {
      return Number(record.balance.payments.total);
    } else {
      return 0;
    }
  }
  const roundedNumbersDiscounts = (text, record, index) => {
    if (record.balance !== undefined) {
      return Number(record.balance.payments.discounts);
    } else {
      return 0;
    }
  }

  const cellStyle = (cell, row, rowIndex, colIndex) => {
    return {
      textAlign: "center",
      verticalAlign: 'middle',
      borderTop: "1px solid transparent"
    }
  }

  const columns = [
    {
      text: '#',
      dataField: 'comments',
      formatter: (cell, row, index) => <p>{((pagination - 1) * paginationSize) + (index + 1)}</p>,
      headerStyle: (colum, colIndex) => {
        return { width: '50px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "50px"
      },
      sort: true,
      sorter: compareHasComment,
      searchable: false,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
    },
    {
      text: 'Nombre',
      dataField: 'name',
      headerStyle: (colum, colIndex) => {
        return { width: '110px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "110px",
      },
      sort: true,
      sorter: (a, b) => alphabeticSort(a.name, b.name),
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      events: colEvents,
    },
    {
      text: 'Apellido Paterno',
      dataField: 'lastName',
      headerStyle: (colum, colIndex) => {
        return { width: '90px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "90px",
      },
      sort: true,
      sorter: (a, b) => alphabeticSort(a.lastName, b.lastName),
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      events: colEvents,
    },
    {
      text: 'Apellido Materno',
      dataField: 'secondLastName',
      headerStyle: (colum, colIndex) => {
        return { width: '90px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "90px",
      },
      sort: true,
      sorter: (a, b) => alphabeticSort(a.secondLastName, b.secondLastName),
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      events: colEvents,
    },
    {
      text: 'Telefono',
      dataField: 'phoneNumber',
      headerStyle: (colum, colIndex) => {
        return { width: '80px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "80px",
      },
      events: colEvents,

    },
    {
      text: 'Telefono 2',
      dataField: 'secondPhoneNumber',
      headerStyle: (colum, colIndex) => {
        return { width: '80px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "80px",
      },
      events: colEvents,
      searchable: false,
    },
    {
      text: 'Correo',
      dataField: 'email',
      headerAlign: (column, colIndex) => 'center',
      style: cellStyle,
      events: colEvents,

    },
    {
      text: 'Pago Total',
      dataField: 'balance.payments.total',
      formatter: roundedNumbers,
      headerStyle: (colum, colIndex) => {
        return { width: '90px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "90px",
      },
      sort: true,
      sorter: (a, b) => a.balance.payments.total - b.balance.payments.total,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      events: colEvents,
      searchable: false,
    },
    {
      text: 'Descuentos',
      dataField: 'balance.payments.discounts',
      formatter: roundedNumbersDiscounts,
      headerStyle: (colum, colIndex) => {
        return { width: '90px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "90px",
      },
      sort: true,
      sorter: (a, b) => a.balance.payments.discounts - b.balance.payments.discounts,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      events: colEvents,
      searchable: false,
    },
    {
      text: 'Adeuda otro curso',
      dataField: 'debt',
      formatter: debtIcon,
      headerStyle: (colum, colIndex) => {
        return { width: '70px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "70px",
      },
      sort: true,
      sorter: (a, b) => a.debt - b.debt,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
      searchable: false,
    },
    {
      text: 'Eliminar',
      dataField: '',
      formatter: deleteIcon,
      headerStyle: (colum, colIndex) => {
        return { width: '80px', textAlign: 'center' };
      },
      style: {
        ...cellStyle(),
        width: "80px"
      },
      searchable: false,
    },
  ];

  return (
    <ToolkitProvider
      keyField={"_id"}
      data={props.actualGroup.studentList}
      columns={columns}
      search
    >
      { props => (
        <div>
          <SearchBar className=""
            placeholder="Buscar alumno" {...props.searchProps}
          // delay="2000"
          />
          <BootstrapTable
            bootstrap4
            classes={styles.tableGroups}
            wrapperClasses="table-responsive"
            bordered={false}
            expandRow={{
              renderer: row => onExpandTable(row),
              expanded: collapsableRows,
              expandByColumnOnly: true,
              // onExpand: (row, isExpand, rowIndex, e) => { console.log("expandable") }
            }}
            pagination={paginationFactory(
              {
                onSizePerPageChange: (sizePerPage, page) => {
                  onChange(page, sizePerPage)
                },
                onPageChange: (page, sizePerPage) => {
                  onChange(page, sizePerPage)
                },
                sizePerPage: DEFAULT_PAGE_SIZE,
                hidePageListOnlyOnePage: true,
              }
            )}
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}
export default TableStudents;

