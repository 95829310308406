import React, { useState } from 'react';
import { fetchAsync, urlLiveChannels } from '../../../helpers/Globals/globals'
import { Col, Row, Button, Container } from 'reactstrap';
import EmptyCard from '../../../helpers/Fichas/emptyCard';
import ModalChannel from './modalChannel'
import { useEffect } from 'react';


const SideBarChannels = () => {

  const [addStreamingModal, setAddStreamingModal] = useState(false);
  const [editStreamingModal, setEditStreamingModal] = useState(false);
  const [channelEditData, setChannelEditData] = useState(false);
  const [channelsInfo, setChannelsInfo] = useState()

  useEffect(() => {
    getChannels()
  }, [])

  const getChannels = async () => {
    const result = await fetchAsync(urlLiveChannels, "", "GET")
    if (result.success) {
      setChannelsInfo(result.channels)
    }
  }

  const openAddModal = () => {
    setAddStreamingModal(true)
  }

  const openEditModal = (data) => {
    setChannelEditData(data)
    setEditStreamingModal(true)
  }

  const closeModal = () => {
    setAddStreamingModal(false)
    setEditStreamingModal(false)
    setChannelEditData(undefined)
  }


  return (
    <Container>
      <Row className="sidebar_course">
        <Col xs="12" className="container_course_info mb-3">
          <Row>
            <p className="fichaNameTitle"> Configuración de canales de transmisión en vivo</p>
          </Row>
          <Row>
            <i className="material-icons ficha_icons">info</i>
            <p className="text-text ficha_text_icons">En esta sección podrás crear ,editar y visualizar los canales de transión en vivo de la plataforma</p>
          </Row>
          <Row>
            <Col xs="12" className="mt-2">
              <Button className="icon square btn-ficha float-right" type="button" color="success" outline
                onClick={() => openAddModal()}
              >
                <div className="textButonFicha" >
                  <p ><i className="material-icons icon">add_to_queue</i>Agregar canal</p>
                </div>
              </Button>
            </Col>
          </Row>
          <p style={{ color: "#11306c" }} className="subTitulosFicha">Canales de livestraming:</p>
          <Col className="mt-2">
            {channelsInfo === undefined ? <EmptyCard message={"No hay canales de livestreaming aún creados en el curso"} />
              : channelsInfo.map((data, index) => { return <li key={data._id} className="nombresLista fichaData" onClick={() => openEditModal(data)}><span className="mr-2">{index + 1} </span>{data.name}<hr></hr></li> })}
          </Col>
          <Row>
          </Row>
        </Col>
      </Row>

      {editStreamingModal || addStreamingModal ?
        <ModalChannel
          channelEditData={channelEditData}
          onCloseModal={closeModal}
          value={true}
          isEditModal={editStreamingModal}
        />
        : null
      }

    </Container>
  )
}
export default SideBarChannels
