/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ForgotForm from './components/ForgotForm';

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <div className="row">
            <a className="account__img" alt="" />
            <h3 className="account__title">PREPARACIÓN
              <span className="account__logo">Politécnico</span>
            </h3>
          </div>
        </div>
        <div className="account__or">
          <p></p>
        </div>
        <ForgotForm onSubmit />
      </div>
      <div className="account__credits mt-1">
        <p>Copyrights © Preparación Politécnico</p>
      </div>
    </div>
  </div>
);

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
