import React, { useState, useEffect } from 'react';
import { Col, ButtonToolbar, Button, Row } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import TransferContentComponent from './TransferContent';

const TransferContent = ({ value, onClose, idPlaylist, idChapter, idCourse, openModalVideoView }) => {

    const [targetId, settargetId] = useState("")


    useEffect(() => {
        console.log("EntroUseeEfect")
        settargetId("")
    }, [])


    const ClassNames = {
        modal: "modal-small",
        // modal: "modal-medium modal-full-height",
    }

    return (
        <Modal
            open={value}
            onClose={onClose}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}
            classNames={ClassNames}
        >

            <Row className="marginTableInfoG">
                <Col xs={12} sm={12} md={8}>
                    <p className="titleInfoTable">Movimiento de material</p>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Button size="sm" className="btn btn-secondary float-right" style={{ marginRight: "15px" }}
                        onClick={onClose}>
                        <p>Cerrar</p>
                    </Button>
                </Col>
            </Row>
            {targetId ?
                <Row className="justify-content-center">
                    <div>
                        <p>Seleccionar grupo destino</p>
                        <ButtonToolbar>
                            <Button color="success" size="sm"
                                onClick={() => alert("click")}>
                                <p>Seleccionar Grupo</p>
                            </Button>
                        </ButtonToolbar>
                    </div>
                </Row> :
                <Row>
                    <>
                        <TransferContentComponent idPlaylist={idPlaylist} idChapter={idChapter} openModalVideoView={openModalVideoView} idCourse={idCourse} />
                    </>
                </Row>
            }
        </Modal >
    )
}
export default TransferContent



