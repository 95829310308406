import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'

const SetFechaTable = (fecha) => {
  
  if (fecha) {
    let date = moment(new Date(fecha)).format("DD/MM/YYYY")
    return (<span style={{color:"#646777"}}>{`${date}`}</span>)

  } else {
    return ''
  }
}

SetFechaTable.propsTypes = {
  fechaS: PropTypes.string.isRequired,
}

export default SetFechaTable;