import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const Sms = ({ textSms = "" }) => {
  return (
    <div className={styles.message_orange}>
      <p className={styles.message_content}>
        <strong>[PREPARACIONPolitécnico]: </strong>
        {textSms}
      </p>
      <div className={styles.message_timestamp_right} />
    </div>
  );
};

Sms.propTypes = {};

export default Sms;
