import React, { Component } from 'react';
import { ConfigProvider } from 'antd';
import { hot } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import Context from '../../context/GlobalContext';
import { ViewportState } from '../../context/Viewport/viewportContext';
import Router from './Router';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import es_ES from 'antd/lib/locale/es_ES';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      console.log("Full loaded page")
      this.setState({
        loaded: true,
        loading: false
      });
    });
  }
  //Add the global context 
  render() {
    const { loaded, loading } = this.state;
    return (
      <BrowserRouter>
        <Context>
          <ViewportState>
            {!loaded ? (
              <div className={`load${loading ? '' : ' loaded'}`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            ) :
              <ConfigProvider locale={es_ES}>
                <Router />
              </ConfigProvider>
            }
          </ViewportState>
        </Context>
      </BrowserRouter>
    );
  }
}

export default hot(module)(App);
