import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
// import cellEditFactory from 'react-bootstrap-table2-editor';

import { deletePayment } from "./fetchFunctions";
import Can from "../../../../../helpers/Secure/Can";
import { ability } from "../../../../../helpers/Secure/abilities";
import SortTable from "../../../../../helpers/Tables/sortTable";
import DeleteButtonConfirm from "../../../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";

const Table = ({ dataTable, afterSuccessDeletePayment }) => {
  const showAmount = (cell, row) => {
    return `$ ${cell}`;
  };

  const showDate = (cell, row) => {
    if (cell) {
      return moment(cell).format("DD-MM-YYYY hh:mm A");
    } else {
      return "No disponible.";
    }
  };

  const showDeleteIcon = (cell, row) => (
    <Can do="delete" on="/payments/*">
      <DeleteButtonConfirm
        title="¿Deseas eliminar el pago?"
        deleteFunction={deletePayment}
        id={cell}
        aditionalInfo={row.course}
        typeButton="icon"
        onCloseOptionalFunc={afterSuccessDeletePayment}
      />
    </Can>
  );

  const showUserPayment = (cell, row) => {
    let nameUser = cell ? ` ${cell?.name} ${cell?.lastName} ` : "No disponible";
    return <b>{nameUser}</b>;
  };

  const showConceptType = (cell, row) => {
    return `${cell} ${row.paymentType}`;
  };

  const columns = [
    {
      dataField: "amount",
      text: "Monto",
      formatter: showAmount,
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        color: "#6a85d6",
      },
      style: {
        textAlign: "center",
        verticalAlign: "middle",
      },
      editable: false,
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        const backgroundColor = "#999999";
        return { backgroundColor };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Monto debe ser un numero",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Solo cantidades positivas",
          };
        }
      },
    },
    {
      dataField: "concept",
      text: "Concepto",
      // formatter: showConceptType,
      style: {
        textAlign: "center",
        verticalAlign: "middle",
      },
      editable: false,
      //editable: edit !== -1 || superAdmin !== -1 ? true : false,
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        const backgroundColor = "#999999";
        return { backgroundColor };
      },
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        color: "#6a85d6",
      },
    },
    {
      dataField: "discount",
      text: "Descuento",
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        color: "#6a85d6",
      },
      style: {
        textAlign: "center",
        verticalAlign: "middle",
      },
      editable: false,
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        const backgroundColor = "#999999";
        return { backgroundColor };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Descuento debe ser un numero",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Solo cantidades positivas",
          };
        }
      },
    },
    {
      dataField: "date",
      text: "Fecha",
      formatter: showDate,
      editable: false,
      style: {
        textAlign: "center",
        verticalAlign: "middle",
      },
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        color: "#6a85d6",
      },
    },
    {
      dataField: "user",
      text: "Autor",
      formatter: showUserPayment,
      editable: false,
      style: {
        textAlign: "center",
        verticalAlign: "middle",
      },
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        color: "#6a85d6",
      },
    },
  ];

  if (ability.can("delete", "/payments/*")) {
    let deleteColumn = {
      dataField: "_id",
      text: "",
      formatter: showDeleteIcon,
      style: {
        textAlign: "center",
        color: "#ff0000",
        verticalAlign: "middle",
        width: "40px",
      },
      editable: false,
      headerStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        color: "#6a85d6",
        width: "60px",
      },
    };
    columns.push(deleteColumn);
  }

  return (
    <BootstrapTable
      keyField="_id"
      data={dataTable}
      columns={columns}
      // hover
      bordered={false}
      // cellEdit={cellEditFactory({
      //   mode: 'dbclick',
      //   blurToSave: true,
      //   beforeSaveCell: (oldValue, newValue, row, column) => { this.editarPago(oldValue, newValue, row, column) }
      // })}
      noDataIndication={"No hay pagos"}
    />
  );
};
export default Table;
