import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import { TalksContext } from "context/TalksContext";

import AddPlatica from "./components/addPlatica";
import TablePlatica from "./components/table";
import PaginationTable from "./components/paginationTable";
import SidebarTalks from "./components/Sidebar";

import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import MainPageHeader from "../../helpers/Headers/pageHeader";

import styles from "./index.module.scss";
import "antd/es/page-header/style/index.css";

function Platicas() {
  const talksContext = useContext(TalksContext);
  const {
    state,
    setSelectedTalk,
    togleStatusTalk,
    paginationTalks,
  } = talksContext;
  const { loadingAllTalks, talks, talkSelected, totalTalks } = state;
  const [showAddTalk, setShowAddTalk] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    paginationTalks(0, 30);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //funcion que trae los datos del row al darle click a la table es un callback
  const getActualTalk = (rowData) => {
    setSelectedTalk(rowData);
    setShowDrawer(true);
  };

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <MainPageHeader
            title="Administración Escolar |"
            subTitle={`Platicas Informativas`}
            buttonTitle={"Agregar platica"}
            buttonOnClick={() => setShowAddTalk(true)}
            canActions={["post", "/talks/"]}
          />
        </Col>
        <Col xs="12" className="mb-2">
          <section className="float-right">
            <PaginationTable
              paginationTalks={paginationTalks}
              totalTalks={totalTalks}
            />
          </section>
        </Col>
        <Col sm={12} className={showDrawer ? styles.table_container : ""}>
          <TablePlatica
            loadingAllTalks={loadingAllTalks}
            talks={talks}
            talkSelected={talkSelected}
            getActualTalk={getActualTalk}
            changeStatus={(id) => togleStatusTalk(id)}
          />
        </Col>
      </Row>

      {showAddTalk && (
        <AddPlatica
          closeModal={() => setShowAddTalk(false)}
          valueModal={showAddTalk}
          getPlaticas={() => console.log("")}
        />
      )}

      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SidebarTalks onClosSidebar={() => setShowDrawer(false)} />
        </SidebarRightComponent>
      )}
    </Container>
  );
}
export default Platicas;
