import React, { useReducer } from 'react';
import RolesReducer from './RolesReducer';
import RolesContext from './RolesContext';
import {
  fetchAsync, urlRoles
} from '../../helpers/Globals/globals';
import {
  SELECTED_ROLE,
  GET_ALL_ROLES,
  DELETE_ROLES,
  UPDATE_ROLE,
  UPDATE_AFTER_CREATE_ROLE,
  USERS_IN_ROLE,
  RESET_STATES_ROLES,
} from '../types/index';

const initialState = {
  allRoles: [],
  selectedRole: {},
  roleUsers: [],
}

const RolesState = (props) => {

  //crear dispatch y state
  const [state, dispatch] = useReducer(RolesReducer, initialState)


  const setSelectedRole = (roleInfo) => {
    dispatch({
      type: SELECTED_ROLE,
      payload: roleInfo
    })
  }

  const createRole = async (body) => {
    try {
      const resRole = await fetchAsync(urlRoles, JSON.stringify(body), "POST", "")
      if (resRole.success) {
        dispatch({
          type: UPDATE_AFTER_CREATE_ROLE,
          payload: resRole.role
        })
      }
      return resRole
    } catch (error) {
      console.log(error)
    }
  }

  const updateRole = async (selectedRoleEdit) => {
    try {
      const resRole = await fetchAsync(urlRoles + state.selectedRole._id, JSON.stringify(selectedRoleEdit), "PUT", "")
      if (resRole.success) {
        dispatch({
          type: UPDATE_ROLE,
          payload: resRole.role
        })
      }
      return resRole
    } catch (error) {
      console.log(error)
    }
  }

  const getRoles = () => {
    return fetchAsync(urlRoles, "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: GET_ALL_ROLES,
            payload: result.roles
          })
        }
        return result
      }).catch((reason) => {
        console.log(reason.message)
      });
  }

  const deleteRole = () => {
    return fetchAsync(urlRoles + state.selectedRole._id, "", "DELETE", "")
      .then(result => {
        if (result.success) {
          dispatch({
            type: DELETE_ROLES,
            payload: state.selectedRole._id
          })
        }
        return result
      }).catch((reason) => {
        console.log(reason.message)
      });
  }

  const getRolesUsers = async () => {
    try {
      const resRole = await fetchAsync(urlRoles + state.selectedRole._id + "/users", "", "GET", "")
      if (resRole.success) {
        dispatch({
          type: USERS_IN_ROLE,
          payload: resRole.users
        })
      }
      return resRole
    } catch (error) {
      console.log(error)
    }
  }

  const resetStates = () => {
    dispatch({
      type: RESET_STATES_ROLES,
      payload: ""
    })
  }


  return (
    <RolesContext.Provider
      value={{
        allRoles: state.allRoles,
        getRoles,
        selectedRole: state.selectedRole,
        setSelectedRole,
        deleteRole,
        updateRole,
        createRole,
        roleUsers: state.roleUsers,
        getRolesUsers,
        resetStates,
      }}
    >
      {props.children}
    </RolesContext.Provider>
  )
}
export default RolesState

