import React from 'react';
const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

function SidebarButton(props) {
  const { changeSidebarVisibility, sidebarShow } = props;
  return (
    <div>
      <button type="button" className={sidebarShow ? "sidebar__button sidebar__button--desktop" : "sidebar__button sidebar__button--desktop collapsed"} onClick={changeSidebarVisibility}>
        <img src={icon} alt="" className="sidebar__button-icon" />
      </button>
    </div>
  );
}

export default SidebarButton;
