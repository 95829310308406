//import React from 'react';
import React from "react";
import {
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import MoreVertIcon from "mdi-react/MoreVertIcon";

const CardsConfig = ({
  name,
  description,
  items,
  menuItems = [],
  setShowDrawer,
}) => {
  const showSidebar = () => {
    setShowDrawer(true);
  };

  return (
    <Col xs={12} sm={6} md={6} onClick={showSidebar}>
      <Card>
        {menuItems.length > 0 ? (
          <UncontrolledDropdown inNavbar>
            <DropdownToggle className="card-button icon" outline>
              <MoreVertIcon className="card-button-icon" />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <DropdownItem>Cambiar grupo</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
        <CardBody style={{ background: "#f2f4f7" }}>
          <div className="card__title">
            <h5 className="bold-text">{name}</h5>
            <h5 className="subhead">{items}</h5>
          </div>
          <div className="dashboard__total dashboard__total--area">
            <p>{description}</p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default CardsConfig;
