import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingComponent from "../../../../../../helpers/LoadingComponent";
import SetFechaTable from '../../../../../../helpers/Tables/fechaTables';

const TableCourses = ({ groupsArray, empty, nextTable }) => {

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      nextTable(row)
    },
  };

  const fecha = (cell, row) => {
    return SetFechaTable(cell)
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Grupo',
      sort: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'courseInfo.name',
      text: 'Curso',
      sort: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'startDate',
      text: 'Inicia',
      sort: true,
      headerAlign: 'center',
      align: 'center',
      formatter: fecha,
    },
    {
      dataField: 'endDate',
      text: 'Termina',
      sort: true,
      headerAlign: 'center',
      align: 'center',
      formatter: fecha,
    },
  ];

  return (
    <div className="scrollComentariosAlumno">
      <h3>Selecciona un grupo</h3>
      <BootstrapTable keyField='_id'
        data={groupsArray}
        columns={columns}
        hover
        striped
        bordered={false}
        rowEvents={rowEvents}
        noDataIndication={empty ?
          <div style={{ textAlign: "center" }}>El alumno no tiene grupos disponibles</div>
          : <LoadingComponent />}
      />
    </div>
  );
}
export default TableCourses;
