import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { dayWeekLetter, unAvailableMessage } from '../../../../../helpers/Globals/funciones';
import {
  showNotification, showMessageLoading, showMessageWarning, showMessageError
} from '../../../../../helpers/MessageAndNotificationUtils';
let closeLoading = ""

const Buttons = ({ page, setPage, onCloseModal, addGroup, updateCreate,
  newGroupData, selectedCourse, availableClassroom }) => {

  const [disableButton, setDisableButton] = useState(false);

  const createGroup = () => {
    setDisableButton(true)
    closeLoading = loadingMessage()
    let cloneSchedules = newGroupData.schedules.map(a => ({ ...a }))
    dayWeekLetter(cloneSchedules)

    //datos para el servicio de disponibilidad del salón
    let body = {
      classRoomId: newGroupData.classRoom,
      endDate: newGroupData.endDate,
      startDate: newGroupData.startDate,
      scheduleArray: cloneSchedules
    }

    let available = availableClassroom(body);
    available.then((result) => {
      if (result.success) {
        if (!result.isAvailable) {
          result.groups.forEach((dataGroup) => {
            closeLoading();
            setDisableButton(false)
            showNotification("error", "El salón ya esta ocupado en el horario y duración asignados.", unAvailableMessage(dataGroup), 0)
            //showNotification("error", "El salón ya esta ocupado en el horario y duración asignados.", datos, 0)
          })
        } else {
          //validamos si eligio profesor
          let cloneGroupData = Object.assign({}, newGroupData);
          if (newGroupData.teacher === '') delete cloneGroupData.teacher

          let classicReg = addGroup(newGroupData.teacher === '' ? cloneGroupData : newGroupData)
          classicReg.then((result) => {
            if (result.success) {
              // let inicialDate = moment.utc(new Date()).local().format()
              // getAllGroups("?endRngStart=" + inicialDate);
              updateCreate(result.group)
              setPage(page + 1)
              closeLoading();
              setDisableButton(false)
            } else {
              showMessageError(<strong>{result.errorExt.message.es}</strong>, 3)
              closeLoading();
              setDisableButton(false)
            }
          })
        }
      } else {
        showMessageError(<strong>{result.errorExt.message.es}</strong>, 3)
        closeLoading();
        setDisableButton(false)
      }
    })
  }

  const validateNextStep = () => {
    switch (page) {
      case 1:
        if (selectedCourse === null) return showMessageWarning(<strong>Atención! Debes seleccionar un curso.</strong>, 3)
        setPage(page + 1)
        break;
      case 2:
        if (newGroupData.name === "") return showMessageWarning(<strong>Atención! Debes escribir un nombre.</strong>, 3)
        if (newGroupData.schedules.length === 0) return showMessageWarning(<strong>Atención! Debes agregar los horarios.</strong>, 3)
        //si es un curso padre comipems
        if (selectedCourse.hasOwnProperty("isParent")) {
          setDisableButton(true)
          closeLoading = loadingMessage()
          let cloneGroupData = Object.assign({}, newGroupData);
          delete cloneGroupData.teacher
          delete cloneGroupData.location
          delete cloneGroupData.quota
          delete cloneGroupData.classRoom
          let add = addGroup(cloneGroupData)
          add.then((result) => {
            if (result.success) {
              // let inicialDate = moment.utc(new Date()).local().format()
              // getAllGroups("?endRngStart=" + inicialDate);
              updateCreate(result.group)
              setPage(page + 1)
              closeLoading();
              setDisableButton(false)
            } else {
              showMessageError(<strong>{result.errorExt.message.es}</strong>, 3)
              closeLoading();
              setDisableButton(false)
            }
          })
          return true
        }
        if (newGroupData.classRoom === "") return showMessageWarning(<strong>Atención! Debes agregar un salón.</strong>, 3)
        if (newGroupData.quota === "") return showMessageWarning(<strong>Atención! Debes agregar un cupo para el grupo.</strong>, 3)
        createGroup()
        break;
      case 3:
        break;
      // if (selectedGroup === "") return showMessageWarning(<strong>Atención! Debes seleccionar un grupo.</strong>, 3)
      // nextPage()
      // break
      default:
        break;
    }
  }

  const loadingMessage = () => {
    return showMessageLoading('Registrando..', 0);
  };

  return (
    <>
      {page === 2 ?
        <Button type="button"
          className="btn-sm float-left"
          onClick={() => setPage(page - 1)}
        >
          Regresar
        </Button> : ""}

      {page < 2 ? <Button color="success" type="button"
        className="btn-sm float-right"
        onClick={() => validateNextStep()}
      >
        Siguiente
      </Button> : ""}

      {page === 2 ? <Button color="warning" className="btn-sm float-right"
        onClick={() => { validateNextStep() }}
        disabled={disableButton ? true : false}
      >
        Registrar
      </Button> : ""}

      <Button type="button" color={page === 3 ? "success" : "secondary"}
        className="btn-sm float-right mr-2"
        onClick={() => {
          onCloseModal()
          if (closeLoading !== "") closeLoading();
        }}
      >
        {page === 3 ? " Salir" : " Salir sin guardar"}
      </Button>
    </>
  )
}
export default Buttons

