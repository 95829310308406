import React, { PureComponent } from "react";
import { Tree } from "antd";
import { reverseServiceToTree } from "../../../helpers/Secure/utils";
import { removeItemFromArray } from "../../../helpers/Globals/funciones";
import "antd/lib/tree/style/index.css";
import { treeData } from "../contants/dataTree";

import styles from "./index.module.scss";

const { TreeNode } = Tree;
// let key= treeData[0].children[0].clildren[2].key
class PermissionList extends PureComponent {
  constructor(props) {
    super(props);
    let allowa = reverseServiceToTree(
      this.props.Rol ? this.props.Rol.allows : ""
    );
    this.state = {
      autoExpandParent: true,
      expandedKeys: allowa,
      checkedKeys: allowa,
      checkedKeysDefault: allowa,
      selectedKeys: [],
      isUpdate: this.props.isUpdate,
    };
  }

  componentDidUpdate(prevProps, prepState) {
    if (this.props.Rol) {
      if (prevProps.Rol._id !== this.props.Rol._id) {
        let allowa = reverseServiceToTree(
          this.props.Rol ? this.props.Rol.allows : ""
        );
        this.setState({
          checkedKeys: allowa,
          expandedKeys: allowa,
          checkedKeysDefault: allowa,
          isUpdate: this.props.isUpdate,
          autoExpandParent: true,
        });
        return true;
      } else if (prevProps.isUpdate !== this.props.isUpdate) {
        let allowa = reverseServiceToTree(
          this.props.Rol ? this.props.Rol.allows : ""
        );
        this.setState({
          isUpdate: this.props.isUpdate,
          checkedKeys: allowa,
          checkedKeysDefault: allowa,
        });
        return true;
      }
    }
    return true;
  }
  buscarChecked = (value1, value2) => {
    let resultado = value1.find(function (element) {
      return element === value2;
    });
    if (resultado) return true;
    return false;
  };
  onExpand = (expandedKeys) => {
    //console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  /*
   * Dependency add will allow to defined when you need to use more service for a screen
   *
   */

  handleArrayAdd = (array) => {
    let temp = [];
    for (let i = 0; i < array.length; i++) {
      //console.log("array in " + i, array[i])
      Array.prototype.push.apply(temp, this.handleObjectAdd(array[i]));
    }
    let finalTemp = [...new Set(temp)];
    return finalTemp;
  };

  handleObjectAdd = (object) => {
    let result = [];
    if (object.children) {
      let results = this.handleArrayAdd(object.children);
      return results;
    } else {
      //Here is were the dependency is manage and add
      result.push(object.key);
      if (object.dependency) {
        // if (!isInArray(this.state.checkedKeys,object.key))
        //hasta aqui agrega las dependency , no revisa si ya fueron agregadas
        Array.prototype.push.apply(result, object.dependency);
      }
      return result;
    }
  };

  handleArrayRemove = (array, childs) => {
    let temp = array;
    for (let i = 0; i < childs.length; i++) {
      temp = this.handleObjectRemove(array, childs[i]);
    }
    return temp;
  };

  handleObjectRemove = (array, object) => {
    let result = [];
    if (object.children) {
      let results = this.handleArrayRemove(array, object.children);
      return results;
    } else {
      //Here is were the dependency is manage and remove
      result = removeItemFromArray(array, object.key);
      if (object.dependency) {
        //if has dependency iterate for deleting from original array
        object.dependency.forEach((element) => {
          removeItemFromArray(array, element);
        });
      }
      return result;
    }
  };

  onCheck = (checkedKeys, e) => {
    let checkedKeysDependency = this.state.checkedKeys;
    //let uniq = [...new Set(checkedKeysDependency)];
    if (e.checked) {
      let result = this.handleObjectAdd(e.node.props.dataRef);

      Array.prototype.push.apply(checkedKeysDependency, result);
    } else {
      let result = this.handleObjectRemove(
        checkedKeysDependency,
        e.node.props.dataRef
      );
      checkedKeysDependency = result;
    }
    if (this.props.handlerCheked)
      this.props.handlerCheked(checkedKeysDependency);
    this.setState((state) => {
      return { checkedKeys: [...new Set(checkedKeysDependency)] };
      //return { checkedKeys: checkedKeysDependency };
    }, this.forceUpdate);
  };

  onSelect = (selectedKeys, info) => {
    // console.log('onSelect', info);
    this.setState({ selectedKeys });
  };

  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            icon={
              this.buscarChecked(this.state.checkedKeys, item.key) ? (
                <i className="material-icons">done</i>
              ) : (
                ""
              )
            }
            title={item.title}
            key={item.key}
            dataRef={item}
            disabled={item.disabled}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          icon={
            this.buscarChecked(this.state.checkedKeys, item.key) ? (
              <i className="material-icons">done</i>
            ) : (
              ""
            )
          }
          title={item.title}
          key={item.key}
          dataRef={item}
          disabled={item.disabled}
        />
      );
    });

  render() {
    return (
      <section className={styles.tree_container}>
        <Tree
          showIcon={!this.state.isUpdate}
          checkable={this.state.isUpdate}
          selectable={false}
          onExpand={this.onExpand}
          expandedKeys={this.state.expandedKeys}
          autoExpandParent={this.state.autoExpandParent}
          onCheck={this.onCheck}
          checkedKeys={this.state.checkedKeys}
          onSelect={this.onSelect}
          selectedKeys={this.state.selectedKeys}
          disabled={this.props.disabled}
        >
          {this.renderTreeNodes(treeData)}
        </Tree>
      </section>
    );
  }
}
export default PermissionList;
