import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import moment from "moment";
import "moment/locale/es";

import {
  fetchAsync,
  urlStudents,
} from "../../../../../helpers/Globals/globals";
import { showMessageWarning } from "../../../../../helpers/MessageAndNotificationUtils";
import DeleteButtonConfirm from "../../../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";

import PencilIcon from "mdi-react/PencilIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import MailRuIcon from "mdi-react/MailRuIcon";
import PhoneIcon from "mdi-react/PhoneIcon";

import Can from "../../../../../helpers/Secure/Can";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";

import styles from "./index.module.scss";
import "antd/es/date-picker/style/index.css";
import "antd/lib/message/style/index.css";

const dateFormat = "DD/MM/YYYY";
const inicialState = {
  name: "",
  lastName: "",
  secondLastName: "",
  email: "",
  phoneNumber: "",
  secondPhoneNumber: "",
  birthDate: moment.utc(new Date()).local().format(),
};
const Ava = `${process.env.PUBLIC_URL}/img/generics/face.png`;

const StudentForm = ({
  studentData,
  updateStudent,
  afterDeleteStudent,
  onCloseModal,
}) => {
  const [student, setStudent] = useState(inicialState);
  const [activeEidt, setActiveEidt] = useState(false);
  const [oldInfoStudent, setOldInfoStudent] = useState("");

  useEffect(() => {
    const {
      name = "",
      secondLastName = "",
      lastName = "",
      email = "",
      phoneNumber = "",
      secondPhoneNumber = "",
      birthDate,
    } = studentData;
    setStudent({
      name,
      secondLastName,
      lastName,
      email,
      phoneNumber,
      secondPhoneNumber,
      birthDate:
        birthDate !== undefined
          ? moment.utc(birthDate).local().format()
          : new Date(),
    });
    setOldInfoStudent(studentData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChamgeInputs = (e) => {
    setStudent({
      ...student,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    let local = moment.utc(date).local().format();
    setStudent({
      ...student,
      birthDate: local,
    });
  };

  const updateData = () => {
    let letterNumber = /[0-9]/g;
    let ifNum = student.name + student.lastName + student.secondLastName;

    if (
      student.name === "" ||
      student.lastName === "" ||
      student.secondLastName === ""
    ) {
      showMessageWarning("Atención! Debes ingresar un nombre y apellidos.", 3);
    } else if (ifNum.match(letterNumber)) {
      showMessageWarning(
        "Atención! Debes escribir solo letras en nombre y apellidos",
        3
      );
    } else if (student.email === "") {
      showMessageWarning(
        "Atención! Debes ingresar un correo, ejemplo@mail.com",
        3
      );
    } else if (
      (student.email.includes("@") && student.email.includes(".")) !== true
    ) {
      showMessageWarning(
        `Atención! Debes ingresar un @  y un  punto (.), el correo : ${student.email} esta incompleto`,
        3
      );
    } else if (
      student.phoneNumber === undefined ||
      student.phoneNumber === "" ||
      student.phoneNumber.length < 10
    ) {
      showMessageWarning(
        "Atención! Debes ingresar un numero de teléfono minimo 10 digitos",
        3
      );
    } else if (
      student.secondPhoneNumber === undefined ||
      student.secondPhoneNumber === "" ||
      student.secondPhoneNumber.length < 8
    ) {
      showMessageWarning(
        "Atención! Debes ingresar un  segundo numero de teléfono minimo 10 digitos",
        3
      );
    } else {
      fetchAsync(
        urlStudents + studentData._id,
        JSON.stringify(student),
        "PUT",
        "default"
      )
        .then((data) => {
          if (data.success) {
            setActiveEidt(false);
            updateStudent(data.student);
            setOldInfoStudent(data.student);
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };

  const discard = () => {
    setActiveEidt(false);
    if (oldInfoStudent !== "") {
      setStudent({
        name: oldInfoStudent.name,
        secondLastName: oldInfoStudent.secondLastName,
        lastName: oldInfoStudent.lastName,
        email: oldInfoStudent.email,
        phoneNumber: oldInfoStudent.phoneNumber,
        secondPhoneNumber: oldInfoStudent.secondPhoneNumber,
        birthDate:
          oldInfoStudent.birthDate !== undefined
            ? moment.utc(oldInfoStudent.birthDate).local().format()
            : new Date(),
      });
    }
  };

  //funcion para eliminar un alumno
  const deleteStudent = async (id) => {
    return fetchAsync(urlStudents + id, "", "DELETE", "")
      .then((data) => {
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const afterDelete = () => {
    afterDeleteStudent(studentData._id);
  };

  return (
    <Container>
      <Row className="mt-2">
        <Col xs="12" className={styles.button_container}>
          <Button
            size="sm"
            className={`btn btn-secondary float-right`}
            onClick={onCloseModal}
          >
            Salir
          </Button>
        </Col>
        <Col
          xs={12}
          sm={5}
          className={`${styles.show_avatar} ${styles.avatar_ficha_container}`}
        >
          <img className="avatar_Ficha" src={Ava} alt="avatar" />
        </Col>
        <Col xs={12} sm={7}>
          {!activeEidt ? (
            <Can do="put" on="/students/">
              <Button
                size="sm"
                className="btn float-right"
                outline
                color="success"
                onClick={() => {
                  setActiveEidt(true);
                }}
              >
                <p>
                  <PencilIcon />
                  Editar
                </p>
              </Button>
            </Can>
          ) : (
            <div>
              <div className={styles.div_buttons_edit}>
                <Button
                  size="sm"
                  className="btn float-right"
                  color="success"
                  onClick={updateData}
                >
                  <p>Actualizar</p>
                </Button>
              </div>
              <div className={styles.div_buttons_edit}>
                <Button
                  size="sm"
                  className="btn btn-secondary float-right mr-2"
                  onClick={discard}
                >
                  <p>Descartar</p>
                </Button>
              </div>
            </div>
          )}
        </Col>
        {!activeEidt ? (
          <Col xs="12" className="mt-3 pd-5">
            <div>
              <div className="mb-3">
                <span className={`form__form-group-label ${styles.info_text}`}>
                  Nombre
                </span>
                <br />
                <span className={`${styles.info_text_two} ml-2`}>
                  {student.name}
                </span>
              </div>
              <div className="mb-3">
                <span className={`form__form-group-label ${styles.info_text}`}>
                  Apellido paterno
                </span>
                <br />
                <span className={`${styles.info_text_two} ml-2`}>
                  {student.lastName}
                </span>
              </div>
              <div className="mb-3">
                <span className={`form__form-group-label ${styles.info_text}`}>
                  Apellido materno
                </span>
                <br />
                <span className={`${styles.info_text_two} ml-2`}>
                  {student.secondLastName}
                </span>
              </div>
              <div className="mb-3">
                <span className={`form__form-group-label ${styles.info_text}`}>
                  Fecha de nacimiento
                </span>
                <br />
                <span className="dataInfo calendarioText">
                  {moment.utc(student.birthDate).local().format(dateFormat)}
                </span>
              </div>
              <div className="mb-3">
                <span className={`form__form-group-label ${styles.info_text}`}>
                  Correo
                </span>
                <br />
                <span className={`${styles.info_text_two} ml-2`}>
                  {student.email}
                </span>
              </div>
              <div className="mb-3">
                <span className={`form__form-group-label ${styles.info_text}`}>
                  Teléfono
                </span>
                <br />
                <span className={`${styles.info_text_two} ml-2`}>
                  {student.phoneNumber}
                </span>
              </div>
              <div className="mb-3">
                <span className={`form__form-group-label ${styles.info_text}`}>
                  Segundo Teléfono
                </span>
                <br />
                <span className={`${styles.info_text_two} ml-2`}>
                  {student.secondPhoneNumber}
                </span>
              </div>
            </div>
          </Col>
        ) : (
          <Col xs="12" className="mt-3">
            <form>
              <div className="form__form-group">
                <span className="form__form-group-label">Nombre</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    autoComplete="new-password"
                    value={student.name}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Apellido paterno</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    autoComplete="new-password"
                    value={student.lastName}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Apellido materno</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="secondLastName"
                    name="secondLastName"
                    autoComplete="new-password"
                    value={student.secondLastName}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Fecha de nacimiento
                </span>
                <div className="form__form-group-field">
                  <DatePicker
                    defaultValue={moment("01/01/2020", dateFormat)}
                    value={moment(student.birthDate)}
                    format={dateFormat}
                    locale={locale}
                    allowClear={false}
                    onChange={onChangeDate}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Correo electrónico
                </span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <MailRuIcon />
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    autoComplete="new-password"
                    placeholder="ejemplo@mail.com"
                    value={student.email}
                    onChange={onChamgeInputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Teléfono</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <PhoneIcon />
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    autoComplete="new-password"
                    value={student.phoneNumber}
                    onChange={onChamgeInputs}
                  />
                </div>
                <span className="form__form-group-description ml-4">
                  Telefono debe incluir como minimo 10 digitos 55 77889966.
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Segundo Teléfono</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <PhoneIcon />
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="secondPhoneNumber"
                    name="secondPhoneNumber"
                    autoComplete="new-password"
                    value={student.secondPhoneNumber}
                    onChange={onChamgeInputs}
                  />
                </div>
                <span className="form__form-group-description ml-4">
                  Telefono debe incluir como minimo 8 digitos 77889966.
                </span>
              </div>
              <Can do="delete" on="/students/">
                <div className={!activeEidt ? "collapse" : ""}>
                  <DeleteButtonConfirm
                    title="¿Deseas eliminar al estudiante?"
                    deleteFunction={deleteStudent}
                    id={studentData?._id}
                    onCloseOptionalFunc={afterDelete}
                  />
                </div>
              </Can>
            </form>
          </Col>
        )}
      </Row>
    </Container>
  );
};
export default StudentForm;
