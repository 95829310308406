import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from "reactstrap";
import PropTypes from 'prop-types';
import { attemps, instructions, timeIns } from '../../../helpers/Fichas/constants';
import { Link } from "react-router-dom";
import { Divider, Select, Checkbox, TimePicker } from 'antd';
import { Switch, Tooltip, Breadcrumb } from 'antd';
import "antd/es/breadcrumb/style/index.css";
import "antd/es/divider/style/index.css";
import "antd/es/select/style/index.css";
import "antd/es/switch/style/index.css";
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/time-picker/style/index.css';
import AddIcon from 'mdi-react/AddIcon';
import moment from 'moment';
import 'moment/locale/es';

const { Option } = Select;

const LeftBar = ({ courseInfo, playlistInfo, quizInfo, onChangeInputs,
  validations, saved, setOpenModalVideos, setOpenModalReorder, disable }) => {

  const [timeSelector, setTimeSelector] = useState(false)

  useEffect(() => {
    if (quizInfo.limitTime > 0) {
      setTimeSelector(true)
    }
  }, [quizInfo])

  const handleChange = (value) => {
    onChangeInputs("studentAttempts", value)
  }

  const onChangeSwitch = (checked) => {
    onChangeInputs("active", checked)
  }

  const onChangeCheckbox = (e) => {
    onChangeInputs("random", e.target.checked)
  }

  const onChangeCheckboxTime = (e) => {
    setTimeSelector(e.target.checked)
    if (!e.target.checked) onChangeInputs("limitTime", 0)
  }

  const onChangeTime = (time, timeString) => {
    let timeSplit = timeString.split(':');
    let seconds = (+timeSplit[0]) * 60 * 60 + (+timeSplit[1]) * 60 + (+timeSplit[2]);
    onChangeInputs("limitTime", seconds)
  }

  const setTimeValue = (seconds) => {
    const formatted = moment.utc(seconds * 1000).format('HH:mm:ss');
    return formatted
  }

  return (
    <div className="div_leftbar_conent">
      <Divider orientation="left">
        Crear cuestionario
         <Tooltip title={instructions} placement="rightTop">
          <i className="material-icons tooltip_quiz">info</i>
        </Tooltip>
      </Divider>
      <div className="div_leftbar_title">
        <Breadcrumb >
          <Breadcrumb.Item>
            <Link to={`/material/${courseInfo._id}`}>
              <i className="material-icons ">arrow_back</i>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {courseInfo.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {playlistInfo.name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="mt-4 mb-1">
        <Button className="btn" size="sm" color="add"
          onClick={() => validations()}
          disabled={disable}
        >
          <p><AddIcon />{saved ? "Actualizar cuestionario" : " Guardar cuestionario"}</p>
        </Button>
        <Button className="btn" size="sm" color="add"
          disabled={!saved}
          onClick={() => setOpenModalVideos(true)}
        >
          <p>Agregar videos</p>
        </Button>
        <Button className="btn" size="sm" color="add"
          onClick={() => setOpenModalReorder(true)}
        >
          <p>Reordenar preguntas</p>
        </Button>
      </div>
      <div className="form__form-group  div_form">
        <p>Título del cuestionario</p>
        <div className="form__form-group-field">
          <input type="text" name="name"
            maxLength={55}
            className="form-control"
            id="inpuName" autoComplete="off"
            onChange={(e) => onChangeInputs(e.target.name, e.target.value)}
            value={quizInfo.name}
          />
        </div>
        <p>Descripción <span className="caracteres-description">caracteres restantes: {200 - quizInfo.description.length}</span></p>
        <div className="form__form-group-field">
          <textarea type="textarea" name="description"
            maxLength={200}
            className="form-control textarea_size"
            id="inpuName" autoComplete="off"
            onChange={(e) => onChangeInputs(e.target.name, e.target.value)}
            value={quizInfo.description}
          />
        </div>
        <Row className="mt-3">
          <Col xs="5" className="text-center">
            <p>Preguntas creadas</p>
            {quizInfo.quiz.length}
          </Col>
          <Col xs="7">
            <div className="form__form-group">
              <p>Preguntas a mostrar por examen</p>
              <div className="form__form-group-field">
                <input type="number" name="questionsPerQuiz"
                  className="form-control"
                  id="inpuName" autoComplete="off"
                  onChange={(e) => onChangeInputs(e.target.name, e.target.value)}
                  value={quizInfo.questionsPerQuiz}
                />
              </div>
            </div>
          </Col>
          <Col xs="5" className="text-center">
            <div className="form__form-group">
              <p>Agregar tiempo</p>
              <Checkbox className="mt-2"
                onChange={onChangeCheckboxTime} checked={timeSelector}
              />
              <Tooltip title={timeIns} placement="rightTop">
                <i className="material-icons tooltip_quiz">info</i>
              </Tooltip>
            </div>

          </Col>
          <Col xs="7" className="align-items-center">
            <div className="form__form-group">
              <p className="mb-2">Formato Hora, min, seg</p>
              <TimePicker allowClear={false}
                onChange={onChangeTime}
                placeholder="Selecciona el tiempo"
                //defaultOpenValue={moment("00:00:00", "HH:mm")}
                disabled={!timeSelector}
                value={quizInfo.limitTime !== 0 ? moment(setTimeValue(quizInfo.limitTime), 'HH:mm:ss') : moment('00:00:00', 'HH:mm:ss')}
              />
            </div>
          </Col>
          <Col xs="3" className="">
            <div className="form__form-group">
              <p>Intentos</p>
              <Select onChange={handleChange} value={quizInfo.studentAttempts}>
                {attemps.map((number, index) => {
                  return <Option key={index} value={number} >{number}</Option>
                })}
              </Select>
            </div>
          </Col>
          <Col xs="5" className="text-center mt-2">
            <div className="mt-4">
              <label className="mr-2"> Estatus</label>
              <Switch checked={quizInfo.active} style={{ backgroundColor: quizInfo.active ? "#2ecc71" : "#e74c3c" }}
                onChange={onChangeSwitch}
              />
            </div>
          </Col>
          <Col xs="4" className="text-center">
            <div className="mt-4">
              <Checkbox className="mt-2" onChange={onChangeCheckbox} checked={quizInfo.random}>
                Random
            </Checkbox>
            </div>
          </Col>
        </Row>
      </div>
    </div >
  )
}
LeftBar.propTypes = {
  courseInfo: PropTypes.object.isRequired,
  playlistInfo: PropTypes.object.isRequired,
  quizInfo: PropTypes.object.isRequired,
  onChangeInputs: PropTypes.func.isRequired,
  validations: PropTypes.func.isRequired,
  setOpenModalVideos: PropTypes.func.isRequired,
  setOpenModalReorder: PropTypes.func.isRequired,
  saved: PropTypes.bool.isRequired,
  disable: PropTypes.bool.isRequired,
};
export default LeftBar