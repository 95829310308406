import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";
import statusTable from "../../Tables/statusTable";
import SortTable from "../../Tables/sortTable";

const TableCourse = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  //funcion para el cambio entre tabs
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //Funcion que enumera las filas de la lista
  const generaNum = (cell, row, rowIndex) => {
    if (row) {
      return rowIndex + 1;
    } else {
      return "";
    }
  };

  //genera el dot para activo o inactivo
  const disponible = (cell, row) => {
    return statusTable(row.active);
  };

  //maneja el click en la tabla
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log(row);
      props.saveSelectedCourse(row);
    },
  };

  //pinta el row previamente selecionado
  const rowColor = (cell, row) => {
    let rowStyle = { backgroundColor: "#CACFD2" };
    if (props.idSelectedCourse === cell._id) {
      return rowStyle;
    }
  };

  const headerSortingStyle = { backgroundColor: "#D6DBDF" };
  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: generaNum,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30px", textAlign: "center" };
      },
      headerSortingStyle,
      align: "center",
    },
    {
      dataField: "name",
      text: "Cursos",
      sort: true,
      headerAlign: "center",
      align: "center",
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerSortingStyle,
    },
    {
      dataField: "active",
      text: "Estatus",
      headerAlign: "center",
      align: "center",
      formatter: disponible,
    },
  ];

  return (
    <div className="tabs tabs--justify tabs--bordered-top">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggle("1")}
            >
              Media Superior
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggle("2")}
            >
              Superior
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-1">
          <TabPane tabId="1">
            <BootstrapTable
              keyField={"_id"}
              data={props.coursesMedia}
              columns={columns}
              rowEvents={rowEvents}
              rowStyle={rowColor}
              hover
              bordered={false}
            />
          </TabPane>
          <TabPane tabId="2">
            <BootstrapTable
              keyField={"_id"}
              data={props.coursesSuperior}
              columns={columns}
              rowEvents={rowEvents}
              rowStyle={rowColor}
              hover
              bordered={false}
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};
export default TableCourse;
