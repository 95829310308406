import React from 'react';
import Modal from "react-responsive-modal"
import { Row, Col, Button, Card, CardBody } from "reactstrap"
import ReactPlayer from 'react-player'
const ModalVideoView = (props) => {
    const onCloseModal = () => {
        props.closeModal()
    }
    return (
        <Modal
            open={props.value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>
            <Card className="view-video">
                <CardBody>
                    <Row className="d-flex flex-row">
                        <Col xs="8" className="mt-1">
                            <h4 className="bold-text">Titulo: {props.videoObj.title}</h4>
                        </Col>
                        <Col xs="4">
                            <Button type="button" className="btn-sm float-right"
                                onClick={() => onCloseModal()}
                            >
                                Salir
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3 descripcionVer">
                            <p>
                                <b>Descripción:</b> {props.videoObj.description}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <ReactPlayer url={props.videoObj.url} controls />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Modal>
    )
}
export default ModalVideoView