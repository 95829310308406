import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import ImageComponent from '../../../Material/components/ImageComponent';
import Can from '../../../../helpers/Secure/Can';

function ShowQuizVideos({ infoVideo, openModalVideoView, indexMap,
  questionId, addVideoQuestion, setEditVideo, setEditVideoInfo, openConfirmation }) {

  const editVideo = (data) => {
    setEditVideoInfo(data)
    setEditVideo(true)
  }

  return (
    <Container className="cotainer-single-video">
      <Row key={infoVideo._id}>
        <Col xs="4">
          <div className="container-quizz-video-img mt-1"
            onClick={() => openModalVideoView(infoVideo)}
          >
            {infoVideo.urlThumbnail !== undefined ?
              <ImageComponent className="image" urlThumbnail={infoVideo.urlThumbnail} /> :
              <img className="image_file_material" src={process.env.PUBLIC_URL + '/img/icons/processing.gif'} alt="file" />}
          </div>
        </Col>
        <Col xs="8">
          <Row>
            <Col xs="12" className={questionId !== "" ? "collapse" : ""}>
              <div className="float-right mt-1">
                <Can do="put" on="/playlists/">
                  <span className="mr-4"
                    onClick={() => editVideo(infoVideo)}
                  >
                    <i className="material-icons icon-edit-video-quiz">edit</i>
                  </span>
                </Can>
                <Can do="delete" on="/playlists/">
                  <span className="red_icon"
                    onClick={() => openConfirmation(infoVideo._id)}
                  >
                    <i className="material-icons">delete</i>
                  </span>
                </Can>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className={questionId !== "" ? "ponter-area-video  mt-4" : "ponter-area-video"}
                onClick={() => questionId !== "" ? addVideoQuestion(infoVideo) : ""}
              >
                <h3>{infoVideo.title}</h3>
                <p className="mt-1">Descripción: {infoVideo.description}</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
ShowQuizVideos.propTypes = {
  openConfirmation: PropTypes.func.isRequired,
  setEditVideoInfo: PropTypes.func.isRequired,
  addVideoQuestion: PropTypes.func.isRequired,
  openModalVideoView: PropTypes.func.isRequired,
  setEditVideo: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  infoVideo: PropTypes.object.isRequired,
  indexMap: PropTypes.number.isRequired,
};
export default ShowQuizVideos