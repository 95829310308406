import React, { Component } from 'react';
import { fetchAsync, urlRoles } from '../../../helpers/Globals/globals';
import { Select } from 'antd';
import 'antd/lib/select/style/index.css';
import 'antd/lib/form/style/index.css';
const { Option } = Select;

class RoleSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roles: [],
        }
    }

    componentDidMount() {
        this.getRoles()
    }

    getRoles = () => {
        fetchAsync(urlRoles, "", "GET", "")
            .then((result) => {
                if (result.success) {
                    this.setState({
                        roles: result.roles,
                    })
                }
            }).catch((reason) => {
                console.log(reason.message)
            })
    }

    render() {
        return (
            <Select showSearch value={this.props.idActual ? this.props.idActual : undefined}
                placeholder="Agregar un rol" style={{ width: "300px" }}
                onChange={this.props.handleSelectChange}>
                {!this.state.roles ? "" : this.state.roles.map((item) => {
                    //return <Option value={item._id+"/"+this.props.indice}>{item.name}</Option>
                    return <Option key={item._id} value={item._id + "/" + item.name}>{item.name}</Option>
                })}
            </Select>

        )
    }
}
export default RoleSelector;
