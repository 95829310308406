import React, { PureComponent } from 'react';
import LoadingComponent from "../../../helpers/LoadingComponent"
const icon = `${process.env.PUBLIC_URL}/img/pricing_cards/rocket.svg`;
class ImageComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            urlImage: undefined
        }

    }
    componentDidMount() {
        if (this.props.isFile) {
            this.getVideoUrlThumbnailFile(this.props.file, this.callbackVideo)
        } else if (this.props.urlThumbnail && this.props.urlThumbnail.length > 0) {
            this.setState({
                urlImage: this.props.urlThumbnail
            })
        } else {
            this.getVideoImage(this.props.url, 1, this.callbackVideo)
        }
    }

    componentDidUpdate(prevProps, prepState) {
        if (!this.props.isFile && prevProps.url && prevProps.url !== this.props.url) {
            this.setState({
                urlImage: undefined
            })
            this.getVideoImage(this.props.url, 1, this.callbackVideo)
        }
    }


    callbackVideo = (url) => {
        this.setState({
            urlImage: url
        })
    }

    getVideoImage = (path, secs, callback) => {
        let video = document.createElement('video');
        video.onloadedmetadata = function () {
            if ('function' === typeof secs) {
                secs = secs(this.duration);
            }
            this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
        };
        video.onseeked = function (e) {
            var canvas = document.createElement('canvas');
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            callback(canvas.toDataURL());
        };
        video.onerror = function (e) {
            callback("");
        };
        video.crossOrigin = "Anonymous";
        video.src = path;
    }


    getVideoUrlThumbnailFile = (file, callback) => {
        this.setState({
            urlImage: icon
        })
    }



    render() {
        if (this.state.urlImage) {
            return (
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    src={this.state.urlImage}
                    alt="video_image"
                />
            )
        } else {
            return (
                <div>
                    <LoadingComponent />
                </div>
            )
        }
    }
}
export default ImageComponent