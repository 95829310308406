import React from "react";
import { Col, Row } from "reactstrap";
import { Collapse, Tag } from "antd";

import Table from "./table";

import "antd/es/collapse/style/index.css";

const { Panel } = Collapse;

const CollapsePayments = ({
  studentPaymentsByCourse,
  afterSuccessDeletePayment,
}) => {
  const showName = (name, debt, price, totalDiscounts, totalPayments) => {
    if (debt) {
      let actualDebt = price - (totalDiscounts + totalPayments);

      return (
        <span>
          {name}
          <Tag className="ml-3" color="red">{`Adeuda:$ ${actualDebt}`}</Tag>
        </span>
      );
    } else {
      return <span>{name}</span>;
    }
  };

  return (
    <Collapse
      ghost={true}
      expandIconPosition="right"
      className="ant-collapse-custom-collapse"
    >
      {studentPaymentsByCourse.map(
        (
          { debt, name, payments, price, totalDiscounts, totalPayments },
          index
        ) => {
          return (
            <Panel
              key={index}
              header={showName(
                name,
                debt,
                price,
                totalDiscounts,
                totalPayments
              )}
              className="ant-collapse-custom-panel"
            >
              <Row className="mt-2 mb-3">
                <Col xs={12} sm={12} md={5} className="boxDataPayments mr-3">
                  <p className="">Precio $ {price}</p>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  className="boxDataPayments style_total"
                >
                  <p>Total abonado $ {totalPayments + totalDiscounts}</p>
                </Col>
              </Row>
              <Table
                dataTable={payments}
                afterSuccessDeletePayment={afterSuccessDeletePayment}
              />
            </Panel>
          );
        }
      )}
    </Collapse>
  );
};
export default CollapsePayments;
