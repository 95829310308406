import React from 'react';
import { Link } from 'react-router-dom';

const NotFound404 = () => (
  <div className="not-found">
    <div className="not-found__content">
      <h3 className="not-found__info">Ooops! La pagina que buscas no éxiste :(</h3>
      <Link className="btn btn-primary" to="/">Regresa al inicio</Link>
    </div>
  </div>
);

export default NotFound404;