import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import Highlighter from 'react-highlight-words';
import ViewportContext from '../../../context/Viewport/viewportContext';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import 'antd/es/pagination/style/index.css'
import 'antd/es/table/style/index.css'

function TableEmployess({ showSidebar, allEmployees,
  setIdActualEmployee, setActualEmployee, actualEmployee }) {

  //instance of the viewport context
  const viewportContext = useContext(ViewportContext);
  const { width } = viewportContext
  const isMobile = width < 600;

  const [pagination, setPagination] = useState(1)

  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchText, setSearchText] = useState('')

  const rowEvents = (row) => {
    showSidebar()
    setIdActualEmployee(row._id)
    setActualEmployee(row)
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (pagination) {
      setPagination(pagination.current)
    }
  }

  const getColumnSearchProps = (dataIndex, titileText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar ${titileText}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ fontSize: filtered && "20px", color: filtered && '#3498DB' }} />,
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
    render: text => {
      return searchedColumn === dataIndex && text !== undefined ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#F9E79F', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        )
    },
  });


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
    setSearchedColumn('')
  };

  const alphabeticSort = (a, b) => {
    return a.localeCompare(b);
  }

  const columns = [
    {
      title: '#',
      dataIndex: '',
      render: (cell, row, index) => <p>{((pagination - 1) * 50) + (index + 1)}</p>,
      align: 'center',
      responsive: ['lg'],
    },
    {
      dataIndex: 'name',
      title: 'Nombre',
      sort: true,
      align: 'center',
      ...getColumnSearchProps('name', 'Nombre'),
      sorter: (a, b) => alphabeticSort(a.name, b.name),
      fixed: 'left',
    },
    {
      dataIndex: 'lastName',
      title: 'A Paterno',
      align: 'center',
      ...getColumnSearchProps('lastName', 'Apellido paterno'),
      // sorter: (a, b) => alphabeticSort(a.name, b.name),
    },
    {
      dataIndex: 'secondLastName',
      title: 'A Materno',
      align: 'center',
      responsive: ['lg'],
    },
    {
      dataIndex: 'email',
      title: 'Correo',
      align: 'center',
      ...getColumnSearchProps('email', 'Correo')
    },
    {
      dataIndex: 'phoneNumber',
      title: 'Teléfono',
      align: 'center',
      ...getColumnSearchProps('phoneNumber', 'Teléfono')
      // width: 400,
    },
  ];

  return (
    <Table
      rowKey="_id"
      columns={columns}
      onChange={onChange}
      rowSelection={{
        selectedRowKeys: [actualEmployee._id],
        hideSelectAll: true,
        columnWidth: "0px",
        renderCell: () => {
          return
        }
      }}
      dataSource={allEmployees}
      onRow={(record, rowIndex) => {
        return {
          onClick: event => { rowEvents(record) },
        };
      }}
      scroll={{
        x: true,
      }}
      pagination={{
        total: allEmployees.length,
        showTotal: (total, range) => `${range[0]} al ${range[1]} de ${total} empleados`,
        position: ["topRight", "bottomRight"],
        defaultPageSize: 50,
        pageSizeOptions: ['80', '120', '150'],
        locale: {
          items_per_page: '/ Página',
        },
        showSizeChanger: false,

      }}
      locale={{ emptyText: 'No hay coincidencias para tu busqueda.' }}
      size={isMobile ? "small" : "default"}
    />
  );
}
export default TableEmployess;
