import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import LoadingComponent from "../../../../helpers/LoadingComponent";
import SortTable from '../../../../helpers/Tables/sortTable';
import EmptyCard from '../../../../helpers/Empty/EmptyComponent'
import PropTypes from 'prop-types';

const TableLink = ({ dataTable, addContent, getNewPlaylist, emptyMaterial }) => {

  const addBUtton = (cell, row) => {
    return <i className="material-icons" onClick={() => addContent(row)} >queue</i>
  }

  const watchMaterial = (cell, row) => {
    return <i className="material-icons" onClick={() => getNewPlaylist(row)}>visibility</i>
  }

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: 'name',
      text: 'Nombre',
      sort: true,
      headerAlign: 'center',
      align: 'center',
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />
      },
    },
    {
      dataField: 'active',
      text: 'Ver carpetas',
      headerAlign: 'center',
      align: 'center',
      formatter: watchMaterial,
    },
    {
      dataField: '_id',
      text: 'Enlazar',
      headerAlign: 'center',
      align: 'center',
      formatter: addBUtton,
    },
  ];

  // const defaultSorted = [{
  //   dataField: 'name',
  //   order: 'asc'
  // }];


  return (
    <ToolkitProvider
      keyField="_id"
      data={dataTable}
      columns={columns}
      search
    >
      {
        props => (
          <>
            <SearchBar {...props.searchProps}
              className="custome-search-field  busqueda"
              style={{
                color: '#2980B9',
                backgroundColor: "#ecf0f1",

              }}
              placeholder="Buscar un curso"
            />
            <div className="table_div">
              <BootstrapTable
                striped
                hover
                // defaultSorted={defaultSorted}
                bordered={false}
                {...props.baseProps}
                noDataIndication={emptyMaterial ? <EmptyCard message="No hay material que enlazar en la carpeta" /> : <LoadingComponent />}
              />
            </div>
          </>
        )
      }
    </ToolkitProvider>
  );
}
TableLink.propTypes = {
  dataTable: PropTypes.array.isRequired,
  addContent: PropTypes.func.isRequired,
  getNewPlaylist: PropTypes.func.isRequired,
  emptyMaterial: PropTypes.bool.isRequired,
};
export default TableLink;
