import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-responsive-modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Col, Container, Row, Button } from 'reactstrap';
import { MathFieldComponent } from 'react-mathlive'
import { Tooltip, Popconfirm } from 'antd';
import 'antd/lib/tooltip/style/index.css';
import { reorderquestions } from '../../../../helpers/Fichas/constants';

function ReorderQuestions({ value, close, quiz, saveReorder, reorderQuestions }) {
  const [questionsArray, setQuestionsArray] = useState([])
  const [beforeOrderedArray, setBeforeOrderedArray] = useState([])

  useEffect(() => {
    let newArrayQustions = JSON.parse(JSON.stringify(quiz));
    setQuestionsArray(newArrayQustions)
    setBeforeOrderedArray(newArrayQustions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseModal = () => {
    close(false)
    reorderQuestions(beforeOrderedArray)
  }

  const onDragQuestion = (result) => {
    if (!result.destination) {
      return;
    }
    //reorder logic array
    let items = reorder(
      questionsArray,
      result.source.index,
      result.destination.index
    );
    //apply manual changes
    for (let i = 0; i < items.length; i++) {
      items[i].order = i
    }

    //guardamos en el estado el nuevo ordenamiento
    setQuestionsArray(items)
    reorderQuestions(items)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const showQuestionsTypes = (question, index) => {
    switch (question.questionType) {
      case 2:
        return (
          <div className="container_questions_reorder">
            <Row>
              <Col xs="8" className="text-center">
                <img className="" src={question.questionImage.url} alt="image_question" />
              </Col>
              <Col xs="4" className="colum_question_number">
                <p>Pregunta {index + 1} </p>
              </Col>
            </Row>
          </div>
        )
      case 3:
        return (
          <div className="container_questions_reorder">
            <Row>
              <Col xs="8">
                <div className="container_formula_text">
                  <MathFieldComponent
                    latex={question.questionFormula}
                    mathFieldConfig={{
                      defaultMode: "math",
                      readOnly: true
                    }} />
                </div>
              </Col>
              <Col xs="4" className="colum_question_number">
                <p>Pregunta {index + 1} </p>
              </Col>
            </Row>
          </div>
        )
      default:
        return (
          <div className="container_questions_reorder">
            <Row>
              <Col xs="8">
                <div className="container_formula_text">
                  <span>{question.question}</span>
                </div>
              </Col>
              <Col xs="4" className="colum_question_number">
                <p>Pregunta {index + 1} </p>
              </Col>
            </Row>
          </div>
        )
    }
  }

  return (
    <Modal
      open={value}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}>
      <Container>
        <Row className="modal_reorder_questions">
          <Col xs="12">
            <h3 className="float-left">Reordenar preguntas</h3>
            <Tooltip title={reorderquestions} placement="bottom">
              <i className="material-icons tooltip_title">info</i>
            </Tooltip>
            <Popconfirm title="Recuerda que al seleccionar salir no se guardarán los cambios de reordenamiento que hayas hecho."
              okText="Si"
              cancelText="No"
              icon={<i className="material-icons red_icon">error</i>}
              placement="topRight"
              onConfirm={() => onCloseModal()}>
              <Button size="sm" className="float-right">
                <p>Salir</p>
              </Button>
            </Popconfirm>
            <Button color="success" size="sm" className="float-right mr-3" onClick={() => saveReorder()}>
              <p>Guardar</p>
            </Button>
          </Col>
          <Col xs="12" className="column_drag_drop">
            <DragDropContext
              onDragEnd={onDragQuestion}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {
                      questionsArray.map((question, index) => {
                        return (
                          <>
                            <Draggable key={question._id}
                              draggableId={question._id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={question._id}>
                                  {showQuestionsTypes(question, index)}
                                </div>
                              )}
                            </Draggable>
                          </>
                        )
                      })
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
ReorderQuestions.propTypes = {
  reorderQuestions: PropTypes.func.isRequired,
  saveReorder: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  quiz: PropTypes.array.isRequired,
  value: PropTypes.bool.isRequired,
};
export default ReorderQuestions