/** Lib imports */
import React, { useEffect, useContext, useState, useCallback } from "react";
import moment from "moment";
import { Popconfirm } from "antd";
import { Col, Row, Button, Container } from "reactstrap";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "moment/locale/es";

/**
 * Context
 */

import { GroupsContext } from "context/groups";

/**
 * Helpers Imports
 */
import {
  showMessageLoading,
  showNotificationUpdate,
  showMessageWarning,
} from "helpers/MessageAndNotificationUtils";
import DateDayMonth from "helpers/Fichas/dateDayMonth";
import { newSchedules } from "helpers/Globals/funciones";
import LoadingComponent from "helpers/LoadingComponent";
import { showStatus } from "helpers/Fichas/functions";
import Can from "helpers/Secure/Can";
import LoadingSkeleton from "helpers/LoadingSkeleton";
import SmsAndMailMessages from "helpers/SmsAndMailComponent/SmsAndMailMessages";
import TabsModal from "../../../Students/components/tabsStudents/TabsModal";
/**
 * Local components
 */
import AttendanceSidebar from "./components/attendanceSidebar";
import GroupStudents from "./components/modalStudentsDetail";
import EditGroup from "./components/editGroup";
import MasiveChangesStudents from "./components/masiveChanges";

import styles from "./index.module.scss";
import "antd/lib/popover/style/index.css";
// import 'antd/es/drawer/style/index.css'

let closeLoading = "";
let showStudentsGroup = "";
let superAd = "";
const FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const FILE_EXT = ".xlsx";
const dateFormat = "DD/MM/YYYY";
const dayWeek = moment().isoWeekday(); //returns 1-7 where 1 is Monday and 7 is Sunday

function SidebarGroup(props) {
  const groupsContext = useContext(GroupsContext);
  const {
    setAndGetSidebarData,
    actualGroup,
    loadingSkeleton,
    onDeleteStudent,
    setAttendanceByStudent,
    updateAttendance,
    forwardEnrollmentTicket,
  } = groupsContext;

  const [openMenu, setOpenMenu] = useState(false);

  const [modalEdit, setModalEdit] = useState(false);
  const [modalGroupStudents, setModalGroupStudents] = useState(false);
  const [masiveChanges, setMasiveChanges] = useState(false);
  const [modalSmsMail, setModalSmsMail] = useState(false);

  const [studentInfo, setStudentInfo] = useState("");
  const [visiblePop1, setVisiblePop1] = useState(false);
  const [showTabsStudent, setShowTabsStudent] = useState(false);

  useEffect(() => {
    let allows = JSON.parse(localStorage["allows"]);
    showStudentsGroup = allows.findIndex((x) => x.subject === "/students/");
    superAd = allows.findIndex((x) => x.subject === "all");
    return () => {
      console.log("UNMOUNT GROUPS");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAndGetSidebarData(actualGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualGroup._id]);

  const exportToCSV = (csvData, fileName) => {
    if (csvData === undefined || csvData.length <= 0) {
      showMessageWarning("Aún no hay alumnos de platica disponibles", 5);
    } else {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: FILE_TYPE });
      FileSaver.saveAs(data, fileName + FILE_EXT);
    }
  };

  const sendTicketAgain = async () => {
    closeLoading = showMessageLoading("Reenviando comprobante", 0);
    let key = "sending";
    let totales = actualGroup.studentList.length;
    for (let i = 0; actualGroup.studentList.length > i; i++) {
      try {
        showNotificationUpdate(
          key,
          "Reenviando comprobantes",
          i + 1 + " de " + totales,
          0
        );
        await forwardEnrollmentTicket(actualGroup.studentList[i]._id);
      } catch (e) {
        console.log(e);
      }
    }
    closeLoading();
    showNotificationUpdate(
      key,
      "Comprobantes reenviados correctamente",
      totales,
      0
    );
  };

  const reloadMassiveChanges = () => {
    setMasiveChanges(false);
  };

  //agrega la informacion del student al dar click a la lista
  const onClickList = useCallback(
    (studentData) => {
      setStudentInfo(studentData);
      setShowTabsStudent(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [actualGroup._id]
  );

  const updateList = useCallback(() => {
    setAndGetSidebarData(actualGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualGroup._id]);

  const deleteStudent = useCallback(
    (idStudent) => {
      onDeleteStudent(idStudent, actualGroup._id);
      setShowTabsStudent(false);
      setModalGroupStudents(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [actualGroup._id]
  );

  const {
    active,
    startDate,
    endDate,
    classRoom,
    course,
    teacher,
    name,
    schedules,
  } = actualGroup;
  const sede = classRoom && classRoom.location ? classRoom.location.name : "";
  const classroom = classRoom ? classRoom.name : "";
  const courseName = course ? course.name : "";
  const showDateAttendance =
    actualGroup.schedules !== undefined
      ? actualGroup.schedules.find((schedule) => schedule.day === dayWeek - 1)
      : "";

  return (
    <Container className={styles.container}>
      <Row className="mb-1">
        <Col xs={12}>
          <h3>{name}</h3>
          <section className={styles.status_button_box}>
            {showStatus(active)}

            <Can do="put" on="/groups/">
              <Button
                className="icon square  float-right"
                size="sm"
                type="button"
                color="success"
                outline
                onClick={() => setModalEdit(true)}
              >
                <p>
                  <i className="material-icons">edit</i>Editar
                </p>
              </Button>
            </Can>
          </section>

          <section className={styles.info_container}>
            <h4>Duración:</h4>
            <p>{DateDayMonth(startDate, endDate)}</p>

            <h4>Curso:</h4>
            <p> {loadingSkeleton ? <LoadingSkeleton /> : courseName}</p>

            <h4>Dia(s) y Horarios:</h4>
            <p>
              {loadingSkeleton ? (
                <LoadingSkeleton />
              ) : schedules !== undefined ? (
                newSchedules(schedules)
              ) : (
                "Sin asignar"
              )}
            </p>

            <h4>Sede:</h4>
            <p>{sede !== "" ? sede : "Sin asignar"}</p>

            <h4>Salón:</h4>
            <p>
              {loadingSkeleton ? (
                <LoadingSkeleton />
              ) : classroom !== "" ? (
                classroom
              ) : (
                "Sin asignar"
              )}
            </p>

            <h4>Profesor:</h4>
            <p>
              {loadingSkeleton ? (
                <LoadingSkeleton />
              ) : teacher ? (
                `${teacher.name} ${teacher.lastName} ${teacher.secondLastName}`
              ) : (
                "Sin asignar"
              )}
            </p>
          </section>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12}>
          <Can do="get" on="/groups/?/students">
            <Button
              disabled={loadingSkeleton}
              className="float-left"
              size="lg"
              type="button"
              color="success"
              outline
              onClick={() => setModalGroupStudents(true)}
            >
              <p>
                <i className="material-icons">school</i>Alumnos
              </p>
            </Button>
          </Can>

          <section className={styles.option_box}>
            <Popconfirm
              title="¿Estás seguro de reenviar el comprobante a todos los alumnos del grupo?"
              okText="Si"
              cancelText="No"
              trigger="click"
              placement="rightTop"
              icon={<i className="material-icons">warning</i>}
              visible={visiblePop1}
              onConfirm={() => sendTicketAgain()}
              onCancel={() => setVisiblePop1(false)}
            >
              <ButtonDropdown
                isOpen={openMenu}
                toggle={() => setOpenMenu(!openMenu)}
                className="btn-dropdown-sidebar"
              >
                <DropdownToggle color="success">
                  <p>
                    <i className="material-icons">info</i>Opciones
                    <i className="material-icons">keyboard_arrow_down</i>
                  </p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setModalSmsMail(true)}>
                    Mandar Notificación
                  </DropdownItem>
                  <DropdownItem onClick={() => setMasiveChanges(true)}>
                    Cambiar alumnos (Masivo)
                  </DropdownItem>
                  <DropdownItem onClick={() => setVisiblePop1(true)}>
                    Reenviar comprobantes de inscripción
                  </DropdownItem>
                  <Can do="get" on="/students/show/test/">
                    <DropdownItem
                      onClick={() =>
                        exportToCSV(actualGroup.studentList, actualGroup.name)
                      }
                    >
                      Generar excel
                    </DropdownItem>
                  </Can>
                </DropdownMenu>
              </ButtonDropdown>
            </Popconfirm>
          </section>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <section className={styles.students_list_info}>
            <h4>
              Alumnos inscritos:
              {actualGroup.studentList ? actualGroup.studentList.length : 0}
            </h4>

            <h4>
              Asistencia {moment.utc(new Date()).local().format(dateFormat)}
            </h4>
          </section>

          {showStudentsGroup !== -1 || superAd !== -1 ? (
            loadingSkeleton ? (
              <LoadingComponent />
            ) : (
              <AttendanceSidebar
                actualGroupStudents={actualGroup.studentList}
                onClickList={onClickList}
                setAttendanceByStudent={setAttendanceByStudent}
                group={actualGroup}
                updateAttendance={updateAttendance}
              />
            )
          ) : (
            ""
          )}
        </Col>
      </Row>

      {modalEdit && (
        <EditGroup
          valueModal={modalEdit}
          close={setModalEdit}
          closeTab={props.closeTab}
        />
      )}

      {modalGroupStudents && (
        <GroupStudents
          valueModal={modalGroupStudents}
          close={setModalGroupStudents}
          updateList={updateList}
          onClickList={onClickList}
        />
      )}

      {showTabsStudent && (
        <TabsModal
          valueModal={showTabsStudent}
          close={setShowTabsStudent}
          studentInfo={studentInfo}
          updateStudentContext={updateList}
          afterDeleteStudent={deleteStudent}
          addPaymentsStudent={() => ""}
        />
      )}

      {masiveChanges && (
        <MasiveChangesStudents
          closeModal={reloadMassiveChanges}
          valueModal={masiveChanges}
          grupo={actualGroup}
        />
      )}

      {modalSmsMail && (
        <SmsAndMailMessages
          value={modalSmsMail}
          close={setModalSmsMail}
          type={"groups"}
          infoType={actualGroup}
        />
      )}
    </Container>
  );
}
export default SidebarGroup;
