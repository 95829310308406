import {
  SEND_EMAIL_STATUTS,
  SEND_SMS_STATUTS,
  SET_END_DATE_FILTER,
  SET_START_DATE_FILTER,
  SET_STATUTS_GROUPS_FILTER,
  SET_CHECKBOX_SHOW_ALL,
  SET_TAB,
  SET_TEXT_EMAIL,
  SET_TEXT_SMS,
  SET_SUBJECT_EMAIL,
  PAYMENT_FILTER_GROUPS,
  GET_COURSE_GROUPS,
  SET_STRING_URL,
  SET_LOADING,
} from "../context/types";

const Reducer = (state, action) => {
  switch (action.type) {
    case SEND_EMAIL_STATUTS:
      return {
        ...state,
        sendEmail: action.payload,
      };
    case SEND_SMS_STATUTS:
      return {
        ...state,
        sendSms: action.payload,
      };
    case SET_STATUTS_GROUPS_FILTER:
      return {
        ...state,
        statusGroups: action.payload,
      };
    case SET_CHECKBOX_SHOW_ALL:
      return {
        ...state,
        checkboxAllGroups: action.payload,
      };

    case SET_TAB:
      return {
        ...state,
        actualTab: action.payload,
      };
    case SET_TEXT_EMAIL:
      return {
        ...state,
        textEmail: action.payload,
      };
    case SET_TEXT_SMS:
      return {
        ...state,
        textSms: action.payload,
      };
    case SET_SUBJECT_EMAIL:
      return {
        ...state,
        subjectEmail: action.payload,
      };
    case PAYMENT_FILTER_GROUPS:
      return {
        ...state,
        paymentFilter: action.payload,
      };
    case GET_COURSE_GROUPS:
      return {
        ...state,
        groupsList: action.payload,
        emptyGroupsList: action.payload.length > 0 ? false : true,
      };

    case SET_STRING_URL:
      return {
        ...state,
        stringFilterUrl: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
