import React, { useState, useEffect, useContext } from "react";
import { Select, Checkbox, Popconfirm, Button } from "antd";
import { Col, Row } from "reactstrap";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  customFilter,
  Comparator,
  FILTER_TYPES,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import MultiSelectFilter from "./multiSelectFilter";
import { showMessageWarning } from "helpers/MessageAndNotificationUtils";
import CommentsEditor from "helpers/CommentsEditor";
import EmptyComponent from "helpers/Empty/EmptyComponent";
import Can from "helpers/Secure/Can";
import SortTable from "helpers/Tables/sortTable";
import { TalksContext } from "context/TalksContext";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  fetchAsync,
  urlUsersTalks,
} from "../../../../../../../helpers/Globals/globals";

import styles from "../index.module.scss";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "antd/es/dropdown/style/index.css";
import "antd/es/table/style/index.css";
import "antd/lib/popover/style/index.css";
import "scss/component/motion.scss";

const { Option } = Select;
const { SearchBar } = Search;
const dicStatus = [
  "Buzón de voz 1",
  "Buzón de voz 2",
  "Buzón de voz 3",
  "Buzón de voz 4",
  "Buzón de voz 5",
  "Colgo llamada",
  "Reagendar fecha",
  "Volver a llamar",
  "Número inexistente",
  "Número equivocado",
];

const alphabeticSort = (a, b) => {
  return a.localeCompare(b);
};

const dateSort = (a, b) => {
  return new Date(b.date) - new Date(a.date);
};

const compareCalledBy = (a, b) => {
  if (a.calledBy && a.calledBy.userId)
    if (b.calledBy && b.calledBy.userId)
      return a.calledBy.userId.localeCompare(b.calledBy.userId);
    else return -1;
  else return 1;
};

const compareHasComment = (a, b) => {
  if (a.comments && a.comments.length > 0)
    if (b.comments && b.comments.length > 0)
      return a.comments.length - b.comments.length;
    else return -1;
  else return 1;
};

const DEFAULT_PAGE_SIZE = 20;

const TableAlumnos = ({ openStudentCard, talk }) => {
  const talksContext = useContext(TalksContext);
  const {
    state,
    updateCalledBy,
    updateStatus,
    updateAttendance,
    updateCommentsCallback,
    deleteUserTalk,
  } = talksContext;
  const { talkFullInfo } = state;
  const [collapsableRows, setCollapsableRows] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [paginationSize, setPaginationSize] = useState(20);

  useEffect(() => {
    setExpandedItems(1, DEFAULT_PAGE_SIZE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (currentPagination, sizePagination) => {
    setExpandedItems(currentPagination, sizePagination);
    setPagination(currentPagination);
    setPaginationSize(sizePagination);
  };

  const setExpandedItems = (current, size) => {
    // const start = (current - 1) * size;
    // const end = (current * size) < talkFullInfo.studentList.length ? (current * size) : talkFullInfo.studentList.length;
    const prevcollapsableRows = [];
    for (let i = 0; i < talkFullInfo.studentList.length; i++) {
      prevcollapsableRows.push(talkFullInfo.studentList[i]._id);
    }
    setCollapsableRows(prevcollapsableRows);
  };

  const renderContentStatus = (value, row, index) => {
    return (
      <Can do="put" on="/talks/status/student/*">
        <Select
          style={{ width: 220 }}
          placeholder="Seleccionar status"
          disabled={
            row.isUnavailable ||
            (row.calledBy && Object.entries(row.calledBy).length > 0)
              ? true
              : false
          }
          value={value}
          onChange={(value) => updateStatus(row._id, value)}
        >
          <Option value={1} key={0}>
            {dicStatus[0]}
          </Option>
          <Option value={2} key={1}>
            {dicStatus[1]}
          </Option>
          <Option value={3} key={2}>
            {dicStatus[2]}
          </Option>
          <Option value={4} key={3}>
            {dicStatus[3]}
          </Option>
          <Option value={5} key={4}>
            {dicStatus[4]}
          </Option>
          <Option value={6} key={5}>
            {dicStatus[5]}
          </Option>
          <Option value={7} key={6}>
            {dicStatus[6]}
          </Option>
          <Option value={8} key={7}>
            {dicStatus[7]}
          </Option>
          <Option value={9} key={8}>
            {dicStatus[8]}
          </Option>
          <Option value={10} key={9}>
            {dicStatus[9]}
          </Option>
        </Select>
      </Can>
    );
  };

  const renderContentCalledBy = (cell, row, index) => {
    let date;
    if (cell !== undefined && cell.date) {
      date = moment(new Date(cell.date)).format("DD/MM/YYYY HH:mm A");
    }
    return (
      <Can do="put" on="/talks/call/student/*">
        <Col className="table_usersTalks_centerText">
          <Checkbox
            disabled={row.isUnavailable}
            onChange={() => {
              updateCalledBy(row._id);
            }}
            checked={cell && cell.date}
          ></Checkbox>
          {cell && cell.user ? (
            <p>{cell.user.name + " " + cell.user.lastName}</p>
          ) : (
            ""
          )}
          {date ? <p>{date}</p> : ""}
        </Col>
      </Can>
    );
  };

  const renderContentAttendance = (cell, row, index) => {
    return (
      <Can do="put" on="/talks/attendance/student/*">
        <Col className="table_usersTalks_centerText">
          <Checkbox
            disabled={row.isUnavailable}
            onChange={(value) => {
              updateAttendance(row._id, value.target.checked);
            }}
            checked={cell ? true : false}
          ></Checkbox>
        </Col>
      </Can>
    );
  };

  const renderContentDelete = (cell, row, index) => {
    return (
      <Can do="delete" on="/usersTalks/*">
        {!row.isUnavailable ? (
          <Popconfirm
            title="¿Estàs seguro de borrar el registo?"
            okText="Si"
            cancelText="No"
            icon={<i className="material-icons red_icon">warning</i>}
            placement="topLeft"
            onConfirm={() => deleteUserTalk(row._id)}
          >
            <p>
              <i className="material-icons red_icon">delete</i>
            </p>
          </Popconfirm>
        ) : null}
      </Can>
    );
  };

  const onExpandTable = (row) => {
    return (
      <Row className="ml-1 mt-1">
        <Col xs={12} sm={12} md={12}>
          <CommentsEditor
            key={row._id + row.comments.length}
            comentarios={row.comments}
            idStudent={row._id}
            idTalk={talkFullInfo._id}
            getCommentsByGroup={() => {}}
            updateAfterAddComment={(comment) => {
              updateCommentsCallback("add", row._id, comment);
            }}
            updateAfterEditComment={(comment) => {
              updateCommentsCallback("update", row._id, comment);
            }}
            updateAfterDeleteComment={(id) => {
              updateCommentsCallback("delete", row._id, { _id: id });
            }}
          />
        </Col>
      </Row>
    );
  };

  const showPhonesNumber = (cell, row) => {
    return row ? `${row.phoneNumber} / ${row.secondPhoneNumber}` : "";
  };

  const onSendTicket = (idStudent) => {
    let body = {
      usersTalk: [idStudent],
    };
    fetchAsync(
      urlUsersTalks + "resend/" + talk._id,
      JSON.stringify(body),
      "POST"
    )
      .then((result) => {
        if (result.success) {
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };
  const onClickForwardTicket = (cell, row) => {
    return (
      <Popconfirm
        title="¿Deseas reenviar el comprobante"
        okText="Si"
        cancelText="No"
        onConfirm={() => onSendTicket(row._id)}
      >
        <Button type="primary"> Reenviar</Button>
      </Popconfirm>
    );
  };

  const colEvents = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      if (!row.isUnavailable) {
        openStudentCard(row);
      } else {
        showMessageWarning("El alumnos se ha movido a otra platica", 4);
      }
    },
    // onMouseEnter: (e, row, rowIndex) => {
    //   console.log(`enter on row with index: ${rowIndex}`);
    // }
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2) {
      style.backgroundColor = "#fafbfe";
    }
    style.cursor = "pointer";
    style.color = "#646777";

    if (row.isUnavailable) {
      style.filter = "blur(3px)";
      style.cursor = "not-allowed";
    }
    return style;
  };

  const cellStyle = (cell, row, rowIndex, colIndex) => {
    return {
      textAlign: "center",
      verticalAlign: "middle",
      borderTop: "1px solid transparent",
    };
  };

  const columns = [
    {
      text: "#",
      dataField: "comments",
      sort: true,
      sorter: compareHasComment,
      formatter: (cell, row, index) => (
        <p>{(pagination - 1) * paginationSize + (index + 1)}</p>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "60px", textAlign: "center" };
      },
      style: cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: colEvents,
      searchable: false,
    },
    {
      text: "Fecha",
      dataField: "registerDate",
      sort: true,
      sorter: dateSort,
      formatter: (cell, row, index) => {
        return moment(cell).format("DD-MM-YYYY HH:mm A");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
      style: cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: colEvents,
      searchable: false,
    },
    {
      text: "Nombre",
      dataField: "name",
      sort: true,
      sorter: (a, b) => alphabeticSort(a.name, b.name),
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: colEvents,
    },
    {
      text: "Apellido Paterno",
      dataField: "lastName",
      sort: true,
      sorter: (a, b) => alphabeticSort(a.lastName, b.lastName),
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: colEvents,
    },
    {
      text: "Apellido Materno",
      dataField: "secondLastName",
      sort: true,
      sorter: (a, b) => alphabeticSort(a.secondLastName, b.secondLastName),
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: colEvents,
    },
    {
      text: "Telefono",
      dataField: "phoneNumber",
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      events: colEvents,
      formatter: showPhonesNumber,
    },
    {
      text: "Reenviar Comprobante",
      dataField: "secondPhoneNumber",
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      formatter: onClickForwardTicket,
    },
    {
      text: "Estatus",
      dataField: "status.status",
      formatter: renderContentStatus,
      headerStyle: (colum, colIndex) => {
        return { width: "220px", textAlign: "center" };
      },
      searchable: false,
      style: cellStyle,
      filter: customFilter({
        type: FILTER_TYPES.MULTISELECT,
        comparator: Comparator.EQ,
      }),
      filterRenderer: (onFilter, column) => (
        <MultiSelectFilter onFilter={onFilter} column={column} />
      ),
    },
    {
      text: "Confirmado",
      dataField: "calledBy",
      sort: true,
      sorter: compareCalledBy,
      formatter: renderContentCalledBy,
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      searchable: false,
    },
    {
      text: "Asistencia",
      dataField: "attendance",
      sort: true,
      sorter: (a, b) => (a.attendance ? -1 : b.attendance ? 1 : 0),
      formatter: renderContentAttendance,
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      searchable: false,
    },
    {
      text: "Eliminar",
      dataField: "",
      formatter: renderContentDelete,
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
      style: cellStyle,
      searchable: false,
    },
  ];
  return (
    <ToolkitProvider
      keyField={"_id"}
      data={talkFullInfo.studentList}
      columns={columns}
      search
    >
      {(props) => (
        <div>
          <SearchBar
            className=""
            placeholder="Buscar alumno"
            {...props.searchProps}
            delay="1000"
          />
          <BootstrapTable
            bootstrap4
            classes={styles.tableTalk}
            bordered={false}
            filter={filterFactory()}
            expandRow={{
              renderer: (row) => onExpandTable(row),
              expanded: collapsableRows,
              expandByColumnOnly: true,
              onExpand: (row, isExpand, rowIndex, e) => {
                console.log("expandable");
              },
            }}
            rowStyle={rowStyle}
            noDataIndication={
              <EmptyComponent
                message={"No hay alumnos de platica inscritos en este momento"}
              />
            }
            pagination={paginationFactory({
              onSizePerPageChange: (sizePerPage, page) => {
                onChange(page, sizePerPage);
              },
              onPageChange: (page, sizePerPage) => {
                onChange(page, sizePerPage);
              },
              sizePerPage: DEFAULT_PAGE_SIZE,
            })}
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default TableAlumnos;
