import React from 'react';
import CircleIcon from 'mdi-react/CircleIcon';


const statusTable = (status) => {
  if (status) {
    return <CircleIcon style={{ color: "#2ECC71" }}></CircleIcon>
  } else {
    return <CircleIcon style={{ color: "#E74C3C" }}></CircleIcon>
  }
}

export default statusTable