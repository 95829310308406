import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import { month } from '../Fichas/constants';

const DateDayMonth = (startDate = '', endDate = '') => {

  if (startDate !== '' && endDate !== '') {
    let start = moment(startDate).format("DD/MM/YYYY")
    let end = moment(endDate).format("DD/MM/YYYY")
    let dataStart = start.split('/')
    let dataEnd = end.split('/')
    return `${dataStart[0]} de ${month[dataStart[1] - 1]} - ${dataEnd[0]} de ${month[dataEnd[1] - 1]} ${dataEnd[2]}`
  } else {
    return 'No disponible'
  }
}

DateDayMonth.propsTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
}
export default DateDayMonth;