import React, { useState, useContext } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import StudentsContext from "../../../../context/students/StudentsContext";
import GroupsTab from "./components/groupsTab";
import PaymentsTab from "./components/paymentsTab";
import Folios from "./components/folios";
import LoadingComponent from "../../../../helpers/LoadingComponent";

const TabsSidebar = () => {
  const [activeTab, setActiveTab] = useState("1");

  //creamos el context
  const studentsContext = useContext(StudentsContext);
  const {
    actualStudentGroups,
    actualStudent,
    loadingGroupTabs,
    resendRegisterTicket,
    deleteRegisterGroup,
    actualStudentPayments,
    resendPaymentTicket,
    setFolio,
    editFoliio,
    deleteFolio,
  } = studentsContext;

  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                setActiveTab("1");
              }}
            >
              Cursos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                setActiveTab("2");
              }}
            >
              Financieros
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                setActiveTab("3");
              }}
            >
              Folios
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-1">
          <TabPane tabId="1">
            {loadingGroupTabs ? (
              <LoadingComponent />
            ) : (
              <GroupsTab
                actualStudentGroups={actualStudentGroups}
                studentSubCourses={
                  actualStudent.hasOwnProperty("comipemsOrder")
                    ? actualStudent.comipemsOrder
                    : ""
                }
                resendRegisterTicket={resendRegisterTicket}
                deleteRegisterGroup={deleteRegisterGroup}
              />
            )}
          </TabPane>
          <TabPane tabId="2">
            {loadingGroupTabs ? (
              <LoadingComponent />
            ) : (
              <PaymentsTab
                dataPyments={actualStudentPayments}
                resendPaymentTicket={resendPaymentTicket}
              />
            )}
          </TabPane>
          <TabPane tabId="3">
            {loadingGroupTabs ? (
              <LoadingComponent />
            ) : (
              <Folios
                actualStudent={actualStudent}
                setFolio={setFolio}
                editFoliio={editFoliio}
                deleteFolio={deleteFolio}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};
export default TabsSidebar;
