import {
  CLASSROOMS_LOCATION,
  ACTUAL_CLASSROOM,
  SAVE_NEW_CLASSROOM,
  UPDATE_CLASSROOM,
  DELETE_CLASSROOM,
  ERRORS_CLASSROOM,
} from "../types/index";

const Reducer = (state, action) => {
  switch (action.type) {
    case ERRORS_CLASSROOM:
      console.log("ERROR_ver");
      return {
        ...state,
        actualError: action.payload,
      };
    case CLASSROOMS_LOCATION:
      return {
        ...state,
        classroomsLocation: action.payload,
        actualError: null,
      };
    case ACTUAL_CLASSROOM:
      return {
        ...state,
        actualClassroom: action.payload,
      };
    case SAVE_NEW_CLASSROOM:
      return {
        ...state,
        classroomsLocation: [...state.classroomsLocation, action.payload],
        actualError: null,
      };
    case UPDATE_CLASSROOM:
      const newArray = state.classroomsLocation;
      const indexClassroom = state.classroomsLocation.findIndex(
        (obj) => obj._id === action.payload._id
      );
      newArray[indexClassroom] = action.payload;
      return {
        ...state,
        classroomsLocation: newArray,
        actualClassroom: action.payload,
      };

    case DELETE_CLASSROOM:
      const errorExt = {
        code: "13",
        message: {
          es: "Aún no hay salones registrados en esta sede",
        },
      };
      const newArrayClassrooms = state.classroomsLocation;
      newArrayClassrooms.splice(
        newArrayClassrooms.findIndex((item) => item._id === action.payload),
        1
      );
      return {
        ...state,
        classroomsLocation: newArrayClassrooms,
        actualError: newArrayClassrooms.length === 0 && errorExt,
      };

    default:
      return state;
  }
};
export default Reducer;
