import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";

import { fetchAsync, urlCourseLevels } from "../Globals/globals";
import WizardSelector from "./components/wizardSelector";

const SelectorGroup = (props) => {
  const [coursesMedia, setCoursesMedia] = useState([]);
  const [coursesSuperior, setCoursesSuperior] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(false);

  useEffect(() => {
    getCourseLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCourseLevels = () => {
    setLoadingCourses(true);
    fetchAsync(urlCourseLevels, "", "GET", "")
      .then((data) => {
        if (data.success) {
          //obtenemos los cursos de media y superior
          let mediaLevel = data.courseLevels.find(
            (data) => data.name === "Media Superior"
          );
          let superiorLevel = data.courseLevels.find(
            (data) => data.name === "Superior"
          );
          getCoursesMedia(mediaLevel._id);
          getCoursesSuperior(superiorLevel._id);
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //metodo que trae todos los cursos de media
  const getCoursesMedia = (idMedia) => {
    fetchAsync(urlCourseLevels + idMedia + "/courses", "", "GET", "")
      .then((data) => {
        if (data.success) {
          setCoursesMedia(data.courses.reverse());
        }
        setLoadingCourses(false);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //metodo que trae todos los cursos de Superior
  const getCoursesSuperior = (idSuperior) => {
    fetchAsync(urlCourseLevels + idSuperior + "/courses", "", "GET", "")
      .then((data) => {
        if (data.success) {
          setCoursesSuperior(data.courses.reverse());
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const onCloseModal = () => {
    props.close(false);
  };

  return (
    <Modal
      onClose={onCloseModal}
      open={props.valueModal}
      center={false}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <WizardSelector
        coursesMedia={coursesMedia}
        coursesSuperior={coursesSuperior}
        saveNewGroup={props.saveNewGroup}
        newGroup={props.newGroup}
        onCloseModal={onCloseModal}
        oldIdGroup={props.oldIdGroup}
        loadingCourses={loadingCourses}
      />
    </Modal>
  );
};
export default SelectorGroup;
