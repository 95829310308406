import React, { useReducer } from 'react';
import SidebarReducer from './SidebarReducer';
import SidebarContext from './SidebarContext';
import {
    CHANGE_INIT_POSITION,
    CHANGE_SIDEBAR_SHOW,
    CHANGE_SIDEBAR_MOBILE_SHOW,
    CHANGE_SIDEBAR_COMPLEMENT_SHOW,
    OPEN_ADDCONTENT_SIDEBAR_COMPLEMENT,
    KILL_SIDEBAR_COMPLEMENT
} from '../types/index';

const SIDEBAR_OPEN = 1;

const SidebarState = (props) => {
    const initialState = {
        sidebarPosition: SIDEBAR_OPEN,
        sidebarShow: true,
        sidebarMobileShow: false,
        sidebarComplementShow: false,
        sidebarComplementContent: ''
    }
    //crear dispatch y state
    const [state, dispatch] = useReducer(SidebarReducer, initialState)

    //obtiene la información de la sede seleccionada en la tabla
    const changeSidebarPosition = (position) => {
        dispatch({
            type: CHANGE_INIT_POSITION,
            payload: position
        })
    }

    const changeSidebarShow = (position) => {
        dispatch({
            type: CHANGE_SIDEBAR_SHOW,
            payload: position
        })
    }
    const changeSidebarMobileShow = (position) => {
        dispatch({
            type: CHANGE_SIDEBAR_MOBILE_SHOW,
            payload: position
        })
    }

    const killSideBar = () => {
        dispatch({
            type: KILL_SIDEBAR_COMPLEMENT,
            payload: true
        })
    }

    const changeSidebarComplementShow = (position) => {
        dispatch({
            type: CHANGE_SIDEBAR_COMPLEMENT_SHOW,
            payload: position
        })
    }


    const openAndSetContentSidebarComplement = (content) => {
        dispatch({
            type: CHANGE_SIDEBAR_COMPLEMENT_SHOW,
            payload: true
        })
        dispatch({
            type: OPEN_ADDCONTENT_SIDEBAR_COMPLEMENT,
            payload: content
        })
    }

    return (
        <SidebarContext.Provider
            value={{
                sidebarPosition: state.sidebarPosition,
                sidebarShow: state.sidebarShow,
                sidebarMobileShow: state.sidebarMobileShow,
                sidebarComplementShow: state.sidebarComplementShow,
                sidebarComplementContent: state.sidebarComplementContent,
                changeSidebarPosition,
                changeSidebarShow,
                changeSidebarMobileShow,
                changeSidebarComplementShow,
                openAndSetContentSidebarComplement,
                killSideBar
            }}
        >
            {props.children}
        </SidebarContext.Provider>
    )
}
export default SidebarState;
