import React, { useMemo, useReducer, createContext } from "react";
import TalksReducer from "./TalksReducer";
import {
  fetchAsync,
  urlTalks,
  urlUsersTalks,
  urlSchedules,
} from "helpers/Globals/globals";
import {
  showNotification,
  showMessageSuccess,
  showMessageLoading,
  showMessageError,
} from "helpers/MessageAndNotificationUtils";
import { newSchedules } from "helpers/Globals/funciones";
import { semanaLetra } from "helpers/Fichas/constants";
import {
  SELECT_TALK,
  SET_ALL_TALKS,
  SET_ROW_DATA,
  SET_STUDENT_REMOVE,
  SET_TALK_ACTIVE,
  SOCKET_EVENT_TALKS,
  CREATE_TALK,
  EDIT_TALK,
  DELETE_TALK,
  CHANGE_SKELETON_LOADING,
  SET_FULLINFO_TALK,
  SET_STUDENT_CONFIRMATION,
  SET_STUDENT_STATUS,
  SET_STUDENT_ATTENDANCE,
  SET_STUDENT_COMMENT_ADD,
  SET_STUDENT_COMMENT_UPDATE,
  SET_STUDENT_COMMENT_REMOVE,
  UPDATE_AFTER_EDIT,
  UPDATE_AFTER_DELETE_STUDENT_TALK,
} from "../types/index";

const initialState = {
  loadingAllTalks: true,
  loadingSkeleton: true,
  talkSelected: {},
  talkFullInfo: {},
  talks: [],
  totalTalks: 0,
};

const TalksContext = createContext();

const TalksState = (props) => {
  //crear dispatch y state
  const [state, dispatch] = useReducer(TalksReducer, initialState);

  const selectTalk = (talk) => {
    dispatch({
      type: SELECT_TALK,
      payload: talk,
    });
  };

  const updateUserAfterEdit = (userInfo) => {
    dispatch({
      type: UPDATE_AFTER_EDIT,
      payload: userInfo,
    });
  };

  const setSelectedTalk = (rowData) => {
    dispatch({
      type: SET_ROW_DATA,
      payload: rowData,
    });
  };

  const chargeTalks = () => {
    fetchAsync(urlTalks + "?limit=20", "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: SET_ALL_TALKS,
            payload: result.talks,
          });
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const paginationTalks = (skip, limit) => {
    return fetchAsync(urlTalks + `?skip=${skip}&limit=${limit}`, "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: SET_ALL_TALKS,
            payload: result,
          });
        }
        return result;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const getFullInfoTalk = (id) => {
    if (!state.loadingSkeleton) {
      dispatch({
        type: CHANGE_SKELETON_LOADING,
        payload: true,
      });
    }
    fetchAsync(urlTalks + id + "/sidebar", "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: SET_FULLINFO_TALK,
            payload: data.talk,
          });
        } else {
          showMessageError(
            <strong>
              Atención! No se pudo actualizar el status de la platica
            </strong>,
            2
          );
        }
      })
      .catch((reason) => {
        console.log(reason);
        showMessageError("Ocurrio un error Reportar error a sistemas", 3);
      });
  };

  const updateCalledBy = (idStudentTalk) => {
    let closeLoading = showMessageLoading("Guardando", 0);
    fetchAsync(urlTalks + "call/student/" + idStudentTalk, "", "PUT", "")
      .then((result) => {
        if (result.success) {
          console.log(result);
          closeLoading();
          showMessageSuccess("Usuario confirmado", 2);
          dispatch({
            type: SET_STUDENT_CONFIRMATION,
            payload: {
              calledBy: result.calledBy,
              studentId: idStudentTalk,
            },
          });
        } else {
          closeLoading();
          showMessageError("Ocurrio un error al guardar", 3);
        }
      })
      .catch((reason) => {
        closeLoading();
        showMessageError("Ocurrio un error Reportar error a sistemas", 3);
        console.log(reason.message);
      });
  };

  const updateStatus = (idStudentTalk, value) => {
    let body = {
      status: value,
    };
    let closeLoading = showMessageLoading("Guardando cambios", 0);
    fetchAsync(
      urlTalks + "status/student/" + idStudentTalk,
      JSON.stringify(body),
      "PUT",
      ""
    )
      .then((result) => {
        if (result.success) {
          closeLoading();
          showMessageSuccess("Guardado", 1);
          dispatch({
            type: SET_STUDENT_STATUS,
            payload: {
              status: result.status,
              studentId: idStudentTalk,
            },
          });
        } else {
          closeLoading();
          showMessageError("Ocurrio un error al guardar", 3);
        }
      })
      .catch((reason) => {
        console.log(reason.message);
        closeLoading();
        showMessageError("Ocurrio un error Reportar error a sistemas", 3);
      });
  };

  const updateAttendance = (idStudentTalk, value) => {
    const body = {
      attendance: value,
    };
    const closeLoading = showMessageLoading("Guardando", 0);
    fetchAsync(
      urlTalks + "attendance/student/" + idStudentTalk,
      JSON.stringify(body),
      "PUT",
      ""
    )
      .then((result) => {
        if (result.success) {
          closeLoading();
          showMessageSuccess("Guardado", 1);
          dispatch({
            type: SET_STUDENT_ATTENDANCE,
            payload: {
              attendance: value,
              studentId: idStudentTalk,
            },
          });
        } else {
          closeLoading();
          showMessageError("Ocurrio un error al guardar", 3);
        }
      })
      .catch((reason) => {
        console.log(reason.message);
        closeLoading();
        showMessageError("Ocurrio un error Reportar error a sistemas", 3);
      });
  };
  const updateCommentsCallback = (eventName, idStudentTalk, comment) => {
    console.log(eventName);
    switch (eventName) {
      case "add": {
        dispatch({
          type: SET_STUDENT_COMMENT_ADD,
          payload: {
            comment: comment,
            studentId: idStudentTalk,
          },
        });
        break;
      }
      case "update": {
        dispatch({
          type: SET_STUDENT_COMMENT_UPDATE,
          payload: {
            comment: comment,
            studentId: idStudentTalk,
          },
        });
        break;
      }
      case "delete": {
        dispatch({
          type: SET_STUDENT_COMMENT_REMOVE,
          payload: {
            comment: comment,
            studentId: idStudentTalk,
          },
        });
        break;
      }
      default:
        break;
    }
  };

  const createTalk = async (body) => {
    const hasAvailability = await reviewAvailability(body);
    if (hasAvailability) {
      const closeLoading = showMessageLoading("Creando Platica", 0);
      let bodyToService = {
        name: body.name,
        date: body.startDate,
        active: body.status,
        quota: body.quota,
        schedules: body.schedules,
        courseLevel: body.courseLevel,
        classRoom: body.selectedSalon._id,
        isFeria: body?.isFeria,
      };
      if (body.selectedProfessor !== undefined) {
        bodyToService.teacher = body.selectedProfessor;
      }
      return await fetchAsync(urlTalks, JSON.stringify(bodyToService), "POST")
        .then((data) => {
          closeLoading();
          if (data.success) {
            dispatch({
              type: CREATE_TALK,
              payload: {
                talk: data.talk,
              },
            });
            return true;
          } else {
            if (data.errorExt.code === "08") {
              let mensaje = data.errorExt.message.es;
              showNotification("error", mensaje, "", 0);
            }
            return false;
          }
        })
        .catch((reason) => {
          closeLoading();
          console.log(reason.message);
        });
    } else {
      return false;
    }
  };

  const editTalk = async (idTalk, body) => {
    const hasAvailability = await reviewAvailability(body);
    if (hasAvailability) {
      const closeLoading = showMessageLoading("Guardando cambios", 0);
      let scheduleFix = body.schedules.map((item) => ({ ...item }));
      scheduleFix.forEach((item) => {
        delete item._id;
      });
      let bodyToService = {
        name: body.name,
        date: body.startDate,
        active: body.status,
        quota: body.quota,
        schedules: scheduleFix,
        courseLevel: body.courseLevel,
        classRoom: body.selectedSalon._id,
        isFeria: body?.isFeria,
      };
      if (body.selectedProfessor !== undefined) {
        bodyToService.teacher = body.selectedProfessor;
      }
      return await fetchAsync(
        urlTalks + idTalk,
        JSON.stringify(bodyToService),
        "PUT"
      )
        .then((data) => {
          closeLoading();
          if (data.success) {
            dispatch({
              type: EDIT_TALK,
              payload: {
                talk: data.talk,
              },
            });
            return true;
          } else {
            if (data.errorExt.code === "08") {
              let mensaje = data.errorExt.message.es;
              showNotification("error", mensaje, "", 0);
            }
            return false;
          }
        })
        .catch((reason) => {
          closeLoading();
          console.log(reason.message);
          return false;
        });
    } else {
      return false;
    }
  };

  const reviewAvailability = async (body) => {
    console.log("SE EJECUTA");
    const closeLoading = showMessageLoading("Revisando disponibilidad", 0);
    const scheduleFix = body.schedules.map((item) => ({ ...item }));
    scheduleFix.forEach((item) => {
      item.day = semanaLetra[item.day];
      delete item._id;
    });
    const bodyAvailability = {
      classRoomId: body.selectedSalon._id,
      startDate: body.startDate,
      endDate: body.startDate,
      scheduleArray: scheduleFix,
    };
    return await fetchAsync(
      urlSchedules + "/is/available",
      JSON.stringify(bodyAvailability),
      "POST"
    )
      .then((data) => {
        if (data.success === true) {
          if (data.isAvailable === true) {
            closeLoading();
            return true;
          } else {
            //let messageText = "El horario ya esta ocupado";
            data.groups.forEach((dato) => {
              const inicio = new Date(dato.startDate);
              const termino = new Date(dato.endDate);
              inicio.setMonth(inicio.getMonth() + 1);
              termino.setMonth(inicio.getMonth() + 1);
              const newArray =
                dato.schedules !== undefined ? dato.schedules : [];
              //const inicio = new Date()
              let datos = (
                <div>
                  Grupo: <strong>{dato.name}</strong>
                  <div>
                    Estatus:{" "}
                    {dato.active === true ? "Habilitado" : "Inhabilitado"}
                  </div>
                  <div>
                    Inicia:{" "}
                    {inicio.getUTCDate() +
                      "-" +
                      inicio.getUTCMonth() +
                      "-" +
                      inicio.getUTCFullYear()}
                  </div>
                  <div>
                    Termina:{" "}
                    {termino.getUTCDate() +
                      "-" +
                      termino.getUTCMonth() +
                      "-" +
                      termino.getUTCFullYear()}
                  </div>
                  <div>Horario: {newSchedules(newArray)}</div>
                  <div>Curso: {dato.courseInfo.name}</div>
                  <div>Sede: {dato.locationInfo.name}</div>
                  <div>Salón: {dato.classRoomInfo.name}</div>
                </div>
              );
              closeLoading();
              showNotification(
                "error",
                "Duración, Horario y salón ocupados: ",
                datos,
                0
              );
            });
          }
        } else {
          closeLoading();
          if (data.errorExt.code === "08") {
            let mensaje = data.errorExt.message.es;
            showNotification("error", mensaje, "", 0);
          }
          return false;
        }
      })
      .catch((reason) => {
        closeLoading();
        showNotification("error", "Ocurrio un error, reporta a sistemas.", 0);
        console.log(reason.message);
        return false;
      });
  };

  const deleteTalk = (id) => {
    return fetchAsync(urlTalks + id, "", "DELETE", "")
      .then((data) => {
        if (data.success === true) {
          showMessageSuccess(<strong>Platica Eliminada</strong>, 2);
          dispatch({
            type: DELETE_TALK,
            payload: {
              idTalk: id,
            },
          });
          return data;
        } else {
          showMessageError(
            <strong>Atención! Ocurrió un error al borrar la platica</strong>,
            2
          );
        }
      })
      .catch((reason) => {
        showMessageError(
          <strong>Atención! Ocurrió un error al borrar la platica</strong>,
          2
        );
      });
  };

  const deleteUserTalk = (id) => {
    fetchAsync(urlUsersTalks + id, "", "DELETE", "")
      .then((data) => {
        if (data.success) {
          showMessageSuccess(<strong>Eliminado correctamente</strong>, 2);
          dispatch({
            type: SET_STUDENT_REMOVE,
            payload: {
              studentId: id,
            },
          });
        } else {
          showMessageError(
            <strong>
              Atención! Ocurrió un error al borrar al usuario de la platica
            </strong>,
            2
          );
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };
  const togleStatusTalk = (idTalk) => {
    fetchAsync(urlTalks + idTalk + "/toggle", "", "PUT", "")
      .then((result) => {
        if (result.success === true) {
          dispatch({
            type: SET_TALK_ACTIVE,
            payload: {
              idTalk: idTalk,
            },
          });
          showMessageSuccess(
            <strong>Estado actualizado exitosamente</strong>,
            2
          );
        }
      })
      .catch((reason) => {
        showMessageError(
          <strong>Atención! No se pudo actualizar el estado</strong>,
          2
        );
      });
  };

  const updateDataSocket = (data) => {
    console.log("updateDataSocket");
    if (data) {
      if (data.event === "add_comment_talk") {
        console.log("ADD COMMENT");
      } else if (data.event === "remove_from_talk") {
        dispatch({
          type: SOCKET_EVENT_TALKS,
          payload: data,
        });
      }
    }

    // if (data) {
    //     if (data.event === "add_comment_talk") {
    //     } else {
    //         let provisional = { ...talkFullInfo }
    //         let indexModify = provisional.studentList.findIndex(i => i != null && i._id === data.studentId);
    //         if (indexModify >= 0) {
    //             if (data.event === "update_calledBy") {
    //                 console.log("update_calledBy")
    //                 if (data.calledBy !== undefined)
    //                     provisional.studentList[indexModify].calledBy = { ...data.calledBy }
    //                 else
    //                     provisional.studentList[indexModify].calledBy = undefined
    //                 // settalkFullInfo(provisional)
    //             } else if (data.event === "update_status") {
    //                 provisional.studentList[indexModify].status = data.status
    //                 // settalkFullInfo(provisional)
    //             } else if (data.event === "remove_from_talk") {
    //                 provisional.studentList[indexModify].isUnavailable = true
    //                 // settalkFullInfo(provisional)
    //                 props.getPlaticas()
    //             } else if (data.event === "update_attendance") {
    //                 provisional.studentList[indexModify].attendance = data.attendance
    //                 // settalkFullInfo(provisional)
    //             }
    //         } else {
    //             if (data.event === "remove_from_talk") {
    //                 provisional.studentList.push(data.userTalks)
    //                 // settalkFullInfo(provisional)
    //                 props.getPlaticas()
    //             }
    //         }
    //     }
    // }
  };

  const onDeleteStudentTalk = (idStudent) => {
    dispatch({
      type: UPDATE_AFTER_DELETE_STUDENT_TALK,
      payload: idStudent,
    });
  };

  const value = useMemo(() => {
    return {
      state: state,
      setSelectedTalk,
      chargeTalks,
      selectTalk,
      getFullInfoTalk,
      togleStatusTalk,
      updateCommentsCallback,
      updateCalledBy,
      updateStatus,
      updateAttendance,
      updateDataSocket,
      deleteUserTalk,
      createTalk,
      editTalk,
      deleteTalk,
      updateUserAfterEdit,
      paginationTalks,
      onDeleteStudentTalk,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <TalksContext.Provider value={value}>
      {props.children}
    </TalksContext.Provider>
  );
};

export { TalksContext };
export default TalksState;
