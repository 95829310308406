import React from 'react';
import { Tag, Popconfirm, Progress } from 'antd';
import { Draggable } from "react-beautiful-dnd";
import ImageComponent from "../ImageComponent";
import Can from '../../../../helpers/Secure/Can';
import { TYPE_PLAYLIST, TYPE_VIDEO, TYPE_QUIZ } from '../../../../helpers/Globals/constants';
import { STATUS_VIDEO_PROCESSING, STATUS_VIDEO_PROCESSING_AWS } from '../../../../helpers/Globals/constants';
import { Rate } from 'antd';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll'
import "antd/es/rate/style/index.css";
import "antd/es/progress/style/index.css";
import 'antd/es/popover/style/index.css'
import 'antd/es/tag/style/index.css'

const iconExamn = `${process.env.PUBLIC_URL}/img/icons/quiz/group-12@2x.png`;

const ChaptersContentPlaylist = ({ contentInfo, openViewVideo, idDD, saveSelectedChapter,
  openEdit, removeItemPlaylist, openEditVideo, selectedChapter, moveMaterial = false, selectedCourse }) => {

  const showImg = (item) => {
    switch (item.type) {
      case TYPE_PLAYLIST:
        return <img onClick={() => saveSelectedChapter(item)} className="image_playlist"
          src={item.image !== undefined ? item.image.url : process.env.PUBLIC_URL + '/img/icons/quiz/group@2x.png'} alt="file" />
      case TYPE_VIDEO:
        return <div className={item.statusTranscode === STATUS_VIDEO_PROCESSING || item.statusTranscode === STATUS_VIDEO_PROCESSING_AWS ? "image_video_playlist processing" : "image_video_playlist"}
          onClick={() => openViewVideo(item)}>
          {item.statusTranscode === STATUS_VIDEO_PROCESSING || item.statusTranscode === STATUS_VIDEO_PROCESSING_AWS ?
            <img className="image_file_material" src={process.env.PUBLIC_URL + '/img/icons/processing.gif'} alt="file" /> :
            <ImageComponent url={item.url} urlThumbnail={item.urlThumbnail} />
          }
          {item.statusTranscode === STATUS_VIDEO_PROCESSING || item.statusTranscode === STATUS_VIDEO_PROCESSING_AWS ?
            "" : <i className="material-icons icon_play_video">play_circle_outline</i>
          }
        </div>
      case TYPE_QUIZ:
        return <img className="image_playlist_exam" src={iconExamn} alt="avatar" />
      default:
        break
    }
  }

  return (
    <Element name={contentInfo._id}>
      <Draggable key={contentInfo._id}
        draggableId={contentInfo._id}
        index={idDD}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="container_playlist_card" style={contentInfo.backgroundColor !== undefined ? { backgroundColor: contentInfo.backgroundColor } : {}}>
              <div className="buttons_card_playlist_content">
                {contentInfo.type !== TYPE_VIDEO ? contentInfo.active ? <Tag className="material_tag_green">Activo</Tag> : <Tag className="material_tag_red">Inactivo</Tag> : ""}
                <p className={contentInfo.type !== TYPE_PLAYLIST ? "hide_sub" : ""} >Sub-Capítulo</p>
                {moveMaterial ? "" : <Can do="delete" on="/playlists/">
                  <Popconfirm title={contentInfo.type === TYPE_PLAYLIST ? "¿Estás seguro de eliminar el cápitulo?. Todo su contenido se perdera" :
                    contentInfo.type === TYPE_VIDEO ? "¿Estás seguro de borrar el video?" : "¿Estás seguro de borrar el cuestionario?"}
                    className="float-right"
                    okText="Si"
                    cancelText="No"
                    icon={<i className="material-icons red_icon">error</i>}
                    placement="topRight"
                    onConfirm={() => removeItemPlaylist(contentInfo)}
                  >
                    <span><i className="material-icons icon_delete">delete</i></span>
                  </Popconfirm>
                </Can>}

                {moveMaterial ? "" :
                  contentInfo.type === TYPE_QUIZ ?
                    <Can do="post" on="/playlists/">
                      <Link
                        to={{ pathname: "/cuestionarios/" + selectedCourse._id + "/" + selectedChapter._id + "/" + contentInfo._id }}
                      >
                        <i className="material-icons iconEditVideo">edit</i>
                      </Link>
                    </Can> :
                    <Can do="put" on="/playlists/">
                      <span onClick={() => contentInfo.type === TYPE_PLAYLIST ? openEdit(contentInfo) : contentInfo.type === TYPE_VIDEO ? openEditVideo(contentInfo) : ""}>
                        <i className="material-icons icon_edit"
                        >edit</i></span>
                    </Can>
                }
              </div>
              <div className="div_content_playlist">
                <div className="img_content_playlist">
                  {showImg(contentInfo)}
                </div>
                <div className="div_description_playlist">
                  <p>Nombre: {contentInfo.type !== TYPE_VIDEO ? contentInfo.name : contentInfo.title}</p>
                  <div className="desc_div">
                    {contentInfo.statusTranscode === STATUS_VIDEO_PROCESSING || contentInfo.statusTranscode === STATUS_VIDEO_PROCESSING_AWS ?
                      <>
                        <h4 className="mt-3">PROCESANDO VÍDEO</h4>
                        <Progress status="active" showInfo={false} percent={contentInfo.progress ? contentInfo.progress : 0} />
                      </>
                      : moveMaterial ? "" : <p>Descripción: {contentInfo.description} </p>}
                  </div>
                  {contentInfo.type !== TYPE_VIDEO ?
                    <>
                      {contentInfo.type === TYPE_PLAYLIST ? <p> Elementos guardados: {contentInfo.totalItems !== undefined ? contentInfo.totalItems : 0}</p> :
                        <p> Número de preguntas: {contentInfo.questionCollection !== undefined ? contentInfo.questionCollection.length : 0}</p>
                      }
                    </>
                    : ""
                  }
                  {contentInfo.type === TYPE_VIDEO ? <Rate className="stars_size" disabled allowHalf defaultValue={contentInfo.rate.toFixed(2)} /> : ""}
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </Element>
  )
}
export default ChaptersContentPlaylist