import React from 'react';
import ImageComponent from '../../Material/components/ImageComponent';

const VideosForm = ({ videoInfo, idQuestion, iconDeleteVideoQuestion }) => {
  return (
    <div className="container-form-img mr-4 mb-5">
      <div className="video-form-title">
        <p >{videoInfo.title}</p>
      </div>
      <ImageComponent className="image" urlThumbnail={videoInfo.urlThumbnail} />
      <div className="float-right">
        <i className="material-icons icon-delete-answeer icon-x-question ml-4"
          //id={idQuestion}
          //onClick={(e) => console.log(e.currentTarget.id)}
          onClick={() => iconDeleteVideoQuestion(idQuestion, videoInfo._id)}
        //delete_forever
        //highlight_off
        //delete_sweep
        //backspace
        >
          delete
      </i>
      </div>
    </div>

  )
}
export default VideosForm 
