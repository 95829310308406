import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import QrScanView from "./components/qrScanView";
import ModalConfig from "./components/modalConfig";

const AttendanceQr = (props) => {
  const [valueModal, setValueModal] = useState(false);
  return (
    <Container className="dashboard">
      <Row>
        <Col xs="12">
          <MainPageHeader
            title="Administración Escolar |"
            subTitle={`Pase de asistencia`}
            buttonTitle={"Configuración del equipo"}
            buttonOnClick={() => setValueModal(true)}
            canActions={["post", "/roles/*"]}
          />
          <QrScanView />
        </Col>
      </Row>
      {valueModal && (
        <ModalConfig value={valueModal} close={() => setValueModal(false)} />
      )}
    </Container>
  );
};
export default AttendanceQr;
