import React from "react";
import { Button, Result } from "antd";
import PropTypes from "prop-types";

const AlertMessage = ({
  status,
  mesaage = "",
  button = false,
  textButton = "",
}) => {
  return (
    <Result
      status={status} //success error warning
      title={`${mesaage != "" ? mesaage : "ocurrio un problema"}`}
      extra={
        button && (
          <Button type="primary" key="console">
            {textButton}
          </Button>
        )
      }
    />
  );
};

AlertMessage.propTypes = {};

export default AlertMessage;
