import React, { useState, useContext } from "react";
import { Col, Row } from "reactstrap";
import { showMessageWarning } from "../../../helpers/MessageAndNotificationUtils";
import MoneyIcon from "mdi-react/MoneyIcon";
import NoteIcon from "mdi-react/NoteIcon";
import EnrollmentContext from "../../../context/EnrollmentContext/EnrollmentContext";
import { Checkbox, Divider, Input } from "antd";
import "antd/es/checkbox/style/index.css";
import "antd/es/divider/style/index.css";
import shortid from "shortid";

const inicialState = {
  courseName: "",
  discount: "",
};

const StepPayment = () => {
  //importamos el context y sus estados y funciones
  const enrollmentContext = useContext(EnrollmentContext);
  const {
    selectedCourse,
    payment,
    setPaymentData,
    studentInfoFastRegister,
    discounts,
    deleteDiscount,
    addDiscount,
  } = enrollmentContext;

  const [showDiscount, setShowDiscount] = useState(false);
  const [newDiscount, setNewDiscount] = useState(inicialState);

  //agrega los campos del nuevo descuento
  const onChamgeInputs = (e) => {
    setNewDiscount({
      ...newDiscount,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (e) => {
    setShowDiscount(e.target.checked);
  };

  const saveNewDiscount = () => {
    //console.log(newDiscount)
    if (newDiscount.courseName === "" || newDiscount.discount === "") {
      return showMessageWarning(
        <strong>Atención! Debes ingresar un concepto y cantidad.</strong>,
        2
      );
    }
    // if (parseInt(newDiscount.discount) <= 0) {
    //   return showMessageWarning(<strong>Atención! Debes ingresar una cantidad mayor a cero.</strong>, 2)
    // }
    if (parseInt(newDiscount.discount) > parseInt(selectedCourse.price)) {
      return showMessageWarning(
        <strong>
          Atención! El descuento no puede ser mayor al precio del curso.
        </strong>,
        2
      );
    }
    if (
      parseInt(newDiscount.discount) + parseInt(showTotalDiscount()) >
      parseInt(selectedCourse.price)
    ) {
      return showMessageWarning(
        <strong>
          Atención! La suma de los descuentos no puede ser mayor al precio del
          curso.
        </strong>,
        2
      );
    }
    newDiscount._id = shortid.generate();
    addDiscount(newDiscount);
    //reiniciamos los valores de los estados
    setNewDiscount(inicialState);
  };

  const showTotalDiscount = () => {
    let total = 0;
    if (discounts !== null) {
      discounts.forEach((value) => {
        total = total + parseInt(value.discount);
      });
      return total;
    }
  };

  //genera la lista de descuentos agregados
  const generaLista = () => {
    payment.discount = showTotalDiscount() !== 0 ? showTotalDiscount() : "";
    return discounts.map((data, index) => {
      return (
        <div id={data.course} key={data._id}>
          <Row>
            <Col xs={7} sm={7}>
              <span className="mr-2">{index + 1}-</span>
              <span className="mr-2">{data.courseName}</span>
            </Col>

            <Col xs={3} sm={3}>
              <span className="mr-2">{data.discount}</span>
            </Col>

            <Col xs={2} sm={2}>
              <div>
                <i
                  onClick={() => deleteDiscount(data._id)}
                  className="material-icons deleteIcon"
                >
                  clear
                </i>
              </div>
            </Col>
          </Row>
        </div>
      );
    });
  };

  return (
    <>
      <div>
        <h2>{selectedCourse.name}</h2>
        <h3>{`Costo:$ ${selectedCourse.price}`}</h3>
        {studentInfoFastRegister !== null ? (
          <>
            <h4>Descuento total aplicado:$ {showTotalDiscount()}</h4>
            <p className="totalPagar">{`Total a pagar: $ ${
              parseInt(selectedCourse.price) - parseInt(showTotalDiscount())
            }`}</p>
            <h5>Descuentos:</h5>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="payments_discounts">
        {studentInfoFastRegister !== null ? generaLista() : ""}
      </div>

      {studentInfoFastRegister !== null ? (
        <section className="mb-4">
          <h5 className="my-2">Agregar descuento</h5>
          <Input.Group compact>
            <Input
              style={{ width: "40%" }}
              size="small"
              placeholder="Concepto"
              type="text"
              name="courseName"
              onChange={(event) => {
                onChamgeInputs(event);
              }}
              value={newDiscount.courseName}
            />
            <Input.Search
              style={{ width: "50%" }}
              size="small"
              placeholder="Cantidad"
              type="number"
              name="discount"
              onChange={(event) => {
                onChamgeInputs(event);
              }}
              value={newDiscount.discount}
              enterButton="Agregar"
              onSearch={saveNewDiscount}
            />
          </Input.Group>
        </section>
      ) : (
        ""
      )}

      <form className="form form--horizontal wizard__form">
        {studentInfoFastRegister == null ? (
          <Divider orientation="left">
            <Checkbox defaultChecked={showDiscount} onChange={onChange}>
              Agregar descuento
            </Checkbox>
          </Divider>
        ) : (
          ""
        )}

        <div className="form__form-group">
          <span className="form__form-group-label">
            <strong>Concepto</strong>
          </span>
          <div className="form__form-group-field">
            <div
              className="form__form-group-icon cornersIconRounded"
              style={{ backgroundColor: "#EAECEE" }}
            >
              <NoteIcon />
            </div>
            <input
              className="form-control"
              name="concept"
              type="text"
              onChange={(e) => {
                setPaymentData(e.target.name, e.target.value);
              }}
              value={payment.concept}
            />
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            <strong>Cantidad</strong>
          </span>
          <div className="form__form-group-field">
            <div
              className="form__form-group-icon cornersIconRounded"
              style={{ backgroundColor: "#EAECEE" }}
            >
              <MoneyIcon />
            </div>
            <input
              className="form-control"
              name="amount"
              type="Number"
              onChange={(e) => {
                setPaymentData(e.target.name, e.target.value);
              }}
              value={payment.amount}
            />
          </div>
        </div>

        <div
          className={showDiscount === false ? "collapse" : " form__form-group"}
        >
          <span className="form__form-group-label">
            <strong>Descuento (Opcional)</strong>
          </span>
          <div className="form__form-group-field">
            <div
              className="form__form-group-icon cornersIconRounded"
              style={{ backgroundColor: "#EAECEE" }}
            >
              <MoneyIcon />
            </div>
            <input
              className="form-control"
              name="discount"
              type="Number"
              onChange={(e) => {
                setPaymentData(e.target.name, e.target.value);
              }}
              value={payment.discount}
            />
          </div>
        </div>
      </form>
    </>
  );
};
export default StepPayment;
