import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { newSchedules } from '../../../helpers/Globals/funciones';
import SortTable from '../../../helpers/Tables/sortTable';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingComponent from "../../../helpers/LoadingComponent";
import moment from 'moment'
import EnrollmentContext from '../../../context/EnrollmentContext/EnrollmentContext';
import ViewportContext from '../../../context/Viewport/viewportContext'

import styles from "./index.module.scss";

const StepGroup = () => {

  //instance of the viewport context
  const viewportContext = useContext(ViewportContext);
  const { width } = viewportContext
  const isMobile = width < 750;
  const isMobileSmall = width < 500;

  //importamos el context y sus estados y funciones
  const enrollmentContext = useContext(EnrollmentContext);
  const { loadingGroups, selectedCourseGroups,
    selectedGroup, selectGroup } = enrollmentContext;

  const fixDate = (cell, row) => {
    if (cell) {
      var fecha = moment(cell).format("DD-MM-YYYY")
      return fecha
    }
  }

  const paintRed = (cell, row) => {
    if (row.available === "0") {
      return <span style={{
        borderColor: 'transparent',
        color: 'red'
      }}>
        {cell}
      </span>
    } else {
      return cell
    }
  }

  const setGroupData = (cell, row) => {
    if (row.available !== '0') {
      return (
        <Button className="btn btn-sm"
          color="primary" size="sm"
          onClick={() => {
            selectGroup(row)
          }}>
          {isMobile ? "Elegir" : "Seleccionar"}
        </Button>
      )
    } else {
      return " "
    }
  }

  //pinta el row previamente selecionado
  const rowColor = (cell, row) => {
    let rowStyle = { backgroundColor: '#D0D3D4' };
    if (selectedGroup._id === cell._id) {
      return rowStyle
    }
  }

  const diasHoras = (cell, row) => {
    return newSchedules(cell)
  }

  const cellStyle = (cell, row, rowIndex, colIndex) => {
    return {
      textAlign: "center",
      verticalAlign: 'middle',
    }
  }

  const columnsGroups = [{
    dataField: 'name',
    text: 'Nombre',
    headerAlign: 'center',
    headerStyle: {
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    style: cellStyle,
    sort: true,
    sortCaret: (order, column) => {
      return <SortTable order={order} colum={column} />
    },
  },
  {
    dataField: 'startDate',
    text: 'Inicia',
    headerAlign: 'center',
    headerStyle: {
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    style: cellStyle,
    formatter: fixDate,
    sort: true,
    sortCaret: (order, column) => {
      return <SortTable order={order} colum={column} />
    },
  },

  {
    dataField: 'schedules',
    text: 'Dia(s) y Horarios',
    headerAlign: 'center',
    headerStyle: {
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    style: cellStyle,
    formatter: diasHoras,
    hidden: isMobileSmall,
  },
  {
    dataField: 'quota',
    text: 'Capacidad',
    headerAlign: 'center',
    headerStyle: {
      width: '80px',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    style: {
      ...cellStyle(),
      width: "80px"
    },
    formatter: paintRed,
    sort: true,
    sortCaret: (order, column) => {
      return <SortTable order={order} colum={column} />
    },
    hidden: isMobile,
  },
  {
    dataField: 'available',
    text: 'Lugares',
    headerAlign: 'center',
    headerStyle: {
      width: '80px',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    style: {
      ...cellStyle(),
      width: "80px"
    },
    formatter: paintRed,
    order: 'desc',
    sort: true,
    sortCaret: (order, column) => {
      return <SortTable order={order} colum={column} />
    },
    hidden: isMobile,
  },
  {
    dataField: '_id',
    text: 'Agregar',
    csvExport: false,
    headerStyle: {
      width: '135px',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    style: {
      ...cellStyle(),
      width: "125px"
    },
    formatter: setGroupData,
  },
  ];

  const defaultSorted = [{
    dataField: 'available',
    order: 'desc'
  }];

  return ((loadingGroups) ?
    <LoadingComponent />
    :
    <div className={styles.tables_group_main_container}>
      <BootstrapTable
        keyField='_id'
        hover
        bordered={false}
        data={selectedCourseGroups}
        columns={columnsGroups}
        defaultSorted={defaultSorted}
        noDataIndication={<div style={{ textAlign: "center" }}>El curso no tiene grupos disponibles</div>
        }
        rowStyle={rowColor} />
    </div>
  );
}
export default StepGroup;
