import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import CardsConfig from "./components/cardsConfig";
import SidebarRightComponent from "../../helpers/SidebarRight/SidebarRightComponent";
import MainPageHeader from "../../helpers/Headers/pageHeader";
import SideBarChannels from "./components/sideBarChannels";

const ExamplePage = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <MainPageHeader
            title="Cuenta |"
            subTitle={`Configuraciones del sistema`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <CardsConfig
            name="Canales En vivo"
            description="Cambia las configuraciones de los canales de transmisión"
            items="2 canales de transmisión en vivo."
            setShowDrawer={setShowDrawer}
          />
        </Col>
      </Row>
      {showDrawer && (
        <SidebarRightComponent
          isSidebarRightOpen={showDrawer}
          onClosSidebar={() => setShowDrawer(false)}
        >
          <SideBarChannels />
        </SidebarRightComponent>
      )}
    </Container>
  );
};

export default ExamplePage;
