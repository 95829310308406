
import { getCookie } from '../../helpers/cookies'

const urlBase = process.env.REACT_APP_URL_SERVER
const urlSocket = process.env.REACT_APP_URL_SERVER_SOCKET

// const urlBase = "https://dev.api.preparacionipn.mx";
// const urlBase = "https://dev.api.preparacionipn.mx";

// =================================================
// === Endpoints
// =================================================
const urlPeople = urlBase + '';
const urlLogin = urlBase + '/api/login';
const urlLoginStudent = urlBase + '/api/login';
const urlLoginStudentRecover = urlBase + '/api/st/pass/recover';

const urlLoginUserRecover = urlBase + '/api/unpr/users/pass/recover';

const urlRegister = urlBase + '/api/students/register';
const urlStudentRegister = urlBase + '/api/students/register';
const urlStudenDelete = urlBase + '/api/students/';
const urlPermissions = urlBase + '/api/permissions/';
const urlUsers = urlBase + '/api/users/';
const urlUsersTalks = urlBase + '/api/usersTalks/';
const urlMe = urlBase + '/api/me';
const urlStudents = urlBase + '/api/students/'; // ?limit=200';//&skip=skipid
const urlSchedules = urlBase + '/api/schedules';
const urlRoles = urlBase + '/api/roles/';
const urlGroups = urlBase + '/api/groups/'; // idgroup ; obtener detalles del grupo
const urlGroupsReceipt = urlBase + '/api/groups/receipt/';
const urlGroupsLite = urlBase + '/api/groups/sp/lite/'; // idgroup ; obtener detalles del grupo
const urlCourses = urlBase + '/api/courses/';
const urlCourseLevels = urlBase + '/api/course/levels/';
const urlCourseTypes = urlBase + '/api/course/types/';
const urlPlaylist = urlBase + '/api/playlists/';
const urlPlaylistByCourse = urlPlaylist + 'course/';
const urlPayments = urlBase + '/api/payments/student/'; // :studentid
const urlSearchBy = urlBase + '/api/students/search/term?q='; // :valor
const urlSearchElastic = urlBase + '/api/search?q='; // :valor
const urlClassRoom = urlBase + '/api/classrooms/';// :classroomid
const urlPayment = urlBase + '/api/payments';
const urlOcupabilidad = urlBase + '/api/available/location';
const urlavailableCourses = urlBase + '/api/available/courses/';
const urlAttendance = urlBase + '/api/attendance';
const urlAttendanceGroup = urlBase + '/api/attendance/group/';
const urlComments = urlBase + '/api/comments/';
const urlVideoComments = urlBase + '/api/videoComments/';
const urlAttendanceSingle = urlBase + '/api/attendance/single/';
const urlAttendanceStudent = urlBase + '/api/attendance/student/';
const urlAttendanceQr = urlBase + '/api/attendance/qr/';
const urlAppoiments = urlBase + '/api/appoiments/';
const urlTalks = urlBase + '/api/talks/';
const urlVideo = urlBase + '/api/videos/';
const urlImage = urlBase + '/api/images/';
const urlLiveChannels = urlBase + '/api/livechannels/';
const urlFolio = urlBase + '/api/folio/';


const urlSedes = urlBase + '/api/locations/';
const urlQuiz = urlBase + '/api/quiz/';
const urlClassrooms = urlBase + '/api/classrooms/location/';
const urlEditarClassrooms = urlBase + '/api/classrooms/';
const urlTemplates = urlBase + '/api/templates/';
const urlNotificationStudent = urlBase + '/api/notifications/student/';
const urlNotificationUserTalk = urlBase + '/api/notifications/userTalk/';
const urlNotificationGroups = urlBase + '/api/notifications/group/';
const urlNotificationTalks = urlBase + '/api/notifications/talk/';
const urlNotificationCourse = urlBase + '/api/notifications/genericNotification';
const urlNotificationGetGroupsByCourse = urlBase + '/api/notifications/groupsByCourse/';

// =================================================
// =================================================

async function fetchAsync(_url, _body, _method, _header, _token) {
  const tokenBearer = getCookie('token')
  let headerC = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
    // "Content-Type": "Accept",
    'Authorization': tokenBearer,
  });
  var data;

  if (_body === '') {
    let options = {
      method: _method,
      headers: headerC
    }
    data = await fetch(_url, options)
      .then(response => response.json())
      .then(data => data)
  } else {
    let options = {
      method: _method,
      headers: headerC,
      body: _body,
    }
    data = await fetch(_url, options)
      .then(response => response.json())
      .then(data => data)
  }

  return data;
}



async function fetchAsyncPublic(_url, _body, _method, _header, _token) {
  let headerC = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
  });
  var data;
  if (_body === '') {
    data = await (await fetch(_url, {
      method: _method,
      headers: headerC
    })).json();
  } else {
    data = await (await fetch(_url, {
      method: _method,
      headers: headerC,
      body: _body,
    })).json();
  }
  return data;
}


async function fetchAsyncDownload(_url, _body, _method, _header, _token) {
  const tokenBearer = getCookie('token')
  let headerC = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'text',
    "Access-Control-Allow-Headers": "Origin, X-Requested-With",
    // "Content-Type": "Accept",
    'Authorization': tokenBearer,
  });

  let data;
  if (_body === '') {
    data = await (await fetch(_url, {
      method: _method,
      headers: headerC,
    }));
  }
  return data;
}

async function uploadData(_url, _body, _method, _header, _token) {
  const tokenBearer = getCookie('token')
  let data = await (await fetch(_url, {
    method: _method,
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Headers": "Origin, X-Requested-With",
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
      "Authorization": tokenBearer
    },
    body: _body,
    progress: (e) => { console.log(`Progress: ${e.loaded / e.total}%`) }
  }))
  return data;
}


//Upload data to a server we own, add the token bearer
function uploadData2(_url, _body, _method, onProgress, index) {
  const tokenBearer = getCookie('token')
  let xhr = new XMLHttpRequest()
  let returnPromise = new Promise((res, rej) => {
    xhr.open(_method, _url)
    xhr.setRequestHeader('Access-Control-Allow-Origin', "*")
    xhr.setRequestHeader("Access-Control-Allow-Headers", "Origin, X-Requested-With, content-type")
    xhr.setRequestHeader("processData", false)
    // xhr.setRequestHeader("contentType", "multipart/form-data")
    xhr.setRequestHeader("mimeType", "multipart/form-data")
    xhr.setRequestHeader("Access-Control-Allow-Credentials", true)
    xhr.setRequestHeader("Authorization", tokenBearer)

    xhr.onload = e => res(e.target.responseText);

    xhr.onerror = rej

    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        var percent = Math.round((event.loaded / event.total) * 100)
        onProgress(percent, index)
        // console.log(percent)
      }
    }
    xhr.send(_body)
  })
  returnPromise.abort = () => {
    xhr.abort()
  }

  return returnPromise

}

//Upload data to S3 aws.
function uploadData3(_url, _body, _method, onProgress, index) {
  let xhr = new XMLHttpRequest()
  let returnPromise = new Promise((res, rej) => {
    xhr.open(_method, _url)
    xhr.setRequestHeader('Content-Type', _body.type);
    xhr.onload = e => res(e.target.responseText);
    xhr.onerror = rej
    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        var percent = Math.round((event.loaded / event.total) * 100)
        onProgress(percent, index)
        // console.log(percent)
      }
    }
    xhr.send(_body)
  })
  returnPromise.abort = () => {
    xhr.abortn()
  }
  return returnPromise
}



export {
  urlComments,
  urlVideoComments,
  urlStudenDelete,
  fetchAsync,
  fetchAsyncPublic,
  uploadData,
  uploadData2,
  uploadData3,
  urlCourses,
  urlPeople,
  urlLogin,
  urlLoginStudent,
  urlLoginStudentRecover,
  urlLoginUserRecover,
  urlRegister,
  urlPermissions,
  urlUsers,
  urlUsersTalks,
  urlMe,
  urlStudents,
  urlSchedules,
  urlRoles,
  urlStudentRegister,
  urlGroups,
  urlGroupsLite,
  urlGroupsReceipt,
  urlPayments,
  urlSearchBy,
  urlSearchElastic,
  urlClassRoom,
  urlBase,
  urlPayment,
  urlOcupabilidad,
  urlavailableCourses,
  urlAttendance,
  urlAttendanceGroup,
  fetchAsyncDownload,
  urlEditarClassrooms,
  urlSedes,
  urlClassrooms,
  urlAttendanceSingle,
  urlAttendanceStudent,
  urlAttendanceQr,
  urlCourseLevels,
  urlCourseTypes,
  urlTemplates,
  urlNotificationGroups,
  urlNotificationTalks,
  urlNotificationStudent,
  urlNotificationUserTalk,
  urlNotificationCourse,
  urlNotificationGetGroupsByCourse,
  urlAppoiments,
  urlTalks,
  urlPlaylist,
  urlPlaylistByCourse,
  urlVideo,
  urlImage,
  urlLiveChannels,
  urlSocket,
  urlQuiz,
  urlFolio,
};
