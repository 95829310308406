import React, { useContext, useState } from "react";
import { Col, Row, Button, Container } from "reactstrap";
import { Collapse } from "antd";
import moment from "moment";
import "moment/locale/es";

import TabsSidebar from "./tabsSidebar/index";
import TabsModal from "./tabsStudents/TabsModal";

import Enrollment from "../../Enrollment/index";
import Can from "../../../helpers/Secure/Can";
import { month } from "../../../helpers/Fichas/constants";

import StudentsContext from "../../../context/students/StudentsContext";
import EnrollmentState from "../../../context/EnrollmentContext/EnrollmentState";

import styles from "./index.module.scss";

const Ava = `${process.env.PUBLIC_URL}/img/generics/face.png`;
const { Panel } = Collapse;

const SideBarStudents = ({ setShowDrawer }) => {
  const studentsContext = useContext(StudentsContext);
  const {
    actualStudent,
    updateStudent,
    updateAfterDeleteStudent,
    addPaymentsStudent,
    updateAfterFastRegister,
  } = studentsContext;

  const [enrollmentModal, setEnrollmentModal] = useState(false);
  const [showTabsStudent, setShowTabsStudent] = useState(false);

  let {
    name,
    lastName,
    secondLastName,
    phoneNumber,
    email,
    secondPhoneNumber,
    birthDate,
    uniqueFolio,
  } = actualStudent;

  let { total, discounts } =
    actualStudent.balance !== undefined ? actualStudent.balance.payments : "";

  const getBirdDate = (dateB) => {
    let start = moment(dateB).format("DD/MM/YYYY");
    let dataStart = start.split("/");
    return `${dataStart[0]} de ${month[dataStart[1] - 1]}`;
  };

  const showDebt = (valor) => {
    let cost = valor.costs.total;
    let totalPaid = valor.payments.total;
    let discounts = valor.payments.discounts;

    if (totalPaid + discounts < cost) {
      return (
        <Row>
          <i className="material-icons ficha_icons red_icon">error</i>{" "}
          <p className="text-text ficha_text_icons">
            {" "}
            <strong>Tiene adeudos </strong>
          </p>{" "}
        </Row>
      );
    } else {
      return (
        <Row>
          <i className="material-icons ficha_icons green_icon">
            check_circle_outline
          </i>{" "}
          <p className="text-text ficha_text_icons">
            {" "}
            <strong>Pagos al corriente</strong>
          </p>{" "}
        </Row>
      );
    }
  };

  const afterDeleteStudent = (studentId) => {
    setShowTabsStudent(false); //close the modal
    setShowDrawer(false); //close the sidebar
    updateAfterDeleteStudent(studentId); //update after delete the table students
  };

  return (
    <Container className={styles.sidebar_main_container}>
      <Row>
        <Col xs="12" className={styles.students_sidebar}>
          <Row>
            <Col xs="5">
              <div className={styles.avatar_ficha_container}>
                <img src={Ava} alt="avatar" />
              </div>
            </Col>
            <Col xs="7" className={styles.ficha_name_container}>
              <p>{name}</p>
              <p>{`${lastName} ${secondLastName}`}</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="mt-2">
              <Button
                className="icon square btn-ficha float-right"
                type="button"
                color="success"
                outline
                onClick={() => setShowTabsStudent(true)}
              >
                <div className={styles.center_text_button}>
                  <p>
                    <i className="material-icons">edit</i>Ver más
                  </p>
                </div>
              </Button>
            </Col>
          </Row>
          <section className={styles.content_sidebar_students}>
            <Collapse defaultActiveKey={["1"]} ghost>
              <Panel header="Ver/Ocultar información" key="1">
                <div className={styles.icons_data_sidebar}>
                  <p>Folio: {uniqueFolio && uniqueFolio}</p>
                </div>
                <div className={styles.icons_data_sidebar}>
                  <i
                    className={`material-icons ${styles.icons_sidebar_students}`}
                  >
                    cake
                  </i>
                  <p>{getBirdDate(birthDate)}</p>
                </div>

                <div className={styles.icons_data_sidebar}>
                  <i
                    className={`material-icons ${styles.icons_sidebar_students}`}
                  >
                    mail
                  </i>
                  <p className={email.length > 39 ? styles.text_small : ""}>
                    {email}
                  </p>
                </div>

                <div className={styles.icons_data_sidebar}>
                  <i
                    className={`material-icons ${styles.icons_sidebar_students}`}
                  >
                    phone
                  </i>
                  <p>{phoneNumber}</p>
                </div>

                {secondPhoneNumber && (
                  <div className={styles.icons_data_sidebar}>
                    <i
                      className={`material-icons ${styles.icons_sidebar_students}`}
                    >
                      phone
                    </i>
                    <p>{secondPhoneNumber}</p>
                  </div>
                )}

                <div className={styles.icons_data_sidebar}>
                  <i
                    className={`material-icons ${styles.icons_sidebar_students}`}
                  >
                    attach_money
                  </i>
                  <p>
                    <strong>Total Abonado: </strong> {Math.floor(total)}
                    {/* <strong>Total Abonado: </strong> {total.toFixed(2)} */}
                  </p>
                </div>

                <div className={styles.icons_data_sidebar}>
                  <i
                    className={`material-icons ${styles.icons_sidebar_students}`}
                  >
                    money_off
                  </i>
                  <p>
                    <strong>Descuento: </strong>
                    {discounts}
                  </p>
                </div>

                <div className={styles.icons_data_sidebar}>
                  {actualStudent.balance ? showDebt(actualStudent.balance) : ""}
                </div>
              </Panel>
            </Collapse>
            <Can do="put" on="/groups/?/enroll/?">
              <Row>
                <Col xs="12" className="mt-2">
                  <Button
                    className={`icon square`}
                    type="button"
                    color="success"
                    outline
                    onClick={() => setEnrollmentModal(true)}
                  >
                    <p>
                      <i className="material-icons">add_circle_outline</i>
                      Inscribir a curso
                    </p>
                  </Button>
                </Col>
              </Row>
            </Can>
            <Row>
              <Col xs="12">
                <TabsSidebar />
              </Col>
            </Row>
          </section>
        </Col>
      </Row>
      {enrollmentModal && (
        <EnrollmentState>
          <Enrollment
            openModal={enrollmentModal}
            close={setEnrollmentModal}
            data={actualStudent}
            updateAfterFastRegister={updateAfterFastRegister}
          />
        </EnrollmentState>
      )}

      {showTabsStudent && (
        <TabsModal
          valueModal={showTabsStudent}
          close={setShowTabsStudent}
          studentInfo={actualStudent}
          updateStudentContext={updateStudent}
          afterDeleteStudent={afterDeleteStudent}
          addPaymentsStudent={addPaymentsStudent}
        />
      )}
    </Container>
  );
};
export default SideBarStudents;
