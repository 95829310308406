import React from "react";
import { Col, Row, Container, Button } from "reactstrap";
import { Divider } from "antd";

import TabsDevice from "./tabsDevice";
import GroupsList from "./groupsList";
import OptionsTypesMessages from "./optionsTypesMessages";
import CourseFilters from "./courseFilters";
import GroupsFilter from "./groupsFilter";
import WriteComment from "./writeComment";

import styles from "./index.module.scss";

const ShowComponents = ({ type, infoType, onCloseModal }) => {
  
  const showType = () => {
    switch (type) {
      case "courses":
        return <CourseFilters infoType={infoType} />;
      case "groups":
        return <GroupsFilter />;
      default:
        break;
    }
  };

  return (
    <Container className="container-fluid">
      <Row className={`${styles.show_components_main_container} no-gutters`}>
        <Col xs="12" md={type === "student" ? "7" : "6"}>
          <TabsDevice
            type={type}
            infoType={infoType}
            onCloseModal={onCloseModal}
            text={""}
          />
          {type === "courses" && (
            <section className={styles.extra_space}>
              <GroupsList infoType={infoType} />
            </section>
          )}
        </Col>
        <Col
          xs="12"
          md={type === "student" ? "5" : "6"}
          className={styles.options_container}
        >
          {type !== "talkStudent" && (
            <div>
              <Button
                size="sm"
                className={`btn btn-secondary float-right`}
                onClick={onCloseModal}
              >
                Salir
              </Button>
            </div>
          )}
          <section className={styles.section_options}>
            <div>
              <OptionsTypesMessages />
            </div>

            {type === "courses" || type === "groups" ? (
              <div>
                <Divider orientation="left">Agergar filtros</Divider>
                <section>{showType()}</section>{" "}
              </div>
            ) : (
              ""
            )}

            <WriteComment type={type} infoType={infoType} />
          </section>
        </Col>
      </Row>
    </Container>
  );
};
export default ShowComponents;
