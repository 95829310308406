//import React from 'react';
import React, { PureComponent, Fragment } from "react";
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap";

import styles from "./index.module.scss";
class CardDraw extends PureComponent {
  createTable = (elements, type) => {
    let table = [];
    //console.log(elements.length)

    for (let i = 0; i < elements.length; i++) {
      table.push(
        <Col
          key={elements[i]._id}
          xs={12}
          sm={12}
          md={8}
          lg={4}
          xl={3}
          onClick={() => this.handleClick(elements[i])}
        >
          <Card className={styles.card_box}>
            <CardBody
              className={`${styles.card_body_color} ${
                this.props.selected._id === elements[i]._id
                  ? styles.selected_card
                  : ""
              }`}
            >
              <div className="card__title">
                {type === "salones" ? (
                  <Fragment>
                    <h5 className="bold-text">
                      Nombre salón: {elements[i].name}
                    </h5>
                    <CardTitle className="subhead">
                      Capacidad:{elements[i].capacity}
                    </CardTitle>
                  </Fragment>
                ) : type === "sedes" ? (
                  <Fragment>
                    <h4 className="bold-text">{elements[i].name}</h4>
                    <CardTitle className="subhead">
                      Calle: {elements[i].street}
                    </CardTitle>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="card__title">
                      <h5 className="bold-text">{elements[i].name}</h5>
                      <p className="subhead">
                        Usuarios: {elements[i].totalUsers}
                      </p>
                    </div>
                    <div className="dashboard__total dashboard__total--area">
                      <CardTitle className="subhead">
                        {elements[i].description}
                      </CardTitle>
                    </div>
                  </Fragment>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return table;
  };

  handleClick(value) {
    //console.log(value)
    this.props.ClickOnElement(value);
  }

  render() {
    return (
      <div className={styles.main_container_cards}>
        {this.createTable(this.props.data, this.props.type)}
      </div>
    );
  }
}

export default CardDraw;
