import React, { useState, createContext, useEffect, useCallback } from "react";
import {
  fetchAsync,
  urlUsers,
  urlGroups,
  urlRoles,
  urlAttendance,
} from "../../../../helpers/Globals/globals";
export const EmployeeContext = createContext();

const EmployeeContextProvider = (props) => {
  const [allRoles, setAllRoles] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [allTeacherGroups, setAllTeacherGroups] = useState([]);
  const [emptyGroups, setEmptyGroups] = useState(false);
  const [actualEmployee, setActualEmployee] = useState({});
  const [idActualEmployee, setIdActualEmployee] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllEmployees = () => {
    setLoading(true);
    fetchAsync(urlUsers, "", "GET", "")
      .then((result) => {
        if (result.success) {
          setAllEmployees(result.users);
        }
        setLoading(false);
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const getGroupsByIdEmployee = useCallback(() => {
    setEmptyGroups(true);
    fetchAsync(urlGroups + "?teacher=" + idActualEmployee, "", "GET", "")
      .then((result) => {
        if (result.success === true) {
          setAllTeacherGroups(result.groups);
          setEmptyGroups(false);
        }
        setEmptyGroups(false);
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  }, [idActualEmployee]);

  const getRoles = useCallback(() => {
    fetchAsync(urlRoles, "", "GET", "")
      .then((result) => {
        if (result.success === true) {
          setAllRoles(result.roles);
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  }, []);

  useEffect(() => {
    if (idActualEmployee === "") return;
    getGroupsByIdEmployee();
    getRoles();
  }, [idActualEmployee, getGroupsByIdEmployee, getRoles]);

  const registerEmployees = (employee) => {
    return fetchAsync(urlUsers, JSON.stringify(employee), "POST", "default")
      .then((data) => {
        if (data.success) {
          setAllEmployees((allEmployees) => [...allEmployees, data.user]);
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const editEmployees = (employee) => {
    return fetchAsync(
      urlUsers + idActualEmployee,
      JSON.stringify(employee),
      "PUT",
      "default"
    )
      .then((data) => {
        if (data.success) {
          let newArray = [...allEmployees];
          let index = allEmployees.findIndex(
            (data) => data._id === idActualEmployee
          );
          newArray[index] = data.user;
          setAllEmployees(newArray);
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const deleteEmployee = () => {
    return fetchAsync(urlUsers + idActualEmployee, "", "DELETE", "")
      .then((data) => {
        if (data.success) {
          setAllEmployees(
            allEmployees.filter((employee) => employee._id !== idActualEmployee)
          );
          setAllTeacherGroups([]);
          setActualEmployee({});
          setIdActualEmployee("");
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  ///////////////operaciones de red de asistencia//////

  const getEmployeeAttendance = () => {
    // return fetchAsync(urlAttendance + "/user/" + idActualEmployee + "?startDate=2019-01-01&endDate=2050-09-01", "", "GET", "")
    return fetchAsync(
      urlAttendance + "/user/" + idActualEmployee,
      "",
      "GET",
      ""
    )
      .then((result) => {
        return result;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const setAttendace = (body) => {
    return fetchAsync(
      urlAttendance + "/users/" + idActualEmployee,
      JSON.stringify(body),
      "POST"
    )
      .then((data) => {
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const updateAttendace = (body) => {
    return fetchAsync(
      urlAttendance + "/user/" + idActualEmployee,
      JSON.stringify(body),
      "PUT"
    )
      .then((data) => {
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const deleteAttendance = (idAtendance) => {
    return fetchAsync(
      urlAttendance +
        "/user/" +
        idActualEmployee +
        "?attendanceId=" +
        idAtendance,
      "",
      "DELETE",
      ""
    )
      .then((result) => {
        return result;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  return (
    <EmployeeContext.Provider
      value={{
        loading,
        allRoles,
        allEmployees,
        actualEmployee,
        setActualEmployee,
        idActualEmployee,
        setIdActualEmployee,
        allTeacherGroups,
        emptyGroups,
        getAllEmployees,
        deleteEmployee,
        registerEmployees,
        editEmployees,
        //asisencia
        getEmployeeAttendance,
        setAttendace,
        updateAttendace,
        deleteAttendance,
      }}
    >
      {props.children}
    </EmployeeContext.Provider>
  );
};
export default EmployeeContextProvider;
