import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import {
  fetchAsync,
  urlStudents,
  urlPayment,
} from "../../../../../helpers/Globals/globals";
import {
  showMessageLoading,
  showMessageWarning,
  showMessageSuccess,
} from "../../../../../helpers/MessageAndNotificationUtils";
import { Modal } from "react-responsive-modal";
import { Select, Checkbox, Divider } from "antd";
import "antd/es/select/style/index.css";
import "antd/es/checkbox/style/index.css";
import "antd/es/divider/style/index.css";

const { Option } = Select;
let closeLoading = "";
const stateInicial = {
  paymentType: "cash",
  amount: "0",
  student: "",
  concept: "",
  discount: "0",
  course: "",
};

function PagosModal(props) {
  const {
    valueModal,
    close,
    titulo,
    _idEstudiente,
    discount,
    reloadAfterPayment,
    addPaymentsStudent,
  } = props;

  //estados
  const [disableButton, setDisableButton] = useState(false);
  const [checkboxCon, setCheckboxCon] = useState(false);
  const [allDebs, setAllDebs] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [bodyPayment, setBodyPayment] = useState(stateInicial);

  useEffect(() => {
    getStudentDebt(_idEstudiente);
    setBodyPayment({
      ...bodyPayment,
      student: _idEstudiente,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    setBodyPayment(stateInicial);
    if (closeLoading !== "") {
      closeLoading();
    }
    close(false);
  };

  const validationForm = () => {
    if (!selectedCourse.hasOwnProperty("courseName")) {
      showMessageWarning("Atención! Debes seleccionar un curso.", 2);
    } else if (bodyPayment.concept === "") {
      showMessageWarning("Atención! Debes ingresar un concepto de pago.", 2);
    } else if (discount) {
      if (parseInt(bodyPayment.discount) <= 0) {
        showMessageWarning("Atención! El descuento debe ser mayor a  cero.", 2);
      } else if (
        parseInt(bodyPayment.discount) > parseInt(selectedCourse.balance)
      ) {
        showMessageWarning(
          "Atención! El descuento no puede ser mayor al adeudo del curso.",
          2
        );
      } else {
        registerPayment();
      }
    } else {
      if (bodyPayment.amount <= 0) {
        showMessageWarning("Atención! Cantidad debe ser mayor a cero.", 2);
      } else if (bodyPayment.amount > selectedCourse.balance) {
        showMessageWarning(
          "Atención! La cantidad no puede ser mayor al adeudo del curso.",
          2
        );
      } else {
        registerPayment();
      }
    }
  };

  //opreaciones de RED//
  const registerPayment = () => {
    setDisableButton(true);
    closeLoading = LoadingMessage();

    if (_idEstudiente) {
      fetchAsync(urlPayment, JSON.stringify(bodyPayment), "POST", "default")
        .then((data) => {
          closeLoading();
          setDisableButton(false);
          if (data.success) {
            data.payment.amount === 0
              ? showMessageSuccess("Operación exitosa.", 3)
              : showMessageSuccess(
                  "Operación exitosa, una copia de este pago se envió al correo del alumno.",
                  3
                );
            reloadAfterPayment();
            //callback para los pagos en la ficha y tabla
            addPaymentsStudent(data.payment);
            onCloseModal();
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };

  //get all the student courses  status
  const getStudentDebt = (id) => {
    fetchAsync(urlStudents + `${id}/debt`, "", "GET", "")
      .then((data) => {
        if (data.success) {
          let array = data.payments.filter((data) => data.balance > 0);
          // let array = data.payments
          let agrega = { courseName: "Otros" };
          array.push(agrega);
          setAllDebs(array);
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const onChangeCheckBox = (e) => {
    setCheckboxCon(e.target.checked);
  };

  //mestra los cursos en el select
  const showCourses = () => {
    return allDebs.map((value, index) => {
      return (
        <Option key={index} value={value.courseName + "_" + value.courseId}>
          {value.courseName}
        </Option>
      );
    });
  };

  //guarda el curso seleccionado
  const handleSelecCourses = (value) => {
    if (value.split("_")[0] === "Otros") {
      let actualCourse = { courseName: "Otros" };
      setSelectedCourse(actualCourse);
      delete bodyPayment.course;
      setBodyPayment({
        ...bodyPayment,
        concept: value.split("_")[0],
      });
    } else {
      let actualCourse = allDebs.find(
        (data) => data.courseId === value.split("_")[1]
      );
      setSelectedCourse(actualCourse);
      setBodyPayment({
        ...bodyPayment,
        concept: value.split("_")[0],
        course: actualCourse.courseId,
      });
    }
  };

  //setea el valor de los inputs en el estado
  const handleInputs = (e) => {
    setBodyPayment({
      ...bodyPayment,
      [e.target.name]: e.target.value,
    });
  };

  const LoadingMessage = () => {
    return showMessageLoading("Registrando..", 0);
  };

  return (
    <Modal
      open={valueModal}
      onClose={onCloseModal}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container>
        <div className="cotainer_payment_modal">
          <Row className="mt-3">
            <Col xs="12" md="6" className="mt-2">
              <p className="titlePyments float-left">{titulo}</p>
            </Col>
            <Col xs="12" md="6" className="margin_mobile_payment">
              <Button
                color="success"
                type="button"
                className="btn-sm float-right"
                disabled={disableButton}
                onClick={() => validationForm()}
              >
                Registrar
              </Button>
              <Button
                style={{ marginRight: "15px" }}
                type="button"
                className="btn-sm float-right"
                onClick={() => onCloseModal()}
              >
                Salir sin guardar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }} className="mt-3 mb-4">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Selecciona un curso o escribe su nombre"
                onChange={handleSelecCourses}
              >
                {showCourses()}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col
              sm="12"
              md={{ size: 5, offset: 1 }}
              className="style_crete_payment"
            >
              <p className="">Costo del curso $ {selectedCourse.coursePrice}</p>
            </Col>
            <Col sm="12" md={{ size: 5 }} className="style_total">
              <p className="">Total pagado $ {selectedCourse.totalPayments}</p>
            </Col>
            <Col sm="12" md={{ size: 5, offset: 1 }} className="style_discount">
              <p className="">Descuento $ {selectedCourse.totalDiscounts}</p>
            </Col>
            <Col sm="12" md={{ size: 5 }} className="style_debt">
              <p className="">Adeudo $ {selectedCourse.balance}</p>
            </Col>
          </Row>
          <Row className="my-2">
            <Col sm="12" md={{ size: 8, offset: 1 }}>
              <Divider orientation="left">Habilitar editar concepto</Divider>
            </Col>
            <Col sm="12" md="3" className="checkBoxPayment">
              <Checkbox onChange={onChangeCheckBox} />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md={{ size: 5, offset: 1 }} className="">
              <div className="form__form-group ">
                <span className="form__form-group-label">
                  <strong>Concepto</strong>
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    className="form-control"
                    name="concept"
                    defaultValue={bodyPayment.concept}
                    disabled={!checkboxCon}
                    onChange={handleInputs}
                  />
                </div>
              </div>
            </Col>
            <Col
              sm="12"
              md={{ size: 5 }}
              className={discount ? "" : "hide_field_payments"}
            >
              <div className="form__form-group ">
                <span className="form__form-group-label">
                  <strong>Descuento</strong>
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    className="form-control "
                    name="discount"
                    defaultValue={bodyPayment.discount}
                    onChange={handleInputs}
                  />
                </div>
              </div>
            </Col>
            <Col
              sm="12"
              md={{ size: 5 }}
              className={discount ? "hide_field_payments" : ""}
            >
              <div className="form__form-group ">
                <span className="form__form-group-label">
                  <strong>Cantidad</strong>
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    className="form-control "
                    name="amount"
                    defaultValue={bodyPayment.amount}
                    onChange={handleInputs}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Modal>
  );
}
export default PagosModal;
