function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


function toServices(data) {
    let allows = []
    for (let key in data) {
        let methods = []
        for (let i = 0; i < data[key].length; i++) {
            methods.push(data[key][i].method)
        }
        let prov = {
            resource: key,
            methods: methods
        }
        allows.push(prov)
    }
    return allows
}


function structPermissionTree(checkedKeys) {
    let copy = []
    for (let i = 0; i < checkedKeys.length; i++) {
        let data = checkedKeys[i].split("-")
        if (data.length > 1) {
            let object = {
                route: data[0],
                method: data[1]
            }
            copy.push(object)
        }
    }
    let copy2 = groupBy(copy, "route")
    return toServices(copy2)
}

function reverseServiceToTree(allows) {
    if (allows) {
        let keys = []
        for (let i = 0; i < allows.length; i++) {
            if (allows[i].resource === "/*") {
                //return reverseServiceToTree(allPermissions)
            }
            for (let j = 0; j < allows[i].methods.length; j++) {
                let key = allows[i].resource + "-" + allows[i].methods[j]
                keys.push(key)
            }
        }
        return keys
    }
    return []
}


function serviceToabilities(allows) {
    if (allows) {
        let keys = []
        for (let i = 0; i < allows.length; i++) {
            if (allows[i].resource === "/*") {
                let variable = {
                    subject: "all",
                    actions: "manage",
                }
                keys.push(variable)
                break
            }
            let variable = {
                subject: allows[i].resource,
                actions: allows[i].methods,
            }
            keys.push(variable)
        }
        return keys
    }
    return []
}


export {
    structPermissionTree,
    groupBy,
    toServices,
    reverseServiceToTree,
    serviceToabilities
}