import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
  fetchAsync,
  urlGroups,
  urlAttendanceStudent,
  urlAttendance,
  urlAttendanceSingle,
} from "../../../../../helpers/Globals/globals";
import {
  showMessageLoading,
  showMessageWarning,
} from "../../../../../helpers/MessageAndNotificationUtils";
import LoadingComponent from "../../../../../helpers/LoadingComponent";
import TableCourses from "./components/tableCourses";
import TableAttendance from "./components/tableAttendance";
import moment from "moment";
import "moment/locale/es";

import styles from "./index.module.scss";

let closeLoading = "";
const dateFormat = "DD/MM/YYYY";

const Attendance = ({
  dataAlumno,
  employee = false,
  attendaceInfoEmployee = "",
  createAttendance,
  updateAttendanceEmployee,
  deleteAttedanceEmployee,
  onCloseModal,
}) => {
  const [groupsArray, setGroupsArray] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [attendaceInfo, setAttendaceInfo] = useState("");
  const [addAttendaceDate, setAddAttendaceDate] = useState("");
  const [disableAdd, setDisableAdd] = useState(false);
  const [status, setStatus] = useState(true);
  const [disableCheck, setDisableCheck] = useState(false);

  useEffect(() => {
    getStudentGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStudentGroups = () => {
    if (dataAlumno.groups !== undefined && dataAlumno.groups.length > 0) {
      var promises = dataAlumno.groups.map((idGroup) => {
        return fetchAsync(urlGroups + idGroup, "", "GET", "").then(
          (data) => data.group
        );
      });

      Promise.all(promises).then((results) => {
        const sortedArray = results.sort(
          (a, b) => moment(b.startDate) - moment(a.startDate)
        );
        setGroupsArray(sortedArray);
      });
    } else {
      setEmpty(true);
    }
  };

  const getStudentAttendnceByGroup = (idGrupo) => {
    fetchAsync(
      urlAttendanceStudent + dataAlumno._id + "?group=" + idGrupo,
      "",
      "GET"
    )
      .then((data) => {
        if (data.success) {
          const sortArrayDates = data.attendance.attendance.sort(
            (a, b) => moment(b.date) - moment(a.date)
          );
          let body = {
            average: data.average,
            attendance: sortArrayDates,
            attendanceData: data.attendance,
          };
          setAttendaceInfo(body);
        } else {
          let bodyFalse = {
            average: "",
            attendance: [],
            attendanceData: "",
          };
          setAttendaceInfo(bodyFalse);
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const setAttendanceByStudent = () => {
    if (!disableAdd && addAttendaceDate !== "") {
      const index = attendaceInfo.attendance.findIndex(
        (attendance) =>
          moment.utc(attendance.date).local().format(dateFormat) ===
          moment.utc(addAttendaceDate).local().format(dateFormat)
      );
      if (index === -1) {
        const dayWeek = moment(addAttendaceDate).isoWeekday();
        let validateDay = selectedGroup.schedules.find(
          (schedule) => schedule.day === dayWeek - 1
        );

        if (validateDay !== undefined) {
          closeLoading = LoadingMessage("Agregando...");
          setDisableAdd(true);

          const body = {
            date: addAttendaceDate,
            group: selectedGroup._id,
            attendance: status,
          };
          return fetchAsync(
            urlAttendance + "/st/" + dataAlumno._id,
            JSON.stringify(body),
            "POST",
            ""
          )
            .then((result) => {
              if (result.success) {
                let array = [...attendaceInfo.attendance];
                array.push(result.attendance);
                const sortArrayDates = array.sort(
                  (a, b) => moment(b.date) - moment(a.date)
                );
                setAttendaceInfo({
                  ...attendaceInfo,
                  attendance: sortArrayDates,
                });
              }
              closeLoading();
              setDisableAdd(false);
            })
            .catch((reason) => {
              console.log(reason.message);
              closeLoading();
              setDisableAdd(false);
            });
        } else {
          showMessageWarning(
            "¡Atención! El dia que seleccionaste no coincide con el horario del grupo.",
            3
          );
        }
      } else {
        showMessageWarning(
          "¡Atención! Ya existe un registro del día seleccionado",
          3
        );
      }
    } else {
      showMessageWarning("¡Atención! debes seleccionar una fecha", 3);
    }
  };

  const updateAttendance = (body) => {
    closeLoading = LoadingMessage("Actualizando...");
    setDisableCheck(true);
    fetchAsync(
      urlAttendanceSingle + dataAlumno._id,
      JSON.stringify(body),
      "PUT"
    )
      .then((result) => {
        if (result.success) {
          // console.log(result)
          let array = [...attendaceInfo.attendance];
          let index = array.findIndex((item) => item.date === result.data.date);
          array[index] = result.data;
          setAttendaceInfo({
            ...attendaceInfo,
            attendance: array,
          });
        }
        closeLoading();
        setDisableCheck(false);
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const deleteAttendance = (idAtendance) => {
    // closeLoading = LoadingMessage("Eliminando asistencia..")
    fetchAsync(
      urlAttendanceSingle + dataAlumno._id + "?attendanceId=" + idAtendance,
      "",
      "DELETE",
      ""
    )
      .then((result) => {
        if (result.success) {
          let newAttendaceArray = attendaceInfo.attendance.filter((item) =>
            item.hasOwnProperty("_id")
              ? item._id !== idAtendance
              : item.attendanceId !== idAtendance
          );
          setAttendaceInfo({
            ...attendaceInfo,
            attendance: newAttendaceArray,
          });
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const nextTable = (group) => {
    setSelectedGroup(group);
    getStudentAttendnceByGroup(group._id);
  };

  const back = () => {
    setSelectedGroup("");
    setAttendaceInfo("");
    setAddAttendaceDate("");
  };

  const LoadingMessage = (message) => {
    return showMessageLoading(message, 0);
  };

  const showTables = () => {
    if (selectedGroup !== "") {
      return attendaceInfo !== "" ? (
        <TableAttendance
          attendaceInfo={attendaceInfo}
          selectedGroup={selectedGroup}
          setAttendanceByStudent={setAttendanceByStudent}
          setAddAttendaceDate={setAddAttendaceDate}
          setStatus={setStatus}
          status={status}
          back={back}
          updateAttendance={updateAttendance}
          disableCheck={disableCheck}
          deleteAttendance={deleteAttendance}
          disableAdd={disableAdd}
        />
      ) : (
        <LoadingComponent />
      );
    } else {
      return (
        <TableCourses
          groupsArray={groupsArray}
          empty={empty}
          nextTable={nextTable}
        />
      );
    }
  };

  const createAttendanceEmployee = () => {
    if (!disableAdd && addAttendaceDate !== "") {
      const index = attendaceInfoEmployee.attendance.findIndex(
        (attendance) =>
          moment.utc(attendance.date).local().format(dateFormat) ===
          moment.utc(addAttendaceDate).local().format(dateFormat)
      );
      if (index === -1) {
        const body = {
          date: addAttendaceDate,
          attendance: status,
        };
        createAttendance(body);
      } else {
        showMessageWarning(
          "¡Atención! Ya existe un registro del día seleccionado",
          3
        );
      }
    } else {
      showMessageWarning("¡Atención! debes seleccionar una fecha", 3);
    }
  };

  return (
    <div className={styles.attendance_main_container}>
      {!employee && (
        <section className={`mb-2 ${styles.button_container}`}>
          <Button
            size="sm"
            className={`btn btn-secondary float-right`}
            onClick={onCloseModal}
          >
            Salir
          </Button>
        </section>
      )}
      <div className="mt-1">
        {employee ? (
          <TableAttendance
            dataAlumno={dataAlumno}
            attendaceInfo={attendaceInfoEmployee}
            selectedGroup={() => ""}
            setAttendanceByStudent={createAttendanceEmployee}
            setAddAttendaceDate={setAddAttendaceDate}
            setStatus={setStatus}
            status={status}
            back={back}
            updateAttendance={updateAttendance}
            disableCheck={disableCheck}
            deleteAttendance={deleteAttendance}
            disableAdd={disableAdd}
            employee={employee}
            updateAttendanceEmployee={updateAttendanceEmployee}
            deleteAttedanceEmployee={deleteAttedanceEmployee}
          />
        ) : (
          showTables()
        )}
      </div>
    </div>
  );
};
export default Attendance;
