import {
  SELECTED_ROLE,
  GET_ALL_ROLES,
  DELETE_ROLES,
  UPDATE_ROLE,
  UPDATE_AFTER_CREATE_ROLE,
  USERS_IN_ROLE,
  RESET_STATES_ROLES,
} from '../types/index';


const Reducer = (state, action) => {

  switch (action.type) {

    case SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      }
    case GET_ALL_ROLES:
      return {
        ...state,
        allRoles: action.payload,
      }
    case UPDATE_AFTER_CREATE_ROLE:
      return {
        ...state,
        allRoles: [...state.allRoles, action.payload]
      }
    case UPDATE_ROLE:
      const indexUpdate = state.allRoles.findIndex((obj => obj._id === action.payload._id));
      let newArray = [...state.allRoles]
      newArray[indexUpdate] = action.payload
      return {
        ...state,
        allRoles: newArray,
        selectedRole: action.payload
      }
    case DELETE_ROLES:
      return {
        ...state,
        allRoles: state.allRoles.filter(role => role._id !== action.payload),
        selectedRole: {}
      }
    case USERS_IN_ROLE:
      return {
        ...state,
        roleUsers: action.payload
      }
    case RESET_STATES_ROLES:
      return {
        ...state,
        roleUsers: [],
        selectedRole: {},
      }

    default:
      return state;
  }
}
export default Reducer;