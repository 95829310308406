import React from "react";

export const month = [];
month[0] = "Enero";
month[1] = "Febrero";
month[2] = "Marzo";
month[3] = "Abril";
month[4] = "Mayo";
month[5] = "Junio";
month[6] = "Julio";
month[7] = "Agosto";
month[8] = "Septiembre";
month[9] = "Octubre";
month[10] = "Noviembre";
month[11] = "Diciembre";

export const semana = [];
semana[0] = "Lunes";
semana[1] = "Martes";
semana[2] = "Miercoles";
semana[3] = "Jueves";
semana[4] = "Viernes";
semana[5] = "Sabado";
semana[6] = "Domingo";

export const semanaLetra = [];
semanaLetra[0] = "l";
semanaLetra[1] = "m";
semanaLetra[2] = "w";
semanaLetra[3] = "j";
semanaLetra[4] = "v";
semanaLetra[5] = "s";
semanaLetra[6] = "d";

export const semanaDias = [];
semanaDias[0] = "Lu";
semanaDias[1] = "Ma";
semanaDias[2] = "Mi";
semanaDias[3] = "Ju";
semanaDias[4] = "Vi";
semanaDias[5] = "Sa";
semanaDias[6] = "Do";

//api key para el servicio de maps de google
export const ApiKey = "AIzaSyCTEPZpr-eTHvg_9kUYUI_g0F-FzsAqNNQ";
export const url =
  "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" +
  ApiKey;

export const getAdgress =
  "https://maps.googleapis.com/maps/api/geocode/json?latlng=";

// return a color value
export const randomColor = (letter) => {
  switch (letter) {
    case "A":
    case "F":
    case "K":
    case "T":
      return "#f56a00";
    case "B":
    case "G":
    case "L":
    case "P":
      return "#7265e6";
    case "C":
    case "H":
    case "M":
    case "Q":
    case "U":
      return "#ffbf00";
    case "D":
    case "I":
    case "N":
    case "R":
      return "#00a2ae";
    case "E":
    case "J":
    case "O":
    case "S":
      return "#E6EE9C";
    default:
      return "#5DADE2";
  }
};

////QUIZZES///
export const attemps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const instructions = (
  <div>
    <ul>
      <li>
        Para poder agregar video-respuestas a las preguntas del cuestionario es
        importante primero agregar de mínimo una pregunta o agregar todas tus
        preguntas y guardar dicho cuestionario, solo entonces el botón de
        "agregar vídeos" se desbloqueara.
      </li>
      <li>
        El selector de "intentos" se refiere al numero de veces que tendrán los
        alumnos para resolver un examen. Por ejemplo si seleccionas el numero
        "3" los alumnos podrán resolver el examen 3 veces como máximo.
      </li>
      <li>
        El campo de "Preguntas a mostrar por examen" se refiere a que del total
        de preguntas creadas se mostrara la cantidad que tu especifiques ahí.
        Por ejemplo si creo un cuestionario de 20 preguntas y en el campo de
        "Preguntas a mostrar por examen" ingreso el numero "15" entonces a los
        alumnos se les mostraran solo 15 preguntas aleatorias del total de las
        20 que tu agregaste.
      </li>
      <li>
        Si no ingresas ningún numero entonces se les mostrara el total de
        preguntas creadas.
      </li>
    </ul>
  </div>
);

export const timeIns = (
  <div>
    <ul>
      <li>
        Para agregar el tiempo que tendrán para resolver un examen selecciona el
        recuadro para desbloquear el selector de tiempo.
      </li>
      <li>Ingresa el tiempo en horas minutos y segundos.</li>
      <li>
        Si quieres borrar o no quieres agregar un limite de tiempo solo des
        selecciona el recuadro azul y lo que ayas agregado se borrara y el
        examen no tendrá ningún tiempo limite.
      </li>
    </ul>
  </div>
);

export const reorderquestions = (
  <div>
    <ul>
      <li>Reordena las preguntas y presiona guardar.</li>
      <li>Si no quieres guardar los cambios solo da click en salir.</li>
    </ul>
  </div>
);
