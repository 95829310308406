import React, { useReducer, createContext } from "react";
import {
  showMessageLoading,
  showMessageSuccess,
  showMessageError,
} from "helpers/MessageAndNotificationUtils";
import {
  fetchAsync,
  urlGroups,
  urlCourseLevels,
  urlGroupsLite,
  urlUsers,
  urlSchedules,
  urlStudenDelete,
  urlSedes,
  urlAttendance,
  urlAttendanceSingle,
} from "helpers/Globals/globals";
import {
  SIDEBAR_ALL_DATA,
  LEVELS,
  COURSES_MEDIA,
  COURSES_SUPERIOR,
  GET_ALL_GROUPS,
  DISABLE_SWITCH_GROUP,
  ACTUAL_GROUP,
  TEACHERS,
  LOADING_SKELETON_GROUPS,
  SET_SEARCH_NAME,
  SEARCH_GROUPS_RESULT,
  EMPTY_SEARCH,
  GROUP_STUDENTS,
  GROUP_LOCATIONS,
  EMPTY_RESULT_GROUPS,
  DELETE_GROUP,
  DELETE_GROUP_STUDENT,
  UPDATE_TABLE_ROW,
  UPDATE_TABLE_CREATE,
  UPDATE_TABLE_STATUS,
  UPDATE_TABLE_STUDENTS_COMMENT_DELETE,
  UPDATE_TABLE_STUDENTS_ADD_COMMENT,
  UPDATE_TABLE_STUDENTS_UPDATE_COMMENT,
  UPDATE_ATTENDANCE_SIDEBAR,
  SET_URL_STRING,
  SET_URL_ARRAY,
} from "../types/index";

import GroupsReducer from "./GroupsReducer";
import "moment/locale/es";

let closeLoading = "";

const GroupsContext = createContext();

const GroupsState = (props) => {
  const initialState = {
    mediaLevel: "",
    superiorLevel: "",
    coursesMedia: [],
    coursesSuperior: [],
    allGroups: [],
    disableSwitch: false,
    actualGroup: {},
    teachers: [],
    loadingSkeleton: true,
    searchName: "",
    searchGroupsResult: [],
    emptySearch: false,
    actualGroupStudentsFullInfo: [],
    locations: [],
    resultGorups: false,
    filterUrlString: "?",
    filterUrlArray: [],
    filtersData: [],
  };

  //crear dispatch y state
  const [state, dispatch] = useReducer(GroupsReducer, initialState);

  const setUrlFilters = (stringUrl) => {
    dispatch({
      type: SET_URL_STRING,
      payload: stringUrl,
    });
  };

  const setActualGroupRowData = (infoGroup) => {
    dispatch({
      type: ACTUAL_GROUP,
      payload: infoGroup,
    });
  };

  const setAndGetSidebarData = (rowData) => {
    setloadingSkeleton(true);
    return fetchAsync(urlGroups + rowData._id + "/sidebar", "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: SIDEBAR_ALL_DATA,
            payload: data.group,
          });
        }
        setloadingSkeleton(false);
        return data;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //estado para mostrar el loading skeleton en lo que se carga la info del grupo
  const setloadingSkeleton = (status) => {
    dispatch({
      type: LOADING_SKELETON_GROUPS,
      payload: status,
    });
  };

  //fetch para obtener todos los profesores
  const getUsers = () => {
    return fetchAsync(urlUsers, "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: TEACHERS,
            payload: result.users,
          });
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //obtiene los ids de niveles media y superior despues genera los fetch para traer los cursos de media y superior
  const getCourseLevels = () => {
    if (state.mediaLevel !== "" && state.superiorLevel !== "") {
      getCoursesMedia(state.mediaLevel._id);
      getCoursesSuperior(state.superiorLevel._id);
    } else {
      fetchAsync(urlCourseLevels, "", "GET", "")
        .then((data) => {
          if (data.success) {
            //obtenemos los cursos de media y superior
            let mediaLevel = data.courseLevels.find(
              (data) => data.name === "Media Superior"
            );
            let superiorLevel = data.courseLevels.find(
              (data) => data.name === "Superior"
            );
            getCoursesMedia(mediaLevel._id);
            getCoursesSuperior(superiorLevel._id);
            dispatch({
              type: LEVELS,
              payload: data.courseLevels,
            });
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };

  //obtiene los cursos de media y susperior
  const getCoursesAddGroup = () => {
    getCoursesMedia(state.mediaLevel._id);
    getCoursesSuperior(state.superiorLevel._id);
  };

  //metodo que trae todos los cursos de media
  const getCoursesMedia = (idMedia) => {
    fetchAsync(urlCourseLevels + idMedia + "/courses", "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: COURSES_MEDIA,
            payload: data.courses.reverse(),
          });
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //metodo que trae todos los cursos de Superior
  const getCoursesSuperior = (idSuperior) => {
    fetchAsync(urlCourseLevels + idSuperior + "/courses", "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: COURSES_SUPERIOR,
            payload: data.courses.reverse(),
          });
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //fetch para obtener todos los grupos
  const getAllGroups = (searchDate) => {
    return fetchAsync(urlGroupsLite + searchDate, "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: GET_ALL_GROUPS,
            payload: result.groups,
          });
          //si hay grupos
          emptyResultGroups(result.groups.length === 0 ? true : false);
          //si existe una busqueda en curso actualizamos los resulados
          if (state.searchName !== "") {
            let resultado = [];
            const cloneGroups = result.groups.map((a) => ({ ...a }));
            cloneGroups.forEach((valor, index) => {
              if (
                valor.name
                  .toUpperCase()
                  .includes(state.searchName.toUpperCase())
              ) {
                resultado.push(valor);
              }
            });
            resultSearchedGroups(resultado);
          }
          return result;
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const emptyResultGroups = (status) => {
    dispatch({
      type: EMPTY_RESULT_GROUPS,
      payload: status,
    });
  };

  //cambiar el status de un grupo
  const changeStatusGroup = (idGroup) => {
    disableSwitchTable(true);
    return fetchAsync(urlGroups + idGroup + "/active/toggle", "", "PUT")
      .then((data) => {
        if (data.success) {
          updateStatusRow(idGroup, data.active);
          showMessageSuccess("Estatus actualizado exitosamente", 2);
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //recarga el status cambiado en la tabla y la ficha
  const updateStatusRow = (idGroup, status) => {
    const obj = {
      idGroup,
      status,
    };
    dispatch({
      type: UPDATE_TABLE_STATUS,
      payload: obj,
    });
  };

  //obtiene la información del grupo seleccionado en la tabla
  const getChildsinfo = (id) => {
    setloadingSkeleton(true);
    return fetchAsync(urlGroups + id + "/sidebar", "", "GET", "")
      .then((data) => {
        setloadingSkeleton(false);
        return data;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //bloquea el switch en la tabla mientras se esta actualizando algun cambio de estado
  const disableSwitchTable = (status) => {
    dispatch({
      type: DISABLE_SWITCH_GROUP,
      payload: status,
    });
  };

  //setea el resultado de los grupos buscados por nombre
  const resultSearchedGroups = (arrayGroups) => {
    dispatch({
      type: SEARCH_GROUPS_RESULT,
      payload: arrayGroups,
    });
  };

  //guarda el nombre a buscar
  const searchGroup = (name) => {
    let resultado = [];
    if (name.length === 0) {
      setEmptySearch(false);
      resultSearchedGroups(resultado);
    }
    if (name.length > 0) {
      const cloneGroups = state.allGroups.map((a) => ({ ...a }));
      cloneGroups.forEach((valor, index) => {
        if (valor.name.toUpperCase().includes(name.toUpperCase())) {
          resultado.push(valor);
        }
      });
      resultSearchedGroups(resultado);
      if (resultado.length === 0 && name.length > 0) setEmptySearch(true);
      if (resultado.length > 0) setEmptySearch(false);
    }
    dispatch({
      type: SET_SEARCH_NAME,
      payload: name,
    });
  };

  const setEmptySearch = (value) => {
    dispatch({
      type: EMPTY_SEARCH,
      payload: value,
    });
  };

  //revisa si el salón esta disponible
  const availableClassroom = (body) => {
    return fetchAsync(
      urlSchedules + "/is/available",
      JSON.stringify(body),
      "POST"
    )
      .then((data) => {
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //ejecuta el servicio para crear grupo
  const addGroup = (body) => {
    return fetchAsync(urlGroups, JSON.stringify(body), "POST")
      .then((data) => {
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const updateCreate = (newRowInfo) => {
    dispatch({
      type: UPDATE_TABLE_CREATE,
      payload: newRowInfo,
    });
  };

  //actualizar un grupo
  const editGroup = (body) => {
    return fetchAsync(
      urlGroups + state.actualGroup._id,
      JSON.stringify(body),
      "PUT"
    )
      .then((data) => {
        if (data.success) {
          dispatch({
            type: UPDATE_TABLE_ROW,
            payload: data.group,
          });
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //funcion para eliminar un grupo
  const deleteGroup = () => {
    return fetchAsync(urlGroups + state.actualGroup._id, "", "DELETE", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: DELETE_GROUP,
            payload: data,
          });
        } else {
          showMessageError("Ocurrio un error al borrar el grupo", 4);
        }
        return data;
      })
      .catch((reason) => {
        showMessageError("Ocurrio un error al borrar el grupo", 4);
      });
  };

  ////Funciones para el modal alumnos por grupo tabla////////
  const getStudentsByGroup = () => {
    return fetchAsync(
      urlGroups + state.actualGroup._id + "/students",
      "",
      "GET",
      ""
    )
      .then((result) => {
        if (result.success) {
          dispatch({
            type: GROUP_STUDENTS,
            payload: result.students,
          });
        }
        return result;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //funcion para eliminar un alumno de un grupo
  const deleteStudentGroup = (idStudent) => {
    closeLoading = showMessageLoading("Eliminando registro..");
    fetchAsync(
      urlStudenDelete + idStudent + "/group/" + state.actualGroup._id,
      "",
      "DELETE",
      ""
    )
      .then((result) => {
        if (result.success) {
          dispatch({
            type: DELETE_GROUP_STUDENT,
            payload: {
              studentId: idStudent,
              groupId: state.actualGroup._id,
            },
          });
          closeLoading();
        } else {
          closeLoading();
          showMessageError(
            "Ocurrio un error y no se pudo eliminar el registro.",
            2
          );
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };
  const onDeleteStudent = (idStudent, groupId) => {
    dispatch({
      type: DELETE_GROUP_STUDENT,
      payload: {
        studentId: idStudent,
        groupId: groupId,
      },
    });
  };

  const forwardEnrollmentTicket = (studentId) => {
    return fetchAsync(
      urlGroups + state.actualGroup._id + "/receipt/" + studentId,
      "",
      "POST",
      ""
    )
      .then((result) => {
        return result;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const updateAfterAddComment = (studentId, comment) => {
    dispatch({
      type: UPDATE_TABLE_STUDENTS_ADD_COMMENT,
      payload: {
        studentId,
        comment: comment,
      },
    });
  };

  const updateAfterEditComment = (studentId, comment) => {
    dispatch({
      type: UPDATE_TABLE_STUDENTS_UPDATE_COMMENT,
      payload: {
        studentId,
        comment: comment,
      },
    });
  };

  const updateAfterDeleteComment = (studentId, comment) => {
    console.log("Enter al update en el context");
    dispatch({
      type: UPDATE_TABLE_STUDENTS_COMMENT_DELETE,
      payload: {
        studentId,
        comment: comment,
      },
    });
  };
  ////FIN DE las Funciones para el modal alumnos por grupo////////

  //obtiene todas las sedes
  const getAllLocations = () => {
    fetchAsync(urlSedes, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GROUP_LOCATIONS,
            payload: data.locations,
          });
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  ////attendance/////
  const setAttendanceByStudent = (body) => {
    closeLoading = showMessageLoading("Actualizando asistencia ...");
    return fetchAsync(
      urlAttendance + "/student/today",
      JSON.stringify(body),
      "PUT",
      ""
    )
      .then((result) => {
        closeLoading();
        if (result.success) {
          dispatch({
            type: UPDATE_ATTENDANCE_SIDEBAR,
            payload: result,
          });
        }
        return result;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const updateAttendance = (body, studentId) => {
    return fetchAsync(
      urlAttendanceSingle + studentId,
      JSON.stringify(body),
      "PUT"
    )
      .then((result) => {
        if (result.success) {
          dispatch({
            type: UPDATE_ATTENDANCE_SIDEBAR,
            payload: result.data,
          });
        }
        return result;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const value = {
    ...state,
    setAndGetSidebarData,
    getCourseLevels,
    getCoursesAddGroup,
    getAllGroups,
    changeStatusGroup,
    setActualGroupRowData,
    getUsers,
    getChildsinfo,
    searchGroup,
    availableClassroom,
    addGroup,
    updateCreate,
    editGroup,
    deleteGroup,
    getStudentsByGroup,
    deleteStudentGroup,
    onDeleteStudent,
    forwardEnrollmentTicket,
    updateAfterDeleteComment,
    updateAfterAddComment,
    updateAfterEditComment,
    getAllLocations,
    setAttendanceByStudent,
    updateAttendance,
    setUrlFilters,
  };
  return (
    <GroupsContext.Provider value={value}>
      {props.children}
    </GroupsContext.Provider>
  );
};
export { GroupsContext };
export default GroupsState;
