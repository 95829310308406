import React, { useState, useEffect } from 'react';
import LoadingComponent from '../../../../helpers/LoadingComponent'
import { Row, Col } from 'reactstrap'
import EmptyComponent from '../../../../helpers/Empty/EmptyComponent'
import { fetchAsync, urlPlaylist, urlPlaylistByCourse } from '../../../../helpers/Globals/globals'
import { Droppable } from 'react-beautiful-dnd';
import ChaptersContentPlaylist from '../chaptersContentPlaylist/index';
import { Breadcrumb } from 'antd';
import "antd/es/breadcrumb/style/index.css"

const ContentList = ({ idCourse, idPlayList, setPlaylistId, list, setList, listId, disabledDrop, openModalVideoView }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [stackNav, setStackNav] = useState([])
    const [parent, setParent] = useState()

    useEffect(() => {
        console.log("ContentList")
        if (listId === "XDA2") {
            getChapterContent(idCourse);
        } else {
            getPlaylistContent(idPlayList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickChild = (item) => {
        let copy = [...stackNav]
        copy.push(item)
        setStackNav(copy)
        setIsLoading(true)
        getPlaylistContent(item._id)
    }

    const onClickBack = () => {
        let copy = [...stackNav]
        copy.pop()
        if (copy.length > 0) {
            let id = copy[copy.length - 1]
            setStackNav(copy)
            setIsLoading(true)
            getPlaylistContent(id._id)
        } else {
            setStackNav([])
            setIsLoading(true)
            if (listId === "XDA2") {
                getChapterContent(idCourse)
            } else {
                getPlaylistContent(parent ? parent : idPlayList)
            }
        }
    }

    const getChapterContent = (id) => {
        fetchAsync(urlPlaylistByCourse + id, "", "GET")
            .then(
                (data) => {
                    setPlaylistId(id)
                    setList(data.playlists)
                    setIsLoading(false)
                }
            ).catch(err => {
                console.log(err)
            })
    }

    const getPlaylistContent = (id) => {
        fetchAsync(urlPlaylist + id, "", "GET")
            .then(
                (data) => {
                    setPlaylistId(id)
                    setList(data.playlist.contentPlaylist)
                    setParent(data.playlist.parent)
                    setIsLoading(false)
                }
            ).catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            <Row>
                <Col xs="1" className="col_button_back">
                    <button className="material-icons return_button" onClick={() => onClickBack()}>keyboard_arrow_left</button>
                </Col>
                <Col xs="11" className="breadcrumb_item" >
                    <Breadcrumb >
                        {stackNav.map((item) => {
                            return (
                                <Breadcrumb.Item>
                                    {item.name}
                                </Breadcrumb.Item>
                            )
                        })}
                    </Breadcrumb>
                </Col>
            </Row>

            <Droppable droppableId={disabledDrop ? "x" + listId : idPlayList} isDropDisabled={disabledDrop}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {isLoading ? <LoadingComponent /> :
                            list.length < 1 ? <EmptyComponent message="Aún no hay material en este cápitulo , Agrega material y lo visualizarás aquí" /> :
                                list.map((item, index) => {
                                    return <ChaptersContentPlaylist key={index} contentInfo={item}
                                        openViewVideo={() => ""} idDD={index} saveSelectedChapter={onClickChild}
                                        openEdit={() => ""} removeItemPlaylist={() => ""} openEditVideo={() => ""}
                                        selectedChapter={() => ""} moveMaterial={true}
                                    />
                                })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </>
    )
}
export default ContentList



