/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import LogInForm from './components/LogInForm';
const Logo = `${process.env.PUBLIC_URL}/img/pipn.png`;

const LogIn = () => {
  
  return(
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <div className="row">
            {/* <img src={Logo} alt="Profile" /> */}
            <h2>Preparación Politécnico</h2>
          </div>
        </div>
        <div className="account__or">
          <p></p>
        </div>
        <LogInForm/>
      </div>
      <div className="account__credits mt-1">
        <p>Copyrights © Preparación Politécnico</p>
      </div>
    </div>
  </div>
  )
};

export default LogIn;
