import React from "react";
import {
  Table,
  Tag,
  DatePicker,
  Breadcrumb,
  Popconfirm,
  Switch,
  Tooltip,
  Checkbox,
} from "antd";
import { Button } from "reactstrap";
import moment from "moment";
import "moment/locale/es";

import DateDayMonth from "../../../../../../helpers/Fichas/dateDayMonth";
import { newSchedules } from "../../../../../../helpers/Globals/funciones";
import Can from "../../../../../../helpers/Secure/Can";

import { ArrowLeftOutlined } from "@ant-design/icons";

import styles from "./index.module.scss";
import "antd/lib/tooltip/style/index.css";
import "antd/es/switch/style/index.css";
import "antd/es/checkbox/style/index.css";

const dateFormat = "DD/MM/YYYY";
const instructions = (
  <div>
    <ul>
      <li>
        Para agregar una asistencia selecciona el día y estatus y presiona el
        botón de agregar.
      </li>
      <li>
        Recuerda asegurarte que la asistencia a agregar concuerde con los días y
        fecha en que el alumno está tomando su curso.
      </li>
    </ul>
  </div>
);
const instructionsEmployee = (
  <div>
    <ul>
      <li>
        Para agregar una asistencia selecciona el día y estatus y presiona el
        botón de agregar.
      </li>
    </ul>
  </div>
);

const TableAttendance = ({
  attendaceInfo,
  selectedGroup,
  back,
  setAttendanceByStudent,
  disableAdd,
  setAddAttendaceDate,
  setStatus,
  status,
  updateAttendance,
  disableCheck,
  deleteAttendance,
  employee,
  dataAlumno = "",
  updateAttendanceEmployee,
  deleteAttedanceEmployee,
}) => {
  const showStatus = (cell) => {
    if (cell) {
      return (
        <Tag color={"green"} key={cell}>
          Asistencia
        </Tag>
      );
    } else {
      return (
        <Tag color={"red"} key={cell}>
          Falta
        </Tag>
      );
    }
  };

  const showDate = (cell) => {
    return moment.utc(cell).local().format(dateFormat);
  };

  const editAtendance = (e) => {
    // console.log(e)
    const body = {
      date: e.target.name,
      attendance: e.target.checked,
      attendanceId: e.target.value,
    };
    if (!employee) {
      updateAttendance(body);
    } else {
      updateAttendanceEmployee(body);
    }
  };

  const actions = (cell, row) => {
    return (
      <div className={styles.div_actions_attendance}>
        <Can
          do="put"
          on={!employee ? "/attendance/single/?" : "/attendance/user/?"}
        >
          <Checkbox
            name={row.date}
            disabled={disableCheck}
            value={row.hasOwnProperty("_id") ? row._id : row.attendanceId}
            onChange={editAtendance}
            checked={row.attendance}
          />
          <Popconfirm
            placement="topLeft"
            title="¿Seguro que deseas borrar la asistencia?"
            onConfirm={() => {
              if (!employee) {
                deleteAttendance(
                  row.hasOwnProperty("_id") ? row._id : row.attendanceId
                );
              } else {
                deleteAttedanceEmployee(
                  row.hasOwnProperty("_id") ? row._id : row.attendanceId
                );
              }
            }}
            okText="Si"
            cancelText="No"
          >
            <i className={`material-icons ${styles.icon_delete}`}>delete</i>
          </Popconfirm>
        </Can>
      </div>
    );
  };

  //muestra el numero de asistencias y faltas
  const getNumAttendace = () => {
    let asistencias = 0;
    let faltas = 0;
    if (attendaceInfo.attendance) {
      attendaceInfo.attendance.forEach((value) => {
        if (value.attendance === true) {
          asistencias = asistencias + 1;
        } else {
          faltas = faltas + 1;
        }
      });
      return (
        <>
          <p>Asistencias: {asistencias}</p>
          <p className="ml-3">Faltas: {faltas}</p>
        </>
      );
    }
  };

  const schedules_date = () => {
    const newArray =
      selectedGroup.schedules !== undefined ? selectedGroup.schedules : [];
    return (
      <>
        <p className="mr-3">
          Duración:{" "}
          {DateDayMonth(selectedGroup.startDate, selectedGroup.endDate)}
        </p>
        <p>
          Horario:{" "}
          {selectedGroup.schedules !== undefined ? newSchedules(newArray) : ""}
        </p>
      </>
    );
  };

  const onChangeDate = (date, dateString) => {
    let local = moment.utc(date).local().format();
    setAddAttendaceDate(local);
  };

  const onChangeSwitch = (checked) => {
    setStatus(checked);
  };

  const disabledDate = (current) => {
    return !employee
      ? current < moment(selectedGroup.startDate) ||
          current > moment(new Date())
      : current > moment(new Date());
    // return current < moment(selectedGroup.startDate) || current > moment(selectedGroup.endDate).add(1, 'd');
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: showDate,
    },
    {
      title: "Estatus",
      dataIndex: "attendance",
      key: "attendance",
      render: showStatus,
      align: "left",
    },
    {
      title: "Acciones",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: actions,
    },
  ];

  return (
    <div className={styles.attendance_table}>
      {!employee ? (
        <>
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item onClick={back}>
              <ArrowLeftOutlined style={{ cursor: "pointer" }} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{selectedGroup?.courseInfo?.name}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Grupo: {selectedGroup?.name}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.data_attendance}>{getNumAttendace()}</div>
          <div className={styles.schedules_date}>{schedules_date()}</div>
        </>
      ) : (
        <h3 className="mb-4">{`${dataAlumno?.name} ${dataAlumno?.lastName} ${dataAlumno?.secondLastName}`}</h3>
      )}
      <Can
        do="post"
        on={!employee ? "/attendance/st/?" : "/attendance/users/?"}
      >
        <div className={styles.tooltip_attendance}>
          <Tooltip
            title={employee ? instructionsEmployee : instructions}
            placement="rightTop"
          >
            <i className={`material-icons`}>info</i>
          </Tooltip>
          <DatePicker
            className="date_picker_attendace"
            onChange={onChangeDate}
            format="DD-MM-YYYY"
            disabledDate={disabledDate}
            placeholder={"Fecha"}
          />
          <Switch
            checked={status}
            style={{
              backgroundColor: status ? "#2ecc71" : "#e74c3c",
              marginLeft: "20px",
            }}
            onChange={onChangeSwitch}
            checkedChildren="Asistencia"
            unCheckedChildren="Falta"
          />
          <Button
            size="sm"
            className="btn float-right"
            disabled={disableAdd}
            color="success"
            style={{ marginTop: "3px" }}
            onClick={setAttendanceByStudent}
          >
            <p>Agregar asistencia</p>
          </Button>
        </div>
      </Can>
      <Table
        rowKey="date"
        // scroll={{ x: showScroll(), y: showScroll() }}
        pagination={false}
        columns={columns}
        dataSource={attendaceInfo.attendance}
        locale={{
          emptyText: `No hay asistencias registradas del ${
            !employee ? "alumno en el grupo" : "empleado"
          }.`,
        }}
      />
    </div>
  );
};
export default TableAttendance;
