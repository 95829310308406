import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Button, ButtonToolbar } from "reactstrap";
import { Select, DatePicker, Switch } from "antd";
import moment from "moment";
import "moment/locale/es";

import ModalEditCourse from "./modalEditCourse";
import SidebarContext from "context/sidebar/SidebarContext";
import { GroupsContext } from "context/groups";
import ModalConfirmation from "containers/Modal/ModalConfirmation/index";
import SelectClassroomLocation from "helpers/classromLocation/index";
import { showMessageWarning } from "helpers/MessageAndNotificationUtils";
import SelectSchedules from "helpers/selectSchedules/index";
import Can from "helpers/Secure/Can";
import { semana } from "helpers/Fichas/constants";

import Title from "./title";

import locale from "antd/es/date-picker/locale/es_ES";
import DeleteIcon from "mdi-react/DeleteIcon";
import ClassIcon from "mdi-react/ClassIcon";
import UsersIcon from "mdi-react/UsersIcon";
import TeacherIcon from "mdi-react/TeacherIcon";
import BuildingIcon from "mdi-react/BuildingIcon";

import styles from "./index.module.scss";
import "antd/lib/date-picker/style/index.css";
import "antd/es/date-picker/style/index.css";
import "antd/es/switch/style/index.css";

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;

const inicialState = {
  name: "",
  startDate: moment.utc(new Date()).local().format(),
  endDate: moment.utc(new Date()).local().format(),
  schedules: [],
  active: true,
  activeLanding: false,
  teacher: "",
  quota: "",
  course: "",
  classRoom: "",
  location: "",
};

const adicionalState = {
  fullClassRoom: null,
  fullLocation: null,
  fullCourse: null,
};

const EditForm = (props) => {
  //context
  const sidebarContext = useContext(SidebarContext);
  const { killSideBar } = sidebarContext;
  const groupsContext = useContext(GroupsContext);
  const { teachers, getUsers, actualGroup, deleteGroup } = groupsContext;

  //states
  const [modalDelete, setModalDelete] = useState(false);
  const [modalCourse, setModalCourse] = useState(false);
  const [modalSchedules, setModalSchedules] = useState(false);
  const [modalClassroomLocation, setModalClassroomLocation] = useState(false);
  const [editGroupData, setEditGroupData] = useState(inicialState);
  const [adicionalDataGroup, setAdicionalDataGroup] = useState(adicionalState);

  useEffect(() => {
    if (teachers === undefined || teachers.length < 1) {
      getUsers();
    }
    setEditGroupData({
      ...editGroupData,
      name: actualGroup?.name,
      startDate: moment.utc(new Date(actualGroup.startDate)).local().format(),
      endDate: moment.utc(new Date(actualGroup.endDate)).local().format(),
      schedules: actualGroup.schedules.map((a) => ({ ...a })),
      active: actualGroup?.active,
      activeLanding: actualGroup.hasOwnProperty("activeLanding")
        ? actualGroup?.activeLanding
        : false,
      teacher: actualGroup.hasOwnProperty("teacher")
        ? actualGroup.teacher?._id
        : "",
      quota: actualGroup?.quota,
      course: actualGroup?.course?._id,
      classRoom: actualGroup.hasOwnProperty("classRoom")
        ? actualGroup?.classRoom?._id
        : "",
      location: actualGroup.hasOwnProperty("classRoom")
        ? actualGroup?.classRoom?.location?._id
        : "",
    });

    setAdicionalDataGroup({
      ...adicionalDataGroup,
      fullClassRoom: actualGroup.hasOwnProperty("classRoom")
        ? actualGroup?.classRoom
        : null,
      fullLocation: actualGroup.hasOwnProperty("classRoom")
        ? actualGroup?.classRoom?.location
        : null,
      fullCourse: actualGroup.hasOwnProperty("course")
        ? actualGroup?.course
        : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //guarda el salón y sede seleccionados
  const saveLocationClassrom = (data) => {
    //actualiza solo los ids en la nueva información de editar
    setEditGroupData({
      ...editGroupData,
      location: data[0]._id,
      classRoom: data[1]._id,
      quota: data[1].capacity,
    });
    //guarda toda la información del salón y sede
    setAdicionalDataGroup({
      ...adicionalDataGroup,
      fullLocation: data[0],
      fullClassRoom: data[1],
    });
  };

  //guarda el curso seleccionado
  const saveSelectedCourse = (data) => {
    if (data.hasOwnProperty("isParent"))
      return showMessageWarning(
        <strong>
          ¡Atención! Este curso no es compatible con el grupo actual. No se
          puede cambiar.
        </strong>,
        3
      );
    //actualiza solo el id del curso
    setEditGroupData({
      ...editGroupData,
      course: data._id,
    });
    //guarda toda la información del curso
    setAdicionalDataGroup({
      ...adicionalDataGroup,
      fullCourse: data,
    });
    setModalCourse(false);
  };

  const onChamgeInputs = (e) => {
    setEditGroupData({
      ...editGroupData,
      [e.target.name]: e.target.value,
    });
  };

  //funciones para el calendario
  const onChangeStartdate = (date, dateString) => {
    let local = moment.utc(date).local().format();
    setEditGroupData({
      ...editGroupData,
      startDate: local,
    });
  };

  const onChangeEnddate = (date, dateString) => {
    let local = moment.utc(date).local().format();
    setEditGroupData({
      ...editGroupData,
      endDate: local,
    });
  };

  //funciones para los switch
  const onChangeStatus = (checked) => {
    setEditGroupData({
      ...editGroupData,
      active: checked,
    });
  };

  const onChangeActiveLanding = (checked) => {
    setEditGroupData({
      ...editGroupData,
      activeLanding: checked,
    });
  };

  //agrega el array de schedules
  const getHorarioDias = (array) => {
    if (editGroupData.schedules.length <= 0) {
      let agregar = editGroupData.schedules.concat(array);
      setEditGroupData({
        ...editGroupData,
        schedules: agregar,
      });
    } else {
      editGroupData.schedules.forEach((valor, index) => {
        array.forEach((dato, index) => {
          if (valor.day === dato.day) {
            //valores de hora del estdo horarioDias
            let inicioE = hourFormat(valor.startHour);
            let finalE = hourFormat(valor.endHour);

            //valores de hora del array
            let inicioA = hourFormat(dato.startHour);
            let finalA = hourFormat(dato.endHour);

            if (inicioA < inicioE && finalA < inicioE) {
              let agregar = editGroupData.schedules.concat(array);
              setEditGroupData({
                ...editGroupData,
                schedules: agregar,
              });
            } else if (inicioA > finalE && finalA > finalE) {
              let agregar = editGroupData.schedules.concat(array);
              setEditGroupData({
                ...editGroupData,
                schedules: agregar,
              });
            } else {
              showMessageWarning(
                <strong>
                  ¡Atención! Horario empalmado, revisa los horarios ya
                  agregados.
                </strong>,
                3
              );
              array = [];
              let agregar = editGroupData.schedules.concat(array);
              setEditGroupData({
                ...editGroupData,
                schedules: agregar,
              });
            }
          } else {
            let agregar = editGroupData.schedules.concat(array);
            setEditGroupData({
              ...editGroupData,
              schedules: agregar,
            });
          }
        });
      });
    }
  };

  const showSchedules = () => {
    return editGroupData.schedules.map((data, index) => {
      return (
        <div key={index}>
          <Row id={index}>
            <Col xs={12} sm={3}>
              <span className="mr-2">{semana[data.day]}</span>
            </Col>
            <Col xs={12} sm={7}>
              <span className="mr-2">
                <strong>Inicia:</strong> {hourFormat(data.startHour)}
              </span>
              <span className="mr-2">
                <strong>Termina:</strong> {hourFormat(data.endHour)}
              </span>
            </Col>
            <Col xs={12} sm={2}>
              <div onClick={() => clickIconDelete(index)}>
                <i className="material-icons deleteIcon">delete</i>
              </div>
            </Col>
          </Row>
          <hr></hr>
        </div>
      );
    });
  };

  const hourFormat = (hora) => {
    let uno = hora.slice(0, 2);
    let dos = hora.slice(2, 4);
    return uno + ":" + dos;
  };

  const clickIconDelete = (indice) => {
    let newArray = editGroupData.schedules;
    newArray.splice(indice, 1);
    setEditGroupData({
      ...editGroupData,
      schedules: newArray,
    });
  };

  //muestra la lista de profesores en elselect
  const showTeachers = () => {
    return teachers.map((value) => {
      return (
        <Option key={value._id} value={value._id}>
          {value.name} {value.lastName} {value.secondLastName}
        </Option>
      );
    });
  };

  //maneja el evento del selector de profesores
  const handleChangeTeacher = (value) => {
    setEditGroupData({
      ...editGroupData,
      teacher: value,
    });
  };

  const groupDelete = () => {
    let del = deleteGroup();
    del.then((result) => {
      if (result.success) {
        killSideBar();
      }
    });
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={12} className={styles.edit_group_main_container}>
          <Row>
            <Title
              onCloseModal={props.onCloseModal}
              editGroupData={editGroupData}
            />
          </Row>
          <div className="form__form-group">
            <span className="form__form-group-label">Nombre del grupo</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <ClassIcon />
              </div>
              <input
                className="form-control"
                name="name"
                id="inpuName"
                component="input"
                type="text"
                value={editGroupData.name}
                onChange={onChamgeInputs}
              />
            </div>
            <ButtonToolbar>
              <Button
                color="success"
                size="sm"
                disabled={
                  adicionalDataGroup.fullCourse !== null
                    ? adicionalDataGroup.fullCourse.hasOwnProperty("isParent")
                      ? true
                      : false
                    : ""
                }
                onClick={() => setModalCourse(true)}
              >
                Cambiar curso
              </Button>
            </ButtonToolbar>
            <h4>
              Curso elegido:{" "}
              {adicionalDataGroup.fullCourse !== null
                ? adicionalDataGroup.fullCourse.name
                : ""}
            </h4>
          </div>
          <Row className="mb-2">
            <Col xs={12}>
              <ButtonToolbar>
                <Button
                  color="success"
                  size="sm"
                  onClick={() => setModalSchedules(true)}
                >
                  Agregar horario
                </Button>
              </ButtonToolbar>
            </Col>
            <Col xs={12}>{showSchedules()}</Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} sm={12} md={6}>
              <p>Fecha de inicio</p>
              <DatePicker
                value={moment(editGroupData.startDate)}
                format={dateFormat}
                locale={locale}
                allowClear={false}
                onChange={onChangeStartdate}
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <p>Fecha de finalización</p>
              <DatePicker
                value={moment(editGroupData.endDate)}
                format={dateFormat}
                locale={locale}
                allowClear={false}
                onChange={onChangeEnddate}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} className="cambioSalon mt-1 mb-1">
              <ButtonToolbar>
                <Button
                  color="success"
                  size="sm"
                  disabled={editGroupData.schedules.length === 0 ? true : false}
                  onClick={() => setModalClassroomLocation(true)}
                >
                  <p>Cambiar salón</p>
                </Button>
              </ButtonToolbar>
            </Col>
            <Col sm={12} md={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Cupo del grupo</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <UsersIcon />
                  </div>
                  <input
                    id="capacity"
                    type="number"
                    name="quota"
                    className="form-control"
                    style={{ width: "100px" }}
                    onChange={onChamgeInputs}
                    value={editGroupData.quota}
                  />
                </div>
              </div>
            </Col>
            <Col sm={6} md={3} className="mt-2">
              <div className="form__form-group">
                <span className="form__form-group-label">Estatus</span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Switch
                        checked={editGroupData.active}
                        onChange={onChangeStatus}
                        style={
                          editGroupData.active
                            ? { backgroundColor: "#2ECC71" }
                            : { backgroundColor: "#E74C3C" }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} md={3} className="mt-2">
              <div className="form__form-group">
                <span className="form__form-group-label">Externos</span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Switch
                        checked={editGroupData.activeLanding}
                        onChange={onChangeActiveLanding}
                        style={
                          editGroupData.activeLanding
                            ? { backgroundColor: "#2ECC71" }
                            : { backgroundColor: "#E74C3C" }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={7} className="cambioSalon">
              <div className="form__form-group">
                <span className="form__form-group-label">Sede</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <BuildingIcon />
                  </div>
                  <input
                    disabled
                    id="sede"
                    type="text"
                    className="form-control"
                    style={{ width: "100px" }}
                    value={
                      adicionalDataGroup?.fullLocation
                        ? adicionalDataGroup?.fullLocation?.name
                        : ""
                    }
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={5} className="cambioSalon">
              <div className="form__form-group">
                <span className="form__form-group-label">Salón</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <TeacherIcon />
                  </div>
                  <input
                    disabled
                    id="salon"
                    type="text"
                    className="form-control"
                    style={{ width: "100px" }}
                    value={
                      adicionalDataGroup?.fullClassRoom
                        ? adicionalDataGroup?.fullClassRoom?.name
                        : ""
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <Select
                showSearch
                style={{ width: 230 }}
                onChange={handleChangeTeacher}
                defaultValue={
                  actualGroup.hasOwnProperty("teacher")
                    ? actualGroup.teacher._id
                    : "Sin asignar"
                }
              >
                {showTeachers()}
              </Select>
            </Col>
            <Col xs="12" className="apull-right">
              <Can do="delete" on="/groups/">
                <Button
                  className="icon float-right"
                  color="danger"
                  onClick={() => setModalDelete(true)}
                >
                  <p>
                    <DeleteIcon />
                    Eliminar
                  </p>
                </Button>
              </Can>
            </Col>
          </Row>
        </Col>
      </Row>
      {modalCourse ? (
        <ModalEditCourse
          valueModal={modalCourse}
          close={setModalCourse}
          saveSelectedCourse={saveSelectedCourse}
          selectedCourse={adicionalDataGroup.fullCourse}
        />
      ) : null}
      {modalSchedules ? (
        <SelectSchedules
          valueModal={modalSchedules}
          close={setModalSchedules}
          getHorarioDias={getHorarioDias}
        />
      ) : null}
      {modalClassroomLocation ? (
        <SelectClassroomLocation
          value={modalClassroomLocation}
          close={setModalClassroomLocation}
          saveInfo={saveLocationClassrom}
          filterData={editGroupData}
        />
      ) : null}
      {modalDelete ? (
        <ModalConfirmation
          closeModal={setModalDelete}
          valueModal={modalDelete}
          callback={groupDelete}
          value={actualGroup._id}
          titulo="Confirmación : ¿Deseas eliminar el grupo?"
        />
      ) : null}
    </Container>
  );
};
export default EditForm;
