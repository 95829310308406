import React from 'react';
import { Select } from 'antd'

const { Option } = Select;

const dicStatus = ["Buzón de voz 1", "Buzón de voz 2", "Buzón de voz 3",
    "Buzón de voz 4", "Buzón de voz 5", "Colgo llamada", "Reagendar fecha",
    "Volver a llamar", "Número inexistente", "Número equivocado"]

const selectFilterOptions = [
    { value: 1, label: dicStatus[0] },
    { value: 2, label: dicStatus[1] },
    { value: 3, label: dicStatus[2] },
    { value: 4, label: dicStatus[3] },
    { value: 5, label: dicStatus[4] },
    { value: 6, label: dicStatus[5] },
    { value: 7, label: dicStatus[6] },
    { value: 8, label: dicStatus[7] },
    { value: 9, label: dicStatus[8] },
    { value: 10, label: dicStatus[9] },
];



const MultiSelectFilter = ({ onFilter, column }) => {


    const handlerSelected = (arraySelected) => {
        console.log(arraySelected)
        const newArray =[]
        arraySelected.forEach(item=>{
            newArray.push(Number(item))
        })
        console.log(newArray)
        onFilter(newArray)
    }

    return (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Selecciona status"
            onChange={handlerSelected}
        >
            {selectFilterOptions.map((item) =>
                <Option key={item.value}>{item.label}</Option>
            )}
        </Select>
    )
}
export default MultiSelectFilter;