import React from 'react';
import { Tag, Popconfirm } from 'antd';
import { Draggable } from "react-beautiful-dnd";
import Can from '../../../../helpers/Secure/Can';
import { PictureOutlined } from '@ant-design/icons'

const ChaptersList = ({ selectedCoursePlaylist, openEdit, removeChapter, selectedChapter, saveSelectedChapter }) => {

  return (selectedCoursePlaylist.map((chapter, index) => {
    return (
      <Draggable key={chapter._id}
        draggableId={chapter._id}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className={"blog_card " + (chapter._id === selectedChapter._id ? "selected" : "")}
              style={chapter.backgroundColor !== undefined ?
                { backgroundColor: chapter.backgroundColor } : null}
              onClick={() => saveSelectedChapter(chapter)}
            >
              <div className="buttons_card_chapters">
                {(chapter.active) ? <Tag className="material_tag_green">Activo</Tag> : <Tag className="material_tag_red">Inactivo</Tag>}
                {(chapter.image) ? <Tag className="material_tag_image" icon={<PictureOutlined />}>Imagen</Tag> : null}
                <Can do="delete" on="/playlists/">
                  <Popconfirm title="¿Estás seguro de eliminar el cápitulo?. Todo su contenido se perdera"
                    className="float-right"
                    okText="Si"
                    cancelText="No"
                    icon={<i className="material-icons red_icon">error</i>}
                    placement="topRight"
                    onConfirm={() => removeChapter(chapter._id)}>
                    <span><i className="material-icons icon_delete">delete</i></span>
                  </Popconfirm>
                </Can>
                <Can do="put" on="/playlists/">
                  <span onClick={() => openEdit(chapter)} > <i className="material-icons icon_edit">edit</i></span>
                </Can>
              </div>
              <div className="inner_part">
                <h4>{chapter.name}</h4>
                <div className="description_scroll">
                  <p>Descripción: {chapter.description}</p>
                </div>
                <p>Elementos guardados: {chapter.totalItems !== undefined ? chapter.totalItems : 0}</p>
              </div>
            </div>
          </div>
        )}
      </Draggable>)
  }))
}
export default ChaptersList