import React from 'react';
import { Modal } from 'react-responsive-modal';
import { ButtonToolbar, Button } from 'reactstrap'


class ModalConfirmation extends React.Component {

    onCloseModal = () => {
        this.props.closeModal();
    };

    ok = () => {
        if (this.props.closeParent) {
            this.props.callback(this.props.value)
            this.onCloseModal()
            this.props.closeParent()
        } else if (this.props.calendario) {
            this.props.callback(this.props.value, false, this.props.idGroup)
            this.onCloseModal()
        }
        else if (this.props.dontCloseModal) {
            this.props.callback(this.props.value)
        }
        else {
            this.props.callback(this.props.value)
            this.onCloseModal()
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <Modal
                            open={this.props.valueModal}
                            onClose={this.onCloseModal}
                            closeOnEsc={false}
                            showCloseIcon={false}
                            closeOnOverlayClick={false}
                        >
                            <div className="modal-dialog--danger modal-dialog--header">
                                <div className="modal__header">
                                    <span className="lnr lnr-cross-circle modal__title-icon" />
                                    <h2 className="bold-text  modal__title">Alerta</h2>
                                </div>
                                <div className="modal__body">
                                    <h4>{this.props.titulo}</h4>
                                </div>
                                <ButtonToolbar className="modal__footer">
                                    <Button onClick={this.onCloseModal}>Cancelar</Button>
                                    <Button color="danger" onClick={this.ok}>Continuar</Button>
                                </ButtonToolbar>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}
export default ModalConfirmation;