import React, { useState, useEffect } from 'react';
import { Col, Row, Container, Button } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import { showMessageWarning } from '../../../../helpers/MessageAndNotificationUtils';
import {
  fetchAsync, urlCourses, urlCourseLevels, urlPlaylistByCourse, urlPlaylist
} from '../../../../helpers/Globals/globals';
import MainTabs from './tabs';
import LinkList from './LinkList';
import PropTypes from 'prop-types';


const LinkMaterial = ({ valueModal, close, selectedCourse, selectedChapter }) => {

  const [mediaCourses, setMediaCourses] = useState([])
  const [superiorCourses, setSuperiorCourses] = useState([])
  const [arrayContenLink, setArrayContenLink] = useState([])

  const [courseChapters, setCourseChapters] = useState([])
  const [chaptersTracker, setChaptersTracker] = useState([])
  const [emptyMaterial, setEmptyMaterial] = useState(false)

  //levels
  const [mediaLevel, setMediaLevel] = useState("")
  const [superiorLevel, setSuperiorLevel] = useState("")

  useEffect(() => {
    getCourseLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //obtiene los ids de niveles media,superior
  const getCourseLevels = () => {
    fetchAsync(urlCourseLevels, "", "GET", "")
      .then((data) => {
        if (data.success) {
          data.courseLevels.forEach(level => {
            getCoursesByLevel(level)
          })
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  const getCoursesByLevel = (level) => {
    fetchAsync(urlCourses + "level/" + level._id, "", "GET", "")
      .then((data) => {
        if (data.success) {
          //delete the actual course selected
          let newArray = data.courses.filter((data) => data._id !== selectedCourse._id)
          if (level.name === "Media Superior") {
            setMediaCourses(newArray)
            setMediaLevel(level)
          } else {
            setSuperiorCourses(newArray)
            setSuperiorLevel(level)
          }
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  const onClose = () => {
    close(false)
  }

  const addContent = (item) => {
    setArrayContenLink(arrayContenLink => [...arrayContenLink, item])
  }

  const deleteContent = (item) => {
    // console.log(item)
    let newArray = arrayContenLink.filter((data) => data._id !== item._id)
    setArrayContenLink(newArray)
  }

  const getNewPlaylist = (item) => {
    item.course !== undefined || item.parent !== undefined ?
      getChaptersContentPlaylist(item._id) : getCourseChapters(item._id)

    let traker = [...chaptersTracker]
    traker.push(item)
    setChaptersTracker(traker)
  }

  const backPlaylist = () => {
    if (chaptersTracker.length > 1) {
      if (chaptersTracker[chaptersTracker.length - 1].parent !== undefined) {
        getChaptersContentPlaylist(chaptersTracker[chaptersTracker.length - 1].parent)
      } else {
        getCourseChapters(chaptersTracker[chaptersTracker.length - 1].course !== undefined ?
          chaptersTracker[chaptersTracker.length - 1].course :
          chaptersTracker[chaptersTracker.length - 1]._id)
      }
    } else {
      setCourseChapters([])
    }

    let deleteArray = [...chaptersTracker]
    deleteArray.pop()
    setChaptersTracker(deleteArray)
    setEmptyMaterial(false)
  }

  const saveLinkedMaterial = () => {
    if (arrayContenLink.length > 0) {
      console.log(arrayContenLink)
    } else {
      showMessageWarning(<strong>Atención! Debes agregar material.</strong>, 3)
    }
  }

  const getCourseChapters = (idCourse) => {
    fetchAsync(urlPlaylistByCourse + idCourse, "", "GET")
      .then((data) => {
        if (data.success) {
          setCourseChapters(data.playlists)
          if (data.playlists.length === 0) {
            setEmptyMaterial(true)
          } else {
            setEmptyMaterial(false)
          }
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const getChaptersContentPlaylist = (idChapter) => {
    fetchAsync(urlPlaylist + idChapter, "", "GET")
      .then((data) => {
        if (data.success) {
          let onlyChapters = data.playlist.contentPlaylist.filter((data) => data.type === 1)
          setCourseChapters(onlyChapters)

          if (onlyChapters.length === 0) {
            setEmptyMaterial(true)
          } else {
            setEmptyMaterial(false)
          }
        }
      }).catch(err => {
        console.log(err)
      })
  }

  return (
    <Modal
      open={valueModal}
      onClose={onClose}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Container>
        <Row className="link_material_container">
          <Col xs="12" className="link_title">
            <h2 className="float-left">Enlazar material</h2>
            <Button size="sm" className="btn btn-secondary float-right" onClick={onClose}>
              Cerrar
            </Button>
            <Button className="btn float-right mr-2" size="sm" color="add"
              onClick={saveLinkedMaterial}
            >
              <p><i className="material-icons">link</i>Enlazar material</p>
            </Button>
          </Col>
          <Col xs="12" md="6" className="select_course_playlist_cont">
            <h4 className="mb-1">Selecciona {emptyMaterial ? "" : courseChapters.length > 0 ? <b>las CARPETAS</b> : <b>el CURSO</b>} a enlazar</h4>
            <MainTabs mediaCourses={mediaCourses} superiorCourses={superiorCourses} courseChapters={courseChapters}
              addContent={addContent} getNewPlaylist={getNewPlaylist} chaptersTracker={chaptersTracker} backPlaylist={backPlaylist}
              emptyMaterial={emptyMaterial} mediaLevel={mediaLevel} superiorLevel={superiorLevel}
              setChaptersTracker={setChaptersTracker}
            />
          </Col>
          <Col xs="12" md="6" className="files_destiny_cont">
            <p>Curso: <span className="destiny_names">{selectedCourse.name},</span> carpeta donde se va a enlazar el material <span className="destiny_names">{selectedChapter.name}</span> </p>
            <h4 className="mb-1">Material a enlazar:</h4>
            <div className="link_list_div">
              <LinkList
                arrayContenLink={arrayContenLink}
                deleteContent={deleteContent}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
LinkMaterial.propTypes = {
  valueModal: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedCourse: PropTypes.object.isRequired,
  selectedChapter: PropTypes.object.isRequired
};
export default LinkMaterial



