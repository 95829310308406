import React, { PureComponent } from 'react';
import { fetchAsync, urlLoginUserRecover } from '../../../helpers/Globals/globals';
import { showNotification } from '../../../helpers/MessageAndNotificationUtils';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

class ForgotForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      email: ""
    };
  }

  flogin(e) {
    e.preventDefault();
    var dataLogin = {
      email: this.state.email,
      password: this.state.password,
    }
    fetchAsync(urlLoginUserRecover, JSON.stringify(dataLogin), "PUT", "default")
      .then(
        (data) => {
          if (data.success === true) {
            let messageText = "Mensaje enviado"
            let messageDescription = "Se ha enviado un correo de recuperación con una nueva contraseña. En caso de que no aparezca en tu bandeja de correos principal revisar en correo no deseado o spam."
            showNotification("success", messageText, messageDescription, 0)

          } else {
            let messageText = "Error"
            let messageDescription = "No podemos encontrar la cuenta con el correo proporcionado , intenta otra vez o acude con un administrativo para realizar la aclaración."
            showNotification("error", messageText, messageDescription, 0)

          }
        }
      )
      .catch(
        (reason) => {
          console.log(reason.message)

        }
      );
  }


  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    return (
      <form className="form">
        <div className="form__form-group">

          <span className="form__form-group-label">Tú correo electrónico</span>
          <div className="form__form-group-field">

            <input
              name="email"
              type="email"
              onChange={(event) => {
                this.setState({
                  email: event.target.value
                });
              }}
              value={this.state.email}
              placeholder="hola@preparacion.com"
            />
          </div>
        </div>
        <div className="account__btns">
          <Link className="btn btn-secondary account__btn" to="/log_in">Regresar</Link>
          <Button className="btn btn-primary account__btn" onClick={(e) => this.flogin(e)}> Recuperar</Button>
        </div>
      </form>
    );
  }
}

export default ForgotForm;
