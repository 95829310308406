import { fetchAsync, urlLoginStudent } from '../../helpers/Globals/globals'

const Actions = (state, dispatch) => ({
  /**
   * action: login
   * description: login with email and password
   */
  login: ({ email = '', password = '', keepSession }) => {
    const bodyLogin = {
      email: email,
      password: password,
    }
    return fetchAsync(urlLoginStudent, JSON.stringify(bodyLogin), "POST", "")
      .then(
        (response) => {
          if (response.success) {
            dispatch({ type: 'LOGIN', payload: { ...response, keepSession } });
          } else {
            dispatch({ type: 'ERROR', payload: { ...response } });
          }
          return response;
        }
      )
      .catch(
        (reason) => {
          console.log(reason.message);
        }
      );
  },

  updatePassword: ({ email, password, newPassword }) => {
    return fetchAsync('/api/st/login', {
      method: 'POST',
      body: JSON.stringify({ password, email })
    }).then((loginResponse) => {
      return (
        loginResponse.success &&
        fetchAsync(`/api/students/${loginResponse.student._id}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${loginResponse.token}`
          },
          body: JSON.stringify({ password: newPassword })
        }).then((studentResponse) => {
          dispatch({
            type: 'UPDATE_PASSWORD',
            payload: {
              success: true,
              loginData: loginResponse,
              studentData: studentResponse
            }
          });
          return studentResponse.success && loginResponse.success;
        })
      );
    });
  },

  closeSession: () => {
    dispatch({ type: 'CLOSE_SESSION', payload: { success: true } });
  },

})

export default Actions;

