import React, { useState, createContext, useEffect } from 'react';
import openSocket from 'socket.io-client';
import {
    showNotificationCustom
} from 'helpers/MessageAndNotificationUtils';
import { urlSocket } from 'helpers/Globals/globals'

const SocketContext = createContext();

function SocketState(props) {
    const [socketInstance, setSocketInstance] = useState();
    const [socketRooms, setSocketRooms] = useState([]);
    const [isConnected, setIsConnected] = useState(true)

    useEffect(() => {
        setSocketInstance(openSocket(urlSocket));
    }, [])

    useEffect(() => {
        if (socketInstance !== undefined) {
            socketInstance.on('disconnect', function () {
                showNotificationCustom({ type: "warning", message: "Se desconecto el socket, intentando reconectar", placement: "bottomLeft" })
                setIsConnected(false)
            });
            socketInstance.on('reconnect', () => {
                showNotificationCustom({ type: "success", message: "Conexión recuperada", placement: "bottomLeft" });
                socketRooms.forEach(room => {
                    socketInstance.emit('join', room)
                })
                setIsConnected(true)
            })
            //This will remove all sockets in OnUnmount , is optional but just in case to evoid future errors
            return () => {
                console.log("SocketOnDisconnect")
                socketInstance.off();
                socketInstance.disconnect()
                socketInstance.close()
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketInstance])

    /**
     * 
     * @param {String} roomToUnsuscribe 
     * This function guarantee the remove of sockets listeners
     */

    const unsuscribeSocketToRoom = (roomToUnsuscribe) => {
        let idexOfRoom = socketRooms.filter(item => item === roomToUnsuscribe)
        if (idexOfRoom > 0) {
            let newArray = [...socketRooms]
            newArray.splice(idexOfRoom, 1);
            setSocketRooms(newArray)
        }
        socketInstance.off(roomToUnsuscribe);
    }

    const suscribeSocketToRoom = (roomToSuscribe) => {
        if (socketRooms.findIndex(item => item === roomToSuscribe) < 0) {
            let newArray = [...socketRooms]
            newArray.push(roomToSuscribe);
            setSocketRooms(newArray)
        } else {
            // console.log("Is try to join to socket room in array")
        }
        socketInstance.emit('join', roomToSuscribe)
    }



    const value = {
        isConnected,
        socketInstance,
        suscribeSocketToRoom,
        unsuscribeSocketToRoom
    };

    return (
        <SocketContext.Provider value={value}>
            {props.children}
        </SocketContext.Provider>
    )

}
export { SocketContext };
export default SocketState;