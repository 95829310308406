import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import SidebarContent from './SidebarContent';
import SidebarButton from './SidebarButton';
function Sidebar({
  changeMobileSidebarVisibility, sidebarShow, sidebarShowMobile, changeSidebarVisibility,
}) {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebarShowMobile,
    'sidebar--collapse': !sidebarShow,
  });

  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarButton
            changeSidebarVisibility={changeSidebarVisibility}
            sidebarShow={sidebarShowMobile}
          />
          <SidebarContent
            onClick={() => { }}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
          />
        </div>
      </Scrollbar>
    </div>
  );
};
export default Sidebar;
