import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const Header = ({ onCloseModal, questionId, saveSelectedVideos }) => (
  <Row>
    <Col xs="12" md="8">
      <h3 className="mt-1">{questionId !== "" ? `Selecciona el video(s) y presiona guardar` : `Agrega y edita los videos del cuestionario`}</h3>
    </Col>
    <Col sm="12" md="4">
      <Button color="success" size="sm" className={questionId !== "" ? "float-right mr-3" : "collapse"}
        onClick={() => saveSelectedVideos()}
      >
        <p>Guardar</p>
      </Button>
      <Button size="sm" className="float-right mr-3"
        onClick={() => onCloseModal()}>
        <p>Salir</p>
      </Button>
    </Col>
  </Row>
)
Header.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  saveSelectedVideos: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
};
export default Header;