import React, { PureComponent } from 'react';
import { Col, Row, Button, Card, CardBody } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import PermissionList from "./PermissionList"
import { structPermissionTree, reverseServiceToTree } from "../../../helpers/Secure/utils"
import { showMessageLoading, showMessageSuccess, showMessageWarning, showMessageError } from '../../../helpers/MessageAndNotificationUtils';

let loadingMessage

class ModalRol extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            name: "",
            description: "",
            checkedKeys: [],
        };
    }

    onCloseModal = () => {
        this.props.close();

    };

    handlerCheck = (checkedKeys) => {
        this.setState({
            checkedKeys: checkedKeys
        })

    }

    saveRol = () => {
        if (this.state.name === "") return showMessageWarning("Debes escribir un nombre de rol", 2)
        if (this.state.description === "") return showMessageWarning("Debes escribir una descripción", 2)
        if (this.state.checkedKeys.length === 0) return showMessageWarning("!Atención¡, No as seleccionado ningún rol", 3)

        this.setState({ loading: true })
        loadingMessage = showMessageLoading("Creando Rol", 0)
        let allows = structPermissionTree(this.state.checkedKeys.length > 0 ? this.state.checkedKeys : reverseServiceToTree(undefined))
        allows.forEach((data) => {
            data.methods = [...new Set(data.methods)]
        })
        let body = {
            name: this.state.name,
            description: this.state.description,
            allows: allows
        }

        let result = this.props.createRole(body)
        result.then((res) => {
            if (res === undefined || res.success === false) showMessageError("Ocurrio un error", 2)
            loadingMessage()
            this.setState({ loading: false })
            if (res.success) {
                showMessageSuccess("Rol creado exitosamente", 2.5)
                this.onCloseModal()
            }
        })
    }

    render() {
        return (
            <Modal
                open={this.props.value}
                onClose={this.onCloseModal}
                center={true}
                closeOnEsc={false}
                showCloseIcon={false}
                closeOnOverlayClick={false}>

                <Col sm={12} md={12}>
                    <Card>

                        <Row>
                            <Col xs={12} sm={4} md={4}>
                                <h4 className="bold-text mt-3">Crear nuevo Rol</h4>
                            </Col>
                            <Col xs={12} sm={8} md={8} className="mt-2">
                                <Button size="sm" className="btn float-right" color="success" style={{ marginRight: "10px" }}
                                    onClick={() => this.saveRol()} disabled={this.state.loading} >
                                    <p>Guardar</p>
                                </Button>
                                <Button size="sm" className="btn float-right"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => this.onCloseModal()}>
                                    <p>Salir</p>
                                </Button>
                            </Col>
                        </Row>
                        <CardBody>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Nombre</span>
                                <div className="form__form-group-field">
                                    <input type="text"
                                        className="form-control"
                                        id="inpuName" autoComplete="off"
                                        defaultValue={this.state.name}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value })
                                        }} />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Descripción</span>
                                <div className="form__form-group-field">
                                    <input type="text"
                                        className="form-control"
                                        id="inputDes" autoComplete="off"
                                        defaultValue={this.state.description}
                                        onChange={(event) => {
                                            this.setState({ description: event.target.value })
                                        }} />
                                </div>
                            </div>
                            <Row className="d-flex flex-row">
                                <Col sm={12} md={12}>
                                    <PermissionList
                                        handlerCheked={this.handlerCheck}
                                        isUpdate={true}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Modal>
        );
    }
}
export default ModalRol;
