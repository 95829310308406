import React, { Component } from 'react';
import { Col, Card, CardBody } from 'reactstrap';

class sucessView extends Component {

    render() {
        return (
            <Col md={12} xl={12} sm={12} className="mt-2">
                <Card className="pricing-card pricing-card--success">
                    <CardBody className="pricing-card__body">
                        <div className="svg-container stepFinalizar mt-5">
                            <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                                <circle className="circle" fill="#5bb543" cx="24" cy="24" r="22" />
                                <path className="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" strokeLinejoin="round" stroke-strokemiterlimit="10" d="M14 27l5.917 4.917L34 17" />
                            </svg>
                        </div>
                        <h3 className="pricing-card__plan mt-3">Cambios guardados corrrectamente</h3>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}
export default sucessView;
