import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { newSchedules } from '../../../../../helpers/Globals/funciones';
import { dateDnumberMstringYY } from "../../../../../helpers/Fichas/functions"

import styles from './index.module.scss'

const CardGroup = (props) => {

    const newArray = props.groupChange.schedules.length > 0 ? props.groupChange.schedules : []

    return (
        <Card className={props.typeGroup !== "" ? props.groupChange.available === 0 ? styles.card_change_group_overcrowding : styles.card_change_group_new : styles.card_change_group_old}>
            <CardBody>
                <h3>{props.typeGroup !== "" ? "Destino" : "Origen"}</h3>
                <h3>{props.groupChange !== undefined && props.groupChange.courseInfo ? props.groupChange.courseInfo.name : ""}</h3>
                <h4><b>Grupo:</b> {props.groupChange !== undefined ? props.groupChange.name : ""}</h4>
                <h4><b>Sede : </b>{props.groupChange !== undefined && props.groupChange.locationInfo !== undefined ? `${props.groupChange.locationInfo.name}` : ""}</h4>
                <h4><b>Salón: </b>{props.groupChange !== undefined && props.groupChange.classRoomInfo !== undefined ? `${props.groupChange.classRoomInfo.name}` : ""}</h4>
                <h4><b>Inicia: </b>{dateDnumberMstringYY(props.groupChange.startDate)}</h4>
                {props.groupChange.schedules.length > 0 ? <h4><b>Horario(s): </b>{newSchedules(newArray)}</h4> : ""}
                {props.typeGroup === 2 && props.groupChange.available === 0 ?
                    <p className={styles.overcrowding_text}>!Atención¡ El grupo seleccionado esta lleno, podras cambiar el alumno al darle click al botón "Cambiar" pero estaras autorizando un sobrecupo.</p>
                    : ""}
            </CardBody>
        </Card>
    )
}
export default CardGroup;
