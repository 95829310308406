import React, { useState } from 'react';
import Modal from "react-responsive-modal";
import { Upload } from 'antd';
import ModalConfirmation from '../../containers/Modal/ModalConfirmation'
import { Col, Container, Row, Button } from 'reactstrap';
import { Popconfirm } from 'antd';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { fetchAsync, urlImage, uploadData3 } from '../Globals/globals'
import { showMessageError } from '../MessageAndNotificationUtils';

import 'antd/lib/popover/style/index.css'
import '../../scss/component/motion.scss'

function UploadImageModal({ value, close, imageId, imageUrl, onSave, onRemove, actualIdAnswer = "" }) {
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const state = {
        previewVisible: false,
        imageData: imageUrl
    };
    const [stateHook, setStateHook] = useState(state)
    const [imageResult, setImageResult] = useState()

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            showMessageError("Solo puedes subir archivos JPG ó PNG", 3)
            return false
        } else {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                showMessageError("Las imagenes deben ser menores a 2MB", 3)
                return false
            } else {
                return true
            }
        }
    }

    const handleChange = async (info) => {
        console.log("change", info)
        let state = { ...stateHook }
        if (info.file.status === 'uploading') {
            state.loading = true
            setStateHook(state)
        }
        if (info.file.status === 'done') {
            state.imageData = await getBase64(info.file.originFileObj)
            state.loading = false
            setStateHook(state)
        }
    }

    const uploadImage = async ({
        action,
        data,
        file,
        onError,
        onProgress,
        onSuccess,
    }) => {
        let body = {
            filename: file.name
        }
        fetchAsync(urlImage, JSON.stringify(body), "POST")
            .then(
                (data) => {
                    if (data.success) {
                        return uploadData3(data.url, file, "PUT", (percentage, index) => onProgress(percentage, file), 1)
                            .then(result => {
                                setImageResult(data.image)
                                onSuccess(result, file)
                            })
                            .catch(
                                error => {
                                    onError(error, file)
                                }
                            )
                    }
                }
            )
            .catch(
                (reason) => {
                    onError(reason, file)
                }
            );
    }

    const onCloseModal = () => {
        close()
    }

    const handleRemoveFormula = () => {
        onRemove()
    }

    const uploadButton = (
        <div>
            {/* <Icon type={stateHook.loading ? 'loading' : 'plus'} /> */}
            <div className="ant-upload-text">Cargar imagen</div>
        </div>
    )

    const saveImage = () => {
        onSave(imageId, imageResult, actualIdAnswer)
        onCloseModal()
    }

    return (
        <Modal
            open={value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>
            <Container>
                <Row className="modal-videos-quiz">
                    <Col xs="12">
                        <Row>
                            <Col xs="12" md="8">
                                <h3 className="mt-1">Carga aquí la imagen</h3>
                            </Col>
                            <Col sm="12" md="4">
                                <Button color="success" size="sm" className="float-right mr-3" disabled={!stateHook.imageData} onClick={saveImage}>
                                    {/* <Button color="success" size="sm" className="float-right mr-3" disabled={!stateHook.imageData} onClick={() => onSave(imageId, imageResult)}> */}
                                    <p>Guardar</p>
                                </Button>
                                <Button size="sm" className="float-right mr-3"
                                    onClick={onCloseModal}>
                                    <p>Salir</p>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12">
                        <div className="uploadContent">
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                customRequest={uploadImage}
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}>
                                {stateHook.imageData ? <img src={stateHook.imageData} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </div>
                    </Col>
                    {imageUrl ?
                        <Col xs={12} className="pt-4">
                            <Popconfirm title="¿Estás seguro de eliminar la ecuación?"
                                okText="Si"
                                cancelText="No"
                                icon={<i className="material-icons red_icon">error</i>}
                                placement="topLeft"
                                onConfirm={handleRemoveFormula}>
                                <Button className="btn  icon float-right" color="danger">
                                    <p><DeleteIcon />Eliminar</p>
                                </Button>
                            </Popconfirm>
                        </Col>
                        : null
                    }
                </Row>
            </Container>
            {
                (modalConfirmation) ? <ModalConfirmation
                    closeModal={() => setModalConfirmation(false)}
                    valueModal={modalConfirmation}
                    value={imageId}
                    titulo={"Confirmación : ¿Deseas eliminar la Imagen?"}
                /> : null
            }
        </Modal>
    )
}
export default UploadImageModal