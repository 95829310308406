import React, { useState, useEffect, useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useRouteMatch, useHistory } from 'react-router-dom';
import TopBar from './components/TopBar';
import LeftBar from './components/LeftBar';
import FormQuestion from './components/FormQuestion';
import QuizVideos from './components/videos/index';
import QuizContext from '../../context/quiz/QuizContext';
import ReorderQuestions from './components/reorderQuestions';

function Quizzes(props) {

  const history = useHistory();
  const match = useRouteMatch();

  const { idCourse, idPlaylist, idQuiz } = match.params
  //creamos el context
  const quizContext = useContext(QuizContext);
  const { getPlaylistInfo, getCourseInfo, getQuizInfo, courseInfo, playlistInfo, quizInfo, onChangeInputs,
    addQuestion, validations, saved, _idQuiz,
    saveVideosQuestion, reorderQuestions, disable } = quizContext;

  const [openModalVideos, setOpenModalVideos] = useState(false)
  //estados para asignar un video a un pregunta
  const [selectedQuestionId, setSelectedQuestionId] = useState("")
  const [explanationVideoSelectedQuestion, setExplanationVideoSelectedQuestion] = useState([])
  //estados para reordenar las preguntas
  const [openModalReorder, setOpenModalReorder] = useState(false)

  useEffect(() => {
    getPlaylistInfo(idPlaylist)
    getCourseInfo(idCourse)
    addQuestion()
    if (idQuiz !== undefined) {
      getQuizInfo(idQuiz)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (_idQuiz !== "" && idQuiz ===  undefined) {
      history.push({
        pathname: `/cuestionarios/${idCourse}/${idPlaylist}/${_idQuiz}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_idQuiz])

  const openModalAddVideoQuestion = (idQuestion, arrayVideos) => {
    setSelectedQuestionId(idQuestion)
    setExplanationVideoSelectedQuestion(arrayVideos)
    setOpenModalVideos(true)
  }

  const closeVideoModal = () => {
    if (selectedQuestionId !== "") {
      setExplanationVideoSelectedQuestion([])
      setSelectedQuestionId("")
    }
    setOpenModalVideos(false)
  }

  const saveReorder = () => {
    validations()
    setOpenModalReorder(false)
  }

  return (
    <Container>
      <Row className="quiz_main_container">
        <Col xs="12" md="4" className="left_bar_cont">
          <LeftBar courseInfo={courseInfo} playlistInfo={playlistInfo}
            quizInfo={quizInfo} onChangeInputs={onChangeInputs}
            validations={validations} saved={saved} setOpenModalVideos={setOpenModalVideos}
            setOpenModalReorder={setOpenModalReorder} disable={disable} />
        </Col>
        <Col xs="12" md="8">
          <Row className="rigth_bar_cont">
            <TopBar title={quizInfo.name} />
            <Col xs="12" className="col_questions"  >
              <FormQuestion openModalAddVideoQuestion={openModalAddVideoQuestion} />
            </Col>
          </Row>
        </Col>
      </Row>
      {openModalVideos &&
        <QuizVideos
          value={openModalVideos}
          close={closeVideoModal}
          quizId={idQuiz}
          title={"titleQuiz"}
          questionId={selectedQuestionId}
          //guardar el array de videos selecionados a asignar
          saveVideosQuestions={saveVideosQuestion}
          explanationVideoQuestion={explanationVideoSelectedQuestion}
        />}
      { openModalReorder &&
        <ReorderQuestions
          value={openModalReorder}
          close={setOpenModalReorder}
          quiz={quizInfo.quiz}
          reorderQuestions={reorderQuestions}
          saveReorder={saveReorder}
        />}
    </Container>
  )
}
export default Quizzes
