import React, { useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SetFechaTable from "./../../../helpers/Tables/fechaTables";
import LoadingComponent from "../../../helpers/LoadingComponent";
import SortTable from "../../../helpers/Tables/sortTable";
import statusTable from "../../../helpers/Tables/statusTable";
import ViewportContext from "../../../context/Viewport/viewportContext";

import styles from "./index.module.scss";

const TableCourses = ({ dataTable, getActualCourse, setActualCourse }) => {
  // instance of the viewport context
  const viewportContext = useContext(ViewportContext);
  const { width } = viewportContext;

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setActualCourse(row);
      getActualCourse();
    },
  };

  const disponible = (cell, row) => {
    return statusTable(cell);
  };

  const fecha = (cell, row) => {
    return SetFechaTable(cell);
  };

  //const headerSortingStyle = { backgroundColor: '#D6DBDF' };
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
      headerAlign: "center",
      align: "center",
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
    },
    {
      dataField: "groups",
      text: "Grupos",
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "price",
      text: "Precio",
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "creationDate",
      text: "Creado",
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerAlign: "center",
      align: "center",
      formatter: fecha,
      hidden: width < 500,
    },
    {
      dataField: "active",
      text: "Estatus",
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerAlign: "center",
      align: "center",
      formatter: disponible,
    },
  ];

  const defaultSorted = [
    {
      dataField: "creationDate",
      order: "desc",
    },
  ];

  return (
    <div className={styles.table_container}>
      <ToolkitProvider keyField="_id" data={dataTable} columns={columns} search>
        {(props) => (
          <div className="">
            <SearchBar
              {...props.searchProps}
              className={styles.search_box}
              style={{
                color: "#2980B9",
                backgroundColor: "#ecf0f1",
              }}
              placeholder="Buscar un curso"
            />
            <BootstrapTable
              // striped
              hover
              defaultSorted={defaultSorted}
              bordered={false}
              rowEvents={rowEvents}
              {...props.baseProps}
              noDataIndication={<LoadingComponent />}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};
export default TableCourses;
