import React, { useEffect, useState, useContext, useCallback } from "react";
import { Col, Row, Button, Container } from "reactstrap";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { TalksContext } from "context/TalksContext";
import { SocketContext } from "context/SocketContext";
/**
 * COMPONENTS IMPORTS
 */
import AddPlatica from "../addPlatica";
import ReportsModal from "../reports";
import ModalStudents from "./components/ModalStudents";
import TabsModal from "../tabsStudents/TabsModal";
/**
 * HELPERS IMPORTS
 */
import { showStatus } from "helpers/Fichas/functions";
import { showMessageWarning } from "helpers/MessageAndNotificationUtils";
import { newSchedules } from "helpers/Globals/funciones";
import Can from "helpers/Secure/Can";
import LoadingSkeleton from "helpers/LoadingSkeleton";
import SmsAndMailMessages from "helpers/SmsAndMailComponent/SmsAndMailMessages";

import styles from "./index.module.scss";
import "antd/es/avatar/style/index.css";

const FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const FILE_EXT = ".xlsx";

const SidebarFicha = ({ onClosSidebar }) => {
  const talksContext = useContext(TalksContext);
  const socketContext = useContext(SocketContext);

  const {
    state,
    getFullInfoTalk,
    deleteTalk,
    updateDataSocket,
    updateUserAfterEdit,
    onDeleteStudentTalk,
  } = talksContext;
  const { talkFullInfo, talkSelected, loadingSkeleton } = state;

  const { socketInstance, suscribeSocketToRoom, unsuscribeSocketToRoom } =
    socketContext;

  const [userInfo, setUserInfo] = useState();

  const [modalUserCard, setModalUserCard] = useState(false);
  const [openInformacion, setOpenInformacion] = useState(false);
  const [openEditTalk, setOpenEditTalk] = useState(false);
  const [openModalReports, setOpenModalReports] = useState(false);
  const [modalSmsMail, setModalSmsMail] = useState(false);
  /**
   * EFFECTS
   */
  useEffect(() => {
    if (socketInstance !== undefined) {
      suscribeSocketToRoom("update_table_event");
      socketInstance.on("update_table_event", updateDataSocket);
    } else {
      // console.log("IS UNDEFINED SOCKET IN TABLE");
    }
    return () => {
      unsuscribeSocketToRoom("update_table_event");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFullInfoTalk(talkSelected._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talkSelected]);

  const closeModal = useCallback(() => {
    setOpenInformacion(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talkSelected]);

  const handlerLista = useCallback(
    (data) => {
      setUserInfo(data);
      setModalUserCard(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [talkSelected]
  );

  const exportToCSV = (csvData, fileName) => {
    if (csvData === undefined || csvData.length <= 0) {
      showMessageWarning("Aún no hay alumnos de platica disponibles", 5);
    } else {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: FILE_TYPE });
      FileSaver.saveAs(data, fileName + FILE_EXT);
    }
  };

  const onDeleteStudent = (idStudent) => {
    onDeleteStudentTalk(idStudent);
    setModalUserCard(false);
  };

  /*
   ******************************************************************
   ******************************************************************
   **************************RENDER**********************************
   ******************************************************************
   ******************************************************************
   */
  const { name, active } = talkSelected;
  return (
    <Container className={styles.container}>
      <Row className="p-2">
        <Col xs={12}>
          <h3> {name}</h3>

          <div className={styles.status_button_box}>
            <section>
              {loadingSkeleton ? (
                <LoadingSkeleton />
              ) : (
                showStatus(talkFullInfo?.active)
              )}
            </section>
            <Can do="put" on="/talks/">
              <Button
                size="sm"
                className="icon square"
                type="button"
                color="success"
                outline
                onClick={() => setOpenEditTalk(true)}
              >
                <p>
                  <i className="material-icons">edit</i>Editar
                </p>
              </Button>
            </Can>
          </div>

          <section className={styles.info_container}>
            <h4>Fecha:</h4>
            {loadingSkeleton ? (
              <LoadingSkeleton />
            ) : (
              <p>
                {talkFullInfo.date !== undefined
                  ? moment(talkFullInfo.date).format("DD/MMM/YYYY")
                  : "Sin definir"}
              </p>
            )}

            <h4>Dia(s) y Horarios</h4>
            {loadingSkeleton ? (
              <LoadingSkeleton />
            ) : (
              <p>{newSchedules(talkFullInfo.schedules)}</p>
            )}

            <h4>Sede</h4>
            {loadingSkeleton ? (
              <LoadingSkeleton />
            ) : (
              <p>
                {talkFullInfo.classRoom.location
                  ? talkFullInfo.classRoom.location.name
                  : "Sin asignar"}
              </p>
            )}

            <h4>Salón</h4>
            {loadingSkeleton ? (
              <LoadingSkeleton />
            ) : (
              <p>
                {talkFullInfo.classRoom
                  ? talkFullInfo.classRoom.name
                  : "Sin asignar"}
              </p>
            )}

            <h4>Profesor</h4>
            {loadingSkeleton ? (
              <LoadingSkeleton />
            ) : (
              <p>
                {talkFullInfo.teacher
                  ? talkFullInfo.teacher.name
                  : "Sin asignar"}
              </p>
            )}

            <h4>
              Alumnos inscritos:{" "}
              {talkFullInfo.studentList !== undefined
                ? talkFullInfo.studentList.length
                : ""}
            </h4>
          </section>

          <Row className="my-3 p-2">
            <Can do="get" on="/students/show/test/">
              <Button
                className="icon square float-left"
                type="button"
                color="success"
                outline
                disabled={loadingSkeleton}
                onClick={() => setOpenInformacion(true)}
              >
                <p>
                  <i className="material-icons">school</i>Alumnos
                </p>
              </Button>
            </Can>

            <Can do="get" on="/students/show/test/">
              <Button
                className="icon square  float-left"
                type="button"
                color="success"
                outline
                disabled={loadingSkeleton}
                onClick={() =>
                  exportToCSV(talkFullInfo.studentList, talkFullInfo.name)
                }
              >
                <p>
                  <i className="material-icons">attach_file</i>Generar excel
                </p>
              </Button>
            </Can>

            <Can do="get" on="/talks/reports/?">
              <Button
                className="icon square  float-left"
                type="button"
                color="success"
                outline
                disabled={loadingSkeleton}
                onClick={() => setOpenModalReports(true)}
              >
                <p>
                  <i className="material-icons">notes</i>Reportes
                </p>
              </Button>
            </Can>

            <Can do="get" on="/talks/reports/?">
              <Button
                className="icon square  float-left"
                type="button"
                color="success"
                outline
                disabled={loadingSkeleton}
                onClick={() => setModalSmsMail(true)}
              >
                <p>
                  <i className="material-icons">message</i>Enviar mensaje
                </p>
              </Button>
            </Can>
          </Row>
          {/* Open the modal that shows the table of all the students  */}
          {openInformacion && (
            <ModalStudents
              valueModal={true}
              closeModal={closeModal}
              talk={talkFullInfo}
              openStudentCard={handlerLista}
            />
          )}

          {openEditTalk && (
            <AddPlatica
              valueModal={openEditTalk}
              isEditPlatica
              closeModal={() => setOpenEditTalk(false)}
              deleteTalk={deleteTalk}
              onClosSidebar={onClosSidebar}
            />
          )}

          {/* Open the modal tabs with the options to change the estudent data */}
          {modalUserCard && (
            <TabsModal
              valueModal={modalUserCard}
              close={() => setModalUserCard(false)}
              studentInfo={userInfo}
              talkInfo={talkFullInfo}
              updateStudentContext={updateUserAfterEdit}
              afterDeleteStudent={onDeleteStudent}
              addPaymentsStudent={() => ""}
            />
          )}

          {openModalReports && (
            <ReportsModal
              valueModal={openModalReports}
              close={() => setOpenModalReports(false)}
              talkFullInfo={talkFullInfo}
            />
          )}

          {modalSmsMail && (
            <SmsAndMailMessages
              value={modalSmsMail}
              close={setModalSmsMail}
              type={"talks"}
              infoType={talkFullInfo}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default SidebarFicha;
