import { setCookie, removeCookie } from 'helpers/cookies';
import { serviceToabilities } from "helpers/Secure/utils";
import { forceUpdate } from "helpers/Secure/abilities"

const COOKIE_TIMEOUT = 5184000;

const reducers = {

    LOADING: (state, { loading }) => {
        return { ...state, loading };
    },

    ERROR: (state, { error, code }) => {
        console.error(error);
        return {
            ...state,
            error: { code, exist: true, message: error }
        };
    },

    LOGIN: (state, data) => {
        let { keepSession, token, user, allows } = data;
        allows = serviceToabilities(allows)
        localStorage['user'] = JSON.stringify(user);
        localStorage['allows'] = JSON.stringify(allows);
        setCookie('token', `Bearer ${token}`, (keepSession && Infinity) || COOKIE_TIMEOUT);
        forceUpdate(allows)
        return {
            ...state,
            error: null,
            isLoggedIn: true,
            userId: user._id,
            user: { ...user },
            allows: allows
        };
    },

    UPDATE_PASSWORD: (state, { loginData, studentData }) => {
        const loginState = reducers['LOGIN'](state, loginData);
        return {
            ...state,
            ...loginState,
            student: { ...state.student, ...studentData.student }
        };
    },

    CLOSE_SESSION: (state) => {
        delete localStorage['user'];
        delete localStorage['allows'];
        removeCookie('token');
        return {
            ...state,
            token: '',
            user: undefined,
            isLoggedIn: false
        };
    }
}
export default reducers;