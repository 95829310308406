import React, { useState, useContext } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Collapse from "./CollapsableGroups";
import "rc-checkbox/assets/index.css";
import { GroupsContext } from "../../../context/groups";

import styles from "./index.module.scss";

function GroupsTabs(props) {
  //context
  const groupsContext = useContext(GroupsContext);
  const {
    coursesMedia,
    coursesSuperior,
    allGroups,
    emptySearch,
    resultGorups,
  } = groupsContext;

  const [activeTab, setActiveTab] = useState("1");

  const getCourseGroups = (idCourse) => {
    const groupsByCourse = allGroups.filter((dataGroup) => {
      if (dataGroup.course === idCourse) {
        return true;
      }
      return false;
    });
    return groupsByCourse;
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-top">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              setActiveTab("1");
            }}
          >
            Media Superior
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              setActiveTab("2");
            }}
          >
            Superior
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="mt-1">
        <TabPane tabId="1">
          <div className={styles.tabs_container}>
            <Collapse
              courses={coursesMedia}
              allGroups={allGroups}
              getCourseGroups={getCourseGroups}
              resultGorups={resultGorups}
              emptySearch={emptySearch}
              setShowDrawer={props.setShowDrawer}
              showDrawer={props.showDrawer}
            />
          </div>
        </TabPane>

        <TabPane tabId="2">
          <div className={styles.tabs_container}>
            <Collapse
              courses={coursesSuperior}
              allGroups={allGroups}
              getCourseGroups={getCourseGroups}
              resultGorups={resultGorups}
              emptySearch={emptySearch}
              setShowDrawer={props.setShowDrawer}
              showDrawer={props.showDrawer}
            />
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}
export default GroupsTabs;
