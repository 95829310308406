import React from 'react';
import { Modal } from 'react-responsive-modal';
import WizardForm from './components/mainWizard';

function AddGroup(props) {
  const onCloseModal = () => {
    props.setAddGroupModal(false)
  };

  return (
    <Modal
      onClose={onCloseModal}
      open={props.valueModal}
      center={false}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <WizardForm onCloseModal={onCloseModal} />
    </Modal>
  )
}
export default AddGroup