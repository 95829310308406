import React, { useContext } from "react";
import { Switch } from "antd";
import BootstrapTable from "react-bootstrap-table-next";

import { GroupsContext } from "context/groups";

import SetFechaTable from "helpers/Tables/fechaTables";
import { newSchedules } from "helpers/Globals/funciones";
import { ability } from "helpers/Secure/abilities";
import statusTable from "helpers/Tables/statusTable";
import Can from "helpers/Secure/Can";
import SortTable from "helpers/Tables/sortTable";
import SubCourses from "./subCoursesComi/index";

import styles from "containers/Grupos/index.module.scss";
import { CaretRightOutlined, RightOutlined } from "@ant-design/icons";
import "antd/es/switch/style/index.css";

function TableGroups(props) {
  const groupsContext = useContext(GroupsContext);
  const {
    changeStatusGroup,
    disableSwitch,
    setActualGroupRowData,
    actualGroup,
  } = groupsContext;
  const dataTable = props.groupsData;

  //setea la informacion del grupo seleccionado en la tabla
  const showSideBar = (row) => {
    if (actualGroup && String(actualGroup._id) !== String(row._id))
      setActualGroupRowData(row);
    props.setShowDrawer(true);
  };
  /******************************************
   ******************************************
   ************* SPECIFIC RENDER ************
   ******************************************
   ******************************************
   */
  const percentageOf = (cell, row) => {
    return cell > 0 ? `${cell.toFixed(1)}%` : "0 %";
  };

  //pinta los dias y horarios
  const renderSchedules = (cell, row) => {
    const content = newSchedules(cell);
    return content;
  };

  const renderDate = (cell, row) => {
    return SetFechaTable(cell);
  };

  const renderLocation = (cell, row) => {
    return cell !== undefined ? cell?.name : "";
  };

  const renderChangeStatus = (cell, row) => {
    const hasPermissions = ability.can("put", "/groups/");
    if (hasPermissions) {
      return (
        <Can do="put" on="/groups/">
          <Switch
            disabled={props.isParent ? disableSwitch : false}
            defaultChecked={row.active}
            onChange={() =>
              props.isChildTable
                ? props.changeStatusGroup(row._id)
                : changeStatusGroup(row._id)
            }
            style={
              row.active
                ? { backgroundColor: "#2ECC71" }
                : { backgroundColor: "#E74C3C" }
            }
          />
        </Can>
      );
    } else {
      return statusTable(row.active);
    }
  };

  const rowEvents = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      showSideBar(row);
    },
  };

  const expandRow = {
    renderer: (row) => <SubCourses id={row._id} />,
    showExpandColumn: true,
    expandByColumnOnly: false,
    onlyOneExpanding: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return <CaretRightOutlined rotate={isAnyExpands ? 90 : 0} />;
    },

    expandColumnRenderer: ({ expanded }) => {
      return <RightOutlined rotate={expanded ? 90 : 0} />;
    },

    onExpand: (row, isExpand, rowIndex, e) => {
      console.log("EXPANSE ", row);
    },

    onExpandAll: (isExpandAll, results, e) => {
      console.log("EXPANSE ALL", isExpandAll);
    },
  };
  /******************************************
   ******************************************
   ************* Styles for table  **********
   ******************************************
   ******************************************
   */
  const cellStyle = (cell, row, rowIndex, colIndex) => {
    return {
      textAlign: "center",
      verticalAlign: "middle",
      borderTop: "1px solid transparent",
    };
  };

  const rowStyle2 = (row, rowIndex) => {
    const style = {};
    if (
      actualGroup !== undefined &&
      String(row._id) === String(actualGroup._id)
    ) {
      style.backgroundColor = "rgba(46, 204, 113,0.5)";
    } else if (rowIndex % 2) {
      style.backgroundColor = "#fafbfe";
    }
    style.cursor = "pointer";
    style.color = "#646777";
    return style;
  };
  /******************************************
   ******************************************
   ************** COLUMN CONFIG *************
   ******************************************
   ******************************************
   */
  const columns = [
    {
      text: "#",
      dataField: "",
      headerStyle: (colum, colIndex) => {
        return { width: "30px", textAlign: "center" };
      },
      style: {
        ...cellStyle(),
      },
      formatter: (cell, row, index) => <p>{index + 1}</p>,
      events: rowEvents,
    },
    {
      dataField: "name",
      text: "Grupo",
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: rowEvents,
    },
    {
      dataField: "percentage",
      text: "Porcentaje",
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      formatter: percentageOf,
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: rowEvents,
    },
    {
      dataField: "studentListLength",
      text: "Inscritos",
      headerStyle: (colum, colIndex) => {
        return { width: "70px", textAlign: "center" };
      },
      style: {
        ...cellStyle(),
        width: "70px",
      },
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: rowEvents,
    },
    {
      dataField: "quota",
      text: "Cupo",
      headerStyle: (colum, colIndex) => {
        return { width: "60px", textAlign: "center" };
      },
      style: {
        ...cellStyle(),
        width: "60px",
      },
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: rowEvents,
    },
    {
      dataField: "locationInfo",
      text: "Sede",
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      formatter: renderLocation,
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: rowEvents,
    },
    {
      dataField: "schedules",
      text: "Día(s) y horarios",
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      formatter: renderSchedules,
      events: rowEvents,
    },
    {
      dataField: "startDate",
      text: "Inicia",
      headerAlign: (column, colIndex) => "center",
      style: cellStyle,
      formatter: renderDate,
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      events: rowEvents,
    },
    {
      dataField: "active",
      text: "Estatus",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
      style: {
        ...cellStyle(),
        width: "80px",
      },
      formatter: renderChangeStatus,
      sort: true,
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
    },
  ];

  return (
    <div>
      <BootstrapTable
        keyField={"_id"}
        data={dataTable}
        columns={columns}
        bordered={false}
        rowStyle={rowStyle2}
        classes={styles.tableGroupsList}
        expandRow={props.isParent ? expandRow : undefined}
      />
    </div>
  );
}
export default TableGroups;
