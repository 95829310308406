import React, { useEffect, useContext } from "react";
import { Checkbox } from "antd";
import { getCourseGroups } from "./redFunctions";

import MessagesContext from "../context/messagesContext";
import EmptyCard from "../../../helpers/Fichas/emptyCard";
import SetFechaTable from "../../../helpers/Tables/fechaTables";
import { showStatus } from "../../../helpers/Fichas/functions";
// import LoadingComponent from "../../../helpers/LoadingComponent";

import styles from "./index.module.scss";

const GroupsList = ({ infoType }) => {
  const context = useContext(MessagesContext);
  const { getGroups, groupsList, emptyGroupsList, stringFilterUrl } = context;

  //get all the groups from the course
  useEffect(() => {
    let groupsArray = getCourseGroups(infoType._id, stringFilterUrl);
    groupsArray.then((res) => {
      getGroups(res.result);
    });
  }, []);

  const onChange = (e) => {
    let indexChange = groupsList.findIndex(
      (data) => data._id === e.target.name
    );
    
    let newArray = [...groupsList];
    newArray[indexChange].isSelected = e.target.checked;
    getGroups(newArray);
  };

  return emptyGroupsList ? (
    <section className={styles.empty_group_list_box}>
      <EmptyCard
        message={"No hay grupos que mostrar intenta cambiar los filtros"}
      />
    </section>
  ) : (
    <div>
      <h4 className="my-2">
        Desmarca los grupos a los que no quieres que se envie el mensaje
      </h4>
      <div className={styles.groups_list_container}>
        <ul>
          {groupsList.map((group, index) => {
            return (
              <div key={index} className={styles.div_item_icon}>
                <section>
                  <li>{`${index + 1}.-  ${group.name}`}</li>
                  <span>
                    Duración: {SetFechaTable(group.startDate)} -
                    {SetFechaTable(group.endDate)}
                  </span>
                </section>
                <span>{showStatus(group.active, true)}</span>
                <Checkbox
                  name={group._id}
                  checked={group.isSelected}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default GroupsList;
