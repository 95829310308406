import React, { useState } from "react";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Popconfirm } from "antd";

import Can from "../../../../../helpers/Secure/Can";
import MoreVertIcon from "mdi-react/MoreVertIcon";
import { dateDnumberMstringYY } from "../../../../../helpers/Fichas/functions";
import EmptyCard from "../../../../../helpers/Fichas/emptyCard";
import { newSchedules } from "../../../../../helpers/Globals/funciones";
import GroupChange from "../../groupChange/index";

import styles from "./index.module.scss";
import "antd/lib/popover/style/index.css";
import "../../../../../scss/component/motion.scss";

const GroupsTab = (props) => {
  const [modalChange, setModalChange] = useState(false);
  const [groupChange, setGroupChange] = useState("");

  const drawOrder = (items) => {
    items.sort((a, b) => a.phase - b.phase);
    return items.map((item) => <li key={item._id}>{item.name}</li>);
  };

  const openChange = (dataGroup) => {
    setModalChange(true);
    setGroupChange(dataGroup);
  };

  return (
    <>
      {props.actualStudentGroups.length === 0 ? (
        <EmptyCard message="No tiene cursos inscritos" />
      ) : (
        props.actualStudentGroups.map((dato, index) => {
          const newArray =
            dato !== undefined &&
            dato.schedules !== undefined &&
            dato.schedules.length > 0
              ? dato.schedules
              : [];
          return (
            <Card className={styles.groups_top_line} key={index}>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle className="card-button icon" outline>
                  <MoreVertIcon className="card-button-icon" />
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <Can do="put" on="/students/?/groups/?">
                    <DropdownItem onClick={() => openChange(dato)}>
                      Cambiar grupo
                    </DropdownItem>
                  </Can>
                  <Can do="post" on="/groups/?/receipt/?">
                    <Popconfirm
                      title="¿Estás seguro de reenviar el comprobante?"
                      okText="Si"
                      cancelText="No"
                      icon={
                        <i className="material-icons yellow_icon">warning</i>
                      }
                      placement="topLeft"
                      onConfirm={() => props.resendRegisterTicket(dato._id)}
                    >
                      <button className="dropdown-item">
                        Reenviar Comprobante
                      </button>
                    </Popconfirm>
                  </Can>
                  <Can do="delete" on="/students/?/group/?">
                    <Popconfirm
                      title="¿Estás seguro de eliminar el registro al grupo?"
                      okText="Si"
                      cancelText="No"
                      icon={
                        <i className="material-icons yellow_icon">warning</i>
                      }
                      placement="topLeft"
                      onConfirm={() => props.deleteRegisterGroup(dato._id)}
                    >
                      <button className="dropdown-item">Eliminar grupo</button>
                    </Popconfirm>
                  </Can>
                </DropdownMenu>
              </UncontrolledDropdown>
              <CardBody className={styles.card_size}>
                <h4>
                  {dato !== undefined && dato.courseInfo !== undefined
                    ? dato.courseInfo.name
                    : ""}
                </h4>
                <h5>
                  <b>Grupo:</b> {dato !== undefined ? dato.name : ""}
                </h5>
                <h5>
                  <b>Sede y Salón: </b>
                  {dato !== undefined && dato.locationInfo !== undefined
                    ? `${dato.locationInfo.name} ${dato.classRoomInfo.name}`
                    : ""}
                </h5>
                <h5>
                  <b>Inicia: </b>
                  {dateDnumberMstringYY(dato.startDate)}
                </h5>
                {newArray.length > 0 ? (
                  <h5>
                    <b>Horario(s): </b>
                    {newSchedules(newArray)}
                  </h5>
                ) : (
                  ""
                )}
                {props.studentSubCourses !== "" &&
                dato.courseInfo !== undefined &&
                dato.courseInfo.hasOwnProperty("isParent") ? (
                  <ul> {drawOrder(props.studentSubCourses)} </ul>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          );
        })
      )}
      {modalChange ? (
        <GroupChange
          valueModal={modalChange}
          close={setModalChange}
          groupChange={groupChange}
        />
      ) : null}
    </>
  );
};
export default GroupsTab;
