import React, { useReducer} from 'react';
import moment from 'moment'
import AppoimentsContext from './AppoimentsContext';
import AppoimentsReducer from './AppoimentsReducer';
import { fetchAsync, urlAppoiments } from '../../../helpers/Globals/globals';
import {
    GET_ALL_APPOIMENTS,
    SET_WEEKSELECTOR, SET_LOADING, SET_SELECTED
} from './AppoimentsTypes'


const initialState = {
    dateWeekSelector: moment(new Date()),
    dateArray: [],
    selected: {},
    closeSidebar: false,
    loading: true,
}

const AppoimentsState = (props) => {

    //crear dispatch y state
    const [state, dispatch] = useReducer(AppoimentsReducer, initialState)

    // useEffect(() => {
    //     getAppoiments();
    // }, [])

    //obtiene todos los cursos
    const getAppoiments = () => {
        fetchAsync(urlAppoiments, "", "GET", "")
            .then((data) => {
                if (data.success) {
                    dispatch({
                        type: GET_ALL_APPOIMENTS,
                        payload: data.dateArray
                    })
                }
                state.loading = false
            }).catch((reason) => {
                console.log(reason)
                state.loading = false
            });
    }
    const setDateWeekSelector = (value) => {
        dispatch({
            type: SET_WEEKSELECTOR,
            payload: value
        })
    }
    const setLoading = (value) => {
        console.log("setLoading", value)
        dispatch({
            type: SET_LOADING,
            payload: value
        })
    }

    const setSelected = (value) => {
        dispatch({
            type: SET_SELECTED,
            payload: value
        })
    }
    
    return (
        <AppoimentsContext.Provider
            value={{
                getAppoiments,
                closeSidebar: state.closeSidebar,
                dateArray: state.dateArray,
                selected: state.selected,
                setSelected,
                dateWeekSelector: state.dateWeekSelector,
                setDateWeekSelector,
                loading: state.loading,
                setLoading
            }}
        >
            {props.children}
        </AppoimentsContext.Provider>
    )
}
export default AppoimentsState

