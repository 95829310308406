import React, { useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { fetchAsync, urlGroupsLite } from "../../Globals/globals";
import moment from "moment";
import "moment/locale/es";
import TableCourse from "./tableCourse";
import TableGroup from "./tableGroup";
import LoadingComponent from "../../LoadingComponent";

import { DatePicker, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";

import styles from "./index.module.scss";
import "antd/es/date-picker/style/index.css";
import "antd/es/tooltip/style/index.css";

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const instructions = (
  <div>
    <ul>
      <li>
        Al seleccionar un curso se te mostrarán todos sus grupos cuya fecha de
        término aún no se ha alcanzado, si no te aparece el grupo que deseas
        agrega el filtro de fechas.
      </li>
      <li>
        El filtro de fechas funciona ingresando un intervalo de fechas, la fecha
        de inicio y fecha de finalización y presionar el botón aplicar, este
        filtro traerá todos los grupos que inicien y terminen en el rango de
        fechas dado.
      </li>
    </ul>
  </div>
);

const WizardSelector = (props) => {
  const [page, setPage] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [allGroups, setAllGroups] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  //estados de las fechas
  const [stRngStart, setStRngStart] = useState(
    moment.utc(new Date()).local().format()
  );
  const [endRngEnd, setEndRngEnd] = useState(
    moment.utc(new Date()).local().format()
  );

  //funciones para el calendario
  const onChangeStRngStart = (date, dateString) => {
    let localStRngStart = moment.utc(date[0]).local().format();
    let localEndRngEnd = moment.utc(date[1]).local().format();
    setStRngStart(localStRngStart);
    setEndRngEnd(localEndRngEnd);
  };

  const showStep = () => {
    switch (page) {
      case 1:
        return props.loadingCourses ? (
          <LoadingComponent />
        ) : (
          <div className="mt-2">
            <TableCourse
              saveSelectedCourse={saveCourse}
              idSelectedCourse={
                selectedCourse !== ""
                  ? selectedCourse._id
                  : props.newGroup !== ""
                  ? props.newGroup.course
                  : ""
              }
              coursesMedia={props.coursesMedia}
              coursesSuperior={props.coursesSuperior}
            />
          </div>
        );
      case 2:
        return loadingGroups ? (
          <LoadingComponent />
        ) : (
          <TableGroup
            groups={allGroups}
            saveNewGroup={props.saveNewGroup}
            newGroup={props.newGroup !== "" ? props.newGroup._id : ""}
          />
        );
      default:
        return "";
    }
  };

  //obtiene los grupos con filtros es lite
  const getAllGroups = (filterString) => {
    return fetchAsync(urlGroupsLite + filterString, "", "GET", "")
      .then((result) => {
        if (result.success) {
          let groups = result.groups.filter(
            (item) => item._id !== props.oldIdGroup
          );
          setAllGroups(groups);
        }
        setLoadingGroups(false);
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //agrega los filtros de fechas
  const onClickFilter = () => {
    setLoadingGroups(true);
    let filterString = `?stRngStart=${stRngStart}&endRngEnd=${endRngEnd}&course=${selectedCourse._id}`;
    getAllGroups(filterString);
  };

  //guardamos el curso seleccionado y obtenemos sus grupos
  const saveCourse = (course) => {
    let filterString = `?course=${course._id}`;
    //guardamos el curso seleccionado
    setSelectedCourse(course);
    //avanzamos el modal
    setPage(2);
    //activamos el loading
    setLoadingGroups(true);
    //obtenemos los grupos
    getAllGroups(filterString);
  };

  return (
    <Container>
      <Row className={styles.select_group_conatiner}>
        <Col xs={12} className={styles.buttons_container}>
          {page === 2 && (
            <Button
              color="success"
              size="sm"
              className="btn float-right"
              onClick={() => setPage(1)}
            >
              Regresar
            </Button>
          )}
          <Button
            size="sm"
            className="float-right mr-2"
            onClick={() => props.onCloseModal(true)}
          >
            Salir
          </Button>
          {page === 2 && (
            <Tooltip title={instructions} placement="rightTop">
              <span className={styles.tooltip_container}>
                <i className="material-icons">info</i>
              </span>
            </Tooltip>
          )}
        </Col>
        {page === 2 && (
          <Col xs={12} className={styles.calendar_button}>
            <RangePicker
              format={dateFormat}
              locale={locale}
              allowClear={false}
              onChange={onChangeStRngStart}
            />

            <Button
              color="success"
              size="sm"
              className="btn float-right"
              onClick={onClickFilter}
            >
              Aplicar
            </Button>
          </Col>
        )}
        <Col xs={12}>
          <div className="wizard">
            <div className="wizard__steps">
              <div
                className={`wizard__step${
                  page === 1 ? " wizard__step--active" : ""
                }`}
              >
                <p>ELIGE EL CURSO</p>
              </div>
              <div
                className={`wizard__step${
                  page === 2 ? " wizard__step--active" : ""
                }`}
              >
                <p>ELIGE EL GRUPO</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">
              <div className={styles.step_courses}>{showStep()}</div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default WizardSelector;
