import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Col, Row, Button, Card, CardBody } from 'reactstrap';

const ModalConfig = (props) => {
    return (
        <Modal
            open={props.value}
            onClose={()=>props.close()}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>
            <Col sm={12} md={12}>
                <Card>
                    <Row>
                        <Col xs={12} sm={4} md={8}>
                            <h4 className="bold-text mt-3">Configuración del equipo de escaneo de QR</h4>
                        </Col>
                        <Col xs={12} sm={8} md={4} className="mt-2">
                            <Button size="sm" className="btn float-right"
                                //color="success"
                                style={{ marginRight: "10px" }}
                                onClick={() => props.close()}>
                                <p>Salir</p>
                            </Button>
                        </Col>
                    </Row>
                    <CardBody>
                    </CardBody>
                </Card>
            </Col>
        </Modal>
    )
}

export default ModalConfig