import React, { useContext } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { newSchedules } from '../../../helpers/Globals/funciones';
import EnrollmentContext from '../../../context/EnrollmentContext/EnrollmentContext';
import moment from 'moment';
import 'moment/locale/es';

const EndRegister = () => {

  //importamos el context y sus estados y funciones
  const enrollmentContext = useContext(EnrollmentContext);
  const { studentData, selectedCourse, selectedGroup } = enrollmentContext;

  const newArray = selectedGroup.schedules !== undefined ? selectedGroup.schedules : []

  const sede = () => {
    if (selectedGroup.locationInfo) {
      if (selectedGroup.locationInfo.name !== undefined && selectedGroup.locationInfo.name !== null && selectedGroup.locationInfo.name !== "") {
        return (selectedGroup.locationInfo.name)
      } else {
        return ""
      }
    }
  }

  const salon = () => {
    if (selectedGroup.classRoomInfo) {
      if (selectedGroup.classRoomInfo.name !== undefined && selectedGroup.classRoomInfo.name !== null && selectedGroup.classRoomInfo.name !== "") {
        return (selectedGroup.classRoomInfo.name)
      } else {
        return ""
      }
    }
  }

  const { name, lastName, secondLastName } = studentData;

  return (
    <Col md={12} xl={12} sm={12} className="mt-2">
      <Card className="pricing-card pricing-card--success">
        <CardBody className="pricing-card__body">
          <div className="svg-container">
            <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
              <circle className="circle" fill="#5bb543" cx="24" cy="24" r="22" />
              <path className="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" stroke-strokemiterlimit="10" d="M14 27l5.917 4.917L34 17" />
            </svg>
          </div>
          <h2 className="pricing-card__plan mt-3">{`${name} ${lastName} ${secondLastName}`}</h2>
          <h3 className="pricing-card__plan">Quedó inscrito en</h3>
          <h2 className="pricing-card__plan">Curso elegido: {selectedCourse.name}</h2>
          <hr />
          <h4 className="mt-1">Grupo - <b>{selectedGroup.name}</b></h4>
          <h4 className="mt-1">Salón - <b>{salon()}</b></h4>
          <h4 className="mt-1">Fecha de inicio - <b>{moment(new Date(selectedGroup.startDate)).format("DD/MM/YYYY")}</b></h4>
          <h4 className="mt-1">Sede  - <b>{sede()}</b></h4>
          <h4 className="mt-1"><b>{selectedGroup.schedules !== undefined ? newSchedules(newArray) : ""}</b></h4>
        </CardBody>
      </Card>
    </Col>
  )
}
export default EndRegister;
