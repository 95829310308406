import React, { useEffect, useReducer } from "react";
import { Container, Col, Row, Button } from "reactstrap";

import Can from "../../../../../helpers/Secure/Can";
import { newGetPayments } from "./fetchFunctions";
import reducer, { initialState, types } from "./reducer";

import CollapsePayments from "./collapsePayments";
import LoadingComponent from "../../../../../helpers/LoadingComponent";
import EmptyCard from "../../../../../helpers/Fichas/emptyCard";
import PagosModal from "./PagosModal";

import PlusIcon from "mdi-react/PlusIcon";
import CreditCardAddIcon from "mdi-react/CreditCardAddIcon";

import styles from "./index.module.scss";
import "antd/es/collapse/style/index.css";
import "antd/es/statistic/style/index.css";

const StudentPayments = ({ onCloseModal, studentData, addPaymentsStudent }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    loading,
    noPayments,
    studentPaymentsByCourse,
    discountModal,
    paymentModal,
  } = state;

  useEffect(() => {
    dispatch({ type: types.SET_LOADING, payload: true });

    newGetPayments(studentData._id)
      .then((res) => {
        if (res.success) {
          if (res.coursePayments.length === 0) {
            dispatch({ type: types.SET_LOADING, payload: false });
            return dispatch({ type: types.SET_NO_PAYMENTS, payload: true });
          }

          dispatch({
            type: types.SET_STUDENT_PAYMENTS_BY_COURSE,
            payload: res.coursePayments,
          });
          dispatch({ type: types.SET_LOADING, payload: false });
        }
      })
      .catch((reason) => {
        dispatch({ type: types.SET_LOADING, payload: false });
        console.log(reason.message);
      });
  }, [studentData]);

  const showHideModalPayments = (status) => {
    dispatch({ type: types.SHOW_MODAL_PAYMENT, payload: status });
  };

  const showHideModalDiscount = (status) => {
    dispatch({ type: types.SHOW_MODAL_DISCOUNT, payload: status });
  };

  const reloadAfterPayment = () => {
    newGetPayments(studentData._id)
      .then((res) => {
        if (res.success) {
          dispatch({
            type: types.SET_STUDENT_PAYMENTS_BY_COURSE,
            payload: res.coursePayments,
          });
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const afterSuccessDeletePayment = (id) => {
    reloadAfterPayment(id);
    //reload the main table and sidebar data payments
    addPaymentsStudent(id);
  };

  return (
    <Container>
      <Row>
        <Col xs="12" className={styles.button_container}>
          <Button
            size="sm"
            className={`btn btn-secondary float-right`}
            onClick={onCloseModal}
          >
            Salir
          </Button>
        </Col>
        <Col xs="12" className={styles.top_buttons_conatiner}>
          <Can do="post" on="/payments/">
            <Button
              size="sm"
              className="btn float-right ml-3"
              outline
              color="success"
              onClick={() => showHideModalPayments(true)}
            >
              <p>
                <CreditCardAddIcon />
                Registrar pago
              </p>
            </Button>
            <Button
              size="sm"
              className="btn float-right"
              outline
              color="success"
              onClick={() => showHideModalDiscount(true)}
            >
              <p>
                <PlusIcon />
                Descuento
              </p>
            </Button>
          </Can>
        </Col>
        <Col xs="12" className={styles.container_payments}>
          {loading ? (
            <LoadingComponent />
          ) : noPayments ? (
            <EmptyCard message="No hay pagos agregados." />
          ) : (
            <CollapsePayments
              studentPaymentsByCourse={studentPaymentsByCourse}
              afterSuccessDeletePayment={afterSuccessDeletePayment}
            />
          )}
        </Col>
      </Row>
      {paymentModal && (
        <PagosModal
          valueModal={paymentModal}
          close={showHideModalPayments}
          titulo={"Registrar pago"}
          _idEstudiente={studentData._id}
          reloadAfterPayment={reloadAfterPayment}
          addPaymentsStudent={addPaymentsStudent}
        />
      )}

      {discountModal && (
        <PagosModal
          valueModal={discountModal}
          close={showHideModalDiscount}
          titulo={"Registrar descuento"}
          _idEstudiente={studentData._id}
          discount={true}
          reloadAfterPayment={reloadAfterPayment}
          addPaymentsStudent={addPaymentsStudent}
        />
      )}
    </Container>
  );
};
export default StudentPayments;
