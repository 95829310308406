import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Avatar, Comment, message } from 'antd';
import { Button } from 'reactstrap';
import moment from 'moment';

import { randomColor } from "../../../../../helpers/Fichas/constants";
import DeleteButtonConfirm from "../../../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";
import Can from "../../../../../helpers/Secure/Can";
import { editCommentById, deleteCommentById } from './fetchFunctions'

import styles from './index.module.scss';

const { TextArea } = Input;

const ShowComment = ({ commentData, index, updateAfterEdit, updateAfterDelete }) => {

  const [textEditComment, setTextEditComment] = useState("");
  const [showTextarea, setShowTextarea] = useState(false);
  const [disable, setDisable] = useState(false);

  const { comment = "", date = "", user = null } = commentData

  const onChangeTextarea = (value) => {
    setTextEditComment(value)
  }

  const onEdit = () => {
    setShowTextarea(!showTextarea)
    setTextEditComment(commentData.comment)
  }

  const resetData = () => {
    setDisable(false)
    setShowTextarea(false)
    setTextEditComment("")
  }

  const onSaveEditComment = () => {
    if (textEditComment == "") return message.warning(`Debes escribir un comentario`)
    setDisable(true)
    let body = { "comment": textEditComment.trim() }
    editCommentById(commentData._id, body).then((res) => {
      resetData()
      if (res.success) updateAfterEdit(res.comment)
    }).catch((error) => {
      setDisable(false)
      console.log(error)
    })
  }

  const actions = [
    <Can do="put" on="/comments/?">
      <Button key={1} size="sm" className="btn mr-4" color="success"
        onClick={onEdit} >
        Editar
      </Button>
    </Can>,
    <Can do="delete" on="/comments/?">
      <DeleteButtonConfirm title="¿Deseas eliminar el comentario?"
        deleteFunction={deleteCommentById}
        id={commentData?._id}
        onCloseOptionalFunc={updateAfterDelete}
      />
    </Can>
  ];

  return (
    <div key={index} className={styles.show_comments_container}>
      {showTextarea ?
        <div>
          <TextArea rows={3}
            value={textEditComment}
            onChange={(e) => onChangeTextarea(e.target.value)}
            maxLength={500}
          />
          <Button key={1} size="sm" className="btn mr-4 mt-2" color="success"
            onClick={onSaveEditComment} disabled={disable}>
            Guardar cambios
          </Button>
          <Button size="sm" className={`btn btn-secondary mt-2`}
            onClick={onEdit} disabled={disable}>
            Descartar
          </Button>
          <span className="mt-1 float-right">Caracteres restantes {500 - textEditComment.length}</span>
        </div>
        :
        <Comment key={index}
          actions={actions}
          author={<a>{`Por ${user?.name}`}</a>}
          avatar={<Avatar
            style={{ backgroundColor: randomColor(user?.name[0].toUpperCase()) }} size={50}
            alt="No encontrado"
          >
            {user?.name[0].toUpperCase()}
          </Avatar>
          }
          content={<p>{comment}</p>}
          datetime={<span> {moment(date).format('DD-MM-YYYY hh:mm A')}</span>}
        />
      }
    </div >
  )
}

ShowComment.propTypes = {
  commentData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateAfterEdit: PropTypes.func.isRequired,
  updateAfterDelete: PropTypes.func.isRequired,
}

export default ShowComment