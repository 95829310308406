import React, { useContext } from 'react';
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import { SocketContext } from 'context/SocketContext'

import 'antd/es/badge/style/index.css'

const Logo = `${process.env.PUBLIC_URL}/img/pipn.png`;

function Topbar(props) {
  const socketContext = useContext(SocketContext)
  const { isConnected } = socketContext
  const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <div className={props.sidebarCollapse ? "side_bar_space collapsed" : "side_bar_space"} />
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
            sidebarCollapse={true}
          />
          <Link className="topbar__logo" to="/dashboard_default" >
          {/* <img src={Logo} alt="Profile" /> */}
          {/* todo! Ned to be change to the new logo */}
          </Link>
        </div>
        <div className="topbar__right">
          <Badge.Ribbon text={isConnected ? "Conectado" : "Desconectado"} color={isConnected ? "#2ecc71" : "#ff4861"}>
            <TopbarProfile />
          </Badge.Ribbon>
          <div className={props.sidebarCollapseComplement ? "side_bar_space_complement collapsed" : "side_bar_space_complement"} />
        </div>
      </div>
    </div>
  );
}

export default Topbar;
