import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import QrScanView from './components/qrScanView'
import ModalConfig from './components/modalConfig'

import styles from './index.module.scss'

const AttendanceQr = () => {
    // const [loading, setLoading] = useState(false);
    const [valueModal, setValueModal] = useState(false)
    return (
        <Container className="dashboard">
            <Row>
                <Col xs={12} sm={12} md={7} className={`mt-2 ${styles.titles_components}`}>
                    <div className="typography--inline">
                        <p className="subhead">Administración Escolar |</p>
                        <p style={{ color: "#3057af", marginLeft: "5px" }}>Asignación de módulo de comipems</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <QrScanView />
            </Row>
            <ModalConfig
                value={valueModal}
                close={() => setValueModal(false)}
            />
        </Container>
    )
}

export default AttendanceQr