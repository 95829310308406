import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { GroupsContext } from "../../../../../context/groups";
import moment from "moment";
import "moment/locale/es";
import StepCourse from "./stepCourse";
import StepForm from "./stepForm";
import StepEndRegister from "./stepEndRegister";
import Buttons from "./buttons";

import styles from "./index.module.scss";

const inicialState = {
  name: "",
  startDate: moment.utc(new Date()).local().format(),
  endDate: moment.utc(new Date()).local().format(),
  schedules: [],
  active: true,
  activeLanding: false,
  teacher: "",
  quota: "",
  course: "",
  classRoom: "",
  location: "",
};

const WizardForm = (props) => {
  //context
  const groupsContext = useContext(GroupsContext);
  const {
    getCoursesAddGroup,
    getUsers,
    getAllGroups,
    teachers,
    availableClassroom,
    addGroup,
    updateCreate,
  } = groupsContext;

  useEffect(() => {
    getCoursesAddGroup();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //states
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [newGroupData, setNewGroupData] = useState(inicialState);
  const [page, setPage] = useState(1);

  const saveSelectedCourse = (course) => {
    setSelectedCourse(course);
    setNewGroupData({
      ...newGroupData,
      course: course._id,
    });
  };

  const showStep = () => {
    switch (page) {
      case 1:
        return (
          <StepCourse
            saveSelectedCourse={saveSelectedCourse}
            idSelectedCourse={selectedCourse !== null ? selectedCourse._id : ""}
          />
        );
      case 2:
        return (
          <StepForm
            newGroupData={newGroupData}
            setNewGroupData={setNewGroupData}
            selectedCourse={selectedCourse}
            teachers={teachers}
          />
        );
      case 3:
        return <StepEndRegister />;
      default:
        return "";
    }
  };

  return (
    <Container>
      <Row className={styles.add_group_modal}>
        <Col xs="12">
          <Buttons
            page={page}
            setPage={setPage}
            onCloseModal={props.onCloseModal}
            addGroup={addGroup}
            newGroupData={newGroupData}
            selectedCourse={selectedCourse}
            availableClassroom={availableClassroom}
            getAllGroups={getAllGroups}
            updateCreate={updateCreate}
          />
        </Col>

        <Col xs="12">
          <div className="wizard">
            <div className="wizard__steps">
              <div
                className={`wizard__step${
                  page === 1 ? " wizard__step--active" : ""
                }`}
              >
                <p>ELIGE EL CURSO</p>
              </div>
              <div
                className={`wizard__step${
                  page === 2 ? " wizard__step--active" : ""
                }`}
              >
                <p>FORMULARIO</p>
              </div>
              <div
                className={`wizard__step${
                  page === 3 ? " wizard__step--active" : ""
                }`}
              >
                <p>FINALIZAR REGISTRO</p>
              </div>
            </div>
            <div className="wizard__form-wrapper">{showStep()}</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default WizardForm;
