import React, { useState } from 'react';
import { LoadScriptNext, GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { ApiKey } from 'helpers/Fichas/constants';

const mapContainerStyle = {
  height: "300px",
  width: "100%"
}

const mapOptions = {
  fullscreenControl: true,
  streetView: true,
  mapTypeControl: false,
}

const MyMapComponent = (props) => {
  const [sede, setSede] = useState(null);
  return (
    <LoadScriptNext
      googleMapsApiKey={ApiKey}
      onError={() => console.log("ERROR")}
    >
      <GoogleMap
        zoom={15}
        options={mapOptions}
        mapContainerStyle={mapContainerStyle}
        center={{ lat: Number(props.latitud), lng: Number(props.longitud) }}>
        <Marker
          position={{ lat: Number(props.latitud), lng: Number(props.longitud) }}
          onClick={() => {
            setSede(true);
          }}
        >
          {sede ?
            <InfoWindow
              position={{ lat: Number(props.latitud), lng: Number(props.longitud) }}
              onCloseClick={() => {
                setSede(false);
              }}
            >
              <div>
                <p>Sede: {props.name}</p>
                <p>Calle: {props.street}</p>
              </div>
            </InfoWindow>
            : ""
          }
        </Marker>
      </GoogleMap>
    </LoadScriptNext>
  )
}

export default MyMapComponent




