import React, { useState, useContext, useEffect } from "react";
import { DatePicker, Select, Tooltip, Divider, Input } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";

import { GroupsContext } from "../../../../context/groups";

import {
  fetchAsync,
  urlSedes,
  urlUsers,
} from "../../../../helpers/Globals/globals";

import styles from "./index.module.scss";
import "antd/es/date-picker/style/index.css";
import "antd/es/select/style/index.css";
import "antd/es/divider/style/index.css";
import "antd/es/switch/style/index.css";
import "antd/es/tooltip/style/index.css";

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const InputGroup = Input.Group;
const { Option, OptGroup } = Select;

const GroupFilters = () => {
  //context
  const groupsContext = useContext(GroupsContext);
  const {
    coursesMedia,
    coursesSuperior,
    getAllGroups,
    filterUrlString,
    filterUrlArray,
    setUrlFilters,
  } = groupsContext;

  const [teachers, setTeachers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [momentDateArray, setMomentDateArray] = useState([]);

  useEffect(() => {
    fetchAsync(urlSedes, "", "GET", "")
      .then((data) => {
        if (data.success) {
          setLocations(data.locations);
          //set the value if already esxits
          if (filterUrlString.includes("location")) {
            let indexArray = null;
            filterUrlArray.forEach((filter, index) => {
              if (filter.includes("location")) indexArray = index;
            });

            let idToFind = filterUrlArray[indexArray].split("=")[1];
            let locationObj = data.locations.find(
              (dataLocation) => dataLocation._id === idToFind
            );
            setSelectedLocation(locationObj.name);
          }
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAsync(urlUsers, "", "GET", "")
      .then((result) => {
        if (result.success) {
          setTeachers(result.users);

          if (filterUrlString.includes("teacher")) {
            let indexArray = null;
            filterUrlArray.forEach((filter, index) => {
              if (filter.includes("teacher")) indexArray = index;
            });

            let idToFind = filterUrlArray[indexArray].split("=")[1];
            let tecaherObj = result.users.find(
              (dataTeacher) => dataTeacher._id === idToFind
            );
            setSelectedTeacher(
              `${tecaherObj.name} ${tecaherObj.lastName} ${tecaherObj.secondLastName}`
            );
          }
        }
      })
      .catch((reason) => {
        console.log(reason.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterUrlString.includes("course")) {
      let combinedArraysCourses = [...coursesMedia, ...coursesSuperior];

      let indexArray = null;
      filterUrlArray.forEach((filter, index) => {
        if (filter.includes("course")) indexArray = index;
      });

      let idToFind = filterUrlArray[indexArray].split("=")[1];
      let locationObj = combinedArraysCourses.find(
        (dataCourse) => dataCourse._id === idToFind
      );

      setSelectedCourse(locationObj.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterUrlString.includes("stRngStart")) {
      let arrayUrlParams = filterUrlString.split("&");
      let indexArrayStart = null;
      let indexArrayEnd = null;

      arrayUrlParams.forEach((filter, index) => {
        if (filter.includes("stRngStart")) indexArrayStart = index;
        if (filter.includes("endRngEnd")) indexArrayEnd = index;
      });

      let startDateMoment = moment(
        arrayUrlParams[indexArrayStart].split("=")[1]
      );
      let endDateMoment = moment(arrayUrlParams[indexArrayEnd].split("=")[1]);
      let newArray = [startDateMoment, endDateMoment];
      setMomentDateArray(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //muestra la lista de sedes
  const showLocation = () => {
    return locations.map((value) => {
      return (
        <Option key={value._id} value={value.name + "_" + value._id}>
          {value.name} {value.lastName} {value.secondLastName}
        </Option>
      );
    });
  };

  //maneja el evento del selector de sedes
  const handleChangeLocation = (value) => {
    if (value) {
      if (filterUrlString.includes("location")) {
        let arrayUrlParams = filterUrlString.split("&");
        let copyUrl = filterUrlString;
        let indexArray = null;
        arrayUrlParams.forEach((filter, index) => {
          if (filter.includes("location")) indexArray = index;
        });
        let url = copyUrl.replace("&" + arrayUrlParams[indexArray], "");
        url = `${url}&location=${value.split("_")[1]}`;
        setSelectedLocation(value.split("_")[0]);
        setUrlFilters(url);
        const result = getAllGroups(url);
        result.then((res) => {});
        return;
      }

      let newUrl = `${filterUrlString}&location=${value.split("_")[1]}`;
      setSelectedLocation(value.split("_")[0]);
      setUrlFilters(newUrl);
      const result = getAllGroups(newUrl);
      result.then((res) => {});
    } else {
      let arrayUrlParams = filterUrlString.split("&");
      let indexArray = null;

      arrayUrlParams.forEach((filter, index) => {
        if (filter.includes("location")) indexArray = index;
      });

      let copyUrl = filterUrlString;
      let url = copyUrl.replace("&" + arrayUrlParams[indexArray], "");
      setSelectedLocation("");
      setUrlFilters(url);

      const result = getAllGroups(url);
      result.then((res) => {});
    }
  };

  //agrega el cursoId seleccionado
  const handleSelectCourse = (value) => {
    if (value) {
      if (filterUrlString.includes("course")) {
        let arrayUrlParams = filterUrlString.split("&");
        let copyUrl = filterUrlString;
        let indexArray = null;
        arrayUrlParams.forEach((filter, index) => {
          if (filter.includes("course")) indexArray = index;
        });
        let url = copyUrl.replace("&" + arrayUrlParams[indexArray], "");
        url = `${url}&course=${value.split("_")[1]}`;
        setUrlFilters(url);
        setSelectedCourse(value.split("_")[0]);
        const result = getAllGroups(url);
        result.then((res) => {});
        return;
      }

      let newUrl = `${filterUrlString}&course=${value.split("_")[1]}`;
      setUrlFilters(newUrl);
      setSelectedCourse(value.split("_")[0]);
      const result = getAllGroups(newUrl);
      result.then((res) => {});
    } else {
      let arrayUrlParams = filterUrlString.split("&");
      let indexArray = null;

      arrayUrlParams.forEach((filter, index) => {
        if (filter.includes("course")) indexArray = index;
      });

      let copyUrl = filterUrlString;
      let url = copyUrl.replace("&" + arrayUrlParams[indexArray], "");
      setUrlFilters(url);
      setSelectedCourse("");

      const result = getAllGroups(url);
      result.then((res) => {});
    }
  };

  //muestra las opciones de media superior
  const showOptionsCoursesMedia = () => {
    return coursesMedia.map((value, index) => {
      return (
        <Option key={index} value={value.name + "_" + value._id}>
          {value.name}
        </Option>
      );
    });
  };

  //muestra las opciones de superior
  const showOptionsCoursesSuperior = () => {
    return coursesSuperior.map((value, index) => {
      return (
        <Option key={index} value={value.name + "_" + value._id}>
          {value.name}
        </Option>
      );
    });
  };

  //maneja el evento del selector de profesores
  const handleChangeTeacher = (value) => {
    if (value) {
      if (filterUrlString.includes("teacher")) {
        let arrayUrlParams = filterUrlString.split("&");
        let copyUrl = filterUrlString;
        let indexArray = null;
        arrayUrlParams.forEach((filter, index) => {
          if (filter.includes("teacher")) indexArray = index;
        });
        let url = copyUrl.replace("&" + arrayUrlParams[indexArray], "");
        url = `${url}&teacher=${value.split("_")[1]}`;
        setUrlFilters(url);
        setSelectedTeacher(value.split("_")[0]);
        const result = getAllGroups(url);
        result.then((res) => {});
        return;
      }

      let newUrl = `${filterUrlString}&teacher=${value.split("_")[1]}`;
      setUrlFilters(newUrl);
      setSelectedTeacher(value.split("_")[0]);
      const result = getAllGroups(newUrl);
      result.then((res) => {});
    } else {
      let arrayUrlParams = filterUrlString.split("&");
      let indexArray = null;

      arrayUrlParams.forEach((filter, index) => {
        if (filter.includes("teacher")) indexArray = index;
      });

      let copyUrl = filterUrlString;
      let url = copyUrl.replace("&" + arrayUrlParams[indexArray], "");
      setUrlFilters(url);
      setSelectedTeacher("");

      const result = getAllGroups(url);
      result.then((res) => {});
    }
  };

  //muestra la lista de profesores
  const showTeachers = () => {
    return teachers.map((value) => {
      return (
        <Option
          key={value._id}
          value={
            `${value.name} ${value.lastName} ${value.secondLastName}` +
            "_" +
            value._id
          }
        >
          {value.name} {value.lastName} {value.secondLastName}
        </Option>
      );
    });
  };

  //funciones para el calendario
  const onChangeRangepicker = (date, dateString) => {
    if (date) {
      let localStRngStart = moment.utc(date[0]).local().format();
      let localEndRngEnd = moment.utc(date[1]).local().format();

      if (filterUrlString.includes("stRngStart")) {
        let arrayUrlParams = filterUrlString.split("&");
        let copyUrl = filterUrlString;

        let indexArrayStart = null;
        let indexArrayEnd = null;

        arrayUrlParams.forEach((filter, index) => {
          if (filter.includes("stRngStart")) indexArrayStart = index;
          if (filter.includes("endRngEnd")) indexArrayEnd = index;
        });

        let url = copyUrl
          .replace("&" + arrayUrlParams[indexArrayStart], "")
          .replace("&" + arrayUrlParams[indexArrayEnd], "");

        url = `${url}&stRngStart=${localStRngStart}&endRngEnd=${localEndRngEnd}`;
        setUrlFilters(url);
        setMomentDateArray(date);

        const result = getAllGroups(url);
        result.then((res) => {});
        return;
      }

      let newUrl = `${filterUrlString}&stRngStart=${localStRngStart}&endRngEnd=${localEndRngEnd}`;
      setUrlFilters(newUrl);
      setMomentDateArray(date);
      const result = getAllGroups(newUrl);
      result.then((res) => {});
    } else {
      let arrayUrlParams = filterUrlString.split("&");
      let indexArrayStart = null;
      let indexArrayEnd = null;

      arrayUrlParams.forEach((filter, index) => {
        if (filter.includes("stRngStart")) indexArrayStart = index;
        if (filter.includes("endRngEnd")) indexArrayEnd = index;
      });

      let copyUrl = filterUrlString;
      let url = copyUrl
        .replace("&" + arrayUrlParams[indexArrayStart], "")
        .replace("&" + arrayUrlParams[indexArrayEnd], "");
      setUrlFilters(url);
      setMomentDateArray([]);

      const result = getAllGroups(url);
      result.then((res) => {});
    }
  };

  return (
    <div className={styles.filters_main_container}>
      <section className={styles.selectors_box}>
        <Divider orientation="left">Filtrar por sedes.</Divider>
        <Select
          value={selectedLocation}
          allowClear
          showSearch
          placeholder="Selecciona una sede"
          onChange={handleChangeLocation}
        >
          {showLocation()}
        </Select>
      </section>

      <section className={styles.selectors_box}>
        <Divider orientation="left">Filtrar por curso.</Divider>

        <InputGroup compact>
          <Select
            value={selectedCourse}
            allowClear
            showArrow
            showSearch
            onChange={handleSelectCourse}
            placeholder="Selecciona un curso"
          >
            <OptGroup
              key={1}
              label={
                <div className={styles.types_divider_text}>
                  <span className="colorTextSelect">NIVEL MEDIA SUPERIOR</span>
                </div>
              }
            >
              {showOptionsCoursesMedia()}
            </OptGroup>
            <OptGroup
              key={2}
              label={
                <div className={styles.types_divider_text}>
                  <span className="colorTextSelect">NIVEL SUPERIOR</span>
                </div>
              }
            >
              {showOptionsCoursesSuperior()}
            </OptGroup>
          </Select>
        </InputGroup>
      </section>

      <section className={styles.selectors_box}>
        <Divider orientation="left">Filtrar por profesor.</Divider>
        <Select
          value={selectedTeacher}
          allowClear
          showSearch
          placeholder="Selecciona un Profesor"
          onChange={handleChangeTeacher}
        >
          {showTeachers()}
        </Select>
      </section>

      <section className={styles.rangepicker_box}>
        <Divider orientation="left">Filtrar por rango de fechas</Divider>
        <RangePicker
          value={momentDateArray}
          format={dateFormat}
          locale={locale}
          allowClear
          onChange={onChangeRangepicker}
        />
      </section>
    </div>
  );
};

export default GroupFilters;
