import {
  fetchAsync,
  urlTemplates,
  urlNotificationGroups,
  urlNotificationStudent,
  urlNotificationUserTalk,
  urlNotificationTalks,
  urlNotificationCourse,
  urlNotificationGetGroupsByCourse,
} from "../../../helpers/Globals/globals";
import { message } from "antd";

export const getHtmlFromTemplate = async () => {
  const result = fetchAsync(
    `${urlTemplates}d-a934c1e29ac94e4eb91061956d620114/versions/1aaf32ea-1048-491c-80d7-2030e89f2f35`,
    "",
    "GET",
    ""
  );
  return result;
};

export const getCourseGroups = async (courseId, stringFilterUrl) => {
  const result = await fetchAsync(
    `${urlNotificationGetGroupsByCourse}${courseId}${stringFilterUrl}`,
    "",
    "GET",
    ""
  );
  return result;
};

export const sendFetchTypes = (type, infoType, body) => {
  let loadingMessage = message.loading("Enviando el mensaje..");
  switch (type) {
    case "student":
      return fetchAsync(
        urlNotificationStudent + infoType._id,
        JSON.stringify(body),
        "POST",
        ""
      )
        .then((res) => {
          if (res.success) {
            loadingMessage.then(() =>
              message.success("Mensaje enviado exitosamente", 6)
            );
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
          loadingMessage.then(() => {
            return message.error("Ocurrio un error", 5);
          });
          return err;
        });

    case "talkStudent":
      return fetchAsync(
        urlNotificationUserTalk + infoType._id,
        JSON.stringify(body),
        "POST",
        ""
      )
        .then((res) => {
          if (res.success) {
            loadingMessage.then(() =>
              message.success("Mensaje enviado exitosamente", 6)
            );
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
          loadingMessage.then(() => {
            return message.error("Ocurrio un error", 5);
          });
          return err;
        });

    case "talks":
      return fetchAsync(
        urlNotificationTalks + infoType._id,
        JSON.stringify(body),
        "POST",
        ""
      )
        .then((res) => {
          if (res.success) {
            loadingMessage.then(() =>
              message.success("Mensaje enviado exitosamente", 6)
            );
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
          loadingMessage.then(() => {
            return message.error("Ocurrio un error", 6);
          });
          return err;
        });

    case "groups":
      //filterByDebt: 1 = todos , 2= liquidados, 3 adeudos
      let url =
        infoType.filterByDebt === 1
          ? urlNotificationGroups + infoType._id
          : urlNotificationGroups +
            infoType._id +
            `?debt=${infoType.filterByDebt === 2 ? false : true}`;

      return fetchAsync(url, JSON.stringify(body), "POST", "")
        .then((res) => {
          if (res.success) {
            loadingMessage.then(() =>
              message.success("Mensaje enviado exitosamente", 6)
            );
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
          loadingMessage.then(() => {
            return message.error("Ocurrio un error", 5);
          });
          return err;
        });

    case "courses":
      return fetchAsync(urlNotificationCourse, JSON.stringify(body), "POST", "")
        .then((res) => {
          if (res.success) {
            loadingMessage.then(() =>
              message.success("Mensaje enviado exitosamente", 6)
            );
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
          loadingMessage.then(() => {
            return message.error("Ocurrio un error", 5);
          });
          return err;
        });

    default:
      break;
  }
};
