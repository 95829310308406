import React, { useState, useContext } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";
import statusTable from "../../../../../helpers/Tables/statusTable";
import SortTable from "../../../../../helpers/Tables/sortTable";
import EmptyComponent from "../../../../../helpers/Empty/EmptyComponent";
import { GroupsContext } from "../../../../../context/groups";

import styles from "./index.module.scss";

const StepCourse = (props) => {
  //context
  const groupsContext = useContext(GroupsContext);
  const { coursesMedia, coursesSuperior } = groupsContext;

  const [activeTab, setActiveTab] = useState("1");

  //funcion para el cambio entre tabs
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //Funcion que enumera las filas de la lista
  const generaNum = (cell, row, rowIndex) => {
    if (row) {
      return rowIndex + 1;
    } else {
      return "";
    }
  };

  //genera el dot para activo o inactivo
  const disponible = (cell, row) => {
    return statusTable(row.active);
  };

  //maneja el click en la tabla
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log(row);
      props.saveSelectedCourse(row);
    },
  };

  //pinta el row previamente selecionado
  const rowColor = (cell, row) => {
    let rowStyle = { backgroundColor: "#CACFD2" };
    if (props.idSelectedCourse === cell._id) {
      return rowStyle;
    }
  };

  const headerSortingStyle = { backgroundColor: "#D6DBDF" };
  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: generaNum,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "30px", textAlign: "center" };
      },
      headerSortingStyle,
      align: "center",
    },
    {
      dataField: "name",
      text: "Cursos",
      sort: true,
      headerAlign: "center",
      align: "center",
      sortCaret: (order, column) => {
        return <SortTable order={order} colum={column} />;
      },
      headerSortingStyle,
    },
    {
      dataField: "active",
      text: "Estatus",
      headerAlign: "center",
      align: "center",
      formatter: disponible,
    },
  ];

  const showSubcourses = (row) => {
    return (
      <div className={styles.sub_courses_list}>
        <h4 className={row.isParent ? "" : "collapse"}>Subcursos:</h4>

        {row.isParent ? (
          row.children.map((child) => {
            return (
              <div className="ml-5" key={child._id}>
                <div>
                  <ul>
                    <li>{child.name}</li>
                  </ul>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyComponent message={"No tiene subcursos asignados"} />
        )}
      </div>
    );
  };

  const expandRow = {
    renderer: (row) => showSubcourses(row),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="material-icons">remove_circle</i>;
      }
      return <i className="material-icons">add_circle</i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <i className="material-icons">remove_circle_outline</i>;
      }
      return <i className="material-icons">add_circle_outline</i>;
    },
  };

  return (
    <div className="tabs tabs--justify tabs--bordered-top mt-2">
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggle("1")}
            >
              Media Superior
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggle("2")}
            >
              Superior
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-1">
          <TabPane tabId="1">
            <section className={styles.tables_container}>
              <BootstrapTable
                keyField={"_id"}
                data={coursesMedia}
                columns={columns}
                expandRow={expandRow}
                rowEvents={rowEvents}
                rowStyle={rowColor}
                hover
                bordered={false}
              />
            </section>
          </TabPane>
          <TabPane tabId="2">
            <section className={styles.tables_container}>
              <BootstrapTable
                keyField={"_id"}
                data={coursesSuperior}
                columns={columns}
                rowEvents={rowEvents}
                rowStyle={rowColor}
                hover
                bordered={false}
              />
            </section>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};
export default StepCourse;
