import React, { PureComponent } from 'react';
import { Avatar } from 'antd';

import LoadingComponent from "../../../helpers/LoadingComponent"
import EmptyComponent from "../../../helpers/Empty/EmptyComponent";
import { randomColor } from '../../../helpers/Fichas/constants'

//Styles for Popconfirm
import styles from './index.module.scss'
import 'antd/lib/avatar/style/index.css';
import 'antd/lib/popover/style/index.css'
import '../../../scss/component/motion.scss'

class UsersList extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({
            loading: true,
        })
        let result = this.props.getRolesUsers()
        result.then(() => {
            this.setState({
                loading: false,
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rol._id !== this.props.rol._id) {
            this.setState({
                loading: true,
            })
            let result = this.props.getRolesUsers()
            result.then(() => {
                this.setState({
                    loading: false,
                })
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ? <LoadingComponent /> : this.props.roleUsers.length === 0 ?
                    <EmptyComponent message="No hay usuarios registrados en este role" /> :
                    this.props.roleUsers.map((user, index) => (
                        <li className={styles.user_list_sidebar} key={index}>
                        {/* <li className="nombresLista" key={index}> */}
                            <Avatar style={{ backgroundColor: randomColor(user.name[0].toUpperCase()) }} size="small">
                                {user.name[0]}
                            </Avatar>
                            <span >{` ${user.name} ${user.lastName} ${user.secondLastName}`}</span>
                            <hr />
                        </li>
                    ))}
            </div>
        );
    }
}
export default UsersList;
