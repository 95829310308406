import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-responsive-modal";
import { Col, Row, Button } from "reactstrap";
import { Input, Select, TreeSelect, Switch } from "antd";

import Can from "../../../../helpers/Secure/Can";
import UploadImageModal from "../../../../helpers/uploadImage/";
import ImageContentComponent from "../../../../helpers/uploadImage/imageComponent";
import { showMessageWarning } from "../../../../helpers/MessageAndNotificationUtils";

import CourseContext from "../../../../context/courses/CourseContext";
import GeneraLista, { ValidateCourse } from "./ShowDiscounts";
import treeData from "./treeData";
import { Header } from "./Headers";
import DeleteButtonConfirm from "../../../../helpers/DeleteButtonConfirm/DeleteButtonConfirm";

import CalendarIcon from "mdi-react/CalendarIcon";
import ClassIcon from "mdi-react/ClassIcon";
import MoneyIcon from "mdi-react/MoneyIcon";
import SelectTopics from "./selectTopics";

import styles from "./index.module.scss";
import "antd/es/switch/style/index.css";
import "antd/es/select/style/index.css";
import "antd/es/input/style/index.css";
import "antd/es/tree-select/style/index.css";

const InputGroup = Input.Group;
const { Option, OptGroup } = Select;
const { SHOW_PARENT } = TreeSelect;

function AddEditCourses({ title, value, close, edit, killSideBar }) {
  const courseContext = useContext(CourseContext);
  const {
    actualCourse,
    courseTypes,
    courses,
    coursesMedia,
    createCourse,
    coursesSuperior,
    courseLevels,
    disableButton,
    editCourse,
    deleteCourse,
  } = courseContext;

  const initialState = {
    name: "",
    //image: '',
    duration: "",
    price: "",
    active: true,
    courseLevel: "",
    courseType: "",
    discounts: [],
    schedulesAppoimentList: [],
  };

  //estados
  const [course, setCourse] = useState(initialState);
  const [childrenArray, setChildrenArray] = useState([]);
  const [actaualDiscount, setActaualDiscount] = useState("");
  const [courseIdDiscount, setCourseIdDiscount] = useState("");
  // const [eliminar, setEliminar] = useState(false);
  const [valueModalImage, setValueModalImage] = useState(false);

  //useEfect para setear datos al editar
  useEffect(() => {
    if (edit) {
      setCourse({
        name: actualCourse.name,
        image: actualCourse.image,
        duration: actualCourse.duration,
        price: actualCourse.price,
        active: actualCourse.active,
        courseLevel: actualCourse.courseLevel,
        courseType: actualCourse.courseType,
        discounts: actualCourse.discounts,
        schedulesAppoimentList: actualCourse.schedulesAppoimentList,
        isActiveAppoiments: actualCourse.isActiveAppoiments,
      });
      setActaualDiscount("");
      setCourseIdDiscount("");
      // setEliminar(true);
    } else {
      setCourse(initialState);
      setChildrenArray([]);
      setActaualDiscount("");
      setCourseIdDiscount("");
      // setEliminar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    //reseteamos los estados
    setCourse(initialState);
    setChildrenArray([]);
    setActaualDiscount("");
    setCourseIdDiscount("");
    // edit && eliminar && killSideBar();
    close(false);
  };

  const afterDelete = () => {
    killSideBar();
    close(false);
  };

  //setea el valor de los inputs en el estado
  const handleInputs = (e) => {
    setCourse({
      ...course,
      [e.target.name]: e.target.value,
    });
  };

  //setea el valor de los inputs en el estado
  const handleActualDiscount = (e) => {
    setActaualDiscount(e.target.value);
  };

  //SWITCH buttons///////
  const switchButton = (checked) => {
    setCourse({
      ...course,
      active: checked,
    });
  };

  //SWITCH buttons///////
  const switchButtonExternal = (checked) => {
    setCourse({
      ...course,
      activeExternal: checked,
    });
  };

  //SWITCH buttons///////
  const switchButtonAppoiments = (checked) => {
    setCourse({
      ...course,
      isActiveAppoiments: checked,
    });
  };

  //////////SELECTS//////
  //handle y opciones del nivel
  const showLevels = () => {
    return courseLevels.map((value) => {
      return (
        <Option key={value._id} value={value.name + "_" + value._id}>
          {value.name}
        </Option>
      );
    });
  };

  const handleSelectLevels = (value) => {
    setCourse({
      ...course,
      courseLevel: value.split("_")[1],
    });
  };

  //handle y opciones del tipo de curso
  const showTypes = () => {
    return courseTypes.map((value) => {
      return (
        <Option key={value._id} value={value.name + "_" + value._id}>
          {value.name}
        </Option>
      );
    });
  };

  const handleSelectTypes = (value) => {
    setCourse({
      ...course,
      courseType: value.split("_")[1],
    });
  };

  const handleScheduleAppoiment = (value) => {
    // console.log(value);
    setCourse({
      ...course,
      schedulesAppoimentList: value,
    });
  };

  const setTopicByDayList = (array) => {
    setCourse({
      ...course,
      topicByDayList: array,
    });
  };

  //muestra las opciones de media superior
  const showOptionsCoursesMedia = () => {
    return coursesMedia.map((value, index) => {
      return (
        <Option key={index} value={value.name + "_" + value._id}>
          {value.name}
        </Option>
      );
    });
  };
  //muestra las opciones de superior
  const showOptionsCoursesSuperior = () => {
    return coursesSuperior.map((value, index) => {
      return (
        <Option key={index} value={value.name + "_" + value._id}>
          {value.name}
        </Option>
      );
    });
  };

  //agregar el array de ids de los cursos hijos
  const handleSelecCourse = (value) => {
    setChildrenArray(value);
  };

  //agrega el cursoId seleccionado
  const handleSelectDiscount = (value) => {
    setCourseIdDiscount(value.split("_")[1]);
  };

  //operaciones de los DESCUENTOS//////
  const addDiscount = () => {
    var indexOfObj = course.discounts.findIndex(
      (i) => i.course === courseIdDiscount
    );
    //valida si exite el id en el array
    if (actaualDiscount !== "" && courseIdDiscount !== "") {
      if (indexOfObj === -1) {
        let discountObj = {
          course: courseIdDiscount,
          discount: actaualDiscount,
        };
        //agregamos el obj al estado
        setCourse({
          ...course,
          discounts: [...course.discounts, discountObj],
        });
        setActaualDiscount(""); //reiniciamos el valor del curso y el descuento en cantidad
      } else {
        showMessageWarning("Atención! Ya has agregado ese curso.", 5);
      }
    } else {
      showMessageWarning(
        "Atención! Debes elegir un curso y escribir una cantidad.",
        5
      );
    }
  };

  //elimina el id curso del array de descuentos
  const clickIcon = (deleteId) => {
    let myArray = course.discounts.filter(function (obj) {
      return obj.course !== deleteId;
    });

    setCourse({
      ...course,
      discounts: myArray,
    });
  };
  //setea el valor por default para los selects cuando es editar
  //muestra el nombre del nivel
  const setDefaulLevel = (id) => {
    if (courseLevels.length > 0) {
      let obj = courseLevels.find((value) => value._id === id);
      return obj.name;
    }
  };

  //muestra el nombre del typo de curso seleccionado
  const setDefaulTypeCourse = (id) => {
    let obj = courseTypes ? courseTypes.find((value) => value._id === id) : "";
    return obj?.name;
  };

  //muestra los Subcursos elegidos
  const setDefaulSubCourses = (arrayChildren) => {
    let array = [];
    arrayChildren.forEach((value) => {
      if (value.hasOwnProperty("name")) {
        array.push(value.name + "_" + value._id);
      } else {
        let infoCourse = courses.find((course) => course._id === value);
        array.push(infoCourse.name + "_" + infoCourse._id);
      }
    });
    return array;
  };

  const onSave = (item) => {
    setCourse({
      ...course,
      image: item,
    });
    setValueModalImage(false);
  };
  const onRemove = (item) => {
    setCourse({
      ...course,
      image: "",
    });
    setValueModalImage(false);
  };

  return (
    <Modal
      open={value}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <Row className={styles.add_edit_container}>
        <Col xs="12">
          <Header
            title={title}
            onCloseModal={onCloseModal}
            registerCourse={ValidateCourse}
            course={course}
            childrenArray={childrenArray}
            createCourse={createCourse}
            edit={edit}
            dataCourse={actualCourse}
            bloquear={disableButton}
            editCourse={editCourse}
          />
          <div className="form__form-group mb-1">
            <span className="form__form-group-label">Nombre</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <ClassIcon />
              </div>
              <input
                type="text"
                className="form-control"
                name="name"
                autoComplete="off"
                value={course.name}
                onChange={handleInputs}
              />
            </div>
          </div>

          {course.image !== undefined && course.image !== "" ? (
            <Row className="mt-1">
              <ImageContentComponent url={course.image.url} />
            </Row>
          ) : null}

          {edit ? (
            <Row>
              <Col sm="12" className="mt-1">
                <Button
                  color="success"
                  type="button"
                  className="btn-sm float-right"
                  outline
                  onClick={() => setValueModalImage(true)}
                >
                  {course.image === undefined || course.image === ""
                    ? "Agregar Imagen"
                    : "Editar Imagen"}
                </Button>
              </Col>
            </Row>
          ) : (
            <div className="mt-4" />
          )}
          <Row className="mt-2">
            <Col xs="12" md="4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Duración (en dias)
                </span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <CalendarIcon />
                  </div>
                  <input
                    name="duration"
                    type="number"
                    className="form-control"
                    onChange={handleInputs}
                    value={course.duration}
                  />
                </div>
              </div>
            </Col>
            <Col xs="12" md="4">
              <div className="form__form-group">
                <span className="form__form-group-label">Costo</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <MoneyIcon />
                  </div>
                  <input
                    name="price"
                    type="number"
                    className="form-control"
                    onChange={handleInputs}
                    value={course.price}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6" className="text-center">
              <span className="form__form-group-label">Estatus</span>
              <br />
              {actualCourse !== undefined ? (
                actualCourse.active === true ? (
                  <Switch
                    defaultChecked
                    onChange={switchButton}
                    className="mt-2"
                  />
                ) : (
                  <Switch onChange={switchButton} className="mt-2" />
                )
              ) : (
                <Switch
                  defaultChecked
                  onChange={switchButton}
                  className="mt-2"
                />
              )}
            </Col>
            <Col xs="12" md="6" className="text-center">
              <span className="form__form-group-label">Visible Alumnos</span>
              <br />
              {actualCourse !== undefined ? (
                actualCourse.activeExternal === true ? (
                  <Switch
                    defaultChecked
                    onChange={switchButtonExternal}
                    className="mt-2"
                  />
                ) : (
                  <Switch onChange={switchButtonExternal} className="mt-2" />
                )
              ) : (
                <Switch
                  defaultChecked
                  onChange={switchButtonExternal}
                  className="mt-2"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <span className="form__form-group-label">
                Selecciona un nivel
              </span>
              <Select
                size="default"
                defaultValue={
                  edit ? setDefaulLevel(actualCourse.courseLevel) : ""
                }
                showSearch
                style={{ width: "100%" }}
                placeholder="Seleccionar nivel"
                onChange={handleSelectLevels}
              >
                {showLevels()}
              </Select>
            </Col>
            <Col xs="12" md="6">
              <span className="form__form-group-label">Tipo de curso</span>
              <Select
                size="default"
                defaultValue={
                  edit ? setDefaulTypeCourse(actualCourse.courseType) : ""
                }
                showSearch
                style={{ width: "100%" }}
                placeholder="Seleccionar tipo curso"
                onChange={handleSelectTypes}
              >
                {courseTypes && showTypes()}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} className="mt-3">
              <span className="form__form-group-label">
                Agregar subcursos (opcional)
              </span>
              <div>
                {edit ? (
                  <Select
                    showArrow
                    showSearch
                    defaultValue={
                      actualCourse.isParent
                        ? setDefaulSubCourses(actualCourse.children)
                        : null
                    }
                    disabled={edit !== undefined}
                    notFoundContent="No hay cursos disponibles"
                    mode="multiple"
                    style={{ width: "100%" }}
                    onChange={handleSelecCourse}
                  >
                    <OptGroup
                      key={1}
                      label={
                        <span className={styles.types_divider_text}>
                          NIVEL MEDIA SUPERIOR
                        </span>
                      }
                    >
                      {showOptionsCoursesMedia()}
                    </OptGroup>
                    <OptGroup
                      key={2}
                      label={
                        <span className={styles.types_divider_text}>
                          NIVEL SUPERIOR
                        </span>
                      }
                    >
                      {showOptionsCoursesSuperior()}
                    </OptGroup>
                  </Select>
                ) : (
                  <Select
                    showArrow
                    showSearch
                    notFoundContent="No hay cursos disponibles"
                    mode="multiple"
                    style={{ width: "100%" }}
                    onChange={handleSelecCourse}
                  >
                    <OptGroup
                      key={1}
                      label={
                        <section className={styles.types_divider_text}>
                          <span className={styles.types_divider_text}>
                            NIVEL MEDIA SUPERIOR
                          </span>
                        </section>
                      }
                    >
                      {showOptionsCoursesMedia()}
                    </OptGroup>
                    <OptGroup
                      key={2}
                      label={
                        <section className={styles.types_divider_text}>
                          <span>NIVEL SUPERIOR</span>
                        </section>
                      }
                    >
                      {showOptionsCoursesSuperior()}
                    </OptGroup>
                  </Select>
                )}
              </div>
            </Col>
          </Row>
          {edit ? (
            <Row className="mt-4">
              <Col xs="12" md="9">
                <span className="form__form-group-label">
                  Selecciona las horas de asesoría(opcional)
                </span>
                <InputGroup compact>
                  <TreeSelect
                    treeData={treeData}
                    treeCheckable={true}
                    value={course.schedulesAppoimentList}
                    onChange={handleScheduleAppoiment}
                    showCheckedStrategy={SHOW_PARENT}
                    placeholder="Selecciona los horarios"
                    style={{ width: "100%" }}
                  />
                </InputGroup>
              </Col>
              <Col xs="12" md="3" className="text-center">
                <span className="form__form-group-label">
                  Activar Asesorías
                </span>
                <br />
                {actualCourse !== undefined ? (
                  actualCourse.isActiveAppoiments ? (
                    <Switch
                      defaultChecked
                      onChange={switchButtonAppoiments}
                      className="mt-2"
                    />
                  ) : (
                    <Switch
                      onChange={switchButtonAppoiments}
                      className="mt-2"
                    />
                  )
                ) : (
                  <Switch
                    defaultChecked
                    onChange={switchButtonAppoiments}
                    className="mt-2"
                  />
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}
          {edit ? (
            <Row className="mt-4">
              <SelectTopics
                topicList={actualCourse.topicList}
                topicByDayList={actualCourse.topicByDayList}
                setTopicByDayList={setTopicByDayList}
              />
            </Row>
          ) : (
            ""
          )}
          <Row className="mt-4">
            <Col xs="12">
              <span className="form__form-group-label">
                Selecciona un curso y escribe una cantidad para agregar un
                descuento (opcional)
              </span>
              <InputGroup compact>
                <Select
                  defaultValue={courseIdDiscount}
                  style={{ width: "65%" }}
                  showArrow
                  showSearch
                  onChange={handleSelectDiscount}
                >
                  <OptGroup
                    key={1}
                    label={
                      <section className={styles.types_divider_text}>
                        <span>NIVEL MEDIA SUPERIOR</span>
                      </section>
                    }
                  >
                    {showOptionsCoursesMedia()}
                  </OptGroup>
                  <OptGroup
                    key={2}
                    label={
                      <section className={styles.types_divider_text}>
                        <span>NIVEL SUPERIOR</span>
                      </section>
                    }
                  >
                    {showOptionsCoursesSuperior()}
                  </OptGroup>
                </Select>
                <Input
                  style={{ width: "35%" }}
                  name="actaualDiscount"
                  type="number"
                  onChange={handleActualDiscount}
                  value={actaualDiscount}
                />
              </InputGroup>
            </Col>
            <Col sm="12" className="mt-4">
              <Button
                color="success"
                type="button"
                size="sm"
                onClick={addDiscount}
              >
                <p>Agregar descuento</p>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              {course.discounts.length > 0 && (
                <GeneraLista
                  allCourses={courses}
                  discounts={course.discounts}
                  clickIcon={clickIcon}
                />
              )}
            </Col>
          </Row>
          {edit && (
            <div>
              <Can do="delete" on="/courses/">
                <DeleteButtonConfirm
                  title="¿Deseas eliminar el curso?"
                  deleteFunction={deleteCourse}
                  id={actualCourse?._id}
                  onCloseOptionalFunc={afterDelete}
                  // onCloseOptionalFunc={onCloseModal}
                />
              </Can>
            </div>
          )}
        </Col>
      </Row>
      {valueModalImage && (
        <UploadImageModal
          value={valueModalImage}
          close={() => setValueModalImage(false)}
          imageId={course.image !== undefined ? course.image._id : undefined}
          imageUrl={course.image !== undefined ? course.image.url : undefined}
          onSave={onSave}
          onRemove={onRemove}
        />
      )}
    </Modal>
  );
}
export default AddEditCourses;
