import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-responsive-modal";
import Loading from '../../../../helpers/LoadingComponent'
import Empty from '../../../../helpers/Empty/EmptyComponent'
import ModalConfirmation from '../../../Modal/ModalConfirmation'
import { Col, Container, Row, Button } from 'reactstrap';
import { fetchAsync, urlVideo, urlQuiz } from "../../../../helpers/Globals/globals"
import Header from './Header';
import ShowQuizVideos from './ShowQuizVideos';
import ModalVideoView from '../../../Material/components/ModalVideoView';
import ModalAddVideo from '../../../Material/components/ModalAddVideo';
import EditVideo from '../../../Material/components/editVideo';

function QuizVideos({ quizId, value, close, title, questionId,
  saveVideosQuestions, explanationVideoQuestion }) {

  const onCloseModal = () => {
    close(false)
  }

  const [isLoading, setIsLoading] = useState(false)
  //estado que contiene la palylist del quiz
  const [contentPlayList, setcontentPlayList] = useState([])
  //abre el modal ver video
  const [modalViewVideo, setModalViewVideo] = useState(false)
  const [videoInfo, setVideoInfo] = useState("")
  //guarda el array de videos a asignar a una pregunra
  const [arrayVideos, setarrayVideos] = useState([])
  //recargar el componente
  const [reload, setReload] = useState(false)
  //abrir el modal para agregar un video
  const [addVideo, setAddVideo] = useState(false)
  //abrir el modal de editar un video
  const [editVideo, setEditVideo] = useState(false)
  const [editVideoInfo, setEditVideoInfo] = useState({})
  //abrir el modal de confirmación
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [idVideoConfirmation, setIdVideoConfirmation] = useState("")

  useEffect(() => {
    getPlayList()
    if (explanationVideoQuestion.length > 0) {
      const cloneArray = [...explanationVideoQuestion];
      setarrayVideos(cloneArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPlayList = () => {
    setIsLoading(true)
    fetchAsync(urlQuiz + 'videos/' + quizId, "", "GET")
      .then((data) => {
        setIsLoading(false)
        if (data.success) {
          setcontentPlayList(data.videos)
        }
      })
  }
  const removeVideo = () => {
    fetchAsync(urlVideo + idVideoConfirmation, "", "DELETE")
      .then((data) => {
        getPlayList()
      })
      .catch()
  }

  //abre el modal para reproducir el video
  const openModalVideoView = (video) => {
    setModalViewVideo(true);
    setVideoInfo(video);
  }

  const openDeleteConfirmation = (id) => {
    setModalConfirmation(true)
    setIdVideoConfirmation(id)
  }

  //agrega los videos seleccionados al array de videos a guardar
  const addVideoQuestion = (video) => {
    let existe = arrayVideos.findIndex(data => data._id === video._id);
    if (existe === -1) {
      setarrayVideos(arrayVideos => [...arrayVideos, video]);
    } else {
      arrayVideos.splice(existe, 1);
      reloadForm()
    }
  }

  //guarda los videos seleccionados en la pregunta 
  const saveSelectedVideos = () => {
    saveVideosQuestions(questionId, arrayVideos)
    onCloseModal()
  }

  //genera el estilo para un video asignado o no al acer click 
  const setStyleSleleted = (id) => {
    let index = arrayVideos.findIndex(data => data._id === id);
    return index
  }

  //recarga el formuario, es un dato dummy solo para recargar
  const reloadForm = () => {
    if (reload) {
      setReload(false)
    } else {
      setReload(true)
    }
  }

  return (
    <Modal
      open={value}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}>
      <Container>
        <Row className="modal-videos-quiz">
          <Col xs="12">
            <Header
              onCloseModal={onCloseModal}
              questionId={questionId}
              saveSelectedVideos={saveSelectedVideos}
            />
          </Col>
          <Col xs="12" className={questionId !== "" ? "collapse" : ""} >
            <Button className="btn" size="sm" color="add"
              onClick={() => setAddVideo(true)}
              disabled={isLoading}
            >
              <p>Subir videos</p>
            </Button>
          </Col>
          <Col xs="12">
            {
              !isLoading ? contentPlayList.length > 0 ? contentPlayList.map((data, index) => (
                <div key={index} className={setStyleSleleted(data._id) === -1 ? "" : "selected-video"}>
                  <ShowQuizVideos
                    indexMap={index}
                    infoVideo={data}
                    openModalVideoView={openModalVideoView}
                    questionId={questionId}
                    addVideoQuestion={addVideoQuestion}
                    //editar video
                    setEditVideo={setEditVideo}
                    setEditVideoInfo={setEditVideoInfo}
                    //borrar
                    openConfirmation={openDeleteConfirmation}
                  />
                </div>
              )) : <Empty message={"Aún no existen vídeos para este cuestionario, empieza agregando uno"} /> : <Loading />
            }
          </Col>
        </Row>
      </Container>
      {(modalViewVideo) ?
        <ModalVideoView
          value={modalViewVideo}
          closeModal={setModalViewVideo}
          videoObj={videoInfo}
        /> : null}
      {(addVideo) ? <ModalAddVideo
        idQuizParent={quizId}
        value={addVideo}
        updateContent={getPlayList}
        closeModal={setAddVideo}
      /> : null}
      {(editVideo) ? <EditVideo
        valueModal={editVideo}
        close={setEditVideo}
        updateContent={getPlayList}
        videoInfo={editVideoInfo}
      /> : null}
      {(modalConfirmation) ? <ModalConfirmation
        closeModal={() => setModalConfirmation(false)}
        valueModal={modalConfirmation}
        callback={removeVideo}
        value={idVideoConfirmation}
        titulo={"Confirmación : ¿Deseas eliminar el Vídeo?"}
      /> : null}
    </Modal>
  )
}
QuizVideos.propTypes = {
  explanationVideoQuestion: PropTypes.array.isRequired,
  saveVideosQuestions: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  quizId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
};
export default QuizVideos