import React, { useState, useContext } from 'react';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { showMessageWarning } from '../../../helpers/MessageAndNotificationUtils';
import Loading from '../../../helpers/LoadingComponent'
import { Checkbox } from 'antd';
import AddIcon from 'mdi-react/AddIcon';
import FormulaModal from './math/mathEditorModal';
import QuizContext from '../../../context/quiz/QuizContext';
import VideosForm from './VideosForm';
import { MathFieldComponent } from 'react-mathlive';
import ImageContentComponent from '../../../helpers/uploadImage/imageComponent';
import UploadImageModal from '../../../helpers/uploadImage';
import { Pagination } from 'antd';
import 'antd/es/pagination/style/index.css';

// saveTypeQuestions 1 to tex, 2 to image 3 to formula 
function FormQuestion({ openModalAddVideoQuestion }) {

  //creamos el context
  const quizContext = useContext(QuizContext);
  const { quizInfo, addQuestion, deleteQuestion, handleOnChangeQuestion, addQuestionFormula,
    deleteQuestionFormula, addImageQuestion, addAnswer, deleteAnswer, handleChangeAnswer,
    onChangeCheckbox, addAnswerFormula, deleteAnswerFormula, addAnswerImage, deleteVideoQuestion,
    setSkipNumber, skipNumber, loading } = quizContext;

  const [actualIdQuestion, setActualIdQuestion] = useState("")
  const [actualIdAnswer, setActualIdAnswer] = useState("")

  const [formulaModal, setFormulaModal] = useState(false)
  const [formulaQuestionValue, setFormulaQuestionValue] = useState("")

  const [imageModal, setImageModal] = useState(false)
  const [imageQuestionValue, setImageQuestionValue] = useState("")

  //pagination states
  const [currentPage, setCurrentPage] = useState(1)

  const setOpenFormulaModal = (idQuestion, questionFormula, idAnswer = "") => {
    if (questionFormula) {
      setFormulaQuestionValue(questionFormula)
    }
    setActualIdQuestion(idQuestion)
    setActualIdAnswer(idAnswer)
    setFormulaModal(true)
  }

  const setOpenImageModal = (idQuestion, questionImage, idAnswer = "") => {
    if (questionImage) {
      // setImageQuestionValue(questionImage)
    }
    setActualIdQuestion(idQuestion)
    setActualIdAnswer(idAnswer)
    setImageModal(true)
  }

  const onCloseModals = () => {
    setFormulaModal(false)
    setActualIdQuestion("")
    setFormulaQuestionValue("")
    setImageModal(false)
    setImageQuestionValue("")
    setActualIdAnswer("")
  }

  //genera la sig busqueda al hacer click en la paginación
  const onChange = page => {
    console.log("PAGE")
    console.log(page)
    setCurrentPage(page)
    let skipNumber = (page - 1) * 15
    // console.log(skipNumber)
    setSkipNumber(skipNumber)
  };

  const onDelete = (idQuestion) => {

    deleteQuestion(idQuestion)
    let newNumberPagination = quizInfo.quiz.length / 15

    if (currentPage > 1 && newNumberPagination < currentPage - 1 + 0.10) {
      setCurrentPage(currentPage - 1)
      let skipNumber = (currentPage - 2) * 15
      setSkipNumber(skipNumber)
    }
  }

  return (
    <>
      <Row>
        <Col className="pagination_div">
          <Pagination
            total={quizInfo.quiz.length}
            showTotal={(total, range) => `${range[0]} al ${range[1]} de ${total} preguntas`}
            defaultPageSize={15}
            current={currentPage}
            onChange={onChange}
            className="float-right"
            showSizeChanger={false}
          />
        </Col>
      </Row>
      { quizInfo.quiz.length === 0 || loading ? <Loading /> :
        quizInfo.quiz.slice(skipNumber, 15 + skipNumber).map((question, index) => {
          return (
            <Row key={index} className="margin-top-questions">
              <Col key={question._id} xs="12">
                <div className="form__form-group form-fields">
                  <div className="div-question-name-icon">
                    <p >Pregunta {skipNumber + index + 1}</p>
                    <i className="material-icons icon-delete-answeer icon-x-question ml-4"
                      onClick={() => onDelete(question._id)}>delete</i>
                    {/* onClick={() => deleteQuestion(question._id)}>delete</i> */}
                  </div>
                  <div className="float-right mr-4">
                    <Button className="btn" size="sm" color="add"
                      onClick={() => setOpenFormulaModal(question._id, question.questionFormula)}>
                      <p> <i className="material-icons icon-add-answer">{question.questionType === 3 ? "edit" : "add"}</i>Formula</p>
                    </Button>
                    <Button className="btn" size="sm" color="add"
                      onClick={() => setOpenImageModal(question._id, question.questionImage)}>
                      <p> <i className="material-icons icon-add-answer">add</i>Imagen</p>
                    </Button>
                  </div>
                  {question.questionType === 2 ?
                    <ImageContentComponent url={question.questionImage.url} /> : null}
                  {question.questionType === 3 ?
                    <div className="my-3">
                      <span className="mr-2">Fórmula:</span>
                      <MathFieldComponent
                        latex={question.questionFormula}
                        mathFieldConfig={{
                          defaultMode: "math",
                          readOnly: true
                        }} />
                    </div>
                    : null}
                  <div className="form__form-group-field">
                    <textarea className="form-control" type="text"
                      id={question._id}
                      name="textarea"
                      maxLength="10000"
                      placeholder="Escribe la pregunta"
                      defaultValue={question.question}
                      onBlur={handleOnChangeQuestion}
                    />
                    {/* <textarea className="form-control" type="text"
                      id={question._id}
                      name="textarea"
                      maxLength="10000"
                      placeholder="Escribe la pregunta"
                      value={question.question}
                      onChange={handleOnChangeQuestion}
                      onBlur={() => console.log("salio INPUt")}
                    /> */}
                  </div>
                </div>
                <div className="div-ansers">
                  <div className="form-fields">
                    <p className="form-fields">Respuestas</p>
                  </div>
                  {
                    question.answerCollection.map((answer, index) => {
                      return (
                        <div className="line-answers" key={index}>
                          <div key={answer.idAnswer} className="form__form-group line-answers ml-1">
                            <div className="form__form-group-field ml-2">
                              {answer.answerType === 2 ?
                                <ImageContentComponent isAnswer url={answer.answerImage.url != null ? answer.answerImage.url : ""} />
                                : answer.answerType === 3 ?
                                  <Col>
                                    <div className="my-3">
                                      <MathFieldComponent
                                        latex={answer.answerFormula}
                                        mathFieldConfig={{
                                          defaultMode: "math",
                                          readOnly: true
                                        }} />
                                    </div>
                                  </Col>
                                  :
                                  <input type="text" className="form-control" name="answer"
                                    defaultValue={answer.content}
                                    onBlur={(e) => handleChangeAnswer(answer.idAnswer, question._id, e.target.value)}
                                    placeholder="Escribe la respuesta"
                                  />
                              }
                              {/* <input type="text" className="form-control" name="answer"
                                    defaultValue={answer.content}
                                    onChange={(e) => handleChangeAnswer(answer.idAnswer, question._id, e.target.value)}
                                    placeholder="Escribe la respuesta"
                                  /> */}
                              <Checkbox className="ml-3 mt-1"
                                onChange={onChangeCheckbox}
                                id={answer.idAnswer + "/" + question._id}
                                checked={answer.validAnswer}
                              ></Checkbox>
                              <i className="material-icons float-right ml-3 mt-1 "
                                id={answer.idAnswer + "/" + question._id}
                                onClick={() => setOpenFormulaModal(question._id, answer.answerFormula, answer.idAnswer)}
                              >functions</i>
                              <i className="material-icons float-right ml-3 mt-1 "
                                id={answer.idAnswer + "/" + question._id}
                                onClick={() => setOpenImageModal(question._id, answer.answerImage, answer.idAnswer)}
                              >collections</i>
                              <i className="material-icons float-right ml-3 mt-1 icon-delete-answeer"
                                onClick={() => {
                                  if (index === 0) return showMessageWarning("Atención! No puedes borrar todas las respuestas.", 3)
                                  deleteAnswer(answer.idAnswer, question._id)
                                }}
                              >delete</i>
                            </div>
                          </div>
                          {index + 1 === question.answerCollection.length ?
                            <div className="float-right mr-4 mb-5">
                              <Button className="btn" size="sm" color="add"
                                onClick={() => addAnswer(question._id)}>
                                <p> <i className="material-icons icon-add-answer">add</i></p>
                              </Button>
                            </div> : null}
                        </div>
                      )
                    })
                  }
                </div>
                <div className="mt-5">
                  <div className="form-fields">
                    <p className="mr-4 button-title-videos">Videos asignados</p>
                    <Button className="btn div-videos-button button-title-videos" size="sm" color="add"
                      onClick={(e) => openModalAddVideoQuestion(question._id, question.explanationVideo)}
                    >
                      <p>Asignar video</p>
                    </Button>
                  </div>
                  {
                    question.explanationVideo && question.explanationVideo.map((videoData, index) =>
                      <div className="div-video-form" key={index}>
                        <VideosForm
                          idQuestion={question._id}
                          videoInfo={videoData}
                          iconDeleteVideoQuestion={deleteVideoQuestion}
                        />
                      </div>)
                  }
                </div>
                {/* {index + 1 === quizInfo.quiz.length ? */}
                {skipNumber + index + 1 === quizInfo.quiz.length ?
                  <div className="float-left mt-5">
                    <Button className="btn" size="sm" color="add"
                      onClick={addQuestion}>
                      <p><AddIcon />Agregar pregunta</p>
                    </Button>
                  </div> : null}
              </Col>
            </Row>
          )
        })
      }
      <Row>
        <Col className="pagination_div">
          <Pagination
            total={quizInfo.quiz.length}
            showTotal={(total, range) => `${range[0]} al ${range[1]} de ${total} preguntas`}
            defaultPageSize={15}
            current={currentPage}
            onChange={onChange}
            size="small"
            className="float-right"
            showSizeChanger={false}
          />
        </Col>
      </Row>
      {formulaModal &&
        <FormulaModal
          value={formulaModal}
          close={onCloseModals}
          fomulaId={actualIdQuestion}
          formulaValue={formulaQuestionValue}
          onSave={actualIdAnswer !== "" ? addAnswerFormula : addQuestionFormula}
          onRemove={actualIdAnswer !== "" ? deleteAnswerFormula : deleteQuestionFormula}
          actualIdAnswer={actualIdAnswer}
        />}
      {imageModal &&
        <UploadImageModal
          value={imageModal}
          close={onCloseModals}
          imageId={actualIdQuestion}
          onSave={actualIdAnswer !== "" ? addAnswerImage : addImageQuestion}
          actualIdAnswer={actualIdAnswer}
          imageUrl={imageQuestionValue}
        // onRemove={setRemoveImageQuestion}
        />}
    </>
  )
}
FormQuestion.propTypes = {
  openModalAddVideoQuestion: PropTypes.func.isRequired,
};
export default FormQuestion