import React from "react";
import { Modal } from "react-responsive-modal";

import ShowComponents from "./components/showComponents";
import { MessagesProvider } from "./context/messagesContext";

import styles from "./index.module.scss";

const SmsAndMailMessages = ({
  value,
  close,
  type = "", //student, groups, courses, talks, talkStudent
  infoType = null,
}) => {
  const onCloseModal = () => {
    close(false);
  };
  //to be show in a modal or to be show in a component
  return type === "student" || type === "talkStudent" ? (
    <MessagesProvider>
      <ShowComponents
        type={type}
        onCloseModal={onCloseModal}
        infoType={infoType}
      />
    </MessagesProvider>
  ) : (
    <Modal
      open={value}
      onClose={onCloseModal}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        modal: `${styles.modal_container}`,
      }}
    >
      <MessagesProvider>
        <ShowComponents
          type={type}
          onCloseModal={onCloseModal}
          infoType={infoType}
        />
      </MessagesProvider>
    </Modal>
  );
};
export default SmsAndMailMessages;
