import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Input, Form, Button, Select } from "antd";
import { FOLIO_VALUE } from "../../../../../../helpers/Globals/constants"

import styles from "./index.module.scss";
import "antd/es/input/style/index.css";
import "antd/es/form/style/index.css";
import "antd/es/select/style/index.css";
import "antd/es/button/style/index.css";

const initialStateError = {
  errorFetch: "",
  folio: "",
  title: "",
};

const ShowForm = ({ setFolio }) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const [errorData, setErrorData] = useState(initialStateError);

  const onFinish = (values) => {
    const { title, folio } = values;

    if (title && folio) {
      let body = {
        title,
        folio: folio.replace(/\s+/g, ""),
      };
      const result = setFolio(body);
      result.then((res) => {
        if (!res.success) {
          const { message = "" } = res.error;
          setErrorData({
            ...errorData,
            folio:
              message !== ""
                ? message?.es
                : "Ocurrio un error al agregar el folio",
          });
        }
      });
      form.resetFields();
    } else {
      setErrorData({
        ...errorData,
        folio: folio ? "" : "Ingresa un Folio",
        title: title ? "" : "Seleccioná un curso",
      });
    }
  };

  const onChangeFields = (actualValue, allFields) => {
    const { errorFetch, folio, title } = errorData;
    if (errorFetch != "" || folio != "" || title != "") {
      setErrorData(initialStateError);
    }
  };

  return (
    <div className={styles.form_main_container}>
      <Form
        form={form}
        layout={"vertical"}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        onFieldsChange={onChangeFields}
      >
        <Form.Item
          name="folio"
          validateStatus={
            errorData.folio !== "" || errorData.errorFetch !== ""
              ? "error"
              : "success"
          }
          help={
            errorData.errorFetch !== ""
              ? errorData.errorFetch.message?.es
              : errorData.folio !== ""
              ? errorData.folio
              : ""
          }
        >
          <Input maxLength={10} placeholder="Escribe el Folio" />
        </Form.Item>

        <Form.Item
          name="title"
          validateStatus={errorData.title !== "" ? "error" : "success"}
          help={errorData.title !== "" ? errorData.title : ""}
        >
          <Select placeholder="curso">
            <Option value={FOLIO_VALUE}>{FOLIO_VALUE}</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Agregar Folio
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

ShowForm.propTypes = {};

export default ShowForm;
