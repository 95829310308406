import React, { useReducer, useEffect, useCallback } from 'react';
import CourseContext from './CourseContext';
import CourseReducer from './CourseReducer';
import { showMessageLoading } from '../../helpers/MessageAndNotificationUtils';
import {
  fetchAsync, urlCourses, urlCourseLevels,
  urlCourseTypes, urlGroups
} from '../../helpers/Globals/globals';
import {
  GET_ALL_COURSES,
  GET_COURSES_TYPES,
  GET_COURSES_LEVELS,
  ACTUAL_COURSE,
  ACTUAL_COURSE_GROUPS,
  DISABLE_BUTTON,
  UPDATE_COURSE_AFTER_EDIT,
  UPDATE_COURSE_AFTER_ADD,
} from '../types/index';

let closeLoading = "";

const CourseState = (props) => {

  const initialState = {
    courses: null,
    coursesMedia: null,
    coursesSuperior: null,
    courseTypes: null,
    courseLevels: [{ _id: "5ce2b9b2e277d4309b5ed0f6", name: "Media Superior", description: "Curso de nivel medio superior" },
    { _id: "5ce2b9b2e277d4309b5ed0f5", name: "Superior", description: "Cursos de nivel superior" }],
    mediaLevel: { _id: "5ce2b9b2e277d4309b5ed0f6", name: "Media Superior", description: "Curso de nivel medio superior" },
    superiorLevel: { _id: "5ce2b9b2e277d4309b5ed0f5", name: "Superior", description: "Cursos de nivel superior" },
    actualCourse: {},
    idActualCourse: "",
    actualCourseGroups: null,
    disableButton: false,
    closeSidebar: false,
  }

  //crear dispatch y state
  const [state, dispatch] = useReducer(CourseReducer, initialState)

  //obtiene todos los cursos
  const getAllCourses = () => {
    fetchAsync(urlCourses, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_ALL_COURSES,
            payload: data.courses
          })
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  //obtiene los ids de los types platicas, examen,talleres etc..
  const getCourseTypes = () => {
    fetchAsync(urlCourseTypes, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_COURSES_TYPES,
            payload: data.courseTypes
          })
        }
      }).catch((reason) => {
        console.log(reason)
      });
  }

  //obtiene los ids de niveles media,superior
  const getCourseLevels = () => {
    return fetchAsync(urlCourseLevels, "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_COURSES_LEVELS,
            payload: data.courseLevels
          })
        }
        return data
      }).catch((reason) => {
        console.log(reason)
      });
  }

  //obtiene la información del curso seleccionado en la tabla
  const setActualCourse = (courseInfo) => {
    dispatch({
      type: ACTUAL_COURSE,
      payload: courseInfo
    })
  }


  //fetch para obtener todos grupos por id curso y fecha de inicio
  const getGrupos = useCallback(() => {
    return fetchAsync(urlGroups + "?course=" + state.idActualCourse, "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: ACTUAL_COURSE_GROUPS,
            payload: result.groups
          })
        }
      }).catch((reason) => {
        console.log(reason.message)
      });
  }, [state.idActualCourse])

  useEffect(() => {
    if (state.idActualCourse !== "" && state.idActualCourse !== undefined) {
      getGrupos()
    }
  }, [state.idActualCourse, getGrupos])

  const createCourse = (body) => {
    setDisableButton(true)
    closeLoading = LoadingMessage()
    fetchAsync(urlCourses, JSON.stringify(body), "POST")
      .then((data) => {
        closeLoading();
        setDisableButton(false);
        if (data.success) {
          dispatch({
            type: UPDATE_COURSE_AFTER_ADD,
            payload: data.course
          })
        }
      }).catch((reason) => {
        closeLoading();
        setDisableButton(false)
        console.log(reason.message)
      });
  }

  const editCourse = (body) => {
    if (body.image !== undefined) {
      body.image = body.image._id
    }
    setDisableButton(true)
    closeLoading = LoadingMessage()
    fetchAsync(urlCourses + state.idActualCourse, JSON.stringify(body), "PUT")
      .then((data) => {
        closeLoading();
        setDisableButton(false)
        if (data.success) {
          setActualCourse(data.course)
          dispatch({
            type: UPDATE_COURSE_AFTER_EDIT,
            payload: data.course
          })
        }
      }).catch((reason) => {
        closeLoading();
        console.log(reason.message)
      });
  }
  const updateLive = (body) => {
    closeLoading = LoadingMessage()
    return fetchAsync(urlCourses + state.idActualCourse + "/updateLive", JSON.stringify(body), "PUT")
      .then((data) => {
        if (data.success === true) {
          setActualCourse(data.course)
          getAllCourses();
          closeLoading();
          setDisableButton(false)
        }
        closeLoading();
        setDisableButton(false)
      }).catch((reason) => {
        closeLoading();
        console.log(reason.message)
      });
  }

  //funcion para eliminar un curso
  const deleteCourse = () => {
    return fetchAsync(urlCourses + state.idActualCourse, "", "DELETE", "")
      .then((data) => {
        getAllCourses();
        return data
      }).catch((reason) => {
        console.log(reason.message)
      });
  }

  const createTopic = (name) => {
    let body = { name }
    return fetchAsync(`${urlCourses}${state.idActualCourse}/topics`, JSON.stringify(body), "POST", "")
      .then((data) => {
        dispatch({
          type: ACTUAL_COURSE,
          payload: data.course
        })
      }).catch((reason) => {
        console.log(reason.message)
      });
  }
  //setea el estado para bloquear los botones al crear o editar
  const setDisableButton = (status) => {
    dispatch({
      type: DISABLE_BUTTON,
      payload: status
    })
  }

  const LoadingMessage = () => {
    return showMessageLoading('Registrando..', 0);
  };

  //reporte 
  const getReportInfo = (fetchString) => {
    return fetchAsync(urlCourses + fetchString, "", "GET", "")
      .then((data) => {
        return data
      }).catch((reason) => {
        console.log(reason)
      });
  }


  return (
    <CourseContext.Provider
      value={{
        courses: state.courses,
        coursesMedia: state.coursesMedia,
        coursesSuperior: state.coursesSuperior,
        getAllCourses,
        courseTypes: state.courseTypes,
        getCourseTypes,
        courseLevels: state.courseLevels,
        mediaLevel: state.mediaLevel,
        superiorLevel: state.superiorLevel,
        getCourseLevels,
        actualCourse: state.actualCourse,
        idActualCourse: state.idActualCourse,
        actualCourseGroups: state.actualCourseGroups,
        setActualCourse,
        createCourse,
        disableButton: state.disableButton,
        setDisableButton,
        editCourse,
        updateLive,
        deleteCourse,
        createTopic,
        getReportInfo,
      }}
    >
      {props.children}
    </CourseContext.Provider>
  )


}
export default CourseState

