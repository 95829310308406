import React from 'react';
import { Modal } from 'react-responsive-modal';
import { PageHeader } from 'antd';
import styles from './index.module.scss';

const ModalStudentsDetails = ({ valueModal, onCloseModal, title, TableComponent }) => {
    return (
        <Modal
            open={valueModal}
            onClose={onCloseModal}
            closeOnEsc={false}
            showCloseIcon={true}
            closeOnOverlayClick={false}
            classNames={{
                modal: styles.styleModal,
            }}>
            <div>
                <PageHeader
                    className="site-page-header-responsive"
                    title={title}
                    subTitle="" />
                <>
                    {TableComponent}
                </>
            </div>
        </Modal>
    )

}

export default ModalStudentsDetails