export const treeData = [
  {
    title: 'Gestión de usuarios',
    key: 'GU',
    children: [
      {
        title: 'Alumnos',
        key: 'STparent',
        children: [
          {
            title: 'Datos Personales',
            key: 'studentsParent',
            children: [
              { title: 'Leer', key: '/students/-get', dependency: ["/studentsDummy/-get", "/search-get", "/groups/*-get", "/payments/student/?-get", "/payments/*-get"] },
              { title: 'Editar', key: '/students/-put', dependency: ["/studentsDummy/-put", "/students/-get", "/payments/student/?-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
              { title: 'Borrar', key: '/students/-delete', dependency: ["/studentsDummy/-delete", "/students/-put", "/payments/student/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
            ]
          },
          {
            title: 'Pagos',
            key: 'paymentsParent',
            children: [
              { title: 'Leer', key: '/payments/student/?-get', dependency: ['/paymentsDymmy/-get', "/students/-get", "/search-get", "/groups/*-get"] },
              { title: 'Crear', key: '/payments/-post', dependency: ['/paymentsDummy/-post', "/payments/student/?-get", "/payments/*-get", "/students/-get", "/search-get", "/groups/*-get"] },
              // { title: 'Editar', key: '/payments/?-put', dependency: ['/paymentsDummy/-put', "/payments/student/?-get", "/payments/*-get", "/students/-get", "/search-get", "/groups/*-get"] },
              { title: 'Borrar', key: '/payments/*-delete', dependency: ['/paymentsDummy/-delete', "/payments/student/?-get", "/payments/*-get", "/students/-get", "/search-get", "/groups/*-get"] },
            ]
          },
          {
            title: 'Comentarios',
            key: 'commentsParent',
            children: [
              { title: 'Leer', key: '/comments/?-get', dependency: ['/commentsDummy/-get', "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
              { title: 'Crear', key: '/comments/-post', dependency: ['/commentsDummy/-post', "/comments/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
              { title: 'Editar', key: '/comments/?-put', dependency: ['/commentsDummy/-put', "/comments/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
              { title: 'Borrar', key: '/comments/?-delete', dependency: ['/commentsDummy/-delete', "/comments/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
            ]
          },
          {
            title: 'Asistencia',
            key: 'attendanceParent',
            children: [
              { title: 'Leer', key: '/attendance/student/*-get', dependency: ['/attendanceDummy/-get', "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
              { title: 'Crear', key: '/attendance/st/?-post', dependency: ['/attendanceDummy/-post', "/attendance/student/*-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
              {
                title: 'Editar', key: '/attendance/single/?-put', dependency: ['/attendanceDummy/-put', "/attendance/student/*-get",
                  "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get", "/attendance/single/*-delete"]
              },
            ]
          },
          { title: 'Envíar notificaciones', key: '/notifications/student/-post', dependency: ["/templates/*-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
          { title: 'Reenviar comprobante de ins..', key: '/groups/?/receipt/?-post', dependency: ["/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
          { title: 'Reenviar comprobante de pago', key: '/payments/send/?-put', dependency: ["/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
          { title: 'Cambio de grupo', key: '/students/?/groups/?-put', dependency: ["/students/-get", "/search-get", "/groups/*-get", "/payments/*-get", "/course/levels/*-get", "/courses/?/groups-get"] },
          { title: 'Borrar grupo', key: '/students/?/group/?-delete', dependency: ['/studentsDummy/?/group/?-delete', "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get"] },
          { title: 'Inscribir alumno existente', key: '/groups/?/enroll/?-put', dependency: ["/students/-get", "/search-get", "/groups/*-get", "/payments/*-get", "/courses/*-get", "/course/levels/-get"] },
        ],
      },
      {
        title: 'Empleados',
        key: 'users',
        children: [
          { title: 'Leer', key: '/users/-get', dependency: ["/roles/-get", "/groups/*-get"] },
          { title: 'Crear', key: '/users/-post', dependency: ["/users/-get", "/roles/-get", "/groups/*-get"] },
          { title: 'Editar', key: '/users/-put', dependency: ["/users/-get", "/roles/-get", "/groups/*-get"] },
          { title: 'Borrar', key: '/users/-delete', dependency: ["/users/-get", "/users/-put", "/roles/-get", "/groups/*-get"] },
          {
            title: 'Asistencia',
            key: 'attendanceEM',
            children: [
              {
                title: 'Leer', key: '/attendance/user/?-get', dependency: ["/users/-get", "/roles/-get", "/groups/*-get"]
              },
              {
                title: 'Crear', key: '/attendance/users/?-post', dependency: ['/attendance/user/?-get', "/users/-get", "/roles/-get", "/groups/*-get"]
              },
              {
                title: 'Editar', key: '/attendance/user/?-put', dependency: ['/attendance/user/?-get', "/attendance/user/*-delete", "/users/-get", "/roles/-get", "/groups/*-get"]
              },
            ]
          },
        ],
      },
      {
        title: 'Roles',
        key: 'roles',
        children: [
          { title: 'Leer', key: '/roles/*-get' },
          { title: 'Crear', key: '/roles/*-post', dependency: ["/roles/*-get"] },
          { title: 'Editar', key: '/roles/*-put', dependency: ["/roles/*-get"] },
          { title: 'Borrar', key: '/roles/*-delete', dependency: ["/roles/*-get", "/roles/*-put"] },
        ],
      },
    ],
  },
  {
    title: 'Administración escolar',
    key: 'AE',
    children: [
      {
        title: 'Inscripción Usuario Nuevo',
        key: '/students/register-post',
        dependency: ["/courses/*-get", "/course/levels/-get", "/students/email/?-get", "/groups/*-get", "/students/roles/-get"]
      },
      {
        title: 'Sedes',
        key: 'locations',
        children: [
          { title: 'Leer', key: '/locations/-get', dependency: ["/classrooms/location/*-get"] },
          { title: 'Crear', key: '/locations/-post', dependency: ["/locations/-get", "/classrooms/location/*-get"] },
          { title: 'Editar', key: '/locations/-put', dependency: ["/locations/-get", "/classrooms/location/*-get"] },
          { title: 'Borrar', key: '/locations/-delete', dependency: ["/locations/-get", "/locations/-put", "/classrooms/location/*-get"] },
          {
            title: 'Salones',
            key: 'classrooms',
            children: [
              { title: 'Leer', key: '/classrooms/location/*-get', dependency: ["/locations/-get", "/locations/-put"] },
              { title: 'Crear', key: '/classrooms/*-post', dependency: ["/locations/-get", "/locations/-put", "/classrooms/location/*-get"] },
              { title: 'Editar', key: '/classrooms/*-put', dependency: ["/locations/-get", "/locations/-put", "/classrooms/location/*-get"] },
              { title: 'Borrar', key: '/classrooms/*-delete', dependency: ["/locations/-get", "/locations/-put", "/classrooms/*-put", "/classrooms/location/*-get"] }
            ]
          },
        ],
      },
      {
        title: 'Cursos',
        key: 'courses',
        children: [
          { title: 'Leer', key: '/courses/-get', dependency: ["/course/*-get", "/groups/*-get"] },
          { title: 'Crear', key: '/courses/-post', dependency: ["/courses/-get", "/course/*-get", "/groups/*-get"] },
          { title: 'Editar', key: '/courses/-put', dependency: ["/courses/-get", "/course/*-get", "/groups/*-get"] },
          { title: 'Borrar', key: '/courses/-delete', dependency: ["/courses/-get", "/courses/-put", "/course/*-get", "/groups/*-get"] },
          { title: 'Clases en live streaming', key: '/courses/?/updateLive/-put', dependency: ["/courses/-get", "/course/*-get", "/groups/*-get"] },
          {
            title: 'Material Curso en línea',
            key: 'MCO',
            children: [
              { title: 'Leer', key: '/playlists/-get', dependency: ["/courses/-get", "/courses/-put", "/groups/*-get", "/course/*-get", "/playlists/course/*-get", "/videos/*-get"] },
              {
                title: 'Crear/Editar', key: '/playlists/-post',
                dependency: ["/playlists/-put", "/courses/-get", "/courses/-put", "/playlists/-get", "/course/*-get", "/videos/-put",
                  "/videos/-post", "/groups/*-get", "/playlists/course/*-get", "/videos/*-get", "/quiz/*-post", "/quiz/*-put", "/quiz/*-get",
                  "/images/*-post", "/images/*-get", "/images/*-put"]
              },
              {
                title: 'Borrar', key: '/playlists/-delete', dependency: ['/playlists/*-delete', "/playlists/-put", "/courses/-get", "/courses/-put", "/playlists/-get", "/course/*-get", "/videos/-put",
                  "/videos/-post", "/videos/*-delete", "/groups/*-get", "/playlists/course/*-get", "/videos/*-get", "/quiz/*-delete", "/quiz/*-post", "/quiz/*-put", "/quiz/*-get",
                  "/images/*-post", "/images/*-get", "/images/*-put"]
              }
            ]
          },
        ],
      },
      {
        title: 'Grupos',
        key: 'groups',
        children: [
          {
            title: 'Leer', key: '/groups/-get',
            dependency: ["/groups/*-get", "/course/*-get", "/groups/*-get", "/users/*-get", "/course/levels/", "/locations/*-get"]
          },
          { title: 'Crear', key: '/groups/-post', dependency: ["/groups/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"] },
          { title: 'Editar', key: '/groups/-put', dependency: ["/groups/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/attendance/*-get", "/locations/*-get", "schedules/is/available-post"] },
          { title: 'Borrar', key: '/groups/-delete', dependency: ["/groups/-put", "/groups/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/attendance/*-get", "/locations/*-get", "schedules/is/available-post"] },
          {
            title: 'Alumnos por grupo ',
            key: 'studentsGroup',
            children: [

              {
                title: 'Cambio Masivo de alumnos', key: '/students/?/groups/*-put',
                dependency: ["/groups/?/students-get", "/groups/*-put", "/groups/-get", "/users/*-get",
                  "/course/*-get", "/course/levels/*-get", "/courses/?/groups-get", "/classrooms/*-get",
                  "/attendance/*-get", "/locations/*-get", "schedules/is/available-post", "/comments/*-get",
                  "/comments/*-post", "/comments/*-put", "/comments/*-delete"]
              },
              {
                title: 'Enviár notificación a grupo', key: '/notifications/group/?-post',
                dependency: ["/groups/?/students-get", "/groups/*-put", "/groups/-get", "/users/*-get", "/templates/?/versions/?-get",
                  "/course/*-get", "/course/levels/*-get", "/courses/?/groups-get", "/classrooms/*-get",
                  "/attendance/*-get", "/locations/*-get", "schedules/is/available-post", "/comments/*-get",
                  "/comments/*-post", "/comments/*-put", "/comments/*-delete"]
              },
              {
                title: 'Reenviár recibo de ins..', key: 'groups/?/receipt/?-post',
                dependency: ["/groups/?/students-get", "/groups/*-put", "/groups/-get", "/users/*-get", "/templates/?/versions/?-get",
                  "/course/*-get", "/course/levels/*-get", "/courses/?/groups-get", "/classrooms/*-get",
                  "/attendance/*-get", "/locations/*-get", "schedules/is/available-post", "/comments/*-get",
                  "/comments/*-post", "/comments/*-put", "/comments/*-delete"]
              },
              {
                title: 'Eliminar alumno del grupo', key: '/studentsDummy/?/group/?-delete',
                dependency: ['/students/?/group/?-delete', "/groups/?/students-get", "/groups/*-put", "/groups/-get", "/users/*-get", "/templates/?/versions/?-get",
                  "/course/*-get", "/course/levels/*-get", "/courses/?/groups-get", "/classrooms/*-get",
                  "/attendance/*-get", "/locations/*-get", "schedules/is/available-post", "/comments/*-get",
                  "/comments/*-post", "/comments/*-put", "/comments/*-delete"]
              }
            ]
          },
          {
            title: 'Alumnos Información',
            key: 'ST',
            children: [
              {
                title: 'Datos Personales',
                key: 'students',
                children: [
                  {
                    title: 'Leer', key: '/studentsDummy/-get', dependency: ['/students/-get', "/search-get", "/groups/*-get", "/payments/student/?-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Editar', key: '/studentsDummy/-put', dependency: ['/students/-put', "/students/-get", "/payments/student/?-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Borrar', key: '/studentsDummy/-delete', dependency: ['/students/-delete', "/students/-put", "/payments/student/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                ]
              },
              {
                title: 'Pagos',
                key: 'payments',
                children: [
                  {
                    title: 'Leer', key: '/paymentsDymmy/-get',
                    dependency: ['/payments/student/?-get', "/students/-get", "/search-get", "/groups/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Crear', key: '/paymentsDummy/-post', dependency: ['/payments/-post', "/payments/student/?-get", "/payments/*-get", "/students/-get", "/search-get", "/groups/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Editar', key: '/paymentsDummy/-put', dependency: ['/payments/?-put', "/payments/student/?-get", "/payments/*-get", "/students/-get", "/search-get", "/groups/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Borrar', key: '/paymentsDummy/-delete', dependency: ['/payments/*-delete', "/payments/student/?-get", "/payments/*-get", "/students/-get", "/search-get", "/groups/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                ]
              },
              {
                title: 'Comentarios',
                key: 'comments',
                children: [
                  {
                    title: 'Leer', key: '/commentsDummy/-get', dependency: ['/comments/?-get', "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Crear', key: '/commentsDummy/-post', dependency: ['/comments/-post', "/comments/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Editar', key: '/commentsDummy/-put', dependency: ['/comments/?-put', "/comments/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get", "/groups/-get",
                      "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Borrar', key: '/commentsDummy/-delete', dependency: ['/comments/?-delete', "/comments/?-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                ]
              },
              {
                title: 'Asistencia',
                key: 'attendanceST',
                children: [
                  {
                    title: 'Leer', key: '/attendanceDummy/-get', dependency: ['/attendance/student/*-get', "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Crear', key: '/attendanceDummy/-post', dependency: ['/attendance/st/?-post', "/attendance/student/*-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post"]
                  },
                  {
                    title: 'Editar', key: '/attendanceDummy/-put', dependency: ['/attendance/single/?-put', "/attendance/student/*-get", "/students/-get", "/search-get", "/groups/*-get", "/payments/*-get",
                      "/groups/-get", "/groups/?/students/lite/-get", "/users/*-get", "/course/*-get", "/classrooms/*-get", "/locations/*-get", "schedules/is/available-post", "/attendance/single/*-delete"]
                  },
                ]
              },
            ],
          },
        ],
      },
      {
        title: 'Asesorias',
        key: 'appooiments',
        children: [
          { title: 'Leer', key: '/appoiments/-get' },
          { title: 'Editar', key: '/appoiments/-put', dependency: ["/appoiments/-get",] },
        ],
      },
      {
        title: 'Platicas',
        key: 'talks',
        children: [
          { title: 'Leer', key: '/talks/-get', dependency: ["/talks/?-get", "/comments/*-get", '/talks/?/students/-get', '/usersTalks/*-get', '/users/*-get'] },
          {
            title: 'Editar estatus', key: '/talks/?/toggle/-put', dependency: ["/talks/-get", "/comments/*-get", "/talks/?-get",
              '/talks/?/students/-get', '/usersTalks/*-get', '/users/*-get']
          },
          {
            title: 'Crear', key: '/talks/-post', dependency: ["/talks/-get", "/comments/*-get", "/locations/*-get", "/course/levels/-get",
              "/users/*-get", "/schedules/is/available-post", "/classrooms/location/?-get"]
          },
          {
            title: 'Editar', key: '/talks/-put', dependency: ["/talks/-get", "/comments/*-get", "/locations/*-get", "/course/levels/-get",
              "/users/*-get", "/schedules/is/available-post", "/classrooms/location/?-get"]
          },
          {
            title: 'Borrar', key: '/talks/-delete', dependency: ["/talks/-put", "/talks/-get", "/comments/*-get", "/locations/*-get", "/course/levels/-get",
              "/users/*-get", "/schedules/is/available-post", "/classrooms/location/?-get"]
          },
          {
            title: 'Reportes', key: '/talks/reports/?-get', dependency: ["/talks/-put", "/talks/-get", "/comments/*-get", "/locations/*-get", "/course/levels/-get",
              "/users/*-get", "/schedules/is/available-post", "/classrooms/location/?-get"]
          },
          {
            title: 'Alumnos Información',
            key: 'StudentsTalks',
            children: [
              {
                title: 'Datos Personales',
                key: 'studentsT',
                children: [
                  {
                    title: 'Leer', key: '/usersTalks/?-get', dependency: ["/talks/-get", "/talks/?-get", "/comments/*-get", '/talks/?/students/-get', '/users/*-get']
                  },
                  {
                    title: 'Editar', key: '/usersTalks/?-put', dependency: ["/usersTalks/?-get", "/talks/-get", "/talks/?-get", "/comments/*-get", '/talks/?/students/-get', '/users/*-get']
                  },
                  {
                    title: 'Borrar', key: '/usersTalks/?-delete', dependency: ["/usersTalks/?-put", "/usersTalks/?-get", "/talks/-get", "/talks/?-get",
                      "/comments/*-get", '/talks/?/students/-get', '/users/*-get']
                  },
                ]
              },
              {
                title: 'Comentarios',
                key: 'commentsTalks',
                children: [
                  {
                    title: 'Leer', key: '/comments/?userTalks=?/-get',
                    dependency: ["/usersTalks/?-get", "/talks/-get", "/talks/?-get", "/comments/*-get", '/talks/?/students/-get', '/users/*-get']
                  },
                  {
                    title: 'Crear', key: '/comments/test/-post',
                    dependency: ["/comments/*-post", "/comments/?userTalks=?/-get", "/usersTalks/?-get", "/talks/-get", "/talks/?-get", "/comments/*-get", '/talks/?/students/-get', '/users/*-get']
                  },
                  {
                    title: 'Editar', key: '/comments/test/-put',
                    dependency: ["/comments/*-put", "/comments/*-post", "/comments/?userTalks=?/-get", "/usersTalks/?-get", "/talks/-get", "/talks/?-get", "/comments/*-get", '/talks/?/students/-get', '/users/*-get']
                  },
                  {
                    title: 'Borrar', key: '/comments/test/-delete',
                    dependency: ["/comments/test/-put", "/comments/*-put", "/comments/*-post", "/comments/*-delete", "/comments/?userTalks=?/-get", "/usersTalks/?-get", "/talks/-get", "/talks/?-get", "/comments/*-get", '/talks/?/students/-get', '/users/*-get']
                  },
                ]
              },
              { title: 'Cambio de platica', key: '/usersTalks/?/talks/?-put', dependency: ["/usersTalks/?-get", "/talks/-get", "/talks/?-get"] },
              {
                title: 'Enviár notificación personal', key: '/notifications/userTalk/?-post',
                dependency: ["/templates/?/versions/?-get", "/usersTalks/?-get", "/talks/-get"]
              },
              {
                title: 'Inscribir',
                key: '/students/register/test/-post',
                dependency: ["/courses/*-get", "/course/levels/-get", "/students/email/?-get", "/students/register/*-post",
                  "/groups/*-get", "/students/roles/-get", "/usersTalks/?-get", "/talks/-get"]
              },
            ],
          },
          {
            title: 'Ver alumnos tabla',
            key: '/students/show/test/-get',
            dependency: [
              "/talks/-get", "/comments/*-get", "/talks/?-get", '/talks/?/students/-get', '/usersTalks/*-get', '/users/*-get',
              "/talks/attendance/student/*-put", "/usersTalks/*-delete", "/talks/status/student/*-put", "/talks/call/student/*-put",
              //inscribir
              "/students/register/test/-post", "/courses/*-get", "/course/levels/-get", "/students/email/?-get",
              "/students/register/*-post", "/groups/*-get", "/students/roles/-get",
              //Enviár notificación personal
              "/notifications/userTalk/?-post", "/templates/?/versions/?-get", "/usersTalks/?-get",
              //Cambio de platica
              "/usersTalks/?/talks/?-put",
              //comentarios
              "/comments/test/-post", "/comments/test/-put", "/comments/test/-delete",
              "/comments/*-put", "/comments/*-post", "/comments/?userTalks=?/-get", "/comments/*-delete",
              //Datos Personales
              "/usersTalks/?-put", "/usersTalks/?-delete",
            ]
          },
        ],
      },
      {
        title: 'Comentarios de Alumnos xxxxx',
        key: 'studentsComments',
        children: [
          { title: 'Leer', key: '/videoComments/?-get', dependency: ['/videoComments/-get'] },
          { title: 'Borrar', key: '/videoComments/?-delete', dependency: ['/videoComments/-delete'] },
        ]
      },
      {
        title: 'Pase de asistencia',
        key: '/attendance/qr/*-put',
      },
      {
        title: 'Cambiar contraseña',
        key: "/users/?-put",
      },
      {
        title: 'Configuración general',
        key: "/livechannels/-get",
      },
    ],
  },
];