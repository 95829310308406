import React from 'react';
import { Modal } from 'react-responsive-modal';
import EditForm from './components/editForm';

function EditGroup(props) {

  const onCloseModal = () => {
    props.close(false)
  };

  return (
    <Modal
      onClose={onCloseModal}
      open={props.valueModal}
      center={false}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <EditForm onCloseModal={onCloseModal} closeTab={props.closeTab} />
    </Modal>
  )
}
export default EditGroup