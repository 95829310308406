import React, { useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { urlAttendanceQr, fetchAsync } from "../../../helpers/Globals/globals";
import Empty from "../../../helpers/Empty/EmptyComponent";
import LoadingComponent from "../../../helpers/LoadingComponent/index";
import BarcodeReader from "react-barcode-reader";
import CardInfo from "./cardInfo/index";
import TabsModal from "../../Students/components/tabsStudents/TabsModal";
import AlertMessage from "./alertMessage";

import "antd/es/avatar/style/index.css";
import "antd/es/result/style/index.css";

const qrImage = process.env.PUBLIC_URL + "/img/icons/qrCode.png";

const ScanView = (props) => {
  const [loading, setLoading] = useState(false);
  const [showTabsStudent, setShowTabsStudent] = useState(false);
  const [groupArray, setGroupArray] = useState([]);
  const [studentData, setStudentData] = useState(null);
  const [message, setMessage] = useState("");
  const [successAttendance, setSuccessAttendance] = useState(false);
  const [talk, setTalk] = useState(false);

  const handleScan = (data) => {
    setLoading(true);
    let validation = new RegExp(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/).test(
      data
    );
    if (validation) {
      updateAttendance(data);
    } else {
      setMessage("El código escaneado no es de un usuario del sistema.");
      setLoading(false);
    }
  };

  const handleError = (err) => {
    setMessage(
      "Ocurrio un error desconocido, probablemente sea problema del equipo de escanéo: "
    );
  };

  const updateAttendance = (id) => {
    fetchAsync(urlAttendanceQr + id, "", "PUT")
      .then((result) => {
        setLoading(false);
        if (result.success) {
          if ("isTalkUser" in result) {
            setStudentData(result.student);
            setTalk(result.isTalkUser);
            setSuccessAttendance(result.student.attendance);
          } else {
            let groupsIds = [];
            groupsIds = result.student.groups.map((data) => data._id);
            result.student.groups = groupsIds;

            setGroupArray(result.groups);
            setStudentData(result.student);
            setMessage(result.message);
            setSuccessAttendance(true);
          }
        } else {
          const { error, message, groups, student } = result;
          setMessage(error ? error?.message?.es : message);
          setSuccessAttendance(false);
          setStudentData("");
        }
        return result;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const openModalStudent = () => {
    setShowTabsStudent(true);
  };

  return (
    <Container>
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <Card className="pricing-card pricing-card--success">
        {loading ? (
          <CardBody className="pricing-card__body">
            <div className="svg-container stepFinalizar">
              <LoadingComponent message="Cargando..!" />
            </div>
          </CardBody>
        ) : studentData === null ? (
          <Empty
            image={qrImage}
            message="Configura el dispositivo y escanea un código para comenzar."
          />
        ) : (
          <CardBody className="container_qrCode_result pricing-card__body">
            <AlertMessage
              mesaage={
                talk && successAttendance
                  ? "Asistencia guardada éxitosamente"
                  : message
              }
              status={successAttendance ? "success" : "error"}
            />
            {studentData && studentData !== "" && (
              <div>
                <CardInfo
                  studentData={studentData}
                  groupsArray={groupArray}
                  successAttendance={successAttendance}
                  openStudent={openModalStudent}
                  isTalkUser={talk}
                />
              </div>
            )}
          </CardBody>
        )}
      </Card>

      {showTabsStudent && (
        <TabsModal
          valueModal={showTabsStudent}
          close={setShowTabsStudent}
          studentInfo={studentData}
          updateStudentContext={() => ""}
          afterDeleteStudent={() => ""}
          addPaymentsStudent={() => ""}
        />
      )}
      {/* <button
        style={{ padding: "20px", background: "yellow" }}
        onClick={() => updateAttendance()}
      >
        Test service
      </button> */}
    </Container>
  );
};

export default ScanView;
