import React, { memo } from "react";
import { PageHeader } from "antd";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Can from "../../helpers/Secure/Can";
import { PlusOutlined } from "@ant-design/icons";

import styles from "./index.module.scss";
import "antd/es/page-header/style/index.css";

const MainPageHeader = memo(
  ({
    title = "",
    subTitle = "",
    buttonTitle = "",
    buttonOnClick = null,
    buttonRoute = "",
    aditionalData = null,
    canActions = [],
  }) => {
    const history = useHistory();

    return (
      <header className={styles.main_container_header}>
        <PageHeader
          className="site-page-header-responsive"
          onBack={buttonRoute != "" ? () => history.push(buttonRoute) : false}
          title={title}
          subTitle={subTitle}
          extra={
            canActions.length > 0 ? (
              <Can do={canActions[0]} on={canActions[1]}>
                <Button
                  key="b1"
                  size="sm"
                  color="success"
                  className="btn icon"
                  onClick={() => buttonOnClick && buttonOnClick()}
                  disabled={aditionalData}
                >
                  <p>
                    <PlusOutlined /> {buttonTitle}
                  </p>
                </Button>
              </Can>
            ) : (
              buttonOnClick && [
                <Button
                  key="b1"
                  size="sm"
                  color="success"
                  className="btn icon"
                  onClick={() => buttonOnClick && buttonOnClick()}
                  disabled={aditionalData}
                >
                  <p>
                    <PlusOutlined /> {buttonTitle}
                  </p>
                </Button>,
              ]
            )
          }
        />
      </header>
    );
  }
);
MainPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonRoute: PropTypes.string,
  aditionalData: PropTypes.bool,
};
export default MainPageHeader;
