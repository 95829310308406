import { AbilityBuilder, Ability } from '@casl/ability'
// TODO
// https://github.com/stalniy/casl/tree/master/packages/casl-react
let allows
let template = []
allows = JSON.parse(localStorage.getItem('allows'))
if (allows) {
  template = allows
}
Ability.addAlias('post', 'create')
Ability.addAlias('put', 'update')

function dynamicCan(canFunction) {
  for (let i = 0; i < template.length; i++) {
    canFunction(template[i].actions, template[i].subject)
  }
}

let ability = AbilityBuilder.define(can => {
  Ability.addAlias('post', 'create')
  Ability.addAlias('put', 'update')
  dynamicCan(can)
})

function forceUpdate(data) {
  ability.update(data)
}

export {
  ability,
  forceUpdate
};