import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import SidebarComplement from './sidebarcomplement/SIdeBarComplement';
import SidebarContext from '../../context/sidebar/SidebarContext'

const Layout = (props) => {
  const sidebarContext = useContext(SidebarContext);
  const {
    changeSidebarShow,
    changeSidebarMobileShow,
    changeSidebarComplementShow,
    killSideBar,

    sidebarShow,
    sidebarMobileShow,
    sidebarComplementShow,
    sidebarComplementContent,
  } = sidebarContext

  useEffect(() => {
    killSideBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname])


  const changeSidebarVisibility = () => {
    changeSidebarShow(!sidebarShow)
  };

  const changeMobileSidebarVisibility = () => {
    changeSidebarMobileShow(!sidebarMobileShow)
  };

  const layoutClass = classNames({
    layout: true,
    'layout--collapse': !sidebarShow,
    'layout--collapse--complement': !sidebarComplementShow,
  });

  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        sidebarCollapse={!sidebarShow}
        sidebarCollapseComplement={!sidebarComplementShow}
        changeSidebarVisibility={changeSidebarVisibility}
      />

      <Sidebar
        sidebarShow={sidebarShow}
        sidebarShowMobile={sidebarMobileShow}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />

      <SidebarComplement
        sidebarComplementShow={sidebarComplementShow}
        sidebarComplementContent={sidebarComplementContent}
        changeSidebarComplementShow={changeSidebarComplementShow}
      />

    </div>
  )
}

export default withRouter(Layout);
