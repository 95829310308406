import React, { useState } from 'react'
import { Row, Col, Card, Button } from "reactstrap"
import Modal from "react-responsive-modal"

const ModalEditAppoimentList = ({ value, onCloseModal, qouta, updateQuoota }) => {
    const [size, setSize] = useState(qouta || 10)

    return (
        <Modal
            open={value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>
            <Card className="addCursoSize mt-4 margenCursoAdd">
                <Row>
                    <Col sm={12} md={7}>
                        <div className="card__title">
                            <h4 className="bold-text">Editar cupo</h4>
                        </div>

                    </Col>
                    <Col sm={12} md={5}>
                        <Button color="success" type="button"
                            disabled={!size}
                            className="btn-sm float-right"
                            onClick={() => updateQuoota(size)}
                        >Actualizar</Button>
                        <Button type="button" className="btn-sm float-right" onClick={() => onCloseModal()}>Salir</Button>
                    </Col>
                </Row>
                <div className="form__form-group">
                    <span className="form__form-group-label">Nuevo tamaño de la platica</span>
                    <div className="form__form-group-field">
                        <input type="number"
                            className="form-control"
                            id="inpuName" autocomplete="off"
                            value={size}
                            onChange={(event) => { setSize(event.target.value) }} />
                    </div>
                </div>
            </Card>
        </Modal>
    )

}
export default ModalEditAppoimentList