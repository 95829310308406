import React, { useContext } from "react";
import Frame, { FrameContextConsumer } from "react-frame-component";

import MessagesContext from "../context/messagesContext";
import Sms from "../../sms/index";

import styles from "./index.module.scss";

const MessageIcon = `${process.env.PUBLIC_URL}/img/MessageIcon.png`;
const structureMessage =
  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t {{{message}}}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";

const ShowDevice = ({ template, text, typeDevice = "" }) => {
  const context = useContext(MessagesContext);
  const { textEmail, textSms } = context;

  const showDevices = (typeDevice) => {
    switch (typeDevice) {
      case "email":
        return (
          <div className={styles.pc_device_container}>
            <Frame initialContent={template}>
              <FrameContextConsumer>
                {({ document, window }) => {
                  let dummy = structureMessage;
                  dummy = dummy.replace("{{name}}", "");
                  dummy = dummy.replace("{{message}}", textEmail);
                  document.getElementById("DYNAMIC_ID_CONTENT").innerHTML =
                    dummy;
                }}
              </FrameContextConsumer>
            </Frame>
          </div>
        );
      case "sms":
        return <Sms textSms={textSms} />;
      default:
        break;
    }
  };

  return showDevices(typeDevice);
};

export default ShowDevice;
