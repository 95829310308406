import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import "moment/locale/es";

import { showMessageLoading } from "../../../../helpers/MessageAndNotificationUtils";
import Attendance from "../../../Students/components/tabsStudents/attendance/index";
import LoadingComponent from "../../../../helpers/LoadingComponent";

import styles from "./index.module.scss";

let closeLoading = "";

function AttendanceEmployee({
  openAttendance,
  setOpenAttendance,
  actualEmployee,
  getEmployeeAttendance,
  setAttendace,
  updateAttendace,
  deleteAttendance,
}) {
  const [attendaceInfo, setAttendaceInfo] = useState();
  const [loading, seLoading] = useState(false);

  useEffect(() => {
    seLoading(true);
    const result = getEmployeeAttendance();
    result.then((res) => {
      if (res !== undefined && res.success) {
        const sortArrayDates = res.attendance.attendance.sort(
          (a, b) => moment(b.date) - moment(a.date)
        );
        res.attendance.attendance = sortArrayDates;
        setAttendaceInfo(res.attendance);
      } else {
        let bodyFalse = {
          average: "",
          attendance: [],
          attendanceData: "",
        };
        setAttendaceInfo(bodyFalse);
      }
      seLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAttendance = (body) => {
    closeLoading = LoadingMessage("Creando...");
    const result = setAttendace(body);
    result.then((res) => {
      if (res !== undefined && res.success) {
        let array = [...attendaceInfo.attendance];
        array.push(res.attendance);
        const sortArray = array.sort((a, b) => moment(b.date) - moment(a.date));
        setAttendaceInfo({
          ...attendaceInfo,
          attendance: sortArray,
        });
        closeLoading();
      }
    }, []);
  };

  const updateAttendanceEmployee = (body) => {
    closeLoading = LoadingMessage("Actualizando...");
    const result = updateAttendace(body);
    result.then((res) => {
      if (res !== undefined && res.success) {
        let array = [...attendaceInfo.attendance];
        let index = array.findIndex((item) => item.date === res.data.date);
        array[index] = res.data;
        setAttendaceInfo({
          ...attendaceInfo,
          attendance: array,
        });
        closeLoading();
      }
    }, []);
  };

  const deleteAttedanceEmployee = (idAttedance) => {
    closeLoading = LoadingMessage("Eliminando...");
    const result = deleteAttendance(idAttedance);
    result.then((res) => {
      if (res !== undefined && res.success) {
        let newAttendaceArray = attendaceInfo.attendance.filter((item) =>
          item.hasOwnProperty("_id")
            ? item._id !== idAttedance
            : item.attendanceId !== idAttedance
        );
        setAttendaceInfo({
          ...attendaceInfo,
          attendance: newAttendaceArray,
        });
      }
      closeLoading();
    });
  };

  const onCloseModal = () => {
    setOpenAttendance(false);
  };

  const LoadingMessage = (message) => {
    return showMessageLoading(message, 0);
  };

  return (
    <Modal
      open={openAttendance}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <div className={styles.container_attendance_employee}>
        <div>
          <Button
            size="sm"
            className={`btn btn-secondary float-right`}
            onClick={() => onCloseModal()}
          >
            <p>Salir</p>
          </Button>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <Attendance
            dataAlumno={actualEmployee}
            employee={true}
            attendaceInfoEmployee={attendaceInfo}
            createAttendance={createAttendance}
            updateAttendanceEmployee={updateAttendanceEmployee}
            deleteAttedanceEmployee={deleteAttedanceEmployee}
            showButtonClose={false}
          />
        )}
      </div>
    </Modal>
  );
}
Attendance.propTypes = {};
export default AttendanceEmployee;
