import React, { useState } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  Redirect,
} from "react-router-dom";
import { context } from 'context/GlobalContext'
import { useContext } from 'react';

function LogInForm() {
  //import global context Login and others
  const { state, actions } = useContext(context)

  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const sendLogin = (e) => {
    actions.login({ email, password })
  }


  const showPasswordFunction = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword)
  };

  const onEnterKey = (e) => {
    if (e.key === 'Enter') {
      sendLogin(e)
    }
  }


  return state.isLoggedIn === true ?
    (<Redirect to="/" />)
    :
    (
      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Tú correo electrónico</span>
          <div className="form__form-group-field">
            <input
              name="email"
              type="email"
              autoComplete="email"
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              value={email}
              placeholder="hola@preparacion.com"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tú contraseña</span>
          <div className="form__form-group-field">
            <input
              name="password"
              value={password}
              autoComplete="current-password"
              onKeyPress={(event) => {
                onEnterKey(event)
              }}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
              type={showPassword ? 'text' : 'password'}
              placeholder="Contraseña"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => showPasswordFunction(e)}
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <Link to="/forgot">¿Olvidaste tu contraseña?</Link>
          </div>
        </div>
        <div className="account__btns">
          <Button onClick={(e) => sendLogin(e)} color="primary" size="lg" className="account__btn">Entrar</Button>
        </div>
      </form>
    );
}
export default LogInForm;
