import moment from "moment";
import _ from "lodash";
import "moment/locale/es";
import {
  GET_STUDENTS,
  STUDENTS_SEARCH,
  LOADING_STUDENTS_TABLE,
  SET_ACTUAL_STUDENT,
  GET_PAYMENT_STUDENT,
  GET_GROUPS_STUDENT,
  LOADING_GORUPS_TAB,
  LOADING_PAYMENTS_TAB,
  UPDATE_AFTER_DELETE_REGISTER,
  UPDATE_AFTER_CHANGE_GROUP,
  UPDATE_STUDENT_AFTER_EDIT,
  UPDATE_AFTER_DELETE_STUDENT,
  ADD_PAYMENT_UPDATE,
  UPDATE_AFTER_ENROLL_GROUP,
  RELOAD_STUDENT,
  SET_FOLIOS_TEMPORAL,
  SET_FOLIO,
  EDIT_FOLIO,
  DELETE_FOLIO,
  SEARCH_WORD,
} from "../types/index";

const Reducer = (state, action) => {
  switch (action.type) {
    case SEARCH_WORD:
      return {
        ...state,
        actualSearch: action.payload,
      };
    case GET_STUDENTS:
      return {
        ...state,
        allStudents: action.payload.filter((v) => v),
        resultCount: 0,
      };
    case STUDENTS_SEARCH:
      return {
        ...state,
        allStudents: action.payload.result.hits.hits.filter((v) => v),
        resultCount:
          action.payload.result.hits.total.value ||
          action.payload.result.hits.extTotal,
      };
    case LOADING_STUDENTS_TABLE:
      return {
        ...state,
        loadingTable: action.payload,
      };
    case SET_ACTUAL_STUDENT:
      //actualizamos el estudiante en el array de estudiantes a pintar en la tabla
      let arrayStudents = state.allStudents;
      const indexStudent = state.allStudents.findIndex((obj) =>
        obj != null ? obj._id === action.payload._id : ""
      );
      arrayStudents[indexStudent] = action.payload;
      return {
        ...state,
        actualStudent: action.payload,
        allStudents: arrayStudents,
      };
    case RELOAD_STUDENT:
      //actualizamos el estudiante en el array de estudiantes a pintar en la tabla
      let arrayS = state.allStudents;
      const index = state.allStudents.findIndex((obj) =>
        obj != null ? obj._id === action.payload._id : ""
      );
      arrayS[index] = action.payload;
      return {
        ...state,
        actualStudent: action.payload,
        allStudents: arrayS,
      };
    case GET_PAYMENT_STUDENT:
      const sortedArrayPayments = action.payload.sort(
        (a, b) => moment(b.date) - moment(a.date)
      );
      return {
        ...state,
        actualStudentPayments: sortedArrayPayments,
        // actualStudentPayments: action.payload,
      };
    case GET_GROUPS_STUDENT:
      const sortedArrayDos = action.payload.sort(
        (a, b) => moment(b.startDate) - moment(a.startDate)
      );
      return {
        ...state,
        actualStudentGroups: sortedArrayDos,
      };
    case LOADING_GORUPS_TAB:
      // if (action.payload) {
      //   state.actualStudentGroups = []
      // }
      return {
        ...state,
        loadingGroupTabs: action.payload,
      };
    case LOADING_PAYMENTS_TAB:
      return {
        ...state,
        loadingPaymentsTabs: action.payload,
      };
    case UPDATE_AFTER_DELETE_REGISTER:
      let newGroups = state.actualStudentGroups.filter(
        (item) => item._id !== action.payload
      );
      state.actualStudent.groups = state.actualStudent.groups.filter(
        (item) => item !== action.payload
      );
      return {
        ...state,
        actualStudentGroups: newGroups,
      };
    case UPDATE_AFTER_CHANGE_GROUP:
      //actualizamos el estudiante en el array de estudiantes a pintar en la tabla
      //const objIndex = state.allStudents.findIndex((obj => obj != null ? obj._id === action.payload._id : ""));
      //state.allStudents[objIndex] = action.payload
      //actualizamos el array de grupos en el estado del estudiante actual
      //state.actualStudent.groups = action.payload.groups
      //eliminamos el grupo anterior
      const newArrayGroups = state.actualStudentGroups.filter(
        (item) => item._id !== action.payload.oldIdGroup
      );
      //actualizamos y agregamos el grupo nuevo
      newArrayGroups.push(action.payload.newGroupInfo);
      //ordenamos los grupos
      const sortedGroups = newArrayGroups.sort(
        (a, b) => moment(b.startDate) - moment(a.startDate)
      );
      // console.log(action.payload)
      // console.log(newArrayGroups)
      return {
        ...state,
        actualStudentGroups: sortedGroups,
      };
    case UPDATE_STUDENT_AFTER_EDIT:
      //actualizamos el estudiante en el array de estudiantes a pintar en la tabla
      const indexStudents = state.allStudents.findIndex((obj) =>
        obj != null ? obj._id === action.payload._id : ""
      );
      state.allStudents[indexStudents] = action.payload;
      return {
        ...state,
        actualStudent: action.payload,
        loadingTable: false,
      };
    case UPDATE_AFTER_DELETE_STUDENT:
      const newArrayStudents = state.allStudents.filter(
        (item) => item._id !== action.payload
      );
      return {
        ...state,
        allStudents: newArrayStudents,
        actualStudent: [],
        actualStudentPayments: [],
        actualStudentGroups: [],
      };
    case ADD_PAYMENT_UPDATE:
      if (!action.payload.hasOwnProperty("amount")) {
        state.actualStudentPayments.splice(
          state.actualStudentPayments.findIndex(
            (item) => item._id === action.payload
          ),
          1
        );
        return state;
      }
      //agregamos el pago nuevo
      state.actualStudentPayments.push(action.payload);
      const sortedArrayUpdate = state.actualStudentPayments.sort(
        (a, b) => moment(b.date) - moment(a.date)
      );
      return {
        ...state,
        actualStudentPayments: sortedArrayUpdate,
      };
    case UPDATE_AFTER_ENROLL_GROUP:
      //agregamos el grupo nuevo
      state.actualStudentGroups.push(action.payload);
      //ordenamos los grupos
      const sortG = state.actualStudentGroups.sort(
        (a, b) => moment(b.startDate) - moment(a.startDate)
      );
      return {
        ...state,
        newArrayGroups: sortG,
      };
    // folios

    case SET_FOLIO:
      let studentObj = _.cloneDeep(state.actualStudent);
      studentObj.materialFolios.unshift(action.payload);
      return {
        ...state,
        actualStudent: studentObj,
      };

    case EDIT_FOLIO:
      let studentCopy = _.cloneDeep(state.actualStudent);
      const indexFolio = state.actualStudent.materialFolios.findIndex(
        (obj) => obj._id == action.payload._id
      );
      studentCopy.materialFolios[indexFolio] = action.payload;

      return {
        ...state,
        actualStudent: studentCopy,
      };

    case DELETE_FOLIO:
      let studentData = _.cloneDeep(state.actualStudent);
      let arrayFolios = studentData.materialFolios.filter(
        (data) => data._id !== action.payload
      );
      studentData.materialFolios = arrayFolios;

      return {
        ...state,
        actualStudent: studentData,
      };
    default:
      return state;
  }
};
export default Reducer;
