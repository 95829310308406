import React, { useContext, PureComponent } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../Layout/index";
import Material from "../Material/index";
import NotFound404 from "../DefaultPages/404/index";
import LogIn from "../LogIn/index";
import ForgotForm from "../PasswordRecovery/index";
import ExpertConfig from "../ExpertConfig/index";
import PasswordUpdate from "../PasswordUpdate/index";
import Employees from "../employess/index";
import Sedes from "../Sedes/index";
import Roles from "../Roles/index";
import Salones from "../Salones/index";
import Cursos from "../Cursos/index";
import Students from "../Students/index";
import Groups from "../Grupos/index";
import Platicas from "../Platicas/index";
import Asesorias from "../Appoiments/index";
import AttendanceQr from "../AttendanceQr/index";
import AssignationQr from "../AssignationQr/index";
import Comentarios from "containers/Comentarios";
import Quizzes from "../Quizzes";

//GLOBAL CONTEXT
import { context } from "../../context/GlobalContext";

//Contex Routes
import SocketContext from "context/SocketContext";
import EmployeeContextProvider from "../employess/components/context/EmployeeContext";
import CourseState from "../../context/courses/CourseState";
import LocationsState from "../../context/locations/LocationsState";
import GroupsState from "context/groups";
import { StudentsProvider } from "../../context/students/StudentsContext";
import { ClassroomsProvider } from "../../context/classrooms/ClassroomsContext";
import SidebarState from "../../context/sidebar/SidebarState";
import QuizState from "../../context/quiz/QuizState";
import TalksState from "context/TalksContext";
import AppoimentsState from "../Appoiments/context/AppoimentsState";
import RolesState from "../../context/roles/RolesState";
import CommentContext from "context/CommentContext";

class WrappedRoutes extends PureComponent {
  componentDidUpdate(prevProps, prevState) {
    console.log("prev", prevProps);
  }
  render() {
    return (
      <>
        <Layout />
        <div className="container__wrap">
          <Switch>
            <Route exact path="/" render={() => <div />}></Route>
            <Route exact path="/home" render={() => <div />}></Route>
            <Route exact path="/cuestionarios/:idCourse/:idPlaylist/:idQuiz?">
              <QuizState>
                <Quizzes />
              </QuizState>
            </Route>
            <Route exact path="/attendanceQr">
              <AttendanceQr />
            </Route>
            <Route exact path="/asignationQr">
              <AssignationQr />
            </Route>
            <Route exact path="/edit/password">
              <PasswordUpdate />
            </Route>

            <Route exact path="/alumnos">
              <StudentsProvider>
                <Students />
              </StudentsProvider>
            </Route>

            <Route exact path="/grupos">
              <GroupsState>
                <Groups />
              </GroupsState>
            </Route>

            <Route exact path="/empleados">
              <EmployeeContextProvider>
                <Employees />
              </EmployeeContextProvider>
            </Route>

            <Route exact path="/roles">
              <RolesState>
                <Roles />
              </RolesState>
            </Route>

            <Route exact path="/platicas">
              <TalksState>
                <Platicas />
              </TalksState>
            </Route>

            <Route exact path="/comentarios">
              <CommentContext>
                <Comentarios />
              </CommentContext>
            </Route>

            <Route exact path="/asesorias">
              <AppoimentsState>
                <Asesorias />
              </AppoimentsState>
            </Route>

            <Route exact path="/sedes">
              <LocationsState>
                <Sedes />
              </LocationsState>
            </Route>

            <Route exact path="/sedes/salones/:idSede/:nameSede">
              <ClassroomsProvider>
                <Salones />
              </ClassroomsProvider>
            </Route>

            <Route exact path="/expertConfig">
              <ExpertConfig />
            </Route>

            <Route exact path="/cursos">
              <CourseState>
                <Cursos />
              </CourseState>
            </Route>

            <Route exact path="/material/:idCourse">
              <Material />
            </Route>
            <Route exact path="/404">
              <NotFound404 />
            </Route>
            <Redirect from="*" to="/404" />
          </Switch>
        </div>
      </>
    );
  }
}

function Router() {
  const { state } = useContext(context);

  return state && state.isLoggedIn ? (
    <div className="theme-light">
      <div className="wrapper">
        <SocketContext>
          <SidebarState>
            <Route path="/" component={() => <WrappedRoutes />} />
          </SidebarState>
        </SocketContext>
      </div>
    </div>
  ) : (
    <div className="theme-light">
      <div className="wrapper">
        <Switch>
          <Route exact path="/" component={LogIn} />
          <Route exact path="/log_in" component={LogIn} />
          <Route exact path="/forgot" component={ForgotForm} />
          <Redirect from="*" to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Router;
