import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Card,
} from "reactstrap";
import { Tabs, Popconfirm } from "antd";

import RolesContext from "../../../context/roles/RolesContext";

import { reverseServiceToTree } from "../../../helpers/Secure/utils";
import { structPermissionTree } from "../../../helpers/Secure/utils";
import {
  showMessageLoading,
  showMessageSuccess,
  showMessageError,
  showMessageWarning,
} from "../../../helpers/MessageAndNotificationUtils";
import Can from "../../../helpers/Secure/Can";

import PermissionList from "./PermissionList";
import UsersList from "./UsersList";

import DeleteIcon from "mdi-react/DeleteIcon";

import styles from "./index.module.scss";
import "../../../scss/component/motion.scss";
import "antd/lib/tabs/style/index.css";
import "antd/lib/popover/style/index.css";
import "antd/lib/popconfirm/style/index.css";

let loadingMessage;
const { TabPane } = Tabs;

const SidebarRoles = ({ onClosSidebar }) => {
  const rolesContext = useContext(RolesContext);
  const {
    selectedRole,
    deleteRole,
    updateRole,
    roleUsers,
    getRolesUsers,
  } = rolesContext;

  const [disableButton, setDisableButton] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRoleEdit, setSelectedRoleEdit] = useState({});
  const [Rol, setRol] = useState({});
  const [checkedKeys, setCheckedKeys] = useState([]);

  useEffect(() => {
    if (isUpdate) {
      let bodyRole = {
        name: selectedRole.name,
        description: selectedRole.description,
      };
      setSelectedRoleEdit(bodyRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  useEffect(() => {
    setRol(selectedRole);
    setCheckedKeys(
      reverseServiceToTree(selectedRole ? selectedRole.allows : [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  const onChangeinputs = (e) => {
    setSelectedRoleEdit({
      ...selectedRoleEdit,
      [e.target.name]: e.target.value,
    });
  };

  const handlerCheck = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
  };

  const update = () => {
    if (selectedRoleEdit.name === "")
      return showMessageWarning("Debes escribir un nombre de rol", 2);
    if (selectedRoleEdit.description === "")
      return showMessageWarning("Debes escribir una descripción", 2);
    if (checkedKeys.length === 0)
      return showMessageWarning("!Atención¡, No as seleccionado ningún rol", 3);
    setDisableButton(true);
    loadingMessage = showMessageLoading("Actualizando Rol", 0);
    let allows = structPermissionTree(checkedKeys);
    allows.forEach((data) => {
      data.methods = [...new Set(data.methods)];
    });
    selectedRoleEdit.allows = allows;
    let result = updateRole(selectedRoleEdit);
    result.then((res) => {
      if (res === undefined || res.success === false)
        showMessageError("Ocurrio un error", 2);
      loadingMessage();
      cancel();
      setDisableButton(false);
    });
  };

  const onDelete = () => {
    loadingMessage = showMessageLoading("Eliminando", 0);
    setDisableButton(true);
    let result = deleteRole();
    result.then((res) => {
      setDisableButton(false);
      loadingMessage();
      if (res === undefined || res.success === false)
        showMessageError("Ocurrio un error", 2);
      if (res.success) {
        showMessageSuccess("Rol eliminado exitosamente", 2);
        onClosSidebar();
      }
    });
  };

  const cancel = () => {
    setIsUpdate(false);
    setSelectedRoleEdit({});
  };

  return (
    <Container className={styles.container}>
      <Row>
        <Col>
          {isUpdate ? (
            <>
              <Button
                size="sm"
                className="icon square float-right"
                type="button"
                color="success"
                outline
                onClick={cancel}
                disabled={disableButton}
              >
                <p>Cancelar</p>
              </Button>
              <Button
                className="icon square float-right mr-2"
                size="sm"
                type="button"
                color="success"
                outline
                onClick={update}
                disabled={disableButton}
              >
                <p>
                  <i className="material-icons">done</i>Guardar
                </p>
              </Button>
            </>
          ) : (
            <Can do="put" on="/roles/*">
              <Button
                size="sm"
                className="icon square float-right"
                type="button"
                color="success"
                outline
                onClick={() => setIsUpdate(true)}
              >
                <p>
                  <i className="material-icons">edit</i>Editar
                </p>
              </Button>
            </Can>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {isUpdate ? (
            <div>
              <div className="form__form-group">
                <span className="form__form-group-label">Nombre</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="inpuName"
                    autoComplete="off"
                    value={selectedRoleEdit.name}
                    onChange={onChangeinputs}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Descripción</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    name="description"
                    className="form-control"
                    id="inputDes"
                    autoComplete="off"
                    value={selectedRoleEdit.description}
                    onChange={onChangeinputs}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.name_description}>
              <p>{selectedRole.name}</p>
              <p>{selectedRole.description}</p>
            </div>
          )}
          <Tabs size="small">
            <TabPane tab="Permisos" key="1">
              <PermissionList
                Rol={Rol}
                handlerCheked={handlerCheck}
                isUpdate={isUpdate}
                disabled={false}
              />
            </TabPane>
            {isUpdate ? (
              ""
            ) : (
              <TabPane tab="Usuarios" key="2">
                <UsersList
                  rol={Rol}
                  getRolesUsers={getRolesUsers}
                  roleUsers={roleUsers}
                />
              </TabPane>
            )}
          </Tabs>
          {!isUpdate ? (
            ""
          ) : (
            <Can do="delete" on="/roles/*">
              <div>
                <Popconfirm
                  title="¿Estás seguro de eliminar este Rol？Esta acción no puede deshacerse"
                  okText="Si"
                  cancelText="No"
                  icon={<i className="material-icons red_icon">error</i>}
                  placement="topLeft"
                  onConfirm={onDelete}
                >
                  <Button
                    size="sm"
                    className="btn float-right"
                    color="danger"
                    disabled={disableButton}
                  >
                    <p>
                      <DeleteIcon />
                      Eliminar
                    </p>
                  </Button>
                </Popconfirm>
              </div>
            </Can>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default SidebarRoles;
