import React, { useState, useEffect } from 'react';
import { fetchAsync, urlPlaylist } from '../../../../helpers/Globals/globals'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Col, Container, Row } from 'reactstrap'
import ContentList from './ContentList'
import { showMessageError } from '../../../../helpers/MessageAndNotificationUtils'
const boardId = "1992"


const Board = ({ idCourse, idPlaylist, idChapter, openModalVideoView }) => {
    const [sourceId, setSourceId] = useState(idPlaylist)
    const [destinationId, setDestinationId] = useState(idChapter)
    const [sourceList, setSourceList] = useState([])
    const [destinationList, setDestinationList] = useState([])

    useEffect(() => {
        console.log("BOARD")
    }, [])

    //draggableId is the id of the item to drag
    //
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const reorderService = (idPlaylist, array) => {
        let body = []
        let items = [...array]
        for (let a = 0; a < items.length; a++) {
            let newObj = {
                order: a + 1,
                _id: items[a]._id,
                type: items[a].type
            }
            body.push(newObj)
        }
        fetchAsync(urlPlaylist + "updateOrder/" + idPlaylist, JSON.stringify(body), "PUT", "default")
            .then((data) => {
                this.updateContent()
            }).catch((reason) => {
                console.log(reason)
            });
    }

    const changeMaterial = (idSource, idDestination, element, orderList) => {
        let body = {
            _id: element._id,
            type: element.type
        }
        fetchAsync(`${urlPlaylist}changeMaterial/${idSource}/${idDestination}`, JSON.stringify(body), "PUT", "default")
            .then((data) => {
                if (data.success) {
                    reorderService(idDestination, orderList)
                }
            }).catch((reason) => {
                console.log(reason)
            });

    }
    const handleDragStart = () => {
        if (sourceId === destinationId) {
            showMessageError("No puedes transferir material a la misma carpeta", 3)
        } else if (destinationId === idCourse) {
            showMessageError("Debes seleccionar un cápitulo del lado derecho para continuar", 3)
        }
    }

    const handleDragEnd = ({ draggableId, source, destination, type }) => {
        if (!destination) {
            return;
        }
        //If is drag in the same list then 
        if (source.droppableId === destination.droppableId) {
            let id
            let list
            if (source.droppableId === sourceId) {
                id = sourceId
                list = reorder(sourceList, source.index, destination.index)
                setSourceList(list)

            } else {
                id = destinationId
                list = reorder(destinationList, source.index, destination.index)
                setDestinationList(list)
            }
            reorderService(id, list)
        } else {
            let idSource
            let idDestination
            let element
            let orderList
            const array = Array.from(sourceList);
            const array2 = Array.from(destinationList)
            if (source.droppableId === sourceId) {
                idSource = sourceId
                idDestination = destinationId
                const [removed] = array.splice(source.index, 1);
                array2.splice(destination.index, 0, removed)
                element = removed
                orderList = array2
            } else {
                idSource = destinationId
                idDestination = sourceId
                const [removed] = array2.splice(source.index, 1);
                array.splice(destination.index, 0, removed)
                element = removed
                orderList = array
            }
            setSourceList(array)
            setDestinationList(array2)
            changeMaterial(idSource, idDestination, element, orderList)
        }
    }

    return (
        <Container className="container_material">
            <Row>
                <Col xs="12" className="content_chapters_playlist">
                    <div className="div_playlist_chapter">
                        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                            <Droppable droppableId={boardId} type="COLUMN" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: "flex" }}>
                                        <Row>
                                            <Col xs={6} style={{ overflowY: "scroll", maxHeight: "calc(100vh - 140px)" }}>
                                                <ContentList
                                                    listId={"XDA1"}
                                                    idPlayList={sourceId}
                                                    setPlaylistId={setSourceId}
                                                    list={sourceList}
                                                    setList={setSourceList}
                                                    disabledDrop={sourceId === destinationId || destinationId === idCourse}
                                                    openModalVideoView={openModalVideoView}
                                                />

                                            </Col>
                                            <Col xs={6} style={{ overflowY: "scroll", maxHeight: "calc(100vh - 140px)" }}>
                                                <ContentList
                                                    listId={"XDA2"}
                                                    idPlayList={destinationId}
                                                    setPlaylistId={setDestinationId}
                                                    list={destinationList}
                                                    setList={setDestinationList}
                                                    disabledDrop={sourceId === destinationId || destinationId === idCourse}
                                                    openModalVideoView={openModalVideoView}
                                                    idCourse={idCourse}
                                                />

                                            </Col>
                                        </Row>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Board



