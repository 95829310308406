import React, { useState, useContext } from "react";
import { Pagination, Input, message } from "antd";

import StudentsContext from "../../../context/students/StudentsContext";

import styles from "./index.module.scss";

const { Search } = Input;

const SearchAndPagination = () => {
  const studentsContext = useContext(StudentsContext);
  const { studentSearchBy, getStudents, resultCount } = studentsContext;

  const [activeSearch, setActiveSearch] = useState(false);
  const [current, setCurrent] = useState(1);
  const [searchText, setSearchText] = useState("");

  const onSearch = (value) => {
    if (value === "") return message.warning("Escribe un nombre");

    studentSearchBy(value);
    setActiveSearch(true);
    setCurrent(1);
    setSearchText(value);
  };

  const onBlurSearch = (e) => {
    if (e.target.value === "" && activeSearch) {
      getStudents();
      setActiveSearch(false);
    }
  };

  const onChange = (page) => {
    setCurrent(page);
    let skipNumber = (page - 1) * 10;
    studentSearchBy(`${searchText}&skip=${skipNumber}&limit=10`);
  };

  return (
    <div className={styles.search_pagination_container}>
      <Search
        placeholder="Buscar alumno"
        style={{ width: 300 }}
        enterButton
        onSearch={onSearch}
        onBlur={onBlurSearch}
      />

      <section>
        <Pagination
          total={resultCount}
          showTotal={(total, range) =>
            `${range[0]} al ${range[1]} de ${total} encontrados`
          }
          current={current}
          onChange={onChange}
          defaultPageSize={10}
          hideOnSinglePage={true}
          showSizeChanger={false}
        />
      </section>
    </div>
  );
};

export default SearchAndPagination;
