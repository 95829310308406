import React, { PureComponent } from 'react';
import { Col, Card, CardBody } from 'reactstrap';

import styles from "./index.module.scss";
class EmptyCard extends PureComponent {

  render() {

    return (

      <Col xs={12} sm={12} md={12} className={styles.center_empty_card}>
        <Card >

          <CardBody >
           
              <h4>{this.props.message}</h4>
           
          </CardBody>

        </Card>
      </Col>

    )
  }


}
export default EmptyCard;
