import 'antd/es/table/style/index.css'
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Pie } from '@ant-design/charts';
import { Statistic, Table } from 'antd';
import Loading from '../../../../helpers/LoadingComponent'
import { fetchAsync, urlTalks } from '../../../../helpers/Globals/globals'
import { Row, Col, Button } from 'reactstrap';

import styles from './index.module.scss'
import 'antd/es/statistic/style/index.css'

function Reports(props) {

    const [groupInfo, setGroupInfo] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getInfoReport(props.talkFullInfo._id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alphabeticSort = (a, b) => {
        return a.localeCompare(b);
    }

    const onCloseModal = () => {
        props.close()
    }
    const getInfoReport = (id) => {
        setLoading(true)
        fetchAsync(urlTalks + "reports/" + id, "", "GET", "")
            .then(
                (data) => {
                    if (data.success === true) {
                        setGroupInfo(data.report)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        // alert(data.error)
                    }
                }
            ).catch(
                (reason) => {
                    setLoading(false)
                    // showMessageError("Ocurrio un error Reportar error a sistemas", 3)
                })
    }

    // const renderLegend = ({ payload }) => (
    //     <ul className="dashboard__booking-reservations-chart-legend dashboard__chart-legend">
    //         {
    //             payload.map((entry, index) => (
    //                 <li key={`item-${index}`}>
    //                     <span style={{ backgroundColor: entry.color }} />
    //                     <p>{entry.value}</p>
    //                 </li>
    //             ))
    //         }
    //     </ul>
    // )

    const columns = [
        {
            title: 'Nombre',
            dataIndex: ['employee', 'name'],
            sorter: (a, b) => alphabeticSort(a.employee.name, b.employee.name),
        },
        {
            title: 'Appellido Paterno',
            dataIndex: ['employee', 'lastName'],
            sorter: (a, b) => alphabeticSort(a.employee.lastName, b.employee.lastName),
        },
        {
            title: 'Apellido Materno',
            dataIndex: ['employee', 'secondLastName'],
            sorter: (a, b) => alphabeticSort(a.employee.secondLastName, b.employee.secondLastName),
        },
        {
            title: 'Confirmados',
            dataIndex: ['data', 'confirmados'],
            sorter: (a, b) => a.data.confirmados - b.data.confirmados,
        },
        {
            title: 'Buzón de voz',
            dataIndex: ['data', 'buzon'],
            sorter: (a, b) => a.data.buzon - b.data.buzon,
        },
        {
            title: 'Colgo la llamada',
            dataIndex: ['data', 'finishCall'],
            sorter: (a, b) => a.data.finishCall - b.data.finishCall,
        },
        {
            title: 'Reagendar',
            dataIndex: ['data', 'reprogramDate'],
            sorter: (a, b) => a.data.reprogramDate - b.data.reprogramDate,
        },
        {
            title: 'Regresar llamada',
            dataIndex: ['data', 'returnCall'],
            sorter: (a, b) => a.data.returnCall - b.data.returnCall,
        },
        {
            title: 'No existe',
            dataIndex: ['data', 'noExistPhone'],
            sorter: (a, b) => a.data.noExistPhone - b.data.noExistPhone,
        },
        {
            title: 'Numero Equivocado',
            dataIndex: ['data', 'wrongNumber'],
            sorter: (a, b) => a.data.wrongNumber - b.data.wrongNumber,
        },
    ]

    const data = [
        { type: 'Numero de confirmados', value: groupInfo.confirmados },
        { type: 'Buzón de voz', value: groupInfo.buzon },
        { type: 'Colgo la llamada', value: groupInfo.finishCall },
        { type: 'Reagendar Fecha', value: groupInfo.reprogramDate },
        { type: 'Regresar la llamada', value: groupInfo.returnCall },
        { type: 'No existe el número', value: groupInfo.noExistPhone },
        { type: 'Numero equivocado', value: groupInfo.wrongNumber },
        { type: 'Sin Comentarios', value: groupInfo.sinComentarios },
        { type: 'Sin interacción', value: groupInfo.sinInteraccion },
    ];

    const config = {
        appendPadding: 10,
        data: data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.64,
        meta: {
            value: {
                formatter: function formatter(v) {
                    return v;
                },
            },
            alias: "total"
        },
        label: {
            type: 'inner',
            offset: '-50%',
            style: { textAlign: 'center' },
            autoRotate: false,
            content: '{value}',
        },
        statistic: {
            title: {
                customHtml: "Total de registros"
            }
        },
        interactions: [
            { type: 'element-selected' },
            { type: 'element-active' },
            { type: 'pie-legend-active' },
            { type: 'pie-statistic-active' },
        ],
    };

    return (
        <Modal
            open={props.valueModal}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}
            classNames={{
                modal: styles.styleModalReports,
            }}
        >
            <div>
                <Row className="d-flex flex-row">
                    <Col sm={12} md={12}>
                        <Button type="button" className="btn-sm float-right" onClick={() => onCloseModal()}>Salir</Button>
                    </Col>
                </Row>
                <Row>
                    <>
                        {loading ?
                            <Loading />
                            : <>
                                <Col md="6">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <Statistic title="Total de registros:" value={groupInfo.studentsCount} />
                                            <Statistic title="Numero de confirmados:" value={groupInfo.confirmados} />
                                            <Statistic title="Buzón de voz:" value={groupInfo.buzon} />
                                            <Statistic title="Colgo la llamada:" value={groupInfo.finishCall} />
                                            <Statistic title="Reagendar Fecha:" value={groupInfo.reprogramDate} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <Statistic title="Regresar la llamada:" value={groupInfo.returnCall} />
                                            <Statistic title="No existe el número:" value={groupInfo.noExistPhone} />
                                            <Statistic title="Numero equivocado:" value={groupInfo.wrongNumber} />
                                            <Statistic title="Sin comentarios: " value={groupInfo.sinComentarios} />
                                            <Statistic title="Sin interacción: " value={groupInfo.sinInteraccion} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6">
                                    <div className="dashboard__booking-reservations">
                                        <p className="dashboard__booking-reservations-title">Total de registros:</p>
                                        <p className="dashboard__booking-reservations-number">{groupInfo.studentsCount}</p>
                                        <>
                                            <Pie {...config} />
                                        </>
                                    </div>
                                </Col>
                                <>
                                    <Table className={styles.table_talks_reports} columns={columns} dataSource={groupInfo.employeesData} pagination={false} />
                                </>
                            </>}
                    </>
                </Row>
            </div>

        </Modal>
    )

}
export default Reports