import React, { useCallback } from 'react';
import ModalStudentsDetails from 'helpers/ModalStudentsDetails'
import TableAlumnos from './components/tableRefactor';

const ModalStudents = (props) => {
    const closeModal = useCallback(() => {
        props.closeModal(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.talk])
    return (
        <ModalStudentsDetails
            valueModal={props.valueModal}
            onCloseModal={closeModal}
            title={"Información: " + props.talk.name}
            TableComponent={
                <TableAlumnos
                    talk={props.talk}
                    openStudentCard={props.openStudentCard}
                />
            }
        />
    )
}
export default ModalStudents
