import React, { useReducer, createContext } from "react";
import {
  showMessageLoading,
  showMessageSuccess,
  showMessageError,
} from "../../helpers/MessageAndNotificationUtils";
import {
  fetchAsync,
  urlStudents,
  urlSearchElastic,
  urlPayment,
  urlGroups,
  urlFolio,
} from "../../helpers/Globals/globals";
import {
  GET_STUDENTS,
  STUDENTS_SEARCH,
  LOADING_STUDENTS_TABLE,
  SET_ACTUAL_STUDENT,
  GET_PAYMENT_STUDENT,
  GET_GROUPS_STUDENT,
  LOADING_GORUPS_TAB,
  LOADING_PAYMENTS_TAB,
  UPDATE_AFTER_DELETE_REGISTER,
  UPDATE_AFTER_CHANGE_GROUP,
  UPDATE_STUDENT_AFTER_EDIT,
  UPDATE_AFTER_DELETE_STUDENT,
  ADD_PAYMENT_UPDATE,
  UPDATE_AFTER_ENROLL_GROUP,
  RELOAD_STUDENT,
  SET_FOLIO,
  EDIT_FOLIO,
  DELETE_FOLIO,
} from "../types/index";
import StudentsReducer from "./StudentsReducer";
// import StudentsContext from './StudentsContext';
import "moment/locale/es";

let loading = "";

const StudentsContext = createContext();
export default StudentsContext;

export const StudentsProvider = (props) => {
  const inicialState = {
    actualSearch: "",
    allStudents: [],
    resultCount: 0,
    loadingTable: false,
    loadingGroupTabs: false,
    loadingPaymentsTabs: false,
    actualStudent: "",
    actualStudentPayments: [],
    actualStudentGroups: [],
  };

  //crear dispatch y state
  const [state, dispatch] = useReducer(StudentsReducer, inicialState);

  //obtenemos los estudiantes sin ningun filtro
  const getStudents = () => {
    setLoadingTable(true);
    fetchAsync(urlStudents + "?limit=10", "", "GET", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_STUDENTS,
            payload: data.students,
          });
        }
        setLoadingTable(false);
        return data;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //fetch para la barra de busqueda
  const studentSearchBy = (stringFetch) => {
    if (stringFetch.indexOf("skip") === -1) {
      //setResultCount(0)
      setLoadingTable(true);
    }
    fetchAsync(urlSearchElastic + stringFetch, "", "GET", "")
      .then((result) => {
        if (result.success) {
          // if (result.result.hits.total > 0 || result.result.hits.extTotal > 0) {
          dispatch({
            type: STUDENTS_SEARCH,
            payload: result,
          });
          setLoadingTable(false);
          // }
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const setLoadingTable = (status) => {
    dispatch({
      type: LOADING_STUDENTS_TABLE,
      payload: status,
    });
  };

  const setSudentInfo = (studentInfo) => {
    dispatch({
      type: SET_ACTUAL_STUDENT,
      payload: studentInfo,
    });
  };

  ////////////SERVICIOS PARA LAS TABS DE LA FICHA///////////
  //obtenemos los pagos del alumno
  const getPaymentsStudent = (idStudent) => {
    setloadinPaymentsTabs(true);
    return fetchAsync(urlPayment + "/student/" + idStudent, "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: GET_PAYMENT_STUDENT,
            payload: result.payments,
          });
        }
        setloadinPaymentsTabs(false);
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  //obtenemos la información completa del alumno
  const getStudentInfo = (id) => {
    fetchAsync(urlStudents + id, "", "GET", "")
      .then((result) => {
        if (result.success) {
          setSudentInfo(result.student);
          getGroupsInfo(result.student.groups);
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //obtiene la información de todos los grupos del alumno y crea un nuevo array
  const getGroupsInfo = (array) => {
    if (array.length === 0) {
      return dispatch({
        type: GET_GROUPS_STUDENT,
        payload: [],
      });
    }
    setloadinGroupTabs(true);

    let promises = array.map((id) => {
      return fetchAsync(urlGroups + id, "", "GET", "").then((data) => {
        return data.group;
      });
    });

    // console.log("Verpromises")
    // console.log(promises)
    // console.log("Verpromises")

    Promise.all(promises).then((results) => {
      if (results.length > 0) {
        // console.log("ACABO EL FETCH")
        // console.log(results)
        // console.log("ACABO EL FETCH")
        dispatch({
          type: GET_GROUPS_STUDENT,
          payload: results,
        });
      }
      setloadinGroupTabs(false);
    });
  };

  //obtiene la información de un grupo
  const getGroupInfo = (idGroup) => {
    return fetchAsync(urlGroups + idGroup, "", "GET", "")
      .then((data) => {
        return data;
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //activa el loading de los grupos por alumno (tabs de la sidebar)
  const setloadinGroupTabs = (status) => {
    dispatch({
      type: LOADING_GORUPS_TAB,
      payload: status,
    });
  };

  //activa el loading de los pagos por alumno (tabs de la sidebar)
  const setloadinPaymentsTabs = (status) => {
    dispatch({
      type: LOADING_PAYMENTS_TAB,
      payload: status,
    });
  };

  //reenviar el correo de inscripción
  const resendRegisterTicket = (idGroup) => {
    loading = showMessageLoading("Reenviando comporbante", 0);
    return fetchAsync(
      urlGroups + idGroup + "/receipt/" + state.actualStudent._id,
      "",
      "POST",
      ""
    )
      .then((result) => {
        if (result.success) {
          loading();
          showMessageSuccess("El comprobante ha sido enviado correctamente", 3);
        }
      })
      .catch((reason) => {
        loading();
        showMessageError("Ocurrio un error al enviar el comprobante", 3);
      });
  };

  //elimina el registro a un grupo
  const deleteRegisterGroup = (idGroup) => {
    loading = showMessageLoading("Borrando grupo");
    fetchAsync(
      urlStudents + state.actualStudent._id + "/group/" + idGroup,
      "",
      "DELETE",
      ""
    )
      .then((data) => {
        if (data.success) {
          loading();
          showMessageSuccess("Grupo borrado correctamente");
          updateAfterDeleteRegisterGroup(idGroup);
        }
      })
      .catch((reason) => {
        loading();
        showMessageError("Ocurrio un error al eliminar el grupo", 3);
      });
  };

  const updateAfterDeleteRegisterGroup = (idGorup) => {
    dispatch({
      type: UPDATE_AFTER_DELETE_REGISTER,
      payload: idGorup,
    });
  };

  //reenvia el comprobante de un pago
  const resendPaymentTicket = (idPayment) => {
    loading = showMessageLoading("Reenviando comporbante", 0);
    return fetchAsync(urlPayment + "/send/" + idPayment, "", "PUT", "")
      .then((result) => {
        if (result.success) {
          loading();
          showMessageSuccess(
            "El comprobante de pago ha sido enviado correctamente",
            3
          );
        }
      })
      .catch((reason) => {
        loading();
        showMessageError(
          "Ocurrio un error al enviar el comprobante de pago",
          3
        );
      });
  };

  //funcion para cambiar un grupo
  const changeStudentGroup = (oldGroupId, newGroupId) => {
    loading = showMessageLoading("Cambiando...", 0);
    let body = {};
    body.group = {
      id: newGroupId,
    };
    return fetchAsync(
      urlStudents +
        state.actualStudent._id +
        "/groups/" +
        oldGroupId +
        "/?force=true",
      JSON.stringify(body),
      "PUT",
      ""
    )
      .then((data) => {
        if (data.success) {
          updateAfterChange(data.student, oldGroupId, newGroupId);
          loading();
          showMessageSuccess("Se cambio el grupo exitosamente", 4);
        }
        loading();
        return data;
      })
      .catch((reason) => {
        loading();
        showMessageError("Ocurrio un error al realizar el cambio", 3);
      });
  };

  //actualiza la tabla, sidebar y array de grupos despues de cambiar un grupo
  const updateAfterChange = (student, oldGroupId, newGroupId) => {
    //actualizamos la informacion del side bar y la tabla
    getInfoStudentAfterEditorPayment(state.actualStudent._id);
    //actualizamos los grupos
    let newGroup = getGroupInfo(newGroupId);
    newGroup.then((result) => {
      student.oldIdGroup = oldGroupId;
      student.newGroupInfo = result.group;
      dispatch({
        type: UPDATE_AFTER_CHANGE_GROUP,
        payload: student,
      });
    });
  };

  //actualiza el alumno al editar
  const updateStudent = (student) => {
    setLoadingTable(true);
    dispatch({
      type: UPDATE_STUDENT_AFTER_EDIT,
      payload: student,
    });
  };

  //actualiza el alumno al editar
  const updateAfterDeleteStudent = (student) => {
    dispatch({
      type: UPDATE_AFTER_DELETE_STUDENT,
      payload: student,
    });
  };

  //actualiza el alumno al editar
  const addPaymentsStudent = (payment) => {
    // getInfoStudent(state.actualStudent._id)
    getInfoStudentAfterEditorPayment(state.actualStudent._id);
    dispatch({
      type: ADD_PAYMENT_UPDATE,
      payload: payment,
    });
  };
  //obtiene la informacion del alumno y recarga la tabla
  const getInfoStudentAfterEditorPayment = (id) => {
    setLoadingTable(true);
    fetchAsync(urlStudents + id, "", "GET", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: RELOAD_STUDENT,
            payload: result.student,
          });
        }
        setLoadingTable(false);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  //recarga los pagos, grupos, tabla y sidebar despues de inscribir a un grupo
  const updateAfterFastRegister = (idGroup) => {
    //recargamos sus pagos
    getPaymentsStudent(state.actualStudent._id);
    //recargamos la tabla y sidebar
    getInfoStudentAfterEditorPayment(state.actualStudent._id);
    //obtenemos los datos del nuevo grupo
    let newGroup = getGroupInfo(idGroup);
    newGroup.then((result) => {
      dispatch({
        type: UPDATE_AFTER_ENROLL_GROUP,
        payload: result.group,
      });
    });
  };

  //Folios
  const setFolio = (infoFolio) => {
    return fetchAsync(
      urlFolio + state.actualStudent._id,
      JSON.stringify(infoFolio),
      "POST",
      ""
    )
      .then((result) => {
        if (result.success) {
          dispatch({
            type: SET_FOLIO,
            payload: result.folio,
          });
        }
        return result;
      })
      .catch((error) => {
        showMessageError("Ocurrio un error al agregar el folio", 4);
      });
  };

  const editFoliio = (infoFolio) => {
    let body = { ...infoFolio };
    delete body._id;
    return fetchAsync(urlFolio + infoFolio._id, JSON.stringify(body), "PUT", "")
      .then((result) => {
        if (result.success) {
          dispatch({
            type: EDIT_FOLIO,
            payload: result.folio,
          });
        }
        return result;
      })
      .catch((reason) => {
        showMessageError("Ocurrio un error", 3);
      });
  };

  const deleteFolio = (idFolio) => {
    return fetchAsync(urlFolio + idFolio, "", "DELETE", "")
      .then((data) => {
        if (data.success) {
          dispatch({
            type: DELETE_FOLIO,
            payload: idFolio,
          });
        }
        return data;
      })
      .catch((reason) => {
        showMessageError("Ocurrio un error", 3);
      });
  };

  const value = {
    ...state,
    //traer todos los estudiantes
    getStudents,
    //traer estudiante(s) por alguna palabra
    studentSearchBy,
    setLoadingTable,
    //información del estudiante selecionado en la tabla
    setSudentInfo,
    //tabs ficha
    getPaymentsStudent,
    resendPaymentTicket,
    //obtiene toda la información del alumno
    getStudentInfo,
    //obtiene la información de los grupos en los que esta registrado el alumno
    getGroupsInfo,
    //activa los loading de los tabs de la ficha
    setloadinGroupTabs,
    //reenvia el ticket de registro a un grupo
    resendRegisterTicket,
    //elimina el registro a un grupo
    deleteRegisterGroup,
    //cambiar grupo
    changeStudentGroup,
    //actualizar alumno al editar
    updateStudent,
    //actualiza despues de eliminar un alumno
    updateAfterDeleteStudent,
    //actualiza despues de agregar un pago
    addPaymentsStudent,
    //actualiza despues de inscribir a un grupo
    updateAfterFastRegister,
    //obtiene la informacion de un grupo
    getGroupInfo,
    setFolio,
    editFoliio,
    deleteFolio,
  };

  return (
    <StudentsContext.Provider value={value}>
      {props.children}
    </StudentsContext.Provider>
  );
};
