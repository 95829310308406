import React from 'react';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';
import MinusIcon from 'mdi-react/MinusIcon';


//este componente funcional genera el estilo del sort para todas la tablas
const SortTable = (props) => {
    let order = props.order

    if (!order) return (<span><MinusIcon /></span>);
    else if (order === 'asc') return (<span><font color="#3057af"><MenuUpIcon /></font></span>);
    else if (order === 'desc') return (<span><font color="#3057af"><MenuDownIcon /></font></span>);
    return null;
}
export default SortTable