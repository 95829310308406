import React, { useContext, useState, useEffect } from "react";
import { Tabs } from "antd";

import ShowDevice from "./showDevice";
import LoadingComponent from "../../../helpers/LoadingComponent";
import MessagesContext from "../context/messagesContext";
import { getHtmlFromTemplate } from "./redFunctions";

import { MailOutlined, MessageOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";

const { TabPane } = Tabs;

const TabsDevice = ({ type, text = "" }) => {
  const context = useContext(MessagesContext);

  const { actualTab, onChangeTab } = context;

  const [template, setTemplate] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const response = getHtmlFromTemplate();
    response.then((res) => {
      if (res.success) {
        setTemplate(res.response.html_content);
      }
      setLoading(false);
    });
  }, []);

  const onChangeTabs = (key) => {
    onChangeTab(key);
  };

  return (
    <Tabs
      defaultActiveKey={actualTab}
      className={
        type !== "courses"
          ? styles.previews_container_full
          : styles.previews_container
      }
      onChange={onChangeTabs}
    >
      <TabPane
        tab={
          <span>
            <MailOutlined />
            Email
          </span>
        }
        key="1"
      >
        <section
          className={
            type !== "courses"
              ? styles.preview_screen_full
              : styles.preview_screen
          }
        >
          {loading ? (
            <LoadingComponent />
          ) : (
            <ShowDevice template={template} text={text} typeDevice={"email"} />
          )}
        </section>
      </TabPane>
      <TabPane
        tab={
          <span>
            <MessageOutlined />
            SMS
          </span>
        }
        key="2"
      >
        <section
          className={
            type !== "courses"
              ? styles.preview_screen_full
              : styles.preview_screen
          }
        >
          {loading ? (
            <LoadingComponent />
          ) : (
            <ShowDevice template={template} text={text} typeDevice={"sms"} />
          )}
        </section>
      </TabPane>
    </Tabs>
  );
};

export default TabsDevice;
