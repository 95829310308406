import React from "react"
import { Skeleton } from 'antd';
import "antd/es/skeleton/style/index.css"

const LoadingSkeleton = (props) => {
    // let { hereProps } = props
    return (
        <Skeleton active paragraph={{ rows: 0 }} />
    )
}

export default LoadingSkeleton