import React from "react";
import { Col, Row, Button } from "reactstrap";
import { Modal } from "react-responsive-modal";
import StepCourse from "containers/Grupos/components/addGroup/components/stepCourse";

import styles from "./index.module.scss";

const ModalEditCourse = (props) => {
  const onCloseModal = () => {
    props.close(false);
  };

  return (
    <Modal
      open={props.valueModal}
      onClose={onCloseModal}
      center={true}
      closeOnEsc={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <section sm={12} md={12} className={styles.edit_group_course_container}>
        <div>
          <Button
            type="button"
            className="btn-sm float-right"
            onClick={onCloseModal}
          >
            Salir sin guardar
          </Button>
        </div>

        <div className={styles.courses_box}>
          <StepCourse
            saveSelectedCourse={props.saveSelectedCourse}
            idSelectedCourse={
              props.selectedCourse !== null ? props.selectedCourse._id : ""
            }
          />
        </div>
      </section>
    </Modal>
  );
};
export default ModalEditCourse;
