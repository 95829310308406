import React, { useState, useContext } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import LoadingComponent from "../../../helpers/LoadingComponent";
import CourseContext from "../../../context/courses/CourseContext";
import TableCourses from "./TableCourses";

import SidebarRightComponent from "../../../helpers/SidebarRight/SidebarRightComponent";
import SidebarCourse from "./SidebarCourse";

import styles from "./index.module.scss";

function MainTab() {
  const courseContext = useContext(CourseContext);
  const {
    courses,
    coursesMedia,
    coursesSuperior,
    setActualCourse,
  } = courseContext;

  const [activeTab, setActiveTab] = useState("1");
  const [showDrawer, setShowDrawer] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const showSidebar = () => {
    setShowDrawer(true);
  };

  return (
    <section className={styles.tabs_conteinerR}>
      <div className="tabs tabs--justify tabs--bordered-top">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Nivel media superior
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Nivel superior
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Todos los Cursos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-1">
          <TabPane tabId="1">
            {coursesMedia !== null ? (
              <TableCourses
                dataTable={coursesMedia}
                getActualCourse={showSidebar}
                setActualCourse={setActualCourse}
              />
            ) : (
              <LoadingComponent />
            )}
          </TabPane>
          <TabPane tabId="2">
            {coursesSuperior !== null ? (
              <TableCourses
                dataTable={coursesSuperior}
                getActualCourse={showSidebar}
                setActualCourse={setActualCourse}
              />
            ) : (
              <LoadingComponent />
            )}
          </TabPane>
          <TabPane tabId="3">
            {courses !== null ? (
              <TableCourses
                dataTable={courses}
                getActualCourse={showSidebar}
                setActualCourse={setActualCourse}
              />
            ) : (
              <LoadingComponent />
            )}
          </TabPane>
        </TabContent>
        {showDrawer && (
          <SidebarRightComponent
            isSidebarRightOpen={showDrawer}
            onClosSidebar={() => setShowDrawer(false)}
          >
            <SidebarCourse closeSidebar={() => setShowDrawer(false)} />
          </SidebarRightComponent>
        )}
      </div>
    </section>
  );
}
export default MainTab;
