import React, { useContext } from "react";
import { Container, Col, Row, ButtonToolbar, Button } from "reactstrap";
import { newSchedules } from "../../../helpers/Globals/funciones";
import EnrollmentContext from "../../../context/EnrollmentContext/EnrollmentContext";
import ViewportContext from "../../../context/Viewport/viewportContext";
import { showMessageError } from "../../../helpers/MessageAndNotificationUtils";
import moment from "moment";
import "moment/locale/es";

const StepConfirmation = (props) => {
  //instance of the viewport context
  const viewportContext = useContext(ViewportContext);
  const { width } = viewportContext;
  const isMobile = width < 500;

  //importamos el context y sus estados y funciones
  const enrollmentContext = useContext(EnrollmentContext);
  const {
    studentInfoFastRegister,
    studentData,
    selectedCourse,
    selectedGroup,
    payment,
    registerStudent,
    disableButtonRegister,
    studentAlreadyRegistered,
  } = enrollmentContext;

  const salon = () => {
    if (selectedGroup.classRoomInfo) {
      if (
        selectedGroup.classRoomInfo.name !== undefined &&
        selectedGroup.classRoomInfo.name !== null &&
        selectedGroup.classRoomInfo.name !== ""
      ) {
        return selectedGroup.classRoomInfo.name;
      } else {
        return "";
      }
    }
  };

  const sede = () => {
    if (selectedGroup.locationInfo) {
      if (
        selectedGroup.locationInfo.name !== undefined &&
        selectedGroup.locationInfo.name !== null &&
        selectedGroup.locationInfo.name !== ""
      ) {
        return selectedGroup.locationInfo.name;
      } else {
        return "";
      }
    }
  };

  const continueButton = () => {
    props.setShowStepConfirmation(false);
    //validamos si es inscripción normal o rapida
    if (studentInfoFastRegister !== null || props.typeComponent !== "talks")
      return studentAlreadyRegistered();
    const result = registerStudent();
    result.then((res) => {
      if (res === undefined)
        showMessageError(
          <strong>Ocurrio un error al realizar el registro.</strong>,
          2
        );
    });
  };

  const montoLiquidar = () => {
    let { discount, amount } = payment;
    let totalPagado =
      discount > 0 ? parseInt(discount) + parseInt(amount) : parseInt(amount);
    let montoLiquidar = parseInt(selectedCourse.price) - totalPagado;
    return montoLiquidar;
  };

  const newArray =
    selectedGroup.schedules !== undefined ? selectedGroup.schedules : [];
  const { name, lastName, secondLastName, email, phoneNumber } = studentData;

  return (
    <Container>
      <Row>
        <Col xs="12">
          <Row>
            <Col sm={12} md={6}>
              <div className="card__title">
                <h4 className="bold-text">Confirma los datos del alumno</h4>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <ButtonToolbar>
                <Button
                  className="mr-3"
                  size="sm"
                  onClick={() => props.setShowStepConfirmation(false)}
                >
                  <p>Corregir</p>
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  style={{ backgroundColor: "#4170cd" }}
                  onClick={continueButton}
                  disabled={disableButtonRegister}
                  // disabled={disableButtonRegister ? "disabled" : ""}
                >
                  <p>Continuar</p>
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
          <Col
            md={12}
            xl={12}
            sm={12}
            className={
              isMobile
                ? "text-card-enrollment-data"
                : "text-card-enrollment-data pricing-card pricing-card--warning"
            }
          >
            {studentInfoFastRegister === null ? (
              <>
                <p className="mt-1">
                  Nombre - <b>{`${name} ${lastName} ${secondLastName}`}</b>
                </p>
                <p className="mt-1">
                  Correo - <b>{email}</b>
                </p>
                <p className="mt-1">
                  Teléfono - <b>{phoneNumber}</b>
                </p>
              </>
            ) : (
              <>
                <p className="mt-1">
                  Nombre -{" "}
                  <b>{`${studentInfoFastRegister.name} ${studentInfoFastRegister.lastName} ${studentInfoFastRegister.secondLastName}`}</b>
                </p>
                <p className="mt-1">
                  Correo - <b>{studentInfoFastRegister.email}</b>
                </p>
                <p className="mt-1">
                  Teléfono - <b>{studentInfoFastRegister.phoneNumber}</b>
                </p>
              </>
            )}
            <p className="mt-1">
              Curso elegido: - <b> {selectedCourse.name}</b>
            </p>
            <p className="mt-1">
              Sede - <b>{sede()}</b>
            </p>
            <p className="mt-1">
              Grupo - <b>{selectedGroup.name}</b>
            </p>
            <p className="mt-1">
              Salón - <b>{salon()}</b>
            </p>
            <p className="mt-1">
              Fecha de inicio -{" "}
              <b>
                {moment(new Date(selectedGroup.startDate)).format("DD/MM/YYYY")}
              </b>
            </p>
            <p className="mt-1">
              Precio del curso - <b>{selectedCourse.price}</b>
            </p>
            <p className="mt-1">
              Pago - <b>{payment.amount}</b>
            </p>
            <p className="mt-1">
              Descuento - <b>{payment.discount}</b>
            </p>
            <p className="mt-1">
              Monto para liquidar - <b>{montoLiquidar()}</b>
            </p>
            <p className="mt-1">
              <b>
                {selectedGroup.schedules !== undefined
                  ? newSchedules(newArray)
                  : ""}
              </b>
            </p>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
export default StepConfirmation;
