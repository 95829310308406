//types de cursos
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_COURSES_TYPES = 'GET_COURSES_TYPES';
export const GET_COURSES_LEVELS = 'GET_COURSES_LEVELS';
export const ACTUAL_COURSE = 'ACTUAL_COURSE';
export const ACTUAL_COURSE_GROUPS = 'ACTUAL_COURSE_GROUPS';
export const CREATE_COURSE = 'CREATE_COURSE';
export const DISABLE_BUTTON = 'DISABLE_BUTTON';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const UPDATE_COURSE_AFTER_EDIT = 'UPDATE_COURSE_AFTER_EDIT';
export const UPDATE_COURSE_AFTER_ADD = 'UPDATE_COURSE_AFTER_ADD';

//types Enrollment (inscripción e inscripción rapida)
export const NEXT_PAGE = "NEXT_PAGE";
export const BACK_PAGE = "BACK_PAGE";
export const STUDENT_DATA = "STUDENT_DATA";
export const SET_STUDENT_DATA_TALK = "SET_STUDENT_DATA_TALK";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const ENROLLMENT_SELECTED_COURSE = 'ENROLLMENT_SELECTED_COURSE';
export const ENROLLMENT_ALL_COURSES = 'ENROLLMENT_ALL_COURSES';
export const ENROLLMENT_COURSES_LEVELS = 'ENROLLMENT_COURSES_LEVELS';
export const ACTIVE_TAB = 'ACTIVE_TAB';
export const COURSE_GROUPS = 'COURSE_GROUPS';
export const LOADING_GROUPS = 'LOADING_GROUPS';
export const ENROLLMENT_SELECTED_GROUP = 'ENROLLMENT_SELECTED_GROUP';
export const PAYMENT_DATA = "PAYMENT_DATA";
export const DISABLE_BUTTON_REGISTER = "DISABLE_BUTTON_REGISTER";
export const FAST_REGISTER_DATA_STUDENT = "FAST_REGISTER_DATA_STUDENT";
export const STUDENT_DISCOUNTS = "STUDENT_DISCOUNTS";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const ADD_DISCOUNT = "ADD_DISCOUNT";

//Types of Locations (Sedes)
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
export const ACTUAL_LOCATION = "ACTUAL_LOCATION";
export const RESET_ACTUAL_LOCATION = "RESET_ACTUAL_LOCATION";
export const CLASSROOMS_LOCATION = "CLASSROOMS_LOCATION";
export const EMPTY_CLASSROOMS = "EMPTY_CLASSROOMS";

//Types of CLassrooms 
export const ACTUAL_CLASSROOM = "ACTUAL_CLASSROOM";
export const SAVE_NEW_CLASSROOM = "SAVE_NEW_CLASSROOM";
export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";
export const ERRORS_CLASSROOM = "ERRORS_CLASSROOM";

//Types of Groups
export const SIDEBAR_ALL_DATA = "SIDEBAR_ALL_DATA"
export const LEVELS = "LEVELS";
export const COURSES_MEDIA = "COURSES_MEDIA";
export const COURSES_SUPERIOR = "COURSES_SUPERIOR";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const DISABLE_SWITCH_GROUP = "DISABLE_SWITCH_GROUP";
export const ACTUAL_GROUP = "ACTUAL_GROUP";
export const TEACHERS = "TEACHERS";
export const LOADING_SKELETON_GROUPS = "LOADING_SKELETON_GROUPS";
export const SET_SEARCH_NAME = "SET_SEARCH_NAME";
export const SEARCH_GROUPS_RESULT = "SEARCH_GROUPS_RESULT";
export const EMPTY_SEARCH = "EMPTY_SEARCH";
export const GROUP_STUDENTS = "GROUP_STUDENTS";
export const GROUP_LOCATIONS = "GROUP_LOCATIONS";
export const EMPTY_RESULT_GROUPS = "EMPTY_RESULT_GROUPS";
export const UPDATE_TABLE_ROW = "UPDATE_TABLE_ROW";
export const UPDATE_TABLE_CREATE = "UPDATE_TABLE_CREATE";
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_STUDENT = 'DELETE_GROUP_STUDENT'
export const UPDATE_TABLE_STATUS = "UPDATE_TABLE_STATUS";
export const UPDATE_TABLE_STUDENTS_COMMENT_DELETE = "UPDATE_TABLE_STUDENTS_COMMENT_DELETE";
export const UPDATE_TABLE_STUDENTS_ADD_COMMENT = "UPDATE_TABLE_STUDENTS_ADD_COMMENT";
export const UPDATE_TABLE_STUDENTS_UPDATE_COMMENT = "UPDATE_TABLE_STUDENTS_UPDATE_COMMENT";
export const UPDATE_ATTENDANCE_SIDEBAR = "UPDATE_ATTENDANCE_SIDEBAR";
export const SET_URL_STRING = "SET_URL_STRING";
export const SET_URL_ARRAY = "SET_URL_ARRAY";


//types of students (Alumnos)
export const GET_STUDENTS = "GET_STUDENTS";
export const STUDENTS_SEARCH = "STUDENTS_SEARCH";
export const SEARCH_WORD = "SEARCH_WORD";
export const LOADING_STUDENTS_TABLE = "LOADING_STUDENTS_TABLE";
export const LOADING_GORUPS_TAB = "LOADING_GORUPS_TAB";
export const LOADING_PAYMENTS_TAB = "LOADING_PAYMENTS_TAB";
export const SET_ACTUAL_STUDENT = "SET_ACTUAL_STUDENT";
export const GET_ACTUAL_STUDENT_INFO = "GET_ACTUAL_STUDENT_INFO";
export const GET_PAYMENT_STUDENT = "GET_PAYMENT_STUDENT";
export const GET_GROUPS_STUDENT = "GET_GROUPS_STUDENT";
export const UPDATE_AFTER_DELETE_REGISTER = "UPDATE_AFTER_DELETE_REGISTER";
export const UPDATE_AFTER_CHANGE_GROUP = "UPDATE_AFTER_CHANGE_GROUP";
export const UPDATE_STUDENT_AFTER_EDIT = "UPDATE_STUDENT_AFTER_EDIT";
export const UPDATE_AFTER_DELETE_STUDENT = "UPDATE_AFTER_DELETE_STUDENT";
export const ADD_PAYMENT_UPDATE = "ADD_PAYMENT_UPDATE";
export const UPDATE_AFTER_ENROLL_GROUP = "UPDATE_AFTER_ENROLL_GROUP";
export const RELOAD_STUDENT = "RELOAD_STUDENT";
export const SET_FOLIOS_TEMPORAL = "SET_FOLIOS_TEMPORAL";
export const SET_FOLIO = "SET_FOLIO";
export const EDIT_FOLIO = "EDIT_FOLIO";
export const DELETE_FOLIO = "DELETE_FOLIO";

//types of Quiz
export const GET_QUIZ_INFO = "GET_QUIZ_INFO";
export const SAVED_QUIZ = "SAVED_QUIZ";
export const SAVE_QUIZ_COURSE_INFO = "SAVE_QUIZ_COURSE_INFO";
export const SAVE_QUIZ_PLAYLIST_INFO = "SAVE_QUIZ_PLAYLIST_INFO";
export const ONCHANGE_INPUTS = "ONCHANGE_INPUTS";
export const ADD_QUESTION = "ADD_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const ONCHANGE_QUESTION = "ONCHANGE_QUESTION";
export const ADD_FORMULA_QUESTION = "ADD_FORMULA_QUESTION";
export const DELETE_FORMULA_QUESTION = "DELETE_FORMULA_QUESTION";
export const ADD_IMAGE_QUESTION = "ADD_IMAGE_QUESTION";
export const ADD_VIDEO_QUESTION = "ADD_VIDEO_QUESTION";
export const DELETE_VIDEO_QUESTION = "DELETE_VIDEO_QUESTION";
export const ADD_ANSWER = "ADD_ANSWER";
export const DELETE_ANSWER = "DELETE_ANSWER";
export const ONCHANGE_ANSWER = "ONCHANGE_ANSWER";
export const VALID_ANSWER = "VALID_ANSWER";
export const ADD_FORMULA_ANSWER = "ADD_FORMULA_ANSWER";
export const DELETE_FORMULA_ANSWER = "DELETE_FORMULA_ANSWER";
export const ADD_IMAGE_ANSWER = "ADD_IMAGE_ANSWER";
export const AFTER_SAVED_QUIZ = "AFTER_SAVED_QUIZ";
export const REORDER_QUESTIONS = "REORDER_QUESTIONS";
export const SET_SKIP_NUMBER = "SET_SKIP_NUMBER";
export const LOADING_QUIZ = "LOADING_QUIZ";
export const RESTART_VALUES = "RESTART_VALUES";
export const DISABLE_BUTTONS = "DISABLE_BUTTONS";

//ROLES///
export const SELECTED_ROLE = "SELECTED_ROLE"
export const GET_ALL_ROLES = "GET_ALL_ROLES"
export const DELETE_ROLES = "DELETE_ROLES"
export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_AFTER_CREATE_ROLE = "UPDATE_AFTER_CREATE_ROLE"
export const USERS_IN_ROLE = "USERS_IN_ROLE"
export const RESET_STATES_ROLES = "RESET_STATES_ROLES"



//types of sidebar 
export const CHANGE_INIT_POSITION = 'CHANGE_INIT_POSITION'
export const CHANGE_SIDEBAR_SHOW = 'CHANGE_SIDEBAR_SHOW'
export const CHANGE_SIDEBAR_MOBILE_SHOW = 'CHANGE_SIDEBAR_MOBILE_SHOW'
export const CHANGE_SIDEBAR_COMPLEMENT_SHOW = 'CHANGE_SIDEBAR_COMPLEMENT_SHOW'
export const CHANGE_SIDEBAR_COMPLEMENT_MOBILE_SHOW = 'CHANGE_SIDEBAR_COMPLEMENT_MOBILE_SHOW'
export const OPEN_ADDCONTENT_SIDEBAR_COMPLEMENT = 'OPEN_ADDCONTENT_SIDEBAR_COMPLEMENT'
export const KILL_SIDEBAR_COMPLEMENT = 'KILL_SIDEBAR_COMPLEMENT'


//types TALKS 
export const SET_ALL_TALKS = 'SET_ALL_TALKS'
export const SELECT_TALK = 'SELECT_TALK'
export const SET_ROW_DATA = 'SET_ROW_DATA'
export const SET_TALK_ACTIVE = 'SET_TALK_ACTIVE'
export const CREATE_TALK = 'CREATE_TALK'
export const EDIT_TALK = 'EDIT_TALK'
export const DELETE_TALK = 'DELETE_TALK'
export const CHANGE_SKELETON_LOADING = 'CHANGE_SKELETON_LOADING'
export const SET_FULLINFO_TALK = 'SET_FULLINFO_TALK'
export const SET_STUDENT_CONFIRMATION = 'SET_STUDENT_CONFIRMATION'
export const SET_STUDENT_ATTENDANCE = 'SET_STUDENT_ATTENDANCE'
export const SET_STUDENT_REMOVE = 'SET_STUDENT_REMOVE'
export const SET_STUDENT_STATUS = 'SET_STUDENT_STATUS'
export const SET_STUDENT_COMMENT = 'SET_STUDENT_COMMENT'
export const SET_STUDENT_COMMENT_ADD = 'SET_STUDENT_COMMENT_ADD'
export const SET_STUDENT_COMMENT_UPDATE = 'SET_STUDENT_COMMENT_UPDATE'
export const SET_STUDENT_COMMENT_REMOVE = 'SET_STUDENT_COMMENT_REMOVE'
export const SOCKET_EVENT_TALKS = 'SOCKET_EVENT_TALKS'
export const UPDATE_AFTER_EDIT = 'UPDATE_AFTER_EDIT'
export const UPDATE_AFTER_DELETE_STUDENT_TALK = 'UPDATE_AFTER_DELETE_STUDENT_TALK'
